<template>
  <v-toolbar color="#fff" flat>
    <v-toolbar-title class="settingHeader">Orders</v-toolbar-title>
    <v-btn
      class="button"
      v-if="!showReadOnly"
      style="float: right"
      @click="save"
      type="submit"
      >Save</v-btn
    >
    <v-btn
      variant="outlined"
      v-if="showReadOnly"
      @click="edit"
      class="usersent-btn"
      prepend-icon="mdi-pencil-outline"
      style="float: right"
      >Edit</v-btn
    >
    <v-btn
      variant="text"
      density="comfortable"
      @click="close"
      v-if="!showReadOnly"
      elevation="0"
      icon="mdi-close"
      style="float: right"
    ></v-btn>
  </v-toolbar>
  <v-divider class="mb-2"></v-divider>
  <v-form @submit.prevent="save" ref="form">
    <v-container class="pa-4 pl-8">
      <v-row>
        <v-col cols="12" class="pl-1 sub-title"
          >Looks for Orders From :-</v-col
        >
        <v-col cols="4" class="pa-0">
          <!-- <v-label disabled="true" class="date-label">Order Date</v-label>
        <span class="p-fluid">
          <span class="p-input-icon-right">
    <i class="pi pi-calendar" style="z-index:100" />
        <Calendar  :disabled="showReadOnly" v-model="orderPreferences.lastUpdate" placeholder="Order Date" />
        </span>
        </span> -->
          <DatePicker
            :data="orderPreferences.lastUpdate"
            @textData="($event) => {orderPreferences.lastUpdate = $event;}"
            :disabled="showReadOnly"
            :label="'Order Date'"
          />
        </v-col>
        <v-col cols="8" class="pa-0 pl-2">
          <AutoComplete
            :data="orderPreferences.riskSelected"
            @textData="($event) => {orderPreferences.riskSelected = $event;}"
            :items="riskOptions"
            :text="'name'"
            chips
            :closeChip="true"
            :multiple="true"
            value="code"
            :label="'Accept the Following Risk'"
            :disabled="showReadOnly"
          ></AutoComplete>
        </v-col>
        <v-col cols="2" class="pa-0">
          <InputBox
            :data="orderPreferences.channel"
            @textData="($event) => {orderPreferences.channel = $event;}"
            :disabled="showReadOnly"
            :label="'Channel'"
          />
        </v-col>
        <v-col cols="2" class="pa-0 pl-2">
          <InputBox
            :data="orderPreferences.custPo"
            @textData="($event) => {orderPreferences.custPo = $event;}"
            :disabled="showReadOnly"
            :label="'Cust Po'"
          />
        </v-col>
        <v-col cols="2" class="pa-0 pl-2">
          <InputBox
            :data="orderPreferences.portalAmount"
            @textData="($event) => {orderPreferences.portalAmount = $event;}"
            :disabled="showReadOnly"
            :label="'Portal Amount'"
          />
        </v-col>
        <v-col cols="2" class="pa-0 pl-2">
          <InputBox
            :data="orderPreferences.paymentStatus"
            @textData="($event) => {orderPreferences.paymentStatus = $event;}"
            :disabled="showReadOnly"
            :label="'Payment Status'"
          />
        </v-col>
        <v-col cols="4" class="pa-0 pl-2">
          <InputBox
            :data="orderPreferences.tags"
            @textData="($event) => {orderPreferences.tags = $event;}"
            :disabled="showReadOnly"
            :label="'Tags'"
          />
        </v-col>
        <v-col cols="12" class="pl-1 pt-0 pb-5 sub-title"
          >Discrepancy Report Settings :-</v-col
        >
        <v-col cols="5" class="pa-0">
          <AutoComplete
            :data="orderPreferences.include_all"
            @textData="($event) => {orderPreferences.include_all = $event;}"
            :items="includes"
            :text="'name'"
            value="code"
            :label="'Include'"
            :disabled="showReadOnly"
          ></AutoComplete>
        </v-col>
        <v-col cols="5" class="pa-0 pl-2">
          <InputBox
            :data="orderPreferences.orders_for"
            @textData="($event) => {orderPreferences.orders_for = $event;}"
            :disabled="showReadOnly"
            @keypress="onlyNumberDays($event)"
            :label="'Since Last'"
            :text="'Days'"
          />
        </v-col>
        <v-col cols="10" class="pa-0">
          <AutoComplete
            :data="orderPreferences.excludeTaxChannel"
            @textData="($event) => {orderPreferences.excludeTaxChannel = $event;}"
            :multiple="true"
            :closeChip="true"
            chips
            :disabled="showReadOnly"
            :items="excludetags"
            :text="'name'"
            value="code"
            label="Choose Channel to Exclude Tax"
          />
        </v-col>
        <v-col cols="12" class="pl-1 pt-0 sub-title">Notifications :-</v-col>
        <v-col cols="4" class="pa-0 pl-2">
          <v-switch
            v-model="orderPreferences.missingProductAlerts"
            :disabled="showReadOnly"
            :label="'Alert Missing Styles'"
            color="primary"
          >
          </v-switch>
        </v-col>
        <v-col cols="4" class="pa-0 pl-3">
          <v-switch
            v-model="orderPreferences.orderConfirmation"
            :disabled="showReadOnly"
            :label="'Send Order Confirmation'"
            color="primary"
          ></v-switch>
        </v-col>
        <v-col cols="4" class="pa-0">
          <v-switch
            v-model="orderPreferences.notifyOrdersRisk"
            :disabled="showReadOnly"
            :label="'Notify Orders'"
            color="primary"
          >
          </v-switch>
        </v-col>
        <v-col cols="4">
          <TextArea
            v-model="orderPreferences.missingStyleDcList"
            :disabled="showReadOnly"
          />
        </v-col>
        <v-col cols="4">
          <TextArea
            v-model="orderPreferences.orderDcList"
            :disabled="showReadOnly"
          />
        </v-col>
        <v-col cols="4">
          <TextArea
            v-model="orderPreferences.notifyOrderDcList"
            :disabled="showReadOnly"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
import DatePicker from "@/components/DatePicker.vue";
import TextArea from "@/components/TextArea.vue";
import MwsPreferenceService from "@/services/mws/MwsPreferenceService";
import EnumService from "@/services/admin/EnumService";
import moment from "moment";
export default {
  props: ["placeholder"],
  components: {
    DatePicker,
    TextArea,
  },
  data() {
    return {
      showReadOnly: true,
      orderData: {},
      riskOptions: [
        { code: "accept", name: "Accept" },
        { code: "investigate", name: "Investigate" },
        { code: "reject", name: "Reject" },
      ],
      mwsId: localStorage.getItem("mwsHeaderId"),
      excludetags: [
        { code: "facebook", name: "Facebook" },
        { code: "ebay", name: "Ebay" },
        { code: "online shop", name: "Online Shop" },
        { code: "shop", name: "Shop" },
      ],
      includes: [
        { id: "Y", name: "Match/Mismatch" },
        { id: "N", name: "Only Mismatch" },
      ],
      orderPreferences: {
        lastUpdate: "",
        riskSelected: [],
        channel: null,
        custPo: null,
        portalAmount: null,
        paymentStatus: null,
        tags: null,
        include_all: null,
        orders_for: null,
        excludeTax: null,
        excludeTaxChannel: [],
        missingProductAlerts: false,
        missingStyleDcList: null,
        orderConfirmation: false,
        orderDcList: null,
        notifyOrdersRisk: false,
        notifyOrderDcList: null,
      },
      mwsHeader: {
        id: null,
        rule_type: "order_preferences",
        mws_header_id: null,
      },
    };
  },
  created() {
    this.getEnums();
    this.getMws();
  },
  methods: {
    moment,
    async getEnums() {
      let $res = await EnumService.findAll({ domain: "excludetags" });
      console.log($res);
    },
    onlyNumberDays(evt){
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();
      if (!/^[+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    close() {
      this.showReadOnly = true;
    },
    async save() {
      this.mwsHeader.mws_header_id = this.mwsId;
      let $obj = Object.assign({}, this.orderPreferences);
      this.mwsHeader.preferences = JSON.stringify($obj);
      this.mwsHeader.rule_type = "order_preferences";
      if (this.mwsHeader && this.mwsHeader.id) {
        await MwsPreferenceService.update(this.mwsHeader);
        this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
        this.showReadOnly = true;
      } else {
        try {
          await MwsPreferenceService.create(this.mwsHeader);
          this.$store.dispatch("setSnackbar", { text: "Saved Successfully" });
          this.showReadOnly = true;
        } catch (e) {
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: "Something Went Wrong",
          });
        }
      }
    },
    async getMws() {
      let $result = await MwsPreferenceService.findAll({
        mws_header_id: this.mwsId,
        rule_type: "order_preferences",
      });
      if ($result && $result.length > 0) {
        $result = $result[0];
        let $obj = {};
        $obj.id = $result.id;
        $obj.preferences =
          $result.preferences && typeof $result.preferences == "string"
            ? JSON.parse($result.preferences)
            : {};
        $obj.rule_type = "order_preferences";
        this.mwsHeader = $obj;
        this.orderPreferences = this.mwsHeader.preferences;
      }
    },
    edit() {
      this.showReadOnly = false;
    },
  },
};
</script>
<style scoped>
.date-label {
  position: absolute;
  top: 144px;
  left: 33px;
  font-size: 13px;
  font-weight: 600;
  color: #000;
  opacity: inherit;
}
</style>

