<template>
  <v-from @submit.prevent="" ref="form">
    <v-card elevation="0" class="ma-6">
        <v-card-title class="dialog-Title">
            SUBSCRIPTIONS
            <v-btn density="comfortable" @click="this.$router.go(-1)" elevation="0" icon="mdi-close" style="float: right" ></v-btn>
            <v-btn class="button" elevation="0" style="float: right" type="submit" @click="save()" :disabled="showReadOnly"> Save </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
             <v-row>
              <v-row class="pt-6 ml-2">
                <v-col cols="4" class="pa-0 pl-2">
                  <AutoComplete :items="reportsData" label="Choose Report" :data="reportData.report_id" @textData="selectedValue($event)"   :text="'name'" :value="'id'" :disabled="showReadOnly"></AutoComplete>
                </v-col> 
                <v-col cols="4" class="pa-0 pl-2">
                  <InputBox :label="'Report Name'" :data="reportData.name" @textData="($event) => {reportData.name = $event;}" :rules="nameRule" :disabled="showReadOnly"></InputBox>
                </v-col>
                <v-col clos="2" class="pa-0 pl-2">
                  <AutoComplete  :label="'Export As'" :items="exportOption"   :text="'title'" :value="'title'" :disabled="showReadOnly"/>
                </v-col>
                <v-col cols="2" class="pt-5">
                    <v-switch label="Schedule my report" :data="switchValue" color="primary" v-model="switchValue" :disabled="showReadOnly"></v-switch>
                </v-col>
               </v-row> 
                <v-col cols="10" class="pa-0 pl-2 ml-2">
                  <InputBox :label="'Send Email to'" :data="reportData.email_to" @textData="($event) => {reportData.email_to = $event;}" :disabled="showReadOnly"></InputBox>
                </v-col>
                <v-row class="ml-2" v-if="switchValue">
                    <v-col class="pa-0 pl-2">
                        <!-- <InputBox  :label="'Schedule Date'" type="date" :data="reportData.schedule_at_date" @textData="($event) => {reportData.schedule_at_date = $event;}"></InputBox> -->
                     <DatePicker :label="'Schedule Date'" :data="reportData.schedule_at_date" @textData="($event) => {reportData.schedule_at_date = $event;}" :disabled="showReadOnly"/>
                    <!-- <span v-if="errorMsg" class="error">Start Date is Required</span>  -->
                    </v-col>
                    <v-col class="pa-0 pl-2">
                        <InputBox  :label="'Schedule Time'" type="time" :data="reportData.schedule_at_time" @textData="($event) => {reportData.schedule_at_time = $event;}" :disabled="showReadOnly"></InputBox>
                    </v-col>
                    <v-col class="pa-0 pl-2">
                        <InputBox  :label="'Interval'" type="number" :data="reportData.interval_at" @textData="($event) => {reportData.interval_at= $event;}" :disabled="showReadOnly"></InputBox>
                    </v-col>
                    <v-col class="pa-0 pl-2">
                        <AutoComplete  :label="'Frequency'" :items="frequencyOption" :text="'title'" :value="'title'"  :data="reportData.frequency" @textData="($event) => {reportData.frequency = $event;}" :disabled="showReadOnly"/>
                    </v-col> 
                    <v-row class="pa-2 pt-6">
                        <v-checkbox label="Sun" color="primary" v-model="selectDate" value="sunday" :disabled="showReadOnly"></v-checkbox>
                        <v-checkbox label="Mon" color="primary" v-model="selectDate" value="monday" :disabled="showReadOnly"></v-checkbox>
                        <v-checkbox label="Tue" color="primary" v-model="selectDate" value="tuesday" :disabled="showReadOnly"></v-checkbox>
                        <v-checkbox label="Wed" color="primary" v-model="selectDate" value="wednesday" :disabled="showReadOnly"></v-checkbox>
                        <v-checkbox label="Thu" color="primary" v-model="selectDate" value="thursday" :disabled="showReadOnly"></v-checkbox>
                        <v-checkbox label="Fri" color="primary" v-model="selectDate" value="friday" :disabled="showReadOnly"></v-checkbox>
                        <v-checkbox label="Sat" color="primary" v-model="selectDate" value="saturday" :disabled="showReadOnly"></v-checkbox>
                    </v-row>
                </v-row>
             </v-row>
        </v-card-text>
    </v-card>

    <v-row class="ma-3">
<v-col cols="2" class="pt-0">
  <v-card elevation="0" class="content-menu-cardleft">
            <v-list >
              <v-list-item    v-for="(item, index) in menuData"
              :key="index"
              :value="index"
              :class="{ selecteditem: item.isActive }"  class="pa-4 contentsidemenu" @click="showmenu(item,index)">
                <v-list-title>
                      {{ item.title }}
                </v-list-title>
              </v-list-item>
            </v-list>
        </v-card>
</v-col>
<v-col class="pt-0">
  <v-card elevation="0" class="content-menu-cardright">
            <SubscriptionLayout v-if="layOutView" :layoutData="this.layoutData" @savedlayoutData="savedData($event)" :layoutArray="layoutArray"></SubscriptionLayout>
            <Sorting v-if="sortingView" :sortingData="this.layoutData"  @sortColumn="getSort($event)" :data="this.getSortData"></Sorting>
            <Filter v-if="filterView" :filterData="this.filterData" @filterValues="filterValues($event)"></Filter>
        </v-card>
</v-col>
    </v-row>
  </v-from>
</template>
<script>
import SubscriptionLayout from "./SubscriptionLayout.vue";
import Sorting from "@/modules/setting/Reports/Sorting.vue";
import Filter from "@/modules/setting/Reports/Filter.vue";
import ReportSubscriptionService from "./services/ReportSubscriptionService";
import ListModelDetailService from "@/services/admin/ListModelDetailService";
import ListModelFilterDetailService from "@/services/admin/ListModelFilterDetailService";
import ReportService from "@/services/admin/ReportService";
import EnumService from "@/services/admin/EnumService";
import ListModelService from "@/services/admin/ListModelService";
import ListModelFilterService from "@/services/admin/ListModelFilterService";
import DatePicker from "@/components/DatePicker.vue";

export default{
    components:{
        Sorting,
        Filter, 
        SubscriptionLayout,
        DatePicker
    },
    data(){
        return{
          modelId: null,
          nameRule:[(value)=>(!!value)|| "Report Name Is Required"],
          selectDate:[],
          reportId: null,
          reportsData:[],
          isActive:false,
          layoutData:[],
          filterData:[],
          addScreen : false,
          filterView : true,
          sortingView : false,
          layOutView : false,
          reportData: {},
          switchValue: false,
          menuData: [
            {title:'FILTER',value:'1'},
            {title:'LAYOUT',value:'2'},
            {title:'SORTING',value:'3'}
          ],
          exportOption: [
            {title:'Excel',value:'1'},
            {title:'PDF',value:'2'},
          ],
          frequencyOption: [
            {title:'Hour',value:'1'},
            {title:'Month',value:'2'},
            {title:'Week',value:'3'},
          ],

          getSortData:[],
          layoutArray: [],
          showReadOnly: false
        }
    },
    created(){
      this.menuData[0].isActive = true;
       this.getReport();
       this.getById();
       if(this.menuData){
          this.menuData.forEach((e) => {
          e.isActive = false;});
       if (this.menuData.length > 0) {
          this.menuData[0].isActive = true;
        }
       if (this.$route.query.type == 'view'){
          this.showReadOnly =true
       }
       }
    },
    watch:{

    },
    methods:{
      getSort($event){
      this.reportData.sort_options = JSON.stringify($event);
    },
      filterValues($event){
         this.reportData.columns = JSON.stringify($event)
      },
      async getReport(){
          let res = await ReportService.findAll();
          this.reportsData = res;
        },
      async getById(){
            this.reportId = this.$route.query.id
            if (this.reportId){
                let res = await ReportSubscriptionService.findAll({id:this.reportId});
                this.reportData.name = res[0].name
                this.layoutArray = JSON.parse(res[0].export_options)
                this.reportData = res[0]
                this.reportData.schedule_at_date = new Date(res[0].schedule_at_date)
                this.selectDate = JSON.parse(res[0].schedule_day)
                this.getSortData = JSON.parse(res[0].sort_options)
                this.reportData.report_id = res[0].report_id
                this.switchValue = this.reportData.schedule_at_date ? true : false
                this.selectedValue(res[0].report_id)
            }
        },

      savedData($event){
        this.reportData.export_options = $event ? JSON.stringify($event.map((item) => item.column_name)):[];
      },

      async selectedValue($event){
        this.reportData.report_id = $event
        let obj = await this.reportsData.find(e => e.id === $event)
        let data_set_id = await obj ? obj.data_set_id : null 
        // getting Data value
        if(data_set_id){
          let res = await EnumService.findAll({id: data_set_id})
        // getting Model id
        let res2 = await ListModelService.findAll({model: res[0].data_value})
        // getting Model Datas
        this.modelId = await res2[0].id
        // Assign Layout Data
        let modelData = await ListModelDetailService.findAll({model_id: this.modelId})
        this.layoutData = await modelData
        console.log("layoutData",this.layoutData)
        // Assign FilterData
        let FilterData = await ListModelFilterService.findAll({model_id: this.modelId})
       
        this.filterData = await ListModelFilterDetailService.findAll({model_filter_id: FilterData[0].id})

        }
        else{
          this.filterData = [],
          this.layoutData = []
        }
      },


        async save(){
      //       let validation = await this.$refs.form.validate();
      // if (!validation.valid) {
      //   console.log(validation);
      // }
      // else{
        this.reportData.id = this.$route.query.id
        this.reportData.schedule_day = JSON.stringify(this.selectDate)
        if(this.reportData.id){
          this.reportData.exportOptions = JSON.stringify(this.reportData.exportOptions)
         await ReportSubscriptionService.update(this.reportData)
         this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
        }else{
         await ReportSubscriptionService.create(this.reportData)
         this.$store.dispatch("setSnackbar", { text: "Saved Successfully" });
        }

      // }
        },

        showmenu(value,index){
            this.filterView = value.value === '1'; 
            this.layOutView = value.value === '2';
            this.sortingView = value.value === '3';
            this.menuData.forEach((e) => (e.isActive = false));
            this.menuData[index].isActive = true;
        }
    }
}
</script>
<style scoped>
/* .content-menu-cardleft{
    width: 15%;
}
.content-menu-cardright{
    width: 80%;
} */
</style>