<template>
  <div class="publicLogin">
    <div class="text">
      <span style="font-weight: 400"> Re-Define </span><br />
      <span style="font-weight: 600">Your Digital Journey</span><br />
    </div>
    <div class="smalltext">
      Instant connectivity to world’s <br />
      leading marketplaces & E-commerce platforms<br />
      <!-- <div class="dotPadding">
        <span class="dot"></span>&nbsp; <span class="dot active"></span>&nbsp;
        <span class="dot"></span>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // screenHeight:"min-height:" +(screen.height-100)+'px'
    };
  },
};
</script>
<style scoped>
.dot {
  cursor: pointer;
  height: 10px;
  width: 10px;
  margin: 0 2px;
  background-color: #72749c;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active,
.dot:hover {
  background-color: rgb(255, 255, 255);
}
.dotPadding{
  padding-top: 0px;
}
.publicLogin {
  background-image: url("/public/images/publicLogin.png") !important;
  background-size: cover;
  min-height: 100vh;
  background-size:100%;
}
.publicLogin .text {
  position: absolute;
  width: 503px;
  height: 120px;
  left: 127px;
  top: 245px;
  font-family: 'Nunito' !important;
  font-size: 52px;
  line-height: 60px;
  color: #ffffff;
}
.publicLogin .smalltext {
  position: absolute;
  width: 585px;
  height: 60px;
  left: 127px;
  top: 389px;
  font-family: 'Nunito' !important;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
}
@media (max-width:480px) {
  .publicLogin {
  min-height: 250px !important;
}
.publicLogin .text {
    width: 200px !important;
    /* height: 120px; */
    left: 40px !important;
    top: 65px;
    font-size: 15px !important;
    line-height: 20px !important;
}
.publicLogin .smalltext {
     position: absolute;
    width: 157px !important;
    height: 60px;
    left: 40px !important;
    top: 105px !important;
    font-size: 9px !important;
    line-height: 12px !important;
}
}
@media only screen and (min-width:480px) and (max-width: 600px)  {
   .publicLogin {
  min-height: 250px !important;
}

.publicLogin .text {
    width: 200px !important;
    /* height: 120px; */
    left: 50px !important;
    top: 85px;
    font-size: 22px !important;
    line-height: 20px !important;
}
.publicLogin .smalltext {
     position: absolute;
    width: 230px !important;
    height: 60px;
    left: 52px !important;
    top: 135px !important;
    font-size: 9px !important;
    line-height: 14px !important;
}
}
@media only screen and (min-width:600px) and (max-width: 900px)  {
   .publicLogin {
  min-height: 350px !important;
}

.publicLogin .text {
   width: 300px !important;
    left: 50px !important;
    top: 120px;
    font-size: 30px !important;
    line-height: 35px !important;
}
.publicLogin .smalltext {
    position: absolute;
    width: 230px !important;
    /* height: 60px; */
    left: 52px !important;
    top: 190px !important;
    font-size: 12px !important;
    line-height: 20px !important;
}
}
@media only screen and (min-width:900px) and (max-width: 1200px)  {
   .publicLogin {
  min-height: 550px !important;
}

.publicLogin .text {
      width: 460px !important;
    left: 100px !important;
    top: 150px;
    font-size: 45px !important;
    line-height: 60px !important;
}
.publicLogin .smalltext {
        position: absolute;
    width: 415px !important;
    /* height: 60px; */
    left: 100px !important;
    top: 272px !important;
    font-size: 18px !important;
    line-height: 36px !important;
}
}
</style>