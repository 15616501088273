<template>
    <v-col class="ml-2 settingHeader"><v-icon color="#000000" class="mr-2" @click="$router.go(-1)">mdi-arrow-left-circle-outline</v-icon>Product Settings </v-col>
    <v-row class="pa-5 pt-0 contentsubmenu">
        <v-card elevation="0" class="content-menu-cardleft">
            <v-list class="content-menu-list" style="height: 700px;">
                <v-list-item :to="item.router" class="pa-4 contentsidemenu" :class="{ contentborder: item.isActive }" v-for="(item, index) in productData" @click="scrollUp()"
                    :key="index" :value="index" active-class="selecteditem">
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-card>
        <v-card elevation="0" class="pa-2 content-menu-cardright">
            <RouterView></RouterView>
        </v-card>
    </v-row>
    
</template>

<script>
export default {
    data() {
        return {
            isActive: false,
            setting: 0,
            admin: true,
            product: false,
            productData: [
                {
                    value: 1,
                    name: 'ProductType',
                    router: '/productsetting/producttype',
                },
                {
                    value: 2,
                    name: 'Category',
                    router: '/productsetting/category',
                },
                {
                    value: 3,
                    name: 'SubCategory',
                    router: '/productsetting/subcategory',
                    isActive:true,
                },
                {
                    value: 4,
                    name: 'Brand',
                    router: '/productsetting/brand',
                },
                {
                    value: 5,
                    name: 'SBU',
                    router: '/productsetting/sbu',
                    isActive:true,
                },
                {
                    value: 6,
                    name: 'Attribute',
                    router: '/productsetting/attribute',
                },
                // {
                //     value: 7,
                //     name: 'Color',
                //     router: '/setting/color',
                // },
                // {
                //     value: 8,
                //     name: 'Material',
                //     router: '/setting/material',
                // },
                // {
                //     value: 9,
                //     name: 'Size',
                //     router: '/setting/size',
                // },
                // {
                //     value: 10,
                //     name: 'UOM',
                //     router: '/productsetting/uom',
                // },
                // {
                //     value: 11,
                //     name: 'Season',
                //     router: '/setting/season',
                // },
                // {
                //     value: 12,
                //     name: 'Consumer',
                //     router: '/setting/consumer',
                // },
                // {
                //     value: 13,
                //     name: 'Pricing Template',
                //     router: '/productsetting/pricingtemplate',
                // },
                // {
                //     value: 14,
                //     name: 'Freight Template',
                //     router: '/productsetting/freighttemplate',
                // },
            ]
        }
    },
    created() {
        let $lastRoute = localStorage.getItem("settingLst")
        if ($lastRoute) {
            this.$router.push($lastRoute)
        }
        // else {
        //     this.$router.push('/setting/user')
        // }
    },
    methods: {
        // adminOpen() {
        //     this.setting = 0
        //     this.product = false
        //     this.admin = true
        //     this.$router.push('/setting/user')
        // },
        // productOpen() {
        //     this.setting = 1
        //     this.product = true
        //     this.admin = false
        //     this.$router.push('/setting/producttype')
        // },
        scrollUp(){
            window.scrollTo(0, 0);
        }
    }
}
</script>
<style lang="scss">
.contentborder{
    border-bottom: 1px solid rgb(225, 225, 225);
}
</style>