<template>
  <v-card elevation="0">
    <v-form @submit.prevent="save()" ref="form">
  <v-card-title class="settingHeader">ERP Profile
    <v-btn density="comfortable" @click="this.close()" elevation="0" icon="mdi-close" style="float: right" v-if="!showReadOnly"></v-btn>
    <v-btn class="button" elevation="0" style="float: right" type="submit" v-if="!showReadOnly"> Save </v-btn>
    <v-btn class="button" elevation="0" style="float: right" @click="edit()" v-if="showReadOnly"> Edit </v-btn>
  </v-card-title>
  <v-divider></v-divider>
<v-card-text>
    <v-row no-gutters >
      <v-col cols="6" class="pa-0 pl-2">
        <!-- <AutoComplete class="ma-2" :items="erpData" label="Erp"   ></AutoComplete> -->
        <EnumDropdown
            :disabled="showReadOnly"
            :label="'Erp '"
            :data="formData.erp"
            @textData="checkData($event)"
            :domainName="'ERP'"
            :rules="erpRule"
          />
          <span v-if="dataerror" class="error">Please Update the Data's in Channels </span>
      </v-col>
      <v-col cols="6" class="pa-0 pl-2">
        <!-- <AutoComplete class="ma-2" :items="items" label="Integration Type"   ></AutoComplete> -->
        <EnumDropdown
            :disabled="showReadOnly"
            text="display_value"
            :value="'data_value'"
            :label="'Integration Type '"
            v-model="formData.integration_type"
            :data="formData.integration_type"
            @textData="selectType($event)"
            :domainName="'integrationtype'"
            :rules="typeRule"
          />
      </v-col>
      <v-col cols="6" v-for="items in inputBoxData" :key="items.label" class="pa-0 pl-2">
        <InputBox :label="items.label" :data="items.key" @textData="($event) => items.key = $event" :disabled="showReadOnly"></InputBox>
      </v-col>
      <v-col cols="12" class="pa-0">
        <!-- <TextArea class="ma-2" :label="'DB String'" :rules="DBStringRule"></TextArea> -->
        <QuilEditor
                    :showReadOnly="showReadOnly"
                    :data="formData.description"
                    :editorStyle="'height:200px;overflow: auto'"
                    :lable="'Description'"
                    @textData="
                      ($event) => {
                        formData.description = $event;
                      }
                    "
       ></QuilEditor>
      </v-col>
    </v-row>
  </v-card-text> 
</v-form>
</v-card>
</template>
<script>
import QuilEditor from "@/components/QuilEditor.vue";
import ChannelService from "@/services/admin/ChannelService";
import ErpProfileService from "@/services/admin/ErpProfileService";
export default {
    components: {
      QuilEditor,
    },
    data(){
return{
  showReadOnly: true,
  dataerror: false,
  inputBoxData:[],
  channelData:[],
  companyData:[],
  formData:{},
    typeRule:[(value) => !! value ||"Integration Type is Required"],
    erpRule:[(value) => !! value ||"ERP is Required"],
    URLRule:[(value) => !! value ||" URL is Required"],
    companyRule:[(value) => !! value ||"Company is Required"],
    DBStringRule:[(value) => !! value || "DB String is Required"]
}
    },
created(){
  this.showReadOnly = true
  this.getCompany();
  this.getErpData();
},
    methods:{
      async getCompany(){
        let company_details = JSON.parse(localStorage.getItem('companyDetails'))
        this.formData.company = company_details.code
        this.formData.company_id = company_details.id
      },
      async getErpData(){
        let res = await ErpProfileService.findAll({company_id : this.formData.company_id});
        console.log("geterp",res)
        this.formData = res[0];
        this.formData.erp = res[0].erp;
        this.formData.integration_type = res[0].integration_type;
        this.inputBoxData = JSON.parse(res[0].configuration)
      },
      async selectType($event){
        this.formData.integration_type = $event;
        let res = await ErpProfileService.findAll({erp : this.formData.erp, integration_type : this.formData.integration_type})
        console.log("resss",res)
        if(res.length!=0){
          this.formData = res[0]
          this.inputBoxData = JSON.parse(res[0].configuration)
        }
        else{
          this.formData.integration_type = $event
        }
      },
      async checkData($event){
         this.formData.erp = $event;

         let res = await ErpProfileService.findAll({erp: this.formData.erp})

         if (res.length > 0){
          this.dataerror = false;
          this.formData.erp = res[0].erp;
          this.inputBoxData = JSON.parse(res[0].configuration)
         }
 
        // Initialize inputBoxData as an empty array if it's null
        //  if (!this.inputBoxData) {
        //     this.inputBoxData = [];
        // }

else{
  let res = await ChannelService.findAll({ type: 'api', name: this.formData.erp });

if (res.length > 0) {
  this.dataerror = false;
  let jsonData = await ChannelService.yamlToJson({ id: res[0].id });
  console.log("jsonData", jsonData);

if (jsonData.parameters) {
  for (let i = 0; i < jsonData.parameters.length; i++) {
  const obj = jsonData.parameters[i];
  let key = Object.keys(obj)[0];
  let data = {
       label: obj[key].label,
       key: ''
  };
  console.log("88888", data);
  this.inputBoxData.push(data);
}
}
} 
else {
  this.dataerror = true;
  this.inputBoxData = null;
}

}
      },
      async save(){
        this.formData.configuration = JSON.stringify(this.inputBoxData)

        delete this.formData.users

        let validation = await this.$refs.form.validate();
      if (!validation.valid || this.codeError) {
        console.log("Not valid");
      }
        else{
          if(!this.dataerror){
            if( this.formData && this.formData.id){
            await ErpProfileService.update(this.formData)
            this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
            this.close();
          }
        else{
          try{
          await ErpProfileService.create(this.formData);
          this.$store.dispatch("setSnackbar", { text: "Saved Successfully" });
          this.formData = {};
          this.close()
          }
          catch (e) {
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "Something Went Wrong",
            });
        }
        }
          }
      }
      },
      edit(){
        this.showReadOnly = false;
      },
      close(){
        this.showReadOnly = true;
        this.getErpData();
      }

    }
}
</script>
<style>
</style>