<template>
    <v-row no-gutters dense class="pl-3">
        <v-col cols="12">
            <Strong>Accounts</Strong>
        </v-col>
        <v-col cols="12">
            <v-table class="elevation-0 dataTable">
                <thead>
                    <tr>
                        <th class="text-left">
                            Code
                        </th>
                        <th class="text-left">
                            Name
                        </th>
                        <th class="text-left">
                            Marketplaces
                        </th>
                        <th class="text-left">

                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in accounts" :key="item">
                        <td> <b class="link">{{ item.code }}</b></td>
                        <td>
                            <v-chip v-if="item.name">
                                {{ item.name }}
                            </v-chip>
                        </td>
                        <td>
                            {{ item.mws_header_id }}
                        </td>
                        <td class="pa-2">
                            <v-btn @click="selectAccount(item)" class="save">Select</v-btn>
                        </td>
                    </tr>
                </tbody>
            </v-table>
        </v-col>
    </v-row>
</template>
<script>
import AccountService from '@/services/admin/AccountService';
// import { authStore } from "@/store";
export default {
    data() {
        return {
            accounts: [],
        };
    },
    created() {
        this.getAccounts();
    },
    methods: {
        async getAccounts() {
            await AccountService.findAllByCompanyXref()
                .then((response) => {
                    this.accounts = response;
                    if (this.accounts && this.accounts.length > 1) {
                        for (let i = 0; i < this.accounts.length; i++) {
                            this.accounts[i].mws_header_id = JSON.parse(this.accounts[i].mws_header_id)
                            this.accounts[i].mws_header_id = this.accounts[i].mws_header_id.join(',')
                        }
                    }
                    else {
                        if (this.accounts && this.accounts.length > 0) {
                            localStorage.setItem("_ACCOUNT_ID_", this.accounts[0].id);
                            let status = this.$store.state.accountStatus;
                            this.$store.commit('setAccountStatus', !status)
                            this.$router.push('/')
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        selectAccount(data) {
            console.log(data, 'accounttttttt')
            localStorage.setItem("_ACCOUNT_ID_", data.id);
            let status = this.$store.state.accountStatus;
            this.$store.commit('setAccountStatus', !status)
            this.$router.push('/')
        }
    }
}
</script>
<style scoped>
.save {
    background-color: #3C5AAA;
    color: #fff;
    width: 90px;

}
</style>