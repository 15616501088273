import ApiService from '@/api/service/ApiService.js';
import apiConfig from '../config/config'

var $options = {};
var SwitchAccountService = {
    findAll: function ($data) {
        return new Promise(function (resolve, reject) {
            if($data) { 
                $options.path='switchAccount'
            } 
            $options.baseUrl=apiConfig.baseUrl
            $options.method = "GET"
            $options.data = $data
            ApiService.request($options)
                .then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error);
                })
        });

    },






};
export default SwitchAccountService;