<template>
    <v-row>
        Add Flow
    </v-row>
    <v-row>
          <v-col
            cols="12"
            sm="6"
            md="6"
          >
            <v-radio-group
              v-model="flowType"
              column
            >
              <v-radio  v-for="task in taskFlows" :key="task" @change="sendParent(task.id)"
                :label="task.name"
                :color="task.color"
                :value="task.id"
              ></v-radio>
            </v-radio-group>
          </v-col>
    </v-row>
</template>
<script>
export default {
    props:["setFlowType"],
    data(){
        return{
            flowType:null,
            taskFlows:[
                {
                    id:"condition",
                    name:"Condition",
                    color:"info"
                },
                {
                    id:"task",
                    name:"Task",
                    color:"orange"
                },
                {
                    id:"taskapproval",
                    name:"Task Approval",
                    color:"secondary"
                },
                {
                    id:"end",
                    name:"End Flow",
                    color:"success"
                }
            ]
        }
    },
    watch:{
        setFlowType(){
            this.flowType = this.setFlowType;
        }
    },
    created(){
        if(this.setFlowType){
            this.flowType = this.setFlowType;
        }
    },
    methods:{
        sendParent($val){
            this.$emit('selection',$val)
        }
    }
}
</script>