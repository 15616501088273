<template>
    <v-row><v-col cols="12" v-for="item in items" :key="item" xs="4" sm="5" md="4" lg="3" xl="2"
    >
    <v-card :loading="loading" class="mx-4 my-4 rounded-lg" max-width="330" >
    <template v-slot:loader="{ isActive }">
      <v-progress-linear
        :active="isActive"
        color="deep-purple"
        height="4"
        indeterminate
      ></v-progress-linear>
    </template>

    <v-img class="pa-4" cover height="300" :src="item.img"></v-img>

    <v-card-item>
      <v-card-title
        >AirPode Max
        <span align="end" class="pl-16">$240.00</span></v-card-title
      >

      <v-card-subtitle class="pt-1">
        <span class=""> Table with air purifier,stained venner/black</span>

        <v-icon color="error" icon="mdi-fire-circle" size="small"></v-icon>
      </v-card-subtitle>
    </v-card-item>

    <v-card-text class="pt-1">
      <v-row align="center" class="mx-0">
        <v-rating
        class="pt-1"
          :model-value="4.5"
          color="amber"
          density="compact"
          half-increments
          readonly
          size="small"
        ></v-rating>

        <div class="text-grey ms-4">4.5 (413)</div>
      </v-row>

      <!-- <div class="my-4 text-subtitle-2">$ 240.00</div> -->
    </v-card-text>
    <v-card-actions>
      <v-btn
        prepend-icon="mdi-plus-outline"
        color="#FF8A80"
        variant="outlined" 
        @click="reserve"
      >
        Add to Cart
      </v-btn>
      <span class="pl-2">
        <v-btn
          color="#5C6BC0"
          variant="outlined"
          prepend-icon="mdi-cart-minus" 
          @click="view"
        >
         Buy Now
        </v-btn></span
      >
    </v-card-actions>
  </v-card>
    </v-col></v-row>
 
</template>
  <script>
export default {
  data: () => ({
    loading: false,
    selection: 1,
    items:[
        {img:"https://www.apple.com/v/airpods-max/e/images/overview/design_colors_silver_front__g8c839jaldqy_xlarge.jpg"},
        {img:"https://www.apple.com/v/airpods-max/e/images/overview/design_colors_pink_front__dbqafvrvcy6a_xlarge.jpg"},
        {img:"https://www.apple.com/v/airpods-max/e/images/overview/design_colors_green_front__cqpeugza9as2_xlarge.jpg "},
        {img:"https://www.apple.com/v/airpods-max/e/images/overview/design_colors_blue_front__ddfias5frxqq_xlarge.jpg "},
        {img:"https://www.apple.com/v/airpods-max/e/images/overview/design_colors_gray_front__bgkzj4cnbafm_xlarge.jpg "},
        {img:"https://www.apple.com/v/airpods-max/e/images/overview/design_colors_green_front__cqpeugza9as2_xlarge.jpg "},
        {img:"https://www.apple.com/v/airpods-max/e/images/overview/design_colors_silver_front__g8c839jaldqy_xlarge.jpg"},
        {img:"https://www.apple.com/v/airpods-max/e/images/overview/design_colors_pink_front__dbqafvrvcy6a_xlarge.jpg"},
        {img:"https://www.apple.com/v/airpods-max/e/images/overview/design_colors_green_front__cqpeugza9as2_xlarge.jpg "},
        {img:"https://www.apple.com/v/airpods-max/e/images/overview/design_colors_blue_front__ddfias5frxqq_xlarge.jpg "},
        {img:"https://www.apple.com/v/airpods-max/e/images/overview/design_colors_gray_front__bgkzj4cnbafm_xlarge.jpg "},
        {img:"https://www.apple.com/v/airpods-max/e/images/overview/design_colors_green_front__cqpeugza9as2_xlarge.jpg "},
    ]
  }),

  methods: {
    reserve() {
      this.loading = true;

      setTimeout(() => (this.loading = false), 2000);
    },
 
    view(){
      this.$router.push("/productview");
    }

  },
};
</script>