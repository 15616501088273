<template>
  <v-card elevation="1">
    <v-card-text class="pa-0">
      <v-row class="pa-6 " style="position:relative;top:15px">
        <v-col class="pa-0 pl-2">
          <AutoComplete
            :data="listModelData.model"
            @textData="getDetails($event,true)"
            :rules="modelRule"
            :label="'Model'"
            :disabled="showReadOnly"
            :items="listModels"
            :text="'model'"
            :value="'id'"
          />
        </v-col>
        <!-- <v-btn
          class="mt-4"
          color="indigo"
          density="compact"
          icon="mdi-plus"
          @click="addModel()"
        ></v-btn> -->
        <v-col class="pa-0 pl-2">
          <InputBox
            :data="listModelData.name"
            @textData="($event) => {listModelData.name = $event;}"
            :rules="nameRule"
            :label="'Name'"
            :disabled="true"
          />
        </v-col>
        <v-col align="end">
          <v-btn
            density="comfortable"
            @click="closeDialog"
            elevation="0"
            icon="mdi-close"
          >
          </v-btn>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="pa-3" style="min-height: 80vh">
        <v-col cols="3" v-if="tab==1">
          <h4>Field</h4> 
          <v-list class="content-menu-list">
            <v-list-item
              :to="item.router"
              class="pa-4 contentsidemenu"
              v-for="(item, index) in listDetails"
              @click="scrollUp(index, item)"
              :key="index"
              :value="index"
              :class="{ selecteditem: item.isActive }"
            >
              <v-list-item-title>{{ item.label }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-col>
        <v-divider vertical v-if="tab==1"></v-divider>
        <v-col>
          <v-tabs v-model="tab" color="indigo">
            <v-tab value="1">Columns</v-tab>
            <v-tab value="2">Layouts</v-tab>
          </v-tabs>
          <v-card-text>
            <v-window v-model="tab">
              <v-window-item value="1">
                <ListModelColumn
                  :selectedColumn="isSelectColumn"
                  :listModelData="listModelData"
                  :showReadOnly="showReadOnly"
                />
              </v-window-item>
              <v-window-item value="2">
                <ListModelLayout :showReadOnly="showReadOnly" ref="ListModelLayout" :columns="listDetails" />
              </v-window-item>
            </v-window>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
  <v-dialog v-model="dialog" :width="600">
    <AddModel @close="close" />
  </v-dialog>
</template>

<script>
import ListModelColumn from "./ListModelColumn.vue";
import ListModelLayout from "./ListModelLayout.vue";
import AutoComplete from "@/components/AutoComplete.vue";
import InputBox from "@/components/InputBox.vue";
import AddModel from "./AddModel.vue";
import ListModelService from "@/services/admin/ListModelService";
import ListModelDetailService from "@/services/admin/ListModelDetailService";
export default {
  components: {
    AutoComplete,
    InputBox,
    AddModel,
    ListModelColumn,
    ListModelLayout,
  },
  data() {
    return {
      layout: [],
      tab: null,
      dialog: false,
      isSelectColumn: {},
      listModelData: {
        model: null,
        name: null,
      },
      listDetails: [],
      modelRule: [(value) => !!value || "Model is Required"],
      nameRule: [(value) => !!value || "Name is Required"],
      listModels: [],
      showReadOnly: false,
    };
  },
  created() {
    this.getModels();
  },
  methods: {
    async getModels() {
      let $data = await ListModelService.findAll({});
      this.listModels = $data && $data.length > 0 ? $data : [];
      console.log(this.listModels,'data')
      if (this.$route.query.id) {
        this.listModelData.model = parseInt(this.$route.query.id);
        this.getDetails(this.listModelData.model,false)
      }
      if (this.$route.query.type) {
        this.showReadOnly = this.$route.query.type=='view';
      }
    },
    async getDetails(value,$flag) {
      this.listModelData.model = value
      if($flag && this.$route.query.type){
        this.$router.push(
          `/channel/listmodel/add?id=${this.listModelData.model}&type=${this.$route.query.type}`
        );
      }
      let $data = await ListModelDetailService.findAll({
        model_id: this.listModelData.model,
      });
      this.listDetails = $data && $data.length > 0 ? $data : [];
      let $name = this.listModels.filter(
        (k) => k.id == this.listModelData.model
      );
      this.listModelData.name =
        $name && $name.length > 0 ? $name[0].model : null;
      this.listDetails.forEach((e) => {
        e.isActive = false;
      });
      if (this.listDetails.length > 0) {
        this.listDetails[0].isActive = true;
        this.isSelectColumn = this.listDetails[0];
      }
    },
    scrollUp(index, item) {
      this.listDetails.forEach((e) => (e.isActive = false));
      this.listDetails[index].isActive = true;
      this.isSelectColumn = item;
      window.scroll({
        top: 0,
        behavior: 'instant'
      })
    },
    closeDialog() {
      this.$router.push("/channel/listmodel");
    },
    close() {
      this.dialog = false;
    },
    addModel() {
      this.dialog = true;
    },
  },
};
</script>

<style>
.listcard {
  flex-wrap: wrap;
  min-height: 70vh;
  align-self: normal;
}
</style>