<template>
    <v-label class="commonLabel ml-1" v-if="label">{{ label }}</v-label>
    <vue-date-picker v-model="value" auto-apply
              :format="'dd/MM/yyyy'"
              :class="errorColor ? 'datepickerror' :'datePicker'"
              :min-date="minDate"
              :max-date="maxDate"
              @update:model-value="changeText($event)"
              :placeholder="label || placeholder"
              :disabled="disabled"></vue-date-picker>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
export default {
    props: ['label', 'icon', 'rules', 'disabled','placeholder','data','min','Datetype','minDate','maxDate','errorColor'],
    components: {
        VueDatePicker
    },
    data() {
        return {
            value:null,
            errorMsg: false,
            datelimit: null,
        }
    },
    created(){
        if(this.data){
        this.value =this.data
       }else{
        this.value = null
       }
    },
    watch:{
          data(){
                if(this.data){
                      this.value =this.data
                }else{
                    this.value = null;
                }
          },
          min(){
              this.setDates();
            }
          
    },
    methods:{
        changeText($event) { 
                this.$emit('textData', $event)
                // if (!$event){
                //     this.errorMsg = true;
                //     this.$emit("errorMsg")
                // }
                // else{
                //     this.errorMsg = false;
                // }
          },
          setDates(){
            if (this.Datetype == 'start'){
                this.datelimit = this.min
                this.datelimit.setDate(this.datelimit.getDate()-1)
            }
            else{
                this.datelimit = this.min
            }
          }
    }
    
        }

</script>
<style lang="scss">
.datePicker{
.dp__input {
    background: #ececec;
    border-radius: 8px;
    min-height: 44px;
    border: 1px solid  #e3e3e3;
    font-size: 13px;
    font-weight: 500;
}.dp__input:hover {
    border-color: #e3e3e3 !important;
}.dp__input:focus-within{
    border: 2px solid #3C5AAA !important;
    background-color: #ffffff !important;
}.dp__clear_icon {
    display: none !important;
}
.dp__input_icon_pad {
     padding-inline-start:15px !important;
}.dp__input_icon {
    inset-inline-end: 0 !important;
    inset-inline-start: unset !important;
}.dp__disabled {
    opacity: 0.6;
}
.error{
    color: #b00020;
    padding-top: 0px;
    margin-top: 0px;
    padding-left: 5px;
    padding-bottom: 10px;
    margin-bottom: 5px;
    font-size: 12px;
  }
}
.datepickerror{
    .dp__input {
    background: #ececec;
    border-radius: 8px;
    min-height: 44px;
    border: 2px solid  #b00020;
    font-size: 13px;
    font-weight: 500;
}
    .dp__clear_icon {
    display: none !important;
}
.dp__input_icon_pad {
     padding-inline-start:15px !important;
}.dp__input_icon {
    inset-inline-end: 0 !important;
    inset-inline-start: unset !important;
}.dp__disabled {
    opacity: 0.6;
}
}
</style>