<template>
  <v-card>
    <v-form @Submit.prevent="save" ref="form">
      <v-card-title class="dialog-Title"
        >{{
          type == "edit"
            ? "Edit"
            : type == "view"
            ? "View"
            : type == "add"
            ? "Add"
            : type == "select"
            ? "View"
            : ""
        }}
        Division
        <v-btn
          density="comfortable"
          @click="closeDialog"
          elevation="0"
          icon="mdi-close"
          style="float: right"
        ></v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="6" class="pa-0">
              <InputBox
                :label="'Code'"
                :disabled="showReadOnly"
                :rules="codeRules"
                :data="formData.code"
                @textData="checkCode($event)"
                @keydowninput="prevent"
              />
              <span v-if="codeError" class="error-field">Already Exists!</span>
              <!-- <v-text-field  v-model="formData.code" :rules="codeRules" density="compact"   variant="outlined" class="text-Field" @keyup="checkCode()"  >
        </v-text-field> -->
            </v-col>
            <v-col cols="6" class="pl-2 pa-0">
              <InputBox
                :label="'Erp Key'"
                :disabled="showReadOnly"
                :data="formData.erp_key"
                @textData="
                  ($event) => {
                    formData.erp_key = $event;
                  }
                "
                :rules="ErpKeyRules"
              />
            </v-col>
            <v-col cols="12" class="pa-0">
              <InputBox
                :label="'Name'"
                :disabled="showReadOnly"
                :data="formData.name"
                @textData="
                  ($event) => {
                    formData.name = $event;
                  }
                "
                :rules="NameRules"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="dialogfooter" v-if="!showReadOnly">
        <v-spacer></v-spacer>
        <v-btn class="mr-1 cancel" @click="closeDialog">Cancel</v-btn>
        <v-btn class="save" type="submit">Save</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import InputBox from "@/components/InputBox.vue";
import DivisionService from "@/services/admin/DivisionService.js";
export default {
  props: ["type", "id"],
  components: {
    InputBox,
  },
  data() {
    return {
      codeError: false,
      codeExistFlag: false,
      picked: new Date(),
      codeRules: [(v) => !!v || "Code is Required"],
      ErpKeyRules: [(value) => !!value || "Erp Key is Required"],
      NameRules: [(value) => !!value || " Name is Required"],
      formData: {
        code: null,
        name: null,
        erp_key: null,
      },
      DivisionData: [],
      showReadOnly: false,
    };
  },
  created() {
    if (this.id) {
      this.getEditData();
    }

    if (this.type == "view" || this.type == "select") {
      this.showReadOnly = true;
    }
  },
  methods: {
    async getEditData() {
      let $res = await DivisionService.findAll({ id: this.id });
      this.formData = $res[0];
      console.log(this.formData, "formData");
    },

    closeDialog() {
      this.$emit("closeDialog");
    },

    prevent(e) {
      if (!/^[a-zA-Z0-9-_ \t]+$/.test(e.key) && e.key !== "Backspace") {
        e.preventDefault();
      }
    },
    async checkCode($event) {
      this.formData.code = $event;
      if (this.formData.code && this.formData.code.length > 0) {
        this.formData.code = this.formData.code.toUpperCase();
        await DivisionService.findAll({ code: this.formData.code }).then(
          (res) => {
            let $exist = res.filter((key) => key.code == this.formData.code);
            this.codeExistFlag =
              $exist.length > 0
                ? this.formData.id && $exist[0].id == this.formData.id
                  ? false
                  : true
                : false;

            if (this.codeExistFlag) {
              this.codeError = true;
            } else {
              this.codeError = false;
            }
          }
        );
      }
    },
    async save() {
      console.log(this.formData, "data", this.$refs.form.items);
      let validation = await this.$refs.form.validate();
      if (!validation.valid || this.codeError) {
        console.log(validation);
      } else {
        if (this.formData && this.formData.id) {
          delete this.formData.users;
          let output = await DivisionService.update(this.formData);
          this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });

          console.log(output);
          this.closeDialog();
        } else {
          try {
            let output = await DivisionService.create(this.formData);
            this.$store.dispatch("setSnackbar", { text: "Saved Successfully" });
            console.log(output, "output", this.formData);
            this.closeDialog();
          } catch (e) {
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "Something Went Wrong",
            });
          }
        }
      }
    },
  },
};
</script>
