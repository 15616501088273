<template>
   <div class="container">
<form role="search" method="get" class="search-form" action="">
	<label>
		<input @keyup="$emit('primeSearch',search)" type="search" class="search-field" placeholder="Search …" v-model="search" name="s" title="Search for:" />
	</label>
	<input @keyup="$emit('primeSearch',search)" type="submit" class="search-submit" v-model="search" />
</form>
</div>
</template>
<script>
export default{
   data(){
      return{
         search:null
      }
   }
}
</script>
