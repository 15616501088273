<template>
  <v-card class="cards">
    <v-card-title>
      <v-row>
        <v-col cols=3>
          Info
        </v-col>
        <v-col cols=9 style="text-align:end">
          <v-btn density="comfortable" @click="closeDialog()" elevation="0" icon="mdi-close"
            style="float: right;"></v-btn>
        </v-col>
      </v-row>
      <!-- <div class="row">
             <div class="col-12 col-md-10 col-xl-10 col-sm-10 col-xs-10 text-h5" >Info</div>
             <div class="col-12 col-md-2 col-xl-2 col-sm-2 col-xs-2" style="text-align:end;margin-top:10px" @click="closeDialog"><CloseIcon /></div>
          </div> -->
    </v-card-title>
    <!-- <v-card-section class="q-pt-none"> -->
    <v-card-text>
      <v-row v-if="showFirst">
        <div class="pa-4">
          <!-- <div class="col q-mt-sm "> -->
          Do you have an Ecommerce Sales Channels ?
          <br />
          <v-radio-group v-model="project.channel" row>
            <v-radio value="Yes" label="Yes" @click="open('1')" />
            <v-radio value="No" label="No but planning to" @click="open('1')" />
          </v-radio-group>
        </div>
      </v-row>
      <v-row v-if="showSecond">
        <!-- <div class="col q-mt-sm"> -->
        <div class="pa-4">
          Do you sells your prodcuts from ?
          <br />
          <v-radio-group v-model="project.website" row>
            <v-radio value="Own Website" label="Own Website" @click="open('2')" />
            <v-radio value="Marketplaces" label="Marketplaces" @click="open('2')" />
            <v-radio value="Both" label="Both" @click="open('2')" />
          </v-radio-group>
        </div>
      </v-row>
      <v-row v-if="project.website == 'Own Website' && showThird">
        <!-- <div class="col q-mt-sm"> -->
        <div class="pa-4">
          Do you use any ecommece Platform ?
          <br />
          <v-radio-group v-model="project.platform" row>
            <v-radio value="Shopify" label="Shopify" @click="open('3')" />
            <v-radio value="E-commerce" label="E-commerce" @click="open('3')" />
            <v-radio value="Others" label="Others" @click="open('3')" />
          </v-radio-group>
        </div>
      </v-row>
      <v-row v-if="project.website == 'Own Website' && showFourth">
        <!-- <div class="col q-mt-sm"> -->
        <div class="pa-4">
          Do you use Marketplaces ?
          <br />
          <v-radio-group v-model="project.marketplaces" row>
            <v-radio value="Amazon" label="Amazon" @click="open('4')" />
            <v-radio value="Ali Express" label="Ali Express" @click="open('4')" />
            <v-radio value="Walmart" label="Walmart" @click="open('4')" />
          </v-radio-group>
        </div>
      </v-row>
      <v-row v-if="showFive">
        <!-- <div class="col q-mt-sm"> -->
        <div class="pa-4">
          Do you need to integrate with your ERP ?
          <br />
          <v-radio-group v-model="project.needErp" row>
            <v-radio value="Yes" label="Yes" @click="open('5')" />
            <v-radio value="No" label="No" @click="open('5')" />
          </v-radio-group>
        </div>
      </v-row>
      <v-row v-if="project.needErp == 'Yes' && showSix">
        <!-- <div class="col q-mt-sm"> -->
        <div class="pa-4">
          Which erp do you use ?
          <br />
          <v-radio-group v-model="project.erp" row>
            <v-radio value="Zoho" label="Zoho" @click="open('6')" />
            <v-radio value="Oracle Ebusiness suite" label="Oracle Ebusiness suite" @click="open('6')" />
            <v-radio value="Nebiem" label="Nebiem" @click="open('6')" />
            <v-radio value="Others" label="Others" @click="open('6')" />
          </v-radio-group>
        </div>
      </v-row>
      <div v-if="showDetails">
        <v-row>
          <v-col cols="10">
            <InputBox :placeholder="'Email'" :data="project.sender_email"  @textData="($event) => {project.sender_email = $event;}" 
            :rules="emailRule" />
            <!-- <PlainText
                  :required="true"
                  :value="project.sender_email"
                  label="Email"
                  v-on:changed="
                    ($value) => {
                      project.sender_email = $value;
                    }
                  "
                /> -->
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="10">
            <InputBox :placeholder="'Mobile'" :data="project.mobile"  @textData="($event) => {project.mobile = $event;}" :rules="mobileRule" />
            <!-- <PlainText
                  :required="true"
                  :value="project.mobile"
                  label="Mobile"
                  v-on:changed="
                    ($value) => {
                      project.mobile = $value;
                    }
                  "
                /> -->
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="10">
            <!-- <v-text-field
                v-model="project.description"
                autogrow
                outlined
                label="Description"
              /> -->
            <InputBox :placeholder="'Description'" :data="project.description"  @textData="($event) => {project.description= $event;}" :rules="descriptionRule" />
            <!-- <PlainText
                  :required="true"
                  :value="project.description"
                  label="Description"
                  v-on:changed="
                    ($value) => {
                      project.description = $value;
                    }
                  "
                /> -->
          </v-col>
        </v-row>
      </div>
    </v-card-text>
    <v-card-actions v-if="showDetails" class="dialogfooter">
      <v-spacer></v-spacer>
      <v-btn class="mr-1 cancel" @click="gofirst">Start Over</v-btn>
      <v-btn class="save" :disabled="project.sender_email && project.mobile ? disabled : ''" type="submit">Send</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import axios from 'axios'
export default {
  props: ["projectListing"],
  components: {
  },
  data() {
    return {
      project: {
        erp: null,
        platform: null,
        channel: null,
        website: null,
        marketplaces: null,
        needErp: null,
        sender_email: '',
        mobile: '',
        description: ''
      },
      entryModal: true,
      showButton: true,
      snackbar: false,
      descriptionRule: [(value) => !!value || "Description is Required"],
      mobileRule: [(value) => !!value || "Mobile is Required"],
      emailRule: [
        (value) => !!value || "E-mail is Required",
        (value) => {
          if (/.+@.+\..+/.test(value)) {
            this.isValidEmail = true;
            return true;
          }
          this.isValidEmail = false;
          return "E-mail must be valid.";
        },
      ],
      disabled: false,
      projectData: [],
      showSecond: false,
      showThird: false,
      showFourth: false,
      showFive: false,
      showSix: false,
      showFirst: true,
      showDetails: false,
      lastIndex: '',
    };
  },
  created: function () { },
  methods: {
    closeDialog() {
      this.entryModal = false
      this.$emit("closed");
    },
    gofirst() {
      this.showFirst = true
      this.showDetails = false
    },
    goBack() {
      switch (this.lastIndex) {
        case 1:
          {
            this.showFirst = true;
            this.showSecond = false
          }
          break
        case 2:
          if (this.project.website == 'Own Website') {
            this.showThird = false;
            this.showSecond = true
          } else {
            this.showFive = false
            this.showSecond = true
          }
          break
        case 3:
          {
            this.showFourth = false;
            this.showThird = true
          }
          break
        case 4:
          {
            this.showFive = false;
            this.showFourth = true
          }
          break
        case 5:
          if (this.project.needErp == 'Yes') {
            this.showSix = false
            this.showFive = true
          } else {
            this.showDetails = false
            this.showFive = true
          }
          break
        case 6:
          {
            this.showDetails = false
            this.showSix = true
          }
          break
        case 7:
          {
            this.showDetails = true
            this.showSix = false
          }
          break
      }
    },
    open($index) {
      console.log(this.project)
      switch (parseInt($index)) {
        case 1:
          {
            this.lastIndex = 1
            this.showSecond = true;
            this.showFirst = false
          }
          break
        case 2:
          if (this.project.website == 'Own Website') {
            this.lastIndex = 2
            this.showThird = true;
            this.showSecond = false
          } else {
            this.lastIndex = 2
            this.showFive = true
            this.showSecond = false
          }
          break
        case 3:
          {
            this.lastIndex = 3
            this.showFourth = true;
            this.showThird = false
          }
          break
        case 4:
          {
            this.lastIndex = 4
            this.showFive = true;
            this.showFourth = false
          }
          break
        case 5:
          if (this.project.needErp == 'Yes') {
            this.lastIndex = 6
            this.showSix = true
            this.showFive = false
          } else {
            this.lastIndex = 5
            this.showDetails = true
            this.showFive = false
          }
          break
        case 6:
          {
            this.lastIndex = 7
            this.showDetails = true
            this.showSix = false
          }
          break
      }
    },
    async sendproject() {
      console.log(this.project)
      let $data = this.project

      axios.post(`https://olz3pva7kb.execute-api.us-east-1.amazonaws.com/production/mail`, $data)
        .then(response => {
          console.log(response)
          this.snackbar = true;
          setTimeout(() => {
            this.snackbar = false
            this.$emit('closed')
          }, 4000)
        }).catch(error => {
          console.log(error)
        });
    },
  },
};
</script>