<template>
  <PrimeTable
    :tableName="'Shippers '"
    :showTableHeader="true"
    activity="Shippers"
    :headers="column"
    :tableData="data"
    @rowAction="rowAction"
    @addDialog="addDialog"
    :modelName="'shippers'"
    @filterData="applyFilter($event)"
  ></PrimeTable>
  <v-dialog v-model="dialog" width="600px" persistent>
    <AddShippers @closeDialog="closeDialog" :type="type" :id="editDataId" />
  </v-dialog>
  <v-dialog v-model="confirmation" width="300px" persistent>
    <ConfirmationDialog
      @cancelled="cancelled()"
      @deleted="deleted"
      :content="confirmationContent"
    />
  </v-dialog>
</template>

<script>
import ShipperService from "@/services/admin/ShipperService.js";
import ConfirmationDialog from "@/components/DialogConfirmation.vue";
import AddShippers from "@/modules/setting/AddAdmin/AddShippers.vue";
export default {
  components: {
    AddShippers,
    ConfirmationDialog,
  },
  data() {
    return {
      dialog: false,
      editDataId: "",
      data: [],
      confirmationContent: null,
      confirmation: false,
      column: [
        { title: "Name", align: "start", sortable: false, key: "name" },
        { title: "Code", align: "start", key: "code" },
        { title: "Erp key", align: "start", key: "erp_key" },
        { title: "Modified by", align: "start", key: "users" },
        { title: "Modified on", align: "start", key: "modified" },
        { title: "Status", align: "start", key: "active" },
        { title: "Action", key: "actions", sortable: false },
      ],
      active: [
        { id: 1, name: "Active" },
        { id: 0, name: "Inactive" },
      ],
    };
  },
  created() {
    let $value = {};
    this.getShippers($value);
  },
  methods: {
    applyFilter($value){
       this.getShippers($value)
    },
    async getShippers($value) {
      await ShipperService.findAll($value)
        .then((response) => {
          this.data = response;
          this.data.map(async (obj) => {
            return (obj.active = this.active.find((type) => type.id == obj.active).name);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    rowAction(e) {
      if (e.type == "edit") {
        this.dialog = true;
        this.type = e.type;
        this.editDataId = e.value.id;
      }
      if (e.type == "view") {
        this.dialog = true;
        this.type = e.type;
        this.editDataId = e.value.id;
      }
      if (e.type == "delete") {
        this.dialog = false;
        this.confirmation = true;
        this.confirmationContent = `Do you want to delete the Shipper ${e.value.name} ?`;
        this.editDataId = e.value.id;
      }
      if (e.type == "select") {
        this.dialog = true;
        this.type = e.type;
        this.editDataId = e.value.id;
      }
    },

    addDialog() {
      this.editDataId = null;
      this.dialog = true;
      this.type = "add";
    },
    closeDialog() {
      this.dialog = false;
      this.getShippers();
    },
    async deleted() {
      this.confirmation = false;
      await ShipperService.delete({ id: this.editDataId });
      this.getShippers();
    },
    cancelled() {
      this.editDataId = null;
      this.confirmation = false;
    },
  },
};
</script>
