<template>
  <v-card flat class="rounded-lg" style="border: 2px solid; border-color: #cfcdcd" height="282px">
    <v-card-title> Organisation Heirarchy</v-card-title>
    <v-card-text>
      <v-form ref="form">
        <v-row class="pt-6">
          <v-col cols="12" class="pa-0 px-2">
            <AutoComplete :label="'SBU'" 
            :data="formData.sbu"
              @textData="organisationData($event)"
            :items="sbuData" :text="'name'"
          :value="'id'"
               :rules="sbuRule" />
          </v-col>
          <v-col cols="12" class="pa-0 px-2">
            <AutoComplete :label="'Brand'" :items="brandData" :text="'name'"
            :value="'id'"
            :data="formData.brand"
              @textData="organisationData1($event)"
              :rules="brandRule" />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>
  
<script>
import AutoComplete from "@/components/AutoComplete.vue";
import BrandService from "@/services/catalogs/BrandService";
import SbuService from "@/services/admin/SbuService";
export default {
  props: ['validation', 'productData'],
  components: {
    AutoComplete,
  },
  data() {
    return {
      sbuRule: [(value) => (!!value) || "SBU is Required"],
      brandRule: [(value) => (!!value) || "Brand is Required"],
      formData: {
        sbu: null,
        brand: null,
      },
      sbuData: [],
      brandData: [],
    };
  },
  created() {
    if (this.productData) {
      this.formData.sbu = this.productData.sbu_id
      this.formData.brand = this.productData.brand_id
    }
    this.getSbu();
    this.getBrand();
  },
  watch: {
    validation() {
      this.save();
    },
    productData() {
      this.formData.sbu = this.productData.sbu_id
      this.formData.brand = this.productData.brand_id
    }
  },
  methods: {
    async getBrand() {
      let res = await BrandService.findAll();
      this.brandData = res&&res.length>0 ? res : [];
    },
    async getSbu() {
      let res = await SbuService.findAll();
      this.sbuData = res&&res.length>0 ? res : [];
    },
    async save() {
      let validation = await this.$refs.form.validate();
      if (!validation.valid) {
        console.log(validation);
      }
      else {
        var isvalid = true
        this.$emit("validCheck", isvalid)
      }
    },
    organisationData(value) {
      this.formData.sbu = value
      this.$emit("organisationData", this.formData);
    },
    organisationData1(value) {
      this.formData.brand = value
      this.$emit("organisationData", this.formData);
    },
  },
};
</script>
  
<style></style>