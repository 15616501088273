<template>
  <v-app-bar class="topmenu" :elevation="0">
    <span class="pl-2" style="font-size: 13px">{{ greeting }}&nbsp;</span>
    <h4 style="display: inline-block" class="dialog-Title">{{ userName }}</h4>
    <!-- <v-col cols="1">
      <AccountSelection />
    </v-col> -->
    <template v-slot:append>
      <GlobalSearch />
      <AccountSelection />
      <v-tooltip text="Notification" location="bottom">
        <template v-slot:activator="{ props }">
          <v-btn v-bind="props" variant="text" @click="toggle">
            <v-badge
              :content="notificationContent"
              color="error"
              v-if="notificationContent > 0"
            >
              <img
                class="top-menu-img-filter"
                src="https://s3.ap-south-1.amazonaws.com/xtend3.0/icons/xtend/notfication.svg"
              />
            </v-badge>

            <img
              class="top-menu-img-filter"
              v-if="notificationContent == 0"
              src="https://s3.ap-south-1.amazonaws.com/xtend3.0/icons/xtend/notfication.svg"
            />
          </v-btn>
        </template>
      </v-tooltip>
      <!-- <v-tooltip text="Message" location="bottom">
        <template v-slot:activator="{ props }">
          <v-btn v-bind="props" variant="text"><img class="top-menu-img-filter"
              src="https://s3.ap-south-1.amazonaws.com/xtend3.0/icons/xtend/message.svg" /></v-btn>
        </template>
      </v-tooltip> -->
      <v-tooltip text="User" location="bottom">
        <template v-slot:activator="{ props }">
          <v-btn v-bind="props" variant="text" id="menu-activator">
            <img
              class="top-menu-img-filter"
              src="https://s3.ap-south-1.amazonaws.com/xtend3.0/icons/xtend/user.svg"
          /></v-btn>
        </template>
      </v-tooltip>
      <v-menu activator="#menu-activator">
        <v-list>
          <v-list-item
            v-for="(item, index) in items"
            :key="index"
            :value="index"
            @click="menuBar(index, item)"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-app-bar>
  <v-dialog v-model="dialog" width="400">
    <ConfirmationDialog @close="close" />
  </v-dialog>
  <OverlayPanel ref="op" appendTo="body" class="mt-6">
    <Notification></Notification>
  </OverlayPanel>
</template>
<script>
import AccountModel from "@/api/service/AccountService";
import DsProductService from "@/services/catalogs/DsProductService";
// import { authStore } from "@/store";
import ConfirmationDialog from "./ConfirmationDialog.vue";
import Notification from "./Notification.vue";
import AccountSelection from "./AccountSelection.vue";
import { ref } from "vue";
import OverlayPanel from "primevue/overlaypanel";
import GlobalSearch from "@/components/GlobalSearch.vue";
export default {
  data() {
    return {
      dialog: false,
      searchData: "",
      notificationContent: 0,
      items: [
        { title: "User Profile" },
        { title: "Switch Company" },
        { title: "Add Company" },
        // { title: "Dark And Light" },
        { title: "Logout" },
      ],
      accountData: [],
      ProductListData: [],
      greeting: "",
      userName: localStorage.getItem("_CUSER_"),
      items1: [
        {
          title: "Dashboard",
          disabled: false,
          href: "breadcrumbs_dashboard",
        },
        {
          title: "Link 1",
          disabled: false,
          href: "breadcrumbs_link_1",
        },
      ],
    };
  },
  components: {
    Notification,
    ConfirmationDialog,
    OverlayPanel,
    AccountSelection,
    GlobalSearch,
  },
  created() {
    this.getAccounts();
    // let isDark = localStorage.getItem("APP_THEME");
    // if (isDark && isDark == "dark") {
    //   localStorage.setItem("APP_THEME", "dark");
    //   this.$store.commit("setIsDark", "dark");
    //   this.items.splice(3, 1, { title: "Light" });
    // } else {
    //   localStorage.setItem("APP_THEME", "light");
    //   this.$store.commit("setIsDark", "light");
    //   this.items.splice(3, 1, { title: "Dark" });
    // }
  },
  mounted() {
    const hour = new Date().getHours();
    if (hour < 12) {
      this.greeting = "Good Morning";
    } else if (hour >= 12 && hour < 18) {
      this.greeting = "Good Afternoon";
    } else {
      this.greeting = "Good Evening";
    }
    this.getProduct();
  },
  methods: {
    async getAccounts() {
      const $response = await AccountModel.findAll();
      this.accountListData = $response;
      this.accountData = $response;
    },
    async getProduct() {
      const res = await DsProductService.findAll();
      this.ProductListData = res;
      this.ProductData = res;
      console.log(this.ProductData, "productData");
    },
    menuBar(index, item) {
      // if (
      //   item.title.toLowerCase() == "dark" ||
      //   item.title.toLowerCase() == "light"
      // ) {
      //   localStorage.setItem("APP_THEME", item.title.toLowerCase());
      //   this.$store.commit("setIsDark", item.title.toLowerCase());
      //   this.items.splice(3, 1, {
      //     title: item.title.toLowerCase() == "dark" ? "Light" : "Dark",
      //   });
      // }
      // let data = item;
      // this.$emit("DarkAndLight", data);
      if (index == 3 && item.title == "Logout") {
        this.dialog = true;
      } else if (index == 2) {
        this.$router.push("/addcompany");
      } else if (index == 1 && item.title == "Switch Company") {
        this.$store.commit("setSignIn", false);
        let token = localStorage.getItem("XTE_TOKEN");
        localStorage.clear();
        this.$router.push(`/login?token=${token}`);
      } else if (index == 0 && item.title == "User Profile") {
        this.$router.push("/profile");
      }
    },
    // searchProductData($event){
    //      this.ProductListData = this.ProductData.filter(f => (f.description && f.description.toLowerCase().includes($event.toLowerCase()) || f.sku && f.sku.toLowerCase().includes($event.toLowerCase())));
    // },
    close() {
      this.dialog = false;
    },
    // searchDataFilter(event) {
    //   this.accountData = this.accountListData.filter(f => (f.name && f.name.toLowerCase().includes(event.toLowerCase())));
    // },
  },
  setup() {
    const op = ref();

    const toggle = (event) => {
      op.value.toggle(event);
    };

    return {
      op,
      toggle,
    };
  },
};
</script>
