<template>
  <div class="transp">
    <div class="btnPadding">
     <!--  <v-btn text> Home </v-btn>
      <v-btn text> About </v-btn>
      <v-btn text> Product </v-btn>
      <v-btn text> Contact </v-btn> -->
      <v-btn variant="text" @click="$router.push('/login')"> Login </v-btn>
      <v-btn rounded @click="openDialog" style="background-color: #be1e2d !important">
        Get Started
      </v-btn>
    </div>
     <v-dialog max-width="600" max-heigth="300" class="dialogbox"
            v-model="entryModal"  transition="dialog-top-transition" hide-overlay >
          
             <Dialog
             @closed="closeDialog"
             />
          </v-dialog>
  
  
    
  </div>
</template>
<script>
 import Dialog from './dialog.vue'
export default {
  components:{
    Dialog
    },
  data(){
    return{
      editFlag:false,
      entryModal:false
    }
  },
  methods:{
    openDialog(){
      this.entryModal=true;
      this.editFlag=true
    },
    closeDialog(){
      this.entryModal=false;
      this.editFlag=false
    }
   }
}
</script>
<style scoped>
.transp {
  font-family: 'Nunito' !important;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    width: 100%;
    position: fixed;
    text-align: end;
    right: 20px;
    padding-top: 15px;
}
.theme--light.v-btn {
  color: #ffffff !important;
}
/* .transp .btnPadding {
  padding-right: 90px;
  padding-top: 20px;
} */

@media only screen and (max-width: 480px) {
  .transp {
    width: 100vw !important;
    height: 40px;
    top: 0px;
    font-size: 6px !important;
    line-height: 8px !important;
  }
  .transp .btnPadding {
    padding-right: 5px;
    padding-top: 10px;
  }
  .v-btn.v-size--default {
    font-size: 6px;
  }
  .v-btn:not(.v-btn--round).v-size--default {
    height: 20px;
    min-width: 40px;
    padding: 0px 2px;
  }
    .dialogbox{
    max-width:73px !important;
    /* max-height:23% !important; */
    zoom:20%;
  
  }  
}
@media only screen and (min-width:480px) and (max-width: 600px)  {
  .transp {
    width: 100vw !important;
    height: 45px;
    top: 0px;
    font-size: 6px !important;
    line-height: 8px !important;
  }
  .transp .btnPadding {
    padding-right: 10px;
    padding-top: 10px;
  }
  .v-btn.v-size--default {
    font-size: 10px;
  }
  .v-btn:not(.v-btn--round).v-size--default {
    height: 23px;
    min-width: 55px;
    padding: 0px 4px;
  }
}
@media only screen and (min-width:600px) and (max-width: 900px)  {
  .transp {
    width: 100vw !important;
    height: 45px;
    top: 0px;
    font-size: 6px !important;
    line-height: 8px !important;
  }
  .transp .btnPadding {
    padding-right: 10px;
    padding-top: 10px;
  }
  .v-btn.v-size--default {
    font-size: 12px;
  }
  .v-btn:not(.v-btn--round).v-size--default {
    height: 25px;
    min-width: 60px;
    padding: 0px 4px;
  }
}

</style>