<template>
  <v-card>
    <PrimeTable :tableName="'Orders'" activity="Order" :showTableHeader="true" :headers="column" :tableData="data" @addDialog="addDialog" @filterClicked="filterClicked()"
      @rowAction="rowAction" :modelName="'mws_orders'" @filterData="applyFilter($event)"></PrimeTable>
  </v-card>
  <v-dialog v-model="getOrderDialog" width="700px" persistent>
  <GetOrder @closeDialog="closeDialog()"></GetOrder>
 </v-dialog>
</template>
<script>
import PrimeTable from '@/components/PrimeTable.vue';
import MwsOrderService from '@/services/orders/MwsOrderService';
import MwsOrderItemService from '@/services/orders/MwsOrderItemService';
import GetOrder from '@/modules/setting/Orders/GetOrders.vue'
export default {
  components: {
    PrimeTable,
    GetOrder,
  },
  data() {
    return {
      dataId: null,
      getOrderDialog: false,
      data: [],
      column: [
        { title: 'Order', align: 'start', sortable: false, key: 'mws_order_id' },
        { title: 'Erp', align: 'start', key: 'merchant_order_id' },
        { title: 'Buyer', align: 'start', key: 'buyer_name' },
        { title: 'Source', align: 'start', key: 'order_source' },
        { title: 'Net Value', key: 'net_amount', align: 'start' },
        { title: 'Market Place', key: 'market_place', align: 'start' },
        { title: 'Shipment Method', key: 'shipment_method', align: 'start' },
        { title: 'Created On', key: 'created', align: 'start' },
        { title: 'Stage', key: 'stage', align: 'start' },
        { title: 'Status', align: 'start', key: 'active' },
        { title: 'Action', key: 'actions', sortable: false },
      ],
      active: [
        { id: 1, name: "Active" },
        { id: 0, name: "Inactive" },
      ],
      stage: [
        { stage:'O', name: "Open" },
        { stage:'C', name: "Shipped" },
      ],
    }
  },
  created() {
    let $value = {};
    this.getOrdersData($value);
  },
  methods: {
    applyFilter($value){
       this.getOrdersData($value);
    },
    async getOrdersData($value) {
      await MwsOrderService.findAll($value)
        .then((response) => {
          this.data = response;
          this.data.map((obj) => {
          obj.market_place = obj.mws_headers.name;
          obj.active = this.active.find((type) => type.id == obj.active).name;
          obj.stage = this.stage.find((stage) => stage.stage == obj.stage).name;
          return obj;
          });
          // console.log("dataata",this.data)
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async rowAction(e) {
      var market_place = e.value.market_place
      if (e.type == "view") {
        this.dataId = e.value.id
        this.$router.push({ path: '/ordersView', query: { id: this.dataId,store:market_place } })
        this.type = e.type
      }
      if (e.type == "select") {
        this.dataId = e.value.id
        this.$router.push({ path: '/ordersView', query: { id: this.dataId,store:market_place  } })
        this.type = e.type
      }
      if (e.type == "delete") {
        this.dataId = e.value.id
         await MwsOrderItemService.delete({id: this.dataId })
         await MwsOrderService.delete({id: this.dataId })
         this.getOrdersData()

      }
    },
    addDialog(){
      this.getOrderDialog = true;
    },
    closeDialog(){
      this.getOrderDialog = false;
    },
    filterClicked(){
    }
  }
}
</script>