<template>
  <div class="ma-3">
    <v-icon class=" mt-2" @click="$router.go(-1)">mdi-arrow-left-circle-outline</v-icon>
      <v-col class="Product-header px-1">{{ productData.style_name }}
        <v-icon @click="dialog = true" v-if="productData.style_name" color="primary"
          class="ml-1">mdi-pencil-circle</v-icon>
        <!-- <v-btn @click="dialog = true"  size="small" icon="mdi-pencil"></v-btn -->
      </v-col>
   
    <v-row>
      <v-col cols="9">
        <ProductDescriptionVue :products="productData" @updateStyle="updateProduct()"/>
      </v-col>
      <v-col>
        <ProductOrganisationVue :products="productData" />
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" width="700">
      <v-card>
        <v-form @submit.prevent="save" ref="form">
          <v-card-title class="dialog-Title">Style
            <v-btn density="comfortable" @click="dialog = false" elevation="0" icon="mdi-close"
              style="float: right"></v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="6" class="pl-2 pa-0">
                  <InputBox :rules="styleRule" :data="formData.code" @textData="($event) => { formData.code = $event; }"
                    :disabled="showReadOnly" label="Style"></InputBox>
                </v-col>
                <v-col cols="6" class="pa-0 pl-2">
                  <InputBox :rules="nameRule" :data="formData.name" @textData="($event) => { formData.name = $event; }"
                    :disabled="showReadOnly" label="Name"></InputBox>
                </v-col>
                <v-col cols="6" class="pa-0 pl-2">
                  <AutoComplete :items="productTypeData" :rules="productTypeRule" :text="'name'" :value="'id'"
                    :data="formData.product_type_id" @textData="($event) => { formData.product_type_id = $event; }"
                    :disabled="showReadOnly" label="Product Type"></AutoComplete>
                </v-col>
                <v-col cols="6" class="pa-0 pl-2">
                  <AutoComplete :items="categoryData" :rules="categoryRule" :text="'name'" :value="'id'"
                    :data="formData.category_id" @textData="($event) => { formData.category_id = $event; }"
                    :disabled="showReadOnly" label="Category"></AutoComplete>
                </v-col>
                <v-col cols="6" class="pa-0 pl-2">
                  <AutoComplete :items="subCategoryData" :rules="subCategoryRule" :text="'name'" :value="'id'"
                    :data="formData.sub_category_id" @textData="($event) => { formData.sub_category_id = $event; }"
                    :disabled="showReadOnly" label="Sub Category"></AutoComplete>
                </v-col>
                <v-col cols="6" class="pa-0 pl-2">
                  <AutoComplete :items="brandData" :rules="brandRule" :text="'name'" :value="'id'"
                    :data="formData.brand_id" @textData="($event) => { formData.brand_id = $event; }"
                    :disabled="showReadOnly" label="Brand"></AutoComplete>
                </v-col>
                <v-col cols="6" class="pa-0 pl-2">
                  <AutoComplete :items="subData" :rules="sbuRule" :text="'name'" :value="'id'" :data="formData.sbu_id"
                    @textData="($event) => { formData.sbu_id = $event; }" :disabled="showReadOnly" label="SBU">
                  </AutoComplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="dialogfooter">
            <v-spacer></v-spacer>
            <v-btn class="mr-1 cancel" @click="dialog = false">Cancel</v-btn>
            <v-btn class="save" type="submit">Save</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ProductDescriptionVue from "./ProductDescription.vue";
import ProductOrganisationVue from "./ProductOrganisation.vue";
import DsProductDetailService from "@/services/catalogs/DsProductDetailService";
import ProductTypeService from "@/services/catalogs/ProductTypeService";
import CategoryService from "@/services/catalogs/CategoryService";
import SubCategoryService from "@/services/catalogs/SubCategoryService";
import BrandService from "@/services/catalogs/BrandService";
import SbuService from "@/services/admin/SbuService";
import StyleService from "@/services/catalogs/StyleService";
export default {
  components: {
    ProductDescriptionVue,
    ProductOrganisationVue,
  },
  data() {
    return {
      productData: {},
      productId: null,
      productTypeData: [],
      categoryData: [],
      subCategoryData: [],
      brandData: [],
      subData: [],
      dialog: false,
      formData: {
        product_type_id: null,
        category_id: null,
        sub_category_id: null,
        brand_id: null,
        sbu_id: null,
        code: null,
        name: null,
      },
      styleRule: [(value) => !!value || "Style is Required"],
      nameRule: [(value) => !!value || "Name is Required"],
      productTypeRule: [(value) => !!value || "Product Type is Required"],
      categoryRule: [(value) => !!value || "Category is Required"],
      subCategoryRule: [(value) => !!value || "Sub Category is Required"],
      brandRule: [(value) => !!value || "Brand is Required"],
      sbuRule: [(value) => !!value || "SBU is Required"],
    };
  },
  created() {
    this.getProducts();
    this.getProductType();
    this.getCategory();
    this.getSubCategory();
    this.getBrand();
    this.getSbu();
  },
  methods: {
    async getProductType() {
      let res = await ProductTypeService.findAll()
      this.productTypeData = res&&res.length>0 ? res : [];
    },
    async getCategory() {
      let res = await CategoryService.findAll()
      this.categoryData = res&&res.length>0 ? res : [];
    },
    async getSubCategory() {
      let res = await SubCategoryService.findAll()
      this.subCategoryData = res&&res.length>0 ? res : [];
    },
    async getBrand() {
      let res = await BrandService.findAll()
      this.brandData = res&&res.length>0 ? res : [];
    },
    async getSbu() {
      let res = await SbuService.findAll()
      this.subData = res&&res.length>0 ? res : [];
    },
    updateProduct(){
      this.getProducts();
    },
    async getProducts() {
      let $id = this.$route.params.id;
      let res = await DsProductDetailService.findAll({ id: $id });
      if (res && res.length > 0) {
        res[0].products_data =
          res.length > 0 && res[0].products_data
            ? JSON.parse(res[0].products_data)
            : [];
        res[0].store_data =
          res.length > 0 && res[0].store_data
            ? JSON.parse(res[0].store_data)
            : [];
        this.productData = res[0];
        this.getStyle(this.productData.id)
      }
    },
    async getStyle(id) {
      let res = await StyleService.findAll({ id: id })
      this.formData = res[0]
      console.log(this.formData, 'result12345677889')
    },
    async save() {
      let validation = await this.$refs.form.validate()
      if (!validation.valid) {
        console.log(validation)
      } else {
        if (this.productData.id) {
          let output = await StyleService.update(this.formData)
          console.log(output)
          this.close()
        }
      }
    },
    close() {
      this.dialog = false
      this.getProducts()
    }
  },
};
</script>

<style>
.Product-header {
  font-weight: bold;
  font-size: 16px;
}
</style>