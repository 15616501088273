<template>
    <v-row>
        <v-col cols="12" class="pl-0">
            <v-span class="">
                <span><b>Open Items</b></span>
            </v-span>
            <v-span class="pl-5">
                   <v-btn variant="tonal" size="small"  elevation="0" @click="openDialog()" v-if="showbutton" class="button"> Fulfilled </v-btn>&nbsp;&nbsp;
                   <v-btn variant="tonal" size="small"  elevation="0" v-if="showbutton" class="cancel"> Cancel </v-btn>
              </v-span>
        </v-col>
         
        <v-col class="pa-0">
                <v-card elevation="0">

                 
                  <v-card-text v-for="item in items" :key="item" class="pb-1">
                    <v-row class="pa-1">
                        <v-col cols="7" sm="6" xs="3" md="9" lg="9" xl="7" xxl="18" style="display: inline-flex;">
                            <v-row>
                                  <span><v-checkbox color="indigo" v-model="check" ></v-checkbox></span>
                                  <span class="pl-5 pr-3"> <v-img :width="30" :height="60" cover :src="`${item.products.images}`"></v-img></span>
                                  <span class="pl-3 pt-2">
                                     <b class="text-subtitle-2">{{item.products.name }}</b><span class="pl-2"><v-icon size="20" @click="openDialog()">mdi-pencil</v-icon></span>
                                      <div><span class="text-caption">{{ item.shop_sku }} | {{ item.merchant_sku_ordered }} | {{ item.fulfillments.code }} | {{ data && data.mws_orders ? data.mws_orders.shipment_method : '' }}</span> <span class="pl-3 mr-2">
                                        <Tag  class="chip text-caption"  :style="`background-color:${idcolor}; color:${color}`" v-if="item.mws_product_id">{{ item.mws_product_id  ? item.mws_product_id:''}}</Tag> &nbsp;
                                        <Tag  class="chip text-caption"  :style="`background-color:${mpcolor}; color:${color}`" v-if="!item.mws_product_id"><span class="text-caption h-8">Missing Product</span></Tag>

                                      </span>
                                        <v-icon  @click="copyText(item.chip)" size="15px" v-if="item.mws_product_id">
                                            <v-img src="https://s3.ap-south-1.amazonaws.com/xtend3.0/icons/edit/copy.svg" width="10px"></v-img></v-icon> 
                                          </div> 
                                  </span>
                           </v-row>
                            </v-col>    
                        <v-col cols="5" sm="2" xs="1" md="3" lg="3" xl="5" xxl="6">
                         <v-row class="text-subtitle-2 pt-3 pr-0">
                           <div class="pa-0" align="start" style="width: 10%;">{{ data && data.ordered_qty }}</div>
                           <div class="pa-0" align="center" style="width: 5%;">X</div>
                           <div class="pa-0" align="center" style="width: 40%;">$ {{ data && data.sale_price }}</div>
                           <div class="pa-0" align="center" style="width: 5%;"><span>=</span></div>
                           <div class="pa-0" align="center" style="width: 40%;"><span>$ {{ data.ordered_qty * data.sale_price }}</span></div>
                          </v-row>
                        </v-col>
                  </v-row>
                  </v-card-text>
                </v-card>  
              </v-col>
    </v-row>
    <v-dialog v-model="dialog" width="500px" persistent>
         <AddItems @closeDialog="closeDialog()" :type="type"/>
    </v-dialog>
</template>

<script>
// import Tag from 'primevue/tag';
import AddItems from './AddItems.vue';
export default{
  props:['data'],
    components:{
        // Tag,
        AddItems
    },
    data(){
        return{
        showbutton: false,
        check: [],
        dialog: false,
        items:[],
        idcolor:'#D1FCFF',
        mpcolor:'#FFE0E0',
        color:'#272727',  

        }
    },
    watch:{
    'check':function(value){
      if(value){
        this.showbutton = true
      }
      if (value == ''){
        this.showbutton = false
      }
      
    },
    data(){
      this.items = []
      this.items .push(this.data)
      console.log(this.items,'nnnnnn')
}
    },
    methods:{
      openDialog(){
        this.dialog = true
        this.type = 'edit'
      },
      copyText(chip) {
          navigator.clipboard.writeText(chip);
        },
      closeDialog(){
        this.dialog = false
      }
    }
}
</script>
<style scoped>
>>> .chip{
    padding-right: 5px;
    padding-left: 5px;
    padding-top: 0px;
    padding-bottom: 0px;
    font-weight: 400;
}
.p-title{
  color: #636363;
}

</style>