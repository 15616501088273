<template>
  <v-card>
    <v-form @Submit.prevent="save" ref="form">
      <v-card-title class="dialog-Title"
        >{{
          type == "edit"
            ? "Edit"
            : type == "view"
            ? "View"
            : type == "add"
            ? "Add"
            : type == "select"
            ? "View"
            : ""
        }}
        Shipment Service
        <v-btn
          density="comfortable"
          @click="closeDialog"
          elevation="0"
          icon="mdi-close"
          style="float: right"
        >
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="6" class="pa-0">
              <InputBox
                :data="formData.code"
                @textData="checkCode($event)"
                :label="'Code'"
                :rules="codeRule"
                @keydowninput="prevent"
                :disabled="showReadOnly"
              />
              <span v-if="codeError" class="error-field">Already Exists!</span>
            </v-col>
            <v-col cols="6" class="pa-0 pl-2">
              <InputBox
                :label="'Name'"
                :data="formData.name"
                @textData="
                  ($event) => {
                    formData.name = $event;
                  }
                "
                :rules="nameRule"
                :disabled="showReadOnly"
              />
            </v-col>
            <v-col cols="6" class="pa-0">
              <InputBox
                :label="'ERP Key'"
                :data="formData.erp_key"
                @textData="
                  ($event) => {
                    formData.erp_key = $event;
                  }
                "
                :rules="erpRule"
                :disabled="showReadOnly"
              />
            </v-col>
            <v-col cols="6" class="pa-0 pl-2">
              <AutoComplete
                :label="'Shipper'"
                :disabled="showReadOnly"
                :data="formData.shipper_id"
                @textData="
                  ($event) => {
                    formData.shipper_id = $event;
                  }
                "
                :items="shipperData"
                :value="'id'"
                :text="'name'"
                :rules="shipperRule"
              />
            </v-col>
            <v-col cols="12" class="pa-0">
              <EnumDropdown
                :data="formData.service_type"
                @textData="($event) => {formData.service_type = $event;}"
                :domainName="'servicetype'"
                :disabled="showReadOnly"
                :label="'Service Type'"
                :rules="serviceTypeRule"/>
            </v-col>
            <v-col cols="12" class="pa-0">
              <EnumDropdown
                :data="formData.service_level"
                @textData="($event) => {formData.service_level = $event;}"
                :domainName="'servicelevel'"
                :disabled="showReadOnly"
                :label="'Service Level'"
                :rules="serviceLevelRule"/>
            </v-col>
            <v-col cols="12" class="pa-0">
              <InputBox
                :label="'Expected Delivery Days(Max.)'"
                :data="formData.delivery_days"
                @textData="
                  ($event) => {
                    formData.delivery_days = $event;
                  }
                "
                :rules="deliveryDayRule"
                :disabled="showReadOnly"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="dialogfooter" v-if="!showReadOnly">
        <v-spacer></v-spacer>
        <v-btn class="mr-1 cancel" @click="closeDialog">Cancel</v-btn>
        <v-btn class="save" type="submit">Save</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import ShipmentServiceService from "@/services/admin/ShipmentServiceService.js";
import ShipperService from "@/services/admin/ShipperService.js";
export default {
  props: ["type", "id"],
  data() {
    return {
      formData: {},
      shipperData: [],
      serviceType: [],
      serviceLevel: [],
      showReadOnly: false,
      serviceLevelRule:[(value) => !!value || "Service Level is Required"],
      serviceTypeRule:[(value) => !!value || "Service Type is Required"],
      codeRule: [(value) => !!value || "Code is Required"],
      erpRule: [(value) => !!value || "Erp is Required"],
      nameRule: [(value) => !!value || "Name is Required"],
      shipperRule: [(value) => !!value || "Shipper is Required"],
      deliveryDayRule: [(value) => !!value || "Expected Delivery Days is Required"],
      shipmentService: {
        code:null,
        name: null,
        erp_key:null,
        service_type: null,
        service_level: null,
        delivery_days: null,
        shipper_id: null,
      },
    };
  },

  created() {
    if (this.id) {
      this.getEditData();
    }
    if (this.type == "view" || this.type == "select") {
      this.showReadOnly = true;
    }
    this.getShipper();
  },

  methods: {
    prevent(e) {
      if (!/^[a-zA-Z0-9-_ \t]+$/.test(e.key) && e.key !== "Backspace") {
        e.preventDefault();
      }
    },
    checkCode($event) {
      this.formData.code = $event;
      if (this.formData.code && this.formData.code.length > 0) {
        this.formData.code = this.formData.code.toUpperCase();

        ShipmentServiceService.findAll({ code: this.formData.code }).then((res) => {
          let $exist = res.filter((key) => key.code == this.formData.code);

          this.codeExistFlag =
            $exist.length > 0
              ? this.formData.id && $exist[0].id == this.formData.id
                ? false
                : true
              : false;

          if (this.codeExistFlag) {
            this.codeError = true;
          } else {
            this.codeError = false;
          }
        });
      }
    },
    async getEditData() {
      let $res = await ShipmentServiceService.findAll({ id: this.id });
      this.formData = $res[0];
      console.log(this.formData, "formData");
    },
    async getShipper() {
      let $res = await ShipperService.findAll();
      if ($res && $res.length > 0) {
        this.shipperData = $res&&$res.length>0 ? $res : [];
      }
    },

    closeDialog() {
      this.$emit("closeDialog");
    },
    async save() {
      console.log(this.formData, "data");
      let validation = await this.$refs.form.validate();
      if (!validation.valid) {
        console.log(validation);
      } else {
        if (this.formData && this.formData.id) {
          delete this.formData.users;
          let output = await ShipmentServiceService.update(this.formData);
          this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
          console.log(output);
          this.closeDialog();
        } else {
          try {
            let output = await ShipmentServiceService.create(this.formData);
            this.$store.dispatch("setSnackbar", { text: "Saved Successfully" });
            console.log(output, "output", this.formData);
            this.closeDialog();
          } catch (e) {
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "Something Went Wrong",
            });
          }
        }
      }
    },
  },
};
</script>

<style></style>

