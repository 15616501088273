<template>
     <div class="contendbg">
    <v-container style="text-align: center;">
        <v-card class="mx-auto pa-3" max-width="600">
            <v-img style="margin: 0 auto;" src="@/assets/Tiburaxtend.png" width="100px"></v-img>
            <v-card-subtitle class="text-center mt-6">You are logged out from your session. </v-card-subtitle>
        </v-card>
    </v-container>
</div>
</template>
<script>
export default{
    created(){
        setTimeout(()=>{
            this.$router.push('/login')
        },3000)
    }
}
</script>