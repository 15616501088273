<template>
    <v-col class="ml-2 settingHeader"><v-icon class="mr-2" color="#000000" @click="$router.go(-1)">mdi-arrow-left-circle-outline</v-icon>Admin Settings </v-col>

    <!-- <v-btn-toggle v-model="setting" style="height:40px;" color="indigo" group class="ml-5 mb-2"
        variant="outlined">
        <v-btn @click="adminOpen">Admin</v-btn>
        <v-btn @click="productOpen">Product</v-btn>
    </v-btn-toggle> -->

    <v-row class="pa-5 pt-0 contentsubmenu">
        <v-card elevation="0" class="content-menu-cardleft">
            <v-list v-if="admin" class="content-menu-list">
                <v-list-item :to="item.router" class="pa-4 contentsidemenu" v-for="(item, index) in adminData" :key="index" @click="scrollUp()"
                    :value="index" active-class="selecteditem">
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-card>
        <v-card elevation="0" class="pa-2 content-menu-cardright">
            <RouterView></RouterView>
        </v-card>
    </v-row>
    
</template>

<script>
export default {
    data() {
        return {
            isActive: false,
            setting: 0,
            admin: true,
            product: false,
            adminData: [
                // {
                //     title: 'Users',
                //     value: 1,
                //     router: '/setting/user',
                // },
                {
                    title: 'Divisions',
                    value: 2,
                    router: '/setting/division'
                },
                {
                    title: 'Accounts',
                    value: 3,
                    router: '/setting/accounts'
                },
                {
                    title: 'Fulfillments',
                    value: 4,
                    router: '/setting/fulfillments'
                },
                {
                    title: 'Shippers',
                    value: 5,
                    router: '/setting/shippers'
                },
                {
                    title: 'Shipment Service',
                    value: 6,
                    router: '/setting/shipmentservice'
                },
                {
                    title: 'Shipping Zone',
                    value: 7,
                    router: '/setting/shippingzone'
                },
                {
                    title: 'Discount Code',
                    value: 8,
                    router: '/setting/discountcode'
                },
                {
                    title: 'Company Profile',
                    value: 9,
                    router: '/setting/companyprofile'
                },
                {
                    title: 'ERP Profile',
                    value: 10,
                    router: '/setting/erpprofile'
                },
                {
                    title: 'Exchange Rates',
                    value: 11,
                    router: '/setting/exchangerates'
                },
            ],
        }
    },
    created() {
        let $lastRoute = localStorage.getItem("settingLst")
        if ($lastRoute) {
            this.$router.push($lastRoute)
        }
        // else {
        //     this.$router.push('/setting/user')
        // }
    },
    methods: {
        // adminOpen() {
        //     this.setting = 0
        //     this.product = false
        //     this.admin = true
        //     this.$router.push('/setting/user')
        // },
        // productOpen() {
        //     this.setting = 1
        //     this.product = true
        //     this.admin = false
        //     this.$router.push('/setting/producttype')
        // },
        scrollUp(){
            window.scrollTo(0, 0);
        }
    }
}
</script>
<style lang="scss">
.contentborder{
    border-bottom: 1px solid rgb(225, 225, 225);
}
</style>