<template>
  <div style="padding:5px" v-if="accessPrivilege.indexOf('list') < 0 && !loading"  class="noaccesscard">
    <span style="" class="text-h5 noaccesscontent">{{ this.checkingPermission }}</span>
  </div>
  <div style="padding:5px" v-if="accessPrivilege.indexOf('list') >= 0">
    <v-card flat>
      <v-card-text class="pa-0">
        <v-toolbar class="table-header-bg" flat>
          <v-toolbar-title class="settingHeader">Stores</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip location="top" text="Add" v-if="accessPrivilege.indexOf('create') >= 0">
            <template v-slot:activator="{ props }">
              <v-sheet v-bind="props" border rounded :height="45" :width="45" class="tableicon" @click="dialog = true">
                <v-icon>mdi-plus</v-icon>
              </v-sheet>
            </template>
          </v-tooltip>
          <MenuComponent :activator="'#downlod-actvator'" @menuClicked="download" :items="downlodData" />
        </v-toolbar>
        <v-table class="elevation-0 dataTable">
          <thead>
            <tr>
              <th class="text-left">
                Code
              </th>
              <th class="text-left">
                Name
              </th>
              <th class="text-left">
                Created
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="mws in mwsHeaders" :key="mws" @click="goTo(mws)">
              <td class="link" @click="goTo(mws)">{{ mws.code }}</td>
              <td>
                <span style="color:#3c5aaa">{{ mws.name }}</span>
              </td>
              <td>
                {{ mws.created ? moment(mws.created).format("MM/DD/YY") : "" }}
                {{ mws.created ? moment(mws.created).fromNow() : "" }}
              </td>
            </tr>
          </tbody>
        </v-table>
      </v-card-text>
    </v-card>
    <v-dialog width="600" v-model="dialog" persistent>
      <CreateStore @close="close" />
    </v-dialog>
  </div>
</template>
<script>
import MwsHeaderService from "@/services/mws/MwsHeaderService";
import CreateStore from './CreateStore.vue'
import RolePermissionService from '@/services/admin/RolePermissionService'
import moment from "moment";
export default {
  components: {
    CreateStore,
  },
  data() {
    return {
      mwsHeaders: [],
      dialog: false,
      accessPrivilege: [],
      checkingPermission: null,
    };
  },
  async created() {
    this.accessPrivilege = await RolePermissionService.isAllowed('Marketplace')
    let text = 'You Don\'t Have Permission to View This Page'
    this.checkingPermission = this.accessPrivilege.indexOf('list') < 0 ? text: '';
    this.getMarketPlace();
  },
  mounted() { },
  methods: {
    moment,
    close() {
      this.dialog = false
      this.getMarketPlace()
    },
    goTo(item) {
      console.log(item);
      localStorage.setItem("mwsHeaderId", item.id);
      // this.$router.push({ path: 'marketPlace', query: { id: item.id, type: 'edit' } });
      this.$router.push({ path: "marketPlace/profile" });
    },
    async getMarketPlace() {
      await MwsHeaderService.findAll()
        .then((response) => {
          this.mwsHeaders = response;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style>
.rowheader {
  background-color: #d7eaf4;
  font-weight: 600;
}

.link {
  color: #000;
  text-decoration: none;
  font-size: 15px;
}

.link:hover {
  color: #000;
  text-decoration: underline;
  cursor: pointer;
}

.date {
  color: orange;
}

.noaccesscard{
  padding-top: 25px; 
  position: relative; 
  display: flex;
  align-items: center;
  height: 100%;
}

.noaccesscontent{
  color: #3C5AAA;  
  position: relative; 
  left: 50%;
  transform: translate(-50%, -50%);
  padding-top: 50px;
}
</style>