<template>
  <v-container>
    <v-card-text class="fontstyle">
      <v-row no-gutters dense>
        <v-col lg="6" xl="6" md="6" cols="12" class="mobiletxt">
          <v-row no-gutters dense>
            <v-col xl="12" lg="12" md="12" sm="12" cols="12" style="margin-top:110px;" class="mobiletitle">
              <strong class="ml-2 mobiletitle" style="font-size: 52px;font-weight: 400;">Overview</strong><br />
              <v-col xl="12" lg="12" md="12" sm="12" cols="12" style="margin-top:10px;" class="text">
                <span style="font-weight: 300;font-size: 28px;line-height: 40px;" class="text">We enable you to focus on
                  the<br />business while<span style="color: #2f29a1;font-size: 30px;line-height: 40px;" class="xtendtxt">
                    -XTEND</span><br />automates the process.</span>
              </v-col>
              <v-col xl="12" lg="12" md="12" sm="12" cols="12" style="margin-top:5px;" class="lasttxt">
                <span style="font-weight: 100;font-size: 16px;line-height: 30px;" class="lasttxt">With the entire world
                  moving to a <strong>new normal </strong>,business<br /> need to re-strategize and re-innovate to extend
                  their<br /> Online Sales Channels.</span>
              </v-col>
            </v-col>
          </v-row>
        </v-col>
        <v-col lg="3" xl="3" md="3" cols="12" class="partsimage">
          <v-row no-gutters dense style="text-align:center;justify-content:center;">
            <div class="imageWrapper mobileview" style="margin-top:40px">
              <v-img :src="require('@/assets/autoimg.svg')" />
            </div>
            <v-col lg="12" md="12" sm="12" cols="12" class="mt-2 text-center">
              <strong style="font-weight:600;font-size: 18px;" class="mobileview">Automated Product Listing</strong>
            </v-col>
            <v-col lg="12" md="12" sm="12" cols="12" class="mt-3 text-center">
              <span style="font-weight:100;font-size: 14px;" class="mobileviewtxt">Schedule & Synchronize your
                product<br /> listing across marketplaces</span>
            </v-col>
            <div class="imageWrapper circlecolor1 mobileview" style="margin-top:150px">
              <img style="padding-top: 17px;width: 25px;" :src="require('@/assets/seamimg.svg')" />
            </div>
            <v-col lg="12" md="12" sm="12" cols="12" class="mt-4 text-center">
              <strong style="font-weight:600;font-size: 18px;" class="mobileview">Ontime Order Fulfillment</strong>
            </v-col>
            <v-col lg="12" md="12" sm="12" cols="12" class="mt-3 text-center">
              <span style="font-weight:100;font-size: 14px;" class="mobileviewtxt">Synchronize your orders
                with<br />ERP/Warehouse and always stay ahead<br />on your deliveries</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col lg="3" xl="3" md="3" cols="12" class="partstext">
          <v-row no-gutters dense style="text-align:center;justify-content:center;">
            <div class="imageWrapper circlecolor mobileview">
              <img style="padding-top: 22px;width: 25px;" :src="require('@/assets/timeimg.svg')" />
            </div>
            <v-col lg="12" md="12" sm="12" cols="12" class="mt-4 text-center">
              <strong style="font-weight:600;font-size: 18px;" class="mobileview">Seamless Inventory
                Synchornisation</strong>
            </v-col>
            <v-col lg="12" md="12" sm="12" cols="12" class="mt-3 text-center">
              <span style="font-weight:100;font-size: 14px;" class="mobileviewtxt">Customize your inventory using
                a<br />rule based inventory thresholds</span>
            </v-col>
            <div class="imageWrapper circlecolor2 mobileview " style="margin-top:100px;">
              <img style="padding-top: 22px;width: 25px;" :src="require('@/assets/depthimg.svg')" />
            </div>
            <v-col lg="12" md="12" sm="12" cols="12" class="mt-4 text-center">
              <strong style="font-weight:600;font-size: 18px;" class="mobileview">Indepth analytics</strong>
            </v-col>
            <v-col lg="12" md="12" sm="12" cols="12" class="mt-3 text-center">
              <span style="font-weight:100;font-size: 14px;" class="mobileviewtxt">Single source of truth for
                your<br />ecommerce reporting or Effective <br /> decision making with powerful <br /> dashboards </span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-container>
</template>
<style scoped>
.imageWrapper {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.imageWrapper .v-image {
  width: 25.4px;
  /* height: 28.61px;
left: 12.6px;*/
  top: 30%;
  display: flex;
  justify-content: center;
  text-align: center;
  margin: auto !important;
  z-index: 9999;
}

.circlecolor {
  background: #9CDDFF;
}

.circlecolor1 {
  background: #CFB5FF;
}

.circlecolor2 {
  background: #FFAFB6;
}

.fontstyle {
  font-family: 'Nunito' !important;
}

@media (max-width:767px) {
  .mobileview {
    margin-top: 60px !important;
  }

  .mobiletxt {
    text-align: center !important;
    width: 100%;
  }

  .mobiletitle {
    font-size: 37px !important;
    font-weight: 400 !important;
    margin-top: 20px !important
  }

  .text {
    margin-top: 4px !important;
    font-size: 17px !important;
    line-height: 25px !important;
  }

  .xtendtxt {
    font-size: 20px !important;
    line-height: 25px !important;
  }

  .lasttxt {
    margin-top: 0px !important;
    font-size: 10px !important;
    line-height: 15px !important;
  }
}

/* @media (min-width:600px) and (max-width:768px) {
 .mobileview{
    margin-top: 60px  !important;
  }
  .mobiletxt{
    text-align:center !important;width: 100%;
  }.partsimage,.partstext{width: 100%;}
  
  .mobiletitle{
    font-size: 37px  !important;
    font-weight: 400 !important;
    margin-top: 0px !important
  }
  .text{
    margin-top: 4px  !important;
    font-size: 17px  !important;
    line-height: 25px !important;
  }
  .xtendtxt{
    font-size: 20px !important;
    line-height: 25px !important;
  }
  .lasttxt{
    margin-top: 0px  !important;
    font-size: 9px  !important;
    line-height: 15px  !important;
  }
} */
@media (min-width:767px) and (max-width:1024px) {
  .mobiletitle {
    font-size: 45px !important;
  }

  .text {
    font-size: 22px !important;
    line-height: 30px !important;
  }

  .xtendtxt {
    font-size: 25px !important;
    line-height: 25px !important;
  }

  .lasttxt {
    font-size: 13px !important;
    line-height: 20px !important;
  }

  .mobileview {
    font-size: 15px !important;
  }

  .mobileviewtxt {
    font-size: 10px !important;

  }

  .mobiletxt {
    flex: 0 0 50%;
    float: left;
  }

  .partsimage,
  .partstext {
    flex: 0 0 25%;
    float: left;
  }

}</style>