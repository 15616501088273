import ApiService from './ApiService';
import apiConfig from '../config/config';
var $options = {};
var OrderService = {
findAll: function() {
    return new Promise(function(resolve, reject) {
        $options.path = "account"
        $options.baseUrl=apiConfig.dashboardUrl
        $options.method = "get"
        ApiService.request($options)
            .then(response => {
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
    });
}
};
export default OrderService;