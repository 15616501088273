<template>
  <PrimeTable
    :tableName="'Discount Codes'"
    :activity="permission"
    :showTableHeader="true"
    :headers="column"
    :tableData="data"
    :PushButton="this.showPushButton"
    @addDialog="addDialog"
    @rowAction="rowAction($event)"    
    :modelName="'discount_codes'"
    @filterData="applyFilter($event)"
  ></PrimeTable>

  <v-dialog v-model="dialog" width="75%" persistent>
    <AddDiscount
      @closeDialog="closeDialog()"
      :type="type"
      :id="editDataId"
      :showReadOnly="showReadOnly"/>
  </v-dialog>
  <v-dialog v-model="confirmation" width="400px" persistent>
    <DialogConfirmation
      @cancelled="cancelled()"
      @deleted="deleted"
      :content="confirmationContent"
    />
  </v-dialog>
</template>

<script>
import AddDiscount from "@/modules/setting/AddAdmin/AddDiscount.vue";
import DiscountCodeService from "@/services/mws/DiscountCodeService";
import DialogConfirmation from "@/components/DialogConfirmation.vue";
import moment from "moment";

export default {
  components: {
    AddDiscount,
    DialogConfirmation,
  },
  data() {
    return {
      permission: null,
      showPushButton: false,
      dialog: false,
      confirmation: false,
      data: [],
      mwsId: localStorage.getItem("mwsHeaderId"),
      column: [
        { title: "Name", align: "start", sortable: false, key: "name" },
        { title: "Discount Type", align: "start", key: "type" },
        { title: "Start Date", align: "start", key: "start_date" },
        { title: "End Date", align: "start", key: "end_date" },
        { title: "Modified by", align: "start", key: "users" },
        { title: "Modified on", align: "start", key: "modified" },
        { title: "Status", key: "active", sortable: false },
        { title: "Action", key: "actions", sortable: false },
      ],
      active: [
        { id: 1, name: "Active" },
        { id: 0, name: "Inactive" },
      ],
    };
  },
  created() {
    let $value = {};
    this.getDiscountCode($value); 
  },
  methods: {
    rowAction(e) {
      if (e.type == "edit") {
        this.dialog = true;
        this.type = e.type;
        this.editDataId = e.value.id;
        this.showReadOnly = false;
      }
      if (e.type == "view") {
        this.dialog = true;
        this.type = e.type;
        this.showReadOnly = true;
        this.editDataId = e.value.id;
      }
      if (e.type == "delete") {
        this.dialog = false;
        this.confirmation = true;
        this.confirmationContent = `Do you want to delete the Discount Code ${e.value.name} ?`;
        this.editDataId = e.value.id;
      }
      if (e.type == "select") {
        this.dialog = true;
        this.type = e.type;
        this.showReadOnly = true;
        this.editDataId = e.value.id;
      }
    },
    closeDialog() {
      this.dialog = false;
      this.getDiscountCode();
    },
    addDialog() {
      this.editDataId = null;
      this.dialog = true;
      this.type = "add";
      this.showReadOnly = false;
    },
    async deleted() {
      this.confirmation = false;
      await DiscountCodeService.delete({ id: this.editDataId });
      this.getDiscountCode();
    },
    cancelled() {
      this.editDataId = null;
      this.confirmation = false;
    },
    applyFilter($value){
        this.getDiscountCode($value)
    },
    async getDiscountCode($value) {
      if (this.$route.fullPath == '/marketPlace/discountCode'){
        this.showPushButton = true;
        this.permission = 'Discount Code In Marketpl'
      }else{
        this.permission = 'Discount Code'
      }
      // let $result = await DiscountCodeService.findAll({mws_header_id:this.mwsId});
      // console.log($result, "result");
      // this.data = $result;
      // this.data.map((obj) => {
      //   return (obj.active = this.active.find(
      //     (type) => type.id == obj.active
      //   ).name);
      // });

      await DiscountCodeService.findAll({ mws_header_id: this.mwsId, ...$value})
        .then((response) => {
          console.log(response, "working11111");
          this.data = response;
          this.data.map((obj) => {(obj.active = this.active.find((type) => type.id == obj.active).name);});
          this.data.map((obj)=>{
            const formattedDate = obj.start_date ? moment(obj.start_date).format('YYYY-MM-DD') : '';
            const formattedTime = obj.start_time ? moment(obj.start_time, 'HH:mm:ss').format('HH:mm') : '';
            obj.start_date = `${formattedDate} ${formattedTime}`.trim();
            
            const formattedEndDate = obj.end_date ? moment(obj.end_date).format('YYYY-MM-DD') : '';
            const formattedEndTime = obj.end_time ? moment(obj.end_time, 'HH:mm:ss').format('HH:mm') : '';
            obj.end_date = `${formattedEndDate} ${formattedEndTime}`.trim();
            
            return obj.start_date,obj.end_date;
          })
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>