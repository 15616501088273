<template>
  <v-card>
    <v-form @Submit.prevent="save" ref="form">
      <v-card-title>
        {{
          this.type == "add"
            ? "Add"
            : this.type == "edit"
            ? "Edit"
            : this.type == "view"
            ? "View"
            : this.type == "select"
            ?"View"
            :''
        }}
        Shipment Rule
        <v-btn
          density="comfortable"
          @click="closeDialog"
          elevation="0"
          icon="mdi-close"
          style="float: right"
        >
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" class="sub-title pa-0 pb-4">
              If the consignment Ships from
            </v-col>
            <v-col cols="4" class="pa-0 ">
              <AutoComplete
                :data="shipmentRule.shipping_zone_id"
                @textData="($event) => {shipmentRule.shipping_zone_id = $event;}"
                :items="shippingZones"
                :text="'name'"
                :value="'id'"
                :rules="shippingZone"
                :disabled="showReadOnly"
                :label="'Shipping Zone'"
              ></AutoComplete>
            </v-col>
            <v-col cols="4" class="pa-0 pl-2">
              <AutoComplete
                :data="shipmentRule.fulfillment_id"
                @textData="($event) => {shipmentRule.fulfillment_id = $event;}"
                :items="fulFillments"
                :text="'name'"
                :rules="fulfillment"
                :value="'id'"
                :disabled="showReadOnly"
                :label="'Fulfillment Center'"
              ></AutoComplete>
            </v-col>
            <v-col cols="4" class="pa-0 pl-2">
              <AutoComplete
               :data="shipmentRule.shipment_id"
                @textData="($event) => {shipmentRule.shipment_id = $event;}"
                :items="shipmentServices"
                :text="'name'"
                :rules="shipment"
                :value="'id'"
                :disabled="showReadOnly"
                :label="'Shipment Provider'"
              ></AutoComplete>
            </v-col>

            <v-col cols="12" class="sub-title pa-0 pb-3">
              And this meets the condition
            </v-col>
            <v-col cols="12" class="pa-0">
              <v-radio-group
                :disabled="showReadOnly"
                inline
                class="radio-group"
                v-model="shipmentRule.rule_apply"
                color="primary"
              >
                <v-radio
                  label="Should meet all the condition"
                  value="all"
                ></v-radio>
                <v-radio label="Any one of condtion " value="any"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row
            v-for="(qualify, index) in shipmentRule.qualifiers"
            :key="qualify"
          >
            <v-col :cols="3" class="pa-0">
              <InputBox
                :label="'Qualifier'"
                :data="qualify.qualifier"
                :rules="qualifyRule"
                @textData="($event) => {qualify.qualifier = $event;}"
                :disabled="showReadOnly"
              />
            </v-col>
            <v-col :cols="3" class="pa-0 pl-2">
              <AutoComplete
                :data="qualify.operator"
                @textData="($event) => {qualify.operator = $event;}"
                :items="operands"
                :rules="operatorRule"
                :text="'name'"
                :value="'id'"
                :disabled="showReadOnly"
                :label="'Operator'"
              ></AutoComplete>
            </v-col>
            <v-col :cols="3" class="pa-0 pl-2">
              <InputBox
               :data="qualify.value"
                @textData="($event) => {qualify.value = $event;}"
                :label="'Value'"
                :rules="valueRule"
                :disabled="showReadOnly"
              />
            </v-col>
            <v-col :cols="3" class="pa-0 pl-2 pt-7" v-if="!showReadOnly">
              <v-btn
                variant="outlined"
                color="primary"
                @click="addRule()"
                density="compact"
                icon="mdi-plus"
              ></v-btn>
              <v-btn
                variant="outlined"
                color="primary"
                class="ml-2"
                @click="deleteRule(index)"
                v-if="index > 0"
                density="compact"
                icon="mdi-delete"
              ></v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="sub-title pa-0 pb-4">
              Use this Shipment Services
            </v-col>
            <v-col cols="4" class="pa-0">
              <AutoComplete
                :data="shipmentRule.shipper_id"
                @textData="($event) => {shipmentRule.shipper_id = $event;}"
                :items="shippers"
                :text="'name'"
                :rules="shipperRule"
                :value="'id'"
                :disabled="showReadOnly"
                :label="'Shipper'"
              ></AutoComplete>
            </v-col>
            <v-col cols="4" class="pa-0 pl-2">
              <AutoComplete
                :data="shipmentRule.shipment_service_id"
                @textData="($event) => {shipmentRule.shipment_service_id = $event;}"
                :items="shipmentServices"
                :rules="serviceRule"
                :text="'name'"
                :value="'id'"
                :disabled="showReadOnly"
                :label="'Service'"
              ></AutoComplete>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="dialogfooter pa-5" v-if="!showReadOnly">
        <v-spacer> </v-spacer>
        <v-btn class="mr-1 cancel" @click="closeDialog">Cancel</v-btn>
        <v-btn class="save" type="submit">Save</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
  <script>
import MwsPreferenceService from "@/services/mws/MwsPreferenceService";
export default {
  props: [
    "type",
    "id",
    "showReadOnly",
    "shipments",
    "shippers",
    "shipmentServices",
    "shippingZones",
    "fulFillments",
  ],
  data() {
    return {
      headers: ["Qualifier", "Operator", "Value", ""],
      mwsHeader: {
        id: null,
        rule_type: "product_preferences",
        mws_header_id: null,
      },
      shipmentRule: {
        shipping_zone_id: null,
        fulfillment_id: null,
        shipment_service_id: null,
        shipper_id: null,
        shipment_id: null,
        rule_apply: "all",
        qualifiers: [],
      },
      mwsId: localStorage.getItem("mwsHeaderId"),
      operands: [
        { id: "not_in", name: "Not In" },
        { id: "in", name: "In" },
        { id: "starts", name: "Starts With" },
        { id: "ends", name: "Ends WIth" },
        { id: "contains", name: "Contains" },
        { id: "=", name: "Equals" },
        { id: ">", name: "Greater than" },
        { id: "<", name: "Less than" },
        { id: ">=", name: "Greater than or Equal" },
        { id: "<=", name: "Less than or Equal" },
        { id: "!", name: "Exclude" },
      ],
      shippingZone: [(value) => !!value || "Shipping Zone is Required"],
      fulfillment: [(value) => !!value || "Fulfillment is Required"],
      shipment: [(value) => !!value || "Shipment is Required"],
      qualifyRule:[(value) => !!value || "Qualifier is Required"],
      operatorRule:[(value) => !!value || "Operator is Required"],
      valueRule:[(value) => !!value || "Value is Required"],
      shipperRule:[(value) => !!value || "Shipper is Required"],
      serviceRule:[(value) => !!value || "Service is Required"],
    };
  },
  created() {
    if (!this.id) {
      this.shipmentRule.qualifiers.push({
        qualifier: null,
        operator: null,
        value: null,
      });
    } else {
      this.getMws();
    }
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },

    async save() {
      let validation = await this.$refs.form.validate();
      if (!validation.valid) {
        console.log(validation);
      } else {
        this.mwsHeader.mws_header_id = this.mwsId;
        let $obj = Object.assign({}, this.shipmentRule);
        this.mwsHeader.preferences = JSON.stringify($obj);
        this.mwsHeader.rule_type = "shipment_rules";
        if (this.mwsHeader && this.mwsHeader.id) {
          await MwsPreferenceService.update(this.mwsHeader);
          this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
          this.closeDialog();
        } else {
          try {
            await MwsPreferenceService.create(this.mwsHeader);
            this.$store.dispatch("setSnackbar", { text: "Saved Successfully" });
            this.closeDialog();
          } catch (e) {
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "Something Went Wrong",
            });
          }
        }
      }
    },
    async getMws() {
      let $result = await MwsPreferenceService.findAll({
        id: this.id,
        mws_header_id: this.mwsId,
        rule_type: "shipment_rules",
      });
      $result = $result[0];
      if ($result) {
        let $obj = {};
        $obj.id = $result.id;
        $obj.preferences =
          $result.preferences && typeof $result.preferences == "string"
            ? JSON.parse($result.preferences)
            : {};
        $obj.rule_type = "shipment_rules";
        this.mwsHeader = $obj;
        this.shipmentRule = this.mwsHeader.preferences;
        console.log(this.shipmentRule,'result')
      }
    },
    addRule() {
      this.shipmentRule.qualifiers.push({
        qualifier: null,
        operator: null,
        value: null,
      });
    },
    deleteRule($index) {
      this.shipmentRule.qualifiers.splice($index, 1);
      this.shipmentRule.qualifiers = [...this.shipmentRule.qualifiers];
    },
  },
};
</script>