<template>
  <div>
    <v-menu
      :close-on-content-click="false"
    >
    <template v-slot:activator="{ props }">
    <v-text-field
      density="compact"
      variant="outlined"
      class="text-Field"
      :class="{ expendSearch: isActive }"
      id="myTextField"
      v-bind="props"
      placeholder="Global Search"
      append-inner-icon="mdi-magnify"
      v-model="searchData"
      @click.stop="focuseSearch"
      @keyup="searchProductData(searchData)"
    ></v-text-field>
    </template>
      <v-card width="800" @click.stop="focuseSearch">
        <v-card-subtitle class="pa-4 font-weight-bold text-caption">
          Showing results
          <span style="float: right">products</span>
        </v-card-subtitle>
        <v-card-text>
          <v-row
            class="productList"
            v-for="product in ProductListData"
            :key="product"
          >
            <v-col cols="1" class="pt-5">
              <v-img :src="product.img" style="width: 70%" />
            </v-col>
            <v-col class="font-weight-black"
              >{{ product.description }}
              <div class="font-weight-light text-caption">
                SKU : {{ product.sku }}
                <span style="float: right"
                  >{{ product.stores }} Available
                  {{ product.variants }} Variants</span
                >
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import DsProductService from "@/services/catalogs/DsProductService";

export default {
  data() {
    return {
      isActive:false,
      searchData: null,
      ProductListData: [],
    };
  },
  created() {
    this.getProduct();
    document.addEventListener('click', this.onClick);
  },
  bebeforeDestroy(){
    document.removeEventListener('click', this.onClick);
  },
  methods: {
    onClick() {
      this.isActive = false
    },
    focuseSearch(){
      this.isActive = true
      document.getElementById("myTextField").focus();
    },
    async getProduct() {
      const res = await DsProductService.findAll();
      this.ProductListData = res;
      this.ProductData = res;
    },

    searchProductData($event) {
      console.log($event, "eventIn");
      this.ProductListData = this.ProductData.filter(
        (f) =>
          (f.description &&
            f.description.toLowerCase().includes($event.toLowerCase())) ||
          (f.sku && f.sku.toLowerCase().includes($event.toLowerCase()))
      );
    },
  },
};
</script>

<style scoped>
.text-Field {
  width: 400px;
  margin-top: 20px;
  margin-right: 10px;
  margin-left: 10px;
}
.text-Field:focus-within {
  width: 800px;
  margin-right: 10px;
}
.mdi-magnify::before {
  padding-top: 8px;
}.productList:hover{
  border: 1px solid #00d3ff;
  border-radius: 5px;
}
>>>.v-overlay__content {
  top: 55px !important;
}.expendSearch{
  width: 800px;
}
</style>