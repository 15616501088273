<template>
  <v-card>
    <v-form @submit.prevent="save" ref="form">
      <v-card-title class="dialog-Title"
        >{{
          type == "edit"
            ? "Edit"
            : type == "view"
            ? "View"
            : type == "add"
            ? "Add"
            : type == "select"
            ? "View"
            : ""
        }}
        Exchange Rates
        <v-btn
          density="comfortable"
          @click="closeDialog"
          elevation="0"
          icon="mdi-close"
          style="float: right"
        ></v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-container>
          <v-row v-for="(item,index) in displayArray" :key="index">
            <v-col cols="3" class="pa-1">
              <AutoComplete
                    :data="item.from_currency_id"
                    :items="currencies"
                    :text="'display_value'"
                    :value="'data_value'"
                    :disabled="showReadOnly"
                    label="From Currecy"
                    @textData="validateFromCurrency($event,item,index)"
                  ></AutoComplete>
            </v-col>
            <v-col cols="3" class="pa-1">
              <AutoComplete
                    :data="item.to_currency_id"
                    :items="currencies"
                    :text="'display_value'"
                    :value="'data_value'"
                    :disabled="showReadOnly"
                    label="To Currency"
                    @textData="validateToCurrency($event,item,index)"
                  ></AutoComplete>
                  <span style="color:red" v-if="item.to_currency_id && item.to_currency_id==item.from_currency_id">From and To Currencies are same</span>
            </v-col>
            <v-col cols="3" class="pa-1 mt-5">
              <DatePicker :placeholder="'Trans Date'" :data="item.trans_date" @textData="($event) => {item.trans_date= $event;}"
 :disabled="true" />
            </v-col>
            <v-col cols="2" class="pa-1 mt-5" style="display: flex;">
              <v-tooltip location="top" text="Add">
                <template v-slot:activator="{ props }">
                  <v-sheet v-bind="props" border rounded :height="45" :width="45" class="tableicon" @click="addRow">
                    <v-icon>mdi-plus</v-icon>
                  </v-sheet>
                </template>
              </v-tooltip>
          
              <v-tooltip location="top" text="Delete" class="ml-2" v-if="index>0">
                <template v-slot:activator="{ props }">
                  <v-sheet v-bind="props" border rounded :height="45" :width="45" class="tableicon" @click="deleteRow(index)">
                    <v-icon>mdi-delete</v-icon>
                  </v-sheet>
                </template>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row v-if="invalidRow">
              <span style="color:red">Does not allow the dublicate entries</span>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="dialogfooter">
        <v-spacer></v-spacer>
        <v-btn class="mr-1 cancel" @click="closeDialog">Cancel</v-btn>
        <v-btn class="save" type="submit">Sync Rate</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import DatePicker from "@/components/DatePicker.vue";
import EnumService from "@/services/admin/EnumService";
import ExchangeRateService from "@/services/catalogs/ExchangeRateService";
import apiConfig from "@/api/config/config";
import axios from "axios";
export default {
  props: ["type","totalArray"],
  data(){
    return{
      displayArray:[],
      currencies:[],
      showReadOnly:false,
      invalidRow:false
    }
  },
  components: {
    DatePicker
  },
  watch:{
    totalArray(){
      this.displayArray = this.totalArray
      if(this.displayArray.length==0){
        this.addRow()
      }else{
        this.displayArray.forEach(e=>{
          e.trans_date = new Date()
        })
      }
    }
  },
  created(){
    this.getCurrencies()
    if(this.totalArray){
      this.displayArray = this.totalArray
      this.displayArray.forEach(e=>{
          e.trans_date = new Date()
        })
    }
    if(this.displayArray.length==0){
        this.addRow()
      }
  },
  methods: {
    async getCurrencies(){
      let $data = await EnumService.findAll({domain:'currencies',company_id:0});
      this.currencies = $data && $data.length>0 ? $data : []
    },
    addRow(){
      this.displayArray.push({from_currency_id:null,to_currency_id:null,trans_date:new Date()})
    },
    deleteRow($index){
      this.displayArray.splice($index,1)
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
    validateFromCurrency(value,item,index){
      item.from_currency_id = value
      this.validateRow(item,index)
    },
    validateToCurrency(value,item,index){
      item.to_currency_id = value
      this.validateRow(item,index)
    },
    validateRow(item,index){
      console.log(item,index,'itemmmmmaddddddddd')
      if(item.from_currency_id && item.to_currency_id && this.displayArray.length>1){
        let $index = this.displayArray.findIndex((k,i)=>(k.from_currency_id==item.from_currency_id && k.to_currency_id==item.to_currency_id) && index!=i);
        if($index>-1){
          this.invalidRow=true;
        }else{
          this.invalidRow = false;
        }
      }
    },
    async save() {
      let validation = await this.$refs.form.validate();
      console.log(validation);
      if(!validation){
        this.invalidRow=true;
      }else {
        let $valid = true;
        this.displayArray.forEach(async e=>{
          let $flag = e.from_currency_id==e.to_currency_id;
          if($flag){
            $valid=false;
          }
        })
        if($valid){
          let $total = [];
          let $fromCurrencies = this.displayArray.map(l=>l.from_currency_id);
          $fromCurrencies = $fromCurrencies.filter((v,i,a)=>a.findIndex(v2=>(v2===v))===i);
          $fromCurrencies.forEach(async (e,index)=>{
            await axios.get(`${apiConfig.exchangeRateApi}${e.toUpperCase()}`).then($res=>{
            let $ar = this.displayArray.filter(k=>k.from_currency_id==e);
            console.log($ar,'$res.data[k.to_currency_id.toUpperCase()]111')
            $ar.forEach(k=>{
              // console.log(k.to_currency_id.toUpperCase(),'$res.data[k.to_currency_id.toUpperCase()]22')
              // console.log($res.data.data[k.to_currency_id.toUpperCase()],'$res.data[k.to_currency_id.toUpperCase()]')
              k.rate = $res.data.data[k.to_currency_id.toUpperCase()].value
            })
            $total = $total.concat($ar)
            console.log($total,'$res.data[k.to_currency_id.toUpperCase()]')
            if(index==$fromCurrencies.length-1){
              alert('hi')
              $total.forEach(async (e,index)=>{
                await ExchangeRateService.create(e)
                if(index==$total.length-1){
                  this.closeDialog();
                }
              })
            }
            })
            
          })   
          
        }else{
          this.invalidRow=true;
        }
      }
      
    },
  },
};
</script>

<style>
</style>