<template>
    <v-card>
        <v-card-title class="subheading">
            <strong> Expression Builder </strong>
            <v-btn @click="applyExpression" style="margin-left:45%" small class="indigo white--text mr-2 save">Apply</v-btn>
            <v-btn
            density="comfortable"
            @click="closeDialog"
            elevation="0"
            icon="mdi-close"
            style="float: right"
            >
            </v-btn>
            <!-- <v-icon @click="applyExpression" small class="grey---text"> fas fa-times</v-icon> -->
           
        </v-card-title>
        <hr>
        <v-card-text>    
            <v-row  no-gutters dense>
                <v-col cols="12" lg="8" md="8" sm="8"> 
                <v-textarea 
                id="expression"
                class="mt-4 mr-2"
                outlined 
                 v-model="expression"
                 rows="5"
                 />
                 
                 <v-btn @click="selectColumn('SUM(<expr>)')" small class="grey black--text ma-1" >SUM</v-btn>
                 <v-btn @click="selectColumn('AVG(<expr>)')" small class="grey black--text ma-1" >AVG</v-btn>
                 <v-btn @click="selectColumn('MAX(<expr>)')" small class="grey black--text ma-1" >MAX</v-btn>
                 <v-btn @click="selectColumn('MIN(<expr>)')" small class="grey black--text ma-1" >MIN</v-btn>
                 <v-btn @click="selectColumn('+')" small class="grey black--text ma-1" >+</v-btn>
                  <br/>
                 <v-btn @click="selectColumn('1')" small class="grey black--text ma-1" >1</v-btn>
                 <v-btn @click="selectColumn('2')" small class="grey black--text ma-1" >2</v-btn>
                 <v-btn @click="selectColumn('3')"  small class="grey black--text ma-1" >3</v-btn>
                 <v-btn @click="selectColumn('4')"  small class="grey black--text ma-1" >4</v-btn>
                 <v-btn @click="selectColumn('-')"  small class="grey black--text ma-1" >-</v-btn>
                
                 <br/>
                 <v-btn  @click="selectColumn('5')" small class="grey black--text ma-1" >5</v-btn>
                 <v-btn  @click="selectColumn('6')" small class="grey black--text ma-1" >6</v-btn>
                 <v-btn  @click="selectColumn('7')" small class="grey black--text ma-1" >7</v-btn>
                 <v-btn  @click="selectColumn('8')" small class="grey black--text ma-1" >8</v-btn>
                 <v-btn  @click="selectColumn('*')" small class="grey black--text ma-1" >*</v-btn>
                 
                
                 <br/>
                 <v-btn  @click="selectColumn('9')" small class="grey black--text ma-1" >9</v-btn>
                 <v-btn  @click="selectColumn('0')" small class="grey black--text ma-1" >0</v-btn>
                 <v-btn  @click="selectColumn('(')"  small class="grey black--text ma-1" >(</v-btn>
                 <v-btn  @click="selectColumn(')')"  small class="grey black--text ma-1" >)</v-btn>
                <v-btn   @click="selectColumn('/')" small class="grey black--text ma-1" >/</v-btn>
                 <br/>
                 <v-btn @click="selectColumn('%')" small class="grey black--text ma-1" >%</v-btn>
                 <v-btn @click="clear()"  small class="grey black--text ma-1" >CLR</v-btn>
                
                </v-col >
                <v-col cols="12" lg="4" md="4" sm="4">
                    <v-card>
                        <v-card-title class="body-2 indigo white--text mt-1"><strong>Choose Columns</strong></v-card-title>
                        <v-card-text>
                            <v-row  no-gutters dense style="height:300px;overflow:scroll">
                      <template v-for="reportColumn in (columns)" :key="reportColumn">
                            <v-col cols="12" lg="12" md="12" sm="12" class="body-1 " style="padding:5px;">
                               <span  style="cursor:pointer" @click="selectColumn(reportColumn.key)"> {{reportColumn.label}}</span>
                            </v-col >
                       </template>
                       </v-row >
                        </v-card-text>
                    </v-card>
                </v-col >
               
            </v-row >
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    components:{

    },
    props: [
        "columns","formula"
    ],
    data() {
        return {
            "expression":"",
            "functions":[
                "sum",'if','case','max','min','count','avg'
            ]
        }
    },
    created:function() {
        console.log(this.columns,'formula')
        if(this.formula){
            this.expression = this.formula
        }
    },
    mounted:function() {

    },
    methods: {
        clear(){
          this.expression = ''
        },
        applyExpression() {
            this.$emit('applyExpression',this.expression)
        },
        closeDialog(){
            this.$emit('closeDialog')
        },
        selectColumn($key) {
            //this.expression = this.expression+ $key
            var txtarea = document.getElementById("expression");
            var start = txtarea.selectionStart;
            var finish = txtarea.selectionEnd;
            var allText = txtarea.value;
            // obtain the selected text
            // var sel = allText.substring(start, finish);
            //append te text;
            var newText=allText.substring(0, start)+" "+$key+" "+allText.substring(finish, allText.length);

            console.log(newText);

            //txtarea.value=newText;
            this.expression = newText
        }
    }
}
</script>