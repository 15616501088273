<template>
    <AvatarSelection v-on:selected="selectAccount($event)" :moreItems="moreItems"
        v-if="accountData && accountData.length > 0" :avatarData="accountData" />
</template>
<script>
import AccountService from "@/services/admin/AccountService";
import AvatarSelection from "./AvatarSelection.vue";
// import { authStore } from "@/store";
export default {
    components: {
        AvatarSelection
    },
    computed: {
        getAccount() {
            return this.$store.state.accountStatus;
        },
    },
    watch: {
        tableData() {
            this.displayData = this.tableData;
            this.setDefault()
        },
        getAccount(){
            this.getAccountData();
        }
    },
    data() {
        return {
            accountData: [],
            moreItems: [],
            avatarName: "",
            show: false,
        }
    },
    async created() {
        await this.getAccountData();
    },
    methods: {
        getRandomColor(index) {
            const availableColors = ["#C7D4E7", "#E7CED1", "#FDEFCA", "#F5D4DF"];
            if (index >= 0 && index < availableColors.length) {
                return availableColors[index];
            }
            return "#000000";
        },
        async getAccountData() {
            try {
                let res = await AccountService.findAllByCompanyXref({});
                if (res.length >= 4) {
                    this.moreItems = res.splice(4, res.length - 4)
                    console.log(this.moreItems,'moreItemsmoreItems')
                }
                this.accountData = res.length > 0 ? res : [];
            } catch (e) {
                this.accountData = [];
            }
        },
        selectAccount(data) {
            console.log(data, 'accounttttttt')
            localStorage.setItem("_ACCOUNT_ID_", data.id);
        }
    }
}
</script>
<style>
>>>.p-avatar-group .p-avatar:hover {
    /* border: 2px solid #ffffff; */
    cursor: pointer;
    width: 3rem;
    height: 3rem;
    z-index: 9999;
}
</style>