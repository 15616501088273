const apiConfig = {
    "gatewayUrl":"https://79a4z7n2g5.execute-api.ap-south-1.amazonaws.com/dev",
    "baseUrl":"https://xte-frontend.tiburaxtend.com/",
    "dasboardUrl":"http://localhost:3090",
    "appName": "Identti Front End",
    "apiKey": "apiKey",
    "secrect": "secrect",
    "adminUrl":"https://dev-xte.tibura.services:3032",
    "catalogUrl":"https://dev-xte.tibura.services:3035",
    "mwsUrl":"https://dev-xte.tibura.services:3033",
    "orderUrl":" https://dev-xte.tibura.services:3034",
    "exchangeRateApi":"https://v6.exchangerate-api.com/v6/513728b103b1efefc74015de/latest/"
}
export default apiConfig;
