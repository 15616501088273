<template>
  <PrimeTable
    :tableName="'Fulfillments'"
    activity="Fulfillments"
    :showTableHeader="true"
    :headers="column"
    :tableData="data"
    @addDialog="addDialog"
    @rowAction="rowAction($event)"
    @reload="getFulfillments"
    :modelName="'fulfillments'"
    @filterData="applyFilter($event)"
  ></PrimeTable>

  <v-dialog v-model="dialog" width="600px" persistent>
    <AddFulfillment
      @closeDialog="closeDialog()"
      :type="type"
      :id="editDataId"/>
  </v-dialog>
  <v-dialog v-model="confirmation" width="300px" persistent>
    <ConfirmationDialog @cancelled="cancelled()" @deleted="deleted" :content="confirmationContent" />
  </v-dialog>
</template>

<script>
import FulfillmentModel from "@/services/catalogs/FulfillmentService";
import AddFulfillment from "@/modules/setting/AddAdmin/AddFulfillments.vue";
import ConfirmationDialog from "@/components/DialogConfirmation.vue";

export default {
  components: {
    AddFulfillment,
    ConfirmationDialog,
  },
  data() {
    return {
      data: [],
      fulFillmentID: "",
      confirmationContent:null,
      confirmation:false,
      column: [
        { title: "Name", align: "start", sortable: false, key: "name" },
        { title: "Code", align: "start", key: "code" },
        { title: "Erp Key", align: "start", key: "erp_key" },
        { title: "Fulfillment Type", align: "start", key: "type" },
        // { title: "Ship Station",align: "start",key: "ship_station_integration"},
        { title: "Modified by", align: "start", key: "users" },
        { title: "Modified on", align: "start", key: "modified" },
        { title: "Status", key: "active", sortable: false },
        { title: "Action", key: "actions", sortable: false },
      ],
      FulfilmentType: [
        { id: "M", name: "Marketplace Fulfillment" },
        { id: "S", name: "Seller Fulfillment" },
      ],
      active: [
        { id: 1, name: "Active" },
        { id: 0, name: "Inactive" },
      ],
      dialog: false,
    };
  },
  created() {
    let $value = {};
    this.getFulfillments($value);
  },
  methods: {
    applyFilter($value){
      this.getFulfillments($value)
    },
    async getFulfillments($value) {
      await FulfillmentModel.findAll($value)
      .then(response => { 
            console.log(response,"working11111")
            this.data = response
            this.data.map(obj=>{ 
              return obj.active = this.active.find(type=>type.id==obj.active).name
            })
          })
        // .then((response) => {
        //   console.log(response, "working11111");
        //   this.data = response;
        //   this.data.map((obj) => {
        //     obj.fullfilment_type = this.FulfilmentType.find(
        //       (type) => type.id == obj.fullfilment_type
        //     ).name;
        //     return (obj.active = this.active.find(
        //       (type) => type.id == obj.active
        //     ).name);
        //   });
        // })
        .catch((error) => {
          console.log(error);
        });
    },
    rowAction(e) {
      if (e.type == "edit") {
        this.dialog = true;
        this.editDataId = e.value.id;
        this.type = e.type;
      }
      if (e.type == "view") {
        this.dialog = true;
        this.editDataId = e.value.id;
        this.type = e.type;
      }
      if (e.type == "delete") {
        this.dialog=false;
        this.confirmation = true;
        this.confirmationContent = `Do you want to delete the Fulfillment ${e.value.name} ?`
        this.editDataId = e.value.id;
      }
      if (e.type == "select") {
        this.dialog = true;
        this.editDataId = e.value.id;
        this.type = e.type;
      }
    },
    async deleted(){
      this.confirmation = false;
       await FulfillmentModel.delete({id:this.editDataId});
      this.getFulfillments();
    },
    cancelled(){
      this.editDataId = null;
      this.confirmation = false;
    },
    addDialog() {
      this.dialog = true;
      this.type = "add";
      this.editDataId = null;
    },
    closeDialog() {
      this.dialog = false;
      this.getFulfillments();
    },
  },
};
</script>