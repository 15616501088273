<template>
    <v-container justify-center class="text-center mt-5 pt-5">
        <v-col align="center">
            <Loader style="padding-top:220px; padding-left:500px" />
        </v-col>
    </v-container>
</template>
<script>
import FirebaseModel from '../api/service/FirebaseService.js'
import Loader from './Loader.vue'
import moment from 'moment'
export default {
    components: {
        Loader,
    },
    data() {
        return {
            host: window.location.host,
            parts: window.location.host.split('.'),
            user: {}
        }
    },
    mounted: async function () {
        if (this.$route.query.loginToken) {
            this.user = await FirebaseModel.getAuthInfo(this.$route.query.loginToken)
            console.log(this.user, 'mmmmmmmm')
            try {
                localStorage.setItem('tiburaXtend', JSON.stringify({}))
                localStorage.setItem('_CNAME_', this.user.data.User.company.code)
                localStorage.setItem('_CUSER_', this.user.data.User.first_name)
                localStorage.setItem('_LAST_REQUEST_', moment().unix())
                localStorage.setItem('_CSID_', this.user.data.sessionId)
                localStorage.setItem('_TPMIT_', this.user.data.token)
                localStorage.setItem('_CUID_', this.user.data.User.id)
                localStorage.setItem('userDetails', JSON.stringify(this.user.data.User.userData))
                localStorage.setItem('companyDetails', JSON.stringify(this.user.data.User.company))
                let userData = this.user.data.User.userData
                console.log(userData, 'verifyUserDataaaaaaaaaaa')
                if (userData.first_time_user == 0) {
                    const $data = {
                        token: this.$route.query.loginToken,
                        redirectUrl: window.location.href,
                        userId: this.user.data.User.id,
                        validToken:this.user.data.token
                    };
                    const queryParams = Object.keys($data).map(key => `${encodeURIComponent(key)}=${encodeURIComponent($data[key])}`).join("&");
                    window.location = `${process.env.VUE_APP_ON_BOARDING}?` + queryParams;
                }
                else {
                    let token = localStorage.getItem('XTE_TOKEN_TMP')
                    localStorage.setItem('XTE_TOKEN', token)
                    localStorage.removeItem('XTE_TOKEN_TMP')
                    if (this.host.indexOf("localhost") >= 0) {
                        // this.$router.push("/autoLogin?loginToken=" + $token);
                        window.location = "http://" + this.host + "/accounts"
                    } else {
                        let $team = this.user.data.User.company && this.user.data.User.company.portal_id ? this.user.data.User.company.portal_id : localStorage.getItem('companyName')
                        window.location =
                            "https://" +
                            $team +
                            "." +
                            this.parts[this.parts.length - 2] +
                            "." +
                            this.parts[this.parts.length - 1] +
                            "/accounts"
                    }
                }
            } catch (err) {
                console.log(err)
            }

        } else {
            this.redirectToSso()
        }
    },
    methods: {
        moment,
        redirectToSso() {
            window.location.host;
            window.location = process.env.VUE_APP_ONEAPP_DOMAIN + 'signIn?'
        },
        async authenticateToken() {
            let $response = await FirebaseModel.firebaseAuth({ token: this.$route.query.token })
            if ($response.data.length == 1) {
                this.redirectToApp($response.data[0].team, $response.data[0].token)
            }
        },
        redirectToApp($team, $token) {
            if (this.host.indexOf('localhost') >= 0) {
                this.$router.push("/login?loginToken=" + $token)
            } else {
                window.location = 'https://' + $team + '.' +
                    this.parts[this.parts.length - 2] + '.' +
                    this.parts[this.parts.length - 1] + "/login?loginToken=" + $token
            }
        }
    }
}
</script>