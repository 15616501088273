<template>
    <v-col class="ml-2 settingHeader"><v-icon color="#000000" class="mr-2" @click="$router.go(-1)">mdi-arrow-left-circle-outline</v-icon>Template Settings </v-col>
    <v-row class="pa-5 pt-0 contentsubmenu">
            <v-card min-height="60vh" style="border-radius: 8px;" elevation="0" class="content-menu-cardleft">
                <!-- <AutoComplete class="mt-1"  :items="data" :singleLine="true" label="Marketplace"></AutoComplete> -->
                <v-list  class="content-menu-list">
                    <v-list-item   :to="item.router" class="pa-4 contentsidemenu" v-for="(item, index) in items" :key="index" :value="index"
                        active-class="selecteditem">
                        <v-list-item-title >{{ item.title }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-card>

            <v-card  elevation="0" class="content-menu-cardright">
                <RouterView :key="$route.fullPath"></RouterView>              
            </v-card>
    </v-row>
</template>

<script>
export default {
    data(){
        return{
            items: [
                {
                    title: 'Email Template',
                    value: 1,
                    router: '/templates/email',
                },
                {
                    title: 'SMS Template',
                    value: 2,
                    router: '/templates/sms'
                },
                {
                    title: 'Whatsapp',
                    value: 3,
                    router: '/templates/whatsapp'
                },
                {
                    title: 'Notification',
                    value: 4,
                    router: '/templates/notification'
                }
            ],
        }
    },
    methods:{
        // goTo($val){
        //     this.$router.push(`${$val.router}`)
        // }
    }
}
</script>

<style>

</style>