<template>
    <svg width="145" height="42" viewBox="0 0 145 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M31.2885 7.79477C31.2604 7.59034 31.0818 7.47693 30.9336 7.46458C30.7865 7.45224 27.9047 7.40845 27.9047 7.40845C27.9047 7.40845 25.4946 5.06798 25.2565 4.82988C25.0185 4.5918 24.5535 4.66367 24.3727 4.71758C24.3705 4.7187 23.9201 4.85796 23.1621 5.09268C23.0352 4.68164 22.8487 4.17626 22.5825 3.66864C21.7245 2.03121 20.4678 1.16533 18.9494 1.16309C18.9472 1.16309 18.946 1.16309 18.9438 1.16309C18.8382 1.16309 18.7338 1.1732 18.6283 1.18218C18.5833 1.12827 18.5384 1.07549 18.4912 1.02382C17.8297 0.316297 16.9819 -0.0284834 15.9655 0.00183924C14.0046 0.0579924 12.0516 1.47417 10.4681 3.98983C9.354 5.75978 8.50608 7.98347 8.26576 9.7051C6.014 10.4025 4.43946 10.8899 4.40465 10.9012C3.26811 11.2583 3.23218 11.2931 3.08393 12.3645C2.97387 13.1742 0 36.1701 0 36.1701L24.9219 40.4804L35.7235 37.7951C35.7235 37.7951 31.3166 7.99919 31.2885 7.79477ZM21.9143 5.47901C21.3404 5.65646 20.6879 5.85861 19.9804 6.07761C19.9658 5.08482 19.8479 3.70346 19.3852 2.50964C20.8732 2.79153 21.6055 4.475 21.9143 5.47901ZM18.6765 6.48191C17.3704 6.88619 15.9452 7.32756 14.5156 7.77007C14.9176 6.23034 15.6802 4.69736 16.6169 3.69222C16.965 3.31824 17.4524 2.90159 18.0296 2.66349C18.5721 3.79554 18.69 5.39815 18.6765 6.48191ZM16.0047 1.30683C16.4652 1.29673 16.8527 1.39781 17.184 1.61568C16.6539 1.89083 16.1418 2.28615 15.6611 2.80163C14.4156 4.13808 13.461 6.21238 13.0803 8.21367C11.8932 8.5809 10.732 8.94144 9.6628 9.27163C10.3378 6.12141 12.9781 1.39443 16.0047 1.30683Z" fill="#95BF47"/>
<path d="M30.9348 7.4658C30.7878 7.45346 27.906 7.40967 27.906 7.40967C27.906 7.40967 25.4959 5.06919 25.2578 4.83111C25.1691 4.74238 25.0489 4.69633 24.9231 4.67725L24.9242 40.4794L35.7247 37.7953C35.7247 37.7953 31.3178 8.00042 31.2898 7.79599C31.2616 7.59156 31.082 7.47815 30.9348 7.4658Z" fill="#5E8E3E"/>
<path d="M18.936 13.0193L17.6816 17.7125C17.6816 17.7125 16.2823 17.0757 14.6235 17.1802C12.1909 17.3341 12.1651 18.8681 12.1899 19.2534C12.3224 21.3524 17.8444 21.8106 18.1544 26.7273C18.3981 30.5952 16.1026 33.2411 12.7952 33.45C8.82512 33.7004 6.63965 31.3588 6.63965 31.3588L7.48084 27.7808C7.48084 27.7808 9.68088 29.4407 11.4418 29.3295C12.5919 29.2565 13.0029 28.321 12.9613 27.6595C12.7884 24.9214 8.29166 25.0832 8.00755 20.5842C7.76832 16.7984 10.2548 12.962 15.7409 12.6161C17.8545 12.4802 18.936 13.0193 18.936 13.0193Z" fill="white"/>
<path d="M49.9475 22.8468C48.7055 22.1729 48.0672 21.6048 48.0672 20.824C48.0672 19.8304 48.9539 19.1921 50.3385 19.1921C51.9503 19.1921 53.3894 19.866 53.3894 19.866L54.5245 16.3884C54.5245 16.3884 53.4808 15.5719 50.4087 15.5719C46.1335 15.5719 43.1705 18.0202 43.1705 21.4622C43.1705 23.4138 44.554 24.9042 46.3997 25.9679C47.8901 26.819 48.4226 27.4227 48.4226 28.3094C48.4226 29.2317 47.6774 29.9769 46.2939 29.9769C44.232 29.9769 42.2849 28.912 42.2849 28.912L41.0786 32.3896C41.0786 32.3896 42.8776 33.596 45.904 33.596C50.304 33.596 53.4619 31.4316 53.4619 27.5285C53.4608 25.4366 51.8645 23.9462 49.9475 22.8468Z" fill="black"/>
<path d="M67.4761 15.5375C65.3116 15.5375 63.6084 16.5667 62.2963 18.1273L62.225 18.0916L64.1052 8.26367H59.2085L54.4543 33.2775H59.3511L60.983 24.7272C61.6212 21.498 63.2887 19.5119 64.8505 19.5119C65.9498 19.5119 66.3765 20.2571 66.3765 21.322C66.3765 21.9959 66.3052 22.8124 66.1637 23.4863L64.3191 33.2787H69.2161L71.1315 23.1666C71.3447 22.1017 71.487 20.8252 71.487 19.973C71.485 17.205 70.0304 15.5375 67.4761 15.5375Z" fill="black"/>
<path d="M82.5551 15.5375C76.6646 15.5375 72.7629 20.8598 72.7629 26.7846C72.7629 30.5808 75.1041 33.6318 79.5043 33.6318C85.2876 33.6318 89.1906 28.4521 89.1906 22.3847C89.1906 18.8725 87.1321 15.5375 82.5551 15.5375ZM80.1425 29.8723C78.4747 29.8723 77.765 28.4532 77.765 26.6788C77.765 23.8762 79.2202 19.2992 81.8811 19.2992C83.6198 19.2992 84.1865 20.7895 84.1865 22.2443C84.1865 25.2596 82.7333 29.8723 80.1425 29.8723Z" fill="black"/>
<path d="M101.715 15.5375C98.4097 15.5375 96.5341 18.447 96.5341 18.447H96.464L96.748 15.8215H92.4193C92.2068 17.596 91.8155 20.2916 91.4262 22.3145L88.0198 40.2317H92.9165L94.2639 32.9935H94.3711C94.3711 32.9935 95.3757 33.6318 97.2453 33.6318C102.993 33.6318 106.753 27.7426 106.753 21.781C106.753 18.4827 105.298 15.5375 101.715 15.5375ZM97.0314 29.9425C95.7603 29.9425 95.008 29.2329 95.008 29.2329L95.8244 24.6559C96.3925 21.6049 97.9888 19.5821 99.6923 19.5821C101.183 19.5821 101.643 20.9656 101.643 22.2789C101.643 25.4368 99.7631 29.9425 97.0314 29.9425Z" fill="black"/>
<path d="M113.743 8.51208C112.183 8.51208 110.941 9.75409 110.941 11.3503C110.941 12.8051 111.863 13.7987 113.247 13.7987H113.318C114.844 13.7987 116.156 12.7694 116.192 10.9605C116.192 9.5413 115.234 8.51208 113.743 8.51208Z" fill="black"/>
<path d="M106.896 33.2775H111.791L115.127 15.9285H110.195L106.896 33.2775Z" fill="black"/>
<path d="M127.581 15.8928H124.175L124.352 15.0763C124.636 13.4088 125.629 11.9183 127.262 11.9183C128.133 11.9183 128.822 12.1667 128.822 12.1667L129.78 8.33494C129.78 8.33494 128.929 7.90942 127.118 7.90942C125.38 7.90942 123.643 8.40619 122.329 9.54126C120.661 10.9604 119.881 13.0178 119.491 15.0763L119.35 15.8928H117.078L116.368 19.5832H118.64L116.05 33.2786H120.947L123.537 19.5832H126.907L127.581 15.8928Z" fill="black"/>
<path d="M139.36 15.9285C139.36 15.9285 136.299 23.64 134.925 27.8495H134.853C134.76 26.4939 133.647 15.9285 133.647 15.9285H128.502L131.448 31.8584C131.52 32.2127 131.484 32.4266 131.342 32.6749C130.773 33.7744 129.816 34.8393 128.68 35.6201C127.758 36.294 126.729 36.7195 125.913 37.0035L127.261 41.1551C128.255 40.9424 130.312 40.1259 132.051 38.494C134.287 36.4009 136.344 33.1717 138.472 28.7718L144.469 15.9274H139.36V15.9285Z" fill="black"/>
</svg>

</template>