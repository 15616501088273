<template>
  <div class="pa-4">
    <v-form ref="form">
      <v-row>
        <v-col cols="10" class="text-h6"> Stock Keeping Unit </v-col>
        <v-col cols="2" class="d-flex dialogfooter justify-end">
          <v-btn class="mr-2 cancel" @click="close()">Cancel</v-btn>
          <v-btn class="save" @click="save()">Save</v-btn>
        </v-col>
        <v-col cols="4" class="pt-6"
          ><AutoComplete
            :data="formData.sku"
            @textData="($event) => {formData.sku = $event;}"
            :disabled="showReadOnly"
            :items="ProductData"
            :text="'sku'"
            :value="'id'"
            :label="'Select SKU'"
            :@change="updateStyleEditData(formData.sku)"
          />
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="8">
          <AddProductVariants
            @productVarient="productVarient"
            :validation="validation1"
            @validCheck="variantsValidation($event)"
            :productData="styleEditData"
          />
          <AddProductPricing
            class="mt-4"
            @pricingData="pricingData"
            :validation="validation1"
            @validCheck="updateValid2($event)"
            :productData="styleEditData"
          />
          <AddProductPricingPerRegion
            class="mt-4"
            @pricingRegionData="pricingRegionData"
            :pricePerRegionData="pricePerRegionData"
          />
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import AutoComplete from "@/components/AutoComplete.vue";
import AddProductPricing from "./AddProductPricing.vue";
import AddProductPricingPerRegion from "./AddProductPricingPerRegion.vue";
import AddProductVariants from "./AddProductVariants.vue";
// import StyleService from "@/services/catalogs/StyleService";
import ProductService from "@/services/catalogs/ProductService";
import PricePerRegionService from "@/services/catalogs/PricePerRegionService";
export default {
  components: {
    AutoComplete,
    AddProductPricing,
    AddProductPricingPerRegion,
    AddProductVariants,
  },
  data() {
    return {
      pricingPre: [],
      validation1: false,
      ProductData: [],
      pricePerRegionData:[],
      styleEditData: {},
      accountIdData: JSON.parse(localStorage.getItem("_ACCOUNT_ID_")),
      styleData: {
        sku: null,
        upc: null,
        variants: false,
        option1: null,
        option2: null,
        option3: null,
        sale_price: null,
        compare_price: null,
        cost: null,
        gross_margin: null,
        style_id: null,
      },
      formData: {
        sku: null,
      },
    };
  },
  created() {
    this.styleIdData = this.$route.query;
    if(this.styleIdData.productId){
      this.getProductPriPricing(this.styleIdData.productId)
    }
    this.getProductData();
    this.getProductPriPricing()
  },
  methods: {
    async getProductData() {
      if (this.styleIdData.styleId && this.styleIdData.productId) {
        let res = await ProductService.findAll({
          style_id: this.styleIdData.styleId,
        });
        this.ProductData =res&&res.length>0 ? res : [];
        if (this.styleIdData) {
          this.formData.sku = JSON.parse(this.styleIdData.productId);
        }
      }else{
        let res = await ProductService.findAll({
          style_id: this.styleIdData.styleId,
        });
        this.ProductData =  res&&res.length>0 ? res : [];
      }
      // this.updateStyleEditData()
    },
    pricingRegionData(qualifiers) {
      this.pricingPre = qualifiers;
    },
    updateStyleEditData(value) {
      if (value) {
        var data = this.ProductData.filter((e) => e.id == value);
        this.styleEditData = data[0];
        this.styleData = data[0];
       this.getProductPriPricing(value)
      }
    },
    async getProductPriPricing(id){
      let res = await PricePerRegionService.findAll({product_id:id})
      this.pricePerRegionData = res
      console.log(this.pricePerRegionData,'pricePerRegionData')
    },
    productVarient(formData) {
      this.styleData.sku = formData.sku;
      this.styleData.upc = formData.upc;
      let $color = formData.color ? formData.color.split(",").map(String) : [];
      let $size = formData.size ? formData.size.split(",").map(String) : [];
      let $material = formData.material
        ? formData.material.split(",").map(String)
        : [];
      if ($color.length > 0 && $size.length == 0 && $material.length == 0) {
        $color.forEach((c) => {
          let data = {
            color: c,
            size: null,
            material: null,
          };
          this.styleData.option1 = data.color;
        });
      } else if (
        $color.length == 0 &&
        $size.length > 0 &&
        $material.length == 0
      ) {
        $size.forEach((s) => {
          let data = {
            color: null,
            size: s,
            material: null,
          };
          this.styleData.option2 = data.size;
        });
      } else if (
        $color.length == 0 &&
        $size.length == 0 &&
        $material.length > 0
      ) {
        $material.forEach((m) => {
          let data = {
            color: null,
            size: null,
            material: m,
          };
          this.styleData.option3 = data.material;
        });
      }
      else if ($color.length > 0 && $size.length > 0 && $material.length == 0) {
        $color.forEach(c => {
          $size.forEach(s => {
            let data = {
              color: c,
              size: s,
              material: null
            }
            this.styleData.variantsData.push(data)
          })
        })
      }
      else if ($color.length > 0 && $size.length == 0 && $material.length > 0) {
        $color.forEach(c => {
          $material.forEach(m => {
            let data = {
              color: c,
              size: null,
              material: m
            }
            this.styleData.variantsData.push(data)
          })
        })
      }
      else if ($color.length == 0 && $size.length > 0 && $material.length > 0) {
        $material.forEach(m => {
          $size.forEach(s => {
            let data = {
              color: null,
              size: s,
              material: m
            }
            this.styleData.variantsData.push(data)
          })
        })
      }
      else if ($color.length > 0 && $size.length > 0 && $material.length > 0) {
        $color.forEach(c => {
          $size.forEach(s => {
            $material.forEach(m => {
              let data = {
                color: c,
                size: s,
                material: m
              }
              this.styleData.variantsData.push(data)
            })
          })
        })
      }
    },

    pricingData(pricingData) {
      this.styleData.taxableProduct = pricingData.taxableProduct;
      this.styleData.pricingvalue = pricingData.pricingvalue;
      this.styleData.sale_price = pricingData.sale_price;
      this.styleData.compare_price = pricingData.compare_price;
      this.styleData.cost = pricingData.cost;
      this.styleData.gross_margin = pricingData.gross_margin;
    },
    variantsValidation(isvalid) {
      console.log(isvalid, "isvalid");
      this.variantValid = isvalid;
      this.submit();
    },
    updateValid2(isvalid) {
      this.pricingvalid = isvalid;
      this.submit();
    },

    save() {
      if (this.pricingvalid && this.variantValid) {
        this.submit();
      } else {
        this.validation1 = !this.validation1;
      }
    },
    async submit() {
      delete this.styleData.variants;
      if(this.styleIdData.styleId){
      this.styleData.style_id = this.styleIdData.styleId;
      }else{
        this.styleData.style_id = this.styleIdData.styleId;
      }
      if (this.pricingvalid && this.variantValid) {
        if (this.styleIdData.productId) {
          await ProductService.update(this.styleData);
          this.pricingPre.forEach(async (k) => {
            k.product_id = this.styleIdData.productId;
            k.style_id = this.styleIdData.styleId;
            k.account_id = this.accountIdData;
          });
          await PricePerRegionService.createBulk(this.pricingPre);
          this.close()
        } else {
         let output =  await ProductService.create(this.styleData);
         console.log(output,'output')
          this.pricingPre.forEach(async (k) => {
            k.product_id = output.data.data.id;
            k.style_id = this.styleIdData.styleId;
            k.account_id = this.accountIdData;
          });
          await PricePerRegionService.createBulk(this.pricingPre);
         this.close()
        }
      }
    },
    close(){
      if( this.styleIdData.styleId){
        this.$router.push("/productview?id=" + this.styleIdData.styleId);
      }else{
        this.$router.push("/productview?id=" + this.styleIdData.styleId);
      }
    }
  },
};
</script>

<style>
</style>