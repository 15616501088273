<template>
      <v-card elevation="0">
        <v-form @submit.prevent="save()" ref="form">
         <v-card-title class="settingHeader">{{this.formData.column_name}} Lookup
            <v-btn density="comfortable" @click="closeDialog" elevation="0" icon="mdi-close" style="float: right"></v-btn>
         </v-card-title>
         <v-divider></v-divider>
         <v-card-text>
           <v-row class="pt-2">
            <v-col cols="3" class="pa-0 pl-2">
                    <InputBox :label="'Defaults'" :data="formData.defaults" @textData="($event) => { formData.defaults = $event; }" :disabled="showReadOnly"></InputBox>
            </v-col>
            <v-col cols="4" class="pa-0 pl-2">
                    <InputBox :label="'Expression'" :data="formData.expression" @textData="($event) => { formData.expression = $event; }" :disabled="showReadOnly"></InputBox>
            </v-col>
            <v-col cols="3" class="pa-0 pl-2">
                    <InputBox :label="'Type'" :data="formData.type" @textData="($event) => { formData.type = $event; }" :disabled="showReadOnly"></InputBox>
            </v-col>
            <v-col class="pt-6" cols="1" align="center">
                <v-btn class="button" type="submit" :disabled="showReadOnly">Apply</v-btn>
            </v-col>
           </v-row>
         </v-card-text>
        </v-form>
       </v-card>
</template>
<script>
import ListModelFilterDetailService from '@/services/admin/ListModelFilterDetailService';

export default{
    props:['editId','type'],
    data(){
        return{
            formData:{},
            showReadOnly: false
        }
    },
    created(){
       this.getLookup();
       if (this.type === 'view'){
            this.showReadOnly = true
        }
    },
    watch:{
    //   type(){
    //     alert
    //   }
    },
    methods:{
        async getLookup(){
         let res = await ListModelFilterDetailService.findAll({id: this.editId});
         this.formData = res[0];
        },
        async save(){
            delete this.formData.users
            await ListModelFilterDetailService.update(this.formData);
            this.$store.dispatch("setSnackbar", { text: "Saved Successfully" });
            this.$emit("closeDialog")
            this.$emit("reload")
        },
        closeDialog(){
            this.$emit("closeDialog")
        }
    }
}
</script>