<template>
    <v-card elevation="0">
        <v-card-title class="dialog-Title">
            SORTING
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
                <v-col cols="12" class="pa-0 pl-2">
                    <AutoComplete label="Columns" :items="columnData" :value="'column_name'" :text="'label'"  :data="selectedItem" @textData="addswitches($event)" :disabled="showReadOnly"></AutoComplete>
                </v-col> 
            <v-row v-for="(row, index) in rows" :key="index" class="row">
                <v-col cols="6" class="pt-6">
                  <span ><i class="pi pi-times pr-3 pl-2" style="font-size: 10px;cursor: pointer;" @click="removeItem(index)" :disabled="showReadOnly"></i></span><span>{{ row.column_name }}</span>
                </v-col>
                <v-col cols="6" align="end" class="">
                    <v-switch label="Asc" v-model="row.asc" @change="switchValue(row)" color="primary" :disabled="showReadOnly"></v-switch>
                </v-col>
            </v-row> 
        </v-card-text>
    </v-card>
</template>
<script>
export default {
  props:['sortingData','data'],
  data() {
    return {
      // switchValue: false,
      columnData: [],
      rows: [],
      selectedItem: [], 
      ViewReports: false,
      showReadOnly: false
    };
  },
  created(){
    if (this.$route.query.type == 'view'){
          this.showReadOnly =true
       }
      this.columnData = this.sortingData;
      this.ViewReports = this.$route.name === 'ViewReports' ? true : false
      this.rows = this.data
  },
  watch:{
    sortingData(){
      this.columnData = this.sortingData;
    },
  },
  methods: {
    switchValue($event){
      this.rows.map((obj) =>{
        if(obj.column_name === $event){
           obj.asc = true
        }
      })
      this.ViewReports ? this.$emit("sortColumn",this.rows) : null
    },
    async addswitches($event) {
      if (this.rows.length>0){
         let duplicate = await this.rows.find(e => e.column_name === $event)
         if(!duplicate){
            this.rows.push({column_name: $event,asc: false})
            this.ViewReports ? this.$emit("sortColumn",this.rows) : null
         }else{
            this.$store.dispatch("setSnackbar", { text: "Column Already Exist" , color: 'error'});
        }
      }else{
        this.rows.push({column_name: $event,asc: false})
        this.ViewReports ? this.$emit("sortColumn",this.rows) : null
      }
    },
    removeItem(index){
      if (!this.showReadOnly){
        this.rows.splice(index,1);
      this.ViewReports ? this.$emit("sortColumn",this.rows) : null
      }
    }
  },
};
</script>
<style scoped>
.row{
  padding-left: 16px;
  margin-left: 5px;
  height: 55px;
}
</style>

