<template>
  <!-- <v-menu>
        <template v-slot:activator="{ props }"> -->
  <!-- <v-btn v-bind="props" variant="outlined">
    <template v-slot:prepend>
      <v-icon @click="prependClicked()">mdi-filter-outline</v-icon>
    </template>
    Filter By
    <template v-slot:append>
      <v-icon @click="appendClicked()" id="menu-activator">mdi-chevron-down</v-icon>
    </template>
  </v-btn> -->

  <v-btn v-if="!showIconOnly" variant="outlined" @click="prependClicked()">
    <template v-slot:prepend>
      <v-icon @click="prependClicked()">mdi-filter-outline</v-icon>
    </template>
    <span>Filter By</span>
  </v-btn>
  <v-tooltip location="top" text="Filter">
    <template v-slot:activator="{ props }">
      <v-sheet @click="prependClicked()" v-if="showIconOnly" v-bind="props"  border rounded :height="45" :width="45"
        class="tableicon">
        <v-icon>mdi-filter-outline</v-icon>
      </v-sheet>
    </template>
  </v-tooltip>
</template>
<script>
import ListModelFilterDetailService from "@/services/admin/ListModelFilterDetailService";
// import MenuComponent from '../components/MenuComponent.vue'
export default {
  props: ["model", "showIcon"],
  components: {
    // MenuComponent
  },
  data() {
    return {
      modelData: {},
      showIconOnly: false,
      modelDetailsData: {},
      filterDetails: [],
      setMenu: null,
      items: [
        {
          name: "Moorthy",
          value: "hello",
        },
        {
          name: "KMoorthy",
          value: "Khello",
        },
      ],
    };
  },
  watch: {
    model() {
      this.getModel();
    },
    showIcon() {
      if (this.showIcon) {
        this.showIconOnly = true
      }
      else {
        this.showIconOnly = false
      }
    }
  },
  created() {
    if (this.model) {
      this.getModel();
    }
    if (this.showIcon) {
      this.showIconOnly = true
    }
    else {
      this.showIconOnly = false
    }
  },
  methods: {
    async getModel() {
      let $model = await ListModelFilterDetailService.getDetails({
        model: this.model,
      });
      if ($model && $model.length > 0) {
        this.filterDetails = $model;
        console.log($model, 'modellllllllllllll')
        this.$emit('oldFilterDetails', this.filterDetails)
      }
    },
    appendClicked() {
      this.setMenu = "#menu-activator";
    },
    prependClicked() {
      this.$emit("prependClicked");
    },
    showFilter($item) {
      this.$emit("appendClicked", $item);
    },
  },
};
</script>