<template>
    <v-row>
        <v-col cols="6" class="pr-1" sm="4" xs="2" md="6" lg="6" xl="6" xxl="12" v-if="!showColumn">
            <v-card elevation="0" class="border pb-10">
                <v-card-title class="dialog-Title">
                    <v-row>
                        <v-col class="pa-0 ma-0" cols="1" style="height: 57px;">
                            <v-checkbox v-model="selectAll" color="primary" @change="selectAllCheckboxes()" :disabled="showReadOnly"/>
                        </v-col>
                        <v-col cols="11" align="left" style="height: 57px;" class="pl-2">
                            <span>COLUMNS</span>
                            <v-btn v-if="!showReadOnly" density="comfortable"  @click="openDialog()" elevation="0" icon="mdi-plus"
                                style="float: right"></v-btn>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pt-0 scroll">
                    <v-container class="mb-12 ml-0 mr-0 pr-0 pl-0 pt-2">
                        <v-row v-for="item in formData" :key="item.index">
                            <v-row>
                                <v-col style="height: 60px;">
                                    <v-checkbox :label="item.label" color="primary" class="pa-0" @input="addchip(item)"
                                        v-model="item.layout_required" :disabled="showReadOnly"></v-checkbox>
                                </v-col>
                                <span align="end" class="pt-7 pr-5">
                                    <v-icon @click="editDialog(item.id)" v-if="!showReadOnly">mdi-pencil</v-icon>
                                            <span class="pl-2" v-if="showFilter">
                                        <i :class="item.filter_required ? 'pi pi-filter-fill' : 'pi pi-filter'" @click="addFilter(item)"
                                            style="cursor: pointer;color: #3f51b5;"></i></span>
                                </span>
                                <v-divider></v-divider>
                            </v-row>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="6" v-if="!showColumn" class="pl-1" sm="4" xs="2" md="6" lg="6" xl="6" xxl="12">
            <v-card elevation="0" class="border">
                <v-card-title class="dialog-Title pa-2">
                    LAYOUT & SORT
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="scroll ma-1">
                    <v-row>
                        <v-chip class="ma-1" color="#3f51b5" v-for="(item, index) in selectedItems" :key="index" closable
                            variant="outlined" @click:close="removeItem(item)" :disabled="showReadOnly">{{ item.label }}</v-chip>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12" v-if="showColumn">
            <AddColumn :type="this.type" class="border" @closeDialog="closeDialog" style="overflow: auto;" :id="column_id"></AddColumn>
        </v-col>
    </v-row>
</template>
<script>
import AddColumn from "@/modules/setting/Reports/AddColumn.vue";


export default {
    props: ['layoutData','layoutArray'],
    components: {
        AddColumn,
        // VirtualScroller,
    },
    data() {
        return {
            selectAll: false,
            showColumn: false,
            type: null,
            showFilter: false,
            layOutView: true,
            selectedItems: [],
            filterAddData: {},
            id: null,
            formData:[{
                layout_required: false
            }],
            layout:[],
            showReadOnly: false,
            column_id: null
        }
    },
    created() {
        if (this.$route.query.type == 'view'){
          this.showReadOnly = true
       }
        if(this.$route.query.id){
           this.getSavedLayoutData();
        }else{
            this.getLayoutData();
        }
    },
    watch:{
    //   formData(){
    //     this.getLayoutData();
    //   },
    //   layoutArray(){
    //     alert("1111")
    //     console.log("array",this.layoutArray)
    //   }
    layoutData(){
        this.getLayoutData();
    }
},
    methods: {
        editDialog($event){
            this.showColumn = true;
            this.column_id = $event
            console.log("dataaaattaa",this.layoutData)
        },
        async getSavedLayoutData() {      
        this.formData = this.layoutData.map((obj) => {
        const isLayoutRequired = this.layoutArray.includes(obj.column_name);
        return {
           ...obj,
           layout_required: isLayoutRequired ? true : false,
    };
  });

        let select = this.formData.filter((item) => item.layout_required == 1);
        this.selectedItems.push(...select);
        this.selectAll = this.formData.length == this.selectedItems.length ? true:false  
},


       async getLayoutData(){
        this.formData = this.layoutData.map((obj) => {
        return {
            ...obj,
            layout_required: obj.layout_required === 0 ? false : true
             };
               });
               this.selectedItems = []

       this.formData.find((e)=>{
        if(e.layout_required == true){
            this.selectedItems.push(e)
        }
       } )

       },
       async addchip(item) {
      if (item.layout_required) {
        const existingItem = this.selectedItems.find(
          (selectedItem) => selectedItem.id === item.id
        );

        if (!existingItem) {
          this.selectedItems.push(item);
        }
      } else {
        const itemIndex = this.selectedItems.findIndex(
          (selectedItem) => selectedItem.id === item.id
        );

        if (itemIndex !== -1) {
          this.selectedItems.splice(itemIndex, 1);
        }
      }
      this.selectAll = this.formData.length == this.selectedItems.length ? true:false  
      this.$emit("savedlayoutData",this.selectedItems)
    },
    async removeItem(item){
        const itemIndex = this.selectedItems.findIndex(
          (selectedItem) => selectedItem.id === item.id
        );

        if (itemIndex !== -1) {
          this.selectedItems.splice(itemIndex, 1);
          this.formData.map((obj)=>{
           if (obj.id == item.id){
            return obj.layout_required = false
           }
          })
        }
        this.$emit("savedlayoutData",this.selectedItems)
    },
    selectAllCheckboxes(){
        if (this.selectAll){
            this.formData.map((obj)=>{
            console.log(obj)
            obj.layout_required = true
            this.selectedItems.push(obj)
        })
        this.$emit("savedlayoutData",this.selectedItems)
        }else{
            this.formData.map((obj)=>{
            console.log(obj)
            obj.layout_required = false
            this.selectedItems = []
        })
        this.$emit("savedlayoutData",this.selectedItems)
        }
    },
    closeDialog(){
        this.showColumn = false 
    },
    openDialog(){
        this.showColumn = true 
    },

    }
}
</script>
<style scoped>
.border {
    border-style: solid;
    border-width: 3px;
    margin: 4px;
    border-radius: 6px;
    border-color: #0000001f;
    height: 500px;
}

.scroll {
    overflow: auto;
    height: 500px;
    overflow-x: hidden;
}

</style>