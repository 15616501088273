<template>
  <v-card>
    <v-form @Submit.prevent="save" ref="form">
      <v-card-title class="dialog-Title"
        >{{
          type == "edit"
            ? "Edit"
            : type == "view"
            ? "View"
            : type == "add"
            ? "Add"
            : type == "select"
            ? "View"
            : ""
        }}
        Sbu
        <v-btn
          density="comfortable"
          @click="closeDialog"
          elevation="0"
          icon="mdi-close"
          style="float: right"
        ></v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="6" class="pa-0">
              <InputBox
              :data="formData.code"
              @textData="checkCode($event)"
                :label="'Code'"
                :disabled="showReadOnly"
                :rules="codeRules"
                @keydowninput="prevent"
              />
              <span v-if="codeError" class="error-field">Already Exists!</span>
            </v-col>
            <v-col cols="6" class="pa-0 pl-2">
              <InputBox
              :data="formData.erp_ref_no"
              @textData="($event) => {formData.erp_ref_no = $event;}"
                :label="'Erp Ref.No'"
                :disabled="showReadOnly"
                :rules="refRules"
              />
            </v-col>
            <v-col cols="6" class="pa-0">
              <InputBox
              :data="formData.name"
              @textData="($event) => {formData.name = $event;}"
                :label="'Name'"
                :disabled="showReadOnly"
                :rules="nameRules"
              />
            </v-col>
            <v-col cols="6" class="pa-0 pl-2">
              <InputBox
              :data="formData.erp_key"
              @textData="($event) => {formData.erp_key = $event;}"
                :label="'Erp Key'"
                :disabled="showReadOnly"
                :rules="keyRules"
              />
            </v-col>
            <v-col cols="12" class="pa-0">
              <AutoComplete
              :data="formData.division_id"
              @textData="($event) => {formData.division_id = $event;}"
                :label="'Division'"
                :disabled="showReadOnly"
                :items="divisionData"
                :value="'id'"
                :text="'name'"
                :rules="divisionRules"
              />
            </v-col>
            <!-- <v-divider></v-divider> -->
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="dialogfooter" v-if="!showReadOnly">
        <v-spacer></v-spacer>
        <v-btn class="mr-1 cancel" @click="closeDialog">Cancel</v-btn>
        <v-btn class="save" type="submit">Save</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
import SbuService from "@/services/admin/SbuService.js";
import DivisionService from "@/services/admin/DivisionService.js";
export default {
  props: ["type", "id"],
  components: {},
  data() {
    return {
      codeError:false,
      codeExistFlag:false,
      codeRules: [(value) => !!value || "Code is Required"],
      refRules: [(value) => !!value || "Erp Ref.No is Required"],
      nameRules: [(value) => !!value || "Name is Required"],
      keyRules: [(value) => !!value || "Erp Key is Required"],
      divisionRules:[(value)=> !!value || "Division is Required"],
      formData: {},
      showReadOnly: false,
      divisionData: [],
      showRow: false,
      // rows: [
      //   {
      //     fieldName: null,
      //     operand: null,
      //     value: null,
      //   },
      // ],
    };
  },
  created() {
    if (this.id) {
      this.getEditData();
    }
    if (this.type == "view" || this.type == "select") {
      this.showReadOnly = true;
    }
    this.getDivision();
  },
  methods: {
    async getEditData() {
      let $res = await SbuService.findAll({ id: this.id });
      this.formData = $res[0];
      console.log(this.formData, "formData");
    },
    async getDivision() {
      let $res = await DivisionService.findAll();
      this.divisionData = $res&&$res.length>0 ? $res : [];
      console.log(this.formData, "formData");
    },

    closeDialog() {
      this.$emit("closeDialog");
    },
    prevent(e) {
  if (!/^[a-zA-Z0-9-_ \t]+$/.test(e.key) && e.key !== "Backspace") {
    e.preventDefault();
  }
},
checkCode($event) {
            this.formData.code =$event
      if (this.formData.code && this.formData.code.length > 0) {
        this.formData.code = this.formData.code.toUpperCase();

        SbuService.findAll({ code: this.formData.code }).then((res) => {
          let $exist = res.filter((key) => key.code == this.formData.code);

          this.codeExistFlag =
            $exist.length > 0
              ? this.formData.id && $exist[0].id == this.formData.id
                ? false
                : true
              : false;

          if (this.codeExistFlag) {
            this.codeError = true;
          } else {
            this.codeError = false;
          }
        });
      }
    },
    async save() {
      console.log(this.formData, "data");
      let validation = await this.$refs.form.validate();
      if (!validation.valid ||this.codeError) {
        console.log(validation);
      } else {
        if (this.formData && this.formData.id) {
          delete this.formData.users
          let output = await SbuService.update(this.formData);
         this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
          console.log(output);
          this.closeDialog();
        } else {
          try{
          let output = await SbuService.create(this.formData);
          console.log(output, "output", this.formData);
          this.$store.dispatch('setSnackbar', { text: "Saved Successfully" });
          this.closeDialog();
        }
        catch (e) {
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "Something Went Wrong",
            });
          }
      }
      }
    },
  },
};
</script>
