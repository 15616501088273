<template>
  <PrimeTable
    :tableName="'Shipment Services'"
    :showTableHeader="true"
    :headers="column"
    activity="Shipment Service"
    :tableData="data"
    @rowAction="rowAction"
    @addDialog="addDialog"
    :modelName="'shipment_services'"
    @filterData="applyFilter($event)"
  ></PrimeTable>
  <v-dialog v-model="dialog" width="600px" persistent>
    <AddShipmentService @closeDialog="closeDialog" :type="type" :id="editDataId" />
  </v-dialog>
  <v-dialog v-model="confirmation" width="300px" persistent>
    <ConfirmationDialog
      @cancelled="cancelled()"
      @deleted="deleted"
      :content="confirmationContent"
    />
  </v-dialog>
</template>

<script>
import ShipmentServiceService from "@/services/admin/ShipmentServiceService.js";
import ConfirmationDialog from "@/components/DialogConfirmation.vue";
import AddShipmentService from "@/modules/setting/AddAdmin/AddShipmentService.vue";
export default {
  components: {
    AddShipmentService,
    ConfirmationDialog,
  },
  data() {
    return {
      dialog: false,
      editDataId: "",
      data: [],
      confirmationContent: null,
      confirmation: false,
      column: [
        { title: "Name", align: "start", sortable: false, key: "name" },
        { title: "Code", align: "start", key: "code" },
        { title: "Delivery Days", align: "start", key: "delivery_days" },
        { title: "Modified by", align: "start", key: "users" },
        { title: "Modified on", align: "start", key: "modified" },
        { title: "Status", align: "start", key: "active" },
        { title: "Action", key: "actions", sortable: false },
      ],
      active: [
        { id: 1, name: "Active" },
        { id: 0, name: "Inactive" },
      ],
    };
  },
  created() {
    let $value = {};
    this.getShipmentService($value);
  },
  methods: {
    applyFilter($value){
        this.getShipmentService($value);
    },
    async getShipmentService($value) {
      await ShipmentServiceService.findAll($value)
        .then((response) => {
          this.data = response;
          this.data.map((obj) => {
            return (obj.active = this.active.find((type) => type.id == obj.active).name);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    rowAction(e) {
      if (e.type == "edit") {
        this.dialog = true;
        this.type = e.type;
        this.editDataId = e.value.id;
      }
      if (e.type == "view") {
        this.dialog = true;
        this.type = e.type;
        this.editDataId = e.value.id;
      }
      if (e.type == "delete") {
        this.dialog = false;
        this.confirmation = true;
        this.confirmationContent = `Do you want to delete the Shipment Service ${e.value.name} ?`;
        this.editDataId = e.value.id;
      }
      if (e.type == "select") {
        this.dialog = true;
        this.type = e.type;
        this.editDataId = e.value.id;
      }
    },

    addDialog() {
      this.editDataId = null;
      this.dialog = true;
      this.type = "add";
    },
    closeDialog() {
      this.dialog = false;
      this.getShipmentService();
    },
    async deleted() {
      this.confirmation = false;
      await ShipmentServiceService.delete({ id: this.editDataId });
      this.getShipmentService();
    },
    cancelled() {
      this.editDataId = null;
      this.confirmation = false;
    },
  },
};
</script>
