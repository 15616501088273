<template>
  <v-col class="font-weight-black pt-2 mb-2 pl-2 pb-0">Orders</v-col>
  <v-card class="Card" elevation="0" width="100%">
  <v-row class="pl-4 pr-4">
      <v-col cols="12" class="pt-0">
        <v-tabs
          v-model="tab"
          show-arrows
          color="#5E29F5"
          grow
          class="mt-4"
        >
          <v-tab value="1">Recent Orders</v-tab>
          <v-tab value="2">Orders in Risk</v-tab>
          <v-tab value="3">Due Today</v-tab>
          <v-tab value="4">Cancelled</v-tab>
        </v-tabs>
        <v-window v-model="tab">
          <v-window-item value="1">
            <PrimeTable :headers="recentHeader"  :tableData="recentOrderData">
            </PrimeTable>
          </v-window-item>
          <v-window-item value="2">
            <PrimeTable :headers="orderHeader" :tableData="orderData" />
          </v-window-item>
          <v-window-item value="3">
            <PrimeTable :headers="dueTodayHeader" :tableData="dueTodayData" />
          </v-window-item>
          <v-window-item value="4">
            <PrimeTable :headers="cancelHeader" :tableData="cancelData" />
          </v-window-item>
        </v-window>
      </v-col>
  </v-row>
</v-card>
</template>

<script>
import DashBoardService from "@/services/admin/DashBoardService";
// import { authStore } from "@/store";
import moment from "moment";
export default {
  components: {

  },
  props: ["time","tableData","mwsHeaders"],
  data() {
    return {
      timePeriod: null,
      marketPlaceId: null,
      tab: null,
      recentOrderData: [],
      orderData: [],
      cancelData: [],
      dueTodayData: [],
      recentHeader: [
        {
          title: "Store",
          align: "start",
          sortable: false,
          key: "mws_header_name",
        },
        { title: "Order No", align: "start", key: "order_ref_id" },
        // { title: "Cust Po", align: "start", key: "erp_cust_num" },
        { title: "Merchant Order", align: "start", key: "merchant_order_id" },
        { title: "Order Date", align: "start", key: "order_date" },
        // { title: "No of Items", align: "start", key: "no_of_items" },
        // { title: "Open Items", align: "start", key: "open_items" },
        { title: "Stage", align: "start", key: "stageName" },
      ],
      orderHeader: [
        {
          title: "Store",
          align: "start",
          sortable: false,
          key: "mws_header_name",
        },
        { title: "Order No", align: "start", key: "order_ref_id" },
        // { title: "Cust Po", align: "start", key: "erp_cust_num" },
        { title: "Merchant Order", align: "start", key: "merchant_order_id" },
        { title: "Order Date", align: "start", key: "order_date" },
        // { title: "No of Items", align: "start", key: "no_of_items" },
        // { title: "Open Items", align: "start", key: "open_items" },
        { title: "Stage", align: "start", key: "stageName" },
      ],
      cancelHeader: [
        {
          title: "Store",
          align: "start",
          sortable: false,
          key: "mws_header_name",
        },
        { title: "Order No", align: "start", key: "order_ref_id" },
        // { title: "Cust Po", align: "start", key: "bill_to_city" },
        { title: "Merchant Order", align: "start", key: "merchant_order_id" },
        { title: "Order Date", align: "start", key: "order_date" },
        // { title: "No of Items", align: "start", key: "no_of_items" },
        // { title: "Open Items", align: "start", key: "open_items" },
        { title: "Stage", align: "start", key: "stageName" },
      ],
      dueTodayHeader: [
        {
          title: "Store",
          align: "start",
          sortable: false,
          key: "mws_header_name",
        },
        { title: "Order No", align: "start", key: "order_ref_id" },
        // { title: "Cust Po", align: "start", key: "erp_cust_num" },
        { title: "Merchant Order", align: "start", key: "merchant_order_id" },
        { title: "Order Date", align: "start", key: "order_date" },
        // { title: "No of Items", align: "start", key: "no_of_items" },
        // { title: "Open Items", align: "start", key: "open_items" },
        { title: "Stage", align: "start", key: "stageName" },
      ],
    };
  },
  computed: {
    TimePeriod() {
      return this.$store.getters.getTime;
    },
    MarketPlace() {
      return this.$store.getters.getMarketPlace;
    },
  },
  watch: {
    TimePeriod() {
      this.timePeriod = this.TimePeriod;
      // this.firstCall();
    },
    MarketPlace() {
      this.marketPlaceId = this.MarketPlace;
      // this.firstCall();
    },
    tableData(){
      this.setContent()
    }
  },
  created: function () {
    // this.firstCall();
    if(this.tableData){
      this.setContent()
    }
  },
  methods: {
    moment,
    async firstCall() {
      this.recentOrder();
      this.orderTable();
      this.cancelTable();
    },
    setContent(){
      this.recentOrderData = this.tableData && this.tableData.recent ? this.tableData.recent : [];
      this.recentOrderData.forEach(e=>{
        e.storeName = this.mwsHeaders && this.mwsHeaders.length>0 ? this.mwsHeaders.filter(k=>k.id==e.mws_header_id)[0].name : null;
        e.stageName = e.stage ? e.stage.toLowerCase()=='c' ? 'Completed' : e.stage.toLowerCase()=='x' ? 'Cancelled': 'Open' : 'Open';
        e.order_date = e.order_date ? moment(e.order_date).format("MMMM DD, YYYY") : null;
      })
      this.orderData = this.tableData && this.tableData.risks ? this.tableData.risks : [];
      this.orderData.forEach(e=>{
        e.storeName = this.mwsHeaders && this.mwsHeaders.length>0 ? this.mwsHeaders.filter(k=>k.id==e.mws_header_id)[0].name : null;
        e.stageName = e.stage ? e.stage.toLowerCase()=='c' ? 'Completed' : e.stage.toLowerCase()=='x' ? 'Cancelled': 'Open' : 'Open';
        e.order_date = e.order_date ? moment(e.order_date).format("MMMM DD, YYYY") : null;
      })
      this.dueTodayData = this.tableData && this.tableData.due ? this.tableData.due : []; 
      this.dueTodayData.forEach(e=>{
        e.storeName = this.mwsHeaders && this.mwsHeaders.length>0 ? this.mwsHeaders.filter(k=>k.id==e.mws_header_id)[0].name : null;
        e.stageName = e.stage ? e.stage.toLowerCase()=='c' ? 'Completed' : e.stage.toLowerCase()=='x' ? 'Cancelled': 'Open' : 'Open';
        e.order_date = e.order_date ? moment(e.order_date).format("MMMM DD, YYYY") : null;
      })
      this.cancelData = this.tableData && this.tableData.cancelled ? this.tableData.cancelled : [];
      this.cancelData.forEach(e=>{
        e.storeName = this.mwsHeaders && this.mwsHeaders.length>0 ? this.mwsHeaders.filter(k=>k.id==e.mws_header_id)[0].name : null;
        e.stageName = e.stage ? e.stage.toLowerCase()=='c' ? 'Completed' : e.stage.toLowerCase()=='x' ? 'Cancelled': 'Open' : 'Open';
        e.order_date = e.order_date ? moment(e.order_date).format("MMMM DD, YYYY") : null;
      })
    },
    async recentOrder() {
      let res = await DashBoardService.getDashBoardData({});
      this.recentOrderdata = res.data.recent;
    },
    async orderTable() {
      let res = await DashBoardService.getDashBoardData({});
      this.orderData = res.data.risks;
    },
    async cancelTable() {
      let res = await DashBoardService.getDashBoardData({});
      this.cancelData = res.data.cancelled;
    },
    async dueTodayTable() {
      let res = await DashBoardService.getDashBoardData({});
      this.dueTodayData = res.data.due
    },
  },
};
</script>

