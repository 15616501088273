<template>
 <v-container>
    <v-row class="pa-3 pb-0">
      <v-col cols="4" class="pa-1 pl-2">
        <AutoComplete :label="'Layouts'" :items="layouts" :text="'name'" :value="'id'"
          :disabled="showReadOnly" 
          :data="layoutData.id"
          @textData="layoutSelection($event)"
           />
      </v-col>
        <v-btn v-if="!showReadOnly"  class="mt-8" color="indigo" density="compact" icon="mdi-plus" @click="addLayout()"></v-btn>
      <v-col cols="4" class="pa-1" v-if="layoutData.id">
        <AutoComplete :label="'Columns'" :items="listDetails" :text="'column_name'" :value="'id'" 
          :data="layoutData.selectedColumn"
          @textData="columnSelection($event)"
          :disabled="showReadOnly" />
      </v-col>
      <v-col cols="3" align="end" class="pt-7" v-if="layoutData.id">
        <v-btn class="button" @click="updateLayouts()">
          Update Layouts
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="pa-3" v-if="layoutData.layout && layoutData.layout.length > 0">
     
        <v-chip color="primary" @click="chipSelection(layout)" v-for="layout in layoutData.layout" :key="layout" draggable
          label class="ma-2">
          {{ layout.column_name }}

          <v-icon end icon="mdi-close" @click.stop="removeChips(layout)"></v-icon></v-chip>
    </v-row>
    <v-row class="pa-3 mt-4" v-if="selectedColumnId">
      <v-col cols="6" class="pa-0 pl-2">
        <InputBox :label="'Label'"
        :data="columnDefinition.label"
          @textData="($event) => {columnDefinition.label = $event;}"
         />
      </v-col>
      <v-col cols="6" class="pa-0 pl-2">
        <AutoComplete :label="'Data Type'" :items="data_types" :text="'name'" :value="'code'"
          :data="columnDefinition.data_type"
          @textData="($event) => {columnDefinition.data_type = $event;}"
          :disabled="showReadOnly" />
      </v-col>
      <v-col cols="6" class="pa-1" v-if="columnDefinition.data_type == 'expression'">
        <TextArea rows="10" :label="'Expression'" v-model="columnDefinition.expression" />
      </v-col>
      <v-col cols="6" class="pa-1">
        <v-row class="pa-2">
          <v-col class="pa-0 pl-2">
            <InputBox :label="'Width'"
            :data="columnDefinition.width"
            @textData="($event) => {columnDefinition.width = $event;}"
              />
          </v-col>
          <v-col class="pa-0 pl-2">
            <InputBox :label="'Color'"
            :data="columnDefinition.color"
            @textData="($event) => {columnDefinition.color = $event;}"
            />
          </v-col>
        </v-row>
        <v-col cols="12" class="pa-0 pl-2">
          <InputBox :label="'Format'"
          :data="columnDefinition.format"
          @textData="($event) => {columnDefinition.format = $event;}"
            />
        </v-col>
        <v-col v-if="layoutData.id">
          <v-btn color="primary" @click="updateDifinition(true)">
            Save Definition
          </v-btn>
        </v-col>
        <!-- <v-col cols="12" align="center" class="pa-1">
          <v-btn-toggle
            v-model="toggle_multiple"
            background-color="primary"
            dark
            variant="outlined"
            divided
            multiple
          >
            <v-btn>
              <v-icon>mdi-format-bold</v-icon>
            </v-btn>

            <v-btn>
              <v-icon>mdi-format-italic</v-icon>
            </v-btn>

            <v-btn>
              <v-icon>mdi-format-underline</v-icon>
            </v-btn>
          </v-btn-toggle>
        </v-col> -->
      </v-col>
    </v-row>
    <v-row class="pa-2">
      <v-col class="pa-1">
        <h4>Group By  <v-btn color="indigo" v-if="!showReadOnly" class="ml-1" size="25" icon="mdi-plus" @click="addgroupBy"></v-btn></h4>
        <v-card flat color="grey-lighten-3" :height="400" class="mt-2">
          <v-card-text>
          <v-row>
            <v-col class="table-title" :cols="4">
              Column
            </v-col>
            <v-col class="table-title" :cols="4">
              Total
            </v-col>
            <v-col class="table-title" :cols="4">
              Action
            </v-col>
          </v-row>
          <v-row v-for="(group, index) in layoutData.group_by" :key="group">
            <v-col :cols="4">
              {{ group && group.columns ? group.columns: "" }}
            </v-col>
            <v-col :cols="4">
              {{ group && group.total ? getName(group.total) : "" }}
            </v-col>
            <v-col :cols="4">
              <v-icon  @click="editGroup(group, index)">
        mdi-pencil
      </v-icon>
            </v-col>
          </v-row>
        </v-card-text>
        </v-card>
      </v-col>
      <v-col class="pa-1">
        <h4>Sorting</h4>

        <v-card class="mt-3" flat color="grey-lighten-3" :height="400" >
          <v-card-text>
          <v-row class="mt-3">
            <v-col cols="12" class="pa-0 pl-2">
              <AutoComplete :label="'Columns'" :items="sortingColumns" :text="'column_name'" :value="'id'"
              :data="sort"
              @textData="sortSelection($event)"
                :disabled="showReadOnly" />
            </v-col>
          </v-row>
          <v-chip color="primary" v-for="layout in layoutData.sort" :key="layout" draggable label class="ma-2">
            {{ layout.column_name }}

            <v-icon end icon="mdi-close" @click.stop="removeSort(layout)"></v-icon></v-chip>
            </v-card-text>
        </v-card>
      </v-col>
      <v-col class="pa-1">
        <h4>Total</h4>
        <v-card flat class="mt-3" color="grey-lighten-3" :height="400">
          <v-card-text>
          <v-row class="mt-3">
            <v-col cols="12" class="pa-0 pl-2">
              <AutoComplete :label="'Columns'" :items="totalColumns" :text="'column_name'" :value="'id'"
              :data="total"
              @textData="totalSelection($event)"
                :disabled="showReadOnly" />
            </v-col>
          </v-row>
          <v-chip color="primary" v-for="layout in layoutData.report_total" :key="layout" draggable label class="ma-2">
            {{ layout.column_name }}

            <v-icon end icon="mdi-close" @click.stop="removeTotal(layout)"></v-icon></v-chip>
            </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="showLayout" :width="600" persistent>
      <v-card>
        <v-form @submit.prevent="save" ref="form">
          <v-card-title class="dialog-Title">{{
            type == "edit"
            ? "Edit"
            : type == "view"
              ? "View"
              : type == "add"
                ? "Add"
                : type == "select"
                  ? "View"
                  : ""
          }}
            Layout
            <v-btn density="comfortable" @click="showLayout = false" elevation="0" icon="mdi-close" style="float: right"></v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="pa-0 pl-2">
                  <InputBox  
                  :data="layoutData.name"
                  @textData="($event) => {layoutData.name = $event;}"
                  :label="'Name'" :rules="nameRules" />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="dialogfooter">
            <v-spacer></v-spacer>
            <v-btn class="mr-1 cancel" @click="showLayout = false">Cancel</v-btn>
            <v-btn class="save" type="submit">Save</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showGroupby" :width="1000" persistent>
      <AddGroupBy :editData="groupByData" :type="groupByType" @saved="updateGroupBy" @closed="showGroupby = false"
        :columns="layoutData.layout"></AddGroupBy>
    </v-dialog>
  </v-container>
</template>

<script>
import InputBox from "@/components/InputBox.vue";
import TextArea from "@/components/TextArea.vue";
import ListModelLayoutService from "@/services/admin/ListModelLayoutService";
import AddGroupBy from "./AddGroupBy.vue";
export default {
  props: ["columns","showReadOnly"],
  components: {
    InputBox,
    TextArea,
    AddGroupBy,
  },
  data() {
    return {
      data_types: [
        { code: "integer", name: "Integer" },
        { code: "boolean", name: "Boolean" },
        { code: "string", name: "String" },
        { code: "varchar", name: "Varchar" },
        { code: "float", name: "Float" },
        { code: "date", name: "Date" },
        { code: "expression", name: "Expression" },
      ],
      toggle_multiple: [0, 1, 2],
      model_id: null,
      layouts: [],
      listDetails: [],
      nameRules: [(value) => !!value || "Name Is Required"],
      layoutData: {
        id: null,
        name: null,
        selectedColumn: null,
        layout: [],
        definition: {},
        group_by: [],
        report_total: [],
        sort: [],
      },
      columnDefinition: {
        label: null,
        data_type: null,
        expression: null,
        width: null,
        color: null,
        format: null,
      },
      selectedColumnId: null,
      showLayout: false,
      groupByType: "add",
      groupByData: {
        columns: [],
        total: []
      },
      groupByIndex: 0,
      showGroupby: false,
      sortingColumns: [],
      totalColumns: [],
      sort: null,
      total: null
    };
  },
  created() {
    if (this.$route.query.id) {
      this.model_id = this.$route.query.id;
      this.getLayouts();
    }
    if (this.columns) {
      this.listDetails = JSON.parse(JSON.stringify(this.columns));
      this.sortingColumns = JSON.parse(JSON.stringify(this.columns));
      console.log(this.columns, 'columnsssssss')
      let $totalColumns = this.columns.filter(f => f.data_type == 'float' || f.data_type == 'bigint')
      this.totalColumns = JSON.parse(JSON.stringify($totalColumns));
    }
  },
  methods: {
    async updateLayouts() {

      let $obj = Object.assign({}, this.layoutData);
      $obj.layout = $obj.layout.map(k => k.column_name);
      $obj.sort = $obj.sort.map(k => k.column_name);
      $obj.report_total = $obj.report_total.map(k => k.column_name);
      $obj.group_by.forEach(e => {
        e.columns = e.columns.map(k => k.column_name);
        e.total = e.total.map(k => k.column_name);
      })
      $obj.layout = $obj.layout ? JSON.stringify($obj.layout) : JSON.stringify([]);
      $obj.sort = $obj.sort ? JSON.stringify($obj.sort) : JSON.stringify([]);
      $obj.report_total = $obj.report_total ? JSON.stringify($obj.report_total) : JSON.stringify([]);
      $obj.group_by = $obj.group_by ? JSON.stringify($obj.group_by) : JSON.stringify([]);
      $obj.definition = $obj.definition ? JSON.stringify($obj.definition) : JSON.stringify({});
      delete $obj.selectedColumn;
      await ListModelLayoutService.save($obj);
    },
    getName($item) {
      return $item.map(k => k.column_name);
    },
    addgroupBy() {
      this.showGroupby = true;
      this.groupByData = {
        columns: [],
        total: []
      };
      this.groupByIndex = 0;
    },
    updateGroupBy($value) {
      if (this.groupByIndex > 0) {
        this.layoutData.group_by[this.groupByIndex] = $value;
      } else {
        this.layoutData.group_by.push($value);
      }
      this.showGroupby = false;
    },
    editGroup($value, $index) {
      this.showGroupby = true;
      this.groupByData = $value;
      this.groupByIndex = $index;
    },
    updateDifinition(value) {
      let $index = this.layoutData.layout.findIndex(
        (k) => k.id == this.selectedColumnId
      );
      if ($index > -1) {
        let $item = this.layoutData.layout[$index];
        let $obj = this.layoutData.definition[$item.column_name];
        if ($obj && Object.keys($obj).length > 0) {
          delete this.layoutData.definition[$item.column_name];
          this.layoutData.definition[$item.column_name] = this.columnDefinition;
        } else {
          this.layoutData.definition[$item.column_name] = this.columnDefinition;
        }
        if(value){
          this.selectedColumnId = null
        }
      }
    },
    addLayout() {
      this.showLayout = true;
    },
    chipSelection($item) {
      this.columnDefinition = {
        label: null,
        data_type: null,
        expression: null,
        width: null,
        color: null,
        format: null,
      }
      this.selectedColumnId = $item.id;
      //console.log($item,'chippppp')
      let $obj = this.layoutData.definition[$item.column_name];
      this.columnDefinition.label =
        $obj && $obj.lable ? $obj.label : $item.label;
      this.columnDefinition.data_type =
        $obj && $obj.data_type ? $obj.data_type : $item.data_type;
      this.columnDefinition.expression =
        $obj && $obj.expression ? $obj.expression : $item.expression;
      this.columnDefinition.width =
        $obj && $obj.width ? $obj.width : $item.width;
      this.columnDefinition.color =
        $obj && $obj.color ? $obj.color : $item.color;
      this.columnDefinition.format =
        $obj && $obj.format ? $obj.format : $item.format;
      this.updateDifinition(false);
    },
    columnSelection($event) {
      this.layoutData.selectedColumn = $event
      let $index = this.listDetails.findIndex(
        (k) => k.id == this.layoutData.selectedColumn
      );
      if ($index > -1) {
        this.layoutData.layout.push(this.listDetails[$index]);
        this.chipSelection(this.listDetails[$index]);
        this.listDetails.splice($index, 1);
        this.listDetails = [...this.listDetails];
      }
      this.layoutData.selectedColumn = null
    },
    removeChips($item) {
      let $index = this.layoutData.layout.findIndex((k) => k.id == $item.id);
      if ($index > -1) {
        this.listDetails.push($item);
        this.layoutData.layout.splice($index, 1);
        this.listDetails = [...this.listDetails];
      }
    },
    sortSelection($event) {
      this.sort = $event
      let $index = this.sortingColumns.findIndex(
        (k) => k.id == this.sort
      );
      if ($index > -1) {
        this.layoutData.sort.push(this.sortingColumns[$index]);
        this.sortingColumns.splice($index, 1);
        this.sortingColumns = [...this.sortingColumns];
      }
    },
    removeSort($item) {
      let $index = this.layoutData.sort.findIndex((k) => k.id == $item.id);
      if ($index > -1) {
        this.sortingColumns.push($item);
        this.layoutData.sort.splice($index, 1);
        this.sortingColumns = [...this.sortingColumns];
      }
    },
    totalSelection($event) {
      this.total = $event
      let $index = this.totalColumns.findIndex(
        (k) => k.id == this.total
      );
      if ($index > -1) {
        this.layoutData.report_total.push(this.totalColumns[$index]);
        this.totalColumns.splice($index, 1);
        this.totalColumns = [...this.totalColumns];
      }
    },
    removeTotal($item) {
      let $index = this.layoutData.report_total.findIndex((k) => k.id == $item.id);
      if ($index > -1) {
        this.totalColumns.push($item);
        this.layoutData.report_total.splice($index, 1);
        this.totalColumns = [...this.totalColumns];
      }
    },
    async layoutSelection($event) {
      this.layoutData.id = $event
      let $data = await ListModelLayoutService.findAll({
        id: this.layoutData.id,
      });
      let $obj = $data && $data.length > 0 ? $data[0] : this.layoutData;
      $obj.definition =
        $obj.definition && typeof $obj.definition == "string"
          ? JSON.parse($obj.definition)
          : {};
      $obj.layout =
        $obj.layout && typeof $obj.layout == "string"
          ? JSON.parse($obj.layout)
          : [];
      $obj.group_by =
        $obj.group_by && typeof $obj.group_by == "string"
          ? JSON.parse($obj.group_by)
          : [];
      $obj.report_total =
        $obj.report_total && typeof $obj.report_total == "string"
          ? JSON.parse($obj.report_total)
          : [];
      $obj.sort =
        $obj.sort && typeof $obj.sort == "string" ? JSON.parse($obj.sort) : [];
      if ($obj.layout && $obj.layout.length > 0) {
        let $dt = [];
        for (let i = 0; i < $obj.layout.length; i++) {
          let $index = this.listDetails.findIndex(k => k.column_name == $obj.layout[i]);
          if ($index > -1) {
            $dt.push(this.listDetails[$index])
            this.listDetails.splice($index, 1)
          }
        }
        $obj.layout = $dt;
      }
      if ($obj.sort && $obj.sort.length > 0) {
        let $ds = [];
        for (let i = 0; i < $obj.sort.length; i++) {
          let $index = this.sortingColumns.findIndex(k => k.column_name == $obj.sort[i]);
          if ($index > -1) {
            $ds.push(this.sortingColumns[$index])
          }
        }
        $obj.sort = $ds;
      }
      if ($obj.report_total && $obj.report_total.length > 0) {
        let $dtt = [];
        for (let i = 0; i < $obj.report_total.length; i++) {
          let $index = this.totalColumns.findIndex(k => k.column_name == $obj.report_total[i]);
          if ($index > -1) {
            $dtt.push(this.totalColumns[$index])
          }
        }
        $obj.report_total = $dtt;
      }
      if ($obj.group_by && $obj.group_by.length > 0) {
        let $dg = [];
        for (let i = 0; i < $obj.group_by.length; i++) {
          let $obj1 = {
            columns: [],
            total: []
          }
          for (let j = 0; j < $obj.group_by[i].columns.length; j++) {
            let $index = this.totalColumns.findIndex(k => k.column_name == $obj.group_by[i].columns[j]);
            if ($index > -1) {
              $obj1.columns.push(this.totalColumns[$index])
            }
          }
          for (let k = 0; k < $obj.group_by[i].total.length; k++) {
            let $index = this.totalColumns.findIndex(l => l.column_name == $obj.group_by[i].total[k]);
            if ($index > -1) {
              $obj1.total.push(this.totalColumns[$index])
            }
          }
          $dg.push($obj1)
        }
        $obj.group_by = $dg;
      }
      this.layoutData = $obj;
    },
    async getLayouts() {
      let $data = await ListModelLayoutService.findAll({
        model_id: this.model_id,
      });
      this.layouts = $data && $data.length > 0 ? $data : [];
    },
    async save() {
      let validation = await this.$refs.form.validate();
      if (validation.valid) {
        let $obj = {};
        $obj.name = this.layoutData.name;
        $obj.model_id = this.model_id;
        await ListModelLayoutService.create($obj);
        this.showLayout = false;
        this.getLayouts();
      }
    },
  },
};
</script>

<style scoped>
.layoutCard {
  overflow: auto;
  width: 100%;
}
</style>