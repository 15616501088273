<template>
  <v-card>
    <v-form @submit.prevent="save" ref="form">
      <v-card-title class="dialog-Title"
        >{{
          type == "edit"
            ? "Edit"
            : type == "view"
            ? "View"
            : type == "add"
            ? "Add"
            : type == "select"
            ? "View"
            : ""
        }}
        Fulfillment
        <v-btn
          density="comfortable"
          @click="closeDialog"
          elevation="0"
          icon="mdi-close"
          style="float: right"
        ></v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="6" class="pa-0">
              <InputBox
                :label="'Code'"
                :data="fulFillment.code"
                @textData="checkCode($event)"
                :rules="codeRules"
                :disabled="showReadOnly"
                @keydowninput="prevent"
              />
              <span v-if="codeError" class="error-field">Already Exists!</span>
            </v-col>
            <v-col cols="6" class="pl-2 pa-0">
              <InputBox
                :label="'Erp Key'"
                :data="fulFillment.erp_key"
                @textData="
                  ($event) => {
                    fulFillment.erp_key = $event;
                  }
                "
                :rules="keyRules"
                :disabled="showReadOnly"
              />
            </v-col>
            <v-col cols="12" class="pa-0">
              <InputBox
                :label="'Name'"
                :data="fulFillment.name"
                @textData="
                  ($event) => {
                    fulFillment.name = $event;
                  }
                "
                :rules="nameRules"
                :disabled="showReadOnly"
              />
            </v-col>
            <v-col cols="12" class="pa-0">
              <AutoComplete
                :label="'Fulfillment Type'"
                :data="fulFillment.type"
                @textData="
                  ($event) => {
                    fulFillment.type = $event;
                  }
                "
                :disabled="showReadOnly"
                :items="fulFillment_type"
                :text="'name'"
                :value="'id'"
                :rules="fulFillmentRule"
              />
            </v-col>
            <!-- <v-col cols="6" class="pa-2">
              <v-switch
                label="Ship Station Integration"
                color="primary"
                v-model="fulFillment.ship_station_integration"
                :disabled="showReadOnly"
              ></v-switch>
            </v-col> -->
            <v-col cols="12" v-if="showAddress">
              <v-btn
                v-if="!showReadOnly"
                prepend-icon="mdi-playlist-plus"
                class="button"
                @click="AddAddress()"
                >{{
                  this.type == "edit" ? "Edit Addresss" : "Add Address"
                }}</v-btn
              >
            </v-col>
            <v-col cols="12" v-if="!showAddress" class="pa-2 pl-2">
              <v-btn
                prepend-icon="mdi-playlist-remove"
                class="button mb-5"
                @click="close()"
                :disabled="showReadOnly"
                >Close</v-btn
              >
            </v-col>
            <v-row v-if="!showAddress" class="pa-2">
              <v-col cols="12" class="pa-0">
                <InputBox
                  :label="'Address1'"
                  :data="fulFillment.address1"
                  @textData="
                    ($event) => {
                      fulFillment.address1 = $event;
                    }
                  "
                  :disabled="showReadOnly"
                />
              </v-col>
              <v-col cols="12" class="pa-0">
                <InputBox
                  :label="'Address2'"
                  :data="fulFillment.address2"
                  @textData="
                    ($event) => {
                      fulFillment.address2 = $event;
                    }
                  "
                  :disabled="showReadOnly"
                />
              </v-col>
              <v-col cols="6" class="pa-0">
                <InputBox
                  :label="'City'"
                  :data="fulFillment.city"
                  @textData="
                    ($event) => {
                      fulFillment.city = $event;
                    }
                  "
                  :disabled="showReadOnly"
                />
              </v-col>
              <v-col cols="6" class="pl-2 pa-0">
                <InputBox
                  :label="'Zip'"
                  :data="fulFillment.zip_code"
                  @textData="
                    ($event) => {
                      fulFillment.zip_code = $event;
                    }
                  "
                  :disabled="showReadOnly"
                  @keypress="allowNumbers($event)"
                  :type="'number'"
                />
              </v-col>
              <v-col cols="6" class=" pa-0">
                <AutoComplete
                  :data="fulFillment.country"
                  @textData="countrySelect($event)"
                  :items="contries"
                  :text="'name'"
                  :value="'id'"
                  :disabled="showReadOnly"
                  label="Country"
                />
              </v-col>
              <v-col cols="6" class="pl-2 pa-0">
                <AutoComplete
                  :data="fulFillment.state"
                  @textData="
                    ($event) => {
                      fulFillment.state = $event;
                    }
                  "
                  :items="displacyStates"
                  :text="'name'"
                  :value="'id'"
                  :disabled="showReadOnly"
                  label="State"
                  @click="selectState"
                ></AutoComplete>
              </v-col>
            </v-row>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="dialogfooter" v-if="!showReadOnly">
        <v-spacer></v-spacer>
        <v-btn class="mr-1 cancel" @click="closeDialog">Cancel</v-btn>
        <v-btn class="save" type="submit">Save</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import FulfillmentService from "@/services/catalogs/FulfillmentService";
import Country from "@/api/service/Countries.json";
import States from "@/api/service/States.json";
export default {
  props: ["type", "id"],
  components: {},
  data() {
    return {
      codeError: false,
      codeExistFlag: false,
      stateData: States,
      displacyStates: [],
      contries: Country,
      showReadOnly: false,
      fulFillmentRule: [(value) => !!value || "Fulfillment Is Required"],
      codeRules: [(value) => !!value || "Code Is Required"],
      keyRules: [(value) => !!value || "Erp Key Is Required"],
      nameRules: [(value) => !!value || "Name Is Required"],
      showAddress: true,
      fulFillment_type: [
        { id: "marketplaceFulfillment", name: "Marketplace Fulfillment" },
        { id: "sellerFulfillment", name: "Seller Fulfillment" },
      ],
      fulFillment: {
        code: null,
        erp_key: null,
        name: null,
        // fulfillment_type:null,
        // ship_station_integration: null,
        address1: null,
        address2: null,
        city: null,
        state: null,
        country: null,
        zip_code: null,
      },
      FulfillmentData: [],
    };
  },
  async created() {
    if (this.type == "view" || this.type == "select") {
      this.showReadOnly = true;
      this.showAddress = false;
    }
    if (this.id) {
      let res = await FulfillmentService.findOne({ id: this.id });
      this.fulFillment = res[0];
    }
  },
  methods: {
    allowNumbers(evt) {
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();
      if (!/^[+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    countrySelect($event) {
      this.fulFillment.country = $event
      this.fulFillment.state = null;
      this.displacyStates = this.stateData[this.fulFillment.country];
    },
    selectState() {
      this.displacyStates = this.stateData[this.fulFillment.country];
    },
    prevent(e) {
      if (!/^[a-zA-Z0-9-_ \t]+$/.test(e.key) && e.key !== "Backspace") {
        e.preventDefault();
      }
    },
    checkCode($event) {
      this.fulFillment.code = $event;
      if (this.fulFillment.code && this.fulFillment.code.length > 0) {
        this.fulFillment.code = this.fulFillment.code.toUpperCase();

        FulfillmentService.findAll({ code: this.fulFillment.code }).then(
          (res) => {
            let $exist = res.filter((key) => key.code == this.fulFillment.code);

            this.codeExistFlag =
              $exist.length > 0
                ? this.fulFillment.id && $exist[0].id == this.fulFillment.id
                  ? false
                  : true
                : false;

            if (this.codeExistFlag) {
              this.codeError = true;
            } else {
              this.codeError = false;
            }
          }
        );
      }
    },

    async save() {
      let validation = await this.$refs.form.validate();
      if (!validation.valid || this.codeError) {
        console.log("Not valid");
      } else {
        delete this.fulFillment.users
        if (this.id) {
          console.log("resssss", this.fulFillment);
          await FulfillmentService.update(this.fulFillment);
          this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
          this.$emit("closeDialog", true);
          this.$emit("reload", true);
        } else {
          try {
            await FulfillmentService.create(this.fulFillment);
            this.$store.dispatch("setSnackbar", { text: "Saved Successfully" });
            this.$emit("closeDialog", true);
            this.$emit("reload", true);
          } catch (e) {
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "Something Went Wrong",
            });
          }
        }
      }
    },
    async getById(id) {
      let res = await FulfillmentService.findOne(id);
      this.FulfillmentData = res[0];
      console.log(this.FulfillmentData, "fulFillmentData");
    },

    closeDialog() {
      this.$emit("closeDialog", true);
    },
    AddAddress() {
      this.showAddress = false;
    },
    close() {
      this.showAddress = true;
    },
  },
};
</script>

<style>
</style>