<template>
  <v-card>
    <v-form @submit.prevent="save" ref="form">
      <v-card-title class="dialog-Title"
        >Create Store
        <v-btn
          density="comfortable"
          @click="closeDialog"
          elevation="0"
          icon="mdi-close"
          style="float: right"
        ></v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pb-0">
        <v-container>
          <v-row>
            <v-col cols="12" class="pa-0 pl-2">
              <InputBox
                :label="'Code'"
                :data="mwsHeader.code"
                 @textData="checkCode($event)"
                :disabled="showReadOnly"
                :rules="codeRule"
              ></InputBox>
              <span v-if="codeError" class="error-field">Already Exists!</span>
            </v-col>
            <v-col cols="12" class="pa-0 pl-2">
              <InputBox
                :label="'Name'"
                :data="mwsHeader.name"
                 @textData="($event) => {mwsHeader.name = $event;}"
                :disabled="showReadOnly"
                :rules="nameRule"
              ></InputBox>
            </v-col>
            <v-col cols="12" class="pa-0 pl-2">
              <EnumDropdown  :rules="marketPlaceRule" :data="mwsHeader.market_place" @textData="($event)=>{ mwsHeader.market_place = ($event) }" :domainName="'marketplaces'"
              :disabled="showReadOnly" :label="'Market Place'" />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="dialogfooter">
        <v-spacer></v-spacer>
        <v-btn class="mr-1 cancel" @click="closeDialog">Cancel</v-btn>
        <v-btn type="submit" class="save">Save</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>

import InputBox from "@/components/InputBox.vue";
import MwsHeaderService from "@/services/mws/MwsHeaderService";
import EnumService from "@/services/admin/EnumService";
export default {
  components: {
    InputBox,
  },
  data() {
    return {
      storeData: ["List", "Value", "Text"],
      nameRule: [(value) => !!value || "Name is Required"],
      codeRule: [(value) => !!value || "Code is Required"],
      marketPlaceRule: [(value) => !!value || "MarketPlace is Required"],
      marketPlaces: [],
      codeError:false,
      mwsHeader: {
        name: null,
        code: null,
        market_place: null,
        active: 1,
        id: null,
      },
    };
  },
  created() {
    this.getEnums();
  },
  methods: {
    closeDialog() {
      this.$emit("close");
    },
    checkCode($event){
      this.mwsHeader.code =$event
      if (this.mwsHeader.code && this.mwsHeader.code.length > 0) {
        this.mwsHeader.code = this.mwsHeader.code.toUpperCase();

        MwsHeaderService.findAll({ code: this.mwsHeader.code }).then((res) => {
          let $exist = res.filter((key) => key.code == this.mwsHeader.code);
          this.codeExistFlag =
            $exist.length > 0
              ? this.mwsHeader.id && $exist[0].id == this.mwsHeader.id
                ? false
                : true
              : false;

          if (this.codeExistFlag) {
            this.codeError = true;
          } else {
            this.codeError = false;
          }
        });
      }
    },
    async save() {
      let validation = await this.$refs.form.validate();
      if (validation.valid || this.codeError) {
        if (this.mwsHeader && this.mwsHeader.id) {
          await MwsHeaderService.update(this.mwsHeader);
          this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
          this.closeDialog();
        } else {
          try{
          await MwsHeaderService.create(this.mwsHeader);
          this.$store.dispatch('setSnackbar', { text: "Saved Successfully" });
          this.closeDialog();
        }
        catch (e) {
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "Something Went Wrong",
            });
          }
      }
      }
    },
    async getEnums() {
      let $res = await EnumService.findAll({ domain: "marketplaces" });
      if ($res && $res.length > 0) {
        this.marketPlaces = $res;
      }
    },
  },
};
</script>

<style>
</style>