<template>
  <v-card
    flat
    class="rounded-lg"
    style="border: 2px solid; border-color: #cfcdcd"
  >
    <v-card-title>Variants</v-card-title>
    <v-card-text>
      <v-form ref="form">
        <v-col cols="4" class="pb-6 pl-0 switch">
          <v-switch
            color="#5E29F5"
            v-model="formData.variants"
            hide-details
            inset
            :label="'Product has Multiple Variants'"
          />
        </v-col>
        <v-row>
          <v-col cols="6" class="pa-0 pl-2" v-if="formData.variants">
            <AutoComplete
              :label="'Variant Options'"
              :data="selectedData"
              @textData="selectVariant($event)"
             :items="variantData" :text="'display_value'"
              :value="'id'" />
            <!-- <AutoComplete
          :items="data"
          v-model="formData.variant"
          :text="'name'"
          chips
          closable-chips
          :multiple="true"
          value="id"
          :label="'Variant Options'"
          :disabled="showReadOnly"
           ></AutoComplete> -->
          </v-col>
          <v-col cols="6" class="pt-5" v-if="formData.variants">
            <v-span v-for="item in chipData" :key="item.id" class="ml-5">
              <v-chip
                class="chip"
                label
                closable
                v-if="item"
                @click:close="removeVariant(item)"
                >{{ item.display_value }}</v-chip
              >
            </v-span>
          </v-col>
          <v-col cols="6" class="pa-0 pl-2">
            <InputBox
              :label="'SKU'"
              :data="formData.sku"
              @textData="productVarient($event)"
              :rules="skuRule"
            />
          </v-col>
          <v-col cols="6" class="pa-0 px-2">
            <InputBox
              :label="'UPC'"
              :data="formData.upc"
              @textData="productVarient1($event)"
              :rules="upcRule"
            />
          </v-col>
          <v-col
            cols="6"
            class="pa-0 pl-2"
            v-for="item in chipData"
            :key="item.id"
          >
            <InputBox
              :label="`${item.display_value}`"
              @input="setFormDataValue(item.data_value, $event)"
              @textData="productVarient2($event)"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import AutoComplete from "@/components/AutoComplete.vue";
import InputBox from "@/components/InputBox.vue";
import EnumService from "../../services/admin/EnumService";
// import EnumDropdown from "@/components/EnumDropdown.vue";
export default {
  props: ["validation", "productData"],
  components: {
    AutoComplete,
    InputBox,
    // EnumDropdown
  },
  data() {
    return {
      skuRule: [(value) => !!value || "SKU is Required"],
      upcRule: [(value) => !!value || "UPC is Required"],
      selectColor: false,
      selectSize: false,
      selectMaterial: false,
      Product: false,
      variant: null,
      formData: {
        upc: null,
        sku: null,
        variants: false,
        // color: null,
        // size: null,
        // material: null,
      },
      chipData: [],
      variantData: [],
      selectedData: [],
    };
  },
  async created() {
    await this.getVariantData();

    if (this.productData) {
      this.formData = this.productData;
      if (this.formData.color || this.formData.size || this.formData.material) {
        this.formData.variants = true;
      }
      if (this.formData.color) {
        this.formData.color = this.formData.color
          ? JSON.parse(this.formData.color)
          : "";
        let data = this.variantData.filter((f) => f.data_value == "color");
        this.chipData.push(data[0]);
      }
      if (this.formData.size) {
        this.formData.size = this.formData.size
          ? JSON.parse(this.formData.size)
          : "";
        let data = this.variantData.filter((f) => f.data_value == "size");
        this.chipData.push(data[0]);
      }
      if (this.formData.material) {
        this.formData.material = this.formData.material
          ? JSON.parse(this.formData.material)
          : "";
        let data = this.variantData.filter((f) => f.data_value == "material");
        this.chipData.push(data[0]);
      }
    }
  },
  computed: {
    switchValue() {
      return this.formData.variants;
    },
  },
  watch: {
    switchValue() {
      if (this.formData.variants == true) {
        (this.chipData = []), (this.selectedData = []);
        // this.formData.color = null,
        // this.formData.size = null,
        // this.formData.material = null
      }
    },
    validation() {
      this.save();
    },
    async productData() {
      await this.getVariantData();
      this.formData = this.productData;

      if (this.formData.color || this.formData.size || this.formData.material) {
        this.formData.variants = true;
      }
      if (this.formData.color) {
        let data = this.variantData.filter((f) => f.data_value == "color");
        this.chipData.push(data[0]);
        // let value = this.formData.color ? JSON.parse(this.formData.color) : ''
        // console.log(value,'valuecolorrrrr')
        // this.formData.color = value.join(',')
      }
      if (this.formData.size) {
        let data = this.variantData.filter((f) => f.data_value == "size");
        this.chipData.push(data[0]);
        // let value = this.formData.color ? JSON.parse(this.formData.color) : ''
        // console.log(value,'valuecolorrrrr')
        // this.formData.size = value.join(',')
      }
      if (this.formData.material) {
        let data = this.variantData.filter((f) => f.data_value == "material");
        this.chipData.push(data[0]);
        // let value = this.formData.color ? JSON.parse(this.formData.color) : ''
        // console.log(value,'valuecolorrrrr')
        // this.formData.material = value.join(',')
      }
    },
  },
  methods: {
    setFormDataValue(dataValue, value) {
      this.formData[dataValue] = value.target.value;
    },
    async getVariantData() {
  
      let res = await EnumService.findAll({ domain: 'varianttype',company_id : 0 })
      this.variantData = res&&res.length>0 ? res : [];
    },
    selectVariant(value) {
      this.selectedData = value;
      if (this.chipData.findIndex((v) => v.id == value) == -1) {
        let data = this.variantData.filter((f) => f.id == value);
        this.chipData.push(data[0]);
        this.$emit("productVarient", this.formData);
      }
    },

    removeVariant(value) {
      const index = this.chipData.indexOf(value);
      this.chipData.splice(index, 1);
    },
    productVarient(value) {
      this.formData.sku = value;
      if (!this.formData.variants) {
        this.chipData = [];
      }
      this.$emit("productVarient", this.formData);
    },
    productVarient1(value) {
      this.formData.upc = value;
      if (!this.formData.variants) {
        this.chipData = [];
      }
      this.$emit("productVarient", this.formData);
    },
    productVarient2(value) {
      this.chipData[0].data_value = value;
      this.formData.sku = value;
      if (!this.formData.variants) {
        this.chipData = [];
      }
      this.$emit("productVarient", this.formData);
    },
    async save() {
      let validation = await this.$refs.form.validate();
      if (validation.valid) {
        this.$emit("validCheck", true);
      } else {
        this.$emit("validCheck", false);
      }
    },
    // close(iteam){
    //   if('material'){
    //     this.
    //   }
    // }
  },
};
</script>

<style>
.chip {
  color: black;
  background-color: #dafcfa;
}
</style>