<template>
   <div class="text-center">
  
      <v-card style="background-color:#3C5AAA" class="pa-3 rounded-lg" :width="375">
      <v-card-title class="pa-0">
        <v-btn class="p-0"
          
            density="comfortable"
            @click="closeDialog"
            elevation="0"
            icon="mdi-close"
            style="float: right ; background-color:#3C5AAA ;color:#45454e"
          ></v-btn>
        </v-card-title>


        <v-card-title class="text-h6 text-md-h5 text-lg-h4 text-center" style="color:#FFFFFF"> {{ message.end_point =='ordersync' ? 'Pull Orders' : message.end_point =='productsync' ? 'Pull Products' : 'Syncing'  }}</v-card-title>
        <v-col class="text-center" style="color:#FFFFFF" >JOB #</v-col>
        <v-card width="" height="" class=" rounded-lg">
          <v-col align="center">
            <v-img  src="../../assets/cupOfCoffee.svg" width="150" height=""/>
          </v-col>
          <v-card-text class=" font-weight-medium text-center" style="font-size:20px"> Sit Back and Relax while we do the job for you </v-card-text>
          <v-col align="center"> 
    <v-progress-circular
      indeterminate
      color="#3C5AAA"
    ></v-progress-circular></v-col>
    <v-col align="center"> 
      <span class="font-weight-bold"  style="color:rgb(223, 175, 31)">  {{ countDown }} Secs</span> since started
        </v-col>
        </v-card>
      </v-card>
  </div>
</template>

<script>
export default {
    props:["type","message"],
    data(){
        return{
          countDown: 0
        }
    },

    created () {
           this.countDownTimer()
       },

    methods:{
          closeDialog() {
      this.$emit("closeDialog");
    },

    countDownTimer () {
               if (this.countDown < 1000) {
                   setTimeout(() => {
                       this.countDown += 1
                       this.countDownTimer()
                   }, 1000)
               }
           },
    } 

}
</script>

<style>

</style>