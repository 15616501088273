<template>
  <div class="camera-modal">
    <v-icon class="capture-close" small @click="closeCamera(null)"
      >close</v-icon
    >
    <video ref="video" class="camera-stream" />
    <div class="camera-modal-container col-12">
      <span
        @click="capture"
        class="take-picture-button take-picture-button mdl-button mdl-js-button mdl-button--fab mdl-button--colored"
      >
        <i class="material-icons">camera</i>
      </span>
    </div>
  </div>
</template>
<script>
import DocumentModel from '@/services/admin/DocumentService';
export default {
  props: ["keyId", "keyType", "documentId"],
  data() {
    return {
      mediaStream: {},
      file: {
        imageName: "",
        imageUrl: "",
        imageFile: "",
      },
    };
  },
  mounted: function () {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((mediaStreams) => {
        this.mediaStream = mediaStreams;
        this.$refs.video.srcObject = mediaStreams;
        this.$refs.video.play();
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    capture() {
      const mediaStreamTrack = this.mediaStream.getVideoTracks()[0];
      const imageCapture = new window.ImageCapture(mediaStreamTrack);
      return imageCapture.takePhoto().then((blob) => {
        this.uploadImage(new Blob([blob]));
      });
    },
    uploadImage($data) {
      var reader = new FileReader();
      var $parent = this;
      reader.readAsDataURL($data);
      reader.onloadend = function () {
       // var base64data = reader.result;
        var $json = {
          key_type: "PP",
          key_id: "0",
          doc_type_id: 1,
          name: "image",
          doc_name: "image",
          file_name: "image.png",
          file_type: "jpg",
          active: 1,
          data: reader.result,
          // company_id: JSON.parse(localStorage.getItem('tiburaClinic')).data.User.company_id
        };
        if ($parent.documentId != 0) {
          $json.id = $parent.documentId;
        }
        DocumentModel.upload($json)
          .then((response) => {
            $parent.closeCamera(response.data.data);
            //resolve("File Uploaded")
          })
          .catch((error) => {
            console.log(error);
            //reject(error)
          });
      };
    },
    closeCamera($data) {
      this.$refs.video.pause();
      this.mediaStream.stop();
      this.$emit("closeCamera", $data);
    },
    uploadImage2($data) {
      if ($data[0] !== undefined) {
        this.file.imageName = $data[0];
        if (this.file.imageName.lastIndexOf(".") <= 0) {
          return;
        }
        const fr = new FileReader();
        var $dataType = this.KeyType;
        var $file = this.file;
        var $keyId = this.keyId;
        fr.readAsDataURL($data[0]);
        fr.addEventListener("load", () => {
          $file.imageUrl = fr.result;
          $file.imageFile = $data[0]; // this is an image file that can be sent to server...
          var $json = {
            key_type: $dataType,
            key_id: $keyId,
            doc_type_id: 2,
            name: $file.imageName,
            doc_name: $file.imageName,
            file_name: $file.imageName,
            file_type: $file.imageFile.type,
            active: 1,
            data: $file.imageUrl,
            company_id: JSON.parse(
              localStorage.getItem("tiburaClinic").data.User.company_id
            ),
          };
          if ($dataType === "##") {
            this.setData($file.imageUrl);
          } else {
            DocumentModel.upload($json)
              .then((response) => {
                this.setData(response.data.data);
                this.$emit("closeDialog", response.data.data);
                //resolve("File Uploaded")
              })
              .catch((error) => {
                console.log(error);
                //reject(error)
              });
          }
        });
      } else {
        this.file.imageName = "";
        this.file.imageFile = "";
        this.file.imageUrl = "";
      }
    },
    destroyed() {
      // const tracks = this.mediaStream.getTracks()
      // tracks.map(track => track.stop())
      this.$emit("closeDialog", true);
    },
  },
};
</script>

<style scoped>
.camera-modal {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background-color: white;
  z-index: 10;
}
.camera-stream {
  width: 100%;
  max-height: 100%;
}
.camera-modal-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  align-items: center;
  margin-bottom: 24px;
}
.take-picture-button {
  display: flex;
}
.mdl-button--fab {
  border-radius: 50%;
  font-size: 24px;
  height: 36px;
  margin: auto;
  min-width: 36px;
  width: 36px;
  padding: 0;
  overflow: hidden;
  background: rgba(158, 158, 158, 0.2);
  box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
  position: relative;
  line-height: normal;
}
.mdl-button--fab.mdl-button--colored {
  background: rgb(255, 82, 82);
  color: rgb(255, 255, 255);
}
.mdl-button--fab .material-icons {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-12px, -12px);
  transform: translate(-12px, -12px);
  line-height: 24px;
  width: 24px;
  vertical-align: middle;
}
.material-icons {
  font-family: "Material Icons";
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  word-wrap: normal;
  -moz-font-feature-settings: "liga";
  font-feature-settings: "liga";
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}
.take-picture-button[data-v-49c240a2] {
  display: flex;
}
.mdl-button {
  background: 0 0;
  border: none;
  border-radius: 50%;
  color: #000;
  position: relative;
  height: 36px;
  margin: 0;
  min-width: 36px;
  padding: 0 10px;
  display: inline-block;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0;
  overflow: hidden;
  will-change: box-shadow;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1),
    background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1),
    color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  outline: none;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  line-height: 36px;
  vertical-align: middle;
}
.capture-close {
  margin-left: 90%;
  margin-top: 10%;
  -webkit-transform: translate(-12px, -12px);
  transform: translate(-12px, -12px);
  line-height: 24px;
  background: rgb(255, 82, 82);
  color: rgb(255, 255, 255);
  border-radius: 50%;
  width: 36px;
  height: 24px;
  width: 24px;
}
</style>