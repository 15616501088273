<template>
   <v-card elevation="0">
    <v-card-title class="text-body-2">
        <b>Pricing Details</b>
    </v-card-title>
    <v-card-text class="pl-1">
        <!-- Pricing List -->
            <v-col cols="12">
              <v-row v-for="item in items" :key="item.title">
                <v-col class="p-title pr-0 mr-0 pt-1">
                    {{ item.title }}
                </v-col>   
                <v-col align="end" class="pl-0 ml-0 pt-1">
                    <b> $ {{ item.value }} </b>
                </v-col>
              </v-row>            
            </v-col>
        </v-card-text>
   </v-card>
</template>
<script>
export default{
  props:['datas'],
  data(){
    return{
      items:[],
    }
  },
  created(){
    this.updateItems();
  },
  watch: {
    'datas' : function(){
      this.updateItems()
    }
    },
  methods:{
    updateItems() {
      console.log("1111111",this.datas)
      this.items = [
        {title:'Gross Amount', value: this.datas.ordered_amount ? this.datas.ordered_amount : '0'},
        {title:'Discount', value: this.datas.discount_amount ? this.datas.discount_amount: '0'},
        {title:'Shipping Fee', value: this.datas.freight_amount ? this.datas.freight_amount : '0'},
        {title:'Shipping Tax', value: this.datas.shipment_tax_amount ? this.datas.shipment_tax_amount : '0'},
        {title:'Tax', value: this.datas.item_tax_amount ? this.datas.item_tax_amount : '0'},
        {title:'Net Amount', value: this.datas.net_amount ? this.datas.net_amount : '0'},
        {title:'Commission', value: this.datas.commission_amount ? this.datas.commission_amount : '0'},
        {title:'Payout', value: this.datas.pay_to_amount ? this.datas.pay_to_amount : '0'},
        { title: 'Total Amount', value: this.calculateTotal() }
      ];
    },
    calculateTotal() {
      return this.datas.net_amount + this.datas.freight_amount + this.datas.shipment_tax_amount + this.datas.commission_amount
    },
    
  }
}
</script>

<style>
.p-title{
  color: #636363
}
</style>