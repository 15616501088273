<template>
    <v-row>
        <!-- Top Nav Arrow Buttons -->
        <v-col cols="8" class="pl-0 pt-5">
            <v-icon class="backicon" @click="$router.go(-1)" color="black">mdi-arrow-left-circle-outline</v-icon>
        </v-col>
        <v-col cols="4" align="end" class="pr-10 pt-5">
            <!-- <v-row class="pt-5 pl-4"> -->
                <span  ><v-icon @click="previousOrder()"><v-img src="https://s3.ap-south-1.amazonaws.com/xtend3.0/icons/arrow/circle_chevron_left.svg" width="25px" ></v-img></v-icon></span>&nbsp;&nbsp;
                <span  ><v-icon @click="nextOrder()"><v-img src="https://s3.ap-south-1.amazonaws.com/xtend3.0/icons/arrow/circle_chevron_right.svg" width="25px" ></v-img></v-icon></span>
            <!-- </v-row> -->
        </v-col>
    
        <!-- Title Contents -->
        <v-row class="pt-4 pl-3">

            <!-- Chips -->
            <v-col cols="6">
              <span class="text-h5">{{data && data.mws_orders ? data.mws_orders.order_ref_id :''}}</span>
              <span class="pl-3" v-if="data.risk_flag == 1"><Tag value="Risk" class="pa-2 pt-1 pb-1  pink-chip text-caption"></Tag></span>
              <span class="pl-3" v-if="data.stage == 'C'"><Tag value="Shipped" class="pa-2 pt-1 pb-1 sand-chip text-caption" ></Tag></span>
              <span class="pl-3" v-if="data.stage == 'O'"><Tag value="Open" class="pa-2 pt-1 pb-1 sand-chip text-caption" ></Tag></span>
              <span><v-icon color="blue" class="pl-5" @click="info()">mdi-information-outline</v-icon></span>
              <div class="text-caption pt-2 p-title">
                <span>PORTAL ORDER ID: {{ data && data.mws_order_item_id ? data.mws_order_item_id : data.mws_order_item_id_txt }}</span>
                <v-divider vertical></v-divider>
                <span> | {{ since }} Days Since Placed</span>
              </div>
            </v-col>

            <!-- Menu Buttons -->
            <v-col cols="6" align="end" class="pr-10">
                <v-btn  size="small" class="menubtn" elevation="0"> Refund </v-btn>&nbsp;
                <v-btn  size="small" class="menubtn" elevation="0"> Send to ERP </v-btn>&nbsp;
                <v-btn  size="small" class="menubtn" elevation="0"> Track </v-btn>&nbsp;
                <v-btn  size="small" class="menubtn" elevation="0"> More <v-icon class="pl-3">mdi-chevron-down</v-icon>
                  <v-menu activator="parent">
                     <v-list>
                         <v-list-item v-for="(item, index) in menuItems" :key="index" :value="index">
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                         </v-list-item>
                     </v-list>
                 </v-menu>
                </v-btn>
            </v-col>

        </v-row>
    </v-row>
</template>
<script>
import moment from 'moment';
import Tag from 'primevue/tag';
export default{
    props:['data'],
    components:{
        Tag,
    },
    data(){
        return{
            since:null,
            menuItems: [ { title: 'Option 1' },
                     { title: 'Option 2' }, ],
        }
    },
    watch:{
        data(){
            this.getSince();
        }
    },
    // created(){

    // },
    methods:{
        moment,
        getSince(){
         let currentDate = moment(new Date());
         let orderedDate = moment(this.data.mws_orders.order_date);
         this.since = currentDate.diff(orderedDate,'days')
        },
        previousOrder(){
            var event ={key:'ArrowLeft'}
            this.$emit('Arrowkey',event) 
        },
        nextOrder(){
            var event ={key:'ArrowRight'}
            this.$emit('Arrowkey',event)
        }  
        
    }
}
</script>
<style scoped>
.sand-chip{
    background-color: #F4EDC6;
    color: #272727;
    font-weight: 400;
}

.pink-chip{
    background-color: #F4C6C6;
    color:#272727;
    font-weight: 400;
}

.menubtn{
    background-color: #F2F0F0;
}
.p-title{
    color: #636363;
}
</style>