import ApiService from '../../api/service/ApiService.js';
const querystring = require('querystring');
import apiConfig from '@/api/config/config.js';
var ShipmentService = {

    findAll: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "shipments"
            $options.method = "get"
            $options.baseUrl = apiConfig.orderUrl
            if ($data) {
                $options.url = "shipments?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
  
    findOne: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "shipments/" + $data.id
            console.log($options.url)
            $options.method = "get"
            $options.baseUrl = apiConfig.orderUrl
            if (!$data) {
                $options.data = $data
            }
            ApiService.request($options)
                .then(response => {
                    // console.log('Gpt the data');
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });

    },
    update: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.data = $data
            $options.url = "shipments/" + $data.id;
            $options.baseUrl = apiConfig.orderUrl
            $options.method = "post"
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
        })
    },
    save: function($data) {
        return new Promise(function(resolve, reject) {
            var $options = {};
            $options.data = $data
            $options.url = "shipments" + $data.id;
            $options.baseUrl = apiConfig.orderUrl
            $options.method = "post"
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
        });
    },

    create: function($data) {
        return new Promise(function(resolve, reject) {
            var $options = {};
            $options.data = $data
            $options.url = "shipments";
            $options.method = "post"
            $options.baseUrl = apiConfig.orderUrl
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
        });
    },
    delete: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.data = $data
            $options.url = "shipments/" + $data.id;
            $options.baseUrl = apiConfig.orderUrl
            $options.method = "delete"
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
        })
    }
};
export default ShipmentService;