<template>
  <v-card>
    <v-form @submit.prevent="save()" ref="form">
      <v-card-title class="dialog-Title"
        >{{
          type == "edit"
            ? "Edit"
            : type == "view"
            ? "View"
            : type == "add"
            ? "Add"
            : type == "select"
            ? "View"
            : ""
        }}
        Sub Category
        <v-btn
          density="comfortable"
          @click="closeDialog"
          elevation="0"
          icon="mdi-close"
          style="float: right"
        ></v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="6" class="pa-0">
              <InputBox
                :data="formData.code"
                @textData="checkCode($event)"
                :label="'Code'"
                :rules="codeRules"
                :disabled="showReadOnly"
                @keydowninput="prevent"
              />
              <span v-if="codeError" class="error-field">Already Exists!</span>
            </v-col>
            <v-col cols="6" class="pa-0 pl-2">
              <InputBox
                :data="formData.erp_key"
                @textData="
                  ($event) => {
                    formData.erp_key = $event;
                  }
                "
                :label="'Erp Ref.No'"
                :rules="refRules"
                :disabled="showReadOnly"
              />
            </v-col>
            <v-col cols="12" class="pa-0">
              <InputBox
                :data="formData.name"
                @textData="
                  ($event) => {
                    formData.name = $event;
                  }
                "
                :label="'Name'"
                :rules="nameRules"
                :disabled="showReadOnly"
              />
            </v-col>
            <v-col cols="12" class="pa-0">
              <AutoComplete
                :label="'Category'"
                :rules="categoryRules"
                :value="'id'"
                :items="category"
                :text="'name'"
                :disabled="showReadOnly"
                :data="formData.category_id"
                @textData="
                  ($event) => {
                    formData.category_id = $event;
                  }
                "
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="dialogfooter" v-if="!showReadOnly">
        <v-spacer></v-spacer>
        <v-btn class="mr-1 cancel" @click="closeDialog()">Cancel</v-btn>
        <v-btn class="save" type="submit">Save</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
import CategoryService from "@/services/catalogs/CategoryService";
import SubCategoryService from "@/services/catalogs/SubCategoryService";
export default {
  props: ["type", "id"],
  components: {},
  data() {
    return {
      codeError: false,
      codeExistFlag: false,
      category: [],
      codeRules: [(value) => !!value || "Code is Required"],
      refRules: [(value) => !!value || "Erp Ref.No is Required"],
      nameRules: [(value) => !!value || "Name is Required"],
      categoryRules: [(value) => !!value || "Category is Required"],
      formData: {},
      showRow: false,
      showReadOnly: false,
    };
  },
  async created() {
    if (this.type == "view" || this.type == "select") {
      this.showReadOnly = true;
    }
    let res = await CategoryService.findAll();
    this.category = res&&res.length>0 ? res : [];
    if (this.id) {
      let res = await SubCategoryService.findOne({ id: this.id });
      this.formData = res[0];
    }
  },
  methods: {
    prevent(e) {
      if (!/^[a-zA-Z0-9-_ \t]+$/.test(e.key) && e.key !== "Backspace") {
        e.preventDefault();
      }
    },
    checkCode($event) {
      this.formData.code = $event;
      if (this.formData.code && this.formData.code.length > 0) {
        this.formData.code = this.formData.code.toUpperCase();
        SubCategoryService.findAll({ code: this.formData.code }).then((res) => {
          let $exist = res.filter((key) => key.code == this.formData.code);

          this.codeExistFlag =
            $exist.length > 0
              ? this.formData.id && $exist[0].id == this.formData.id
                ? false
                : true
              : false;

          if (this.codeExistFlag) {
            this.codeError = true;
          } else {
            this.codeError = false;
          }
        });
      }
    },
    async save() {
      let validation = await this.$refs.form.validate();
      if (!validation.valid || this.codeError) {
        console.log("Not valid");
      } else {
        delete this.formData.categories;
        if (this.id) {
          delete this.formData.users;
          await SubCategoryService.update(this.formData);
          this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
          this.$emit("closeDialog", true);
          this.$emit("reload", true);
        } else {
          try {
            await SubCategoryService.create(this.formData);
            this.$store.dispatch("setSnackbar", { text: "Saved Successfully" });
            this.$emit("closeDialog", true);
            this.$emit("reload", true);
          } catch (e) {
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "Something Went Wrong",
            });
          }
        }
      }
    },
    // addRows() {
    //   this.rows.push({
    //     fieldName: null,
    //     operand: null,
    //     value: null,
    //   });
    // },
    deleteRows(index) {
      this.rows.splice(index, 1);
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
  },
};
</script>