<template>
  <v-card elevation="0">
    <v-form @submit.prevent="save" ref="form">
      <v-card-title class="dialog-Title">
        {{ type == "edit" ? "Edit" : type == "view" ? "View" : "Add" }}
        {{ this.title }}
        <v-btn density="comfortable" @click="closeDialog" elevation="0" icon="mdi-close" style="float: right"></v-btn>
        <v-btn v-if="this.title == 'Add Form Builder'" class="button" style="float: right" elevation="0">Test Form</v-btn>
        <!-- <v-btn v-if="this.title == 'Add Nextflo Internal Api'" class="button" style="float: right" elevation="0">Test Configuration</v-btn> -->
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-col cols="4" class="pa-0 pl-2">
          <InputBox :data="formData.code" @textData="($event) => { formData.code = $event; }" :label="'Code'"
            :disabled="showReadonly" :rules="codeRule" @keydown="prevent($event)" @keyup="checkCode()" />
          <span v-if="codeError" class="error-field">Already Exists!</span>
        </v-col>
        <v-col cols="4" class="pa-0 pl-2">
          <InputBox :data="formData.name" @textData="($event) => { formData.name = $event; }" :label="'Name'"
            :disabled="showReadonly" :rules="nameRule" />
        </v-col>
        <v-col cols="4" v-if="showDomain" class="pa-0 pl-2">
          <EnumDropdown :data="formData.domain" @textData="($event) => { formData.domain = $event; }"
            :disabled="showReadonly" :domainName="'domain'" :label="'Domain'" />
        </v-col>
        <v-col cols="4" class="pa-0 pl-2" v-if="showIntegration">
          <AutoComplete :data="formData.configuration" @textData="($event) => { formData.configuration = $event; }"
            :disabled="showReadonly" :label="'GL Integration'" />
        </v-col>
        <v-col cols="4" class="pa-0 pl-2" v-if="showCode">
          <MonacoEditor @keyup="searchConfiguration(formData.configuration)" language="javascript" theme="vs-dark"
            v-model="formData.configuration" :modelValue="formData.configuration" />
        </v-col>
      </v-card-text>
      <v-card-actions class="dialogfooter" v-if="!showReadonly">
        <v-spacer></v-spacer>
        <v-btn class="mr-1 cancel" @click="closeDialog">Cancel</v-btn>
        <v-btn class="save" type="submit">Save</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
import MonacoEditor from "@/components/MonacoEditor.vue";
import EnumDropdown from "@/components/EnumDropdown.vue";
import ChannelService from "@/services/admin/ChannelService";
export default {
  components: {
    MonacoEditor,
    EnumDropdown,
  },
  data() {
    return {
      codeError: false,
      codeExistFlag: false,
      showReadonly: false,
      showCode: false,
      showDomain: false,
      showIntegration: false,
      id: this.$route.query.id,
      type: this.$route.query.type,
      title: null,
      formData: {
        code: null,
        name: null,
        domain: null,
        configuration: '',
        type: this.$route.name,
      },
      codeRule: [(value) => !!value || "Code Is Required"],
      nameRule: [(value) => !!value || "Name Is Required"],
      emailText: `name: Email Configuration
type: email
properties:
  emailSettings:
    outgoingServer:
      server: <smtpserver>
      secure: true
      port: 587
      encrypted: tls
      host: smtp
    sender:
      email: <email>
      password: <password>
    reciepient:
      email: <receipients>`,
      apiText: `name: SMS Integration
properties:
  variables:
    baseUrl: <url>
    authToken: <bearer|authorization>
    sender: <sendercode>
    base64encoded: <true|false>
    apiKey: <apikey>
  requests:
    - name: <endpointname>
      path: <endpoint>
      method: <get|post|put>
      requestBody:
        - to: <receipientmobileno>
          required: <true|false>
          dataType: <string|number|date>
        - method: sms
          required: true
          dataType: string
        - message: <smstemplate>
          required: true
          dataType: string`,
      smsText: `name: SMS Integration
properties:
  variables:
    baseUrl: <url>
    authToken: <bearer|authorization>
    sender: <sendercode>
    base64encoded: <true|false>
    apiKey: <apikey>
  requests:
    - name: <endpointname>
      path: <endpoint>
      method: <get|post|put>
      requestBody:
        - to: <receipientmobileno>
          required: <true|false>
          dataType: <string|number|date>
        - method: sms
          required: true
          dataType: string
        - message: <smstemplate>
          required: true
          dataType: string`,
      whatsappText: `name: Whatsapp Configuration
type: whatsapp
properties:
  whatsappVariables:
    username: <username>
    password:  <password>
    baseUrl:  <url>
  whatsappLogin:
    baseUrl: <url>
  whatsappTemplate:
    baseUrl:  <template>
  whatsappRequest:
    baseUrl: <template>
    whatsappBody:
      phone: <mobileno>
      extra: <extrainfo>
      whatsappMedia:
        type: media_template
        templateName: mas_number_change_update
        language: en     `,
    };
  },
  async created() {
    this.getRoute();
    if (this.id) {
      this.getChannel(this.id);
    } else {
      let $url = this.$route.path;
      this.formData.configuration =
        $url.indexOf("email") > -1
          ? this.emailText
          : $url.indexOf("sms") > -1
            ? this.smsText
            : $url.indexOf("whatsapp") > -1
              ? this.whatsappText
              : $url.indexOf("api") > -1
                ? this.apiText
                : null;
        // console.log("config data",this.formData)
        // let res = await ChannelService.yamlToJson(this.formData.configuration)
        // console.log("JasonData",res)
    }
    if (this.type == "view") {
      this.showReadonly = true;
    } else {
      this.showReadonly = false;
    }
  },
  methods: {
    changed() {
      // alert("changed");
    },
    searchConfiguration($value) {
      console.log($value, "$value");
    },
    async getChannel(id) {
      let res = await ChannelService.findOne({ id: id });
      this.formData = res[0];
      // let jsonData = await ChannelService.yamlToJson(id)
      // console.log("jsonData",jsonData)
      console.log(res, "resssssss");
      // let jsonData = await ChannelService.yamlToJson();

    },
    getRoute() {
      this.title = this.$route.name;
      console.log(this.title, "tittle");
      if (this.title == "Add Email") {
        this.showCode = false;
      } else if (
        this.title == "api" ||
        this.title == "uploadDocument" ||
        this.title == "glIntegration" ||
        this.title == "formBuilder" ||
        this.title == "nextfloInternalApi"
      ) {
        this.showDomain = true;
        this.showCode = true;
      } else if (this.title == "Add GL Integration") {
        this.showDomain = true;
        this.showIntegration = true;
        this.showCode = true;
      } else {
        this.showCode = true;
      }
    },
    prevent(e) {
      if (!/^[a-zA-Z0-9-_ \t]+$/.test(e.key) && e.key !== "Backspace") {
        e.preventDefault();
      }
    },
    checkCode($event) {
      this.formData.code = $event;
      if (this.formData.code && this.formData.code.length > 0) {
        this.formData.code = this.formData.code.toUpperCase();
        ChannelService.findAll({ code: this.formData.code }).then((res) => {
          let $exist = res.filter((key) => key.code == this.formData.code);

          this.codeExistFlag =
            $exist.length > 0
              ? this.formData.id && $exist[0].id == this.formData.id
                ? false
                : true
              : false;

          if (this.codeExistFlag) {
            this.codeError = true;
          } else {
            this.codeError = false;
          }
        });
      }
    },
    async save() {
      //let val = document.getElementById("editor").value;
      let validation = await this.$refs.form.validate();
      if (!validation.valid || this.codeError) {
        console.log(validation);
      } else {
        delete this.formData.users
        if (this.formData && this.formData.id) {
          let output = await ChannelService.update(this.formData);
          this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
          console.log(output);
          this.closeDialog();
        } else {
          try {
            let output = await ChannelService.create(this.formData);
            this.$store.dispatch('setSnackbar', { text: "Saved Successfully" });
            console.log(output);
            this.closeDialog();
          }
          catch (e) {
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "Something Went Wrong",
            });
          }
        }
      }
    },
    closeDialog() {
      this.$router.go(-1);
    },
  },
};
</script>
<style>
/* .view-lines {
    position: absolute;
    font-family: Consolas, "Courier New", monospace;
    font-weight: normal;
    font-size: 14px;
    font-feature-settings: "liga" 0, "calt" 0;
    line-height: 19px;
    letter-spacing: 0px;
    width: 1736px;
    height: 757px;
    background-color: #1e1e1e;
}

.margin-view-overlays{
position: absolute;
    width: 64px;
    font-family: Consolas, "Courier New", monospace;
    font-weight: normal;
    font-size: 14px;
    font-feature-settings: "liga" 0, "calt" 0;
    line-height: 19px;
    letter-spacing: 0px;
    height: 757px;
    background-color: #1e1e1e;
  }
  .view-lines .mtk1{
    color: white;
} */
</style>
