<template>
  <v-card>
    <v-form @submit.prevent="save()" ref="form">
      <v-card-title class="dialog-Title"
        >{{
          type == "edit"
            ? "Edit"
            : type == "view"
            ? "View"
            : type == "add"
            ? "Add"
            : type == "select"
            ? "View"
            : ""
        }}
        Category
        <v-btn
          density="comfortable"
          @click="closeDialog"
          elevation="0"
          icon="mdi-close"
          style="float: right"
        ></v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="6" class="pa-0">
              <InputBox
              :data="formData.code"
              @textData="checkCode($event)"
                :label="'Code'"
                :rules="codeRules"
                @keydowninput="prevent"
                :disabled="showReadOnly"
               
              />
              <span v-if="codeError" class="error-field">Already Exists!</span>
            </v-col>
            <v-col cols="6" class="pa-0 pl-2">
              <InputBox
              :data="formData.erp_key"
              @textData="($event) => {formData.erp_key= $event;}"
                :label="'Erp Ref.No'"
                :rules="refRules"
                :disabled="showReadOnly"
              />
            </v-col>
            <v-col cols="12" class="pa-0">
              <InputBox
              :data="formData.name"
              @textData="($event) => {formData.name = $event;}"
                :label="'Name'"
                :rules="nameRules"
                :disabled="showReadOnly"
              />
            </v-col>
            <v-col cols="12" class="pa-0">
              <AutoComplete
              :data="formData.product_type_id"
              @textData="($event) => {formData.product_type_id = $event;}"
                :label="'Product Type'"
                :text="'name'"
                :value="'id'"
                :items="productType"
                :disabled="showReadOnly"
                :rules="productRules"
              />
            </v-col>

          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="dialogfooter" v-if="!showReadOnly">
        <v-spacer></v-spacer>
        <v-btn class="mr-1 cancel" @click="closeDialog()">Cancel</v-btn>
        <v-btn class="save" type="submit">Save</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
import CategoryService from '@/services/catalogs/CategoryService';
import ProductTypeService from '@/services/catalogs/ProductTypeService';

export default {
  props: ["type","id"],
  components: {},
  data() {
    return {
      codeError:false,
      codeExistFlag:false,
      showReadOnly: false,
      productType : [],
      codeRules: [(value) => !!value || "Code is Required"],
      refRules: [(value) => !!value || "Erp Ref.No is Required"],
      nameRules: [(value) => !!value || "Name is Required"],
      productRules: [(value) => !!value || "Product Type is Required"],
      formData: {},
      showRow: false,
      rows: [
        {
          fieldName: null,
          operand: null,
          value: null,
        },
      ],
    };
  },
  async created(){
    if(this.type == 'view' || this.type == 'select'){
      this.showReadOnly = true
    }
    let res = await ProductTypeService.findAll()
     this.productType = res&&res.length>0 ? res : [];
    if(this.id){
     let res = await CategoryService.findOne({id: this.id})
     this.formData = res[0]
     }
  },
  methods: {
    prevent(e) {
  if (!/^[a-zA-Z0-9-_ \t]+$/.test(e.key) && e.key !== "Backspace") {
    e.preventDefault();
  }
},
checkCode($event) {
  this.formData.code =$event
      if (this.formData.code && this.formData.code.length > 0) {
        this.formData.code = this.formData.code.toUpperCase();

        CategoryService.findAll({ code: this.formData.code }).then((res) => {
          let $exist = res.filter((key) => key.code == this.formData.code);

          this.codeExistFlag =
            $exist.length > 0
              ? this.formData.id && $exist[0].id == this.formData.id
                ? false
                : true
              : false;

          if (this.codeExistFlag) {
            this.codeError = true;
          } else {
            this.codeError = false;
          }
        });
      }
    },
    async save(){
      let validation = await this.$refs.form.validate()
      if (!validation.valid || this.codeError) {
        console.log("Not valid")
      }
         else{
          delete this.formData.product_types;
        if(this.id){
          delete this.formData.users
        await CategoryService.update(this.formData)
        this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
         this.$emit("closeDialog",true)
         this.$emit("reload",true)        
        } else{
          try{
         await CategoryService.create(this.formData)
         this.$store.dispatch('setSnackbar', { text: "Saved Successfully" });
          this.$emit("closeDialog",true)
          this.$emit("reload",true)
          }
       catch (e) {
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "Something Went Wrong",
            });
          }
      }
    }
    },
    addRows() {
      this.rows.push({
        fieldName: null,
        operand: null,
        value: null,
      });
    },
    deleteRows(index) {
      this.rows.splice(index, 1);
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
  },
};
</script>