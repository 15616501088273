<template>
    <v-container fluid style="background:#f3f9ff;padding:0px;margin:0px">
        <v-card dense flat>
            <v-card-title>
               Role & Permissions
            </v-card-title>
            <v-card-text>
                <v-row dense>
                    <v-col cols="3">
                        <AutoComplete :label="'Roles'" :items="roles"  :text="'name'"
                            :value="'id'" :data="formData.role_id" @textData="roleChanged($event)" :rules="roleRules" />
                    </v-col>
                    <v-col cols="3">
                        <AutoComplete :label="'Module'" :items="modules" :text="'name'"
                            :value="'id'" :data="formData.module_id" @textData="moduleChanged($event)"
                            :rules="moduleRules" />
                    </v-col>
                    <v-col cols="3" class="mt-6">
                        <v-btn v-if="formData.role_id" @click="cloneDialog = true" size="small" outlined
                            icon="mdi mdi-content-copy" style="border-radius:4px">

                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-row dense>
            &nbsp;
        </v-row>
        <v-row dense>
            <v-col cols="3">
                <v-card flat height="60vh">
                    <v-card-title>
                        Activity Groups
                    </v-card-title>
                    <v-card-text>
                        <ListView :listItems="activityGroups" value="id" text="name" return-object=true add-on-click=false
                            @item-active="activityGroupChanged" @item-selected="activityGroupChanged" />
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="3">
                <v-card flat>
                    <v-card-title>
                        Activities
                    </v-card-title>
                    <v-card-text style="height:54vh;overflow: auto">
                        <ListView :listItems="activities" value="id" text="name" return-object=true add-on-click=false
                            @item-active="activityChanged" @item-selected="activityChanged" />
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="6">
                <v-card flat height="60vh">
                    <v-card-title>
                        Actions
                    </v-card-title>
                    <v-card-text v-if="actions && actions.length > 0">
                        <v-row>
                            <v-col cols="1">
                                <v-checkbox hide-details density="compact" v-model="selectAllData"
                                    @update:modelValue="selectAll($event)"></v-checkbox>
                            </v-col>
                            <v-col cols="5" class="mt-3">
                                Select All
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <template v-for="action in actions" v-bind:key="action">
                                <v-col cols="1">
                                    <v-checkbox hide-details density="compact"
                                        @update:modelValue="checkboxSelected($event, action)"
                                        v-model="action.selected"></v-checkbox>
                                </v-col>
                                <v-col cols="5" class="mt-3">
                                    {{ action.name }}
                                </v-col>
                            </template>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog max-width="400px" v-model="cloneDialog" persistent>
            <PermissionClone :roles="roles" :fromRoleId="this.formData.role_id" @closeDialog="cloneDialog = false" />
        </v-dialog>
    </v-container>
</template>
<script>
import PermissionClone from './PermissionClone.vue'
import RoleService from '../../services/admin/RoleService'
import ModuleService from '../../services/admin/ModuleService';
import ActivityGroupService from '../../services/admin/ActivityGroupService';
import ActivityService from '../../services/admin/ActivityService';
import AutoComplete from "@/components/AutoComplete.vue";
import RolePermissionService from '../../services/admin/RolePermissionService';
import { ListView } from 'frontendcomponent'
//import {Button} from 'frontendcomponent'
export default {
    components: {
        AutoComplete,
        ListView,
        PermissionClone
        //      Button
    },
    data() {
        return {
            cloneDialog: false,
            roleRules: [(value) => !!value || "Role is Required"],
            moduleRules: [(value) => !!value || "Module is Required"],
            formData: {
                "actions": []
            },
            autoSaveRequired: false,
            selectAllData: false,
            roles: [],
            modules: [],
            activityGroups: [],
            activities: [],
            actions: []
        }
    },
    created() {
        this.getRoles()
        this.getModules()
    },
    methods: {
        async getModules() {
            let res = await ModuleService.findAll({ active: 1 });
            this.modules = res && res.length > 0 ? res : []
        },
        async getRoles() {
            this.roles = await RoleService.findAll({ active: 1 });
        },
        checkboxSelected($value, item) {
            item.selected = $value
            let $selected = this.actions.filter((obj) => { return obj.selected })
            let $selection = false
            if ($selected.length == this.actions.length) {
                $selection = true
            } else {
                $selection = false
            }
            this.selectAllData = $selection
            this.autoSaveRequired = true
        },
        selectAll($event) {
            // let $selected = this.actions.filter((obj) => { return obj.selected })
            let $selection = $event
            // if ($selected.length == this.actions.length) {
            //     $selection = true
            // } else if ($selected.length > 0) {
            //     $selection = true
            // } else {
            //     $selection = true
            // }
            for (let action of this.actions) {
                action.selected = $selection
            }
            this.selectAllData = $selection
            this.autoSaveRequired = true
        },
        async roleChanged($value) {
            this.formData.role_id = $value
            this.formData.module_id = null
            this.activityGroups = []
            this.activities = []
            this.actions = []
            if (this.formData.activity_id) {
                await this.autoSave()
            }
        },
        async moduleChanged($item) {
            this.formData.module_id = $item
            if (this.formData.activity_id) {
                await this.autoSave()
            }
            this.formData.module_id = $item
            this.formData.activity_group_id = null
            this.formData.activity_id = null
            this.actions = []
            this.getActivityGroups()
        },
        async activityGroupChanged($item) {
            if (this.formData.activity_id) {
                await this.autoSave()
            }
            this.formData.activity_group_id = $item.id
            this.actions = []
            this.getActivities()
        },
        async activityChanged($item) {
            // alert('activity chanigne')
            if (this.formData.activity_id) {
                await this.autoSave()
            }
            this.formData.activity_id = $item.id
            this.actions = JSON.parse($item.actions)
            // alert('gettg permissions')
            this.getPermissions()
        },
        async getPermissions() {
            //  alert({"role_id":this.formData.role_id,"activity_id":this.formData.activity_id})
            let $permissions = await RolePermissionService.findAll({ "role_id": this.formData.role_id, "activity_id": this.formData.activity_id })
            $permissions = $permissions.length > 0 ? $permissions[0] : {}
            if ($permissions.id) {
                this.formData.id = $permissions.id
                let $allowedActions = $permissions.actions ? JSON.parse($permissions.actions) : []
                for (let $action of this.actions) {
                    if ($allowedActions.indexOf($action.code) >= 0) {
                        $action.selected = true
                    } else {
                        $action.selected = false
                    }
                }
            } else {
                delete this.formData.id
            }
            let $selected
            if (this.actions && this.actions.length > 0) {
                $selected = this.actions.filter((obj) => { return obj.selected })
            }
            if ($selected.length == this.actions.length) {
                this.selectAllData = true
            } else {
                this.selectAllData = false
            }

            //alert(JSON.stringify(this.actions))



        },
        async autoSave() {
            if (this.autoSaveRequired) {
                let $selected = this.actions.filter((obj) => {
                    return obj.selected
                })
                this.formData.actions = $selected.map((element) => {
                    return element.code
                })
                this.formData.actions = JSON.stringify(this.formData.actions)
                let $formData = Object.assign({}, this.formData)
                delete $formData.module_id
                delete $formData.activity_group_id

                if (this.formData.id) {

                    await RolePermissionService.update($formData)
                } else {
                    await RolePermissionService.create($formData)
                }
                this.autoSaveRequired = false
                this.$store.dispatch("setSnackbar", {
                    color: "success",
                    text: "Auto Saving...",
                });
            }

        },
        async getActivityGroups() {
            this.activityGroups = await ActivityGroupService.findAll({ active: 1, module_id: this.formData.module_id });
            this.activities = []
        },
        async getActivities() {
            this.activities = await ActivityService.findAll({ "activity_group_id": this.formData.activity_group_id })
        },
    }
}
</script>