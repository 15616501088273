<template>
    <v-row align="center">
      <v-col> Overview / <span class="subTitle">Sales</span> </v-col>
      <!-- <v-icon size="small" @click="showDropdown = !showDropdown">{{
        showDropdown ? "mdi-close-circle-outline" : "mdi-filter-outline"
      }}</v-icon> -->
      <v-menu transition="fade-transition" :close-on-content-click="false">
        <template v-slot:activator="{ props }">
          <v-btn  v-bind="props" variant="text" density="comfortable" icon="mdi-filter-outline"></v-btn>
          <!-- <v-icon
            class="mr-4"
            size="small"
            dark
            v-bind="props"
            icon="mdi-filter-outline"
          >
          </v-icon> -->
        </template>
        <v-card width="700" style="overflow:unset"> 
          <v-card-title class="pl-8">Filter
            <v-btn class="button" style="float:right" type="submit" @click="setFilter()">Apply</v-btn>
        </v-card-title>
        <v-divider></v-divider>
          <v-card-text>
            <v-container>
          <v-row>
                <v-col cols="6" class="pb-0">
                  <Dropdown
                    placeholder="Choose Marketplace"
                    multiple
                    :items="mwsHeaderData"
                    item-title="name"
                    item-value="id"
                    :@change="changemarketPlace(marketplaceData.marketPlaceId)"
                    :data="marketplaceData.marketPlaceId"
                      @textData="
                        ($event) => {
                          marketplaceData.marketPlaceId = $event;
                        }
                      " 
                  ></Dropdown>
                </v-col>
                <v-col cols="6" class="pb-0">
                  <Dropdown
                    placeholder="Choose Date"
                    :items="periodeData"
                    item-title="name"
                    item-value="value"
                    :@change="changePeriodeData(marketplaceData.periodeDataId)"
                    v-model="marketplaceData.periodeDataId"
                  ></Dropdown>
                </v-col>
                <v-col v-if="showDate" cols="6" class="pt-0">
                  <DatePicker
                    placeholder="Start Date"
                    v-model="marketplaceData.startDate"
                  ></DatePicker>
                </v-col>
                <v-col v-if="showDate" cols="6" class="pt-0">
                  <DatePicker
                    placeholder="End Date"
                    v-model="marketplaceData.endDate"
                  ></DatePicker>
                </v-col> </v-row>
              </v-container>
              </v-card-text>
        </v-card>
      </v-menu>
    </v-row>
  
    <!-- <v-row v-if="showDropdown">
      <v-col cols="3" sm="3" lg="3" class="pt-0 pb-0">
        <Dropdown
          placeholder="Choose Marketplace"
          multiple
          :items="mwsHeaderData"
          item-title="name"
          item-value="id"
          :@change="changemarketPlace(marketplaceData.marketPlaceId)"
          v-model="marketplaceData.marketPlaceId"
        ></Dropdown>
      </v-col>
      <v-col cols="3" sm="3" lg="3" class="pt-0 pb-0">
        <Dropdown
          placeholder="Choose Date"
          :items="periodeData"
          item-title="name"
          item-value="value"
          :@change="changePeriodeData(marketplaceData.periodeDataId)"
          v-model="marketplaceData.periodeDataId"
        ></Dropdown>
      </v-col>
      <v-col v-if="showDate" cols="3" sm="3" lg="3" class="pt-0 pb-0">
        <DatePicker placeholder="Start Date" v-model="startDate"></DatePicker>
      </v-col>
      <v-col v-if="showDate" cols="3" sm="3" lg="3" class="pt-0 pb-0">
        <DatePicker
          placeholder="End Date"
          :value="startDate"
          v-model="endDate"
        ></DatePicker>
      </v-col>
    </v-row> -->
    <v-row>
      <v-col
        class="pt-0"
        cols="12"
        md="6"
        sm="12"
        lg="3"
        xs="12"
        v-for="item in totalCardData"
        :key="item"
      >
        <v-hover v-slot="{ isHovering, props }" open-delay="100">
          <v-card
            :elevation="isHovering ? 4 : 0"
            v-bind="props"
            :class="{ 'on-hover': isHovering }"
            class="Card rounded-lg"
          >
            <v-card-text class="pr-0">
              <v-row no-gutters>
                <v-col cols="8">
                  <v-card-subtitle class="pa-0">{{ item.name }}</v-card-subtitle>
                  <v-card-title class="pa-0 mt-6">{{
                    item.totalValue
                  }}</v-card-title>
                </v-col>
                <v-col cols="4" align="center">
                  <v-sheet
                    :color="item.color"
                    rounded
                    style="padding-top: 8px; padding-left: 2px"
                    :height="35"
                    :width="40"
                  >
                    <img class="dashboard-card-img" :src="item.icons" />
                  </v-sheet>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </template>
  <script>
  import mwsHeaderService from "@/services/mws/MwsHeaderService";
  import Dropdown from "../components/Dropdown.vue";
  import DatePicker from "../components/DatePicker.vue";
  import { authStore } from "@/store";
  import DashBoardService from '@/services/admin/DashBoardService'
  // import InputBox from "@/components/InputBox.vue";
  import moment  from "moment";
  export default {
    components: {
      Dropdown,
      DatePicker,
      // InputBox
    },
    data() {
      return {
        showDate: false,
        showDropdown: false,
        marketplaceData: {
          marketPlaceId: null,
          periodeDataId: null,
          startDate: null,
          endDate: null,
        },
        totalCardData: [
          {
            name: "Need Attention",
            totalValue: "0 Order(s)",
            key:'attention',
            color: "#FFE0B2",
            icons:
              "https://s3.ap-south-1.amazonaws.com/xtend3.0/icons/xtend/warning_outline.svg",
          },
          {
            name: "Orders @ Risk",
            totalValue: "0 Order(s)",
            key:'risk',
            color: "#FFCDD2",
            icons:
              "https://s3.ap-south-1.amazonaws.com/xtend3.0/icons/xtend/error_outline.svg",
          },
          {
            name: "New Orders",
            totalValue: "0 Order(s)",
            key:'new_orders',
            color: "#C8E6C9",
            icons:
              "https://s3.ap-south-1.amazonaws.com/xtend3.0/icons/xtend/neworders.svg",
          },
          {
            name: "Not Processed",
            totalValue: "0 Order(s)",
            key:'not_processed',
            color: "#CFD8DC",
            icons:
              "https://s3.ap-south-1.amazonaws.com/xtend3.0/icons/xtend/tag-outline.svg",
          },
        ],
        mwsHeaderData: [],
        periodeData: [
          { value: "today", name: "Today" },
          { value: "Week", name: "This Week" },
          { value: "Month", name: "This Month" },
          { value: "year", name: "This Year" },
          { value: "custom", name: "Custom" },
        ],
      };
    },
    methods: {
      moment,
      async getMwsHeader() {
        let res = await mwsHeaderService.findAll({});
        this.mwsHeaderData = res;
        this.$emit('setHeader',this.mwsHeaderData)
      },
      changemarketPlace($value) {
        authStore.commit("setMarketPlace", $value);
      },
      changePeriodeData($value) {
        if ($value == "custom") {
          this.showDate = true;
        } else {
          this.showDate = false;
        }
        authStore.commit("setTime", $value);
      },
      setFilter(){
        let $obj = Object.assign(this.marketplaceData);
        localStorage.setItem('dashBoardFiler',JSON.stringify($obj))
        this.getCardDetails();
        this.getTableDetails();
      },
      async getCardDetails(){
        let $card = await DashBoardService.getCardData(this.marketplaceData);
        if($card){
          this.totalCardData.forEach(e=>{
            let $ind = $card.findIndex(k=>k.title==e.key);
            if($ind>-1){
              e.totalValue = $card[$ind].total_count + ' Order(s)'
            }
          })
        }
        this.$emit('cardDetails',$card);
      },
      async getTableDetails(){
        let $table = await DashBoardService.getDashBoardData(this.marketplaceData);
        this.$emit('tableDetails',$table);
      }
    },
    created: async function () {
      await this.getMwsHeader();
      let $old = localStorage.getItem("dashBoardFiler");
      $old = $old ? JSON.parse($old):{};
      if($old && Object.keys($old).length>0){
        this.marketplaceData.marketPlaceId = $old.marketPlaceId ? $old.marketPlaceId : null;
        this.marketplaceData.periodeDataId = $old.periodeDataId ? $old.periodeDataId : 'today';
        if(this.marketplaceData.periodeDataId=='custom'){
          this.marketplaceData.startDate = this.marketplaceData.startDate ? moment(this.marketplaceData.startDate).format('YYYY-MM-DD') :  moment().subtract(15).format('YYYY-MM-DD');
          this.marketplaceData.endDate = this.marketplaceData.endDate ? moment(this.marketplaceData.endDate).format('YYYY-MM-DD') :  moment().format('YYYY-MM-DD');
        }
      }
      await this.getCardDetails();
      await this.getTableDetails();
    },
  };
  </script>