import ApiService from '../../api/service/ApiService.js';
const querystring = require('querystring');
import apiConfig from '@/api/config/config.js';
var DashBoardService = {

    getCardData: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "getCardData"
            $options.method = "get"
            $options.baseUrl = apiConfig.adminUrl
            if ($data) {
                $options.url = "getCardData?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
    getDashBoardData: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "getDashBoardData"
            $options.method = "get"
            $options.baseUrl = apiConfig.adminUrl
            if ($data) {
                $options.url = "getDashBoardData?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
    getFilterData: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "getFilterData"
            $options.method = "get"
            $options.baseUrl = apiConfig.adminUrl
            if ($data) {
                $options.url = "getFilterData?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    }
};
export default DashBoardService;