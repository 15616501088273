<template>
   <v-form  ref="form">
    
   <v-toolbar color="#fff" flat>
                    <v-toolbar-title class="settingHeader">Products</v-toolbar-title>
                    <v-btn class="button" v-if="!showReadOnly" @click="save">Save</v-btn>
                    <v-btn variant="outlined" @click="edit" v-if="showReadOnly" class="usersent-btn" prepend-icon="mdi-pencil-outline" style="float: right;">Edit</v-btn>
                     <v-btn
                 variant="text"
                density="comfortable"
                @click="close"
                v-if="!showReadOnly"
                elevation="0"
                icon="mdi-close"
                style="float: right;"
              ></v-btn>
                </v-toolbar>
                <v-divider class="mb-2"></v-divider>
  <v-row class="pa-10">
    <v-col cols="4" class="pa-0">
      <AutoComplete
        :data="productPreferences.syncType"
        @textData="($event) => {productPreferences.syncType = $event;}"
        :items="syncTypes"
        :text="'name'"
        :value="'id'"
        :label="'Sync Type'"
        :disabled="showReadOnly"
        multiple
        :chips="true"
        :closeChip="true"
      ></AutoComplete>
    </v-col>
    <v-col cols="8" class="pa-0 pl-2">
      <AutoComplete
        :data="productPreferences.attribute"
        @textData="($event) => {productPreferences.attribute = $event;}"
        :items="productAttributes"
        :chips="true"
        :closeChip="true"
        :text="'name'"
        :value="'id'"
        :label="'Field to be Sync with Marketplace'"
        :disabled="showReadOnly"
        :multiple="true"
      ></AutoComplete>
    </v-col>
    <v-col cols="4" class="pa-0">
      <InputBox
        :data="productPreferences.stylePattern"
        @textData="($event) => {productPreferences.stylePattern = $event;}"
        :label="'Style Pattern'"
        :disabled="showReadOnly"
      ></InputBox>
    </v-col>
    <v-col cols="5">
      <v-switch
        v-model="productPreferences.sku"
        color="#3C5AAA"
        :disabled="showReadOnly"
        label="Sku in marketplace does not match with Erp"
        FFFFf
      >
      </v-switch>
    </v-col>
    <v-col cols="3">
      <v-switch
        v-model="productPreferences.sync_inventory"
        color="#3C5AAA"
        label="Default sync inventory"
        :disabled="showReadOnly"
      >
      </v-switch>
    </v-col>
    <v-col cols="12" v-if="productPreferences.sku" class="pa-0 pl-1">
      <QuilEditor
                    :data="productPreferences.skuPattern"
                    :showReadOnly="showReadOnly"
                    :editorStyle="'height:100px;overflow: auto'"
                    :lable="'Use the following pattern to match the Sku with Erp'"
                    @textData="
                      ($event) => {
                        productPreferences.skuPattern = $event;
                      }
                    "
       ></QuilEditor>
    </v-col>
    <v-col cols="12" v-if="productPreferences.sync_inventory" class="pa-0 pl-1 py-4">
      <QuilEditor
                    :data="productPreferences.skip_product"
                    :editorStyle="'height:100px;overflow: auto'"
                    :lable="'Skip the Product from the order'"
                    :showReadOnly="showReadOnly"
                    @textData="
                      ($event) => {
                        productPreferences.skip_product = $event;
                      }
                    "
       ></QuilEditor>
    </v-col>
    <v-col cols="4" class="pa-0">
      <AutoComplete
        :data="productPreferences.defaultProductTypeId"
        @textData="selectProductType($event)"
        :items="productTypes"
        :label="'Default Product Type'"
        :text="'name'"
        :value="'id'" 
        @update:modelValue="getCategories"
        :disabled="showReadOnly"
      ></AutoComplete>
    </v-col>
    <v-col cols="4" class="pa-0 pl-2">
      <AutoComplete
        :data="productPreferences.defaultCategoryId"
        @textData="selectCategory($event)"
        :items="categories"
        :label="'Default Category'"
        :text="'name'"
        :value="'id'"
        :disabled="showReadOnly"
        @update:modelValue="getSubCategories"
        
      ></AutoComplete>
    </v-col>
    <v-col cols="4" class="pa-0 pl-2">
      <AutoComplete
        :data="productPreferences.defaultSubCategoryId"
        @textData="($event) => {productPreferences.defaultSubCategoryId = $event;}"
        :items="subCategories"
        :label="'Default Sub Category'"
        :text="'name'"
        :value="'id'"
        :disabled="showReadOnly"
      ></AutoComplete>
    </v-col>

    
    <v-col cols="4" class="pa-0">
      <AutoComplete
        :data="productPreferences.defaultBrandId"
        @textData="($event) => {productPreferences.defaultBrandId = $event;}"
        :items="brands"
        :label="'Default Brand'"
        :text="'name'"
        :value="'id'"
        :disabled="showReadOnly"
      ></AutoComplete>
    </v-col>
    <v-col cols="4" class="pa-0 pl-2">
      <AutoComplete
        :data="productPreferences.defaultSbuId"
        @textData="($event) => {productPreferences.defaultSbuId = $event;}"
        :items="sbus"
        :text="'name'"
        :value="'id'"
        :label="'Default Sbu'"
        :disabled="showReadOnly"
      ></AutoComplete>
    </v-col>
    <!-- <v-col cols="4">
      <AutoComplete
        :items="items"
        label="Default Season"
        item-title="name" item-value="id"
        v-model="productPreferences.defaultSeasonId"
        :disabled="showReadOnly"
      ></AutoComplete>
    </v-col>
    <v-col cols="4">
      <AutoComplete
        :items="items"
        label="Default Size"
        item-title="name" item-value="id"
        v-model="productPreferences.defaultSizeId"
        :disabled="showReadOnly"
      ></AutoComplete>
    </v-col>
    <v-col cols="4">
      <AutoComplete
        :items="items"
        label="Default Color"
        item-title="name" item-value="id"
        v-model="productPreferences.defaultColorId"
        :disabled="showReadOnly"
      ></AutoComplete>
    </v-col>
    <v-col cols="4">
      <AutoComplete
        :items="items"
        label="Default Consumer"
        item-title="name" item-value="id"
        v-model="productPreferences.defaultConsumerId"
        :disabled="showReadOnly"
      ></AutoComplete>
    </v-col> -->
  </v-row>
  </v-form>
</template>
<script >
import MwsPreferenceService from '@/services/mws/MwsPreferenceService';
import ProductTypeService from '@/services/catalogs/ProductTypeService';
import CategoryService from '@/services/catalogs/CategoryService';
import SubCategoryService from '@/services/catalogs/SubCategoryService';
import BrandService from '@/services/catalogs/BrandService';
import SbuService from '@/services/admin/SbuService.js';
import QuilEditor from "@/components/QuilEditor.vue";
export default {
  components: {
    QuilEditor,
  },
  data() {
    return {
      showReadOnly:true,
      items: [
        {id:1,name:'Test'},
        {id:2,name:'Main'},
        {id:3,name:'List'},
        {id:4,name:'Consumer'},
      ],
      mwsId:localStorage.getItem("mwsHeaderId"),
      mwsHeader: {
        id: null,
        rule_type:"product_preferences",
        mws_header_id:null
      },
      syncTypes: [
        { id: "push", name: "Push" },
        { id: "pull", name: "Pull" },
      ],
      productAttributes: [
        { id: "price", name: "Price" },
        { id: "sku", name: "SKU" },
        { id: "upc", name: "UPC" },
        { id: "dimension", name: "Dimensions and tag" },
        { id: "price_per_region", name: "Price per Region" },
        { id: "images", name: "Image" },
      ],
      productPreferences: {
        sync_inventory:false,
        syncType: [],
        skip_product:null,
        attribute: [],
        skuPattern: "",
        sku:null,
        defaultProductTypeId: null,
        defaultCategoryId: null,
        defaultSubCategoryId: null,
        defaultSbuId: null,
        defaultBrandId: null,
        defaultSeasonId: null,
        defaultColorId: null,
        defaultSizeId: null,
        defaultConsumerId: null,
        categoryMap: [],
        stylePattern:"{{sku}}"
      },
      productTypes:[],
      categories:[],
      subCategories:[],
      brands:[],
      sbus:[]
    };
  },
  created(){
    this.getProductTypes()
    // this.getCategories()
    // this.getSubCategories()
    this.getBrands()
    this.getSbus();
    this.getMws()
  },
  methods:{
    selectProductType($value){
         this.productPreferences.defaultProductTypeId = $value
         this.getCategories(this.productPreferences.defaultProductTypeId)
         this.productPreferences.defaultCategoryId = null
         this.productPreferences.defaultSubCategoryId = null
    },
    selectCategory($value){
      this.productPreferences.defaultCategoryId = $value
       this.getSubCategories( this.productPreferences.defaultCategoryId)
       this.productPreferences.defaultSubCategoryId = null
    },
    async getProductTypes(){
      let $res = await ProductTypeService.findAll({});
      if($res && $res.length>0){
        this.productTypes = $res;
    }
    else{
      this.productTypes=[];
    }
    },
    async getCategories(id){
      let $res = await CategoryService.findAll({product_type_id:id});
      if($res && $res.length>0){
        this.categories = $res;
      }
      else{
      this.categories=[];
    }
    },
    async getSubCategories(id){
      let $res = await SubCategoryService.findAll({category_id:id});
      if($res && $res.length>0){
        this.subCategories = $res;
      }
      else{
      this.subCategories=[];
    }
    },
    async getBrands(){
      let $res = await BrandService.findAll({});
      if($res && $res.length>0){
        this.brands = $res;
      }
      else{
      this.brands=[];
    }
    },
    async getSbus(){
      let $res = await SbuService.findAll({});
      if($res && $res.length>0){
        this.sbus = $res;
      }
      else{
      this.sbus=[];
    }
    },
    edit(){
      this.showReadOnly = false
    },
    close(){
          this.showReadOnly = true
    },
    async save() {
      this.mwsHeader.mws_header_id = this.mwsId
      let $obj = Object.assign({},this.productPreferences)
        this.mwsHeader.preferences = JSON.stringify($obj)
        this.mwsHeader.rule_type="product_preferences";
      if (this.mwsHeader && this.mwsHeader.id) {
        await MwsPreferenceService.update(this.mwsHeader);
        this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
        this.showReadOnly = true
      } else { try {
        await MwsPreferenceService.create(this.mwsHeader);
        this.$store.dispatch('setSnackbar', { text: "Saved Successfully" });
        this.showReadOnly = true
      }
      catch (e) {
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "Something Went Wrong",
            });
          }
    }
    },
    async getMws(){
        let $result = await MwsPreferenceService.findAll({mws_header_id:this.mwsId,rule_type:"product_preferences"});
        $result = $result[0]
        if($result){
            let $obj = {};
            $obj.id = $result.id;
            $obj.preferences = $result.preferences && typeof $result.preferences=='string' ? JSON.parse($result.preferences) : {};
            $obj.rule_type="product_preferences";
            this.mwsHeader = $obj;
            this.productPreferences = this.mwsHeader.preferences;
            if(this.productPreferences.defaultProductTypeId){
              this.getCategories(this.productPreferences.defaultProductTypeId)
            }
            if(this.productPreferences.defaultCategoryId){
              this.getSubCategories(this.productPreferences.defaultCategoryId)
            }
            
            
        }
    }
  }
};
</script>