import Setting from '@/modules/setting/Setting.vue'
import ProductSetting from '@/modules/setting/ProductSetting.vue'
import CompanyProfile from '@/modules/setting/admin/CompanyProfile.vue'
import DiscountCode from '@/modules/setting/admin/DiscountCode.vue'
import Division from '@/modules/setting/admin/Division.vue'
import ERPProfile from '@/modules/setting/admin/ERPProfile.vue'
import Fulfillments from '@/modules/setting/admin/Fulfillments.vue'
import ShipmentService from '@/modules/setting/admin/ShipmentService.vue'
import Shippers from '@/modules/setting/admin/Shippers.vue'
import ShippingZone from '@/modules/setting/admin/ShippingZone.vue'
import ProductType from '@/modules/setting/product/ProductType.vue'
import Category from '@/modules/setting/product/Category.vue'
import Subcategory from '@/modules/setting/product/SubCategory.vue'
import Brand from '@/modules/setting/product/Brand.vue'
import Sbu from '@/modules/setting/product/SBU.vue'
import Uom from '@/modules/setting/product/UOM.vue'
import Color from '@/modules/setting/product/Color.vue'
import Consumer from '@/modules/setting/product/Consumer.vue'
import Material from '@/modules/setting/product/Material.vue'
import Season from '@/modules/setting/product/Season.vue'
import Attribute from '@/modules/setting/product/Attribute.vue'
import Size from '@/modules/setting/product/Size.vue'
import FreightTemplate from '@/modules/setting/product/FreightTemplate.vue'
import PricingTemplate from '@/modules/setting/product/PricingTemplate.vue'
import ExchangeRates from '@/modules/setting/admin/ExchangeRates.vue'
import Accounts from '@/modules/setting/admin/Accounts.vue'


export default [
    {
        path: '/setting',
        component: Setting,
        children: [
            {
                path: "companyprofile",
                component: CompanyProfile,
                meta: {
                    breadCrumb: [
                      {
                        to:'/setting/companyprofile',
                        text: 'Settings',
                        color:'#3C5AAA'
                      },
                      {
                        to:'/setting/companyprofile',
                        text: 'Admin',
                        color:'#3C5AAA'
                      },
                      {
                        to:'/setting/companyprofile',
                        text: 'CompanyProfile'
                      },
                    ]
                  }

            },
            {
                path: "accounts",
                component: Accounts,
                meta: {
                    breadCrumb: [
                      {
                        to:'/setting/accounts',
                        text: 'Settings',
                        color:'#3C5AAA'
                      },
                      {
                        to:'/setting/accounts',
                        text: 'Admin',
                        color:'#3C5AAA'
                      },
                      {
                        to:'/setting/accounts',
                        text: 'Accounts'
                      },
                    ]
                  }

            },
            {
                path: "discountcode",
                component: DiscountCode,
                meta: {
                    breadCrumb: [
                      {
                        to:'/setting/discountcode',
                        text: 'Settings',
                        color:'#3C5AAA'
                      },
                      {
                        to:'/setting/discountcode',
                        text: 'Admin',
                        color:'#3C5AAA'
                      },
                      {
                        to:'/setting/discountcode',
                        text: 'DiscountCode'
                      },
                    ]
                  }

            },
            {
                path: "division",
                component: Division,
                meta: {
                    breadCrumb: [
                      {
                        to:'/setting/division',
                        text: 'Settings',
                        color:'#3C5AAA'
                      },
                      {
                        to:'/setting/division',
                        text: 'Admin',
                        color:'#3C5AAA'
                      },
                      {
                        to:'/setting/division',
                        text: 'Division'
                      },
                    ]
                  }

            },
            {
                path: "erpprofile",
                component: ERPProfile,
                meta: {
                    breadCrumb: [
                      {
                        to:'/setting/erpprofile',
                        text: 'Settings',
                        color:'#3C5AAA'
                      },
                      {
                        to:'/setting/erpprofile',
                        text: 'Admin',
                        color:'#3C5AAA'
                      },
                      {
                        to:'/setting/erpprofile',
                        text: 'ERPProfile'
                      },
                    ]
                  }

            },
            {
                path: "shipmentservice",
                component: ShipmentService,
                meta: {
                    breadCrumb: [
                      {
                        to:'/setting/shipmentservice',
                        text: 'Settings',
                        color:'#3C5AAA'
                      },
                      {
                        to:'/setting/shipmentservice',
                        text: 'Admin',
                        color:'#3C5AAA'
                      },
                      {
                        to:'/setting/shipmentservice',
                        text: 'ShipmentService'
                      },
                    ]
                  }

            },
            {
                path: "fulfillments",
                component: Fulfillments,
                meta: {
                    breadCrumb: [
                      {
                        to:'/setting/fulfillments',
                        text: 'Settings',
                        color:'#3C5AAA'
                      },
                      {
                        to:'/setting/fulfillments',
                        text: 'Admin',
                        color:'#3C5AAA'
                      },
                      {
                        to:'/setting/fulfillments',
                        text: 'Fulfillments'
                      },
                    ]
                  }

            },
            {
                path: "shippers",
                component: Shippers,
                meta: {
                    breadCrumb: [
                      {
                        to:'/setting/shippers',
                        text: 'Settings',
                        color:'#3C5AAA'
                      },
                      {
                        to:'/setting/shippers',
                        text: 'Admin',
                        color:'#3C5AAA'
                      },
                      {
                        to:'/setting/shippers',
                        text: 'Shippers'
                      },
                    ]
                  }

            },
            {
                path: "shippingzone",
                component: ShippingZone,
                meta: {
                    breadCrumb: [
                      {
                        to:'/setting/shippingzone',
                        text: 'Settings',
                        color:'#3C5AAA'
                      },
                      {
                        to:'/setting/shippingzone',
                        text: 'Admin',
                        color:'#3C5AAA'
                      },
                      {
                        to:'/setting/shippingzone',
                        text: 'ShippingZone'
                      },
                    ]
                  }

            },
            {
                path: "exchangerates",
                component: ExchangeRates,
                meta: {
                    breadCrumb: [
                      {
                        to:'/setting/exchangerates',
                        text: 'Settings',
                        color:'#3C5AAA'
                      },
                      {
                        to:'/setting/exchangerates',
                        text: 'Admin',
                        color:'#3C5AAA'
                      },
                      {
                        to:'/setting/exchangerates',
                        text: 'ExchangeRates'
                      },
                    ]
                  }

            },

        ]
    },
    {
        path: '/productsetting',
        component: ProductSetting,
        children: [
            {
                path: "producttype",
                component: ProductType,
                meta: {
                    breadCrumb: [
                      {
                        to:'/productsetting/producttype',
                        text: 'Settings',
                        color:'#3C5AAA'
                      },
                      {
                        to:'/productsetting/producttype',
                        text: 'Product',
                        color:'#3C5AAA'
                      },
                      {
                        to:'/productsetting/producttype',
                        text: 'ProductType'
                      },
                    ]
                  }

            },
            {
                path: "category",
                component: Category,
                meta: {
                    breadCrumb: [
                      {
                        to:'/productsetting/category',
                        text: 'Settings',
                        color:'#3C5AAA'
                      },
                      {
                        to:'/productsetting/category',
                        text: 'Product',
                        color:'#3C5AAA'
                      },
                      {
                        to:'/productsetting/category',
                        text: 'Category'
                      },
                    ]
                  }

            },
            {
                path: "subcategory",
                component: Subcategory,
                meta: {
                    breadCrumb: [
                      {
                        to:'/productsetting/subcategory',
                        text: 'Settings',
                        color:'#3C5AAA'
                      },
                      {
                        to:'/productsetting/subcategory',
                        text: 'Product',
                        color:'#3C5AAA'
                      },
                      {
                        to:'/productsetting/subcategory',
                        text: 'Subcategory'
                      },
                    ]
                  }

            },
            {
                path: "brand",
                component: Brand,
                meta: {
                    breadCrumb: [
                      {
                        to:'/productsetting/brand',
                        text: 'Settings',
                        color:'#3C5AAA'
                      },
                      {
                        to:'/productsetting/brand',
                        text: 'Product',
                        color:'#3C5AAA'
                      },
                      {
                        to:'/productsetting/brand',
                        text: 'Brand'
                      },
                    ]
                  }

            },
            {
                path: "sbu",
                component: Sbu,
                meta: {
                    breadCrumb: [
                      {
                        to:'/productsetting/sbu',
                        text: 'Settings',
                        color:'#3C5AAA'
                      },
                      {
                        to:'/productsetting/sbu',
                        text: 'Product',
                        color:'#3C5AAA'
                      },
                      {
                        to:'/productsetting/sbu',
                        text: 'Sbu'
                      },
                    ]
                  }

            },
            {
                path: "uom",
                component: Uom

            },
            {
                path: "color",
                component: Color

            },
            {
                path: "consumer",
                component: Consumer

            },
            {
                path: "material",
                component: Material

            },
            {
                path: "season",
                component: Season

            },
            {
                path: "attribute",
                component: Attribute,
                meta: {
                    breadCrumb: [
                      {
                        to:'/productsetting/attribute',
                        text: 'Settings',
                        color:'#3C5AAA'
                      },
                      {
                        to:'/productsetting/attribute',
                        text: 'Product',
                        color:'#3C5AAA'
                      },
                      {
                        to:'/productsetting/attribute',
                        text: 'Attribute'
                      },
                    ]
                  }

            },
            {
                path: "size",
                component: Size

            },
            {
                path: "freighttemplate",
                component: FreightTemplate

            },
            {
                path: "pricingtemplate",
                component: PricingTemplate

            },

        ]
    },
]