<template>
  <v-label class="commonLabel ml-1" v-if="label">{{ label }}[E]
    <span style="color: rgb(207, 7, 7);padding-left: 2px;font-weight: 700;" v-if="rules">*</span>
  </v-label>
  <v-autocomplete class="text-Field" density="compact" :rules="rules" v-model="value" @update:model-value="changeText()"
    :disabled="disabled" :items="enumData" :placeholder="label || placeholder" variant="outlined" item-title="display_value"
    item-value="data_value">
  </v-autocomplete>
</template>

<script>
import EnumService from '@/services/admin/EnumService';
export default {
  props: ["items", "rules", "label", "disabled", "domainName", "data",'placeholder','reload'],
  data() {
    return {
      enumData: [],
      value: null
    }
  },
  created() {
    if (this.domainName) {
      this.getEnum()
    }
      this.value = this.data
  },
  watch: {
    domainName() {
      this.getEnum()
    },
    data() {
        this.value = this.data
    },
    reload(){
      this.getEnum();
    }
  },
  methods: {
    changeText() {
      this.$emit('textData', this.value)
    },
    async getEnum() {
      if (this.domainName == 'usertype' || this.domainName == 'varianttype' || this.domainName == 'ERP' 
      || this.domainName == 'integrationtype' || this.domainName == 'domain' || this.domainName == 'servicelevel'
      || this.domainName == 'servicetype' || this.domainName == 'currencies' || this.domainName == 'fieldtypes' 
      || this.domainName == 'databases' || this.domainName == 'marketplaces'|| this.domainName == 'endpoint') {
        let $res = await EnumService.findAll({ domain: this.domainName, company_id: 0 })
        this.enumData = $res
      }
      else {
        let $res = await EnumService.findAll({ domain: this.domainName })
        this.enumData = $res
      }
    }
  }
}
</script>

<style></style>

  
  
  