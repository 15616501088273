<template>
  <PrimeTable
    :tableName="'Users'"
    activity="Users"
    :showTableHeader="true"
    :headers="column"
    :tableData="data"
    @rowAction="rowAction($event)"
    @addDialog="addDialog"
    @filterClicked="filterClicked"
    :modelName="'users'"
    @filterData="applyFilter($event)"
  ></PrimeTable>
  <v-dialog v-model="dialog" width="800px" persistent>
    <AddUserTab @closeDialog="closeDialog($event)" :type="type" :id="userId" />
  </v-dialog>
  <v-dialog v-model="confirmation" width="300px" persistent>
    <ConfirmationDialog
      @cancelled="cancelled()"
      @deleted="deleted"
      :content="confirmationContent"
      :type="'inactive'"
    />
  </v-dialog>
  <div v-if="openDrawer">
    <Filter
      :model="'users'"
      v-on:closeFilter="filterClose()"
      v-on:filterClosed="closeFilter1($event)"
      :drawerStatus="openDrawer"
    />
  </div>
</template>

<script>
import AddUserTab from "../AddAdmin/AddUserTab.vue";
import UserModel from "@/services/admin/UserService";
import Filter from "@/components/Filter.vue";
import ConfirmationDialog from "@/components/DialogConfirmation.vue";
export default {
  components: {
    AddUserTab,
    Filter,
    ConfirmationDialog
  },
  data() {
    return {
      confirmationContent: null,
      confirmation: false,
      dialog: false,
      data: [],
      userId: "",
      openDrawer: false,
      column: [
        { title: "Name", align: "start", key: "name" },
        { title: "Email", align: "start", key: "email" },
        { title: "Mobile", align: "start", key: "mobile" },
        { title: "Modified By", align: "start", key: "modified_name" },
        { title: "Modified on", align: "start", key: "modified" },
        { title: "Status", key: "active", sortable: false },
        { title: "Action", key: "actions", sortable: false },
      ],
      active: [
        { id: 1, name: "Active" },
        { id: 0, name: "Inactive" },
      ],
    };
  },
  created() {
    let $value = {};
    this.getUsers($value);
  },
  methods: {
    applyFilter($value){
      this.getUsers($value)
    },
    filterClicked() {
      this.openDrawer = true;
    },
    filterClose() {
      this.openDrawer = false;
    },
    closeFilter1($data) {
      console.log($data, "closeFilter1");
    },
    async getUsers($value) {
      await UserModel.getAll($value)
        .then((response) => {
          this.data = response;
          this.data.map((obj) => {
            return (obj.active = this.active.find((type) => type.id == obj.active).name);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    rowAction($event) {
      console.log($event, "rrrr");
      if ($event.type == "edit") {
        this.dialog = true;
        this.type = $event.type;
        this.userId = $event.value.id;
      }
      if ($event.type == "view") {
        this.dialog = true;
        this.type = $event.type;
        this.userId = $event.value.id;
      }
      if ($event.type == "delete") {
        this.dialog = false;
        this.confirmation = true;
        this.confirmationContent = `Do you want to Inactive the User ${$event.value.name} ?`;
        this.userId = $event.value.id;
      }
      if ($event.type == "select") {
        this.dialog = true;
        this.type = "view";
        this.userId = $event.value.id;
      }
    },
    addDialog() {
      this.dialog = true;
      this.type = "add";
      this.userId = null;
    },
    closeDialog($event) {
      if ($event) {
        this.getUsers();
      }
      this.dialog = false;
    },
    async deleted() {
      this.confirmation = false;
      await UserModel.update({ id: this.userId ,active : 0});
      this.getUsers();
    },
    cancelled() {
      this.userId = null;
      this.confirmation = false;
    },
  },
};
</script>
