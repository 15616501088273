<template>
      <v-form @submit.prevent="updateDetails()" ref="form">
  <v-container>
    <v-row>
      <v-col cols="6" class="pa-0 pl-2">
        <InputBox :data="details.label" @textData="($event) => { details.label = $event; }" :label="'Label'"
          :disabled="showReadOnly" />
      </v-col>
      <v-col cols="6" class="pa-0 pl-2">
        <AutoComplete :data="details.data_type" @textData="($event) => { details.data_type = $event; }" :label="'Data Type'"
          :items="data_types" text="name" value="code" :disabled="showReadOnly" />
      </v-col>
      <v-col cols="6" class="pa-0 pl-2">
        <EnumDropdown :data="details.field_type" @textData="($event) => { details.field_type = $event; }"
          :disabled="showReadOnly" :label="'Field Type'" :domainName="'fieldtypes'" />
      </v-col>
      <v-col cols="6" class="pa-0 pl-2" v-if="details.field_type == 'select' || details.field_type == 'autocomplete'
        ">
        <EnumDropdown :data="details.database_name" @textData="checkDatabase($event)"
          :disabled="showReadOnly" :label="'Data Base'" :domainName="'databases'" :rules="databaseRule"/>
      </v-col>
      <v-col cols="6" class="pa-0 pl-2" 
       v-if="details.field_type == 'select' || details.field_type == 'autocomplete' || details.field_type == 'enum'">
        <AutoComplete :rules="sourceRule" :data="details.source" @textData="($event) => { details.source = $event; }" :disabled="showReadOnly"
          :label="'Source'" :items="details.field_type == 'select' || details.field_type == 'autocomplete' ? allTables : allEnums
          " :text="details.field_type == 'select' || details.field_type == 'autocomplete' ? 'table_name' : 'domain'
          " :value="details.field_type == 'select' || details.field_type == 'autocomplete' ? 'table_name' : 'domain'" />
      </v-col>
      <v-col v-if="details.field_type == 'select' ||
        details.field_type == 'autocomplete'" cols="6" class="pa-0 pl-2">
        <InputBox :data="details.value" @textData="($event) => { details.value = $event; }" :disabled="showReadOnly"
          :label="'Value'" :rules="valueRule"/>
      </v-col>
      <v-col v-if="details.field_type == 'select' ||
        details.field_type == 'autocomplete'" cols="6" class="pa-0 pl-2">
        <InputBox :data="details.text" @textData="($event) => { details.text = $event; }" :disabled="showReadOnly"
          :label="'Text'" :rules="textRule"/>
      </v-col>
      <v-col cols="6" class="pa-0 pl-2">
        <InputBox :data="details.sequence" @textData="($event) => { details.sequence = $event; }" :disabled="showReadOnly"
          :label="'Sequence'" />
      </v-col>
      <v-col cols="6" class="pa-0 pl-2">
        <InputBox :data="details.width" @textData="($event) => { details.width = $event; }" :disabled="showReadOnly"
          :label="'Width'" />
      </v-col>
      <v-col cols="6" class="pa-0 pl-2">
        <InputBox :data="details.formatters" @textData="($event) => { details.formatters = $event; }"
          :disabled="showReadOnly" :label="'Format'" />
      </v-col>
      <v-col cols="6" class="pa-0 pl-2">
        <AutoComplete :disabled="showReadOnly" :label="'Alignment'" />
      </v-col>
      <v-col cols="6" class="pa-3">
        <v-switch :disabled="showReadOnly" label="Filter Required" color="primary"
          v-model="details.filter_required"></v-switch>
      </v-col>
    </v-row>
    <v-row class="dialogfooter" align="end" v-if="!showReadOnly">
      <v-btn class="save ml-2" type="submit">Update</v-btn>
    </v-row>
  </v-container>
</v-form>
</template>

<script>
import ListModelDetailService from "@/services/admin/ListModelDetailService";
import EnumDropdown from "@/components/EnumDropdown.vue"
import EnumService from '@/services/admin/EnumService';
import ListModelService from "@/services/admin/ListModelService";
export default {
  components: {
    EnumDropdown,
  },
  props: ["selectedColumn", "listModelData", "showReadOnly"],
  data() {
    return {
      details: {
        databaseRule: [(value) => !!value || "DataBase is Required"],
        sourceRule: [(value) => !!value || "Source is Required"],
        valueRule: [(value) => !!value || "Value is Required"],
        textRule: [(value) => !!value || "Text is Required"],
        label: null,
        data_type: null,
        value: null,
        text: null,
        source_type: null,
        source: null,
        sequence: null,
        formatters: null,
        filter_required: null,
        field_type: null,
        database_name: null,
        width: null,
        id: null,
      },
      data_types: [
        { code: "integer", name: "Integer" },
        { code: "boolean", name: "Boolean" },
        { code: "string", name: "String" },
        { code: "varchar", name: "Varchar" },
        { code: "float", name: "Float" },
        { code: "date", name: "Date" },
      ],
      field_types: [],
      allEnums: [],
      allTables: [],
      dataBases: [],
    };
  },
  watch: {
    selectedColumn() {
      // console.log(this.selectedColumn,'selectedColumn');
      this.details = this.selectedColumn;
      this.details.filter_required = this.details.filter_required ? true : false
      // console.log(this.details,'selectedColumn1111');
    },
  },
  created() {
    if (this.selectedColumn) {
      this.details = this.selectedColumn;
      this.details.filter_required = this.details.filter_required ? true : false
    }
    this.getEnums();
  },
  computed: {
    textRule(){
      return [(v) => !!v || "Text is required"];
    },    
    databaseRule(){
      return [(v) => !!v || "DataBase is required"];
    },    
    sourceRule(){
      return [(v) => !!v || "Source is required"];
    },    
    valueRule(){
      return [(v) => !!v || "Value is required"];
    },    
  },
  methods: {
    checkDatabase($value){
      this.details.source = null
      this.details.database_name = $value
      this.getTables($value)
    },
    async getEnums(){
     let $data =  await EnumService.findAll({});
     this.allEnums = $data && $data.length >0 ? $data : []
    },
    async getTables($value) {
      let $data = await ListModelService.getTables({database:$value});
      this.allTables = $data && $data.length > 0 ? $data : [];
    },
    async updateDetails() {
      let validation = await this.$refs.form.validate();
      if (!validation.valid) {
        console.log("Not valid");
      } else {
        let $obj = Object.assign({}, this.details);
        delete $obj.isActive;
        delete $obj.users
        await ListModelDetailService.update($obj).then(res => {
        console.log(res)
        this.$store.dispatch("setSnackbar", { text: "Saved Successfully" });
        }, error => {
        console.log(error)
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: "Something Went Wrong",
        });
      });
       }
    },
  },
};
</script>

<style></style>