<template>
  <PrimeTable
    :tableName="'Season'"
    :showTableHeader="true"
    :headers="column"
    :tableData="data"
    @rowAction="rowAction"
    @addDialog="addDialog"
  ></PrimeTable>
  <v-dialog v-model="dialog" width="600px" persistent>
    <AddSeason @closeDialog="closeDialog" :type="type" />
  </v-dialog>
</template>
  
  <script>
import SeasonModel from "@/models/SeasonService";
import AddSeason from "@/modules/setting/AddAdmin/AddSeason.vue";
export default {
  components: {
    AddSeason,
  },
  data() {
    return {
      data: [],
      dialog: false,
      column: [
        { title: "Name", align: "start", key: "name" },
        { title: "Code", align: "start", key: "code" },
        { title: "Description", align: "start", key: "description" },
        { title: "Status", key: "active", sortable: false },
        { title: "Action", key: "actions", sortable: false },
      ],
      active: [
        { id: 1, name: "Active" },
        { id: 0, name: "Inactive" },
      ],
    };
  },
  created() {
    this.getSeason();
  },
  methods: {
    async getSeason() {
      await SeasonModel.findAll()
        .then((response) => {
          console.log(response, "working1111111");
          this.data = response.data;
          this.data.map((obj) => {
            return (obj.active = this.active.find(
              (type) => type.id == obj.active
            ).name);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    rowAction(e) {
      if (e.type == "edit") {
        this.dialog = true;
        this.type = e.type;
      }
      if (e.type == "view") {
        this.dialog = true;
        this.type = e.type;
      }
      if (e.type == "delete") {
        this.dialog = true;
      }
      if (e.type == "select") {
        this.dialog = true;
        this.type = e.type;
      }
    },

    addDialog() {
      this.dialog = true;
      this.type = "add";
    },
    closeDialog() {
      this.dialog = false;
    },
  },
};
</script>