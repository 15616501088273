import { createApp } from 'vue'
import App from './App.vue'
import 'vuetify/styles'
import './scss/style.scss'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import router from './router'
import VueApexCharts from "vue3-apexcharts";
import PrimeVue from 'primevue/config';
import "primevue/resources/themes/saga-blue/theme.css"; //theme
import "primevue/resources/primevue.min.css"; //core CSS
import "primeicons/primeicons.css"; //icons
import InputBox from "@/components/InputBox.vue";
import AutoComplete from '@/components/AutoComplete.vue';
import EnumDropdown from '@/components/EnumDropdown.vue'
import PrimeTable from '@/components/PrimeTable.vue';
import TextArea from '@/components/TextArea.vue'
import SearchBox from '@/components/SearchBox.vue'
import { initializeApp } from 'firebase/app';
import Calendar from "primevue/calendar";
import store from './store/index.js'
import VueMq from 'vue3-mq'
loadFonts()
var firebaseConfig = {
  apiKey: "AIzaSyBuSxDLtQ2yaHo-qiCrTVANCaI1hpuS4iQ",
  authDomain: "tibura-auth.firebaseapp.com",
  projectId: "tibura-auth",
  storageBucket: "tibura-auth.appspot.com",
  messagingSenderId: "1092532911259",
  appId: "1:1092532911259:web:ed47f643c831ae4ed9da3e",
  // measurementId: "G-2FWL8ZZ5KS"
}

/*firebase.initializeApp(firebaseConfig);
if(firebase.messaging.isSupported()){
    Vue.prototype.$messaging = firebase.messaging()
}*/
initializeApp(firebaseConfig);
const app = createApp(App);
app.use(VueMq, {
  breakpoints: {
    mobile: 600,    // for screens smaller than 600px wide
    tablet: 960,    // for screens between 600px and 960px wide
    laptop: 1264,   // for screens between 960px and 1264px wide
    desktop: Infinity, // for screens wider than 1264px
  },
});
app.use(router).use(VueApexCharts).use(vuetify).use(PrimeVue).use(store);
app.component('InputBox', InputBox)
  .component('AutoComplete', AutoComplete)
  .component('PrimeTable', PrimeTable)
  .component('TextArea', TextArea)
  .component('SearchBox', SearchBox)
  .component('Calendar', Calendar)
  .component('EnumDropdown', EnumDropdown)
router.isReady().then(() => {
  app.mount('#app')
})
// createApp(App).use(router)
//   .use(vuetify).use(VueApexCharts)
//   .mount('#app')
