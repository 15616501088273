<template>
  <v-form @Submit="save" ref="form">
    <v-toolbar color="indigo-lighten-5">
      <v-toolbar-title>{{ formData.name }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn class="button" v-if="showReadonly" @click="showReadonly = false">
        Edit
      </v-btn>
      <v-btn class="button" v-if="!showReadonly" @click="save()"> Save </v-btn>
      <v-btn icon v-if="!showReadonly">
        <v-icon color="indigo" @click="getCompanyData">mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-row>
      <v-col cols="4" class="avatar">
        <v-avatar color="surface-variant" rounded="2" size="200">
          <v-img
            src="https://s3.ap-south-1.amazonaws.com/xtend3.0/icons/logo/xtend.svg"
            alt="x-tend"
          ></v-img
        ></v-avatar>
      </v-col>

      <v-col>
        <v-container>
          <v-row class="mt-4" :disabled="showReadonly">
            <v-col cols="8" class="pa-0">
              <InputBox
                :label="'Name'"
                :data="formData.name"
                @textData="
                  ($event) => {
                    formData.name = $event;
                  }
                "
                :disabled="showReadonly"
              />
            </v-col>
            <v-col cols="8" class="pa-0">
              <InputBox
                :label="'First Line Of Address'"
                :data="formData.address1"
                @textData="
                  ($event) => {
                    formData.address1 = $event;
                  }
                "
                :disabled="showReadonly"
              />
            </v-col>
            <v-col cols="8" class="pa-0">
              <InputBox
                :label="'Second Line Of Address'"
                :data="formData.address2"
                @textData="
                  ($event) => {
                    formData.address2 = $event;
                  }
                "
                :disabled="showReadonly"
              />
            </v-col>
            <v-col cols="8" class="pa-0">
              <InputBox
                :label="'City'"
                :data="formData.city"
                @textData="
                  ($event) => {
                    formData.city = $event;
                  }
                "
                :disabled="showReadonly"
              />
            </v-col>
            <v-col cols="8" class="pa-0">
              <InputBox
                :label="'Zip'"
                :data="formData.zip_code"
                @textData="
                  ($event) => {
                    formData.zip_code = $event;
                  }
                "
                :disabled="showReadonly"
                @keypress="allowNumbers($event)"
              />
            </v-col>
            <v-col cols="8" class="pa-0">
              <AutoComplete
                :label="'Country'"
                :data="formData.country"
                @textData="countrySelect($event)" 
                :text="'name'"
                :value="'id'"
                :items="countryData"
                :disabled="showReadonly"
              />
            </v-col>
            <v-col cols="8"  class="pa-0">
              <AutoComplete
                :label="'State'"
                :data="formData.state"
                @textData="
                  ($event) => {
                    formData.state = $event;
                  }
                "
                :items="displayStates"
                :value="'id'"
                :text="'name'"
                :disabled="showReadonly"
                @click="stateSelect()"
              />
            </v-col>
            <v-col cols="8" class="pa-0">
              <!-- <InputBox
                :label="'Currency'"
                :data="formData.currency"
                @textData="
                  ($event) => {formData.currency = $event; }
                "
                :disabled="showReadonly"
              /> -->
              <EnumDropdown :data="formData.currency" @textData="($event)=>{ formData.currency = ($event) }" :domainName="'currencies'"
              :disabled="showReadonly" :label="'Currency'" />
            </v-col>
            <v-col cols="8" class="pa-0">
              <InputBox
                :label="'Website'"
                :data="formData.portal_id"
                @textData="
                  ($event) => {
                    formData.portal_id = $event;
                  }
                "
                :disabled="showReadonly"
              />
            </v-col>
            <v-col cols="8" class="pa-0">
              <InputBox
                :label="'Email'"
                :data="formData.email"
                @textData="
                  ($event) => {
                    formData.email = $event;
                  }
                "
                :disabled="showReadonly"
              />
            </v-col>
            <v-col cols="8" class="pa-0">
              <InputBox
                :label="'Mobile'"
                :data="formData.mobile"
                @textData="
                  ($event) => {
                    formData.mobile = $event;
                  }
                "
                :disabled="showReadonly"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
// import InputBoxVue from "@/components/InputBox.vue";
import States from "@/api/service/States.json";
import Country from "@/api/service/Countries.json";
import CompanyService from "@/services/admin/CompanyService";
export default {
  components: {
    // InputBoxVue,
  },
  data() {
    return {
      formData: {},
      stateData: States,
      countryData: Country,
      displayStates: [],
      showReadonly: true,
    };
  },
  created() {
    this.getCompanyData();
  },
  methods: {
    allowNumbers(evt) {
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();
      if (!/^[+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    async getCompanyData() {
      let companyDetails = JSON.parse(localStorage.getItem("companyDetails"));
      let company = await CompanyService.findOne({ id: companyDetails.id });
      console.log("212111111",company)
      this.formData = company[0];
      this.showReadonly = true;
    },
    countrySelect($event) {
      this.formData.country = $event
      this.formData.state = '';
      this.displayStates = this.stateData[$event];
    },
    stateSelect() {
      this.displayStates = this.stateData[this.formData.country];
    },
    async save() {
      if (this.formData.id) {
        await CompanyService.update(this.formData);
        localStorage.setItem("companyDetails", JSON.stringify(this.formData));
        this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
        this.showReadonly = true;
      }
    },
  },
};
</script>
<style>
.avatar {
  padding-top: 175px;
  display: flex;
  justify-content: center;
  vertical-align: middle;
}
</style>
