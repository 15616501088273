<template>
    <v-col class="ml-2 settingHeader"><v-icon class="mr-2" color="#000000" @click="$router.go(-1)">mdi-arrow-left-circle-outline</v-icon>Identity Settings </v-col>

    <v-row class="pa-5 pt-0 contentsubmenu">
        <v-card elevation="0" class="content-menu-cardleft">
            <v-list>
                <v-list-item class="pa-4 contentsidemenu" v-for="(item, index) in menu" :key="index" :value="index" active-class="selecteditem" :to="item.router">
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-card>
        <v-card elevation="0" class="pa-2 content-menu-cardright">
            <RouterView></RouterView>
        </v-card>
    </v-row>
    
</template>
<script>
export default{
    data(){
        return{
       menu:[
        {
            title: 'Users',
            value: 1,
            router: '/identity/user',
        },
        {
            title: 'Role',
            value: 2,
            router: '/identity/role',
        },
        {
            title: 'Role & Permission',
            value: 3,
            router: '/identity/rolePermission',
        },
        {
            title: 'Security',
            value: 4,
            router: '/identity/security',
        }
       ]
        }
    },
    created() {
        //this.$router.push('/identity/user')
        // let $lastRoute = localStorage.getItem("identityList")
        // if ($lastRoute) {
        //     this.$router.push($lastRoute)
        // } else {
        //     this.$router.push('/identity/user')
        // }
    },
    methods:{
    }
}

</script>