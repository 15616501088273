<template>
  <div class="contendbg">
  <v-container style="text-align: center;">
      <v-card class="mx-auto pa-3" max-width="600" v-if="showRedirect">
          <v-img style="margin: 0 auto;" src="@/assets/Tiburaxtend.png" width="100px"></v-img>
          <v-card-subtitle class="text-center mt-6">Redirecting to login page ...</v-card-subtitle>
      </v-card>
      <v-card class="mx-auto pa-3" max-width="900" v-if="!showRedirect">
          <v-img style="margin: 0 auto;" src="@/assets/Tiburaxtend.png" width="100px"></v-img>
          <v-col align="center" class="mt-1" v-if="!denyLogin && !showDesk"> 
              <!-- <Circle4  class="#36D7B7" ></Circle4> -->
              <Loader />
          </v-col>
          <v-card-title v-if="denyLogin">You don't have access to the portal. Please contact your
            administrator</v-card-title>
      <ChooseDesk v-if="showDesk" :multipleCompany="multipleCompany" @redirectToApp="setCompany($event)"/>
      </v-card>

  </v-container>
</div>
</template>
<script>
import FirebaseModel from '@/api/service/FirebaseService';
import SwitchAccountService from '@/api/service/SwitchAccountService';
import { v4 as uuid } from 'uuid';
// import { Circle4 } from "vue-loading-spinner";
import Loader from './Loader.vue';
import ChooseDesk from "@/components/ChooseDesk.vue"

export default {
  data(){
      return{
          multipleCompany: [],
          showRedirect:true,
          showCard:false,
          showDesk:false,
          denyLogin:false,
          host: window.location.host,
          parts: window.location.host.split(".")
      }
  },
  components:{
      // Circle4,
      ChooseDesk,
      Loader
  },
  mounted: async function () {
      if (this.$route.query.token) {
          this.authenticateToken();
      } else if (this.$route.path == "/switchAccount") {
          this.switchAccount()
      } else {
          setTimeout(()=>{
              this.redirectToSso();
          },2000)
      }
  },
  methods:{
      async authenticateToken() {
          this.showRedirect=false
          let $response = await FirebaseModel.firebaseAuth({
              token: this.$route.query.token,
          });
          this.profile = $response.data.profile;
          this.showCard=true
          if ($response.data.desks.length == 1 && ($response.data.desks[0].err ? false : true)) {
              this.redirectToApp(
              $response.data.desks[0].team,
              $response.data.desks[0].token
              );
          }
          else if ($response.data.err) {
              this.denyLogin=true
              this.showErr = $response.data.desks[0].err
              this.denyLogin = false;
          }
          else if ($response.data.desks.length > 1) {
              this.showDesk=true
              this.multipleCompany = $response.data.desks;
              this.denyLogin = false;
          } else {
              this.multipleCompany = [];
              this.denyLogin = true;
          }
      },
      async switchAccount() {
          this.multipleCompany = await SwitchAccountService.findAll({})
      },
      async redirectToSso() {
          const authlog = {
              sessionId: uuid(),
              redirectUrl: window.location.href,
              loginSucess: 0
          }
          await FirebaseModel.sendToAuthLog(authlog)
          const userData = {
              appId: process.env.VUE_APP_ID,
              sessionId: authlog.sessionId,
              redirectUrl: authlog.redirectUrl
          };
          const queryParams = Object.keys(userData).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(userData[key])}`).join("&");
          window.location = `${process.env.VUE_APP_ONEAPP_DOMAIN}signIn?` + queryParams;
      },
      redirectToApp($team, $token) {
          localStorage.setItem('XTE_TOKEN_TMP', this.$route.query.token)
          localStorage.setItem('companyName',$team)
          this.$router.push("/autoLogin?loginToken=" + $token)
      },
      setCompany($company) {
          console.log($company,"working22222")
          this.redirectToApp($company.team, $company.token);
      },
  }
}
</script>
