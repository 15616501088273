<template>
  <AvatarGroup>
    <Avatar v-for="(item, index) in avatarData" :key="index" :size="item.active ? 'small' : 'small'" shape="circle"
      @click="selectItem(item)" style="cursor:pointer;border: 0px;font-size:12px"
      :style="{ 'background-color': getRandomColor(index) }" :class="item.select ? 'select' : ''">

      {{ item.code.slice(0, 2) }}
      <v-tooltip activator="parent" location="bottom">{{
        item.name
      }}</v-tooltip>
    </Avatar>
    <Avatar id="moreAvatars" style="border: 0;font-size:70%" :size="show ? 'large' : 'small'" shape="circle"
      v-if="moreItems.length > 0">
      +{{ moreItems.length }}
      <v-tooltip activator="parent" location="bottom">+ {{
        moreItems.length
      }}</v-tooltip>
    </Avatar>
  </AvatarGroup>
  <v-menu activator="#moreAvatars">
    <v-list>
      <v-list-item style="cursor:pointer" v-for="(item, index) in moreItems" :key="index">
        <v-list-item-title class="work-space-menu" @click="selectItem(item)">{{ item.name }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
import Avatar from "primevue/avatar";
import AvatarGroup from "primevue/avatargroup";

export default {
  props: ["avatarData", "moreItems"],
  components: {
    Avatar,
    AvatarGroup
  },
  data() {
    return {
      avatarName: ""
    }
  },
  watch: {
    avatarData() {
      this.getCurrentAC('avatar');
    }
  },
  async created() {
    this.getCurrentAC('avatar')
  },
  methods: {
    getCurrentAC(type) {
      this.currentACId = localStorage.getItem('_ACCOUNT_ID_')
      let removeAccount = false
      if (type == 'avatar') {
        if (this.currentACId && this.avatarData.length > 0) {
          this.avatarData.forEach((k, index) => {
            if (k.id === parseInt(this.currentACId)) {
              removeAccount = true
              k.select = true
            }
            else {
              k.select = false
            }
            if (index == this.avatarData.length - 1) {
              if (this.moreItems.length > 0 && !removeAccount) {
                this.getCurrentAC('menu')
              }
              else {
                if (!removeAccount) {
                  localStorage.removeItem("_ACCOUNT_ID_");
                  this.$router.push('/accounts')
                }
              }
            }
          })

        }
      }
      else {
        if (this.currentACId && this.moreItems.length > 0) {
          this.moreItems.forEach((k, index) => {
            if (k.id === parseInt(this.currentACId)) {
              removeAccount = true
              k.select = true
            }
            else {
              k.select = false
            }
            if (index == this.moreItems.length - 1) {
              if (!removeAccount) {
                localStorage.removeItem("_ACCOUNT_ID_");
                this.$router.push('/accounts')
              }
            }
          })

        }
      }
    },
    selectItem($item) {
      this.$emit('selected', $item)
      this.getCurrentAC('avatar')

    },
    getRandomColor(index) {
      const availableColors = ["#C7D4E7", "#E7CED1", "#FDEFCA", "#F5D4DF"];
      if (index >= 0 && index < availableColors.length) {
        return availableColors[index];
      }
      return "#000000";
    },
  }
}
</script>
<style scoped>
.p-avatar.p-avatar-circle:hover {
  cursor: pointer;
  width: 3rem;
  height: 3rem;
  z-index: 9999;
}

.p-avatar.p-avatar-circle:active {
  cursor: pointer;
  width: 3rem;
  height: 3rem;
  z-index: 9999;
}

>>>.p-avatar-group .p-avatar:hover {
  /* border: 2px solid #ffffff; */
  cursor: pointer;
  width: 3rem;
  height: 3rem;
  z-index: 9999;
}

.select {
  width: 3rem;
  height: 3rem
}
</style>