<template>
    <v-dialog v-model="showDialog" persistent width="35%">
      <v-card justify="center" style="border-radius: 12px; overflow: hidden">
        <v-card-title> Confirmation </v-card-title>
        <v-divider></v-divider>
        <v-card-content class="pa-4 text-center" >  {{ content }}  </v-card-content>
        <!-- <v-divider></v-divider> -->
        <v-card-actions class="pl-3 mb-2 dialogfooter">
          <v-row justify="center">
            <v-btn style="width: auto !important;" @click="$emit('cancelled')"
              >Cancel</v-btn
            >
            <v-btn class="save" style="width: auto !important;" @click="$emit('deleted')"
              >{{ type == 'inactive' ? "Inactive" : "Delete" }}</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default {
    props:["content","type"],
    data() {
      return {
        showDialog: true,
      };
    },
    methods: {
      
    },
  };
  </script>
  <style>
  </style>