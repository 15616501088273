<template>
  <v-card
    flat
    class="rounded-lg"
    style="border: 2px solid; border-color: #cfcdcd"
  >
    <v-card-title> Pricing </v-card-title>
    <v-card-text>
      <v-form ref="form">
        <v-row>
          <v-col cols="6" class="switch">
            <v-switch
              color="#5E29F5"
              v-model="formData.pricingvalue"
              hide-details
              inset
              :label="'Pricing Differs by Region'"
              @update:modelValue="pricing()"
            />
          </v-col>
          <v-col cols="6" class="switch">
            <v-switch
              color="#5E29F5"
              v-model="formData.taxableProduct"
              hide-details
              inset
              :label="'Taxable Product'"
              @update:modelValue="pricing()"
            />
          </v-col>
          <v-col cols="6" class="pa-0 pl-2"
            ><InputBox
              :data="formData.sale_price"
              @textData="pricing($event)"
              :label="'Sale Price'"
              type="number"
              :rules="priceRule"
              @keydowninput="prevent"
          /></v-col>
          <v-col cols="6" class="pa-0 px-2"
            ><InputBox
              :data="formData.compare_price"
              @textData="pricing1($event)"
              :label="'Compare Price'"
              v-model="formData.compare_price"
              @keydowninput="prevent"
          /></v-col>
          <v-col cols="6" class="pa-0 pl-2"
            ><InputBox
              :data="formData.cost"
              @textData="pricing2($event)"
              @keydowninput="prevent"
              :label="'Cost'"
              :rules="costRule"
          /></v-col>
          <v-col cols="6" class="pa-0 px-2"
            ><InputBox
              :data="formData.gross_margin"
              @textData="pricing3($event)"
              @keydowninput="prevent"
              :label="'Gross Margin'"
          /></v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import InputBox from "@/components/InputBox.vue";
export default {
  props: ["validation", "productData"],
  components: {
    InputBox,
  },
  data() {
    return {
      priceRule: [(value) => !!value || "Sale Price is Required"],
      costRule: [(value) => !!value || "Cost is Required"],
      Taxable: false,
      formData: {
        pricingvalue: false,
        taxableProduct: false,
        sale_price: null,
        compare_price: null,
        cost: null,
        gross_margin: null,
      },
    };
  },
  watch: {
    validation() {
      this.save();
    },
    productData() {
      this.formData = this.productData;
    },
  },
  created() {
    if (this.productData) {
      this.formData = this.productData;
    }
  },
  methods: {
    prevent(e) {
      if (!/^[0-9-_ \t]+$/.test(e.key) && e.key !== "Backspace") {
        e.preventDefault();
      }
    },
    pricing(value) {
      this.formData.sale_price = value;
      this.$emit("pricingData", this.formData);
    },
    pricing1(value) {
      this.formData.compare_price = value;
      this.$emit("pricingData", this.formData);
    },
    pricing2(value) {
      this.formData.cost = value;
      this.$emit("pricingData", this.formData);
    },
    pricing3(value) {
      this.formData.gross_margin = value;
      this.$emit("pricingData", this.formData);
    },
    async save() {
      let validation = await this.$refs.form.validate();
      if (validation.valid) {
        this.$emit("validCheck", true);
      } else {
        this.$emit("validCheck", false);
      }
    },
  },
};
</script>

<style>
</style>