<template>
  <PrimeTable
    :tableName="'Enums'"
    activity="Enum"
    :showTableHeader="true"
    :headers="column"
    :tableData="data"
    @rowAction="rowAction"
    @addDialog="addDialog"
    :modelName="'enums'"
    @filterData="applyFilter($event)"
  ></PrimeTable>
  <v-dialog  v-model="dialog" width="600px" persistent>
    <AddEnum @closeDialog="closeDialog" :type="type" :id="enumId" :showReadonly="showReadonly" />
  </v-dialog>
  <v-dialog v-model="confirmation" width="400px" persistent>
    <DialogConfirmation @cancelled="cancelled()" @deleted="deleted" :content="confirmationContent" />
  </v-dialog>
</template>

<script>
import EnumService from '@/services/admin/EnumService'
import AddEnum from './AddEnum.vue';
import DialogConfirmation from '@/components/DialogConfirmation.vue';
export default {
    components:{
        AddEnum,
        DialogConfirmation,
    },
data(){
    return{
        dialog: false,
        confirmation:false,
      data: [],
      column: [
      { title: "Domain", align: "start", key: "domain" },
        { title: "Display Value", align: "start", key: "display_value" },
        { title: "Data Value", align: "start", key: "data_value" },
        { title: "Modified By", align: "start", key: "users" },
        { title: "Modified On", align: "start", key: "modified" },
        { title: "Status", key: "active", sortable: false },
        { title: "Action", key: "actions", sortable: false },
      ],
      active: [
        { id: 1, name: "Active" },
        { id: 0, name: "Inactive" },
      ],
    }
},
created() {
  let $value = {};
  this.getEnum($value)
},
methods:{
  applyFilter($value){
    this.getEnum($value)
  },
      async getEnum($value){
          await EnumService.findAll($value).then((response) => {
          console.log(response,'res')
          this.data = response
          this.data.map((obj) => {
            return (obj.active = this.active.find(
              (type) => type.id == obj.active
            ).name);
          });
          })
    },
    rowAction($event) {
      if ($event.type == "edit") {
        this.dialog = true;
        this.type = $event.type;
        this.enumId = $event.value.id;
        this.showReadonly = false
      }
      if ($event.type == "view") {
        this.dialog = true;
        this.type = $event.type;
        this.enumId = $event.value.id;
        this.showReadonly = true
      }
      if ($event.type == "delete") {
        this.dialog=false;
        this.confirmation = true;
        this.confirmationContent = `Do you want to delete the Enum ${$event.value.domain} ?`
        this.editDataId = $event.value.id;
      }
      if ($event.type == "select") {
        this.dialog = true;
        this.type = $event.type;
        this.enumId = $event.value.id;
        this.showReadonly = true
      }
    },
    addDialog(){
        this.dialog = true 
        this.type = 'add'
        this.showReadonly = false
    },
    closeDialog(){
        this.dialog = false
        this.getEnum()
    },
    async deleted(){
      this.confirmation = false;
       await EnumService.delete({id:this.editDataId});
      this.getEnum();
    },
    cancelled(){
      this.editDataId = null;
      this.confirmation = false;
    }
}
}
</script>

<style>

</style>