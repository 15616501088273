<template>
  <div>
    <v-row no-gutters dense class="publicmap">
      <v-col xl="12" lg="12" md="12" sm="12" cols="12">
        <div class="txt">
          <!-- <span>Trusted by businesses across</span><br /> -->
          <span class="txt1"> Our Clients</span>
        </div>
      </v-col>
        <v-sheet v-if="$mq=='mobile'"
    class="mx-auto "
    max-width="700"
    margin-bottom="20px !important"
  >
   <v-carousel v-model="model" :show-arrows="false" cycle hide-delimiters height="150">
      <v-carousel-item
        v-for="card in cards"
        :key="card"
        
      >
        <v-card style="background: #FFFFFF" class="cards">
          
          <v-card-text 
            >
            <v-img
            :style="{'width':card.width ? card.width : '','margin-top':card.margin_top ? card.margin_top : ''}"   
            :src=card.url 
              
            >
            </v-img>
          </v-card-text>
            </v-card>
      </v-carousel-item>
    </v-carousel>
  </v-sheet>
  <div v-if="$mq!='mobile'">
      <v-col cols="12" class="pa-5 pt-6">
        <v-row no-gutters style="width:70%;margin:auto;">
          <v-col cols="12" lg=3  sm=4 md=3 xl=2 xs=6>
         <v-card class="pa-1 pt-4 cards">
            
            <v-img
              style="width: 140px;  margin-top: 30px" 
              src=" https://s3.ap-south-1.amazonaws.com/tibura.com/assets/clients/granule.jpg"
            >
            </v-img>
            
          </v-card>
          </v-col>
    
          <!-- <v-col cols="12" lg=3  sm=4 md=3 xl=2 xs=6>
            <v-card  class="pa-1 cards">
              
            <v-img
              style="width: 140px;  margin-top: 30px"
              src=" https://s3.ap-south-1.amazonaws.com/tibura.com/assets/clients/metro.png"
            >
            </v-img>
               
            </v-card>
          </v-col>
          <v-col cols="12" lg=3  sm=4 md=3 xl=2 xs=6>
            <v-card class="pa-1 cards">
            <v-img
              style="width: 140px;  margin-top: 30px"
              src=" https://s3.ap-south-1.amazonaws.com/tibura.com/assets/clients/hitachi.png"
            >
            </v-img>
            </v-card>
          </v-col>
          <v-col cols="12" lg=3  sm=4 md=3 xl=2 xs=6>
            <v-card   class="pa-2 pt-6 cards">
            <v-img
              style="width: 130px;margin-top: 30px"
              src=" https://s3.ap-south-1.amazonaws.com/tibura.com/assets/clients/minor.png"
            >
            </v-img>
            </v-card>
          </v-col> -->
          <v-col cols="12" lg=3  sm=4 md=3 xl=2 xs=6>
            <v-card   class="pa-1 pt-6 cards">
            <v-img
              style="width: 190px;margin-top: 30px"
              src=" https://s3.ap-south-1.amazonaws.com/tibura.com/assets/clients/linenya.png"
            >
            </v-img>
            </v-card>
          </v-col>
          <v-col cols="12" lg=3  sm=4 md=3 xl=2 xs=6>
            <v-card   class=" pt-9   cards">
            <v-img
              style="width: 130px;margin-top: 30px;margin-left:10px" 
              src="https://s3.ap-south-1.amazonaws.com/tibura.com/assets/clients/FF.png"
            >
            </v-img>
            </v-card>
          </v-col>
          <v-col cols="12" lg=3  sm=4 md=3 xl=2 xs=6>
            <v-card   class="pa-1 pt-6 pl-2 cards">
            <v-img
              style="width: 130px;margin-top: 30px"
              src=" https://s3.ap-south-1.amazonaws.com/tibura.com/assets/clients/groundup.png"
            >
            </v-img>
            </v-card>
          </v-col>
          <v-col cols="12" lg=3  sm=4 md=3 xl=2 xs=6>
            <v-card   class="pt-5 pl-2 cards">
            <v-img
              style="width: 130px;margin-top: 30px"
              src=" https://s3.ap-south-1.amazonaws.com/tibura.com/assets/clients/wb.png"
            >
            </v-img>
            </v-card>
          </v-col>
          <!-- <v-col cols="12" lg=3  sm=4 md=3 xl=2 xs=6>
            <v-card   class="pt-1 pl-2 cards">
            <v-img
              style="width: 140px;margin-top: 35px"
              src=" https://s3.ap-south-1.amazonaws.com/tibura.com/assets/clients/deliotte.png"
            >
            </v-img>
            </v-card>
          </v-col> -->
          <v-col cols="12" lg=3  sm=4 md=3 xl=2 xs=6>
            <v-card   class="pt-7 pl-2  cards">
            <v-img
              style="width: 130px;margin-top: 35px"
              src="https://s3.ap-south-1.amazonaws.com/tibura.com/assets/clients/dn+1.png"
            >
            </v-img>
            </v-card>
          </v-col>
          <v-col cols="12" lg=3  sm=4 md=3 xl=2 xs=6>
            <v-card class="pt-6 pl-3 cards">
            <v-img
              style="width: 130px;margin-top: 35px"
              src=" https://s3.ap-south-1.amazonaws.com/tibura.com/assets/clients/blk.png"
            >
            </v-img>
            </v-card>
          </v-col>
          <v-col cols="12" lg=3  sm=4 md=3 xl=2 xs=6>
            <v-card class="pt-8 pl-2 cards">
            <v-img
              style="margin-top: 30px;width:100%"
              src=" https://s3.ap-south-1.amazonaws.com/tibura.com/assets/clients/baon.png"
            >
            </v-img>
            </v-card>
          </v-col>
          <!-- <v-col cols="12" lg=3  sm=4 md=3 xl=2 xs=6>
            <v-card class="pt-3 pl-3 cards">
            <v-img
               style="width: 130px;  margin-top: 35px"
              src=" https://s3.ap-south-1.amazonaws.com/tibura.com/assets/clients/airboss.png"
            >
            </v-img>
            </v-card>
          </v-col> -->
          <v-col cols="12" lg=3  sm=4 md=3 xl=2 xs=6>
            <v-card class="pa-1 pl-6 cards">
            <v-img
              style="width: 100px;  margin-top: 25px"
              src=" https://s3.ap-south-1.amazonaws.com/tibura.com/assets/clients/ful.png"
            >
            </v-img>
            </v-card>
          </v-col>
          <v-col cols="12" lg=3  sm=4 md=3 xl=2 xs=6>
            <v-card class="pa-1 cards">
            <v-img
              style="width: 130px;  margin-top: 30px"
              src=" https://s3.ap-south-1.amazonaws.com/tibura.com/assets/clients/cn1.png"
            >
            </v-img>
            </v-card>
          </v-col>
          <!-- <v-col cols="12" lg=3  sm=4 md=3 xl=2 xs=6>
            <v-card   class="pa-1 pl-4 cards">
            <v-img
              style="width: 110px;  margin-top: 15px"
              src=" https://s3.ap-south-1.amazonaws.com/tibura.com/assets/clients/jtc.png"
            >
            </v-img>
            </v-card>
          </v-col>
          <v-col cols="12" lg=3  sm=4 md=3 xl=2 xs=6>
            <v-card   class="pt-1 pl-5 cards">
            <v-img
              style="width: 100px;  margin-top: 20px"
              src=" https://s3.ap-south-1.amazonaws.com/tibura.com/assets/clients/sats.png"
            >
            </v-img>
            </v-card>
          </v-col>
          <v-col cols="12" lg=3  sm=4 md=3 xl=2 xs=6>
            <v-card   class="py-1 pa-1 pl-5 cards">
            <v-img
              style="width: 110px;  margin-top: 15px"
              src=" https://s3.ap-south-1.amazonaws.com/tibura.com/assets/clients/mcmc.png"
            >
            </v-img>
            </v-card>
          </v-col> -->
          <!-- <v-col cols="12" lg=3  sm=4 md=3 xl=2 xs=6>
            <v-card  class="pl-2 cards">
            <v-img
              style="width: 130px;"
              src=" https://s3.ap-south-1.amazonaws.com/tibura.com/assets/clients/straits.png"
            >
            </v-img>
            </v-card> 
           </v-col> -->
          <v-col cols="12" lg=3  sm=4 md=3 xl=2 xs=6>
            <v-card  class="pt-1 pl-2 cards">
            <v-img
              style="width: 130px;  margin-top: 25px"
              src=" https://s3.ap-south-1.amazonaws.com/tibura.com/assets/clients/flairhair.png"
            >
            </v-img>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      </div>
      <!-- <v-col xl="12" lg="12" md="12" sm="12" cols="12">
              <div style="padding-top:20px">
            <img src="images/publicmap.png" style="width:100%" />
          </div>
          </v-col> -->
    </v-row>
  </div>
</template>
<style scoped>
.publicmap {
  justify-content: center;
  text-align: center;
}
.img {
  width: 192px;
  height: 75px;
  margin-top: 30px;
}
.publicmap .txt {
  font-family: Quicksand !important;
  font-size: 40px;
  line-height: 70px;
  text-align: center;
  color: #000000;
}
.publicmap .txt1 {
  font-weight: bold;
  font-size: 50px;
}
.cards{
  width:150px;
  height:150px;
  margin-top:20px;
  margin:auto;
  box-shadow: none !important;
  margin-bottom:20px;
  border: 1px solid #ADADAD;
}
@media (max-width: 480px) {
  .publicmap .txt {
    font-family: Quicksand !important;
    font-size: 25px;
    line-height: 38px;
    text-align: center;
    color: #000000;
  }
  .publicmap .txt1 {
    font-weight: bold;
    font-size: 28px;
  }
  .solutionImages {
    zoom: 25%;
  }
}
@media only screen and (min-width: 480px) and (max-width: 600px) {
  .publicmap .txt {
    font-family: Quicksand !important;
    font-size: 28px;
    line-height: 40px;
    text-align: center;
    color: #000000;
  }
  .publicmap .txt1 {
    font-weight: bold;
    font-size: 32px;
  }
  .solutionImages {
    zoom: 35%;
  }
}

@media only screen and (min-width: 600px) and (max-width: 900px) {
  .publicmap .txt {
    font-family: Quicksand !important;
    font-size: 33px;
    line-height: 42px;
    text-align: center;
    color: #000000;
  }
  .publicmap .txt1 {
    font-weight: bold;
    font-size: 38px;
  }
  .solutionImages {
    zoom: 45%;
  }
}
@media only screen and (min-width: 900px) and (max-width: 1200px) {
  .solutionImages {
    zoom: 55%;
  }
}
</style>
<script>
 export default {
    data: () => ({
      model: 0,
      cards: [
        {url:" https://s3.ap-south-1.amazonaws.com/tibura.com/assets/clients/granule.jpg",width:"130px",margin_top:"35px !important"},
        {url:" https://s3.ap-south-1.amazonaws.com/tibura.com/assets/clients/linenya.png",width:"130px",margin_top:"40px !important"},
        {url:" https://s3.ap-south-1.amazonaws.com/tibura.com/assets/clients/FF.png",width:"130px",margin_top:"40px !important"},
        {url:" https://s3.ap-south-1.amazonaws.com/tibura.com/assets/clients/groundup.png",width:"130px",margin_top:"40px !important"},
        {url:" https://s3.ap-south-1.amazonaws.com/tibura.com/assets/clients/wb.png",width:"130px",margin_top:"40px !important"},
        {url:" https://s3.ap-south-1.amazonaws.com/tibura.com/assets/clients/dn+1.png",width:"130px",margin_top:"40px !important"},
        {url:" https://s3.ap-south-1.amazonaws.com/tibura.com/assets/clients/blk.png",width:"130px",margin_top:"40px !important"},
        {url:" https://s3.ap-south-1.amazonaws.com/tibura.com/assets/clients/baon.png",width:"130px",margin_top:"40px !important"},
        {url:" https://s3.ap-south-1.amazonaws.com/tibura.com/assets/clients/ful.png",width:"130px",margin_top:"0px !important"},
        {url:" https://s3.ap-south-1.amazonaws.com/tibura.com/assets/clients/cn1.png",width:"130px",margin_top:"30px !important"},
        // {url:" https://s3.ap-south-1.amazonaws.com/tibura.com/assets/clients/sats.png",width:"130px","margin-top":"0px"},
        {url:" https://s3.ap-south-1.amazonaws.com/tibura.com/assets/clients/flairhair.png",width:"130px",margin_top:"20px !important"},
      ],
    }),
  }
  </script>