<template>
    <v-card flat class="rounded-lg" style="border: 2px solid; border-color: #cfcdcd">
      <v-card-title>Add Image</v-card-title>
      <v-card-text class="text-center">
          <ImageCard :avatar="'true'" :documentId="this.image_profile_id" :borderRadius="'0'" :height="'200px'" :width="'600px'" @imageChanged="addedData($event)"></ImageCard>
      </v-card-text>
    </v-card>
  </template>
  
  <script>
import ImageCard from '../../components/ImageCard.vue';
  export default {
    components: {
        ImageCard,
    },
    data() {
      return {
        data: {},
        image_profile_id : null,
      };
    },
    methods: {
        addedData($event){
            this.image_profile_id = $event.id
            let imageChanged = $event.id
            this.$emit('imageChanged',imageChanged)
            console.log("8888888",imageChanged)
            
        },
        // updateImage($event){
        //  this.image_profile_id = $event.id
        // }
    }
  };
  </script>