<template>
  <div>
    <v-row class="solution" no-gutters dense> Solution </v-row>
    <v-row class="txt" no-gutters dense>
      <v-col xl="12" lg="12" md="12" sm="10" cols="8">
        Keep up with the demand and expand your e-commerce operations without
        investing more on the <br />
        resources. - XTEND seamlessly integrates with ERPs of your choice.
      </v-col>
      <v-col xl="12" lg="12" md="12" sm="8" cols="8" class="paddingtxt1">
        <span class="txt1"> Don’t be left behind,</span><br />
        <span class="txt2">
          Re-define your digital journey with Tibura -Xtend</span
        >
      </v-col>
    </v-row>
    <v-row class="justify-center pa-2 pt-5 solutionicons">
      <v-col xl="2" lg="2" md="2" sm="2" cols="2"> <IconAmazon /></v-col>
      <v-col xl="2" lg="2" md="2" sm="2" cols="2"><IconShopify /></v-col>
      <v-col xl="2" lg="2" md="2" sm="2" cols="2"> <IconLazada /></v-col>
      <v-col xl="2" lg="2" md="2" sm="2" cols="2"> <IconMagento /></v-col>
      <v-col xl="2" lg="2" md="2" sm="2" cols="2"> <IconFarfetch /></v-col>
      <!-- <v-col xl="2" lg="2" md="2" sm="2" cols="2"> <IconEtsy /></v-col> -->
    </v-row>
    <v-row class="justify-center pa-2 pt-5 images" style="padding-left: 3% !important;
    padding-right: 5% !important;">
      <v-col>
        <v-img
          style="
            width: 125px;
            height: 60px;
            margin-top: 40px;
            margin-left: 90px;
          "
          src="https://s3.ap-south-1.amazonaws.com/tibura.com/assets/etsy.png"
        >
        </v-img>
      </v-col>
      <v-col>
        <v-img
          style="
            width: 200px;
            height: 90px;
            margin-left: 40px;
            margin-top: 30px;
          "
          src="https://1000logos.net/wp-content/uploads/2021/04/AliExpress-logo.png"
        >
        </v-img>
      </v-col>
      <v-col>
        <v-img
          style="
            width: 85px;
            height: 125px;
            margin-top: 10px;
            margin-left: 50px;
          "
          src="https://seeklogo.com/images/S/shopee-logo-065D1ADCB9-seeklogo.com.png"
        >
        </v-img>
      </v-col>
      <v-col>
        <v-img
          style="width: 160px; height: 70px; margin-top: 30px"
          src="https://purepng.com/public/uploads/large/purepng.com-ebay-logologobrand-logoiconslogos-251519938326w1a5t.png"
        >
        </v-img>
      </v-col>
      <v-col>
        <v-img
          style="width: 300px; height: 70px; margin-top: 30px"
          src="https://s3.ap-south-1.amazonaws.com/tibura.com/assets/walmart.jpg"
        >
        </v-img>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import IconAmazon from "../Icons/IconAmazon.vue";
import IconShopify from "../Icons/IconShopify.vue";
import IconLazada from "../Icons/IconLazada.vue";
import IconMagento from "../Icons/IconMagento.vue";
import IconFarfetch from "../Icons/IconFarfetch.vue";
// import IconEtsy from "../Icons/IconEtsy.vue";
export default {
  components: {
    IconAmazon,
    IconShopify,
    IconLazada,
    IconMagento,
    IconFarfetch,
  },
};
</script>
<style scoped>
.solution {
  font-family: "Nunito" !important;
  font-weight: 800;
  font-size: 48px;
  letter-spacing: 1px;
  line-height: 34px;
  padding-top: 70px;
  color: #000000;
  justify-content: center;
}
.txt {
  font-family: "Nunito" !important;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  justify-content: center !important;
  text-align: center;
  padding-top: 40px;
}
.txt .paddingtxt1 {
  padding-top: 60px;
}
.txt .txt1 {
  color: #000;
  font-size: 18px;
  font-weight: 500;
}
.txt .txt2 {
  color: #000;
  font-size: 42px;
  font-weight: 800;
  line-height: 75px;
}
.solutionicons {
  padding-top: 80px !important;
  /* padding-bottom: 80px !important; */
}

@media (max-width: 480px) {
  .solution {
    font-family: "Nunito" !important;
    font-weight: 500;
    font-size: 35px;
    line-height: 34px;
    padding-top: 30px;
    color: #000000;
    justify-content: center;
  }
  .txt {
    font-family: "Nunito" !important;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    justify-content: center !important;
    text-align: center;
    padding-top: 6px;
  }
  .txt .paddingtxt1 {
    padding-top: 25px;
  }
  .txt .txt1 {
    line-height: 30px;
    font-size: 18px;
    font-weight: 600;
  }
  .txt .txt2 {
    font-size: 23px;
    font-weight: 600;
    line-height: 25px;
  }
  .solutionicons {
    padding-top: 60px !important;
    /* padding-bottom: 60px !important; */
    zoom: 25%;
  }
  .images {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
    zoom: 25%;
  }
}
@media only screen and (min-width: 480px) and (max-width: 600px) {
  .solution {
    font-family: "Nunito" !important;
    font-weight: 500;
    font-size: 35px;
    line-height: 34px;
    padding-top: 30px;
    color: #000000;
    justify-content: center;
  }
  .txt {
    font-family: "Nunito" !important;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    justify-content: center !important;
    text-align: center;
    padding-top: 6px;
  }
  .txt .paddingtxt1 {
    padding-top: 25px;
  }
  .txt .txt1 {
    line-height: 30px;
    font-size: 18px;
    font-weight: 600;
  }
  .txt .txt2 {
    font-size: 23px;
    font-weight: 600;
    line-height: 25px;
  }
  .solutionicons {
    padding-top: 60px !important;
    /* padding-bottom: 60px !important; */
    zoom: 35%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 900px) {
  .solution {
    font-family: "Nunito" !important;
    font-weight: 500;
    font-size: 45px;
    line-height: 34px;
    padding-top: 30px;
    color: #000000;
    justify-content: center;
  }
  .txt {
    font-family: "Nunito" !important;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    justify-content: center !important;
    text-align: center;
    padding-top: 15px;
  }
  .txt .paddingtxt1 {
    padding-top: 25px;
  }
  .txt .txt1 {
    line-height: 30px;
    font-size: 18px;
    font-weight: 600;
  }
  .txt .txt2 {
    font-size: 23px;
    font-weight: 600;
    line-height: 25px;
  }
  .solutionicons {
    padding-top: 80px !important;
    /* padding-bottom: 60px !important; */
    zoom: 45%;
  }
  .images {
    padding-top: 80px !important;
    padding-bottom: 60px !important;
    zoom: 45%;
  }
}
@media only screen and (min-width: 900px) and (max-width: 1200px) {
  .solution {
    font-family: "Nunito" !important;
    font-weight: 500;
    font-size: 50px;
    line-height: 34px;
    padding-top: 33px;
    color: #000000;
    justify-content: center;
  }
  .txt {
    font-family: "Nunito" !important;
    font-size: 18px;
    line-height: 23px;
    color: #000000;
    justify-content: center !important;
    text-align: center;
    padding-top: 15px;
  }
  .txt .paddingtxt1 {
    padding-top: 25px;
  }
  .txt .txt1 {
    line-height: 33px;
    font-size: 22px;
    font-weight: 600;
  }
  .txt .txt2 {
    font-size: 28px;
    font-weight: 600;
    line-height: 30px;
  }
  .solutionicons {
    padding-top: 80px !important;
    /* padding-bottom: 60px !important; */
    zoom: 55%;
  }
   .images {
    padding-top: 80px !important;
    padding-bottom: 60px !important;
    zoom: 55%;
  }
}
</style>