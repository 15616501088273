<template>
    <v-card elevation="0" class="border">
        <v-card-title class="dialog-Title">
            FILTERS
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-6">
          <v-row v-for="item in formData" :key="item">
                <v-col class="pa-1"  cols="11" sm="7" xs="3" md="11" lg="11" xl="11" xxl="20">
                   <span ><i class="pi pi-times pr-3 pl-2" style="font-size: 10px;cursor: pointer;" @click="removeItem(item)"></i></span>
                   <span style="height: 30px;"> {{ item.column_name }} </span><span v-if="item.type == 'select'"><i class="v-icon pink--text fas fa-angle-double-down theme--light pl-2" ></i></span>
                </v-col>
                <v-col class="pa-1" cols="1" sm="1" xs="1" md="1" lg="1" xl="1" xxl="4">
                  <v-icon @click="editDialog(item)" size="18px" style="float: right">mdi-pencil</v-icon>
                </v-col>
          </v-row>
        </v-card-text>
    </v-card>
    <v-dialog v-model="lookupDialog" persistent width="850px">
        <LookUp @closeDialog="closeDialog()" :editId="this.editDataId" @reload="this.getFilterData()" :type="this.type"></LookUp>
    </v-dialog>
</template>
<script>
import ListModelDetailService from '@/services/admin/ListModelDetailService';
import LookUp from './LookUp.vue'; 
import ListModelFilterDetailService from '@/services/admin/ListModelFilterDetailService';
export default {
  props:['filterData','filterId','modelId'],
  components:{
    LookUp,
  },
  data() {
    return {  
      lookupDialog : false,
      formData:[],
      dropdownItems: [],
      selectedItems: [],
      model_id: null,
      savedData:{},
      filter_id: null,
      editDataId: null,
      showReadOnly: false,
      type: null
    };
  },
  created(){
      this.model_id = this.filterData ? this.filterData.model_id : '';
      this.filter_id = this.filterId;
      this.getFilterData();
      if (this.$route.query.type === 'view'){
      this.showReadOnly = true
      this.type = 'view'
      }
  },
  watch:{
    filterData(){
      this.filter_id = this.filterId;
      this.asignValues();
    },
    filterId(){
      this.filter_id = this.filterId;
      this.getFilterData();
    },
    modelId(){
      this.model_id = this.modelId
    }
  },
  methods: {
    async asignValues(){
     if (this.filterId){
        this.savedData.column_name = this.filterData.column_name;
        this.savedData.model_filter_id = this.filterId
        let avoidDuplicate = await ListModelFilterDetailService.findAll({model_filter_id: this.filterId, column_name: this.savedData.column_name,deleted: 0})
        if (avoidDuplicate.length == 0){
        await ListModelFilterDetailService.create(this.savedData)
        this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
        await this.getFilterData();
        // alert("111111")
      }else{
        this.$store.dispatch("setSnackbar", { text: "Already Added this Filter" });
      }
     }
    },
    async getFilterData(){
      // alert("222222")
       if (this.filter_id){
        let res = await ListModelFilterDetailService.findAll({model_filter_id: this.filterId})
        console.log("resssss",res)
        this.formData = res;
       }
    },
    editDialog($event){
      this.lookupDialog = true;
      this.editDataId = $event.id;
    },
    async removeItem(item) {
     if (!this.showReadOnly){
      console.log("removeeee",item)
      await ListModelFilterDetailService.save({id:item.id, deleted: 1})
     //getting column id in list model detail
     let res = await ListModelDetailService.findAll({model_id: this.model_id, column_name: item.column_name})
     //update the filter required column
     await ListModelDetailService.update({id: res[0].id, filter_required: 0})
     this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
     this.getFilterData();
     this.$emit("reloadColumnList", true);
     }
    },
    closeDialog(){
        this.lookupDialog = false;
    }
  },
};
</script>
<style scoped>
.border{
    border-style: solid;
    border-width: 1px;
    margin: 4px;
    border-radius: 6px;
    border-color: #0000001f;
    height: 500px;
}
.fa-angle-double-down{
    color: #e91e63;
}
</style>