<template>
  <PrimeTable :tableName="'User Role'" activity="User Role Securities" :showTableHeader="true" :headers="column" :tableData="data" @rowAction="rowAction"
    @addDialog="addDialog" :view="dissabled" :edit="dissabled"></PrimeTable>
  <v-dialog v-model="dialog" width="600px" persistent>
    <AddUserRoleSecuritiesVue @closeDialog="closeDialog" :type="type" :id="editDataId" :userId="this.id"/>
  </v-dialog>
  <v-dialog v-model="confirmation" width="300px" persistent>
    <ConfirmationDialog @cancelled="cancelled()" @deleted="deleted" :content="confirmationContent" />
  </v-dialog>
</template>

<script>
import UserRoleSecurityService from "@/services/admin/UserRoleSecurityService.js";
import ConfirmationDialog from "@/components/DialogConfirmation.vue";
import AddUserRoleSecuritiesVue from '../AddAdmin/AddUserRoleSecurities.vue';
export default {
  props: ['id','hide'],
  components: {
    AddUserRoleSecuritiesVue,
    ConfirmationDialog,
  },
  data() {
    return {
      userId: null,
      dialog: false,
      DivisionId: "",
      data: [],
      confirmationContent: null,
      confirmation: false,
      column: [
        { title: "Role", align: "start", key: "roles" },
        { title: "Created", align: "start", key: "created" },
        { title: "Modified by", align: "start", key: "users" },
        { title: "Modified on", align: "start", key: "modified" },
        { title: "Status", align: "start", key: "active" },
      ],
      active: [
        { id: 1, name: "Active" },
        { id: 0, name: "Inactive" },
      ],
    };
  },
  watch: {
    id() {
      if (this.id) {
        this.userId = this.id
        this.getUserRoleSecurities();
      }
    },
  },
  created() {        
    if (this.hide == 'edit'){
      this.column.push({ title: "Action", key: "actions", sortable: false })
        }
    if (this.id) {
        this.userId = this.id
        this.getUserRoleSecurities();
      }
  },
  methods: {
    async getUserRoleSecurities() {
      await UserRoleSecurityService.findAll({ user_id: this.userId })
        .then((response) => {
          this.data = response;
          this.data.map((obj) => {
            return (obj.active = this.active.find((type) => type.id == obj.active).name);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    rowAction(e) {
      if (e.type == "edit") {
        this.dialog = true;
        this.type = e.type;
        this.editDataId = e.value.id;
      }
      if (e.type == "view") {
        this.dialog = true;
        this.type = e.type;
        this.editDataId = e.value.id;
      }
      if (e.type == "delete") {
        this.dialog = false;
        this.confirmation = true;
        this.confirmationContent = `Do you want to delete the User Role ${e.value.roles.name} ?`;
        this.editDataId = e.value.id;
      }
      if (e.type == "select") {
        this.dialog = true;
        this.type = e.type;
        this.editDataId = e.value.id;
      }
    },

    addDialog() {
      if (this.hide === 'view'){
        this.dialog = false
      }else{
      this.dialog = true;
      this.type = "add";
      this.editDataId = null;
      }
    },
    closeDialog() {
      this.dialog = false;
      this.getUserRoleSecurities();
    },
    async deleted() {
      this.confirmation = false;
      await UserRoleSecurityService.delete({ id: this.editDataId });
      this.getUserRoleSecurities();
    },
    cancelled() {
      this.editDataId = null;
      this.confirmation = false;
    },
  },
};
</script>
<style scoped>
.v-table__wrapper {
    border-radius: inherit;
    overflow: auto;
    height: 450px;
}
</style>
