<template>
  <v-card>
    <v-form @Submit.prevent="save" ref="form">
      <v-card-title class="dialog-Title"
        >{{
          type == "edit"
            ? "Edit"
            : type == "view"
            ? "View"
            : type == "add"
            ? "Add"
            : type == "select"
            ? "View"
            : ""
        }}
        Product Type
        <v-btn
          density="comfortable"
          @click="closeDialog"
          elevation="0"
          icon="mdi-close"
          style="float: right"
        >
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="6" class="pa-0">
              <InputBox
                :data="formData.code"
                @textData="checkCode($event)"
                :label="'Code'"
                :disabled="showReadOnly"
                :rules="codeRules"
                @keydowninput="prevent"
              />
              <span v-if="codeError" class="error-field">Already Exists!</span>
            </v-col>
            <v-col cols="6" class="pa-0 pl-2">
              <InputBox
                :data="formData.erp_key"
                @textData="
                  ($event) => {
                    formData.erp_key = $event;
                  }
                "
                :label="'Erp Ref.No'"
                :disabled="showReadOnly"
                :rules="refRules"
              />
            </v-col>
            <v-col cols="12" class="pa-0">
              <InputBox
                :data="formData.name"
                @textData="
                  ($event) => {
                    formData.name = $event;
                  }
                "
                :label="'Name'"
                :disabled="showReadOnly"
                :rules="nameRules"
              />
            </v-col>
            <v-col cols="12" class="pa-0">
              <QuilEditor
                :data="formData.description"
                :editorStyle="'height:100px;overflow: auto'"
                :lable="'Description'"
                :showReadOnly="showReadOnly"
                @textData="
                  ($event) => {
                    formData.description = $event;
                  }
                "
              ></QuilEditor>
            </v-col>
            <!-- <v-divider></v-divider> -->
            <!-- <v-col cols="12" class="pa-1">
              <v-switch
                label="Default the Product Type Based on the below Rule"
                color="primary"
                v-model="formData.switch"
              ></v-switch>
            </v-col> -->
          </v-row>
          <!-- <div v-if="formData.switch">
            <v-row v-for="(row, index) in rows" :key="index">
              <v-col cols="3" class="pa-1">
                <AutoComplete :label="'Field Name'" v-model="row.fieldName" />
              </v-col>
              <v-col cols="3" class="pl-2 pa-1">
                <AutoComplete :label="'Operand'" v-model="row.operand" />
              </v-col>
              <v-col cols="3" class="pl-2 pa-1">
                <InputBox :label="'value'" v-model="row.value" />
              </v-col>
              <v-col class="pl-1 pt-2">
                <v-brn>
                  <v-btn
                    density="compact"
                    icon="mdi-plus"
                    elevation="1"
                    @click="addRows()"
                  ></v-btn>
                </v-brn>
                <v-brn style="padding-left: 10px" v-if="rows.length > 1">
                  <v-btn
                    density="compact"
                    icon="mdi-delete"
                    elevation="1"
                    @click="deleteRows(index)"
                  ></v-btn>
                </v-brn>
              </v-col>
            </v-row>
          </div> -->
        </v-container>
      </v-card-text>
      <v-card-actions class="dialogfooter" v-if="!showReadOnly">
        <v-spacer> </v-spacer>
        <v-btn class="mr-1 cancel" @click="closeDialog">Cancel</v-btn>
        <v-btn class="save" @click="save">Save</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
import ProductTypeService from "@/services/catalogs/ProductTypeService.js";
import QuilEditor from "@/components/QuilEditor.vue";
export default {
  props: ["type", "id"],
  components: {
    QuilEditor,
  },
  data() {
    return {
      codeError: false,
      codeExistFlag: false,
      codeRules: [(value) => !!value || "Code is Required"],
      refRules: [(value) => !!value || "Erp Ref.No is Required"],
      nameRules: [(value) => !!value || "Name is Required"],
      formData: {
        code: null,
        erp_key: null,
        name: null,
        description: null,
      },
      showRow: false,
      showReadOnly: false,
      rows: [
        {
          fieldName: null,
          operand: null,
          value: null,
        },
      ],
    };
  },
  created() {
    if (this.id) {
      this.getEditData();
    }
    if (this.type == "view" || this.type == "select") {
      this.showReadOnly = true;
    }
  },
  methods: {
    async getEditData() {
      let $res = await ProductTypeService.findAll({ id: this.id });
      this.formData = $res[0];
      console.log(this.formData, "formData");
    },
    addRows() {
      this.rows.push({
        fieldName: null,
        operand: null,
        value: null,
      });
    },
    closeDialog() {
      this.$emit("closeDialog");
    },

    prevent(e) {
      if (!/^[a-zA-Z0-9-_ \t]+$/.test(e.key) && e.key !== "Backspace") {
        e.preventDefault();
      }
    },
    checkCode($event) {
      this.formData.code = $event;
      if (this.formData.code && this.formData.code.length > 0) {
        this.formData.code = this.formData.code.toUpperCase();

        ProductTypeService.findAll({ code: this.formData.code }).then((res) => {
          let $exist = res.filter((key) => key.code == this.formData.code);

          this.codeExistFlag =
            $exist.length > 0
              ? this.formData.id && $exist[0].id == this.formData.id
                ? false
                : true
              : false;

          if (this.codeExistFlag) {
            this.codeError = true;
          } else {
            this.codeError = false;
          }
        });
      }
    },

    async save() {
      console.log(this.formData, "data");
      let validation = await this.$refs.form.validate();
      if (!validation.valid || this.codeError) {
        console.log(validation);
      } else {
        if (this.formData && this.formData.id) {
          delete this.formData.users;
          let output = await ProductTypeService.update(this.formData);
          this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
          console.log(output);
          this.closeDialog();
        } else {
          try {
            let output = await ProductTypeService.create(this.formData);
            this.$store.dispatch("setSnackbar", { text: "Saved Successfully" });
            console.log(output, "output", this.formData);
            this.closeDialog();
          } catch (e) {
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "Something Went Wrong",
            });
          }
        }
      }
    },

    deleteRows(index) {
      this.rows.splice(index, 1);
    },
  },
};
</script>
