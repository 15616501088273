<template>
  <PrimeTable
    :tableName="'Brands'"
    activity="Brand"
    :showTableHeader="true"
    :headers="column"
    :tableData="data"
    @addDialog="addDialog()"
    @rowAction="rowAction($event)"
    @filterData="applyFilter($event)"
    :modelName="'brands'"
  ></PrimeTable>
  <v-dialog v-model="dialog" width="600px" persistent>
    <AddBrand
      @closeDialog="closeDialog()"
      :type="type"
      :id="editDataId"
      @reload="getBrand"
    ></AddBrand>
  </v-dialog>
  <v-dialog v-model="confirmation" width="300px" persistent>
    <ConfirmationDialog
      @cancelled="cancelled()"
      @deleted="deleted"
      :content="confirmationContent"
    />
  </v-dialog>
</template>
  
  <script>
import BrandService from "@/services/catalogs/BrandService";
import AddBrand from "../AddAdmin/AddBrand.vue";
import ConfirmationDialog from "@/components/DialogConfirmation.vue";

export default {
  components: {
    AddBrand,
    ConfirmationDialog,
  },
  data() {
    return {
      dialog: false,
      confirmation: false,
      data: [],
      column: [
        { title: "Name", align: "start", key: "name" },
        { title: "Code", align: "start", key: "code" },
        { title: "Erp Ref No", align: "start", key: "erp_key" },
        { title: "Modified by", align: "start", key: "users" },
        { title: "Modified on", align: "start", key: "modified" },
        { title: "Status", align: "start", key: "active" },
        { title: "Action", key: "actions", sortable: false },
      ],
      active: [
        { id: 1, name: "Active" },
        { id: 0, name: "Inactive" },
      ],
    };
  },
  created() {
    let $value = {};
    this.getBrand($value);
  },
  methods: {
    applyFilter($value){
       this.getBrand($value)
    },
    async getBrand($value) {
      await BrandService.findAll($value)
        .then((response) => {
          console.log(response, "working11111");
          this.data = response;
          this.data.map((obj) => {
            return (obj.active = this.active.find(
              (type) => type.id == obj.active
            ).name);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    rowAction(e) {
      if (e.type == "edit") {
        this.dialog = true;
        this.editDataId = e.value.id;
        this.type = e.type;
      }
      if (e.type == "view") {
        this.dialog = true;
        this.editDataId = e.value.id;
        this.type = e.type;
      }
      if (e.type == "delete") {
        this.dialog = false;
        this.confirmation = true;
        this.confirmationContent = `Do you want to delete the Brand ${e.value.name} ?`;
        this.editDataId = e.value.id;
      }
      if (e.type == "select") {
        this.dialog = true;
        this.editDataId = e.value.id;
        this.type = e.type;
      }
    },
    addDialog() {
      this.dialog = true;
      this.type = "add";
      this.editDataId = null;
    },
    closeDialog() {
      this.dialog = false;
    },
    async deleted() {
      this.confirmation = false;
      await BrandService.delete({ id: this.editDataId });
      this.getBrand();
    },
    cancelled() {
      this.editDataId = null;
      this.confirmation = false;
    },
  },
};
</script>