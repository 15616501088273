<template>
    <v-card-title class="text-center" >Please choose your desk</v-card-title>
    <br/>
    <v-row class="d-flex justify-center">
          <v-col cols="4" class="pt-0">
            <InputBox  
            v-model="searchTerm" 
            v-if="multipleCompany.length>4" 
            label="Search for companies" 
            density="compact" 
            persistent-hint 
            variant="outlined" 
            append-inner-icon="mdi-magnify" 
            single-line hide-details />
        </v-col>
    </v-row>
     <v-row class="d-flex justify-center">
   <v-col>
    <div class="card-container">
        <v-card v-for="company in filteredCompanies" :key="company" width="100px" style="box-shadow: none;" @click="setCompany(company)">
            <v-img style="margin: 0 auto;" src="@/assets/team-icon.svg" min-height="10vh" width="70px"></v-img>
            <p class="mt-2">{{ company.name }}</p>
        </v-card>
    </div>
    <br/>
</v-col>
     </v-row>
</template>
<script>
export default{
    components:{
      
    },
    props:["multipleCompany"],
    data(){
        return{
            host: window.location.host,
            parts: window.location.host.split("."),
            searchTerm: "",
        }
    },
    computed: {
        filteredCompanies() {
        return this.multipleCompany.filter((company) =>
            company.name.toLowerCase().includes(this.searchTerm.toLowerCase())
        );
        }
    },
    created(){
        console.log(this.multipleCompany,"working33333");
    },
    methods:{
        setCompany($company) {
            console.log($company,"working22222")
            this.$emit('redirectToApp',$company)
            //this.redirectToApp($company.team, $company.token);
        },
        redirectToApp($team, $token) {
            localStorage.setItem('XTE_TOKEN', this.$route.query.token)
            if (this.host.indexOf("localhost") >= 0) {
            // this.$router.push("/autoLogin?loginToken=" + $token);
            window.location="http://"+this.host+"/autoLogin?loginToken="+$token
            } else {
            window.location =
                "https://" +
                $team +
                "." +
                this.parts[this.parts.length - 2] +
                "." +
                this.parts[this.parts.length - 1] +
                "/autoLogin?loginToken=" +
                $token;
            }
        },
    }
}
</script>
<style scoped>
.card-container {
  display: grid;
  grid-template-columns: repeat(4, auto);
  justify-content: space-evenly;
  grid-row-gap: 20px;
}

@media (max-width: 767px){
    .card-container{
        grid-template-columns:repeat(2,auto)
    }
}

</style>