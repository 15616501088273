<template>
        <v-card elevation="0" width="500px" class="pa-0">
           <v-card-title>
            <v-icon start icon="mdi-alert-circle" class="pb-1"></v-icon>Notifications</v-card-title>
           <v-divider></v-divider>
           <v-card-text class="pa-0">
            <v-list class="pa-0" v-if="notification && notification.length>0">
                 <v-list-item v-for="item in notification" :key="item.title" class="list" @click="updateNotification(item)">              
                    <template v-slot:prepend>
                     <Avatar class="mr-2" :style="`background-color: ${item.color}; color: #ffffff;`" shape="circle" >
                      {{ getInitials(item) }}</Avatar>
                    </template>

                     <span> <b>{{ item.user_name }} </b> : {{ item.content.length>40 ? item.content.slice(0,30)+'...' : item.content }}</span> 
                    <span class="time">{{ item.created ? moment(item.created).fromNow():null }}</span>
                  
                </v-list-item>
             </v-list>
           </v-card-text> 
           <v-card-text class="pa-0" v-if="notification.length==0">
              No notifications yet....
           </v-card-text>
           <!-- <v-card-actions>
             <v-btn class="button" width="100%">Show All Notification</v-btn>
           </v-card-actions> -->
        </v-card>    
</template>
<script>
import Avatar from 'primevue/avatar';
import NotificationService from '@/services/admin/NotificationService';
import moment from 'moment'
export default{
  components:{
    Avatar,
  },
data(){
    return{
      search: "",
      selected: [],
      notification: [],
      userIds: [],
      notificationShow:true,
        items: [
        {
          user_name: 'KarpagaMoorthi',
          first_name: 'Karpaga',
          last_name: 'Moorthi',
          subject: 'You have 43 people browsing your Shopify site',
          time: '1 Hour ago',
          title: 'Item #1',
          value: 1,
          color: '#358403'
        },
        {
          user_name: 'Moorthi',
          first_name: 'Karpaga',
          last_name: 'Moorthi',
          subject: 'No Orders from Shopify Store since Last 20 Day(s)',
          time: '2 Hour ago',
          title: 'Item #2',
          value: 2,
          color: '#4204de'
        },
        {
          user_name: 'Vicash',
          first_name: 'Vicash',
          last_name: 'Baskar',
          subject: '82 % of Your products have no orders since 20 Day(s)',
          time: '30 Minutes ago',
          title: 'Item #3',
          value: 3,
          color: '#ddd9d9'
        },
        {
          user_name: 'Balaganesh',
          first_name: 'Balaganesh',
          last_name: 'G',
          subject: '82 % of Your products have no orders since 20 Day(s)',
          time: '30 Minutes ago',
          title: 'Item #3',
          value: 3,
          color: '#e73313'
        },
      ],
    }
},
mounted: function() {
    this.getNotification();
  },
methods:{
  moment,
  async getNotification() {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
      this.notificationShow=true
      this.notification = await NotificationService.findAll({user_id:userDetails.id});
      this.notificationShow=false;
    },
    getPath(notifications) {
      return notifications.params ? JSON.parse(notifications.params).path : "";
    },
    async updateNotification($notify){
        if($notify.accepted==0){
            await NotificationService.update({accepted:1,id:$notify.id})
            this.getNotification();
        }
        if($notify.link){
            // let $path = $notify.params_text ? JSON.parse($notify.params_text).path : ""
            window.location = $notify.link
        }
    },
    setUrl($path) {
      window.location = $path
    },
    getInitials($data) {
      return $data.users && $data.users.length > 0 ? $data.users[0].username.substr(0, 2) :"";
    },
}
}
</script>
<style scoped>
>>>.time{
  font-size: 10px;
  float:right ;
}
>>>.list{
  border-bottom: 1px solid rgb(218, 216, 216);
}
</style>