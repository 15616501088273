import { createApp } from 'vue'
import Vuex from 'vuex'
createApp(Vuex)

export default new Vuex.Store({
  state() {
    return {
      signIn: false,
      timePeriod: '',
      marketPlaceId: [],
      isDark: 'light',
      isShowSnackbar: false,
      isRouteChange: false,
      accountStatus: false,
      childArray:null,
      childArrayNew:null,
      selectedNode:null,
      snackbar:{},
      isDevice:'desktop'
    };
  },
  getters: {
    getSignIn(state) {
      return state.signIn;
    },
    getTime(state) {
      return state.timePeriod;
    },
    getMarketPlace(state) {
      return state.marketPlaceId;
    },
    getIsDark(state) {
      return state.isDark;
    },
    getIsShowSnackbar(state) {
      return state.isShowSnackbar;
    },
    getRouteChanger(state) {
      return state.isRouteChange;
    },
    getAccountSatus(state) {
      return state.accountStatus
    },
    getChildArray(state){
      return state.childArray;
    },
    getChildArrayNew(state){
      return state.childArrayNew;
    },
    getSelectedNode(state){
      return state.selectedNode;
    },
    getIsDevice(state){
      return state.isDevice
    }
  },
  mutations: {
    setSignIn(state, payload) {
      state.signIn = payload
    },
    setTime(state, payload) {
      state.timePeriod = payload
    },
    SET_SNACKBAR(state, snackbar) {
      state.snackbar = snackbar
    },
    setMarketPlace(state, payload) {
      state.marketPlaceId = payload
    },
    setIsDark(state, payload) {
      state.isDark = payload
    },
    setIsShowSnackbar(state, payload) {
      state.isShowSnackbar = payload
    },
    setAccountStatus(state, payload) {
      state.accountStatus = payload
    },
    setRouteChanger(state, payload) {
      state.isRouteChange = payload
    },
    setChildArray(state,payload){
      state.childArray = payload;
    },
    setChildArrayNew(state,payload){
      state.childArrayNew = payload;
    },
    setSelectedNode(state,payload){
      state.selectedNode = payload;
    },
    setIsDevice(state,payload){
      state.isDevice=payload;
    }
  },
  actions: {
    setSnackbar({ commit }, snackbar) {
      snackbar.showing = true
      snackbar.color = snackbar.color || '#3C5AAA'
      commit('SET_SNACKBAR', snackbar);
    },
    sendNotifications() {

    }
  },
  modules: {}
});

