<template>
  <div class="solutions1">
  <div class="txt">
      -XTEND is the right platform for any business stepping into the world of E-commerce.
  </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
     
    };
  },
};
</script>
<style scoped>
.solutions1 {
  background-image: url("/public/images/publicSolutions.png") !important;
  background-size: cover;
  min-height: 900px;
  background-size:100%;
  }
.solutions1 .txt{
  position: relative;
   width: 43%;
    height: 180px;
    left: 55%;
    top: 280px;
    font-family: 'Nunito' !important;
    font-size: 38px;
    line-height: 60px;
    color: #FFFFFF;
}
@media (max-width:480px) {
  
.solutions1 {
  min-height: 250px !important;
  }
  .solutions1 .txt{
    position: relative;
     width: 43%;
    height: 70px;
    left: 55%;
    top: 55px;
    font-family: 'Nunito' !important;
    font-size: 12px;
    line-height: 20px;
    color: #FFFFFF;
}
}
@media only screen and (min-width:480px) and (max-width: 650px)  {
  
.solutions1 {
  min-height: 350px !important;
  }
  .solutions1 .txt{
   position: relative;
    width: 43%;
    height: 70px;
    left: 55%;
    top: 76px;
    font-family: 'Nunito' !important;
    font-size: 17px;
    line-height: 26px;
    color: #FFFFFF;
}
}
@media only screen and (min-width:650px) and (max-width: 900px)  {
  
.solutions1 {
  min-height: 500px !important;
  }
  .solutions1 .txt{
    position: relative;
    width: 43%;
    height: 70px;
    left: 55%;
    top: 122px;
    font-family: 'Nunito' !important;
    font-size: 26px;
    line-height: 40px;
    color: #FFFFFF;
}
}
@media only screen and (min-width:900px) and (max-width: 1200px)  {
  
.solutions1 {
  min-height: 650px !important;
  }
  .solutions1 .txt{
    position: relative;
    width: 43%;
    height: 155px;
    left: 55%;
    top: 140px;
    font-family: 'Nunito' !important;
    font-size: 35px;
    line-height: 55px;
    color: #FFFFFF;
}
}
/* @media only screen and (min-width:1200px) and (max-width: 1600px)  {
  
.solutions1 {
  min-height: 900px !important;
  }
  .solutions1 .txt{
    position: relative;
   width: 43%;
    height: 180px;
    left: 55%;
    top: 280px;
    font-family: 'Nunito' !important;
    font-size: 38px;
    line-height: 60px;
    color: #FFFFFF;
}
} */
@media only screen and (min-width:1600px)  {
  
.solutions1 {
  min-height: 1100px !important;
  }
  .solutions1 .txt{
    position: relative;
   width: 43%;
    height: 180px;
    left: 55%;
    top: 280px;
    font-family: 'Nunito' !important;
    font-size: 55px;
    line-height: 85px;
    color: #FFFFFF;
}
}
</style>