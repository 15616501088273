<template>
    <v-row>
        <v-col class="pa-0" v-for="item in items" :key="item.title" cols="12">
            <v-col cols="12" class="pl-0"><b>Refunded</b>
                <span class="pl-3">
                    <Tag class="chip1 pa-2 pt-1 pb-1 text-caption">OverSold</Tag>
                    </span> 
            </v-col>

                <v-card elevation="0">
                  <v-card-text class="pb-0">
                    <v-row class="pa-1">
                        <v-col cols="10" >
                            <v-row >
                                <span><v-checkbox color="indigo"></v-checkbox></span>
                                  <span class="pl-5 pr-3"> <v-img :width="30" :height="60" cover :src="`${item.src}`"></v-img></span>
                                  <span class="pl-3 pt-2">
                                     <b class="text-subtitle-2">{{ item.title }}</b><span class="pl-2"><v-icon size="20" @click="openDialog(item.id)">mdi-pencil</v-icon></span>
                                     <div><span class="text-caption">FLML0017-001 | FLML0017-001-QTY</span> </div>
                                  </span>
                           </v-row>
                            </v-col>
                        <v-col cols="2" align="end">
                            <p class="text-body-2">45.00 USD</p> 
                            <p class="text-caption">Qty : 10</p>
                        </v-col>
                  </v-row>
                  </v-card-text>
                </v-card>  
        </v-col>
    </v-row>
    <v-dialog v-model="dialog" width="500px" persistent>
         <AddItems @closeDialog="closeDialog()" :id="this.count"/>
    </v-dialog>
</template>
<script>
import Tag from 'primevue/tag';
import AddItems from './AddItems.vue';
export default{
    components:{
        Tag,
        AddItems
    },
    data(){
        return{
            items:[
          {count:"2",title:"Tour Manager FL 36\" Rolling Black Duffle Bag Default Title",chip:'FL00018-001-Qty',b_color:'aqua',color:'',src:"https://m.media-amazon.com/images/I/81D-IeggusL._AC_UL400_.jpg"},
      ],
            dialog: false,
        }
    },
    methods:{
        closeDialog(){
            this.dialog = false
        },
        openDialog(){
        this.dialog = true
        },
    }
}
</script>

<style scoped>
>>> .chip1{
    /* padding-right: 5px;
    padding-left: 5px;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-left: 8px; */
    background-color: #FFDFD1;
    color:#272727;
    font-weight: 400;
}   

</style>