import MarketPlace from '@/modules/marketPlaces/Main.vue';
import Profile from '@/modules/marketPlaces/Profile.vue'
import Integration from '@/modules/marketPlaces/Integration.vue'
import ChooseMarketPlace from '@/modules/marketPlaces/ChooseMarketPlace.vue'
import Products from '@/modules/marketPlaces/Products.vue'
import Orders from '@/modules/marketPlaces/Orders.vue'
import Inventory from '@/modules/marketPlaces/Inventory.vue'
import Location from '@/modules/marketPlaces/Location.vue'
import ShipmentRule from '@/modules/marketPlaces/ShipmentRule.vue'
import PricingRule from '@/modules/marketPlaces/PricingRule.vue'
import DiscountCode from '@/modules/setting/admin/DiscountCode.vue'
import SkuMapping from '@/modules/marketPlaces/SkuMapping.vue'
import InventoryMapping from '@/modules/marketPlaces/InventoryMapping.vue'
import ProductInstore from '@/modules/marketPlaces/ProductInStore.vue'
import ProductInventoy from '@/modules/marketPlaces/ProductInventory.vue'


export default [
    {
        path: '/chooseMarketPlace',
        name: ChooseMarketPlace,
        component: ChooseMarketPlace,
        meta: {
            breadCrumb: [
              {
                to: '/chooseMarketPlace', 
                text: 'MarketPlaces'
              }
            ]
          }
    },
    {
        path: '/marketPlace',
        component: MarketPlace,
        name:'MarketPlace',
        children: [
            {
                path: "profile",
                component: Profile,
                name:'Profile',
                meta: {
                    breadCrumb: [{
                      to: '/chooseMarketPlace', // hyperlink
                      text: 'MarketPlaces',
                      color:'#3C5AAA' // crumb text
                    },
                     {
                      text: 'Profile'       // crumb text
                     }]
                  }
            },
            {
                path: "integration",
                component: Integration,
                name:"Integration",
                meta: {
                    breadCrumb: [{
                      to: '/chooseMarketPlace', // hyperlink
                      text: 'MarketPlaces',
                      color:'#3C5AAA' // crumb text
                    },
                     {
                      text: 'Integration'       // crumb text
                     }]
                  }
            },
            {
                path: "products",
                component: Products,
                meta: {
                    breadCrumb: [{
                      to: '/chooseMarketPlace', // hyperlink
                      text: 'MarketPlaces',
                      color:'#3C5AAA' // crumb text
                    },
                     {
                      text: 'Products'       // crumb text
                     }]
                  }
            },
            {
                path: "orders",
                component: Orders,
                meta: {
                    breadCrumb: [{
                      to: '/chooseMarketPlace', // hyperlink
                      text: 'MarketPlaces',
                      color:'#3C5AAA' // crumb text
                    },
                     {
                      text: 'Orders'       // crumb text
                     }]
                  }
            },
            {
                path: "inventory",
                component: Inventory,
                meta: {
                    breadCrumb: [{
                      to: '/chooseMarketPlace', // hyperlink
                      text: 'MarketPlaces',
                      color:'#3C5AAA' // crumb text
                    },
                     {
                      text: 'Inventory'       // crumb text
                     }]
                  }
            },
            {
                path: "location",
                component: Location,
                meta: {
                    breadCrumb: [{
                      to: '/chooseMarketPlace', // hyperlink
                      text: 'MarketPlaces',
                      color:'#3C5AAA' // crumb text
                    },
                     {
                      text: 'Locations'       // crumb text
                     }]
                  }
            },
            {
                path: "shipmentRule",
                component: ShipmentRule,
                meta: {
                    breadCrumb: [{
                      to: '/chooseMarketPlace', // hyperlink
                      text: 'MarketPlaces',
                      color:'#3C5AAA' // crumb text
                    },
                     {
                      text: 'ShipmentRule'       // crumb text
                     }]
                  }
            },
            {
                path: "pricingRule",
                component: PricingRule,
                meta: {
                    breadCrumb: [{
                      to: '/chooseMarketPlace', // hyperlink
                      text: 'MarketPlaces',
                      color:'#3C5AAA' // crumb text
                    },
                     {
                      text: 'PricingRule'       // crumb text
                     }]
                  }
            },
            {
                path: "discountCode",
                component: DiscountCode,
                meta: {
                    breadCrumb: [{
                      to: '/chooseMarketPlace', // hyperlink
                      text: 'MarketPlaces',
                      color:'#3C5AAA' // crumb text
                    },
                     {
                      text: 'DiscountCode'       // crumb text
                     }]
                  }
            },
            {
                path: "skuMapping",
                component: SkuMapping,
                meta: {
                    breadCrumb: [{
                      to: '/chooseMarketPlace', // hyperlink
                      text: 'MarketPlaces',
                      color:'#3C5AAA' // crumb text
                    },
                     {
                      text: 'SkuMapping'       // crumb text
                     }]
                  }
            },
            {
                path: "inventoryMapping",
                component: InventoryMapping,
                meta: {
                    breadCrumb: [{
                      to: '/chooseMarketPlace', // hyperlink
                      text: 'MarketPlaces',
                      color:'#3C5AAA' // crumb text
                    },
                     {
                      text: 'InventoryMapping'       // crumb text
                     }]
                  }
            },
            {
                path: "productInstore",
                component: ProductInstore,
                meta: {
                    breadCrumb: [{
                      to: '/chooseMarketPlace', // hyperlink
                      text: 'MarketPlaces',
                      color:'#3C5AAA' // crumb text
                    },
                     {
                      text: 'ProductInStore'       // crumb text
                     }]
                  }
            },
            {
                path: "productInventoy",
                component: ProductInventoy,
                meta: {
                    breadCrumb: [{
                      to: '/chooseMarketPlace', // hyperlink
                      text: 'MarketPlaces',
                      color:'#3C5AAA' // crumb text
                    },
                     {
                      text: 'ProductInventoy'       // crumb text
                     }]
                  }
            },






        ]
    }
]