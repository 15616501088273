<template>
  <v-layout :data-theme="appTheme">
    <v-navigation-drawer v-if="$route.path != '/verification'" width="220" expand-on-hover :rail="rail" permanent class="sidemenu">
      <SideMenu @click="menuClick" :drawer="drawer" :menuItems="menuItems" :userName="userName"></SideMenu>
    </v-navigation-drawer>
    <v-main class="dashbordbodycontent">
      <TopMenu v-if="$route.path != '/verification'" @DarkAndLight="DarkAndLight"></TopMenu>
      <BreadCrumps/>
      <div style="margin-top:36px">
      <RouterView  :key="$route.fullPath"></RouterView>
    </div>
    </v-main>
  </v-layout>
</template>
<script>
import TopMenu from "../components/TopMenu.vue";
import BreadCrumps from "../components/BreadCrumps.vue";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
// import { getMessaging, getToken } from "firebase/messaging";
import TokenService from '@/services/admin/TokenService.js'
import SideMenu from "@/components/SideMenu.vue";
export default {
  props: ["appTheme"],
  components: {
    TopMenu,
    BreadCrumps,
    SideMenu
  },
  data() {
    return {
      darkandlight: 'light',
      userName: localStorage.getItem("_CUSER_"),

      drawer: true,
      items: [
        { title: "Home", icon: "mdi-home-city" },
        { title: "My Account", icon: "mdi-account" },
        { title: "Users", icon: "mdi-account-group-outline" },
      ],
      rail: true,
      menuItems: [
        {
          src: "https://s3.ap-south-1.amazonaws.com/xtend3.0/icons/xtend/home.svg",
          name: "Home",
          show: false,
          isActive: false,
          route: "/",
        },
        {
          src: "https://s3.ap-south-1.amazonaws.com/xtend3.0/icons/xtend/galery.svg",
          name: "Products",
          show: false,
          isActive: false,
          route: "",
        },
        {
          src: "https://s3.ap-south-1.amazonaws.com/xtend3.0/icons/xtend/dashboard.svg",
          name: "Inventory",
          show: false,
          isActive: false,
          route: "",
        },
        {
          src: "https://s3.ap-south-1.amazonaws.com/xtend3.0/icons/xtend/slider.svg",
          name: "Orders",
          show: false,
          isActive: false,
          route: "/orders",
        },
        {
          src: "https://s3.ap-south-1.amazonaws.com/xtend3.0/icons/xtend/menu.svg",
          name: "Scheduler",
          show: false,
          isActive: false,
          route: "",
        },
        {
          src: "https://s3.ap-south-1.amazonaws.com/xtend3.0/icons/xtend/terminal.svg",
          name: "Marketplaces",
          show: false,
          isActive: false,
          route: "/chooseMarketPlace",
        },
        {
          src: "https://s3.ap-south-1.amazonaws.com/xtend3.0/icons/xtend/settingfeutcher.svg",
          name: "Reports",
          show: false,
          isActive: false,
          route: "",
        },
        {
          src: "https://s3.ap-south-1.amazonaws.com/xtend3.0/icons/xtend/setting1.svg",
          name: "Settings",
          show: false,
          isActive: false,
          route: "/setting/user",
        },
        {
          src: "https://synergy-snaps.s3.ap-south-1.amazonaws.com/public/icons/IdentitySettings_gray.svg",
          name: "Identity",
          show: false,
          isActive: false,
          route: "/identity",
        },
        {
          src: "https://s3.ap-south-1.amazonaws.com/xtend3.0/icons/xtend/logout1.svg",
          name: "Logout",
          show: false,
          isActive: false,
        },
      ],
    };
  },
  created: function () {
    this.configureFirebaseMessaging()
    onMessage(getMessaging(), (payload) => {
      console.log('[firebase-messaging-sw.js] Received fg message ');
      // Customize notification here
      const notificationTitle = 'Background Message Title';
      const notificationOptions = {
        body: 'Background Message body.',
        icon: '/firebase-logo.png'
      };
      console.log(payload)
      console.log(notificationOptions)
      console.log(notificationTitle)
      /*self.registration.showNotification(notificationTitle,
      notificationOptions);*/
      // ...
    });
  },
  methods: {
    DarkAndLight($value) {
      if ($value.title == 'Dark And Light') {
        this.darkandlight = this.darkandlight ? 'dark' : 'light'
      }
    },
    menuClick($item) {
      console.log($item, 'itemsssssssssss')
    },
    async updateFCMToken(token, userId) {
      let $data = await TokenService.findAll({ user_id: userId })
      console.log($data, 'token service response')
      if ($data && $data.length > 0) {
        await TokenService.update({ user_id: userId, token_type: token, id: $data[0].id })
      } else {
        // need to insert data
        await TokenService.create({ user_id: userId, token_type: token, active: 1 })
      }
    },
    getNotifcationPermisson() {
      // Let's check if the browser supports notifications
      //alert('here')
      if (!("Notification" in window)) {
        alert("This browser does not support desktop notification");
        return false
      }

      // Let's check whether notification permissions have already been granted
      else if (Notification.permission === "granted") {
        //  var notification = new Notification("Hi there!");
        // alert('granted')
        // alert('Permission gratnd')

        return true
      }
      else if (Notification.permission !== "denied") {
        //alert('Not deinited')
        Notification.requestPermission().then(function (permission) {
          // If the user accepts, let's create a notification
          //alert(permisson)
          if (permission === "granted") {
            //  var notification = new Notification("Hi there!");
            return true
          } else {
            return false
          }
        });
        // At last, if the user has denied notifications, and you
        // want to be respectful there is no need to bother them any more.
      }
    },
    async configureFirebaseMessaging() {
      const token = localStorage.getItem("_FB_MESSAGE_TOKEN_")
      const userDetails = JSON.parse(localStorage.getItem("_CUID_"));
      console.log('I am inside token')

      if (token) {
        this.updateFCMToken(token, userDetails)
        localStorage.setItem("_FB_MESSAGE_TOKEN_", token)
      } else {

        const messaging = getMessaging();
        getToken(messaging, { vapidKey: 'BMrQ62J9KkYZvHLeeyU0C2fdDb-w3LxS5FvhO3L9nSxsvfBtfrZpiuIO_wPqCnvAx1SLM6wmwFJoGbkIBjX8pV4' })
          .then(token => {
            console.log('Notification token', token)
            this.updateFCMToken(token, userDetails)
            localStorage.setItem("_FB_MESSAGE_TOKEN_", token)
          }).catch(error => {
            console.log(error)
          })


      }
      this.getNotifcationPermisson();
    }

   
  }
};
</script>