<template>
  <v-card flat class="rounded-lg" height="342px" style="border: 2px solid; border-color: #cfcdcd">
    <v-card-title>Product Heirarchy</v-card-title>
    <v-card-text>
      <v-form ref="form">
        <v-row class="pt-6">
          <v-col cols="12" class="pa-0 px-2">
            <AutoComplete :label="'Product Type'" :items="productTypeData" :text="'name'"
          :value="'id'"
              :data="formData.product_type"
              @textData="selectProducttype($event)"
              :rules="productRule" />
          </v-col>
          <v-col cols="12" class="pa-0 px-2">
            <AutoComplete :label="'Category'" :items="categoryData" :text="'name'"
              :value="'id'"
              :rules="CategoryRule" 
              :data="formData.category"
              @textData="selectCategory($event)" />
          </v-col>
          <v-col cols="12" class="pa-0 px-2">
            <AutoComplete :label="'Sub Category'" :items="subCategoryData"  :text="'name'"
              :value="'id'"
              :rules="subcategoryRule" 
              :data="formData.sub_category"
              @textData="productHeirarchy($event)" 
               />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import AutoComplete from "../../components/AutoComplete.vue";
import ProductTypeService from "@/services/catalogs/ProductTypeService";
import CategoryService from "@/services/catalogs/CategoryService";
import SubCategoryService from "@/services/catalogs/SubCategoryService";

export default {
  props: ['validation', 'productData'],
  components: {
    AutoComplete,
  },
  data() {
    return {
      productRule: [(value) => (!!value) || "Product Type is Required"],
      CategoryRule: [(value) => (!!value) || "Category Type is Required"],
      subcategoryRule: [(value) => (!!value) || "SubCategory Type is Required"],
      formData: {
        product_type: null,
        category: null,
        sub_category: null,
      },
      productTypeData: [],
      categoryData: [],
      subCategoryData: [],
    };
  },
  created() {
    if (this.productData && Object.keys(this.productData).length>0) {
      this.formData.product_type = this.productData.product_type_id
       this.getCategory(this.formData.product_type);
      this.formData.category = this.productData.category_id
      this.getSubCategory(this.formData.category)
      this.formData.sub_category = this.productData.sub_category_id
    }
    this.getProductType();
  },
  watch: {
    validation() {
      this.save();
    },
    productData() {
      this.formData.product_type = this.productData.product_type_id
      this.formData.category = this.productData.category_id
      if(this.productData.product_type_id){
        this.getSubCategory(this.formData.category)
        this.getCategory(this.formData.product_type)
      }
      this.formData.sub_category = this.productData.sub_category_id
    }
  },
  methods: {
    async getProductType() {
      let res = await ProductTypeService.findAll();
      this.productTypeData = res&&res.length>0 ? res : [];
    },
    async getCategory(id) {
      let res = await CategoryService.findAll({product_type_id:id});
      this.categoryData = res&&res.length>0 ? res : [];
    },
    async getSubCategory(id) {
      let res = await SubCategoryService.findAll({ category_id: id });
      this.subCategoryData =res&&res.length>0 ? res : [];
    },
    async save() {
      let validation = await this.$refs.form.validate();
      if (!validation.valid) {
        console.log(validation);
      }
      else {
        var isvalid = true
        this.$emit("validCheck", isvalid)
      }
    },
    productHeirarchy(value) {
      this.formData.sub_category = value
      this.$emit("productHeirarchy", this.formData);
    },
    selectCategory(value){
      this.formData.category = value
      this.formData.sub_category = null
      if (value) {
        this.getSubCategory(value)
      }
      this.$emit("productHeirarchy", this.formData);
    },
    selectProducttype(value){
      this.formData.product_type = value
      this.formData.category = null
      if (value) {
        this.getCategory(value)
      }
      this.$emit("productHeirarchy", this.formData);
    }
  },
};
</script>

<style></style>