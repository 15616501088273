import List from './templateList.vue'
import TemplateGrid from './templateGrid.vue'
import AddTemplate from './template.vue'
export default [
    {
        path: '',
        component: List,
        children: [
            
              {
                path: 'sms',
                name: 'SMS template',
                children: [
                  {
                    path: '',
                    name: 'SMS template',
                    component: TemplateGrid,
                    meta: {
                      breadCrumb: [
                        {
                          to:'/templates/sms',
                          text: 'Settings',
                          color:'#3C5AAA'
                        },
                        {
                          to:'/templates/sms',
                          text: 'Template',
                          color:'#3C5AAA'
                        },
                        {
                          to:'/templates/sms',
                          text: 'SMS'
                        },
                      ]
                    }
                  },
                  {
                    path: 'add',
                    name: 'Add SMS Template',
                    component: AddTemplate,
                    meta: {
                      breadCrumb: [
                        {
                          to:'/templates/sms',
                          text: 'Settings',
                          color:'#3C5AAA'
                        },
                        {
                          to:'/templates/sms',
                          text: 'Template',
                          color:'#3C5AAA'
                        },
                        {
                          to:'/templates/sms',
                          text: 'SMS',
                          color:'#3C5AAA'
                        },
                        {
                          to:'/templates/sms/add',
                          text: 'Add SMS'
                        },
                      ]
                    }
                  },
                ]
              },
              {
                path: 'email',
                name: 'Email template',
                children: [
                  {
                    path: '',
                    name: 'Email template',
                    component: TemplateGrid,
                    meta: {
                      breadCrumb: [
                        {
                          to:'/templates/email',
                          text: 'Settings',
                          color:'#3C5AAA'
                        },
                        {
                          to:'/templates/email',
                          text: 'Template',
                          color:'#3C5AAA'
                        },
                        {
                          to:'/templates/email',
                          text: 'Email'
                        },
                      ]
                    }
                  },
                  {
                    path: 'add',
                    name: 'Add Email Template',
                    component: AddTemplate,
                    meta: {
                      breadCrumb: [
                        {
                          to:'/templates/email',
                          text: 'Settings',
                          color:'#3C5AAA'
                        },
                        {
                          to:'/templates/email',
                          text: 'Template',
                          color:'#3C5AAA'
                        },
                        {
                          to:'/templates/email',
                          text: 'Email',
                          color:'#3C5AAA'
                        },
                        {
                          to:'/templates/email/add',
                          text: 'Add Email'
                        },
                      ]
                    }
                  },
                ]
              },
              {
                path: 'notification',
                name: 'Notification',
                children: [
                  {
                    path: '',
                    name: 'Notification',
                    component: TemplateGrid,
                    meta: {
                      breadCrumb: [
                        {
                          to:'/templates/notification',
                          text: 'Settings',
                          color:'#3C5AAA'
                        },
                        {
                          to:'/templates/notification',
                          text: 'Template',
                          color:'#3C5AAA'
                        },
                        {
                          to:'/templates/notification',
                          text: 'Notification'
                        },
                      ]
                    }
                  },
                  {
                    path: 'add',
                    name: 'Add Notification Template',
                    component: AddTemplate,
                    meta: {
                      breadCrumb: [
                        {
                          to:'/templates/notification',
                          text: 'Settings',
                          color:'#3C5AAA'
                        },
                        {
                          to:'/templates/notification',
                          text: 'Template',
                          color:'#3C5AAA'
                        },
                        {
                          to:'/templates/notification',
                          text: 'Notification',
                          color:'#3C5AAA'
                        },
                        {
                          to:'/templates/notification/add',
                          text: 'Add Notification'
                        },
                      ]
                    }
                  },
                ]
              },
              {
                path: 'whatsapp',
                name: 'whatsapp',
                children: [
                  {
                    path: '',
                    name: 'Whatsapp',
                    component: TemplateGrid,
                    meta: {
                      breadCrumb: [
                        {
                          to:'/templates/whatsapp',
                          text: 'Settings',
                          color:'#3C5AAA'
                        },
                        {
                          to:'/templates/whatsapp',
                          text: 'Template',
                          color:'#3C5AAA'
                        },
                        {
                          to:'/templates/whatsapp',
                          text: 'Whatsapp'
                        },
                      ]
                    }
                  },
                  {
                    path: 'add',
                    name: 'Add Whatsapp Template',
                    component: AddTemplate,
                    meta: {
                      breadCrumb: [
                        {
                          to:'/templates/whatsapp',
                          text: 'Settings',
                          color:'#3C5AAA'
                        },
                        {
                          to:'/templates/whatsapp',
                          text: 'Template',
                          color:'#3C5AAA'
                        },
                        {
                          to:'/templates/whatsapp',
                          text: 'Whatsapp',
                          color:'#3C5AAA'
                        },
                        {
                          to:'/templates/whatsapp/add',
                          text: 'Add Whatsapp'
                        },
                      ]
                    }
                  },
                ]
              },
    ],
}
]