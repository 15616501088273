<template>
    <v-row no-gutters dense>
        <v-col cols="6">
            <div class="ma-2" style="font-size: 16px;font-weight: bolder;">ERP</div>
            <InputBox :placeholder="'Search for more erps'" :data="search"  @textData="searchData($event)" />
        </v-col>
    </v-row>
    <v-row justify="center">
        <v-col cols="2" class="pa-3" v-for="(item, index) in items" :key="index">
            <v-card @click="select(index)" :class="{ onbordActive: item.isSelect }" elevation="5" height="150"
                class="rounded-lg onboardCard">
                <v-card-text class="text-center mt-4">
                    <div class="text-center mt-2">{{ item.display_value }}</div>
                    <v-icon class="tick-icon" color="green" size="80">mdi-check-circle-outline</v-icon>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import EnumService from '../services/admin/EnumService'
import InputBox from '@/components/InputBox.vue'
export default {
    props: ['reload'],
    components: {
        InputBox,
    },
    data() {
        return {
            items: [],
            subItems: [],
            search: ''
        }
    },
    watch: {
        reload() {
           this.getEnums()
        }
    },
    async mounted() {
        await this.getEnums()
    },
    methods: {
        async getEnums() {
            await EnumService.findAll({ domain: 'ERP', company_id: 0 }).then(res => {
                res.forEach(f => {
                    f.isSelect = false
                })
                this.items = res
                this.items = this.items.slice(0, 5);
                this.subItems = JSON.parse(JSON.stringify(res))
            }, error => {
                console.log(error)
            })
        },
        searchData($value) {
            this.search =$value
            this.items = this.subItems.filter(f => f.display_value.toLowerCase().includes($value.toLowerCase()) || f.data_value.toLowerCase().includes($value.toLowerCase()));
            this.items = this.items.slice(0, 5);
        },
        async select(index) {
            this.items.forEach(element => {
                element.isSelect = false
            });
            this.items[index].isSelect = true
            let $value = this.items[index].data_value
            if ($value == 'envision' || $value == 'dynamics' || $value == 'oracle' || $value == 'quickbooks' || $value == 'ApacheOfBiz'
                || $value == 'netSuite' || $value == 'ERPNext' || $value == 'inoERP' || $value == 'xTubplePostBooks' || $value == 'neibiem') {
                let $data = {
                    data: this.items[index],
                    value: false
                }
                this.$emit('erpChanged', $data)
            }
            else {
                let data = {
                    erp: this.items[index].data_value,
                    active: 1
                }
                localStorage.setItem('ERPData', JSON.stringify(data))
                // await ErpProfileService.create(data)
                let $data = {
                    data: this.items[index],
                    value: true
                }
                this.$emit('erpChanged', $data)
            }
        }
    }
}
</script>

<style></style>