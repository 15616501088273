<template>
  <v-card>
    <v-form @submit.prevent="save()" ref="form">
      <v-card-title
        >Add Product
        <v-btn
          density="comfortable"
          @click="closeDialog()"
          elevation="0"
          icon="mdi-close"
          style="float: right"
        ></v-btn
      ></v-card-title>
      <v-divider></v-divider>

      <v-card-text class="mt-3">
        <AutoComplete
          :label="'Products'"
          :items="productsData"
          :text="'name'"
          :value="'id'"
          :data="formData.product_id"
          @textData="ProductUpdate($event)"
          :rules="productRules"
        />
        <span
          v-if="showonly"
          class="pl-2"
          style="color: red;position:relative;bottom:21px;font-size: 12px;"
          >Already Exists</span
        >
      </v-card-text>
      <v-card-actions align="end" class="dialogfooter">
        <v-spacer></v-spacer>
        <v-btn class="mr-2 cancel" @click="closeDialog()">Cancel</v-btn>
        <v-btn class="save" type="submit" :disabled="showonly"   >Save</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
  
  <script>
import AutoComplete from "./AutoComplete.vue";
import ProductService from "@/services/catalogs/ProductService";
import ProductsWatchService from "@/services/catalogs/ProductsWatchService";
export default {
  props: ["type"],
  components: {
    AutoComplete,
  },
  data() {
    return {
      showonly: false,
      productsData: [],
      ProductWatchData: {},
      productRules: [(value) => !!value || "Product is Required"],
      formData: {
        product_id: null,
      },
      userId: JSON.parse(localStorage.getItem("userDetails")),
      companyId: JSON.parse(localStorage.getItem("companyDetails")),
    };
  },  
  created() {
    if(this.companyId.id){
      this.getProducts(this.companyId.id);
    }
    if (this.type == "add") {
      this.formData.product_id = null;
    }
  },
  methods: {
    async getProducts(id) {
      let res = await ProductService.findAll({company_id:id});
      this.productsData = res&&res.length>0 ? res : [];
    },
    closeDialog() {
      this.$emit("closeDialog");
    },

    async ProductUpdate(value) {
     this.formData.product_id=value
      let res = await ProductsWatchService.findAll({
        product_id:value,
        user_id: this.userId.id,
        company_id: this.companyId.id,
      });
      this.ProductWatchData = res[0];
      if (this.ProductWatchData.active == 1) {
        this.showonly = true;
      } else {
        this.showonly = false;
      }
    },
    async save() {
      let validation = await this.$refs.form.validate();
      if (!validation.valid || this.codeError) {
        console.log("Not valid");
      } else {
        let res = await ProductsWatchService.findAll({
          product_id: this.formData.product_id,
          user_id: this.userId.id,
          company_id: this.companyId.id,
          active: 0,
        });
        if (res && res.length > 0) {
          let data = {
            id: res[0].id,
            active: 1,
          };
          await ProductsWatchService.update(data);
          this.closeDialog();
        } else {
          ProductsWatchService.create(this.formData);
          this.closeDialog();
        }
      }
    },
  },
};
</script>