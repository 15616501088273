<template>
   <v-label class="commonLabel ml-1" v-if="label">{{ label }}</v-label>
  <v-textarea 
  v-model="value"
   @keyup="changeText()"
    :rules="rules"
    :placeholder="label"
    density="compact"
    variant="outlined"
    :label="label"
    class="text-Field"
    :disabled="disabled"
  ></v-textarea>
</template>
<script>
export default{
   props:['label','maxlength','rules','disabled','data'],
   data(){
      return{
        value:null
      }
   },
   created(){
      if(this.data){
          this.value =this.data
      }
   },
   watch:{
          data(){
                if(this.data){
                      this.value =this.data
                }
          }
    },
    methods:{
      changeText() { 
                this.$emit('textData', this.value)
          },
          keydown($event){
            this.$emit('keydowninput', $event)
          }
    }
}
</script>
