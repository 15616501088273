<template>
    <v-row>
         <v-col>
             <PrimeTable :activity="'Product Inventory'" :isShowAdd="true" :tableName="'Product Inventory'" :showTableHeader="true" :headers="column" :tableData="data" @rowAction="rowAction" ></PrimeTable>
         </v-col>
     </v-row>
 </template>
 <script>
 import MwsProductFulfillmentService from '@/services/mws/MwsProductFulfillmentService';
 export default{
   data(){
     return {
       data: [],
       mwsId:localStorage.getItem("mwsHeaderId"),
             column: [
                 { title: 'Mws Product Id', align: 'start', key: 'mws_product_id' },
                 { title: ' Mws Variant Id', align: 'start', key: 'mws_variant_id' },
                 { title: 'Mws Inventory Id', align: 'start', key: 'inventory_id' },
                 { title: 'Sku', align: 'start', key: 'mws_sku' },
                 { title: 'Fulfillment', align: 'start', key: 'mws_product_fulfillment_id' },
                 { title: 'Modified on', align: 'start', key: 'created' },
                 { title: 'Qty', align: 'start', key: 'available' },
                 { title: 'Status    ', key: 'active',sortable: false, },
 
             ],
             active: [
                 { id: 1, name: "Active" },
                 { id: 0, name: "Inactive" },
             ],
     }
   },
   created(){
      this.getInventories();
   },
   methods:{
    async getInventories(){
      let $res = await MwsProductFulfillmentService.findAll({mws_header_id:this.mwsId});
      if($res && $res.length>0){
        $res.forEach((element) => {
          element.mws_variant_id=element.mws_product_xref ? element.mws_product_xref.mws_variant_id : null;
          element.mws_product_id=element.mws_product_xref ? element.mws_product_xref.mws_product_id : null;
          element.inventory_id=element.mws_product_xref ? element.mws_product_xref.inventory_id : null;
          element.mws_sku=element.mws_product_xref ? element.mws_product_xref.mws_sku : null;
          element.mws_upc=element.mws_product_xref ? element.mws_product_xref.mws_upc : null;
          element.merchant_sku=element.mws_product_xref ? element.mws_product_xref.merchant_sku : null;
        })
        this.data = $res;
      }
    }
   }
 }
 </script>