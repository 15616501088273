<template>
  <v-row justify="center">
    <v-col cols="12" class="font-weight-black text-center" style="font-size:25px;">You are all set to Go. </v-col>
  </v-row>
  <v-form @submit.prevent="save" ref="form">
    <v-row no-gutters dense class="pa-3 mt-5" justify="center">
      <v-col cols="4" class="mr-3 mt-4">
        <InputBox :label="'Company Name'" :rules="nameRules" :data="formData.name" @textData="($event) => {formData.name = $event;}" />
      </v-col>
      <v-col cols="4" class="mt-4">
        <InputBox :label="'Site Name'" :rules="portalRules" :data="formData.portal_id" @textData="($event) => {formData.portal_id = $event;}" />
      </v-col>
      <v-col cols="4" class="mr-3 mt-4">
        <InputBox :label="'City'" :rules="cityRules" :data="formData.city" @textData="($event) => {formData.city = $event;}" />
      </v-col>
      <v-col cols="4" class="mt-4">
        <InputBox :label="'Zip'" :rules="zipRules" :data="formData.zip_code" @textData="keyPressNumbers($event)" />
      </v-col>
      <v-col cols="4" class="mr-3 mt-4">
        <AutoComplete :label="'Country'" :rules="countryRules" :data="formData.country"
         @textData="changeCountry($event)" :items="countries" :text="'name'" :value="'id'" />
      </v-col>
      <v-col cols="4" class="mt-4">
        <AutoComplete :label="'State'" :rules="stateRules" :data="formData.state"  @textData="($event) => {formData.state= $event;}" :items="states" :text="'name'"
          :value="'id'" />
      </v-col>
    </v-row>
  </v-form>
  <v-row justify="center">
    <v-col cols="6" class="text-center">Explore the endless possibilities and deep drive into the world of ecommerce with
      <span style="font-size: 16px;color: #3C5AAA;">Xtend</span> as your partner</v-col>
  </v-row>
  <v-row justify="center">
    <v-col cols="12" style="text-align: center;">
      <v-btn class="save" @click="save" type="submit">Save</v-btn>
      <v-btn density="comfortable" @click="reloadBoard()" elevation="0" icon="mdi-reload"></v-btn>
    </v-col>
  </v-row>
</template>

<script>
import InputBox from '@/components/InputBox.vue'
import AutoComplete from '@/components/AutoComplete.vue'
import Country from '../api/service/Countries.json'
import State from '../api/service/States.json'
import ErpProfileService from '@/services/admin/ErpProfileService';
import MwsHeaderService from '../services/mws/MwsHeaderService'
import CompanyService from '../services/admin/CompanyService'
import UserService from '../services/admin/UserService'
export default {
  props: ['reload'],
  components: {
    InputBox,
    AutoComplete
  },
  data() {
    return {
      host: window.location.host,
      parts: window.location.host.split('.'),
      formData: {
        name: null,
        city: null,
        portal_id:null,
        zip_code: null,
        country: null,
        state: null,
        id: null,
        code: null,
      },
      countries: Country,
      states: [],
      nameRules: [(value) => (!!value) || 'Company Name Is Required'],
      portalRules: [(value) => (!!value) || 'Site Name Is Required'],
      cityRules: [(value) => (!!value) || 'City Is Required'],
      zipRules: [(value) => (!!value) || 'Zip Is Required'],
      stateRules: [(value) => (!!value) || 'State Is Required'],
      countryRules: [(value) => (!!value) || 'Country Is Required'],
    };
  },
  watch: {
    reload() {
      this.formData = {
        name: null,
        city: null,
        portal_id:null,
        zip_code: null,
        country: null,
        state: null,
        id: null,
        code: null,
      }
    }
  },
  methods: {
    changeCountry($event) {
    this.formData.country= $event
      if ($event) {
        this.formData.state = null
        this.states = State[$event];
      }
    },
    keyPressNumbers(event) {
      this.formData.zip_code = event
      var charCode = (event.which) ? event.which : event.keyCode;
      // Only Numbers 0-9
      if ((charCode < 48 || charCode > 57)) {
        event.preventDefault();
        return false;
      } else {
        return true;
      }
    },
    reloadBoard() {
      localStorage.removeItem('ERPData')
      localStorage.removeItem('MarketPlaces')
      this.$emit('reload')
    },
    async save() {
      let validation = await this.$refs.form.validate()
      if (validation.valid) {
        console.log(this.user, 'mmmmmmmm')
        let userData = JSON.parse(localStorage.getItem('userDetails'))
        let companyData = JSON.parse(localStorage.getItem('companyDetails'))
        let data = {
          id: userData.id,
          first_time_user: 1
        }
        await UserService.update(data).then(res => {
          console.log(res)
        })
        this.formData.id = companyData.id
        this.formData.code = this.formData.name
        let erpData = JSON.parse(localStorage.getItem('ERPData'))
        let marketplaceData = JSON.parse(localStorage.getItem('MarketPlaces'))
        await ErpProfileService.create(erpData)
        for (let i = 0; i < marketplaceData.length; i++) {
          await MwsHeaderService.create(marketplaceData[i])
        }
        await CompanyService.update(this.formData).then(async res => {
          console.log(res, 'ressssssss')
          let token = localStorage.getItem('XTE_TOKEN_TMP')
          localStorage.setItem('XTE_TOKEN', token)
          localStorage.removeItem('XTE_TOKEN_TMP')
          localStorage.removeItem('ERPData')
          localStorage.removeItem('MarketPlaces')
          if (this.host.indexOf("localhost") >= 0) {
            // this.$router.push("/autoLogin?loginToken=" + $token);
            window.location = "http://" + this.host + "/"
          } else {
            window.location =
              "https://" +
              this.formData.name +
              "." +
              this.parts[this.parts.length - 2] +
              "." +
              this.parts[this.parts.length - 1] +
              "/accounts"
          }
        }
          , error => {
            console.log(error)
          })
      }
    }
  }
}
</script>

<style scoped>
.save {
  background-color: #3C5AAA;
  color: #fff;
  width: 90px;
}
</style>