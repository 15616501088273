<template>
  <div class="monaco-editor" ref="editorRef"></div>
</template>

<script setup>
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';
import { ref, onMounted, watch, defineProps, defineEmits } from 'vue';

const props = defineProps({
  modelValue: String, // Use the modelValue prop for v-model binding
  language: { type: String, default: 'plaintext' },
  theme: { type: String, default: 'vs-dark' },
});

const emits = defineEmits(['update:modelValue']); // Emit the correct event

const editorRef = ref(null);
let editorInstance;

onMounted(() => {
  editorInstance = monaco.editor.create(editorRef.value, {
    value: props.modelValue,
    language: props.language,
    theme: props.theme,
  });

  editorInstance.onDidChangeModelContent(() => {
    const newValue = editorInstance.getValue();
    emits('update:modelValue', newValue); // Emit the update:modelValue event
  });
});

watch(
  () => props.modelValue,
  (newValue) => {
    if (editorInstance) {
      editorInstance.setValue(newValue);
    }
  }
);

</script>

<style scoped>
.monaco-editor {
  width: 70vw;
  height: 50vh;
}
</style>
