<template>
  <PrimeTable
    :tableName="'Shipping Zones'"
    activity="Shipping Zone"
    :showTableHeader="true"
    :headers="column"
    :tableData="data"
    @rowAction="rowAction($event)"
    @addDialog="addDialog"
    :modelName="'shipping_zones'"
    @filterData="applyFilter($event)"
  ></PrimeTable>
   <v-dialog v-model="dialog" width="600px" persistent>
    <AddShipping
      @closeDialog="closeDialog"
      :type="type"
      :id="editDataId"
      @reload="getShippingZone()"
    />
  </v-dialog>
  <v-dialog v-model="confirmation" width="300px" persistent>
    <ConfirmationDialog
      @cancelled="cancelled()"
      @deleted="deleted"
      :content="confirmationContent"/>
  </v-dialog>
</template>

<script>
import ShippingZoneService from "@/services/admin/ShippingZoneService.js";
import AddShipping from "../AddAdmin/AddShippingZone.vue";
import ConfirmationDialog from "@/components/DialogConfirmation.vue";
export default {
  components: {
    AddShipping,
    ConfirmationDialog
  },
  data() {
    return {
      data: [],
      dialog: false,
      confirmationContent: null,
      confirmation: false,
      column: [
        { title: "Name", align: "start", sortable: false, key: "name" },
        { title: "Code", align: "start", key: "code" },
        { title: "Country", align: "start", key: "country" },
        { title: "State", align: "start", key: "state" },
        { title: "Modified by", align: "start", key: "users" },
        { title: "Modified on", align: "start", key: "modified" },
        { title: "Status", key: "active", sortable: false },
        { title: "Action", key: "actions", sortable: false },
      ],
      active: [
        { id: 1, name: "Active" },
        { id: 0, name: "Inactive" },
      ],
    };
  },
  created() {
    let $value = {};
    this.getShippingZone($value);
  },
  methods: {
    applyFilter($value){
       this.getShippingZone($value)
    },
    async getShippingZone($value) {
      await ShippingZoneService.findAll($value)
        .then((response) => {
          this.data = response;
          this.data.map((obj) => {
            return (obj.active = this.active.find(
              (type) => type.id == obj.active
            ).name);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    
       rowAction(e) {
      if (e.type == "edit") {
        this.dialog = true;
        this.type = e.type;
        this.editDataId = e.value.id;
      }
      if (e.type == "view") {
        this.dialog = true;
        this.type = e.type;
        this.editDataId = e.value.id;
      }
      if (e.type == "delete") {
        this.dialog = false;
        this.confirmation = true;
        this.confirmationContent = `Do you want to delete the Shipping Zone ${e.value.name} ?`;
        this.editDataId = e.value.id;
      }
      if (e.type == "select") {
        this.dialog = true;
        this.type = e.type;
        this.editDataId = e.value.id;
      }
    },

    addDialog() {
      this.dialog = true;
      this.type = "add";
      this.editDataId = null;
    },
    closeDialog() {
      this.dialog = false;
      this.getShippingZone();
    },
    async deleted() {
      this.confirmation = false;
      await ShippingZoneService.delete({ id: this.editDataId });
      this.getShippingZone();
    },
    cancelled() {
      this.editDataId = null;
      this.confirmation = false;
    },
  },
};
</script>