<template>
<v-from @submit.prevent="save()" ref="from">
    <v-card>
        <v-card-title class="dialog-Title">Update Tracking Number
            <v-btn density="comfortable" @click="closeDialog" elevation="0" icon="mdi-close" style="float: right"></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
           <v-container>
              <v-row>
                <v-col cols="12" class="pa-0 pl-2">
              <InputBox
                :data="formData.sku"
                @textData="($event) => {formData.sku = $event;}"
                :label="'Sku'"
                :rules="skuRules"
                :disabled="showReadOnly"
              />
            </v-col>
            <v-col cols="12" class="pa-0 pl-2">
              <InputBox
                :data="formData.tracking_no"
                @textData="($event) => {formData.tracking_no = $event;}"
                :label="'Tracking Number'"
                :rules="trackingRules"
                :disabled="showReadOnly"
              />
            </v-col>
              </v-row>
           </v-container>
        </v-card-text>
        <v-card-actions class="dialogfooter">
        <v-spacer></v-spacer>
        <v-btn class="mr-1 cancel" @click="closeDialog()">Cancel</v-btn>
        <v-btn class="save" type="submit">Save</v-btn>
      </v-card-actions>
    </v-card>
</v-from>
</template>
<script>
export default{
    data(){
        return{
          formData:{},
        }
    },
    methods:{
        closeDialog(){
            this.$emit("closeDialog",true)
        }
    }
}
</script>