<template>
  <div>
    <v-card flat class="rounded">
    <div class="Product-header pl-3 pt-2 ">Description <v-icon class="editicon" @click="dialogOpen('Description')"
        color="primary">mdi-pencil-circle</v-icon></div>
    <v-card flat class="mt-2 mb-3 mx-3 rounded-lg " v-if="styleData.style_description" color="#F4F5FF
">
      <!-- <v-btn class="editicon" @click="dialog1 = true" variant="text" size="small" icon="mdi-pencil"></v-btn> -->
      <v-card-text style="font-size:13px;font-weight:400">
        <p v-if="styleData.style_description" v-html="styleData.style_description"></p>
      </v-card-text>
    </v-card>
  </v-card>
  <v-card flat class="my-4"> 
    <v-col>
      <ProductMedia :productsData="styleData" />
    </v-col>
  </v-card>
   
    <v-card flat class="mt-2">
    <div class="Product-header rounded pt-2 pl-3">Variants <v-icon class="pb-1" color="primary"
        @click="openDialog()">mdi-plus-circle</v-icon></div>
    <v-col  class="pa-0 mb-3" cols="12" v-if="styleData.products_data && styleData.products_data.length > 0">
      <v-card flat v-for="item in styleData.products_data" :key="item.id" class="mt-2 mx-3 rounded-lg" color="#F4F5FF">
        <v-row class="pa-2">
          <v-col cols="12" class="pl-6" xs="1" sm="2" md="2" lg="2" xl="2">
            <v-img v-if="item.img" :src="item.img" width="50" height="50" />
            <ImageCard :imgUpload="true" v-if="!item.img && item.img_id" :documentId="item.img_id" width="50" height="50" />
          </v-col>
          <v-col cols="12" xs="1" sm="2" md="3" lg="3" xl="3"><span class="label1">{{ item.product_sku
          }}</span>
            <div>
              <v-chip size="small" class="pa-2 productDescriptionchip" label v-if="item.product_option1">{{
                item.product_option1 }}</v-chip>
            </div>
          </v-col>
          <v-col cols="12" xs="1" sm="2" md="3" lg="3" xl="3"><span class="label1">{{ item.available ?
            item.available : 0 }} Available</span>

            <div style="color: #636363;font-size:12px;font-weight:400px">
              In {{ item.locations ? item.locations : 0 }} Locations
            </div>

          </v-col>
          <v-col cols="12" xs="1" sm="2" md="3" lg="3" xl="3"><span class="label1">{{ item.marketplaces }}
              Marketplaces</span>
            <div style="color: #636363;font-size:12px;font-weight:400px">
              Last Sold:{{ item.last_sold_date ? moment(item.last_sold_date).format("MM/DD/YY") : "" }}
            </div>
          </v-col>
          <v-col cols="12" xs="1" sm="1" md="1" lg="1" xl="1">
            <div class="d-flex justify-end"><v-icon @click="editVariant(item)" color="primary">mdi-pencil-circle</v-icon>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-card>
<v-card flat class="my-4 rounded">
    <div class="Product-header pl-3 pt-2">Extended Fields <v-icon @click="dimensionOpen()"
        color="primary">mdi-pencil-circle</v-icon></div>
      <v-card flat class=" mx-3 mb-3 mt-2 rounded-lg" color="#F4F5FF">
        <v-row class="pa-4">
          <v-col cols="12" xs="1" sm="1" md="3" lg="3" xl="3" class="label1">Dimensions(L x M x H)
            <div><span class="label1">{{ styleData.products_data && styleData.products_data.length > 0 ?
              styleData.products_data[0].product_dimension : '' }}</span></div>
          </v-col>
          <v-col cols="12" xs="1" sm="1" md="2" lg="2" xl="2" class="label1">Volume
            <div><span style="font-size:12px;font-weight:400px">{{ styleData.products_data &&
              styleData.products_data.length > 0 ?
              getRoundOff(styleData.products_data[0].product_volume) : '' }} {{
    styleData.products_data && styleData.products_data.length > 0 ?
    styleData.products_data[0].product_volume_uom
    : '' }}</span></div>
          </v-col>

          <v-col cols="12" xs="1" sm="1" md="2" lg="2" xl="2" class="label1">Weight
            <div><span style="font-size:12px;font-weight:400px">{{ styleData.products_data &&
              styleData.products_data.length > 0 ?
              getRoundOff(styleData.products_data[0].product_weight) : '' }} {{
    styleData.products_data && styleData.products_data.length > 0 ?
    styleData.products_data[0].product_weight_uom
    : '' }}</span></div>
          </v-col>
          <v-col cols="12" xs="1" sm="2" md="3" lg="3" xl="3" class="label1">Pack
            <div><span style="font-size:12px;font-weight:400px">{{ styleData.products_data &&
              styleData.products_data.length > 0 ?
              styleData.products_data[0].pack : 0 }} Piece</span></div>
          </v-col>
        </v-row>
      </v-card>
  </v-card>
    <v-col class="pl-2 pt-0 pr-0">
      <div class="Product-header ml-2">Warranty <v-icon @click="dialogOpen('Warranty')"
          color="primary">mdi-pencil-circle</v-icon> </div>
      <v-card flat class="pa-5 pl-6 mt-2 rounded-lg" v-if="styleData.warranty" width="100%" color="#F4F5FF">
        <p class="text-medium-emphasis" style="font-size:13px;font-weight:400" v-if="styleData.warranty"
          v-html="styleData.warranty"></p>
      </v-card>
      <div class="Product-header mt-3 ml-2">Features <v-icon @click="dialogOpen('Features')"
          color="primary">mdi-pencil-circle</v-icon></div>
      <v-card flat class="pa-5 pl-6 mt-2 rounded-lg" v-if="styleData.features" width="100%" color="#F4F5FF">
        <p class="text-medium-emphasis" style="font-size:13px;font-weight:400" v-if="styleData.features"
          v-html="styleData.features"></p>
      </v-card>
    </v-col>
    <!-- <v-col cols="12">Warranty
            <div class="text-medium-emphasis">{{ styleData.warranty ? styleData.warranty : '' }}</div>
          </v-col> -->
    <!-- <v-col cols="12">Features
            <v-col class="text-medium-emphasis">
              {{ styleData.features ? styleData.features : '' }}
            </v-col>
          </v-col> -->
    <v-dialog v-model="dialog1" width="600">
      <v-card class="pb-3">
        <v-form @submit.prevent="save" ref="form">
          <v-card-title class="dialog-Title">{{ dialogTitle }}
            <v-btn density="comfortable" @click="dialog1 = false" elevation="0" icon="mdi-close"
              style="float: right;"></v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <QuilEditor v-if="dialogTitle == 'Description'" :data="formData.description"
              :editorStyle="'height:200px;overflow: auto'" @textData="($event) => {
                formData.description = $event;
              }
                "></QuilEditor>
            <QuilEditor v-if="dialogTitle == 'Warranty'" :data="formData.warranty"
              :editorStyle="'height:200px;overflow: auto'" @textData="($event) => {
                formData.warranty = $event;
              }
                "></QuilEditor>
            <QuilEditor v-if="dialogTitle == 'Features'" :data="formData.features"
              :editorStyle="'height:200px;overflow: auto'" @textData="($event) => {
                formData.features = $event;
              }
                "></QuilEditor>
          </v-card-text>
          <v-card-actions class="dialogfooter">
            <v-spacer></v-spacer>
            <v-btn class="mr-1 cancel" @click="dialog1 = false">Cancel</v-btn>
            <v-btn class="save" type="submit">Save</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- <v-dialog v-model="dialog" width="800px" persistent>
            <v-card class="rounded-lg">
              <AddProductSku @closeDialog="closeDialog" :type="type"/>
            </v-card>
    
  </v-dialog> -->
    <v-dialog v-model="dimensionDialog" width="600">
      <AddProductDimensionPack @closeDialog="closeDialog" :styleIdData="styleIdData" />
    </v-dialog>
  </div>
</template>

<script>
// import AddProductSku from "./AddProductSku.vue";
import ProductMedia from "./ProductMedia.vue";
import AddProductDimensionPack from "./AddProductDimensionPack.vue";
import StyleService from "@/services/catalogs/StyleService";
import moment from "moment";
import ImageCard from "@/components/ImageCard.vue";
import QuilEditor from "@/components/QuilEditor.vue";
export default {
  props: ['products'],
  components: {
    ProductMedia,
    QuilEditor,
    ImageCard,
    AddProductDimensionPack,
    // AddProductSku,
  },
  data() {
    return {
      dialog: false,
      dialog1: false,
      dimensionDialog: false,
      styleId: null,
      productDetailsData: {},
      productData: null,
      styleData: {},

      formData: {
        description: null,
        warranty: null,
        features: null,
      }
    };
  },
  watch: {
    products() {
      this.styleData = this.products
    }
  },
  created() {
    this.styleData = this.products
    console.log(this.styleData, 'styleData')
    this.styleId = this.$route.params.id
    if (this.styleId) {
      this.getStyle(this.styleId)
    }
  },
  methods: {
    moment,
    getRoundOff(value) {
      if (value) {
        return value.toFixed(2);
      }
      else {
        return null
      }
    },
    async getStyle(id) {
      let res = await StyleService.findAll({ id: id })
      this.formData = res[0]
    },
    closeDialog() {
      this.dialog = false;
      this.dimensionDialog = false
    },

    openDialog() {
      this.$router.push({
        path: "/addProductSku",
        query: { styleId: this.styleId },
      });
    },
    dimensionOpen() {
      this.dimensionDialog = true
      this.styleIdData = this.styleId
    },
    editVariant(item) {
      this.$router.push({
        path: "/addProductSku",
        query: { productId: item.product_id, styleId: this.styleId },
      });
    },
    dialogOpen(value) {
      this.dialogTitle = value
      console.log(this.dialogTitle, 'diolog')
      this.dialog1 = true
    },
    async save() {
      let validation = await this.$refs.form.validate()
      if (!validation.valid) {
        console.log(validation)
      } else {
        if (this.styleId) {
          let output = await StyleService.update(this.formData)
          console.log(output)
          this.close()
        }
      }
    },
    close() {
      this.dialog1 = false
      this.$emit('updateStyle')
      //this.$router.push("/productsku")
      // this.dialog=true;
    }
  }
};
</script>

<style>
.productDescriptionchip {
  --v-chip-size: 0.875rem;
  --v-chip-height: 25px;
  font-size: 0.875rem;
  padding: 0 12px;
  background-color: #F2DEFA;
  color: #636363;
}

.cardFontText {
  font-weight: 500;
}

.label1 {
  font-size: 13px;
  font-weight: 400;
  color: #000;
}
</style>