<template>
  <v-card flat style="overflow: unset !important;">
    <v-card-title class="dialog-Title"> {{ oldFilterName ? oldFilterName : 'Filters' }}
      <v-btn v-if="CustomFilterData && CustomFilterData.length > 0" @click="applyFilter()" class="apply-filter"
        variant="outlined">Apply</v-btn>
      <v-btn density="comfortable" @click="closeFilter" elevation="0" icon="mdi-close" style="float: right"></v-btn>
    </v-card-title>
    <v-divider class="border-opacity-50" />
    <v-card-text>
      <v-container>
        <!-- <v-col cols="4">
                            <v-form @submit.prevent="submit" ref="form">
                                <v-row>
                                    <v-col cols="12">
                                        <span class="ma-2 mb-2"><b>Save this Filter</b></span>

                                        <TextFieldComponent placeholder="Name" v-model="filterName"
                                             />
                                    </v-col>
                                    <v-col cols-12>
                                        <v-btn type="submit" class="save-filter" variant="outlined">Save</v-btn>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-col>
                        <v-divider style="margin-left: 15px;margin-top: -16px;margin-bottom: -11px;"
                            class="border-opacity-50" vertical /> -->
        <v-row v-for="(item, index) in CustomFilterData" :key="item">
          <v-col cols="12" v-if="item.field_type == 'tmp'" class="pb-0 pt-0">
            <v-row>
              <v-col :cols="3">
                <AutoComplete :placeholder="item.label" :data="item.column_name" :items="oldFilterData" :text="'column_name'" :value="'id'"
                  @textData="setSelectedTmp($event, item, index)">
                </AutoComplete>
                <span v-if="showFilterError" class="error-Code">Configuration Missing</span>
              </v-col>
              <v-col :cols="3"></v-col>
              <v-col :cols="3"></v-col>
              <v-tooltip location="top" text="Delete">
                <template v-slot:activator="{ props }">
                  <v-col v-bind="props" :cols="3" v-if="CustomFilterData.length > 1">
                    <v-btn @click="deleteFilter(index, item)"><v-icon size="25"
                        color="primary">mdi-delete-outline</v-icon></v-btn>
                  </v-col>
                </template>
              </v-tooltip>
            </v-row>
          </v-col>
          <v-col cols="12" v-if="item.field_type == 'input'" class="pb-0 pt-0">
            <v-row>
              <v-col :cols="3">
                <AutoComplete :disabled="item.isDisabled" :data="item.column_name" :items="oldFilterData"
                  :text="'column_name'" :value="'column_name'" @textData="setSelected1($event, item, index)">
                </AutoComplete>
              </v-col>
              <v-col :cols="3">
                <AutoComplete :data="item.key_type" :items="item.defaultOptions" :text="'name'" :placeholder="item.label"
                  :value="'value'" @textData="($event) => { item.key_type = $event; }">
                </AutoComplete>
                <!-- <Dropdown
                        v-model="item.key_type"
                        variant="outlined"
                        :items="defaultOptions"
                        :item-title="'name'"
                        :item-value="'value'"
                        :placeholder="item.label"
                      /> -->
              </v-col>
              <v-col :cols="3">
                <TextFieldComponent :placeholder="item.label" :data="item.defaults"
                  @textData="($event) => { item.defaults = $event; }">
                </TextFieldComponent>
              </v-col>
              <v-tooltip location="top" text="Delete">
                <template v-slot:activator="{ props }">
                  <v-col :cols="3" v-if="CustomFilterData.length > 1">
                    <v-btn v-bind="props" @click="deleteFilter(index, item)"><v-icon size="25"
                        color="primary">mdi-delete-outline</v-icon></v-btn>
                  </v-col>
                </template>
              </v-tooltip>
            </v-row>
          </v-col>
          <v-col cols="12" v-if="item.field_type == 'autocomplete'" class="pb-0 pt-0">
            <v-row>
              <v-col :cols="3">
                <AutoComplete :disabled="item.isDisabled" :data="item.column_name" :items="oldFilterData"
                  :text="'column_name'" :value="'column_name'" @textData="setSelected2($event, item, index)">
                </AutoComplete>
              </v-col>
              <v-col :cols="3">
                <AutoComplete :data="item.key_type" :items="item.defaultOptions" :text="'name'" placeholder="Option Type"
                  :value="'value'" @textData="($event) => { item.key_type = $event; }">
                </AutoComplete>
                <!-- <Dropdown
                        v-model="item.key_type"
                        variant="outlined"
                        :items="defaultOptions"
                        :item-title="'name'"
                        :item-value="'value'"
                        placeholder="Option Type"
                      /> -->
              </v-col>
              <v-col :cols="3">
                <AutoComplete :data="item.defaults" density="compact" variant="outlined" :items="item.source_data"
                  :text="item.text" :value="item.value" :placeholder="item.label" multiple
                  @textData="($event) => { item.defaults = $event; }"></AutoComplete>
              </v-col>
              <v-tooltip location="top" text="Delete">
                <template v-slot:activator="{ props }">
                  <v-col :cols="3" v-if="CustomFilterData.length > 1">
                    <v-btn v-bind="props" @click="deleteFilter(index, item)"><v-icon size="25"
                        color="primary">mdi-delete-outline</v-icon></v-btn>
                  </v-col>
                </template>
              </v-tooltip>
            </v-row>
          </v-col>
          <v-col cols="12" v-if="item.field_type == 'select'" class="pb-0 pt-0">
            <v-row>
              <v-col :cols="3">
                <AutoComplete :disabled="item.isDisabled" :data="item.column_name" :items="oldFilterData"
                  :text="'column_name'" :value="'column_name'" @textData="setSelected3($event, item, index)">
                </AutoComplete>
              </v-col>
              <v-col :cols="3">
                <AutoComplete :data="item.key_type" :items="item.defaultOptions" :text="'name'" placeholder="Option Type"
                  :value="'value'" @textData="($event) => { item.key_type = $event; }">
                </AutoComplete>
                <!-- <Dropdown
                        v-model="item.key_type"
                        variant="outlined"
                        :items="defaultOptions"
                        :item-title="'name'"
                        :item-value="'value'"
                        placeholder="Option Type"
                      /> -->
              </v-col>
              <v-col :cols="3">
                <AutoComplete :disabled="item.isDisabled" :data="item.key_type" :items="defaultOptions" :text="'name'"
                  :placeholder="item.label" :multiple="true" :value="'value'"
                  @textData="($event) => { item.key_type = $event; }"></AutoComplete>
                <!-- <Dropdown
                        v-model="item.defaults"
                        variant="outlined"
                        :items="item.source_data"
                        :item-title="item.text"
                        :item-value="item.value"
                        :placeholder="item.label"
                        :multiple="true"
                      /> -->
              </v-col>
              <v-tooltip location="top" text="Delete">
                <template v-slot:activator="{ props }">
                  <v-col :cols="3" v-if="CustomFilterData.length > 1">
                    <v-btn v-bind="props" @click="deleteFilter(index, item)"><v-icon size="25"
                        color="primary">mdi-delete-outline</v-icon></v-btn>
                  </v-col>
                </template>
              </v-tooltip>
            </v-row>
          </v-col>
          <v-col cols="12" v-if="item.field_type == 'enum'" class="pb-0 pt-0">
            <v-row>
              <v-col :cols="3">
                <AutoComplete :disabled="item.isDisabled" :data="item.column_name" :items="oldFilterData"
                  :text="'column_name'" :value="'column_name'" @textData="setSelected4($event, item, index)">
                </AutoComplete>
              </v-col>
              <v-col :cols="3">
                <AutoComplete :data="item.key_type" :items="item.defaultOptions" :text="'name'" placeholder="Option Type"
                  :value="'value'" @textData="($event) => { item.key_type = $event; }">
                </AutoComplete>
                <!-- <Dropdown
                        v-model="item.key_type"
                        variant="outlined"
                        :items="defaultOptions"
                        :item-title="'name'"
                        :item-value="'value'"
                        placeholder="Option Type"
                      /> -->
              </v-col>
              <v-col :cols="3">
                <AutoComplete v-if="item.source_data && item.source_data.length > 0" :data="item.defaults"
                  @textData="($event) => { item.defaults = $event; }" density="compact" variant="outlined"
                  :items="item.source_data" :text="'display_value'" :value="'data_value'" :placeholder="item.label">
                </AutoComplete>
              </v-col>
              <v-tooltip location="top" text="Delete">
                <template v-slot:activator="{ props }">
                  <v-col :cols="3" v-if="CustomFilterData.length > 1">
                    <v-btn v-bind="props" @click="deleteFilter(index, item)"><v-icon size="25"
                        color="primary">mdi-delete-outline</v-icon></v-btn>
                  </v-col>
                </template>
              </v-tooltip>
            </v-row>
          </v-col>
          <v-col cols="12" v-if="item.field_type == 'status'" class="pb-0 pt-0">
            <v-row>
              <v-col :cols="3">
                <AutoComplete :disabled="item.isDisabled" :data="item.column_name" :items="oldFilterData"
                  :text="'column_name'" :value="'column_name'" @textData="setSelected5($event, item, index)">
                </AutoComplete>
              </v-col>
              <v-col :cols="3">
                <AutoComplete :data="item.key_type" :items="item.defaultOptions" :text="'name'" placeholder="Option Type"
                  :value="'value'" @textData="($event) => { item.key_type = $event; }">
                </AutoComplete>
                <!-- <Dropdown
                        v-model="item.key_type"
                        variant="outlined"
                        :items="defaultOptions"
                        :item-title="'name'"
                        :item-value="'value'"
                        placeholder="Option Type"
                      /> -->
              </v-col>
              <v-col :cols="3">
                <AutoComplete :data="item.defaults" density="compact" variant="outlined" :items="statusData" :text="name"
                  :value="id" placeholder="Status" @textData="($event) => { item.defaults = $event; }"></AutoComplete>
              </v-col>
              <v-tooltip location="top" text="Delete">
                <template v-slot:activator="{ props }">
                  <v-col :cols="3" v-if="CustomFilterData.length > 1">
                    <v-btn v-bind="props" @click="deleteFilter(index, item)"><v-icon size="25"
                        color="primary">mdi-delete-outline</v-icon></v-btn>
                  </v-col>
                </template>
              </v-tooltip>
            </v-row>
          </v-col>
          <v-col cols="12" v-if="item.field_type == 'textArea'" class="pb-0 pt-0">
            <v-row>
              <v-col :cols="3">
                <AutoComplete :disabled="item.isDisabled" :data="item.column_name" :items="oldFilterData"
                  :text="'column_name'" :value="'column_name'" @textData="setSelected6($event, item, index)">
                </AutoComplete>
              </v-col>
              <v-col :cols="3">
                <AutoComplete :data="item.key_type" :items="item.defaultOptions" :text="'name'" :placeholder="item.label"
                  :value="'value'" @textData="($event) => { item.key_type = $event; }">
                </AutoComplete>
                <!-- <Dropdown
                        v-model="item.key_type"
                        variant="outlined"
                        :items="defaultOptions"
                        :item-title="'name'"
                        :item-value="'value'"
                        :placeholder="item.label"
                      /> -->
              </v-col>
              <v-col :cols="3">
                <v-textarea v-model="item.defaults" density="compact" class="elevation-0" variant="solo"
                  :label="item.label"></v-textarea>
              </v-col>
              <v-tooltip location="top" text="Delete">
                <template v-slot:activator="{ props }">
                  <v-col :cols="3" v-if="CustomFilterData.length > 1">
                    <v-btn v-bind="props" @click="deleteFilter(index, item)"><v-icon size="25"
                        color="primary">mdi-delete-outline</v-icon></v-btn>
                  </v-col>
                </template>
              </v-tooltip>
            </v-row>
          </v-col>
          <v-col cols="12" v-if="item.field_type == 'date'" class="pb-0 pt-0">
            <v-row>
              <v-col :cols="3">
                <AutoComplete :disabled="item.isDisabled" :data="item.column_name" :items="oldFilterData"
                  :text="'column_name'" :value="'column_name'" @textData="setSelected7($event, item, index)">
                </AutoComplete>
              </v-col>
              <v-col :cols="3">
                <AutoComplete :data="item.key_type" :items="item.defaultOptions" :text="'name'" :placeholder="item.label"
                  :value="'value'" @textData="($event) => { item.key_type = $event; }">
                </AutoComplete>
                <!-- <Dropdown
                        v-model="item.key_type"
                        variant="outlined"
                        :items="defaultOptions"
                        :item-title="'name'"
                        :item-value="'value'"
                        :placeholder="item.label"
                      /> -->
              </v-col>
              <v-col :cols="3">
                <DatePicker :data="item.defaults" :placeholder="item.label"
                  @textData="($event) => { item.defaults = $event; }">
                </DatePicker>
              </v-col>
              <v-col :cols="2" v-if="item.key_type == 'between'">
                <DatePicker :data="item.defaults1" :placeholder="item.label"
                  @textData="($event) => { item.defaults1 = $event; }">
                </DatePicker>
              </v-col>
              <v-tooltip location="top" text="Delete">
                <template v-slot:activator="{ props }">
                  <v-col :cols="1" v-if="CustomFilterData.length > 1">
                    <v-btn v-bind="props" @click="deleteFilter(index, item)"><v-icon size="25"
                        color="primary">mdi-delete-outline</v-icon></v-btn>
                  </v-col>
                </template>
              </v-tooltip>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="invalidFilter" class="mt-2 mb-2">
          <span style="color:red"> If you want to save, Please Enter the inputs for all rows</span>
        </v-row>
        <v-row v-if="CustomFilterData && CustomFilterData.length > 0">
          <v-col :cols="showSave ? 4 : 3" class="pt-0 pb-0">
            <!-- <v-tooltip location="top" text="Add Filter" >
                    <template v-slot:activator="{ props }">
                      <v-sheet
                        v-bind="props"
                        border
                        @click="addFilter()"
                        rounded
                        :height="45"
                        :width="45"
                        class="tableicon"
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-sheet>
                    </template>
                  </v-tooltip> -->
            <v-btn variant="outlined" color="primary" @click="addFilter()" prepend-icon="mdi-plus-circle-outline">Add
              Filter</v-btn>
          </v-col>
          <v-col cols="6" class="pt-0 pb-0">
            <v-form ref="form">
              <InputBox v-if="showSave" :placeholder="'Name'" :disabled="showReadOnly" :data="filterName"
                :rules="nameRule" @textData="($event) => { filterName = $event; }" />
            </v-form>
          </v-col>
          <v-col :cols="showSave ? 2 : 3" align="end" class="pt-0 pb-0">
            <v-btn variant="outlined" v-if="showCreate" class="cancel" @click="createFilter()">Create Filter</v-btn>
            <v-btn variant="outlined" color="primary" v-if="showSave" @click="submit">Save Filter</v-btn>
          </v-col>
        </v-row>
        <v-row v-if="!CustomFilterData || CustomFilterData.length == 0">
          There is no filters currently!!
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>
<script>
import listModelDetailService from "@/services/admin/ListModelDetailService";
import listModelService from "@/services/admin/ListModelService";
import listModelFilterService from "@/services/admin/ListModelFilterService";
import listModelFilterDetailService from "@/services/admin/ListModelFilterDetailService";
import TextFieldComponent from "@/components/InputBox.vue";
import lodash from "lodash";
import enumService from "@/services/admin/EnumService";
import moment from "moment";
import ListModelFilterDetailService from "@/services/admin/ListModelFilterDetailService";
import DatePicker from './DatePicker.vue';

export default {
  props: ["model", "drawerStatus", "filterId", 'oldFilterName', 'defaultFilters'],
  components: {
    DatePicker,
    TextFieldComponent,
  },
  data() {
    return {
      CustomFilterData: [],
      filterName: "",
      showSave: false,
      showCreate: true,
      invalidFilter: false,
      showFilterError: false,
      modelName: "",
      modelId: null,
      existFilters: [],
      nameRule: [(value) => !!value || "Name is Required"],
      drawer: true,
      statusData: [
        { id: 0, name: "InActive" },
        { id: 1, name: "Active" },
      ],
      defaultOptions: [
        {
          name: "Include",
          value: "include",
        },
        {
          name: "Exclude",
          value: "exclude",
        },
        {
          name: "Between",
          value: "between",
        },
        {
          name: "Like",
          value: "like",
        },
        {
          name: "Today",
          value: "today",
        },
      ],
      filterDetails: [],
      oldFilterId: null,
      oldFilterData: []
    };
  },
  watch: {
    drawerStatus() {
      this.drawer = this.drawerStatus;
    },
    // oldFilterName(){
    //     this.filterName = this.oldFilterName;
    // },
    filterId() {
      this.oldFilterId = this.filterId
    },
    model() {
      this.modelName = this.model;
      this.getListModelDetails();
    }

  },
  created() {
    if (this.filterId) {
      this.oldFilterId = this.filterId
    }
    // if(this.oldFilterName){
    //     this.filterName = this.oldFilterName;
    // }
    this.drawer = this.drawerStatus;
    if (this.model) {
      this.modelName = this.model;
      this.getListModelDetails();
    }


  },
  methods: {
    moment,
    addFilter() {
      if (this.CustomFilterData[this.CustomFilterData.length - 1].column_name && !this.showFilterError) {
        this.CustomFilterData.push({ column_name: null,label:'column_name',field_type: 'tmp', isDisabled: false, defaults: null, key_type: 'like' })
      }
    },
    async getFilterDetails() {
      this.filterDetails = await ListModelFilterDetailService.findAll({ model_filter_id: this.oldFilterId });
      console.log(this.filterDetails, 'model_filter_id')
    },
    async getListModelDetails() {
      let $model = await listModelService.findAll({ model: this.modelName });
      if (this.oldFilterId) {
        this.getFilterDetails()
      }
      this.modelId = $model.length > 0 ? $model[0].id : 0;
      if (this.modelId > 0) {
        //this.getExistFilters(this.modelId);
        let $modelDetails = await listModelDetailService.findAll({
          model_id: this.modelId,
        });
        $modelDetails = lodash.orderBy($modelDetails, ["sequence"], ["asc"]);
        this.getDropdownData($modelDetails);
      }
    },
    createFilter() {
      this.showSave = true
      this.showCreate = false
    },
    async getExistFilters($id) {
      let $exist = await listModelFilterService.findAll({ modelId: $id });
      this.existFilters = $exist;
    },
    closeFilter() {
      this.$emit("closeFilter");
    },
    async getDropdownData($data) {
      let filterData = [];
      for (let i = 0; i < $data.length; i++) {
        // let $index = this.defaultFilters.findIndex(k=>k.column_name==$data[i].column_name);
        if ($data[i].filter_required == 1) {
          $data[i].isDisabled = true;
          let $oldIndex = this.filterDetails.findIndex(k => k.column_name == $data[i].column_name);
          if ($oldIndex > -1) {
            $data[i].filterDetailId = this.filterDetails[$oldIndex].id;
          }

          let str = $data[i].label;
          $data[i].label = str.charAt(0).toUpperCase() + str.slice(1);
          if (
            $data[i].field_type == "autocomplete" ||
            $data[i].field_type == "select"
          ) {
            $data[i].defaultOptions = [
              {
                name: "Include",
                value: "include",
              },
              {
                name: "Exclude",
                value: "exclude",
              },
            ],
              $data[i].key_type = "include";
            $data[i].source_data = await this.getValues(
              $data[i].source,
              $data[i].database_name
            );
            if ($data[i].source_data.length == 1) {
              $data[i].defaults = $data[i].source_data[0][$data[i].value];
            }

            if ($oldIndex > -1) {
              $data[i].key_type = this.filterDetails[$oldIndex].expression ? this.filterDetails[$oldIndex].expression : 'include';
              $data[i].defaults = this.filterDetails[$oldIndex].defaults ? JSON.parse(this.filterDetails[$oldIndex].defaults) : [];
            } else {
              $data[i].defaults = [];
            }
          } else if ($data[i].field_type == "enum") {
            $data[i].defaultOptions = [
              {
                name: "Include",
                value: "include",
              },
              {
                name: "Exclude",
                value: "exclude",
              },
            ],
              $data[i].key_type = "include";
            $data[i].source_data = await this.getEnumValues($data[i].source);
            if ($oldIndex > -1) {
              $data[i].key_type = this.filterDetails[$oldIndex].expression ? this.filterDetails[$oldIndex].expression : 'include';
              $data[i].defaults = this.filterDetails[$oldIndex].defaults ? JSON.parse(this.filterDetails[$oldIndex].defaults) : [];
            } else {
              $data[i].defaults = [];
            }
          } else if ($data[i].field_type == "date") {
            $data[i].defaults = moment();
            $data[i].defaults1 = null;
            $data[i].key_type = "today";
            $data[i].defaultOptions = [
              {
                name: "Between",
                value: "between",
              },
              {
                name: "Today",
                value: "today",
              },
            ];
            if ($oldIndex > -1) {
              $data[i].key_type = this.filterDetails[$oldIndex].expression ? this.filterDetails[$oldIndex].expression : 'today';
              $data[i].defaults = this.filterDetails[$oldIndex].defaults;
            }
          } else {
            $data[i].key_type = "like";
            $data[i].defaultOptions = [{ name: "Like", value: "like", }]
            if ($data[i].data_type == 'float') {
              $data[i].defaultOptions.push({
                name: "Between",
                value: "between",
              })
            }
            if ($oldIndex > -1) {
              $data[i].key_type = this.filterDetails[$oldIndex].expression ? this.filterDetails[$oldIndex].expression : 'like';
              $data[i].defaults = this.filterDetails[$oldIndex].defaults;
            } else {
              $data[i].defaults = null;
            }
          }

          filterData.push($data[i]);
          // this.oldFilterData.push($data[i])
          console.log(filterData, 'filterDatavvfilterData')
        } else {
          if (
            $data[i].field_type == "autocomplete" ||
            $data[i].field_type == "select"
          ) {
            $data[i].defaults = [];
            $data[i].source_data = await this.getValues(
              $data[i].source,
              $data[i].database_name
            );
          } else if ($data[i].field_type == "enum") {
            $data[i].defaults = [];
            $data[i].source_data = await this.getEnumValues($data[i].source);
          } else {
            $data[i].defaults = null;
          }
          this.oldFilterData.push($data[i])
        }
      }
      console.log(this.oldFilterData, 'oldFilterDataoldFilterData')
      this.CustomFilterData = JSON.parse(JSON.stringify(filterData));
      console.log(this.CustomFilterData, 'http://localhost:3032/getTableData?tableName=mws_headers&db=mws')
    },
    setSelected1(value, item, index) {
      item.column_name = value
      this.setSelected(item, index)
    },
    async setSelectedTmp(value, item, index) {
      this.showFilterError = false
      console.log(value, item, index)
      let listmodelFilterData = await listModelDetailService.findAll({ id: value })
      if (listmodelFilterData && listmodelFilterData.length > 0) {
        console.log(listmodelFilterData, 'listmodelFilterData')
        if (listmodelFilterData[0].field_type && listmodelFilterData[0].field_type == 'input') {
          listmodelFilterData[0].key_type = 'like'
          listmodelFilterData[0].defaults = null
          listmodelFilterData[0].defaultOptions = [{ name: "Like", value: "like", }]
          if (listmodelFilterData[0].data_type == 'float') {
            listmodelFilterData[0].defaultOptions.push({
              name: "Between",
              value: "between",
            })
          }
          this.CustomFilterData.splice(index, 1)
          this.CustomFilterData.push(listmodelFilterData[0])
        }
        else if (listmodelFilterData[0].field_type && listmodelFilterData[0].field_type == 'date') {
          listmodelFilterData[0].key_type = 'today'
          listmodelFilterData[0].defaults = moment();
          listmodelFilterData[0].defaults1 = null;
          listmodelFilterData[0].defaultOptions = [
            {
              name: "Between",
              value: "between",
            },
            {
              name: "Today",
              value: "today",
            },
          ],
            this.CustomFilterData.splice(index, 1)
          this.CustomFilterData.push(listmodelFilterData[0])
        }
        else if (listmodelFilterData[0].field_type && (listmodelFilterData[0].field_type == 'autocomplete' ||
          listmodelFilterData[0].field_type == 'select') && listmodelFilterData[0].database_name && listmodelFilterData[0].source
          && listmodelFilterData[0].value && listmodelFilterData[0].text) {
          listmodelFilterData[0].key_type = 'include'
          listmodelFilterData[0].defaults = null
          listmodelFilterData[0].source_data = await this.getValues(
            listmodelFilterData[0].source,
            listmodelFilterData[0].database_name
          );
          listmodelFilterData[0].defaultOptions = [
            {
              name: "Include",
              value: "include",
            },
            {
              name: "Exclude",
              value: "exclude",
            },
          ],
            this.CustomFilterData.splice(index, 1)
          this.CustomFilterData.push(listmodelFilterData[0])
        }
        else if (listmodelFilterData[0].field_type && listmodelFilterData[0].field_type == 'enum' && listmodelFilterData[0].source) {
          listmodelFilterData[0].key_type = 'include'
          listmodelFilterData[0].defaults = null
          listmodelFilterData[0].source_data = await this.getEnumValues(
            listmodelFilterData[0].source
          );
          listmodelFilterData[0].defaultOptions = [
            {
              name: "Include",
              value: "include",
            },
            {
              name: "Exclude",
              value: "exclude",
            },
          ],
            this.CustomFilterData.splice(index, 1)
          this.CustomFilterData.push(listmodelFilterData[0])
        }
        else if (listmodelFilterData[0].field_type && listmodelFilterData[0].field_type == 'status') {
          listmodelFilterData[0].key_type = 'include'
          listmodelFilterData[0].defaults = null
          listmodelFilterData[0].defaultOptions = [
            {
              name: "Include",
              value: "include",
            },
            {
              name: "Exclude",
              value: "exclude",
            },
          ],
            this.CustomFilterData.splice(index, 1)
          this.CustomFilterData.push(listmodelFilterData[0])
        }
        else if (listmodelFilterData[0].field_type && listmodelFilterData[0].field_type == 'textarea') {
          listmodelFilterData[0].key_type = 'like'
          listmodelFilterData[0].defaults = null
          listmodelFilterData[0].defaultOptions = [
            {
              name: "Like",
              value: "like",
            },
          ],
            this.CustomFilterData.splice(index, 1)
          this.CustomFilterData.push(listmodelFilterData[0])
        }
        else {
          this.showFilterError = true
        }
      }
      else {
        this.showFilterError = true
      }
    },
    setSelected2(value, item, index) {
      item.column_name = value
      this.setSelected(item, index)
    },
    setSelected3(value, item, index) {
      item.column_name = value
      this.setSelected(item, index)
    },
    setSelected4(value, item, index) {
      item.column_name = value
      this.setSelected(item, index)
    },
    setSelected5(value, item, index) {
      item.column_name = value
      this.setSelected(item, index)
    },
    setSelected6(value, item, index) {
      item.column_name = value
      this.setSelected(item, index)
    },
    setSelected7(value, item, index) {
      item.column_name = value
      this.setSelected(item, index)
    },
    async setSelected(item, index) {
      this.CustomFilterData.splice(index, 1)
      this.showFilterError = false
      console.log(item, index)
      let $lindex = this.oldFilterData.findIndex(k => k.column_name == item.column_name);
      if ($lindex > -1) {
        let listmodelFilterData = await listModelDetailService.findAll({ id: this.oldFilterData[$lindex].id })
        if (listmodelFilterData && listmodelFilterData.length > 0) {
          console.log(listmodelFilterData, 'listmodelFilterData')
          if (listmodelFilterData[0].field_type && listmodelFilterData[0].field_type == 'input') {
            listmodelFilterData[0].key_type = 'like'
            listmodelFilterData[0].defaults = null
            listmodelFilterData[0].defaultOptions = [{ name: "Like", value: "like", }]
            if (listmodelFilterData[0].data_type == 'float') {
              listmodelFilterData[0].defaultOptions.push({
                name: "Between",
                value: "between",
              })
            }
            this.CustomFilterData.push(listmodelFilterData[0])
          }
          else if (listmodelFilterData[0].field_type && listmodelFilterData[0].field_type == 'date') {
            listmodelFilterData[0].key_type = 'today'
            listmodelFilterData[0].defaults = moment();
            listmodelFilterData[0].defaults1 = null;
            listmodelFilterData[0].defaultOptions = [
              {
                name: "Between",
                value: "between",
              },
              {
                name: "Today",
                value: "today",
              },
            ],
              this.CustomFilterData.push(listmodelFilterData[0])
          }
          else if (listmodelFilterData[0].field_type && (listmodelFilterData[0].field_type == 'autocomplete' ||
            listmodelFilterData[0].field_type == 'select') && listmodelFilterData[0].database_name && listmodelFilterData[0].source
            && listmodelFilterData[0].value && listmodelFilterData[0].text) {
            listmodelFilterData[0].key_type = 'include'
            listmodelFilterData[0].defaults = null
            listmodelFilterData[0].source_data = await this.getValues(
              listmodelFilterData[0].source,
              listmodelFilterData[0].database_name
            );
            listmodelFilterData[0].defaultOptions = [
              {
                name: "Include",
                value: "include",
              },
              {
                name: "Exclude",
                value: "exclude",
              },
            ],
              this.CustomFilterData.push(listmodelFilterData[0])
          }
          else if (listmodelFilterData[0].field_type && listmodelFilterData[0].field_type == 'enum' && listmodelFilterData[0].source) {
            listmodelFilterData[0].key_type = 'include'
            listmodelFilterData[0].defaults = null
            listmodelFilterData[0].source_data = await this.getEnumValues(
              listmodelFilterData[0].source
            );
            listmodelFilterData[0].defaultOptions = [
              {
                name: "Include",
                value: "include",
              },
              {
                name: "Exclude",
                value: "exclude",
              },
            ],
              this.CustomFilterData.push(listmodelFilterData[0])
          }
          else if (listmodelFilterData[0].field_type && listmodelFilterData[0].field_type == 'status') {
            listmodelFilterData[0].key_type = 'include'
            listmodelFilterData[0].defaults = null
            listmodelFilterData[0].defaultOptions = [
              {
                name: "Include",
                value: "include",
              },
              {
                name: "Exclude",
                value: "exclude",
              },
            ],
              this.CustomFilterData.push(listmodelFilterData[0])
          }
          else if (listmodelFilterData[0].field_type && listmodelFilterData[0].field_type == 'teaxarea') {
            listmodelFilterData[0].key_type = 'like'
            listmodelFilterData[0].defaults = null
            listmodelFilterData[0].defaultOptions = [
              {
                name: "Like",
                value: "like",
              },
            ],
              this.CustomFilterData.push(listmodelFilterData[0])
          }
          else {
            this.CustomFilterData.push({ column_name: item.column_name, field_type: 'tmp', isDisabled: false, defaults: null, key_type: 'like' })
            this.showFilterError = true
          }
        }
        else {
          this.CustomFilterData.push({ column_name: item.column_name, field_type: 'tmp', isDisabled: false, defaults: null, key_type: 'like' })
          this.showFilterError = true
        }
      }
      else {
        this.CustomFilterData.push({ column_name: item.column_name, field_type: 'tmp', isDisabled: false, defaults: null, key_type: 'like' })
        this.showFilterError = true
      }
    },
    // setSelected($item, $index) {
    //   let $lindex = this.oldFilterData.findIndex(k => k.column_name == $item.column_name);
    //   if ($lindex > -1) {
    //     console.log(this.oldFilterData[$lindex], 'this.oldFilterData[$lindex]')
    //     this.CustomFilterData.splice($index, 1)
    //     alert(this.oldFilterData[$lindex].field_type)
    //     // this.oldFilterData[$lindex].field_type = this.oldFilterData[$lindex].field_type ? this.oldFilterData[$lindex].field_type : 'tmp'
    //     alert(this.oldFilterData[$lindex].field_type)
    //     this.CustomFilterData.push(this.oldFilterData[$lindex])
    //     this.CustomFilterData = [...this.CustomFilterData];
    //     console.log(this.CustomFilterData, 'this.oldFilterData[$lindex]')
    //   }
    // },
    async getValues($source, $db) {
      let res = await listModelService.getTableData({ tableName: $source, db: $db });
      return res ? res : [];
    },
    async getEnumValues(source) {
      if (source == 'usertype' || source == 'varianttype' || source == 'ERP'
        || source == 'integrationtype' || source == 'domain' || source == 'servicelevel'
        || source == 'servicetype' || source == 'currencies' || source == 'fieldtypes'
        || source == 'databases' || source == 'marketplaces' || source == 'endpoint') {
        let res = await enumService.findAll({ domain: source, company_id: 0 })
        return res ? res : [];
      }
      else {
        let res = await enumService.findAll({ domain: source })
        return res ? res : [];
      }
    },
    async applyFilter() {
      let $CustomFilterData = this.CustomFilterData.filter(f => (f.defaults != null && f.defaults != ''))
      let $obj = {};
      if ($CustomFilterData && $CustomFilterData.length > 0) {
        $CustomFilterData.forEach((ele) => {
          if (ele && ele.defaults && ele.column_name) {
            if (Array.isArray(ele.defaults)) {
              if (ele.defaults.length > 0) {
                if (ele.key_type == 'exclude') {
                  ele.defaults.unshift('!');
                }
                $obj[ele.column_name] =
                  ele.data_type.includes("varchar") ||
                    ele.data_type.includes("string")
                    ? `${ele.defaults}`
                    : ele.defaults;
              }
            } else {
              if (ele.key_type == 'like') {
                $obj[ele.column_name] = ele.data_type.includes("varchar") ||
                  ele.data_type.includes("string") ? `%${ele.defaults}%` : `'%${ele.defaults}%'`
                ele.defaults = $obj[ele.column_name]
              }
              else if (ele.key_type == 'between') {
                $obj[ele.column_name] = `${moment(ele.defaults).format('YYYY/MM/DD')}__${moment(ele.defaults1).format('YYYY/MM/DD')}`
              }
              else {
                $obj[ele.column_name] =
                  ele.data_type.includes("varchar") ||
                    ele.data_type.includes("string")
                    ? `${ele.defaults}` :
                    ele.data_type.includes("date") ? moment(ele.defaults).format('YYYY/MM/DD')
                      : ele.defaults;
              }
            }
            return $obj;
          }
        });
        console.log($obj, 'CustomFilterDataCustomFilterData', $CustomFilterData)
        this.$emit("filterClosed", $obj);
        this.drawer = false;
      }
    },
    async submit() {
      let $obj = {};
      let $CustomFilterData = this.CustomFilterData.filter(f => f.defaults != null && f.defaults != '')
      $CustomFilterData.forEach((ele) => {
        if (ele && ele.defaults && ele.column_name) {
          if (Array.isArray(ele.defaults)) {
            if (ele.defaults.length > 0) {
              if (ele.key_type == 'exclude') {
                ele.defaults.unshift('!');
              }
              $obj[ele.column_name] =
                ele.data_type.includes("varchar") ||
                  ele.data_type.includes("string")
                  ? `${ele.defaults}`
                  : ele.defaults;
              ele.defaults = $obj[ele.column_name]
            }
          } else {
            if (ele.key_type == 'like') {
              $obj[ele.column_name] = ele.data_type.includes("varchar") ||
                ele.data_type.includes("string") ? `%${ele.defaults}%` : `'%${ele.defaults}%'`
              ele.defaults = $obj[ele.column_name]
            }
            else if (ele.key_type == 'between') {
              $obj[ele.column_name] = `${moment(ele.defaults).format('YYYY/MM/DD')}__${moment(ele.defaults1).format('YYYY/MM/DD')}`
              ele.defaults = $obj[ele.column_name]
            }
            else {
              $obj[ele.column_name] =
                ele.data_type.includes("varchar") ||
                  ele.data_type.includes("string")
                  ? `${ele.defaults}` :
                  ele.data_type.includes("date") ? moment(ele.defaults).format('YYYY/MM/DD')
                    : ele.defaults;
              ele.defaults = $obj[ele.column_name]
            }
          }
          return $obj;
        }
      });
      // let $validate = this.CustomFilterData.filter(k=>k.column_name==null || k.defaults==null || k.defaults.length==0);

      let validation = await this.$refs.form.validate();
      if (!validation.valid) {
        console.log(validation);
      } else if (Object.keys($obj).length == 0) {
        this.invalidFilter = true;
      }
      else {
        if ($CustomFilterData && $CustomFilterData.length > 0) {
          let $data = {};
          $data.name = this.filterName; //this.filterName
          $data.model_id = this.modelId;
          let $filter = {}
          if (this.filterId) {
            $filter = await listModelFilterService.update($data);
          } else {
            $filter = await listModelFilterService.create($data);
          }

          $CustomFilterData.forEach(async (element) => {
            let $obj = {};
            $obj.model_filter_id = $filter.data.data.id;
            $obj.column_name = element.column_name;
            $obj.expression = element.key_type;
            $obj.defaults = typeof element.defaults == 'object' ? JSON.stringify(element.defaults) : element.defaults;
            if (element.filterDetailId) {
              $obj.id = element.filterDetailId;
              await listModelFilterDetailService.update($obj);
            } else {
              await listModelFilterDetailService.create($obj);
            }

          });
          this.applyFilter();
        }
      }
      // this.getExistFilters(this.modelId)
    },
    async deleteFilter(i, item) {
      if (item.filter_required == 1) {
        this.CustomFilterData[i].defaults = null;
      }
      else {
        this.CustomFilterData.splice(i, 1)
      }
      this.CustomFilterData = [...this.CustomFilterData]
      // await listModelFilterService.delete({id:this.oldFilterId});
      // this.oldFilterId=null;
      // this.filterName=null;
      // this.filterDetails=[];
      // this.getListModelDetails();
    }
  },
};
</script>
<style scoped>
.apply-filter {
  font-size: 15px;
  position: absolute;
  background: #3c5aaa;
  color: white;
  top: 10px;
  right: 60px;
  cursor: pointer;
}

.error-Code {
  color: rgb(176, 0, 32);
  font-size: 12px;
  position: absolute;
  bottom: 53px;
  left: 50px;
}

.save-filter {
  font-size: 15px;
  color: white;
  width: 100%;
  text-align: center;
  background: #3c5aaa;
}
</style>