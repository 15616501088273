<template>
    <v-container>
          <v-card-text class="fontstyle">
        <v-row no-gutters dense>
          <v-col lg="4" xl="4" md="4" sm="6" cols="12" >
            <v-row no-gutters dense :class="$mq=='mobile' ? 'text-center' : 'text-left'">
                 <div :style="$mq=='mobile' ? 'margin:auto' : ''">
              <img :src="require('@/assets/tibura.svg')"  />
                     </div>
              <v-col xl="12" lg="12" md="12" sm="12" cols="12" style="margin-top:20px;">
                   <span style="line-height: 25px;" class="abttxt">TIBURA 0ffers-XTEND,an E-commerce solution with instant connectivity to world's leading marketplaces.</span>
              </v-col>
               <v-col xl="12" lg="12" md="12" sm="12" cols="12" style="margin-top:20px;">
                   <span >contact@tibura.com</span>
              </v-col>
               <v-col xl="12" lg="12" md="12" sm="12" cols="12" style="margin-top:10px;">
                   <span >www.tibura.com</span>
              </v-col>
             
            </v-row>
          </v-col>
          <v-col lg="3" xl="3" md="3" sm="6" xs="12" cols="12" class="text-center">
            <v-row no-gutters dense class="countrystyle country" style="margin-left:70px">
                 <v-container justify-center>
              <v-img :src="require('@/assets/sgflag.png')" width="32px" height="32px" style="position:center;margin:auto"/>
                     </v-container>
              <v-col xl="12" lg="12" md="12" sm="12" cols="12" style="margin-top:10px;">
                   <span style="line-height: 30px;">30 Cecil street,#19-08<br/>Prudential towers<br/>Singapore-049712</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col lg="3" xl="3" md="3" sm="6" xs="12" cols="12" class="text-center">
            <v-row no-gutters dense class="countrystyle country contry1" style="margin-left:70px;">
                 <v-container justify-center>
              <v-img :src="require('@/assets/indflag.svg')" height="32px" width="32px" style="position:center;margin:auto" />
                 </v-container>
              <v-col xl="12" lg="12" md="12" sm="12" cols="12" style="margin-top:10px;">
                   <span style="line-height: 30px;">11/15,Anna Street,<br/>Chitlapakam,Chennai-74<br/>India</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col lg="2" xl="2" md="2" sm="6" cols="12" class="text-center">
            <v-row no-gutters dense class="countrystyle country1">
              <v-col xl="12" lg="12" md="12" sm="12" cols="12" class="text-center" style="margin-top:30px;">
                   <span>Connect Via Social</span>
              </v-col>
              <v-col cols="12" class="mt-2">
                 <v-icon large >fab fa-facebook</v-icon>&nbsp;&nbsp; 
                 <v-icon large class="pl-4">fab fa-twitter</v-icon>
                 <v-icon large class="pl-4">fab fa-instagram</v-icon>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>       
                   <!-- <v-col xl="12" lg="12" md="12" sm="12" cols="12" style="margin-top:30px;" :class="$mq=='mobile' ? 'text-center' : 'text-left'">
                   <span>&copy Tibura 2020.All Right Reserved</span>
              </v-col> -->
        </v-row>
          </v-card-text>
    </v-container>
</template>
<style scoped>


</style>