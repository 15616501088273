<template>
  <v-card>
    <v-form @submit.prevent="save" ref="form">
      <v-card-title class="dialog-Title"
        >{{
          type == "edit"
            ? "Edit"
            : type == "view"
            ? "View"
            : type == "add"
            ? "Add"
            : type == "select"
            ? "View"
            : ""
        }}
        Enum
        <v-btn
          density="comfortable"
          @click="closeDialog"
          elevation="0"
          icon="mdi-close"
          style="float: right"
        ></v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" class="pa-0">
              <InputBox
              :data="enumData.domain"
                 @textData="($event) => {enumData.domain = $event;}"
                :disabled="showReadonly"
                :rules="domainRules"
                :label="'Domain'"
              />
            </v-col>
            <v-col cols="12" class="pa-0">
              <InputBox
                :data="enumData.display_value"
                @textData="dispalayKeyup($event)"
                :disabled="showReadonly"
                :rules="displayRules"
                :label="'Display Name'"
              />
            </v-col>
            <v-col cols="12" class="pa-0">
              <InputBox
                :data="enumData.data_value"
                @textData="($event) => {enumData.data_value = $event;}"
                :disabled="showReadonly"
                :rules="dataRules"
                :label="'Data Value'"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="dialogfooter" v-if="!showReadonly">
        <v-spacer></v-spacer>
        <v-btn class="mr-1 cancel" @click="closeDialog">Cancel</v-btn>
        <v-btn class="save" type="submit">Save</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import InputBox from "@/components/InputBox.vue";
import EnumService from "@/services/admin/EnumService";
export default {
  props: ["type", "id", "showReadonly"],
  components: {
    InputBox,
  },
  data() {
    return {
      enumData: {
        domain: null,
        display_value: null,
        data_value: null,
      },
      domainRules: [(value) => !!value || "Domain Is Required"],
      displayRules: [(value) => !!value || "Display Name Is Required"],
      dataRules: [(value) => !!value || "Data Value Is Required"],
    };
  },
  created() {
    if (this.id && this.type != "add") {
      this.getEnum();
    }
  },
  methods: {
    async getEnum() {
      let res = await EnumService.findOne({ id: this.id });
      this.enumData = res[0];
    },
    dispalayKeyup($event) {
      this.enumData.display_value = $event
      if(this.enumData.display_value){
      this.enumData.data_value = this.enumData.display_value.toLowerCase();
      }
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
    async save() {
      let validation = await this.$refs.form.validate();
      if (!validation.valid) {
        console.log(validation);
      } else {
        delete this.enumData.users;
        if (this.enumData && this.enumData.id) {
          let output = await EnumService.update(this.enumData);
          console.log(output);
          this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
          this.closeDialog();
        } else {
          try {
            let output = await EnumService.create(this.enumData);
            console.log(output, "output", this.enumData);
            this.$store.dispatch("setSnackbar", { text: "Saved Successfully" });
            this.closeDialog();
          } catch (e) {
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "Something Went Wrong",
            });
          }
        }
      }
    },
  },
};
</script>

<style>
</style>