<template>
  <PrimeTable :tableName="tableName" :activity="tableName" :showTableHeader="true" :headers="column" :tableData="data" @rowAction="rowAction"
    @addDialog="addDialog" :modelName="'channels'" @filterData="applyFilter($event)"></PrimeTable>
  <v-dialog v-model="confirmation" width="400px" persistent>
    <DialogConfirmation @cancelled="cancelled()" @deleted="deleted" :content="confirmationContent" />
  </v-dialog>
</template>
<script>
import PrimeTable from "@/components/PrimeTable.vue";
import ChannelService from "@/services/admin/ChannelService";
import DialogConfirmation from "@/components/DialogConfirmation.vue";
export default {
  components: {
    PrimeTable,
    DialogConfirmation,
  },
  data() {
    return {
      tableName: "",
      channelType: "",
      confirmation: false,
      data: [],
      column: [
        { title: "Code", align: "start", key: "code" },
        { title: "Name", align: "start", key: "name" },
        { title: "Modified By", align: "start", key: "users" },
        { title: "Modified On", align: "start", key: "modified" },
        { title: "Status", key: "active", sortable: false },
        { title: "Action", key: "actions", sortable: false },
      ],
      active: [
        { id: 1, name: "Active" },
        { id: 0, name: "Inactive" },
      ],
    };
  },
  created() {
    let $value = {};
    this.getRoute($value);
  },
  methods: {
    applyFilter($value){
      this.getRoute($value)
    },
    getRoute($value) {
      this.tableName = this.$route.name;
      this.channelType = this.$route.path.substring(
        this.$route.path.lastIndexOf("/") + 1,
        this.$route.path.length
      );
      if (this.channelType) {
        this.getChannel(this.channelType,$value);
      }
    },
    async getChannel(type,$value) {
      let $result = await ChannelService.findAll({ type, ...$value});
      this.data = $result;
      if ($result && $result.length > 0) {
        this.data.map((obj) => {
          return (obj.active = this.active.find(
            (type) => type.id == obj.active
          ).name);
        });
      }
    },
    async deleted() {
      this.confirmation = false;
      await ChannelService.delete({ id: this.editDataId });
      this.getChannel();
    },
    cancelled() {
      this.editDataId = null;
      this.confirmation = false;
    },
    rowAction(e) {
      if (e.type == "edit") {
        if (this.tableName == "Email") {
          this.$router.push({
            path: "/channel/email/addEmail",
            query: { id: e.value.id, type: "edit" },
          });
        }
        if (this.tableName == "SMS") {
          this.$router.push({
            path: "/channel/sms/addSMS",
            query: { id: e.value.id, type: "edit" },
          });
        }
        if (this.tableName == "Whatsapp") {
          this.$router.push({
            path: "/channel/whatsapp/addWhatsapp",
            query: { id: e.value.id, type: "edit" },
          });
        }
        if (this.tableName == "Notification") {
          this.$router.push({
            path: "/channel/notification/addNotification",
            query: { id: e.value.id, type: "edit" },
          });
        }
        if (this.tableName == "Api") {
          this.$router.push({
            path: "/channel/api/addApi",
            query: { id: e.value.id, type: "edit" },
          });
        }
        if (this.tableName == "Upload Document") {
          this.$router.push({
            path: "/channel/uploadDocument/addUploadDocument",
            query: { id: e.value.id, type: "edit" },
          });
        }
        if (this.tableName == "GL Integration") {
          this.$router.push({
            path: "/channel/glIntegration/addGlIntegration",
            query: { id: e.value.id, type: "edit" },
          });
        }
        if (this.tableName == "Form Builder") {
          this.$router.push({
            path: "/channel/formBuilder/addFormBuilder",
            query: { id: e.value.id, type: "edit" },
          });
        }
        if (this.tableName == "Nextflo Internal Api") {
          this.$router.push({
            path: "/channel/nextfloInternalApi/addNextfloInternalApi",
            query: { id: e.value.id, type: "edit" },
          });
        }
      }
      if (e.type == "view") {
        if (this.tableName == "Email") {
          this.$router.push({
            path: "/channel/email/addEmail",
            query: { id: e.value.id, type: "view" },
          });
        }
        if (this.tableName == "SMS") {
          this.$router.push({
            path: "/channel/sms/addSMS",
            query: { id: e.value.id, type: "view" },
          });
        }
        if (this.tableName == "Whatsapp") {
          this.$router.push({
            path: "/channel/whatsapp/addWhatsapp",
            query: { id: e.value.id, type: "view" },
          });
        }
        if (this.tableName == "Notification") {
          this.$router.push({
            path: "/channel/notification/addNotification",
            query: { id: e.value.id, type: "view" },
          });
        }
        if (this.tableName == "Api") {
          this.$router.push({
            path: "/channel/api/addApi",
            query: { id: e.value.id, type: "view" },
          });
        }
        if (this.tableName == "Upload Document") {
          this.$router.push({
            path: "/channel/uploadDocument/addUploadDocument",
            query: { id: e.value.id, type: "view" },
          });
        }
        if (this.tableName == "GL Integration") {
          this.$router.push({
            path: "/channel/glIntegration/addGlIntegration",
            query: { id: e.value.id, type: "view" },
          });
        }
        if (this.tableName == "Form Builder") {
          this.$router.push({
            path: "/channel/formBuilder/addFormBuilder",
            query: { id: e.value.id, type: "view" },
          });
        }
        if (this.tableName == "Nextflo Internal Api") {
          this.$router.push({
            path: "/channel/nextfloInternalApi/addNextfloInternalApi",
            query: { id: e.value.id, type: "view" },
          });
        }
      }
      if (e.type == "delete") {
        this.confirmation = true;
        this.confirmationContent = `Do you want to delete the Channel ${e.value.name}`;
        this.editDataId = e.value.id;
      }
      if (e.type == "select") {
        if (this.tableName == "Email") {
          this.$router.push({
            path: "/channel/email/addEmail",
            query: { id: e.value.id, type: "view" },
          });
        }
        if (this.tableName == "SMS") {
          this.$router.push({
            path: "/channel/sms/addSMS",
            query: { id: e.value.id, type: "view" },
          });
        }
        if (this.tableName == "Whatsapp") {
          this.$router.push({
            path: "/channel/whatsapp/addWhatsapp",
            query: { id: e.value.id, type: "view" },
          });
        }
        if (this.tableName == "Notification") {
          this.$router.push({
            path: "/channel/notification/addNotification",
            query: { id: e.value.id, type: "view" },
          });
        }
        if (this.tableName == "Api") {
          this.$router.push({
            path: "/channel/api/addApi",
            query: { id: e.value.id, type: "view" },
          });
        }
        if (this.tableName == "Upload Document") {
          this.$router.push({
            path: "/channel/uploadDocument/addUploadDocument",
            query: { id: e.value.id, type: "view" },
          });
        }
        if (this.tableName == "GL Integration") {
          this.$router.push({
            path: "/channel/glIntegration/addGlIntegration",
            query: { id: e.value.id, type: "view" },
          });
        }
        if (this.tableName == "Form Builder") {
          this.$router.push({
            path: "/channel/formBuilder/addFormBuilder",
            query: { id: e.value.id, type: "view" },
          });
        }
        if (this.tableName == "Nextflo Internal Api") {
          this.$router.push({
            path: "/channel/nextfloInternalApi/addNextfloInternalApi",
            query: { id: e.value.id, type: "view" },
          });
        }
      }
    },
    addDialog() {
      if (this.tableName == "Email") {
        this.$router.push("/channel/email/addEmail");
      }
      if (this.tableName == "SMS") {
        this.$router.push("/channel/sms/addSMS");
      }
      if (this.tableName == "Whatsapp") {
        this.$router.push("/channel/whatsapp/addWhatsapp");
      }
      if (this.tableName == "Notification") {
        this.$router.push("/channel/notification/addNotification");
      }
      if (this.tableName == "Api") {
        this.$router.push("/channel/api/addApi");
      }
      if (this.tableName == "Upload Document") {
        this.$router.push("/channel/uploadDocument/addUploadDocument");
      }
      if (this.tableName == "GL Integration") {
        this.$router.push("/channel/glIntegration/addGlIntegration");
      }
      if (this.tableName == "Form Builder") {
        this.$router.push("/channel/formBuilder/addFormBuilder");
      }
      if (this.tableName == "Nextflo Internal Api") {
        this.$router.push("/channel/nextfloInternalApi/addNextfloInternalApi");
      }
    },
  },
};
</script>