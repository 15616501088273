<template>
  <v-row no-gutters>
    <v-col cols="12" sm="7" md="8" lg="9" xs="12" class="pr-3">
      <v-col class="pb-0"> 
        <DashBoardCardData @timePeriod="timevalue" @cardDetails="setCardData" @tableDetails="setTableData"></DashBoardCardData>
      </v-col>
      <v-col cols="12" class="pt-0 pb-0">
        <ProductData></ProductData>
      </v-col>
    </v-col>
    <v-col cols="12" sm="5" md="4" lg="3" xs="12" class="pr-2 mt-4">
      <RightContent :cardData="cardData"></RightContent>
    </v-col>
  </v-row>
  <v-col cols="12" class="pt-0">
        <DashBoardTabs :time="choose_date" :tableData="tableData" :mwsHeaders="mwsHeaders"></DashBoardTabs>
      </v-col>
</template>

<script>
import DashBoardCardData from '../DashBoard/DashBoardCardData.vue';
import ProductData from "../DashBoard/ProductData.vue";
import RightContent from "../DashBoard/RightContent.vue";
import DashBoardTabs from "../DashBoard/DashBoardTabs.vue";
export default {
  components: {
    DashBoardCardData,
    ProductData,
    RightContent,
    DashBoardTabs,
  },
  data() {
    return {
      choose_date: "",
      cardData:[],
      tableData:[],
      mwsHeaders:[]
    };
  },
  methods: {
    timevalue($value) {
      this.choose_date = $value;
    },
    setCardData($event){
      this.cardData = $event;
    },
    setTableData($event){
      this.tableData = $event;
    },
    setHeader($event){
      this.mwsHeaders = $event;
    }
  },
};
</script>
