<template>
  <!-- <v-label class="text-body-2 font-weight-bold ml-1">{{ label }}</v-label> -->
     <v-select 
     class="text-Field"
  density="compact"
  variant="outlined"
  :disabled="disabled"
  :placeholder="label"
  :label="label"
  :rules="rules"
  :multiple="multiple"
></v-select>
</template>
<script>
export default {
  props:['label','rules','disabled',"multiple"]
}
</script>