<template>
  <div class="pa-4">
    <v-row class="pa-1">
      <v-col cols="9" class="text-h6 pl-4"> Add Product </v-col>

      <v-col cols="3" class="dialogfooter d-flex justify-end pr-4">
        <v-btn class="mr-2 cancel" @click="this.$router.go(-1)">Cancel</v-btn>
        <v-btn class="save" @click="save()">Save</v-btn>
      </v-col>
    </v-row>

    <v-row class="pa-2">
      <v-col cols="12" sm="12" md="8" lg="8">
        <AddProductDetails @productDetails="productDetails" :validation="validation1"
          @validCheck="updateValid1($event)" :productData="styleEditData"/>
        <AddProductPricing class="mt-4" @pricingData="pricingData" :validation="validation1"
          @validCheck="updateValid2($event)" :productData="styleEditData"/>
          <AddImage class="mt-4" @imageChanged="imageChanged($event)"></AddImage>
        <AddProductVariants class="mt-4" @productVarient="productVarient" :validation="validation1"
          @validCheck="variantsValidation($event)" :productData="styleEditData"/>
        <AddProductPricingPerRegion v-if="showPricePerRegion" class="mt-4" @pricingRegionData="pricingRegionData" :productData="styleEditData"/>
      </v-col>
      <v-col>
        <AddProductHeirarchy @productHeirarchy="productHeirarchy" :validation="validation1"
          @validCheck="updateValid3($event)" :productData="styleEditData"/>
        <AddProductOrganisationHeirarchy class="mt-4" @organisationData="organisationData" :validation="validation1"
          @validCheck="updateValid4($event)" :productData="styleEditData"/>
        <AddProductDimensionPack @productDimension="productDimension" class="mt-4" :productData="styleEditData"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AddProductDetails from "./AddProductDetails.vue";
import AddProductPricing from "./AddProductPricing.vue";
import AddProductVariants from "./AddProductVariants.vue";
import AddProductHeirarchy from "./AddProductHeirarchy.vue";
import AddProductOrganisationHeirarchy from "./AddProductOrganisationHeirarchy.vue";
import AddProductDimensionPack from "./AddProductDimensionPack.vue";
import AddProductPricingPerRegion from "./AddProductPricingPerRegion.vue";
import StyleService from "@/services/catalogs/StyleService";
import DsProductEditService from "@/services/catalogs/DsProductEditService";
import AddImage from "./AddImage.vue";
export default {
  components: {
    AddProductDetails,
    AddProductPricing,
    AddProductVariants,
    AddProductHeirarchy,
    AddProductOrganisationHeirarchy,
    AddProductDimensionPack,
    AddProductPricingPerRegion,
    AddImage,
  },

  data() {
    return {
      styleData: {
        length: null,
        width: null,
        height: null,
        volume: null,
        mp_qty: null,
        pricePerRegion: [],
        sku: null,
        upc: null,
        variants: false,
        variantsData: [],
        sbu_id: null,
        brand_id: null,
        product_type_id: null,
        category_id: null,
        sub_category_id: null,
        code: null,
        name: null,
        description: null,
        sale_price: null,
        compare_price: null,
        cost: null,
        gross_margin: null,
        images : null,
      },
      validation1: false,
      productvalid: false,
      showPricePerRegion:false,
      pricingvalid: false,
      Heirarchyvalid: false,
      organisationvalid: false,
      variantValid: false,
      isvalid: false,
      styleEditData:{},
    };
  },
  created() {
    let $id = this.$route.query.id
    if ($id) {
      this.getStyle($id)
    }
    this.getAccountId();
  },
  methods: {
    imageChanged($event){
     this.styleData.images = $event
    },
    async getAccountId() {
      this.styleData.account_id = localStorage.getItem('_ACCOUNT_ID_')
    },
    async getStyle($id) {
      let res = await DsProductEditService.findAll({ id: $id })
      this.styleEditData = res.length > 0 ? res[0] : {}
    },
    productDimension(formData) {
      this.styleData.length = formData.length
      this.styleData.width = formData.width
      this.styleData.height = formData.height
      this.styleData.volume = formData.volume
      this.styleData.mp_qty = formData.pack
    },
    pricingRegionData(qualifiers) {
      this.styleData.pricePerRegion = qualifiers
    },
    productVarient(formData) {
      this.styleData.sku = formData.sku
      this.styleData.upc = formData.upc
      this.styleData.variants = formData.variants
      let $color = formData.color ? formData.color.split(",").map(String) : []
      let $size = formData.size ? formData.size.split(",").map(String) : []
      let $material = formData.material ? formData.material.split(",").map(String) : []
      this.styleData.variantsData = []
      if ($color.length > 0 && $size.length == 0 && $material.length == 0) {
        $color.forEach(c => {
          let data = {
            color: c,
            size: null,
            material: null
          }
          this.styleData.variantsData.push(data)
        })
      }
      else if ($color.length == 0 && $size.length > 0 && $material.length == 0) {
        $size.forEach(s => {
          let data = {
            color: null,
            size: s,
            material: null
          }
          this.styleData.variantsData.push(data)
        })
      }
      else if ($color.length == 0 && $size.length == 0 && $material.length > 0) {
        $material.forEach(m => {
          let data = {
            color: null,
            size: null,
            material: m
          }
          this.styleData.variantsData.push(data)
        })
      }
      else if ($color.length > 0 && $size.length > 0 && $material.length == 0) {
        $color.forEach(c => {
          $size.forEach(s => {
            let data = {
              color: c,
              size: s,
              material: null
            }
            this.styleData.variantsData.push(data)
          })
        })
      }
      else if ($color.length > 0 && $size.length == 0 && $material.length > 0) {
        $color.forEach(c => {
          $material.forEach(m => {
            let data = {
              color: c,
              size: null,
              material: m
            }
            this.styleData.variantsData.push(data)
          })
        })
      }
      else if ($color.length == 0 && $size.length > 0 && $material.length > 0) {
        $material.forEach(m => {
          $size.forEach(s => {
            let data = {
              color: null,
              size: s,
              material: m
            }
            this.styleData.variantsData.push(data)
          })
        })
      }
      else if ($color.length > 0 && $size.length > 0 && $material.length > 0) {
        $color.forEach(c => {
          $size.forEach(s => {
            $material.forEach(m => {
              let data = {
                color: c,
                size: s,
                material: m
              }
              this.styleData.variantsData.push(data)
            })
          })
        })
      }
    },
    organisationData(formData) {
      this.styleData.sbu_id = formData.sbu
      this.styleData.brand_id = formData.brand
    },
    productHeirarchy(formData) {
      this.styleData.product_type_id = formData.product_type
      this.styleData.category_id = formData.category
      this.styleData.sub_category_id = formData.sub_category
    },
    productDetails(formData) {
      this.styleData.code = formData.code
      this.styleData.name = formData.name
      this.styleData.description = formData.description
    },
    pricingData(pricingData) {
      this.showPricePerRegion = pricingData.pricingvalue
      this.styleData.taxableProduct = pricingData.taxableProduct
      this.styleData.pricingvalue = pricingData.pricingvalue
      this.styleData.sale_price = pricingData.sale_price
      this.styleData.compare_price = pricingData.compare_price
      this.styleData.cost = pricingData.cost
      this.styleData.gross_margin = pricingData.gross_margin
    },
    updateValid1(isvalid) {
      this.productvalid = isvalid
      this.submit()
    },
    variantsValidation(isvalid) {
      this.variantValid = isvalid
      this.submit()
    },
    updateValid2(isvalid) {
      this.pricingvalid = isvalid
      this.submit()
    },
    updateValid3(isvalid) {
      this.Heirarchyvalid = isvalid
      this.submit()
    },
    updateValid4(isvalid) {
      this.organisationvalid = isvalid
      this.submit()
    },
    save() {
      if (this.productvalid && this.pricingvalid && this.variantValid && this.Heirarchyvalid && this.organisationvalid) {
       this.submit()
      }
      else{
        this.validation1 = !this.validation1
      }
    },
    async submit() {
      if (this.productvalid && this.pricingvalid && this.variantValid && this.Heirarchyvalid && this.organisationvalid) {
        await StyleService.createAll(this.styleData)
        console.log("finalData", this.styleData)
        this.$router.go(-1);
      }
    }
  },
};
</script>

<style></style>