<template>
  <v-row>
    <v-col>
      <PrimeTable
        :tableName="'Sku Mapping '"
        :activity="'SKU Mapping'"
        :showTableHeader="true"
        :headers="column"
        :tableData="data"
        @rowAction="rowAction"
        @addDialog="addDialog"
      ></PrimeTable>

      <v-dialog v-model="dialog" width="600px" persistent>
        <AddSkuMapping @closeDialog="closeDialog" :type="type" :id="editDataId" />
      </v-dialog>
      <v-dialog v-model="confirmation" width="400px" persistent>
    <ConfirmationDialog @cancelled="cancelled()" @deleted="deleted" :content="confirmationContent" />
  </v-dialog>
    </v-col>
  </v-row>
</template>
 <script>
import AddSkuMapping from "../marketPlaces/AddMarketPlaces/AddSkuMapping.vue";
import MwsPreferenceService from '@/services/mws/MwsPreferenceService';
import ConfirmationDialog from "@/components/DialogConfirmation.vue";
export default {
  components: {
    AddSkuMapping,
    ConfirmationDialog
  },
  data() {
    return {
      dialog: false,
      data: [],
      confirmationContent:null,
      confirmation:false,
      column: [
      { title: "Shop Sku", align: "start", key: "shop_sku" },
        { title: "Merchant Sku", align: "start", key: "merchant_sku" },
        { title: "Modified on", align: "start", key: "created" },
        { title: "Sku  Mapping", align: "start", key: "substitute_only" },
        { title: "Status    ", key: "active", sortable: false },
        { title: "Action", key: "actions", sortable: false },
      ],
      active: [
        { id: 1, name: "Active" },
        { id: 0, name: "Inactive" },
      ],
      mwsId: localStorage.getItem("mwsHeaderId"),
      editDataId: null,
      skuMappingUpdate: [
        { id: "all", name: "All the Time" },
        { id: "stock", name: "When not in stock" },
      ],
    };
  },
  created() {
    this.getInventoryMappings();
  },
  methods: {
    async getInventoryMappings() {
      let $res = await MwsPreferenceService.findAll({
        rule_type: "sku_mapping",
        mws_header_id: this.mwsId,
      });
      if ($res && $res.length > 0) {
        $res.forEach((element) => {
          let $val = element.preferences ? JSON.parse(element.preferences) : {};
          element.shop_sku =
            $val && Object.keys($val).length > 0 ? $val.shop_sku : null;
          element.merchant_sku =
            $val && Object.keys($val).length > 0 ? $val.merchant_sku : null;
            element.substitute_only = $val && Object.keys($val).length>0  && $val.substitute_only ? this.skuMappingUpdate.filter(k=>k.id==$val.substitute_only)[0].name:null;
        });
        this.data = $res;
        this.data.map((obj) => {
            return (obj.active = this.active.find(
              (type) => type.id == obj.active
            ).name);
          });
      }
    },
    rowAction(e) {
      if (e.type == "edit") {
        console.log(e,'eeeeeeeeeeeeeeeeeee')
        this.dialog = true;
        this.type = e.type;
        this.editDataId = e.value.id;
      }
      if (e.type == "view") {
        this.dialog = true;
        this.type = e.type;
        this.editDataId = e.value.id;
      }
      if (e.type == "delete") {
        this.dialog=false;
        this.confirmation = true;
        this.confirmationContent = `Do you want to delete the Sku Mapping ${e.value.shop_sku} ?`
        this.editDataId = e.value.id;
      }
      if (e.type == "select") {
        this.dialog = true;
        this.type = e.type;
        this.editDataId = e.value.id;
      }
    },
    addDialog() {
      this.editDataId = null;
      this.dialog = true;
      this.type = "add";
    },
    closeDialog() {
      this.dialog = false;
      this.getInventoryMappings();
    },
    async deleted(){
      this.confirmation = false;
       await MwsPreferenceService.delete({id:this.editDataId});
      this.getInventoryMappings();
    },
    cancelled(){
      this.editDataId = null;
      this.confirmation = false;
    }
  },
};
</script>