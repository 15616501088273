<template>
  <div>
    <v-row no-gutters dense>
      <v-col xl="6" lg="6" md="6" sm="12" cols="12">
        <div class="img">
          <v-img src="https://s3.ap-south-1.amazonaws.com/tibura.com/assets/clients/xtend-mws.png" width="90%" />
        </div>
        <div class="Download-image-1">
           <v-img src="https://s3.ap-south-1.amazonaws.com/tibura.com/assets/clients/xtend-products1.png" width="75%" />
        </div>
      </v-col>
      <v-col xl="6" lg="6" md="6" sm="12" cols="12">
        <div style="padding-top: 30px">
          <span class="featurestxt"> Features & Benefits<br /><br /> </span>
          <div class="featuressmalltxt">
            - One-point solution<br />
            - Fully automated E-commerce inventory and shipping<br />
            - Get notifications on shipping status<br />
            - Customise stock availabity display on different marketplaces<br />
            - Avoid repetitive tasks<br />
            - Save time and reduce human workload<br />
            - Avoid errors<br />
            - Powerful Dashboards
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<style scoped>
.Download-image-1 {
  position: relative;
  left: 20% !important;
  top: -36% !important;
}
.featurestxt {
  font-family: 'Nunito' !important;
  font-weight: 500;
  font-size: 52px;
  line-height: 34px;
  color: #000000;
}
.featuressmalltxt {
  font-family: 'Nunito' !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 43px;
  color: #272727;
}
.img {
  padding: 30px !important;
}
@media (max-width: 480px) {
  .Download-image-1 {
  /* position: relative; */
  left: 20% !important;
  top: -36% !important;
  width:80%;
}
  .img {
    margin-left:5%;
    padding: 20px !important;
    width:80%;
  }
  .featurestxt {
    font-size: 32px !important;
    line-height: 15px !important;
    padding-left: 30px;
  }
  .featuressmalltxt {
    font-size: 16px;
    line-height: 27px;
    padding-left: 30px !important;
    
  }
}
@media only screen and (min-width:480px) and (max-width: 600px)  {
  .img {
    padding: 20px !important;
  }
  .featurestxt {
    font-size: 37px !important;
    line-height: 15px !important;
    padding-left: 30px;
  }
  .featuressmalltxt {
    font-size: 16px;
    line-height: 27px;
    padding-left: 30px !important;
    
  }
}
@media only screen and (min-width:600px) and (max-width: 960px)  {
    .Download-image-1 {
  /* position: relative; */
  left: 20% !important;
  top: -36% !important;
  width:80%;
}
  .img {
    padding: 60px !important;
    width:80%;
  }
  
  .featurestxt {
    font-size: 45px !important;
    line-height: 15px !important;
    padding-left: 35px;
  }
  .featuressmalltxt {
    font-size: 20px;
    line-height: 33px;
    padding-left: 35px !important;
    
  }
}
@media only screen and (min-width:960px) and (max-width: 1200px)  {
 .featurestxt {
  font-family: 'Nunito' !important;
  font-weight: 500;
  font-size: 42px;
  line-height: 0px;
  color: #000000;
}
.featuressmalltxt {
  font-family: 'Nunito' !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 33px;
  color: #272727;
}
.img {
   padding: 30px !important;
}
}

</style>