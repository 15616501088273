<template>
  <v-row>
    <v-col>
      <PrimeTable
        :tableName="'Pricing Rule'"
        :activity="'Pricing Rule'"
        :showTableHeader="true"
        :headers="column"
        :tableData="data"
        @rowAction="rowAction"
      ></PrimeTable>
    </v-col>
  </v-row>
</template>
 <script>
import MwsPreferenceService from "@/services/mws/MwsPreferenceService";
import moment from "moment";
export default {
  data() {
    return {
      data: [],
      mwsId:localStorage.getItem("mwsHeaderId"),
      column: [
        { title: "Category", align: "start", key: "Category" },
        { title: "Style", align: "start", key: "Style" },
        { title: "Product", align: "start", key: "Product" },
        { title: "Formula", align: "start", key: "Formula" },
        { title: "Status    ", key: "active", sortable: false },
        { title: "Action", key: "actions", sortable: false },
      ],
      active: [
        { id: 1, name: "Active" },
        { id: 0, name: "Inactive" },
      ],
      pricingRules: [
        {
          edit: true,
          fulfillmentId: 0,
          shipmentZoneId: 0,
          field: [],
          operand: [],
          value: "",
          shipperId: null,
          rate: null,
          fulfillments: [],
          minError: "",
          maxError: "",
        },
      ],
      mwsHeader: {
        id: null,
        rule_type: "shipment_rules",
      },
    };
  },
  created() {
    this.getMws();
  },
  methods: {
    moment,
    async createUpdateMws() {
      if (this.mwsHeader && this.mwsHeader.id) {
        let $obj = Object.assign({}, this.pricingRules);
        this.mwsHeader.preferences = JSON.stringify($obj);
        await MwsPreferenceService.update(this.mwsHeader);
      } else {
        await MwsPreferenceService.create(this.mwsHeader);
      }
    },
    async getMws() {
      let $result = await MwsPreferenceService.findAll({
        mws_header_id: this.mwsId,
      });
      if ($result) {
        let $obj = {};
        $obj.id = $result.id;
        $obj.preferences =
          $result.preferences && typeof $result.preferences == "string"
            ? JSON.parse($result.preferences)
            : {};
        $obj.rule_type = "shipment_rules";
        this.mwsHeader = $obj;
        this.pricingRules = this.mwsHeader.preferences;
      }
    },
  },
};
</script>