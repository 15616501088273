<template>
   <v-label class="commonLabel ml-1" v-if="label">{{ label }} 
    <span style="color: rgb(207, 7, 7);padding-left: 2px;font-weight: 700;" v-if="rules">*</span>
   </v-label>
   <v-text-field
     density="compact"
     variant="outlined"
     class="text-Field"
     :rules="rules"
     v-model="formattedValue"
     :suffix="suffix"
     @keydown="keydown"
     @keyup="changeText"
     @keypress="keypress"
     :disabled="disabled"
     :placeholder="label || placeholder"
     :append-inner-icon="icon ? icon : ''"
     @click:append-inner="clickAppend"
     :append-icon="appendicon ? appendicon : ''"
     @input="anychange"
     :type="type"
     @update:model-value="selectChange"
   >
     <span class="text-field-text" v-if="text">{{ text }}</span>
   </v-text-field>
 </template>
 
 <script>
 export default {
   props: ['label', 'icon', 'rules', 'placeholder', 'disabled', 'data', 'type', 'suffix', 'appendicon', 'text'],
   data() {
     return {
       value: null
     };
   },
   computed: {
     formattedValue: {
       get() {
         return this.value;
       },
       set($val) {
        const trimmedValue = $val.trim();
        this.value = trimmedValue;
        this.$emit('textData', trimmedValue);
       }
     }
   },
   created() {
     this.value = this.data;
   },
   watch: {
     data() {
       this.value = this.data;
     }
   },
   methods: {
     selectChange() {
       this.$emit('selectChange', this.value);
     },
     removeWhiteSpace(text) {
       return text.replace(/\s/g, '');
     },
     keydown($event) {
       this.$emit('keydowninput', $event);
     },
     anychange($event) {
       this.$emit('keyChange', $event);
     },
     clickAppend() {
       this.$emit('clickAppend');
     },
     keypress($event){
      this.$emit('keypress', $event)
      },
      changeText() {
        this.$emit('textData', this.value)
        },
   }
 };
 </script>
 
 <style lang="scss">
 .commonLabel {
    font-size: 13px;
    font-weight: 500;
    opacity: inherit;
    color: #606060;
 }
 </style>
 