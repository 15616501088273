<template>
  <v-card>
    <v-form @submit.prevent="save()" ref="form">
      <v-card-title class="dialog-Title"
        >{{
          type == "edit"
            ? "Edit"
            : type == "view"
            ? "View"
            : type == "add"
            ? "Add"
            : type == "select"
            ? "View"
            : ""
        }}
        Discount
        <v-btn
          density="comfortable"
          @click="closeDialog"
          elevation="0"
          icon="mdi-close"
          style="float: right"
        ></v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="7">
              <v-row>
                <v-col cols="12" class="pa-0 pl-2">
                  <InputBox
                  :data="discountCodeData.name"
                   @textData="($event) => {discountCodeData.name = $event;}"
                    :disabled="showReadOnly"
                    :label="'Discount Title'"
                    :rules="nameRule"
                  />
                </v-col>
                <v-col cols="6" class="pa-0 pl-2">
                  <DatePicker
                  :minDate="new Date()"
                  :Datetype="'start'"
                  :data="discountCodeData.start_date"
                   @textData="startDate($event)"
                   :errorColor="errorMsg"
                    :disabled="showReadOnly"
                    :label="'Start Date'"
                    :rules="startDateRule"/>
                    <span v-if="errorMsg" class="error-field-datepicker">Start Date is Required</span> 
                  <!-- <InputBox :disabled="showReadOnly" v-model="discountCodeData.start_time" :label="'Start Time'" type="date">Starts At</InputBox> -->
                </v-col>
                <v-col cols="6" class="pa-0 pl-2">
                  <InputBox
                  :data="discountCodeData.start_time"
                  @selectChange="StartTime($event)"
                    :disabled="showReadOnly"
                    :label="'Start Time'"
                    type="time"
                    :rules="startTimeRule"
                    >Start Time</InputBox
                  >
                  <v-error v-if="showErrorTime" class="error-field">Start Time is Required</v-error>
                </v-col>
                <v-col cols="6" class="pa-0 pl-2">
                  <DatePicker
                  :minDate="discountCodeData.start_date"
                  :data="discountCodeData.end_date"
                   @textData="endDate($event)"
                   :errorColor="errorMsg2"
                    :disabled="showReadOnly"
                    :label="'End Date'"
                    :rules="endDateRule"
                    />
                    <v-error v-if="errorMsg2" class="error-field-datepicker">End Date is Required</v-error>
                </v-col>
                <v-col cols="6" class="pa-0 pl-2">
                  <InputBox
                  :data="discountCodeData.end_time"
                   @selectChange="EndTime($event)"
                    :disabled="showReadOnly"
                    :label="'End Time'"
                    type="time"
                    :rules="endTimeRule"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="5" class="banner">
              <v-col>
                <v-img
                  src="https://as1.ftcdn.net/v2/jpg/05/00/19/42/1000_F_500194237_PlXWMmZ9x3nzpG0FryrUXo0HRq3Dq41V.jpg"
                  alt="x-tend"
                ></v-img>
              </v-col>
            </v-col>
            <v-divider></v-divider>
            <v-col cols="12" class="pa-2">
              <span class="font-weight-bold">Discount Type</span>
              <v-radio-group
                :disabled="showReadOnly"
                inline
                class="radio-group"
                v-model="discountCodeData.type"
                color="primary"
              >
                <v-radio
                  label="Percentage"
                  value="percentage"
                  v-model="discountCodeData.type"
                ></v-radio>
                <v-radio
                  label="Fixed Amount"
                  value="fixedamount"
                  v-model="discountCodeData.type"
                ></v-radio>
                <v-radio
                  label="Free Shipping"
                  value="freeshipping"
                  v-model="discountCodeData.type"
                ></v-radio>
                <v-radio
                  label="Product Offer"
                  value="productoffer"
                  v-model="discountCodeData.type"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-divider></v-divider>
            <v-col cols="5" class="pa-2">
              <span class="font-weight-bold">Minimum Value by</span>
              <v-radio-group
                :disabled="showReadOnly"
                inline
                v-model="discountCodeData.min_value_type"
                class="radio-group"
                color="primary"
              >
                <v-radio
                  label="Quantity"
                  value="quantity"
                  v-if="discountCodeData.type != 'productoffer'"
                ></v-radio>
                <v-radio
                  label="Amount"
                  value="amount"
                  v-if="discountCodeData.type != 'productoffer'"
                ></v-radio>
                <v-radio
                  label="No Minimum Value"
                  value="nominmumovalue"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col
              cols="3"
              class="pa-2 mt-2"
              v-if="discountCodeData.min_value_type == 'amount'"
            >
              <InputBox
              :label="'Amount'"
                 :data="discountCodeData.min_value"
                   @textData="($event) => {discountCodeData.min_value = $event;}"
                :disabled="showReadOnly"
                :icon="'mdi-currency-usd'"
                :type="'number'"
                :rules="minRule"
              />
            </v-col>
            <v-col
              cols="3"
              class="pa-2 mt-2"
              v-if="discountCodeData.min_value_type == 'quantity'">
              <InputBox
              :label="'Qty'"
              :data="discountCodeData.min_value"
                   @textData="($event) => {discountCodeData.min_value = $event;}"
                :disabled="showReadOnly"
                :type="'number'"
                :rules="qtyRule"
              />
            </v-col>
            <v-divider vertical class="mt-4 mb-1 pl-1"></v-divider>
            <v-col
              cols=""
              class="pt-4"
              v-if="this.discountCodeData.type == 'percentage' || this.discountCodeData.type == 'fixedamount'">
              <InputBox
              :data="discountCodeData.amount"
                   @textData="($event) => {discountCodeData.amount = $event;}"
                :disabled="showReadOnly"
                :type="'number'"
                :label="'Discount Percentage'"
                :icon="'mdi-percent'"
                :rules="amountRule"
              />
            </v-col>
            <v-divider></v-divider>
            <v-row
              class="mt-1 mb-1"
              v-if="this.discountCodeData.type == 'freeshipping'"
            >
              <v-col cols="12" class="font-weight-bold pb-4"
                >Free Shipping</v-col
              >
              <v-col cols="6" class="pa-0 pl-4">
                <AutoComplete
                  :data="discountCodeData.country"
                   @textData="stateSelection($event)"
                  :disabled="showReadOnly"
                  :items="country"
                  :text="'name'"
                  value="id"
                  :label="'Country'"
                  :rules="countryRule"
                />
              </v-col>
              <v-col cols="6" class="pa-0 pr-4 pl-2">
                <AutoComplete
                :data="discountCodeData.state"
                   @textData="($event) => {discountCodeData.state = $event;}"
                  :disabled="showReadOnly"
                  :items="displaySates"
                  :text="'name'"
                  value="id"
                  :label="'State'"
                  @click="selectState"
                  :rules="stateRule"
                />
              </v-col>
              <v-divider></v-divider>
            </v-row>
            <v-row
              class="ma-1"
              v-if="this.discountCodeData.type == 'productoffer'"
            >
              <v-col cols="12" class="pa-2 pl-1">
                <span class="font-weight-bold">Apply On</span>
                <v-radio-group
                  :disabled="showReadOnly"
                  inline
                  v-model="discountCodeData.apply_on"
                  class="radio-group"
                  color="primary"
                >
                  <v-radio label="Collection" value="collection"></v-radio>
                  <v-radio label="Product" value="product"></v-radio>
                </v-radio-group>
              </v-col>
              <v-divider></v-divider>

              <v-col cols="12" class="font-weight-bold pl-1 pb-5"
                >Product Offers</v-col
              >
              <v-col cols="6" class="pa-0 pl-2">
                <AutoComplete
                :data="product_offers.customer_buy"
                   @textData="updateBuy($event)"
                  :disabled="showReadOnly"
                  :items="productData"
                  :text="'name'"
                  :value="'id'"
                  :label="'Customer Buys'"
                  :rules="buyRule"
                  :multiple="true"
                />
              </v-col>
              <v-col cols="6" class="pa-0 pl-2">
                <AutoComplete
                :data="product_offers.customer_get"
                   @textData="updateGet($event)"
                  :disabled="showReadOnly"
                  :items="productData"
                  :text="'name'"
                  :value="'id'"
                  :label="'Customer Gets'"
                  :rules="getRule"
                  :multiple="true"
                />
              </v-col>
              <v-col cols="6" class="pa-2 scroll">
                <PrimeTable
                  activity="Discount Code"
                  :tableName="'Users'"
                  :showTableHeader="false"
                  :headers="column"
                  :tableData="buyerBuyData"
                  @rowAction="rowAction($event)"
                  @addDialog="addDialog"
                ></PrimeTable>
              </v-col>
              <v-col cols="6" class="pa-2 scroll">
                <PrimeTable
                 activity="Discount Code"
                  :tableName="'Users'"
                  :showTableHeader="false"
                  :headers="column1"
                  :tableData="buyerGetData"
                  @rowAction="rowAction($event)"
                  @addDialog="addDialog"
                ></PrimeTable>
              </v-col>
              <v-divider></v-divider>
              <v-col cols="6" class="pa-2">
                <span class="font-weight-bold">Apply Type</span>
                <v-radio-group
                  :disabled="showReadOnly"
                  inline
                  v-model="discountCodeData.apply_type"
                  class="radio-group mt-1"
                  color="primary"
                >
                  <v-radio label="Free" value="free"></v-radio>
                  <v-radio label="Discounted Price" value="price"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="6" class="pr-0 pl-2">
                <InputBox
                  :data="discountCodeData.amount"
                   @textData="($event) => {discountCodeData.amount = $event;}"
                  class="mt-5"
                  :disabled="showReadOnly"
                  v-if="discountCodeData.apply_type == 'price'"
                  :label="'Price'"
                  :type="'number'"
                  :rules="priceRule"
                />
              </v-col>
              <v-divider></v-divider>
            </v-row>
            <v-col cols="12" class="mt-3">
              <!-- <TextArea :disabled="showReadOnly" v-model="discountCodeData.description" :label="'Description'"></TextArea> -->
              <QuilEditor
                :data="discountCodeData.description"
                :editorStyle="'height:100px;overflow: auto'"
                :lable="'Description'"
                @textData="($event) => {discountCodeData.description = $event;}"
                :showReadOnly="showReadOnly"
              ></QuilEditor>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="dialogfooter">
        <v-spacer></v-spacer>
        <v-btn class="mr-1 cancel" @click="closeDialog">Cancel</v-btn>
        <v-btn class="save" type="submit">Save</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import DatePicker from "@/components/DatePicker.vue";
import DiscountCodeService from "@/services/mws/DiscountCodeService";
import QuilEditor from "@/components/QuilEditor.vue";
import Country from "@/api/service/Countries.json";
import States from "@/api/service/States.json";
import ProductService from "@/services/catalogs/ProductService";
// import moment from 'moment';
export default {
  props: ["type", "id", "showReadOnly"],
  components: {
    QuilEditor,
    DatePicker,
  },
  data() {
    return {
      priceRule:[(value)=> !!value || "Price Is Required"],
      getRule:[(value)=> !!value || "Customer Get Is Required"],
      buyRule:[(value)=> !!value || "Customer Buy Is Required"],
      stateRule:[(value)=> !!value || "State Is Required"],
      countryRule:[(value)=> !!value || "Country Is Required"],
      amountRule:[(value)=> !!value || "Discount Percentage Is Required"],
      qtyRule:[(value)=> !!value || "Quantity Is Required"],
      minRule:[(value)=> !!value || "Amount Is Required"],
      nameRule:[(value)=> !!value || "Title Is Required"],
      startTimeRule:[(value)=> !!value || "Start Time Is Required"],
      startDateRule:[(value)=> (!!value) || "Start Date Is Required"],
      endDateRule:[(value)=> !!value || "End Date Is Required"],
      endTimeRule:[(value)=> !!value || "End Time Is Required"],
      product_offers: {
        customer_buy: [],
        customer_get: [],
      },
      buyerBuyData: [],
      buyerGetData: [],
      productData: [],
      country: Country,
      states: States,
      displaySates: [],
      data: [],
      showErrorTime:false,
      data1: [],
      column: [
        { title: "Id", align: "start", key: "id" },
        { title: "Name", align: "start", key: "name" },
        { title: "SKU", align: "start", key: "sku" },
      ],
      column1: [
        { title: "Id", align: "start", key: "id" },
        { title: "Name", align: "start", key: "name" },
        { title: "SKU", align: "start", key: "sku" },
      ],
      mwsId: localStorage.getItem("mwsHeaderId"),
      discountCodeData: {
        name: null,
        start_date: null,
        start_time: null,
        end_date: null,
        end_time: null,
        type: null,
        min_value_type: null,
        min_value: null,
        free_shipping: null,
        product_offers: null,
        amount: null,
        discount_value: null,
        apply_on: null,
        apply_type: null,
        company_id: null,
        account_id: null,
        mws_header_id: null,
        description: null,
        country: null,
        state: null,
      },
      errorMsg: false,
      errorMsg2: false,
    };
  },
  async created() {
    this.getProductData();
    if(this.id){
      let res = await DiscountCodeService.findAll({id : this.id});
      this.discountCodeData = res[0];
      let product_Offers = JSON.parse(res[0].product_offers)
      this.product_offers.customer_buy = product_Offers.customer_buy
      this.product_offers.customer_get = product_Offers.customer_get
      this.buyerGetData = product_Offers.customer_get
      this.buyerBuyData = product_Offers.customer_buy
    }
  },
  // watch:{
  //   customer_buy(){
  //     this.updateBuyData();
  //   }
  // },
  methods: {
    startDate($event){
      this.discountCodeData.start_date = $event
      this.errorMsg = false
      if (this.discountCodeData.start_date > this.discountCodeData.end_date){
        this.discountCodeData.end_date = null;
      }
    else{
      this.discountCodeData.start_date = $event
       if(!this.discountCodeData.start_date){
            this.errorMsg = true;
       }else{
            this.errorMsg = false;
       }
    }
      //  this.discountCodeData.start_date = moment($event).format("YYYY-MM-DD")
    },
    endDate($event){
      this.errorMsg2 = false
      this.discountCodeData.end_date = $event
       if (this.discountCodeData.start_date){
         this.discountCodeData.end_date = $event
      
       if(!this.discountCodeData.end_date){
          this.errorMsg2 = true;
       }else{
          this.errorMsg2 = false;
       }
      }
      else{
        this.discountCodeData.end_date = $event
        this.errorMsg = true;
        // this.$store.dispatch("setSnackbar", { text: "Please Select Start Date First" });
      }
      //  this.discountCodeData.end_date = moment($event).format("DD-MM-YYYY")
    },
    EndTime($event){
      this.discountCodeData.end_time = $event
          let startTime = parseFloat(this.discountCodeData.start_time)
          let endTime = parseFloat(this.discountCodeData.end_time)
      if(startTime > endTime){
        this.showErrorTime = true
      }else{
        this.showErrorTime = false
      }
    },
    StartTime($event){
      this.discountCodeData.start_time = $event
      let startTime = parseFloat(this.discountCodeData.start_time)
          let endTime = parseFloat(this.discountCodeData.end_time)
      if(startTime > endTime){
        this.showErrorTime = true
      }else{
        this.showErrorTime = false
      }
    },
    async updateBuy($event) {
      let uniq = [...new Set($event)]
      let array = []
      this.customer_buy = uniq
      this.customer_buy.forEach(element => {
            let res = this.productData.filter((f) => f.id == element);
            array.push(res[0])
          });
          this.product_offers.customer_buy = array
          this.updateBuyData();
      // if (!this.product_offers.customer_buy.some((data) => data.id === res[0].id))
      //  { this.product_offers.customer_buy =  this.product_offers.customer_buy.concat(res);
      //   this.updateBuyData();
      // }else{
      //           this.$store.dispatch("setSnackbar", { text: "Already Added this Item" ,color: 'error'});
      // }
    },
    updateBuyData() {
      this.buyerBuyData = this.product_offers.customer_buy;
    },
    async updateGet($event) {
      let uniq = [...new Set($event)]
      let array = []
      this.customer_get = uniq
      this.customer_get.forEach(element => {
            let res = this.productData.filter((f) => f.id == element);
            array.push(res[0])
          });
          this.product_offers.customer_get = array
          this.updateGetData();
      // let res = this.productData.filter((f) => f.id == this.customer_get);
      // if (
      //   !this.product_offers.customer_get.some((data) => data.id === res[0].id)
      // ) {
      //   this.product_offers.customer_get =
      //     this.product_offers.customer_get.concat(res);
      //   this.updateGetData();
      // }else{
      //   this.$store.dispatch("setSnackbar", { text: "Already Added this Item" ,color: 'error'});
      // }
    },
    updateGetData() {
      this.buyerGetData = this.product_offers.customer_get;
    },
    async getProductData() {
      let res = await ProductService.findAll();
      this.productData = res&&res.length>0 ? res : [];
    },
    stateSelection($event) {
      this.discountCodeData.country = $event
      this.discountCodeData.state
      this.displaySates = this.states[this.discountCodeData.country];
    },
    selectState() {
      this.displaySates = this.states[this.discountCodeData.country];
    },
    async getEditData() {
      // let $result = await DiscountCodeService.findAll({id:this.id})
      // console.log($result,'editdata')
      // this.discountCodeData = $result[0]
    },
    closeDialog() {
      this.$emit("closeDialog");
      this.discountCodeData = {};
    },
    rowAction($event){
      console.log($event)
    },
    async save() {
      console.log("finallllData",this.discountCodeData)
     
      delete this.discountCodeData.users

      this.discountCodeData.product_offers = JSON.stringify(
        this.product_offers
      );
      this.discountCodeData.account_id = localStorage.getItem("_ACCOUNT_ID_");
      this.discountCodeData.company_id = JSON.parse(localStorage.getItem("companyDetails")).id;

      let validation = await this.$refs.form.validate();

      if (!validation.valid || !this.discountCodeData.start_date || !this.discountCodeData.end_date) {
        console.log(validation);
        if(!this.discountCodeData.start_date){
          this.errorMsg = true;
        }
        if(!this.discountCodeData.end_date){
          this.errorMsg2 = true;
        }
      } else {
        this.discountCodeData.mws_header_id = this.mwsId;
        if (this.discountCodeData && this.discountCodeData.id) {
          let output = await DiscountCodeService.update(this.discountCodeData);
          this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
          console.log(output, "out");
          this.closeDialog();
        } else {
          try {
            let output = await DiscountCodeService.create(this.discountCodeData);
            this.$store.dispatch("setSnackbar", { text: "Saved Successfully" });
            console.log(output, "put");
            this.closeDialog();
             this.discountCodeData = {}
          } catch (e) {
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "Something Went Wrong",
            });
          }
        }
      }
    },
  },
  mounted() {
    this.discountCodeData.min_value_type = "nominmumovalue";
    this.discountCodeData.type = "percentage";
    if (this.id) {
      this.getEditData();
    }
  },
};
</script>

<style lang="scss" scoped>
.radio-group {
  font-size: 12px !important;
  .v-label {
    font-size: 12px !important;
    opacity: unset !important;
    font-weight: 500;
  }
}
.banner {
  display: flex;
  align-items: center;
}

.scroll{
  overflow: auto;
  height: 290px;
}
</style>