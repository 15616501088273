<template>
  <v-list>
    <v-list-item class="sidemenuheader">
      <template v-slot:prepend>
        <v-avatar size="45" class="CompanyAvatar">
          {{ userName ? userName.substr(0, 2).toUpperCase() : "" }}
        </v-avatar>
      </template>

      <v-list-item-title class="dialog-Title">{{ userName }}</v-list-item-title>
    </v-list-item>
  </v-list>

  <v-divider></v-divider>
  <v-list >
    <!-- <v-list-item
      :to="item.route"
      v-for="(item, index) in menuItems"
      :key="item"
      class=" sidemenucontent"
      active-class="active-sidemenu"
      @click="activemenu(item, index)"
    >
      <template v-slot:prepend>
        <img class="sidemenu-img" v-bind="props" :src="item.src"  />
      </template>

      <v-list-item-title
      v-if="!item.children || item.children.length==0"
        class="pl-7"
        v-text="item.name"
       
      ></v-list-item-title>
      <v-list-group v-if="item.children && item.children.length>0" style="width: auto !important;">
          <template v-slot:activator="{ props }">
            <v-list-item
              v-bind="props"
              title="Settings"
            ></v-list-item>
          </template>

          <v-list-item
          :to="secondItem.route"
            v-for="(secondItem, i) in item.children"
            :key="i"
            :title="secondItem.name"
            style="margin-left:-50px;width: 200px !important;"
          >
        </v-list-item>
        </v-list-group>

    </v-list-item> -->
    <div  no-action  v-for="(item, index) in menuItems"
      :key="item">
          <!-- <template v-slot:activator="{ props }"> -->
            <v-list-item 
            :to="item.route"
              @click="activemenu(item, index)"
              v-bind="props"
              class=" sidemenucontent"
              active-class="active-sidemenu"
            >
            <template v-slot:prepend>
        <img class="sidemenu-img" v-bind="props" :src="item.src"  />
      </template>
      <template v-slot:append>
       <v-icon v-if="item.chevrondown">{{showMenu ?  'mdi-chevron-up':'mdi-chevron-down' }}</v-icon>
      </template>
         <v-list-item-title
        class="pl-7"
        v-text="item.name"
      ></v-list-item-title>
            </v-list-item>
          <!-- </template> -->
          <div v-if="showMenu">
            <v-list-item
            :to="list.route"
            v-for="list in item.subMenu"
             :key="list"
             class="subMenu"
              :value="list"
            >
            <v-list-item-title class="pl-12" style="font-size: 14px;font-weight: 500;color:#565656;"
        v-text="list.name"
      ></v-list-item-title>
            </v-list-item>
            </div>
        </div>
  </v-list>
  <v-divider></v-divider>
  <v-col class="mt-2">
    <v-img class="LogoImge"
      src="https://s3.ap-south-1.amazonaws.com/xtend3.0/icons/xtend/Xtend1.svg"
    ></v-img>
  </v-col>
  <v-dialog v-model="dialog" width="400" height="400">
    <ConfirmationDialog @close="close"> </ConfirmationDialog>
  </v-dialog>
</template>

<script>
import ConfirmationDialog from "./ConfirmationDialog.vue";

export default {
  components: {
    ConfirmationDialog,
  },
  data() {
    return {
      dialog: false,
      showMenu:false,
      admins: [
        ['Management', 'mdi-account-multiple-outline'],
        ['Settings', 'mdi-cog-outline'],
      ],
      show: false,
      userName: localStorage.getItem("_CUSER_"),
      items: [
        { text: "Real-Time", icon: "mdi-clock" },
        { text: "Audience", icon: "mdi-account" },
        { text: "Conversions", icon: "mdi-flag" },
      ],
      menuItems: [
        {
          src: "https://s3.ap-south-1.amazonaws.com/xtend3.0/icons/xtend/home.svg",
          name: "Home",
          show: false,
          chevrondown:false,
          isActive: false,
          route: "/",
        },
        {
          src: "https://s3.ap-south-1.amazonaws.com/xtend3.0/icons/xtend/galery.svg",
          name: "Products",
          show: false,
          isActive: false,
          chevrondown:false,
          route: "/product",
        },
        {
          src: "https://s3.ap-south-1.amazonaws.com/xtend3.0/icons/xtend/dashboard.svg",
          name: "Inventory",
          show: false,  
          isActive: false,
          chevrondown:false,
          route: "/inventoryinstore",
        },
        {
          src: "https://s3.ap-south-1.amazonaws.com/xtend3.0/icons/xtend/slider.svg",
          name: "Orders",
          show: false,
          isActive: false,
          chevrondown:false,
          route: "/orders",
        },
        {
          src: "https://s3.ap-south-1.amazonaws.com/xtend3.0/icons/xtend/menu.svg",
          name: "Scheduler",
          show: false,
          isActive: false,
          chevrondown:false,
          route: "/scheduler",
        },
        {
          src: "https://s3.ap-south-1.amazonaws.com/xtend3.0/icons/xtend/terminal.svg",
          name: "Marketplaces",
          show: false,
          isActive: false,
          chevrondown:false,
          route: "/chooseMarketPlace",
        },
        {
          src: "https://s3.ap-south-1.amazonaws.com/xtend3.0/icons/xtend/settingfeutcher.svg",
          name: "Reports",
          show: false,
          isActive: false,
          chevrondown:false,
          route: "/reports",
        },
        {
          src: "https://s3.ap-south-1.amazonaws.com/xtend3.0/icons/xtend/setting1.svg",
          name: "Settings",
          show: false,
          chevrondown:true,
          isActive: false,
          // route: "/setting/user",
          subMenu:[
            {
              name: "Admin Settings",
              show: false,
              isActive: false,
              route: "/setting/division"
            },
            {
              name: "Product Settings",
              show: false,
              isActive: false,
              route: "/productsetting/producttype"
            },
            {
              name: "Channel",
              show: false,
              isActive: false,
              route: "/channel/listmodel"
            },
            {
              name: "Identity",
              show: false,
              isActive: false,
              route: "/identity/user"
            },
            {
              name: "Templates",
              show: false,
              isActive: false,
              route: "/templates/email"
            },
          ]
        },
        {
          src: "https://s3.ap-south-1.amazonaws.com/xtend3.0/icons/xtend/logout1.svg",
          name: "Logout",
          show: false,
          chevrondown:false,
          isActive: false,
        },
      ],
    };
  },
  methods: {
    close() {
      this.dialog = false;
    },
    activemenu(item) {
      this.rail = false
      if (item.name == "Logout") {
        this.dialog = true;
        console.log(this.dialog,'dialog')
      }if(item.name == "Settings"){
          this.showMenu = !this.showMenu
      }
    },
  },
};
</script>

<style>
.v-list-group__header__append-icon {
display: none !important;
}</style>