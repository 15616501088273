<template>


  <!-- <v-btn @click="changeTheme('light')">light theme</v-btn>
  <v-btn @click="changeTheme('dark')">dark theme</v-btn> -->
  <Auth v-if="getAuth" :appTheme="isDark" />
  <NoAuth v-if="!getAuth" />
<v-snackbar
    style="margin-top: 80px"
    v-model="snackbar.showing"
    :timeout="1000"
    :top="true"
    :color="snackbar.color"
    location="top">
    {{ snackbar.text }}
  </v-snackbar>

</template>
<script>
import { mapState } from "vuex";
import Auth from "./layout/Auth.vue";
import NoAuth from "./layout/NoAuth.vue";
// import { authStore } from "@/store";
import useBreakpoints from "vue-next-breakpoints";

export default {
  components: {
    Auth,
    NoAuth,
  },
  computed: {
    ...mapState(["snackbar"]),
    signIn() {
      return this.$store.getters.getSignIn;
    },

    isDark() {
      return this.$store.getters.getIsDark;
    },
  },
 
  watch: {
    signIn() {
      this.getAuth = this.signIn;
    },
  },
  data() {
    return {
      getAuth: false,
      breakpoints : useBreakpoints({
            mobile: 600,
            tablet: 960,
            laptop: 1264,
            desktop: Infinity,
            })
    };
  },
  created() {
    var $token = localStorage.getItem("XTE_TOKEN");
    if ($token) {
      this.$store.commit("setSignIn", true);
    }
    // console.log(this.breakpoints,'breankPoints')
    let $info = this.breakpoints.mobile.matches ? 'mobile':this.breakpoints.tablet.matches ? 'tablet'
      : this.breakpoints.laptop.matches ? 'laptop' : 'desktop';
      this.$store.commit("setIsDevice",$info)
  },
  methods: {
    changeTheme(newTheme) {
      document.documentElement.setAttribute("data-theme", newTheme);
    },
    checkSnackbar() {
    console.log(this.snackbar,'alert111111111111');
  }
  },
};
</script>
<style lang="scss">
@import "../src/scss/style.scss";
</style>

