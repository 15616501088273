<template>
  <v-layout style="position: inherit !important">
    <v-navigation-drawer
      v-model="drawer"
      width="700"
      style="top: 10% !important"
      location="right"
      temporary
    >
      <v-card flat>
        <v-card-title class="dialog-Title"
          >Filter
          <v-btn @click="applyFilter()" class="apply-filter" variant="outlined"
            >Apply</v-btn
          >
          <v-btn
            density="comfortable"
            @click="closeFilter"
            elevation="0"
            icon="mdi-close"
            style="float: right"
          ></v-btn>
        </v-card-title>
        <v-divider class="border-opacity-50" />
        <v-card-text>
          <v-container>
          <v-row no-gutters dense class="mb-3">
            <!-- <v-col cols="4">
                            <v-form @submit.prevent="submit" ref="form">
                                <v-row>
                                    <v-col cols="12">
                                        <span class="ma-2 mb-2"><b>Save this Filter</b></span>

                                        <TextFieldComponent placeholder="Name" v-model="filterName"
                                             />
                                    </v-col>
                                    <v-col cols-12>
                                        <v-btn type="submit" class="save-filter" variant="outlined">Save</v-btn>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-col>
                        <v-divider style="margin-left: 15px;margin-top: -16px;margin-bottom: -11px;"
                            class="border-opacity-50" vertical /> -->
            <v-col cols="12" class="ml-5">
              <v-row v-for="item in CustomFilterData" :key="item">
                <v-col cols="12" v-if="item.field_type == 'input'">
                  <v-row>
                    <v-col :cols="3" class="mr-3">
                      <Dropdown
                        disabled="true"
                        v-model="item.column_name"
                        variant="outlined"
                        :items="CustomFilterData"
                        :item-title="'name'"
                        :item-value="'value'"
                        :placeholder="item.label"
                      />
                    </v-col>
                    <v-col :cols="3" class="mr-3">
                      <Dropdown
                        v-model="item.key_type"
                        variant="outlined"
                        :items="defaultOptions"
                        :item-title="'name'"
                        :item-value="'value'"
                        :placeholder="item.label"
                      />
                    </v-col>
                    <v-col :cols="3" class="ml-1">
                      <TextFieldComponent
                        v-model="item.defaults"
                        :placeholder="item.label"
                      >
                      </TextFieldComponent>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" v-if="item.field_type == 'autoComplete'">
                  <v-row>
                    <v-col :cols="3" class="mr-3">
                      <Dropdown
                        disabled="true"
                        v-model="item.column_name"
                        variant="outlined"
                        :items="CustomFilterData"
                        :item-title="'name'"
                        :item-value="'value'"
                        :placeholder="item.label"
                      />
                    </v-col>
                    <v-col :cols="3" class="mr-3">
                      <Dropdown
                        v-model="item.key_type"
                        variant="outlined"
                        :items="defaultOptions"
                        :item-title="'name'"
                        :item-value="'value'"
                        placeholder="Option Type"
                      />
                    </v-col>
                    <v-col :cols="3" class="ml-1">
                      <v-autocomplete
                        v-if="item.source_data && item.source_data.length > 1"
                        v-model="item.defaults"
                        density="compact"
                        :label="item.label"
                        variant="outlined"
                        :items="item.source_data"
                        :item-title="item.text"
                        :item-value="item.value"
                        :placeholder="item.label"
                        multiple
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" v-if="item.field_type == 'select'">
                  <v-row>
                    <v-col :cols="3" class="mr-3">
                      <Dropdown
                        disabled="true"
                        v-model="item.column_name"
                        variant="outlined"
                        :items="CustomFilterData"
                        :item-title="'name'"
                        :item-value="'value'"
                        :placeholder="item.label"
                      />
                    </v-col>
                    <v-col :cols="3" class="mr-3">
                      <Dropdown
                        v-model="item.key_type"
                        variant="outlined"
                        :items="defaultOptions"
                        :item-title="'name'"
                        :item-value="'value'"
                        placeholder="Option Type"
                      />
                    </v-col>
                    <v-col :cols="3" class="ml-1">
                      <Dropdown
                        v-if="item.source_data && item.source_data.length > 1"
                        v-model="item.defaults"
                        variant="outlined"
                        :items="item.source_data"
                        :item-title="item.text"
                        :item-value="item.value"
                        :placeholder="item.label"
                        :multiple="true"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" v-if="item.field_type == 'enum'">
                  <v-row>
                    <v-col :cols="3" class="mr-3">
                      <Dropdown
                        disabled="true"
                        v-model="item.column_name"
                        variant="outlined"
                        :items="CustomFilterData"
                        :item-title="'name'"
                        :item-value="'value'"
                        :placeholder="item.label"
                      />
                    </v-col>
                    <v-col :cols="3" class="mr-3">
                      <Dropdown
                        v-model="item.key_type"
                        variant="outlined"
                        :items="defaultOptions"
                        :item-title="'name'"
                        :item-value="'value'"
                        placeholder="Option Type"
                      />
                    </v-col>
                    <v-col :cols="3" class="ml-1">
                      <v-autocomplete
                        v-if="item.source_data && item.source_data.length > 1"
                        v-model="item.defaults"
                        density="compact"
                        :label="item.label"
                        variant="outlined"
                        :items="item.source_data"
                        :item-title="item.text"
                        :item-value="item.value"
                        :placeholder="item.label"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" v-if="item.field_type == 'status'">
                  <v-row>
                    <v-col :cols="3" class="mr-3">
                      <Dropdown
                        disabled="true"
                        v-model="item.column_name"
                        variant="outlined"
                        :items="CustomFilterData"
                        :item-title="'name'"
                        :item-value="'value'"
                        :placeholder="item.label"
                      />
                    </v-col>
                    <v-col :cols="3" class="mr-3">
                      <Dropdown
                        v-model="item.key_type"
                        variant="outlined"
                        :items="defaultOptions"
                        :item-title="'name'"
                        :item-value="'value'"
                        placeholder="Option Type"
                      />
                    </v-col>
                    <v-col :cols="3" class="ml-1">
                      <v-autocomplete
                        v-model="item.defaults"
                        density="compact"
                        label="Status"
                        variant="outlined"
                        :items="statusData"
                        item-title="name"
                        item-value="id"
                        placeholder="Status"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" v-if="item.field_type == 'textArea'">
                  <v-row>
                    <v-col :cols="3" class="mr-3">
                      <Dropdown
                        disabled="true"
                        v-model="item.column_name"
                        variant="outlined"
                        :items="CustomFilterData"
                        :item-title="'name'"
                        :item-value="'value'"
                        :placeholder="item.label"
                      />
                    </v-col>
                    <v-col :cols="3" class="mr-3">
                      <Dropdown
                        v-model="item.key_type"
                        variant="outlined"
                        :items="defaultOptions"
                        :item-title="'name'"
                        :item-value="'value'"
                        :placeholder="item.label"
                      />
                    </v-col>
                    <v-col :cols="3" class="ml-1">
                      <v-textarea
                        v-model="item.defaults"
                        density="compact"
                        class="elevation-0"
                        variant="solo"
                        :label="item.label"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" v-if="item.field_type == 'date'">
                  <v-row>
                    <v-col :cols="3" class="mr-3">
                      <Dropdown
                        disabled="true"
                        v-model="item.column_name"
                        variant="outlined"
                        :items="CustomFilterData"
                        :item-title="'name'"
                        :item-value="'value'"
                        :placeholder="item.label"
                      />
                    </v-col>
                    <v-col :cols="3" class="mr-3">
                      <Dropdown
                        v-model="item.key_type"
                        variant="outlined"
                        :items="defaultOptions"
                        :item-title="'name'"
                        :item-value="'value'"
                        :placeholder="item.label"
                      />
                    </v-col>
                    <v-col :cols="3" class="ml-1">
                      <span class="p-float-label">
                        <Calendar
                          style="width: 100%"
                          :inputId="item.label"
                          selectionMode="range"
                          :manualInput="false"
                          :panelStyle="{
                            width: '400px',
                            zIndex: '9999999999',
                            transformOrigin: 'center bottom',
                            top: '170px',
                            borderRadius: '10px',
                          }"
                          panelClass="calendar-container"
                          v-model="item.defaults"
                        >
                        </Calendar>
                        <label :for="item.label">{{ item.label }}</label>
                      </span>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row v-if="CustomFilterData && CustomFilterData.length>0">
                <v-col cols="2">
                  <v-tooltip location="top" text="Add Filter" v-if="false">
                    <template v-slot:activator="{ props }">
                      <v-sheet
                        v-bind="props"
                        border
                        rounded
                        :height="45"
                        :width="45"
                        class="tableicon"
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-sheet>
                    </template>
                  </v-tooltip>
                </v-col>
                <v-col cols="2">
                  <v-btn v-if="filterId" class="mr-1 cancel" @click="deleteFilter()">Delete Filter</v-btn>
                </v-col>
                <v-col cols="4">
                  <TextFieldComponent
                    v-model="filterName"
                    placeholder="Name of the Filter"
                  >
                  </TextFieldComponent>
                </v-col>
                <v-col cols="2">
                  <v-btn class="mr-1 cancel" @click="submit">Save Filter</v-btn>
                </v-col>
              </v-row>
              <v-row v-if="!CustomFilterData || CustomFilterData.length==0">
                    There is no filters currently!!
              </v-row>
            </v-col>
          </v-row>
          <v-divider class="border-opacity-50 mt-0" />
        </v-container>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
  </v-layout>
</template>
<script>
import listModelDetailService from "@/services/admin/ListModelDetailService";
import listModelService from "@/services/admin/ListModelService";
import listModelFilterService from "@/services/admin/ListModelFilterService";
import listModelFilterDetailService from "@/services/admin/ListModelFilterDetailService";
import TextFieldComponent from "@/components/InputBox.vue";
import lodash from "lodash";
import Calendar from "primevue/calendar";
import enumService from "@/services/admin/EnumService";
import Dropdown from "@/components/Dropdown.vue";
import moment from "moment";
import ListModelFilterDetailService from "@/services/admin/ListModelFilterDetailService";
import ListModelFilterService from "@/services/admin/ListModelFilterService";

export default {
  props: ["model", "drawerStatus","filterId",'oldFilterName'],
  components: {
    Calendar,
    TextFieldComponent,
    Dropdown,
  },
  data() {
    return {
      CustomFilterData: [],
      filterName: "",
      modelName: "",
      modelId: null,
      existFilters: [],
      nameRule: [(value) => !!value || "Name is Required"],
      drawer: false,
      statusData: [
        { id: 0, name: "InActive" },
        { id: 1, name: "Active" },
      ],
      defaultOptions: [
        {
          name: "Include",
          value: "include",
        },
        {
          name: "Exclude",
          value: "exclude",
        },
        {
          name: "Between",
          value: "between",
        },
        {
          name: "Like",
          value: "like",
        },
        {
          name: "Today",
          value: "today",
        },
      ],
      filterDetails:[],
      oldFilterId:null
    };
  },
  watch: {
    drawerStatus() {
      this.drawer = this.drawerStatus;
    },
    oldFilterName(){
        this.filterName = this.oldFilterName;
    },
    filterId(){
        this.oldFilterId = this.filterId
    },
    model() {
      this.modelName = this.model;
      this.getListModelDetails();
    }
   
  },
  created() {
    if(this.filterId){
        this.oldFilterId = this.filterId
    }
    if(this.oldFilterName){
        this.filterName = this.oldFilterName;
    }
    this.drawer = this.drawerStatus;
    if (this.model) {
      this.modelName = this.model;
      this.getListModelDetails();
    }
    
    
  },
  methods: {
    moment,
    async getFilterDetails(){
        this.filterDetails = await ListModelFilterDetailService.findAll({model_filter_id:this.oldFilterId});
        console.log(this.filterDetails,'model_filter_id')
    },
    async getListModelDetails() {
      let $model = await listModelService.findAll({ model: this.modelName });
      if(this.oldFilterId){
        this.getFilterDetails()
    }
      this.modelId = $model.length > 0 ? $model[0].id : 0;
      if (this.modelId > 0) {
        //this.getExistFilters(this.modelId);
        let $modelDetails = await listModelDetailService.findAll({
          model_id: this.modelId,
        });
        $modelDetails = lodash.orderBy($modelDetails, ["sequence"], ["asc"]);
        this.getDropdownData($modelDetails);
      }
    },
    async getExistFilters($id) {
      let $exist = await listModelFilterService.findAll({ modelId: $id });
      this.existFilters = $exist;
    },
    closeFilter() {
      this.drawer = false;
      this.$emit("closeFilter");
    },
    async getDropdownData($data) {
      let filterData = [];
      for (let i = 0; i < $data.length; i++) {
        if ($data[i].filter_required == 1) {
            let $oldIndex = this.filterDetails.findIndex(k=>k.column_name==$data[i].column_name);
            if($oldIndex>-1){
                $data[i].filterDetailId = this.filterDetails[$oldIndex].id;
            }
            
          let str = $data[i].label;
          $data[i].label = str.charAt(0).toUpperCase() + str.slice(1);
          if (
            $data[i].field_type == "autoComplete" ||
            $data[i].field_type == "select"
          ) {
            $data[i].key_type = "include";
            $data[i].source_data = await this.getValues(
              $data[i].source,
              $data[i].column_name
            );
            if ($data[i].source_data.length == 1) {
              $data[i].defaults = $data[i].source_data[0][$data[i].value];
            }
            
            if($oldIndex>-1){
                $data[i].key_type = this.filterDetails[$oldIndex].expression ? this.filterDetails[$oldIndex].expression :'include';
                $data[i].defaults = this.filterDetails[$oldIndex].defaults ? JSON.parse(this.filterDetails[$oldIndex].defaults) : [];
            }else{
                $data[i].defaults = [];
            }
          } else if ($data[i].field_type == "enum") {
            $data[i].key_type = "include";
            $data[i].source_data = await this.getEnumValues($data[i].source);
            if($oldIndex>-1){
                $data[i].key_type = this.filterDetails[$oldIndex].expression ? this.filterDetails[$oldIndex].expression :'include';
                $data[i].defaults = this.filterDetails[$oldIndex].defaults ? JSON.parse(this.filterDetails[$oldIndex].defaults) : [];
            }else{
                $data[i].defaults = [];
            }
          } else if ($data[i].field_type == "date") {
            $data[i].defaults = moment().format("YYYY-MM-DD");
            $data[i].key_type = "today";
            if($oldIndex>-1){
                $data[i].key_type = this.filterDetails[$oldIndex].expression ? this.filterDetails[$oldIndex].expression :'today';
                $data[i].defaults = this.filterDetails[$oldIndex].defaults;
            }
          } else {
            $data[i].key_type = "like";
            if($oldIndex>-1){
                $data[i].key_type = this.filterDetails[$oldIndex].expression ? this.filterDetails[$oldIndex].expression :'like';
                $data[i].defaults = this.filterDetails[$oldIndex].defaults;
            }
          }

          filterData.push($data[i]);
        }
      }
      this.CustomFilterData = JSON.parse(JSON.stringify(filterData));
    },
    async getValues($source) {
      let res = await listModelService.findAll({ tableName: $source });
      return res ? res : [];
    },
    async getEnumValues(source) {
      let res = await enumService.findAll({ domain: source });
      return res ? res : [];
    },
    async applyFilter() {
      this.drawer = false;
      let $obj = {};
      this.CustomFilterData.forEach((ele) => {
        if (ele && ele.defaults) {
            if(ele.key_type=='exclude'){
                ele.defaults.unshift('!');
            }
          $obj[ele.column_name] =
            ele.data_type.includes("varchar") ||
            ele.data_type.includes("string")
              ? `"${ele.defaults}"`
              : ele.defaults;
          return $obj;
        }
      });
      this.$emit("filterClosed", $obj);
    },
    async submit() {
      if (this.CustomFilterData && this.CustomFilterData.length > 0) {
        let $data = {};
        $data.name = this.filterName; //this.filterName
        $data.model_id = this.modelId;
        let $filter = {}
        if(this.filterId){
            $filter  = await listModelFilterService.update($data);
        }else{
            $filter = await listModelFilterService.create($data);
        }
        
        this.CustomFilterData.forEach(async (element) => {
          let $obj = {};
          $obj.model_filter_id = $filter.data.data.id;
          $obj.column_name = element.column_name;
          $obj.expression = element.key_type;
          $obj.defaults = typeof element.defaults=='object' ? JSON.stringify(element.defaults): element.defaults;
          if(element.filterDetailId){
            $obj.id = element.filterDetailId;
            await listModelFilterDetailService.update($obj);
          }else{
            await listModelFilterDetailService.create($obj);
          }
          
        });
        this.applyFilter();
      }
      // this.getExistFilters(this.modelId)
    },
    async deleteFilter(){
        await ListModelFilterService.delete({id:this.oldFilterId});
        this.oldFilterId=null;
        this.filterName=null;
        this.filterDetails=[];
        this.getListModelDetails();
    }
  },
};
</script>
<style scoped>
.apply-filter {
  font-size: 15px;
  position: absolute;
  background: #3c5aaa;
  color: white;
  top: 10px;
  right: 60px;
  cursor: pointer;
}

.save-filter {
  font-size: 15px;
  color: white;
  width: 100%;
  text-align: center;
  background: #3c5aaa;
}
</style>