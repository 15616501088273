import Users from "@/modules/setting/admin/Users.vue"
import Role from "@/modules/setting/Identity/Role.vue"
import RolePermission from "@/modules/security/Permissions.vue"
import Identity from "@/modules/setting/Identity/Identity.vue"
import Security from "@/modules/security/security.vue"

export default [
    {
        path: '',
        component: Identity,
        children: [
            {
                path: "user",
                name: 'User',
                component: Users,
                meta: {
                    breadCrumb: [
                        {
                            to:'/identity/user',
                            text: 'Settings',
                            color: '#3C5AAA'
                        },
                        {
                            to:'/identity/user',
                            text: 'Identity',
                            color: '#3C5AAA'
                        },
                        {
                            to:'/identity/user',
                            text: 'User'
                        },
                    ]
                }
            },
            {
                path: "role",
                name: 'Role',
                component: Role,
                meta: {
                    breadCrumb: [
                        {
                            to:'/identity/role',
                            text: 'Settings',
                            color: '#3C5AAA'
                        },
                        {
                            to:'/identity/role',
                            text: 'Identity',
                            color: '#3C5AAA'
                        },
                        {
                            to:'/identity/role',
                            text: 'Role'
                        },
                    ]
                }
            },
            {
                path: "rolePermission",
                name: 'RolePermission',
                component: RolePermission,
                meta: {
                    breadCrumb: [
                        {
                            to:'/identity/rolePermission',
                            text: 'Settings',
                            color: '#3C5AAA'
                        },
                        {
                            to:'/identity/rolePermission',
                            text: 'Identity',
                            color: '#3C5AAA'
                        },
                        {
                            to:'/identity/rolePermission',
                            text: 'RolePermission'
                        },
                    ]
                }
            },
            {
                path: "security",
                name: 'Security',
                component: Security,
                meta: {
                    breadCrumb: [
                        {
                            to:'/identity/security',
                            text: 'Settings',
                            color: '#3C5AAA'
                        },
                        {
                            to:'/identity/security',
                            text: 'Identity',
                            color: '#3C5AAA'
                        },
                        {
                            to:'/identity/security',
                            text: 'Security'
                        },
                    ]
                }
            }
        ]
    }
]