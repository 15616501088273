<template>
  <PrimeTable
    :tableName="'Templates'"
    :activity="moduleName"
    :showTableHeader="true"
    :headers="column"
    :tableData="data"
    @rowAction="rowAction"
    @addDialog="addDialog($event)"
    :modelName="'messaging_templates'"
    @filterData="applyFilter($event)"
  ></PrimeTable>
  <v-dialog v-model="confirmation" width="400px" persistent>
    <ConfirmationDialog
      @cancelled="cancelled()"
      @deleted="deleted"
      :content="confirmationContent"
    />
  </v-dialog>
</template>
  <script>
import ConfirmationDialog from "@/components/DialogConfirmation.vue";
import MessagingTemplateService from "@/services/admin/MessagingTemplateService";
export default {
  components: {
    ConfirmationDialog,
  },
  data() {
    return {
      dialog: false,
      confirmationContent: null,
      confirmation: false,
      moduleName:'',
      data: [],
      column: [
        { title: "Code", align: "start", key: "code" },
        { title: "Name", align: "start", key: "name" },
        { title: "Domain", align: "start", key: "domain" },
        { title: "Modified By", align: "start", key: "users" },
        { title: "Modified On", align: "start", key: "modified" },
        { title: "Status", key: "active", sortable: false },
        { title: "Action", key: "actions", sortable: false },
      ],
      active: [
        { id: 1, name: "Active" },
        { id: 0, name: "Inactive" },
      ],
      editDataId: null,
      type: null,
    };
  },
  //   watch: {
  //     "$route.path": {
  //       handler: function (search) {
  //         this.type =
  //         search.indexOf("notification") > -1
  //             ? "notification" :
  //             search.indexOf("sms") > -1
  //             ? "sms" :
  //             search.indexOf("whatsapp") > -1
  //             ? "whatsapp"
  //             : "email";
  //         this.getTemplates();
  //       },
  //       deep: true,
  //       immediate: true,
  //     },
  //   },
  created() {
    this.moduleName = this.$route.name;
    this.type =
      this.$route.path.indexOf("notification") > -1
        ? "notification"
        : this.$route.path.indexOf("sms") > -1
        ? "sms"
        : this.$route.path.indexOf("whatsapp") > -1
        ? "whatsapp"
        : "email";
    let $value = {};
    this.getTemplates($value);
  },
  methods: {
    applyFilter($value){
      this.getTemplates($value);
    },
    async getTemplates($value) {
      let $res = await MessagingTemplateService.findAll({ type: this.type, ...$value});
      if ($res && $res.length > 0) {
        this.data = $res;
        this.data.map((obj) => {
          return (obj.active = this.active.find(
            (type) => type.id == obj.active
          ).name);
        });
      } else {
        this.data = [];
      }
    },
    rowAction(e) {
      this.dialog = false;
      if (e.type == "edit") {
        this.dialog = true;
        // this.type = e.type;
        this.editDataId = e.value.id;
        let $url = `/templates/${this.type}/add?id=${this.editDataId}&type=edit`;
        this.$router.push(`${$url}`);
      }
      if (e.type == "view") {
        this.dialog = true;
        // this.type = e.type;
        this.editDataId = e.value.id;
        let $url = `/templates/${this.type}/add?id=${this.editDataId}&type=view`;
        this.$router.push(`${$url}`);
      }
      if (e.type == "delete") {
        this.dialog = false;
        this.confirmation = true;
        this.confirmationContent = `Do you want to delete the Template ${e.value.name}`;
        this.editDataId = e.value.id;
      }
      if (e.type == "select") {
        this.dialog = true;
        // this.type = e.type;
        this.editDataId = e.value.id;
        let $url = `/templates/${this.type}/add?id=${this.editDataId}&type=view`;
        this.$router.push(`${$url}`);
      }
    },
    addDialog() {
      let $url = `/templates/${this.type}/add?type=add`;
      this.$router.push(`${$url}`);
    },
    closeDialog() {
      this.dialog = false;
      this.getTemplates();
    },
    async deleted() {
      this.confirmation = false;
      await MessagingTemplateService.delete({ id: this.editDataId });
      this.getTemplates();
    },
    cancelled() {
      this.editDataId = null;
      this.confirmation = false;
    },
  },
};
</script>