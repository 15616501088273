<template>
  <v-card>
    <v-form @submit.prevent="save" ref="form">
      <v-card-title class="dialog-Title">
       Add ListModel
        <v-btn density="comfortable" @click="closeDialog" elevation="0" icon="mdi-close" style="float: right"></v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" class="pa-0">
              <EnumDropdown :data="modelData.database_name" @textData="checkDatabase($event)" :disabled="showReadOnly"
                :rules="databaseRules" :label="'DataBase'" :domainName="'databases'" />
            </v-col>
            <v-col cols="12" class="pa-0">
              <AutoComplete :data="modelData.model" @textData="($event) => { modelData.model = $event; }" :rules="sourceRules"
                :text="'table_name'" :disabled="showReadOnly" :label="'Source'" :items="allTables"
                :value="'table_name'" />
            </v-col>
            <span v-if="showInvalid" style="color: #b00020;margin-left: 15px;">Already Exist</span>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="dialogfooter">
        <v-spacer></v-spacer>
        <v-btn class="mr-1 cancel" @click="closeDialog">Cancel</v-btn>
        <v-btn class="save" type="submit">Save</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import AutoComplete from "@/components/AutoComplete.vue";
import EnumDropdown from "@/components/EnumDropdown.vue"
import ListModelService from "@/services/admin/ListModelService";
export default {
  components: {
    EnumDropdown,
    AutoComplete,
  },
  data() {
    return {
      modelData: {
        model: null,
        database_name: null,
      },
      allTables: [],
      showInvalid: false,
      dataBaseData: [],
      modelRules: [(value) => !!value || "Model Is Required"],
      databaseRules: [(value) => !!value || "DataBase Is Required"],
      sourceRules: [(value) => !!value || "Source Is Required"],
    };
  },
  created() {

  },
  methods: {
    closeDialog() {
      this.$emit("close");
    },
    checkDatabase($value) {
      this.modelData.model = null
      this.modelData.database_name = $value
      this.getTables($value)
    },
    async getTables($value) {
      let $data = await ListModelService.getTables({ database: $value });
      this.allTables = $data && $data.length > 0 ? $data : [];
    },
    async save() {
      this.showInvalid = false;
      let validation = await this.$refs.form.validate();
      if (validation.valid) {
        let data = await ListModelService.findAll({ database_name: this.modelData.database_name, model: this.modelData.model })
        if (data && data.length > 0) {
          this.showInvalid = true
        }
        else {
          // let $res = await ListModelService.validateModel({
          //   model: this.modelData.model,
          // });
          // if ($res && $res.length > 0) {
          if (this.modelData && this.modelData.id) {
            await ListModelService.update(this.modelData);
            this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
            this.closeDialog()
          } else {
            await ListModelService.createListModels(this.modelData);
            this.$store.dispatch("setSnackbar", { text: "Saved Successfully" });
            this.closeDialog()
          }
        }
      }
    }
  },
};
</script>

<style></style>