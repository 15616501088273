<template>
    <v-col class="ml-2 settingHeader"> <v-icon color="#000000" class="mr-2" @click="$router.go(-1)">mdi-arrow-left-circle-outline</v-icon>Channel</v-col>
    <v-row class="pa-5 pt-0 contentsubmenu">
        <v-card elevation="0" class="content-menu-cardleft">
            <v-list class="content-menu-list">
                <v-list-item :to="item.router" class="pa-4 contentsidemenu" v-for="(item, index) in channelData" :key="index"
                    :value="index" active-class="selecteditem">
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-card>
        <v-card elevation="0" class=" content-menu-cardright">
            <RouterView :key="$route.fullPath"></RouterView>
        </v-card>
    </v-row>
</template>

<script>
export default {
    data(){
        return{
            channelData: [
                {
                    title: 'ListModel',
                    value: 1,
                    router: '/channel/listmodel',
                },
                {
                    title: 'Enum',
                    value: 2,
                    router: '/channel/enum'
                },
                {
                    title: 'Email',
                    value: 3,
                    router: '/channel/email'
                },
                {
                    title: 'SMS',
                    value: 4,
                    router: '/channel/sms'
                },
                {
                    title: 'Whatsapp',
                    value: 5,
                    router: '/channel/whatsapp'
                },
                {
                    title: 'Notification',
                    value: 6,
                    router: '/channel/notification'
                },
                {
                    title: 'Api',
                    value: 7,
                    router: '/channel/api'
                },
                {
                    title: 'Uplode Document',
                    value: 8,
                    router: '/channel/uploadDocument'
                },
                {
                    title: 'GL Integration',
                    value: 9,
                    router: '/channel/glIntegration'
                },
                {
                    title: 'Form Builder',
                    value: 10,
                    router: '/channel/formBuilder'
                },
                // {
                //     title: 'Nextflo Internal Api',
                //     value: 11,
                //     router: '/channel/nextfloInternalApi'
                // },
            ],
        }
    }
}
</script>

<style>

</style>