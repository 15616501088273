<template>
  <v-card>
    <v-card-title class="dialog-Title">
      {{
        type == "edit"
          ? "Edit"
          : type == "view"
          ? "View"
          : type == "add"
          ? "Add"
          : type == "select"
          ? "View"
          : ""
      }}
      Profile
      <v-btn
        density="comfortable"
        @click="closeDialog(false)"
        elevation="0"
        icon="mdi-close"
        style="float: right"
      ></v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <v-tabs v-model="activeTab" color="primary" v-if="this.tabs.length == 3">
      <v-tab v-for="(tab, index) in tabs" :key="index">
            {{ tab.title }}
      </v-tab>
    </v-tabs>
    <v-window v-model="activeTab">
      <v-window-item v-for="(tab, index) in tabs" :key="index">
        <component :is="tab.component" :id="this.tabs.userId" :type="this.Type" :hide="this.Type" @closeDialog="closeDialog()"></component>
      </v-window-item>
    </v-window>
  </v-card>
</template>

<script>
import AddUsers from "../AddAdmin/AddUsers.vue";
import UserRoleSecurities from "../Identity/UserRoleSecurities.vue";
import AddUserAccount from "./AddUserAccount.vue";
// import UserService from "@/services/admin/UserService";
export default {
  props: ["type", "id"],

  components: {
    AddUsers,
  },
  data() {
    return {
      activeTab: 0,
      Type: null,
      tabs: [
        {
          title: "User",
          component: AddUsers,
          userId: null,
        },
        {
          title: "Role",
          component: UserRoleSecurities,
          userId: null,
        },
        {
          title: "Account",
          component: AddUserAccount,
          userId: null,
        },
      ],
    };
  },
  created() {
    if (this.id){
      this.tabs.userId = this.id
      this.Type = this.type
      this.tabs.length = 3;
    }
    else{
      this.tabs.length = 1;
    }
  },
  methods:{
    // async getUserData(){
    //  let res = await UserService.findOne({id: this.id});
    //  this.id = res[0]
    // },
    closeDialog(){
      this.$emit("closeDialog",true)
    }
  }
};
</script>
