<template>
  <v-card elevation="0">
    <PrimeTable
      :tableName="'Roles'"
      activity="Role"
      :showTableHeader="true"
      :headers="column"
      :tableData="data"
      @addDialog="addDialog"
      @rowAction="rowAction($event)"
      :modelName="'roles'"
      @filterData="applyFilter($event)"
    ></PrimeTable>
  </v-card>
  <v-dialog v-model="dialog" width="600px" persistent>
    <AddRole
      :type="type"
      @closeDialog="closeDialog()"
      :id="this.editId"
    ></AddRole>
  </v-dialog>
  <v-dialog v-model="confirmation" width="300px" persistent>
    <ConfirmationDialog
      @cancelled="cancelled()"
      @deleted="deleted"
      :content="confirmationContent"
    />
  </v-dialog>
</template>
  
  <script>
import PrimeTable from "@/components/PrimeTable.vue";
import RoleService from "@/services/admin/RoleService";
import AddRole from "@/modules/setting/Identity/AddRole.vue";
import ConfirmationDialog from "@/components/DialogConfirmation.vue";
export default {
  props: ["id"],
  components: {
    PrimeTable,
    AddRole,
    ConfirmationDialog,
  },
  data() {
    return {
      dataId: null,
      data: [],
      column: [
        { title: "Code", align: "start", sortable: false, key: "code" },
        { title: "Name", align: "start", key: "name" },
        { title: "Role Type", align: "start", key: "role_type" },
        { title: "Status", align: "start", key: "active" },
        { title: "Modified By", align: "start", key: "users" },
        { title: "Modified On", align: "start", key: "modified" },
        { title: "Action", key: "actions", sortable: false },
      ],
      active: [
        { id: 1, name: "Active" },
        { id: 0, name: "Inactive" },
      ],
      dialog: false,
      confirmation: false,
      type: null,
      editId: null,
    };
  },
  created() {
    let $value = {};
    this.getRoleData($value);
  },
  methods: {
    rowAction($event) {
      if ($event.type == "edit") {
        this.dialog = true;
        this.type = $event.type;
        this.editId = $event.value.id;
        // console.log("iiiiiidddddddd",$event)
        // alert($event.id)
      }
      if ($event.type == "view") {
        this.dialog = true;
        this.type = $event.type;
        this.editId = $event.value.id;
      }
      if ($event.type == "delete") {
        this.dialog = false;
        this.confirmation = true;
        this.confirmationContent = `Do you want to delete the Role ${$event.value.name} ?`;
        this.editDataId = $event.value.id;
      }
      if ($event.type == "select") {
        this.dialog = true;
        this.type = $event.type;
        this.editId = $event.value.id;
      }
    },
    addDialog() {
      this.dialog = true;
      this.type = "add";
    },
    closeDialog() {
      this.dialog = false;
      this.getRoleData();
    },
    applyFilter($value){
      this.getRoleData($value);
    },
    async getRoleData($value) {
      let res = await RoleService.findAll($value);
      console.log("roleDatatataa", res);
      this.data = res;
      this.data.map((obj) => {
        return (obj.active = this.active.find(
          (type) => type.id == obj.active
        ).name);
      });
    },
    async deleted() {
      this.confirmation = false;
      await RoleService.delete({ id: this.editDataId });
      this.getRoleData();
    },
    cancelled() {
      this.editDataId = null;
      this.confirmation = false;
    },
  },
};
</script>