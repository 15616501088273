<template>
 <PrimeTable :tableName="'Shipment Rule'" activity="Category" :showTableHeader="true" :headers="column" 
    :tableData="data" @filterData="applyFilter($event)" @rowAction="rowAction($event)" @addDialog="addDialog">
  </PrimeTable>
  <v-dialog v-model="dialog" width="700px" persistent>
    <AddShipmentRule
      @closeDialog="closeDialog()"
      :showReadOnly="showReadOnly"
      :type="type"
      :id="editDataId"
      :shipments="shipments"
      :shippers="shippers"
      :shipmentServices="shipmentServices"
      :shippingZones="shippingZones"
      :fulFillments="fulFillments"
    />
  </v-dialog>
  <v-dialog v-model="confirmation" width="400px" persistent>
    <ConfirmationDialog
      @cancelled="cancelled()"
      @deleted="deleted"
      :content="confirmationContent"
    />
  </v-dialog>
</template>
<script>
import MwsPreferenceService from "@/services/mws/MwsPreferenceService";
import ConfirmationDialog from "@/components/DialogConfirmation.vue";
import moment from "moment";
import AddShipmentRule from "./AddShipmentRule.vue";
import ShipmentService from "@/services/orders/ShipmentService";
import ShipmentServiceService from "@/services/admin/ShipmentServiceService";
import FulfillmentService from "@/services/catalogs/FulfillmentService";
import ShipperService from "@/services/admin/ShipperService";
import ShippingZoneService from "@/services/admin/ShippingZoneService";
export default {
  components: {
    ConfirmationDialog,
    AddShipmentRule,
  },
  data() {
    return {
      column: [
        { title: "Fulfillment", align: "start", key: "fulfilmentName" },
         { title: "Shipment Service", align: "start", key: "shipmentServiceName" },
        { title: "Shipping Zone", align: "start", key: "shippingZoneName" },
        { title: "Shipper", align: "start", key: "shipperName" },
        { title: "Shipment", align: "start", key: "shipmentName" },
        { title: "Modified on", align: "start", key: "modified" },
        { title: "Action", key: "actions", sortable: false },
      ],
      dialog: false,
      data: [],
      editDataId: null,
      confirmationContent: null,
      confirmation: false,
      mwsId: localStorage.getItem("mwsHeaderId"),
      headers: [
        "Shipping Zone",
        "Fulfillment Center",
        "Shipment Provider",
        "Shipper",
        "Service",
        "Action",
      ],
      shippers: [],
      shipmentServices: [],
      shippingZones: [],
      fulFillments: [],
      shipments: [],
    };
  },
  async created() {
    await this.getFulfillments();
    await this.getShippers();
    await this.getShipmentServices();
    await this.getShippingZones();
    await this.getShipments();
    await this.getMws();
  },
  methods: {
    moment,
    async getShippers() {
      let $res = await ShipperService.findAll();
      if ($res && $res.length > 0) {
        this.shippers = $res;
      }
    },
    async getShipmentServices() {
      let $res = await ShipmentServiceService.findAll();
      if ($res && $res.length > 0) {
        this.shipmentServices = $res;
      }
    },
    async getShippingZones() {
      let $res = await ShippingZoneService.findAll();
      if ($res && $res.length > 0) {
        this.shippingZones = $res;
      }
    },
    async getFulfillments() {
      let $res = await FulfillmentService.findAll();
      if ($res && $res.length > 0) {
        this.fulFillments = $res;
      }
    },
    async getShipments() {
      let $res = await ShipmentService.findAll();
      if ($res && $res.length > 0) {
        this.shipments = $res;
      }
    },
    async getMws() {
      let $result = await MwsPreferenceService.findAll({
        mws_header_id: this.mwsId,
        rule_type: "shipment_rules",
      });
      if ($result && $result.length > 0) {
        $result.forEach((element) => {
          element.preferences = element.preferences
            ? JSON.parse(element.preferences)
            : {};
           element.fulfilmentName = this.fulFillments.filter(k => k.id == element.preferences.fulfillment_id)[0].name
           element.shipmentServiceName = this.shipmentServices.filter(k => k.id == element.preferences.shipment_service_id)[0].name
           element.shippingZoneName = this.shippingZones.filter(k => k.id == element.preferences.shipping_zone_id)[0].name
           element.shipperName = this.shippers.filter(k => k.id == element.preferences.shipper_id)[0].name
           element.shipmentName = this.shipmentServices.filter(k => k.id == element.preferences.shipment_id)[0].name
        });
        this.data = $result;
      }else{
        this.data = []
      }
    },
    rowAction(e) {
      if (e.type == "edit") {
        this.dialog = true;
        this.type = e.type;
        this.editDataId = e.value.id;
        this.showReadOnly = false;
      }
      if (e.type == "view") {
        this.dialog = true;
        this.type = e.type;
        this.editDataId = e.value.id;
        this.showReadOnly = true;
      }
      if (e.type == "delete") {
        this.dialog = false;
        this.confirmation = true;
        this.type = e.type;
        this.confirmationContent = `Do you want to delete the Shipment Rule ?`;
        this.editDataId = e.value.id;
      }
      if (e.type == "select") {
        this.dialog = true;
        this.type = e.type;
        this.showReadOnly = true;
      }
    },
    addDialog() {
      this.dialog = true;
      this.type = 'add';
      this.showReadOnly = false;
      this.editDataId = null;
    
    },
    closeDialog() {
      this.dialog = false;
      this.getMws();
    },
    async deleted() {
      this.confirmation = false;
      await MwsPreferenceService.delete({ id: this.editDataId });
      this.getMws();
    },
    cancelled() {
      this.editDataId = null;
      this.confirmation = false;
    },
  },
};
</script>
