<template>
  <v-card>
    <v-form @Submit.prevent="save" ref="form">
      <v-card-title>
        {{
          this.type == "add"
            ? "Add"
            : this.type == "edit"
            ? "Edit"
            : this.type == "view"
            ? "View"
            : "View"
        }}
        Location
        <v-btn
          density="comfortable"
          @click="closeDialog"
          elevation="0"
          icon="mdi-close"
          style="float: right"
        >
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="6" class="pa-0">
              <AutoComplete
                :data="location.fulfillment_id"
                @textData="($event) => {location.fulfillment_id = $event;}"
                :items="fulFillments"
                :text="'name'"
                :value="'id'"
                :disabled="showReadOnly"
                :rules="fulfillmentRule"
                label="Fulfillment"
              ></AutoComplete>
            </v-col>
            <v-col cols="6" class="pa-0 pl-2">
              <InputBox
                :data="location.address1"
                @textData="($event) => {location.address1 = $event;}"
                :label="'Address1'"
                :disabled="showReadOnly"
              />
            </v-col>
            <v-col cols="6" class="pa-0">
              <InputBox
                :data="location.address2"
                @textData="($event) => {location.address2 = $event;}"
                :label="'Address2'"
                :disabled="showReadOnly"
              />
            </v-col>
            <v-col cols="6" class="pa-0 pl-2">
              <InputBox
               :data="location.address3"
                @textData="($event) => {location.address3 = $event;}"
                :label="'Address3'"
                :disabled="showReadOnly"
              />
            </v-col>
            <v-col cols="6" class="pa-0">
              <InputBox
                :data="location.city"
                @textData="($event) => {location.city = $event;}"
                :label="'City'"
                :disabled="showReadOnly"
                :rules="cityRule"
              />
            </v-col>
            <v-col cols="6" class="pa-0 pl-2">
              <AutoComplete
                :data="location.country"
                @textData="countrySelect($event)"
                :items="contries"
                :text="'name'"
                :value="'id'"
                :disabled="showReadOnly"
                :rules="countryRule"
                label="Country"
              ></AutoComplete>
            </v-col>
            <v-col cols="6" class="pa-0">
              <AutoComplete
                :data="location.state"
                @textData="stateSelect($event)"
                :items="displacyStates"
                :text="'name'"
                :value="'id'"
                :rules="stateRule"
                :disabled="showReadOnly"
                label="State"
                @click="stateSelect"
              ></AutoComplete>
            </v-col>
            <v-col cols="6" class="pa-0 pl-2">
              <InputBox
                :data="location.zip_code"
                @textData="allowNumbers($event)"
                :label="'Zip Code'"
                :disabled="showReadOnly"
                :rules="zipCodeRule"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="dialogfooter pa-5" v-if="!showReadOnly">
        <v-spacer> </v-spacer>
        <v-btn class="mr-1 cancel" @click="closeDialog">Cancel</v-btn>
        <v-btn class="save" type="submit">Save</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
import MwsFulfillmentService from "@/services/mws/MwsFulfillmentService";
import FulfillmentService from "@/services/catalogs/FulfillmentService";
import Country from "@/api/service/Countries.json";
import States from "@/api/service/States.json";
export default {
  props: ["type", "id", "showReadOnly"],
  data() {
    return {
      mwsId: localStorage.getItem("mwsHeaderId"),
      fulFillments: [],
      stateData: States,
      displacyStates: [],
      contries: Country,
      location: {
        id: null,
        fulfillment_id: null,
        mws_header_id: localStorage.getItem("mwsHeaderId"),
        mws_fulfillment_id: null,
        address1: null,
        address2: null,
        address3: null,
        city: null,
        state: null,
        country: null,
        zip_code: null,
      },
      fulfillmentRule: [(value) => !!value || "Fulfillment is Required"],
      cityRule: [(value) => !!value || "City is Required"],
      stateRule: [(value) => !!value || "State is Required"],
      countryRule: [(value) => !!value || "Country is Required"],
      zipCodeRule:[(value) => !!value || "Zip Code is Required"]
    };
  },
  created() {
    if (this.id && this.type != "add") {
      this.getLocation(this.id);
    }
    this.getFulFillments();
  },
  methods: {
    allowNumbers(evt) {
      this.location.zip_code = evt
      // this.location.zip_code = this.location.zip_code ? this.location.zip_code : window.event;
      // let expect = this.location.zip_code.target.value.toString() + this.location.zip_code.key.toString();
      // if (!/^[+]?[0-9]*\.?[0-9]*$/.test(expect)) {
      //   this.location.zip_code.preventDefault();
      // } else {
      //   return true;
      // }
    },
    countrySelect($value) {
      this.location.country = $value
      this.location.state = null;
      this.displacyStates = this.stateData[this.location.country];
    },
    stateSelect($value) {
      this.location.state = $value
      this.displacyStates = this.stateData[this.location.country];
    },
    async getLocation($id) {
      let $res = await MwsFulfillmentService.findAll({ id: $id });
      if ($res && $res.length > 0) {
        this.location = $res[0];
      }
    },
    async getFulFillments() {
      let $res = await FulfillmentService.findAll({});
      if ($res && $res.length > 0) {
        this.fulFillments = $res;
      }
      else{
      this.fulFillments=[];
    }
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
    async save() {
      let validation = await this.$refs.form.validate();
      if (!validation.valid) {
        console.log(validation);
      } else {
        delete this.location.fulfillments
        delete this.location.mws_headers
        if (this.location && this.location.id) {
          await MwsFulfillmentService.update(this.location);
          this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });

          this.closeDialog();
        } else {
          try {
            await MwsFulfillmentService.create(this.location);
            this.$store.dispatch("setSnackbar", { text: "Saved Successfully" });
            this.closeDialog();
          } catch (e) {
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "Something Went Wrong",
            });
          }
        }
      }
    },
  },
};
</script>