<template>
  <v-card elevation="0">
    <PrimeTable :tableName="'Reports'" activity="Create Report" :showTableHeader="true" :headers="column" :tableData="reportsData" @addDialog="addDialog"
      @rowAction="rowAction($event)" :hideView="hideView" :settingIcon="settingIcon" @createReport="createReport" :delete="true" :view="true"></PrimeTable>
  </v-card>
</template>
<script>
import ReportService from '@/services/admin/ReportService';
export default{
    data(){
        return{
        column: [
        { title: 'Model Id', align: 'start', sortable: false, key: 'data_set_id' },
        { title: 'Name', align: 'start', key: 'name' },
        { title: 'Source', align: 'start', key: 'url' },
        { title: 'Active', align: 'start', key: 'active' },
        { title: 'Modified', key: 'modified', align: 'start' },
        { title: 'Action', key: 'actions', sortable: false,align: 'center' },
      ],
      reportsData:[],
      active: [
        { id: 1, name: "Active" },
        { id: 0, name: "Inactive" },
      ],
      type: null
        }
    },
    created(){
        this.getData();
    },
    methods:{
     async getData(){
       await ReportService.findAll()
       .then((response) => {
          this.reportsData = response;
          this.reportsData.map((obj) => {
            return (obj.active = this.active.find(
              (type) => type.id == obj.active
            ).name);
          });
        })
        .catch((error) => {
          console.log(error);
        });
     },
     rowAction($event){
        if($event.type == 'edit'){
            let reportId = $event.value.id
            this.$router.push({ path: '/editreport', query: { id: reportId, type : 'edit' } })
        }
        if ($event.type == "select") {
            let reportId = $event.value.id;
            this.$router.push({path: '/editreport', query: { id: reportId, type : 'view'}})
      }
     },
     addDialog(){
       this.$router.push({path: '/editreport', query: {type : 'create'}})
     },
    }
}
</script>