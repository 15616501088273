<template>
    <v-card>
        <v-card-text>
            <v-container>
                <v-row no-gutters dense class="mb-3">
                    <v-col cols="12" class="pa-2">
                        <AutoComplete :label="'Account'" :disabled="showReadOnly"
                        :data="formData.account_id"
                        @textData="getAccountChipData($event)"
                        :rules="accountRule"  :items="accountData"
                        :value="'id'" :text="'name'" />
                    </v-col>
                    <v-error v-if="errorMsg" class="error">Account is Required</v-error>
                    <v-col cols="12" class="pa-2">
                        <v-chip style="margin-right: 5px;color: white;background:#3C5AAA;" closable
                            v-for="item in accountChipData" :key="item" @click:close="removeChip(item)" :disabled="showReadOnly">
                            <span>{{ item.name }}</span>
                        </v-chip>
                    </v-col>
                </v-row>
                <!-- <v-divider></v-divider> -->
            </v-container>
        </v-card-text>
        <v-card-actions class="dialogfooter" v-if="!showReadOnly">
            <v-spacer></v-spacer>
            <v-btn class="mr-1 cancel" @click="closeDialog">Cancel</v-btn>
            <v-btn class="save" @click="save()">Save</v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
import AccountService from "@/services/admin/AccountService";
import CompanyXrefService from "@/services/admin/CompanyXrefService";
// import { authStore } from "@/store";
export default {
    props: ["type", "id"],
    components: {},
    data() {
        return {
            errorMsg: false,
            accountRule:[(value) => !!value || "Account is Required"],
            formData: { account_id: null},
            showReadOnly: false,
            accountChipData: [],
            accountData: [],
            companyXrefData: [],
            user_id: null,
        };
    },
    async created() {
        if (this.type == 'view'){
            this.showReadOnly = true
        }
        let userDetails = JSON.parse(localStorage.getItem('userDetails'))
        this.user_id = userDetails.id
        await this.getEditData();
        this.getAccount();
    },
    methods: {
        getAccountChipData($value) {
            this.errorMsg = false;
            this.formData.account_id = $value
            console.log("1111111",this.formData.account_id)
            console.log($value, 'chipdata')
            let $data = this.accountData.filter(f => f.id == $value)
            this.accountChipData = this.accountChipData.concat($data)
            let $i = this.accountData.findIndex(x => x.id == $value)
            this.accountData.splice($i, 1)
            this.formData.account_id = null
        },
        removeChip(data) {
            // console.log(data, 'mjvjedkt34ktjemg')
            this.accountData = this.accountData.concat(data)
            let $i = this.accountChipData.findIndex(x => x.id == data.id)
            this.accountChipData.splice($i, 1)
        },
        async getEditData() {
            let $res = await CompanyXrefService.findAll({ user_id: this.user_id });
            this.companyXrefData = $res[0];
            console.log(this.companyXrefData, "formData");
        },
        async getAccount() {
            let $res = await AccountService.findAll();
            let $account = this.companyXrefData.account_id ? JSON.parse(this.companyXrefData.account_id) : []
            for (let a = 0; a < $account.length; a++) {
                let $i = $res.findIndex(f => f.id == $account[a])
                this.accountChipData = this.accountChipData.concat($res[$i])
                $res.splice($i, 1)
            }
            this.accountData = $res&&$res.length>0 ? $res : [];
            console.log(this.accountData, "formData");
        },

        closeDialog() {
            this.$emit("closeDialog");
        },
        async save() {
            console.log(this.accountChipData, "data");
            if (this.accountChipData.length > 0) {
                let $accountId = this.accountChipData.map(x => x.id)
                let data = {
                    id: this.companyXrefData.id,
                    account_id: JSON.stringify($accountId)
                }
                let output = await CompanyXrefService.update(data);
                this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
                let status = this.$store.state.accountStatus;
                this.$store.commit('setAccountStatus', !status)
                console.log(output);
                this.closeDialog();
            }
            else{
                this.errorMsg = true;
            }
        },
    },
};
</script>
<style>
.error{
  color: #b00020;
  padding-top: 0px;
  margin-top: 0px;
  padding-left: 11px;
  padding-bottom: 10px;
  margin-bottom: 5px;
  font-size: 12px;
}
</style>
  