<template>
  <v-card>
    <v-form @Submit.prevent="save" ref="form">
      <v-card-title class="dialog-Title">
        {{
          type == "edit"
            ? "Edit"
            : type == "view"
            ? "View"
            : type == "add"
            ? "Add"
            : type == "select"
            ? "View"
            : ""
        }}
        Shipping Zone

        <v-btn
          density="comfortable"
          @click="closeDialog"
          elevation="0"
          icon="mdi-close"
          style="float: right"
        >
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" class="pa-0">
              <InputBox
                :label="'Code'"
                :data="formData.code"
                @textData="checkCode($event)"
                :rules="codeRule"
                :disabled="showReadOnly"
                @keydowninput="prevent"
              />
              <span v-if="codeError" class="error-field">Already Exists!</span>
            </v-col>
            <v-col cols="12" class="pa-0">
              <InputBox
                :label="'Name'"
                :data="formData.name"
                @textData="
                  ($event) => {
                    formData.name = $event;
                  }
                "
                :rules="nameRule"
                :disabled="showReadOnly"
              />
            </v-col>
            <v-col cols="6" class="pa-0">
              <AutoComplete
                :label="'Country'"
                :data="formData.country"
                @textData="updateState($event)"
                :text="'name'"
                :value="'id'"
                :items="Country"
                :disabled="showReadOnly"
                :rules="countryRule"
              />
            </v-col>
            <v-col cols="6" class="pa-0 pl-2">
              <AutoComplete
                :label="'State'"
                :data="formData.state"
                @textData="
                  ($event) => {
                    formData.state = $event;
                  }
                "
                :text="'name'"
                :value="'id'"
                :items="diplayStates"
                :disabled="showReadOnly"
                :rules="stateRule"
                @click="selectState"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="dialogfooter" v-if="!showReadOnly">
        <v-spacer></v-spacer>
        <v-btn class="mr-1 cancel" @click="closeDialog">Cancel</v-btn>
        <v-btn class="save" type="submit">Save</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import ShippingZoneService from "@/services/admin/ShippingZoneService";
import Country from "@/api/service/Countries.json";
import States from "@/api/service/States.json";
export default {
  props: ["type", "id"],
  data() {
    return {
      codeError: false,
      codeExistFlag: false,
      showReadOnly: false,
      sateData: States,
      Country: Country,
      diplayStates: [],
      codeRule: [(value) => !!value || "Code is Required"],
      nameRule: [(value) => !!value || "Name is Required"],
      countryRule: [(value) => !!value || "Country is Required"],
      stateRule: [(value) => !!value || "State is Required"],
      formData: {
        name: null,
        code: null,
        country: null,
        state: null,
      },
    };
  },
  async created() {
    if (this.type == "view" || this.type == "select") {
      this.showReadOnly = true;
    }
    if (this.id) {
      let res = await ShippingZoneService.findOne({ id: this.id });
      this.formData = res[0];
    }
  },
  methods: {
    updateState($event) {
      this.formData.country = $event
      this.formData.state = null;
      this.diplayStates = this.sateData[this.formData.country];
    },
    selectState() {
      this.diplayStates = this.sateData[this.formData.country];
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
    prevent(e) {
      if (!/^[a-zA-Z0-9-_ \t]+$/.test(e.key) && e.key !== "Backspace") {
        e.preventDefault();
      }
    },
    checkCode($event) {
      this.formData.code = $event;
      if (this.formData.code && this.formData.code.length > 0) {
        this.formData.code = this.formData.code.toUpperCase();

        ShippingZoneService.findAll({ code: this.formData.code }).then(
          (res) => {
            let $exist = res.filter((key) => key.code == this.formData.code);
            console.log($exist, "oness");
            this.codeExistFlag =
              $exist.length > 0
                ? this.formData.id && $exist[0].id == this.formData.id
                  ? false
                  : true
                : false;

            if (this.codeExistFlag) {
              this.codeError = true;
            } else {
              this.codeError = false;
            }
          }
        );
      }
    },

    async save() {
      let validation = await this.$refs.form.validate();
      if (!validation.valid || this.codeError) {
        console.log("Not valid");
      } else {
        if (this.id) {
          delete this.formData.users;
          await ShippingZoneService.update(this.formData);
          this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
          this.$emit("closeDialog", true);
          this.$emit("reload", true);
        } else {
          try {
            await ShippingZoneService.create(this.formData);
            this.$store.dispatch("setSnackbar", { text: "Saved Successfully" });
            this.$emit("closeDialog", true);
            this.$emit("reload", true);
          } catch (e) {
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "Something Went Wrong",
            });
          }
        }
      }
    },
  },
};
</script>

<style>
</style>