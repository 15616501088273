<template>
  <svg width="125" height="38" viewBox="0 0 125 38" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" id="circles" d="M77.5478 29.246C70.2838 34.5615 59.7551 37.3975 50.6901 37.3975C37.9796 37.3975 26.5368 32.7303 17.8799 24.9677C17.1997 24.3573 17.8091 23.5254 18.6253 24.0008C27.9678 29.3973 39.5194 32.6438 51.4519 32.6438C59.4994 32.6438 68.3522 30.9909 76.4922 27.5606C77.7219 27.042 78.7503 28.3601 77.5478 29.246" fill="#FF9900"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M80.5674 25.8158C79.6424 24.6382 74.4297 25.2594 72.09 25.5349C71.3772 25.6213 71.2684 25.0055 71.9105 24.5625C76.0621 21.6617 82.8744 22.499 83.6688 23.4714C84.4632 24.4491 83.4621 31.2285 79.5607 34.4643C78.9622 34.9612 78.3909 34.6966 78.6575 34.0375C79.5335 31.8659 81.4978 26.9988 80.5674 25.8158" fill="#FF9900"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M72.2535 4.08388V1.26408C72.2535 0.837327 72.5799 0.551025 72.9717 0.551025H85.6877C86.0958 0.551025 86.4223 0.84273 86.4223 1.26408V3.67874C86.4168 4.08388 86.074 4.61327 85.4646 5.45057L78.8754 14.7905C81.3239 14.7311 83.9085 15.093 86.1285 16.3355C86.629 16.6164 86.7651 17.0269 86.8032 17.432V20.4409C86.8032 20.8515 86.3461 21.3322 85.8673 21.0837C81.9551 19.0472 76.7587 18.8257 72.433 21.1054C71.9923 21.343 71.5298 20.8677 71.5298 20.4571V17.5995C71.5298 17.1403 71.5352 16.3571 71.9977 15.6602L79.6317 4.79154H72.988C72.5799 4.79154 72.2535 4.50524 72.2535 4.08388" fill="black"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M25.8673 21.6779H21.9986C21.6286 21.6509 21.3348 21.3754 21.3076 21.0243V1.31269C21.3076 0.91835 21.6395 0.605036 22.053 0.605036H25.6605C26.0359 0.621231 26.3352 0.907543 26.3624 1.26407V3.84079H26.4331C27.3745 1.3505 29.1428 0.189087 31.5261 0.189087C33.9474 0.189087 35.46 1.3505 36.5483 3.84079C37.4842 1.3505 39.6117 0.189087 41.8915 0.189087C43.513 0.189087 45.2868 0.853524 46.3696 2.34446C47.5939 4.00285 47.3436 6.4121 47.3436 8.52426L47.3381 20.9649C47.3381 21.3592 47.0062 21.6779 46.5927 21.6779H42.7295C42.3431 21.6509 42.033 21.343 42.033 20.9649V10.5176C42.033 9.68567 42.1092 7.61133 41.9242 6.82265C41.6358 5.49918 40.7706 5.12645 39.6497 5.12645C38.7139 5.12645 37.7345 5.74767 37.3373 6.74162C36.94 7.73558 36.9781 9.39937 36.9781 10.5176V20.9649C36.9781 21.3592 36.6462 21.6779 36.2327 21.6779H32.3695C31.9777 21.6509 31.673 21.343 31.673 20.9649L31.6676 10.5176C31.6676 8.31899 32.0321 5.08323 29.2843 5.08323C26.5039 5.08323 26.6127 8.23795 26.6127 10.5176V20.9649C26.6127 21.3592 26.2808 21.6779 25.8673 21.6779" fill="black"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M97.3697 0.189087C103.11 0.189087 106.217 5.08323 106.217 11.3063C106.217 17.3186 102.784 22.0885 97.3697 22.0885C91.7327 22.0885 88.6638 17.1944 88.6638 11.0956C88.6638 4.95899 91.7708 0.189087 97.3697 0.189087V0.189087ZM97.4024 4.21352C94.5512 4.21352 94.3716 8.0705 94.3716 10.4744C94.3716 12.8836 94.3335 18.0263 97.3697 18.0263C100.368 18.0263 100.509 13.8776 100.509 11.3495C100.509 9.68568 100.439 7.69777 99.9325 6.1204C99.4972 4.74832 98.6321 4.21352 97.4024 4.21352" fill="black"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M113.661 21.6779H109.808C109.422 21.6509 109.112 21.343 109.112 20.9649L109.106 1.24786C109.139 0.885935 109.46 0.605036 109.852 0.605036H113.438C113.775 0.621231 114.052 0.848123 114.129 1.15603V4.17031H114.199C115.282 1.47475 116.8 0.189087 119.472 0.189087C121.208 0.189087 122.9 0.810309 123.988 2.51191C125 4.08928 125 6.74162 125 8.6485V21.0567C124.956 21.4024 124.635 21.6779 124.255 21.6779H120.375C120.021 21.6509 119.728 21.3916 119.689 21.0567V10.3501C119.689 8.19474 119.94 5.04001 117.268 5.04001C116.327 5.04001 115.462 5.66664 115.032 6.61738C114.488 7.82201 114.417 9.02124 114.417 10.3501V20.9649C114.411 21.3592 114.074 21.6779 113.661 21.6779" fill="black"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M62.1164 12.2624C62.1164 13.7587 62.1545 15.0065 61.3927 16.3354C60.7778 17.4158 59.7984 18.0802 58.7156 18.0802C57.2302 18.0802 56.3596 16.9566 56.3596 15.2983C56.3596 12.0247 59.3142 11.4305 62.1164 11.4305V12.2624V12.2624ZM66.0177 21.6239C65.762 21.8508 65.392 21.867 65.1036 21.7157C63.8195 20.657 63.5855 20.1654 62.8836 19.1552C60.7615 21.3052 59.2543 21.948 56.5065 21.948C53.2472 21.948 50.7171 19.9547 50.7171 15.9627C50.7171 12.8458 52.4148 10.7228 54.8415 9.68566C56.9418 8.76732 59.8746 8.60527 62.1164 8.35138V7.8544C62.1164 6.94147 62.1871 5.86109 61.643 5.07241C61.175 4.36476 60.2718 4.07305 59.472 4.07305C57.9974 4.07305 56.6861 4.82392 56.365 6.37967C56.2997 6.7254 56.044 7.06572 55.6903 7.08193L51.9414 6.68218C51.6258 6.61196 51.2721 6.35807 51.3646 5.8773C52.2243 1.36129 56.3378 0 60.0161 0C61.8987 0 64.3581 0.496978 65.8436 1.91228C67.7262 3.6571 67.5467 5.98533 67.5467 8.51884V14.5042C67.5467 16.303 68.2975 17.0917 69.0049 18.064C69.2497 18.4098 69.3042 18.8257 68.9886 19.085C68.1996 19.7386 66.7958 20.9541 66.0231 21.6347L66.0177 21.6239" fill="black"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.3993 12.2625C11.3993 13.7588 11.4373 15.0067 10.6756 16.3355C10.0607 17.4159 9.08676 18.0804 7.99852 18.0804C6.51308 18.0804 5.64794 16.9568 5.64794 15.2984C5.64794 12.0248 8.60249 11.4306 11.3993 11.4306V12.2625V12.2625ZM15.3006 21.624C15.0448 21.8509 14.6748 21.8671 14.3865 21.7159C13.1023 20.6571 12.8738 20.1655 12.1665 19.1554C10.0444 21.3053 8.54264 21.9481 5.78941 21.9481C2.53558 21.9481 0 19.9548 0 15.9628C0 12.8459 1.70309 10.7229 4.12441 9.68578C6.2247 8.76745 9.15749 8.60539 11.3993 8.3515V7.85452C11.3993 6.9416 11.47 5.86121 10.9313 5.07253C10.4579 4.36488 9.5547 4.07317 8.76029 4.07317C7.28573 4.07317 5.96897 4.82404 5.64794 6.3798C5.58264 6.72552 5.32691 7.06584 4.97867 7.08205L1.22426 6.6823C0.908675 6.61208 0.56044 6.35819 0.647499 5.87742C1.51265 1.36141 5.62073 0.00012207 9.29896 0.00012207C11.1816 0.00012207 13.641 0.4971 15.1265 1.9124C17.0091 3.65723 16.8295 5.98546 16.8295 8.51896V14.5043C16.8295 16.3031 17.5804 17.0918 18.2878 18.0642C18.5381 18.4099 18.5925 18.8258 18.2769 19.0851C17.4879 19.7388 16.0841 20.9542 15.3115 21.6348L15.3006 21.624" fill="black"/>
</svg> 
</template>