<template>
  <PrimeTable :tableName="'Category'" activity="Category" :showTableHeader="true" :headers="column" :modelName="'categories'"
    :tableData="data" @filterData="applyFilter($event)" @rowAction="rowAction($event)" @addDialog="addDialog">
  </PrimeTable>
  <v-dialog v-model="dialog" width="600px" persistent>
    <AddCategory @closeDialog="closeDialog" :type="type" :id="editDataId" @reload="getCategory()" />
  </v-dialog>
  <v-dialog v-model="confirmation" width="300px" persistent>
    <ConfirmationDialog @cancelled="cancelled()" @deleted="deleted" :content="confirmationContent" />
  </v-dialog>
</template>
  
<script>
import CategoryService from "@/services/catalogs/CategoryService.js";
import AddCategory from "@/modules/setting/AddAdmin/AddCategory.vue";
import ConfirmationDialog from "@/components/DialogConfirmation.vue";
// import DashBoardService from "@/services/admin/DashBoardService";
export default {
  components: {
    AddCategory,
    ConfirmationDialog
  },
  data() {
    return {
      dialog: false,
      confirmation: false,
      data: [],
      column: [
        { title: "", align: "start", key: "style_images" },
        { title: "Name", align: "start", key: "name" },
        { title: "Code", align: "start", key: "code" },
        { title: "Product Type", align: "start", key: "product_types" },
        { title: "Modified by", align: "start", key: "users" },
        { title: "Modified on", align: "start", key: "modified" },
        { title: "Status", align: "start", key: "active" },
        { title: "Action", key: "actions", sortable: false },
      ],
      active: [
        { id: 1, name: "Active" },
        { id: 0, name: "Inactive" },
      ],
    };
  },
  created() {
    let $value = {}
    this.getCategory($value);
  },
  methods: {
    async getCategory($value) {
      await CategoryService.findAll($value)
        .then(response => {
          console.log(response, "working11111")
          this.data = response
          this.data.map(obj => {
            return obj.active = this.active.find(type => type.id == obj.active).name
          })
        })
        .catch(error => {
          console.log(error);
        });
    },
    async applyFilter($value) {
      console.log($value, 'valueeeeeeee')
      this.getCategory($value)
      // this.getFilter($value)
      // let res = await DashBoardService.getFilterData({CustomFilterData:JSON.stringify($value)})
      // await DashBoardService.getFilterData({CustomFilterData:JSON.stringify($value),tableName:'categories',databaseName:'catalogs'})
      //   .then(response => {
      //     console.log(response, "working11111")
      //     this.data = response
      //     this.data.map(obj => {
      //       return obj.active = this.active.find(type => type.id == obj.active).name
      //     })
      //   })
      //   .catch(error => {
      //     console.log(error);
      //   });
    },
    rowAction(e) {
      if (e.type == "edit") {
        this.dialog = true;
        this.editDataId = e.value.id;
        this.type = e.type;
      }
      if (e.type == "view") {
        this.dialog = true;
        this.editDataId = e.value.id;
        this.type = e.type;
      }
      if (e.type == "delete") {
        this.dialog = false;
        this.confirmation = true;
        this.confirmationContent = `Do you want to delete the Category ${e.value.name} ?`
        this.editDataId = e.value.id;
      }
      if (e.type == "select") {
        this.dialog = true;
        this.editDataId = e.value.id;
        this.type = e.type;
      }
    },
    addDialog() {
      this.dialog = true;
      this.type = "add";
      this.editDataId = null
    },
    closeDialog() {
      this.dialog = false;
      this.getCategory();
    },
    async deleted() {
      this.confirmation = false;
      await CategoryService.delete({ id: this.editDataId });
      this.getCategory();
    },
    cancelled() {
      this.editDataId = null;
      this.confirmation = false;
    }
  },
};
</script>