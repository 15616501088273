<template>
    <v-card elevation="0" >
        <v-card-text>
          <!-- Title Card Details -->
         <v-row class="pa-1">
             <v-col cols="2" class="spacing">
               <span class="p-title">ERP Order id</span>
               <div class="pt-1"><b class="mr-1" id="ErpOrderId">{{ data && data.mws_orders && data.mws_orders.merchant_order_id ? data.mws_orders.merchant_order_id : 'No ERP Ref'}}</b><v-icon size="15px" @click="copyText(orderdata.mws_order_item_id)" v-if="data.mws_orders.merchant_order_id"><v-img src="https://s3.ap-south-1.amazonaws.com/xtend3.0/icons/edit/copy.svg" width="10px"></v-img></v-icon></div>
             </v-col>
             <v-col cols="2" class="spacing">
               <span class="p-title">ERP Cust #</span>
               <div class="pt-1"><b>{{ data && data.mws_orders ? data.mws_orders.order_ref_id :'' }}</b></div>
             </v-col>
             <v-col cols="2" class="spacing">
               <span class="p-title">Store</span>
               <div class="pt-1"><b>{{ this.$route.query.store}}</b></div>
             </v-col>
             <v-col cols="2" class="spacing">
               <span class="p-title">Order Date</span>
               <div class="pt-1"><b>{{ data && data.mws_orders ? moment(data.mws_orders.order_date).format("DD-MM-YYYY") :'' }}</b></div>
             </v-col>
             <v-col cols="2" class="spacing">
               <span class="p-title">Shipping Method</span>
               <div class="pt-1"><b>{{ data && data.mws_orders && data.mws_orders.shipment_method ? data.mws_orders.shipment_method : 'None'}}</b></div>
             </v-col>
             <v-col cols="2" class="spacing">
               <span class="p-title">Shipping Service</span>
               <div class="pt-1"><b>Warehouse/Ship Station/Endica</b></div>
             </v-col>
         </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
import moment from 'moment';

export default{
  props:['data'],
  data(){
    return{
      orderdata:[],
    }
  },
  methods:{
    moment,
    async getDatas(){
      // this.data.created = (new Date(this.datas.created)).toDateString()
    },
    copyText(text) {
      navigator.clipboard.writeText(text);
    }
  
  }

}

</script>

<style scoped>
.p-title{
  color: #636363;
}
.spacing{
  padding: 0px;
  margin: 0px;
  padding-left: 1px;
  padding-right: 1px;
}
</style>