<template>
    <svg width="151" height="40" viewBox="0 0 151 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M61.7136 5.53455H65.134V21.9922H71.03V25.2408H61.7279L61.7136 5.53455Z" fill="#000083"/>
<path d="M78.9512 10.4719C80.7633 10.4492 82.4812 11.2752 83.5955 12.7044V10.7653H86.7865V25.2695H83.5955V23.3304C82.4812 24.7595 80.7633 25.5855 78.9512 25.5628C74.9728 25.5628 71.9531 22.164 71.9531 18.0138C71.9531 13.8636 74.9728 10.4719 78.9512 10.4719V10.4719ZM79.5312 13.377C76.9479 13.377 75.2374 15.4092 75.2374 18.0138C75.2374 20.6184 76.9762 22.6577 79.5312 22.6577C82.0856 22.6577 83.8244 20.6255 83.8244 18.0138C83.8244 15.402 82.1139 13.377 79.5312 13.377" fill="#000083"/>
<path d="M89.2696 22.7508L96.8759 13.6634H89.3838V10.7654H100.997V13.3199L93.4198 22.3716H101.169V25.2696H89.2696V22.7508" fill="#000083"/>
<path d="M109.284 10.4719C111.095 10.4492 112.813 11.2752 113.927 12.7044V10.7653H117.119V25.2695H113.927V23.3304C112.813 24.7595 111.095 25.5855 109.284 25.5628C105.305 25.5628 102.292 22.164 102.292 18.0138C102.292 13.8636 105.305 10.4719 109.284 10.4719V10.4719ZM109.863 13.377C107.28 13.377 105.57 15.4092 105.57 18.0138C105.57 20.6184 107.308 22.6577 109.863 22.6577C112.417 22.6577 114.156 20.6255 114.156 18.0138C114.156 15.402 112.446 13.377 109.863 13.377" fill="#000083"/>
<path d="M126.235 10.4718C128.045 10.448 129.761 11.2745 130.872 12.7043V4.36816H134.063V25.2694H130.872V23.3303C129.761 24.7601 128.045 25.5866 126.235 25.5628C122.257 25.5628 119.237 22.1639 119.237 18.0137C119.237 13.8635 122.257 10.4718 126.235 10.4718V10.4718ZM126.814 13.3769C124.231 13.3769 122.521 15.4091 122.521 18.0137C122.521 20.6183 124.267 22.6577 126.814 22.6577C129.362 22.6577 131.108 20.6255 131.108 18.0137C131.108 15.402 129.398 13.3769 126.814 13.3769" fill="#000083"/>
<path d="M143.179 10.4719C144.989 10.4482 146.706 11.2746 147.816 12.7044V10.7653H151V25.2695H147.802V23.3304C146.691 24.7602 144.975 25.5867 143.165 25.5629C139.186 25.5629 136.167 22.164 136.167 18.0139C136.167 13.8636 139.201 10.4719 143.179 10.4719V10.4719ZM143.759 13.377C141.176 13.377 139.465 15.4092 139.465 18.0139C139.465 20.6185 141.211 22.6578 143.759 22.6578C146.306 22.6578 148.052 20.6256 148.052 18.0139C148.052 15.4021 146.342 13.377 143.759 13.377" fill="#000083"/>
<path d="M24.3786 39.5678C24.0326 39.5702 23.692 39.4816 23.3909 39.311C20.813 37.8195 1.72046 25.8485 0.999435 25.483C0.450707 25.2269 0.0760107 24.7024 0.0117211 24.1002V7.30907C-0.00247766 6.6823 0.307374 6.09247 0.831521 5.7485L0.969801 5.66948C2.81683 4.52373 8.99988 0.750663 9.97772 0.20742C10.2021 0.0742615 10.4577 0.0027094 10.7185 0C10.9631 0.00276723 11.2035 0.0637098 11.4198 0.177789C11.4198 0.177789 20.0722 5.81764 21.3957 6.32137C22.3244 6.74802 23.337 6.96067 24.3589 6.94363C25.5168 6.96783 26.661 6.68861 27.6776 6.1337C28.9715 5.45218 37.2387 0.20742 37.3275 0.20742C37.5359 0.0815298 37.7754 0.0165204 38.0189 0.0197543C38.2801 0.0210322 38.5359 0.0926873 38.7597 0.227174C39.8857 0.849431 47.5504 5.54108 47.8763 5.7485C48.4154 6.07373 48.7422 6.6598 48.7356 7.28933V24.0805C48.6752 24.6841 48.2993 25.2103 47.7479 25.4633C47.0269 25.8583 27.9936 37.8294 25.3663 39.2913C25.0672 39.4688 24.7265 39.5642 24.3786 39.5678Z" fill="url(#paint0_linear)"/>
<path d="M24.2801 39.5679L24.3788 39.5678C24.7249 39.5702 25.0655 39.4816 25.3665 39.311C27.9445 37.8195 47.0271 25.8485 47.7481 25.483C48.2995 25.23 48.6754 24.7038 48.7358 24.1002V7.30905C48.739 7.0248 48.6747 6.74384 48.5482 6.48926L24.2801 19.8135V39.5679Z" fill="url(#paint1_linear)"/>
<path d="M24.1953 39.3312L24.0976 39.3311C23.7553 39.3335 23.4183 39.2458 23.1205 39.0771C20.57 37.6015 1.69064 25.7579 0.977268 25.3963C0.431814 25.1461 0.0599217 24.6255 0.000110587 24.0283V7.41604C-0.00303736 7.13482 0.06057 6.85685 0.185773 6.60498L24.1953 19.7873V39.3312Z" fill="url(#paint2_linear)"/>
<defs>
<linearGradient id="paint0_linear" x1="-0.0183431" y1="0.361573" x2="48.2581" y2="-0.0123546" gradientUnits="userSpaceOnUse">
<stop offset="1e-07" stop-color="#FF9200"/>
<stop offset="0.337553" stop-color="#F36D00"/>
<stop offset="0.56729" stop-color="#F83C72"/>
<stop offset="0.78" stop-color="#FC1CBE"/>
<stop offset="0.93" stop-color="#FE08ED"/>
<stop offset="1" stop-color="#FF00FF"/>
</linearGradient>
<linearGradient id="paint1_linear" x1="13.5441" y1="35.4387" x2="62.7643" y2="8.18589" gradientUnits="userSpaceOnUse">
<stop stop-color="#EE0A3F"/>
<stop offset="1" stop-color="#EE0A3F" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint2_linear" x1="18.3337" y1="28.3365" x2="-5.45056" y2="9.177" gradientUnits="userSpaceOnUse">
<stop stop-color="#ED6600"/>
<stop offset="1" stop-color="#F98200"/>
</linearGradient>
</defs>
</svg>

</template>