<template>
  <div>
    <v-row no-gutters dense>
      <v-card flat>
      <v-col cols="12" class="Product-header">Organisation</v-col>
        <v-row class="mx-2 mb-2 pt-4">
            <v-col cols="6" class="pa-2 pl-3 pt-2"> Product Type </v-col>
            <v-col cols="6" class="cardFontText pa-2 pl-3 pt-2">{{ styleData.product_type_name ?
              styleData.product_type_name : ''
            }}</v-col>
            <v-col cols="6" class="pa-2 pl-3 pt-2"> Category </v-col>
            <v-col cols="6" class="cardFontText pa-2 pl-3 pt-2">{{ styleData.category_name ? styleData.category_name : ''
            }}</v-col>
            <v-col cols="6" class="pa-2 pl-3 pt-2">Sub Category</v-col>
            <v-col cols="6" class="cardFontText pa-2 pl-3 pt-2">{{ styleData.sub_category_name ?
              styleData.sub_category_name : ''
            }}</v-col>
            <v-col cols="6" class="pa-2 pl-3 pt-2">Brand </v-col>
            <v-col cols="6" class="cardFontText pa-2 pl-3 pt-2">{{ styleData.brand_name ? styleData.brand_name : ''
            }}</v-col>
            <v-col cols="6" class="pa-2 pl-3 pt-2">SBU </v-col>
            <v-col cols="6" class="cardFontText pa-2 pl-3 pt-2">{{ styleData.sbu_name ? styleData.sbu_name : '' }}</v-col>
          </v-row>
        </v-card>
     
      <v-col cols="12" class="my-4">
        <v-card flat class="pa-4 pt-0 pb-0">
          <v-card-title class="Product-header pl-0 pb-0">Tags</v-card-title>
        <v-text-field v-on:keyup.enter="addChips" density="compact" variant="outlined" class="text-Field"
          v-model="textBox">
          <template v-slot:prepend-inner>
            <div v-for="(chipText) in chipData" :key="chipText" style="max-width:90%">
              <v-chip class="ma-1"  closable>{{ chipText }}
                <!-- <v-icon end icon="mdi-close" @click.stop="removeChips(index)"></v-icon> -->
                </v-chip>
            </div>
          </template>
        </v-text-field>
      </v-card>
      </v-col>
      <v-card width="100%" flat class="pb-2">
          <v-card-title class="Product-header">Available in Stores</v-card-title>
          <v-row>
      <v-col cols="3" class="mx-2" v-for="(chipText) in styleData.store_data" :key="chipText">
          <v-chip label style="background-color: #D9F8FA" class="ma-1">{{ chipText.name }}
          </v-chip>
      </v-col>
    </v-row>
      </v-card>
    </v-row>
  </div>
</template>

<script>
import StyleService from "@/services/catalogs/StyleService";
export default {
  props: ['products'],
  components: {
  },
  data() {
    return {
      styleData: {},
      chipData: [],
      textBox: "",
    };
  },
  watch: {
    products() {
      this.styleData = this.products
      this.chipData = this.styleData && this.styleData.tags ? JSON.parse(this.styleData.tags) : []
      console.log('this.styleData', this.styleData)
    }
  },
  created() {
    this.styleData = this.products
    this.chipData = this.styleData && this.styleData.tags ? JSON.parse(this.styleData.tags) : []
  },

  methods: {
    async addChips() {
      if (this.textBox.length) {
        this.chipData.push(this.textBox)
        this.textBox = ""
        let data = {
          id: this.$route.query.id,
          tags: JSON.stringify(this.chipData)
        }
        await StyleService.update(data)
      }
    },
    async removeChips(i) {
      this.chipData.splice(i);
      let data = {
        id: this.$route.query.id,
        tags: JSON.stringify(this.chipData)
      }
      await StyleService.update(data)
    }
  }
};
</script>

<style scoped>
.cardFontText {
  font-weight: 500;
}
</style>





