<template>
  <v-card elevation="0">
    <v-card-title class="dialog-Title">
      {{ type == "edit" ? "EDIT" : type == "view" ? "View" : type == "add" ? "ADD" : "" }}
      COLUMN
      <v-btn density="comfortable" @click="closeDialog" elevation="0" icon="mdi-close" style="float: right"></v-btn>
      <v-btn class="button mt-1 mr-1" @click="save($columnData)" elevation="0" style="float: right" type="submit"
        size="small">Save</v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-row class="pt-2">
        <v-col cols="3">
          <InputBox :label="'Column Name'" :data="$columnData.column_name"
            @textData="($event) => { $columnData.column_name = $event; }" :disabled="showReadOnly"></InputBox>
        </v-col>
        <v-col cols="3">
          <InputBox :label="'Lable'" :data="$columnData.label"
          @textData="($event) => { $columnData.label = $event; }" :disabled="showReadOnly"></InputBox>
        </v-col>
        <v-col cols="3">

          <AutoComplete label="Data Type" :data="$columnData.data_type" :items="dropdownItems" :value="'id'"
            :text="'label'" :disabled="showReadOnly"></AutoComplete>
        </v-col>
        <v-col cols="3">
          <InputBox :label="'Format'" :data="$columnData.formatters" :disabled="showReadOnly"></InputBox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <InputBox :label="'Width'" :data="$columnData.width" @textData="($event) => { $columnData.width = $event; }" :disabled="showReadOnly">
          </InputBox>
        </v-col>
        <v-col cols="3">
          <InputBox :label="'Height'" :disabled="showReadOnly"></InputBox>
        </v-col>
        <v-col cols="3">
          <AutoComplete label="Font" :items="dropdownItems" :value="'id'" :text="'label'" :disabled="showReadOnly"></AutoComplete>
        </v-col>
        <v-col cols="3">
          <AutoComplete label="Size" :items="dropdownItems" :value="'id'" :text="'label'" :disabled="showReadOnly"></AutoComplete>
        </v-col>
        <v-col cols="12">
          <QuilEditor :data="formData.description" :editorStyle="'height:100px;overflow: auto'" :lable="'Expression'"
            @textData="($event) => {
              formData.description = $event;
            }" :disabled="showReadOnly"></QuilEditor>
        </v-col>
        <v-col cols="12">
          <AutoComplete label="Mask For Roles" :items="dropdownItems" :value="'id'" :text="'label'" :disabled="showReadOnly"></AutoComplete>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import QuilEditor from "@/components/QuilEditor.vue";
export default {
  components: {
    QuilEditor,
  },
  props: ['type', 'columnData'],
  data() {
    return {
      $columnData:{},
      dropdownItems: [],
      formData: {
        description: null
      },
      showReadOnly: false
    }
  },
  created(){
    if (this.type === 'view'){
        this.showReadOnly = true
      }
  },
  watch:{
    columnData(){
      this.$columnData=this.columnData
    }
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog")
    },
    save($data) {
      this.$emit("closeDialog", $data)
    }
  }
}

</script>