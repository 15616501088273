<template>
  <v-form @submit.prevent="save" ref="form">
    <v-toolbar color="#fff" flat>
      <v-toolbar-title class="settingHeader">Profile</v-toolbar-title>
      <v-btn
        style="float: right"
        v-if="!showReadOnly"
        class="button"
        type="submit"
        >Save</v-btn
      >
      <v-btn
        variant="outlined"
        @click="edit"
        v-if="showReadOnly"
        class="usersent-btn"
        prepend-icon="mdi-pencil-outline"
        style="float: right"
        >Edit</v-btn
      >
      <v-btn
        variant="text"
        density="comfortable"
        @click="close"
        elevation="0"
        v-if="!showReadOnly"
        icon="mdi-close"
        style="float: right"
      ></v-btn>
    </v-toolbar>
    <v-divider class="mb-2"></v-divider>
    <v-row class="pa-10">
      <v-col cols="7" class="pl-2 pa-0">
        <InputBox
          :data="mwsHeader.code"
          @textData="checkCode($event)"
          :disabled="showReadOnly"
          :rules="codeRule"
          :label="'Code'"
          @keydowninput="prevent"
        />
      </v-col>
      <v-col cols="7" class="pl-2 pa-0">
        <InputBox
          :data="mwsHeader.name"
          @textData="($event) => {mwsHeader.name = $event;}"
          :label="'Name'"
          :disabled="showReadOnly"
          :rules="nameRule"
        ></InputBox>
      </v-col>
      <v-col cols="7" class="pl-2 pa-0">
        <EnumDropdown
        :data="mwsHeader.market_place"
        @textData="($event) => {mwsHeader.market_place = $event;}"
          :domainName="'marketplaces'"
          :disabled="showReadOnly"
          :label="'Market Place'"
        />
      </v-col>
      <v-col cols="7" class="pl-2 pa-0">
        <AutoComplete
        :data="mwsHeader.active"
        @textData="($event) => {mwsHeader.active = $event;}"
          :items="items"
          :text="'name'"
          :value="'code'"
          :disabled="showReadOnly"
          :label="'Status'"
        ></AutoComplete>
      </v-col>
      <!-- <v-col cols="7">
        <div>
          <v-label calss="pb-1 text-caption">Choose a Color</v-label>
        </div>
        <v-btn
          class="button mt-2"
          @click="addColor()"
          :style="`background-color:${formData.background_color};color:${formData.color} `"
          >{{ formData.background_color }}</v-btn
        >
        <v-color-picker
          v-if="showColor"
          v-model="formData.background_color"
          class="mt-3"
        ></v-color-picker>
      </v-col> -->
    </v-row>
    <!-- <v-btn class="mr-1 cancel"  @click="closeDialog">Cancel</v-btn> -->
  </v-form>
  <!-- <v-row no-gutters class="mt-10" 
      justify="center">
        <v-col cols="6" >
            <v-row no-gutters class="mt-10" >
            <InputBox :label="'Name'"   ></InputBox>
        </v-row>
            <v-row no-gutters class="mt-10">
            <InputBox :label="'MarketPlace'"   ></InputBox>
        </v-row>
            <v-row no-gutters class="mt-8">
            <AutoComplete :items="items" label="Status"   ></AutoComplete>
        </v-row>
        </v-col>
    </v-row>
    <v-row>
        <v-col align="end">
            <v-btn class="cancel">Cancel</v-btn>&nbsp;&nbsp;&nbsp;
            <v-btn class="button">Save</v-btn>
        </v-col>
    </v-row> -->
  <v-layout>
    <v-navigation-drawer permanent location="right">
      <template v-slot:prepend>
        <v-list-item
          lines="two"
          prepend-avatar="https://randomuser.me/api/portraits/women/81.jpg"
          title="Jane Smith"
          subtitle="Logged in"
        ></v-list-item>
      </template>

      <v-divider></v-divider>

      <v-list density="compact" nav>
        <v-list-item
          prepend-icon="mdi-home-city"
          title="Home"
          value="home"
        ></v-list-item>
        <v-list-item
          prepend-icon="mdi-account"
          title="My Account"
          value="account"
        ></v-list-item>
        <v-list-item
          prepend-icon="mdi-account-group-outline"
          title="Users"
          value="users"
        ></v-list-item>
      </v-list>
    </v-navigation-drawer>
  </v-layout>
</template>
<script>
import MwsHeaderService from "@/services/mws/MwsHeaderService";
export default {
  components: {
  },
  data() {
    return {
      date:null,
      date1:null,
      showReadOnly: true,
      showColor: false,
      items: [
        { code: 1, name: "Active" },
        { code: 2, name: "InActive" },
      ],
      formData: {},
      mwsId: localStorage.getItem("mwsHeaderId"),
      marketPlaces: [],
      mwsHeader: {
        name: null,
        code: null,
        market_place: null,
        active: null,
        id: null,
      },
      codeRule: [(value) => !!value || "Code is Required"],
      nameRule: [(value) => !!value || "Name is Required"],
    };
  },
  watch: {
    "formData.background_color": function () {
      this.changeColor();
    },
  },
  created() {
    this.getMws(this.mwsId);
  },
  methods: {
    changeColor() {
      if (this.formData.background_color == "#FFFFFF") {
        this.formData.color = "#000000";
      } else if (this.formData.background_color == "#000000") {
        this.formData.color = "#FFFFFF";
      }
    },
    close() {
      this.showReadOnly = true;
    },
    addColor() {
      if (this.showColor == true) {
        this.showColor = false;
      } else {
        this.showColor = true;
      }
    },
    edit() {
      this.showReadOnly = false;
    },
    prevent(e) {
      if (!/^[a-zA-Z0-9-_ \t]+$/.test(e.key) && e.key !== "Backspace") {
        e.preventDefault();
      }
    },
    checkCode($event) {
      this.mwsHeader.code = $event
      if (this.mwsHeader.code && this.mwsHeader.code.length > 0) {
        this.mwsHeader.code = this.mwsHeader.code.toUpperCase();
      }
    },

    async save() {
      let validation = await this.$refs.form.validate();
      if (!validation.valid) {
        console.log(validation);
      } else {
        if (this.mwsHeader && this.mwsHeader.id) {
          await MwsHeaderService.update(this.mwsHeader);
          this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
          this.showReadOnly = true;
        } else {
          try {
            await MwsHeaderService.create(this.mwsHeader);
            this.$store.dispatch("setSnackbar", { text: "Saved Successfully" });
            this.showReadOnly = true;
          } catch (e) {
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "Something Went Wrong",
            });
          }
        }
      }
    },
    async getMws() {
      let $result = await MwsHeaderService.findAll({ id: this.mwsId });
      console.log($result, "result");
      if ($result && $result.length > 0) {
        $result = $result[0];
        let $obj = {};
        $obj.id = $result.id;
        $obj.name = $result.name;
        $obj.code = $result.code;
        $obj.market_place = $result.market_place;
        $obj.active = $result.active;
        this.mwsHeader = $obj;
      }
    },
  },
};
</script>