<template>
  <div class="slide pt-10">
    <v-container>
      <v-card flat height="650" class="rounded-lg">
        <v-card-title  v-if="step!=4" class="text-h5 font-weight-black text-left pl-10 pt-12">Help us to Identify you!</v-card-title>
        <v-card-text class="pt-8">
          <v-window v-model="step" class="pa-5 pt-0">
            <v-window-item class="pt-5">
              <OnBoard1 @changeSlide="checkValue($event)" :reload="reloadData"/>
            </v-window-item>
            <v-window-item>
              <OnBoard3 @erpChanged="checkErpType($event)" :reload="reloadData"/>
            </v-window-item>
            <v-window-item class="pt-5">
              <OnBoard2 @erptype="checkMarketplace()" :erp="erpValue" :reload="reloadData"/>
            </v-window-item>
            <v-window-item>
              <OnBoard4 @checkMarketplace="checkCompany()" :reload="reloadData"/>
            </v-window-item>
            <v-window-item>
              <OnBoard5 @reload="reloadBoard()" :reload="reloadData"/>
            </v-window-item>
          </v-window>
        </v-card-text>
        <v-card-actions style="margin: auto;" class="card-slider-footer">
          <v-divider style="width:30px"></v-divider>
          <span :class="{ active: step >= 0 }" class="active-slide"></span>
          <v-divider></v-divider>
          <span :class="{ active: step >= 1 }" class="active-slide"></span>
          <v-divider></v-divider>
          <span :class="{ active: step >= 2 }" class="active-slide"></span>
          <v-divider></v-divider>
          <span :class="{ active: step >= 3 }" class="active-slide"></span>
          <v-divider></v-divider>
          <span :class="{ active: step >= 4 }" class="active-slide"></span>
          <v-divider></v-divider>
        </v-card-actions>
      </v-card>
    </v-container>
  </div>
</template>
  
<script>
import OnBoard1 from './OnBoard1.vue';
import OnBoard2 from './OnBoard2.vue';
import OnBoard3 from './OnBoard3.vue';
import OnBoard4 from './OnBoard4.vue';
import OnBoard5 from './OnBoard5.vue';
export default {
  components: {
    OnBoard1,
    OnBoard2,
    OnBoard3,
    OnBoard4,
    OnBoard5,
  },
  data() {
    return {
      step: 0,
      userData: {},
      erpValue: '',
      reloadData:false
    }
  },
  methods: {
    checkMarketplace() {
      this.step = 3
    },
    reloadBoard() {
      this.reloadData = !this.reloadData
      this.step = 0
    },
    checkCompany() {
      this.step = 4
    },
    checkErpType($event) {
      if ($event.value) {
        this.step = 3
      }
      else {
        this.step = 2
        this.erpValue = $event.data.data_value
      }
    },
    checkValue($event) {
      if ($event.value == 'erp') {
        this.step = 1
      }
      else if ($event.value == 'marketplace') {
        this.step = 3
      }
      else {
        this.step = 4
      }
    },
  }
};
</script>
  
<style lang="scss">
.active-slide {
  background: #D9D9D9;
  padding: 9px;
  border-radius: 100%;
}

.active {
  background: #43E971;
}

.slide {
  background: #3C5AAA;
  min-height: 100vh;
}

.card-slider-footer {
  width: 50%;
}
</style>