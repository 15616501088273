import ApiService from './ApiService.js';
import apiConfig from '../config/config'
const querystring = require('querystring') 

var $options = {};
var FirebaseService = {
    getAuthInfo: function($token) {
 
        return new Promise(function(resolve, reject) {
            $options.path = "authInfo"
            $options.baseUrl=apiConfig.adminUrl
            $options.method = "POST"
            $options.data = {"token":$token}
            ApiService.request($options)
                .then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error);
                })
        });
    },
    firebaseAuth:function($data) { 
        return new Promise(function(resolve,reject) { 
            if($data) { 
                $options.path='authenticateFirebase?'+ querystring.stringify($data) 
            } 
            $options.baseUrl=apiConfig.adminUrl
            $options.method = "GET"
            $options.data = $data
            ApiService.request($options)
                .then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error);
                })
        });
    },
    sendToAuthLog: function($data){
        return new Promise(function(resolve,reject){
            $options.path = "authLog"
            $options.baseUrl=apiConfig.gatewayUrl
            $options.method = "POST"
            $options.data = $data
            ApiService.request($options)
                .then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error);
                })
        });

    }
};
export default FirebaseService;