<template>
<span>
    <svg style="margin-top: 10px;" width="35" height="35" viewBox="0 0 63 83" fill="none" xmlns="http://www.w3.org/2000/svg"
    @click="clicked"
    @mouseout="mouseover"
    @mouseleave="mouseleave"
    @mouseenter="mouseenter"
    @mousedown="mousedown">
<path d="M32 30V52" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M37 34H29.5C28.5717 34 27.6815 34.3687 27.0251 35.0251C26.3687 35.6815 26 36.5717 26 37.5C26 38.4283 26.3687 39.3185 27.0251 39.9749C27.6815 40.6313 28.5717 41 29.5 41H34.5C35.4283 41 36.3185 41.3687 36.9749 42.0251C37.6313 42.6815 38 43.5717 38 44.5C38 45.4283 37.6313 46.3185 36.9749 46.9749C36.3185 47.6313 35.4283 48 34.5 48H26" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<circle cx="32" cy="41" r="19" stroke="black" stroke-width="2"/>
<path d="M37.6833 8.76079C38.0865 8.38344 38.1075 7.75062 37.7302 7.34736L31.5808 0.775774C31.2035 0.372509 30.5707 0.351504 30.1674 0.728857C29.7641 1.10621 29.7431 1.73903 30.1205 2.14229L35.5866 7.9837L29.7452 13.4498C29.3419 13.8271 29.3209 14.4599 29.6982 14.8632C30.0756 15.2665 30.7084 15.2875 31.1117 14.9101L37.6833 8.76079ZM37.0332 7.03117C20.363 6.47783 10.6603 13.3674 5.40847 22.3122C0.206665 31.1718 -0.561899 41.924 0.305224 49.1196L2.29086 48.8804C1.45789 41.9681 2.21941 31.6938 7.13316 23.3248C11.9969 15.0411 20.9952 8.49991 36.9668 9.03007L37.0332 7.03117Z" fill="black"/>
<path d="M25.3167 74.2392C24.9135 74.6166 24.8925 75.2494 25.2698 75.6526L31.4192 82.2242C31.7965 82.6275 32.4293 82.6485 32.8326 82.2711C33.2359 81.8938 33.2569 81.261 32.8795 80.8577L27.4134 75.0163L33.2548 69.5502C33.6581 69.1729 33.6791 68.5401 33.3018 68.1368C32.9244 67.7335 32.2916 67.7125 31.8883 68.0899L25.3167 74.2392ZM25.9668 75.9688C42.637 76.5222 52.3397 69.6326 57.5915 60.6878C62.7933 51.8282 63.5619 41.076 62.6948 33.8804L60.7091 34.1196C61.5421 41.0319 60.7806 51.3062 55.8668 59.6752C51.0031 67.9589 42.0048 74.5001 26.0332 73.9699L25.9668 75.9688Z" fill="black"/>
</svg>
</span>
</template>
<script>
export default {
    props:["color"],
    methods: {
        clicked() {
            this.$emit('clicked')
        },
        mouseover() {
            this.$emit('mouseover')
        },
        mouseleave() {
            this.$emit('mouseleave')
        },
        mousedown() {
            this.$emit('mousedown')
        },
        mouseenter() {
            this.$emit('mouseenter')
        },
    }
}
</script>
<style >
.svg{
    cursor:pointer
}
.svg:hover path {
  stroke: #DA4567;  
}
</style>