import { createRouter, createWebHistory } from "vue-router";
import Login from "../components/Login.vue";
import AutoLogin from "../components/AutoLogin.vue";
import UserProfile from "../components/UserProfile.vue";
import Logout from "@/components/Logout.vue";
import ChooseDesk from "@/components/ChooseDesk.vue";
import setting from "./setting";
import MarketPlaces from "@/modules/marketPlaces/router/marketPlace.js";
import Slider from "@/ONBoard/SliderComponent.vue";
import Home from "@/layout/home.vue"
import Scheduler from "../modules/scheduler/SchedulerJobs.vue";
import Channel from "@/modules/Channel/Channel";
import Orders from "@/modules/setting/Orders/Orders.vue";
import ViewOrders from "@/modules/setting/Orders/ViewOrders.vue";
import Identity from "@/modules/setting/Identity/identity.js";
import ProductScreen from "@/modules/Products/ProductScreen.vue";
import TemplateRoutes from "@/modules/messagingTemplates/routes.js";
import AccountVerification from "@/components/AccountVerification";
import ProductFilter from "@/modules/Products/ProductFilter.vue";
import Reports from "@/modules/setting/Reports/Reports.vue";
import Account from "@/components/Accounts";
import AddProductSku from "@/modules/Products/AddProductSku";
import ProductInStore from "@/modules/Products/ProductInStore.vue";
import InventoryInStore from "@/modules/Products/InventoryInStore.vue";
import ViewReports from "@/modules/setting/Reports/ViewReports.vue";
import AddCompany from "@/modules/setting/admin/AddCompany.vue";
import Activity from '@/modules/Technical/Activities/index.vue'
import ProductList from '@/modules/Products/ProductList.vue'
import AddProduct from '@/modules/Products/AddProducts.vue'
import AddproductSku from '@/modules/Products/AddProductSku.vue'
import ProductHomePage from "@/modules/Products/ProductHomePage.vue"
import CreateReport from '@/modules/setting/Reports/CreateReport.vue';
import EditReport from '@/modules/setting/Reports/EditReport.vue'
import WorkFlowGrid from '@/modules/workflows/workFlowGrid'
import WorkFlowAdd from '@/modules/workflows/workFlow'

var routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      breadCrumb: [
        {
          to: '/', 
          text: 'Home'
        }
      ]
    }
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/logout",
    name: "logout",
    component: Logout,
  },
  {
    path: "/switchAccount",
    name: "switchAccount",
    component: Login,
  },
  {
    path: "/autoLogin",
    name: "AutoLogin",
    component: AutoLogin,
  },
  {
    path: "/chooseDesk",
    name: "ChooseDesk",
    component: ChooseDesk,
  },
  {
    path: "/profile",
    name: "User Profile",
    component: UserProfile,
    meta: {
      breadCrumb: [
        {
          to: '/', 
          text: 'Home',
          color:'#3C5AAA'
        },
        {
          text: 'User Profile'       // crumb text
         }
      ]
    }
  },
  {
    path: "/slider",
    name: "Slider",
    component: Slider,
  },
  {
    path: "/settingroute",
    name: "Setting",
    children: setting,
  },
  {
    path: "/channel",
    name: "Channel",
    children: Channel,
  },
  {
    path: "/templates",
    name: "Templates",
    children: TemplateRoutes,
  },
  {
    path: "/marketplaeRouter",
    name: "MarketPlaces",
    children: MarketPlaces,
  },
  {
    path: "/scheduler",
    name: "Scheduler",
    component: Scheduler,
    meta: {
      breadCrumb: [
        {
          to: '/scheduler', 
          text: 'Scheduler'
        },
      ]
    }
  },
  {
    path: "/productscreen",
    name: "ProductScreen",
    component: ProductScreen,
  },

  {
    path: "/orders",
    name: "Orders",
    component: Orders,
    meta: {
      breadCrumb: [
        {
          to: '/orders', 
          text: 'Orders'
        },
      ]
    }
  },
  {
    path: "/ordersView",
    name: "ViewOrders",
    component: ViewOrders,
    meta: {
      breadCrumb: [
        {
          to: '/orders', 
          text: 'Orders',
          color:'#3C5AAA'
        },
        {
          text: 'ViewOrders'
        },
      ]
    }
  },
  {
    path: "/productsku",
    name: "AddProductSku",
    component: AddProductSku,
  },
  {
    path: "/productinstore",
    name: "ProductInStore",
    component: ProductInStore,
  },
  {
    path: "/inventoryinstore",
    name: "InventoryInStore",
    component: InventoryInStore,
    meta: {
      breadCrumb: [
        {
          to: '/inventoryinstore', 
          text: 'InventoryInStore'
        },
      ]
    }
  },
  {
    path: "/identity",
    name: "Identity",
    children: Identity,
  },
  {
    path: "/verification",
    name: "Verification",
    component: AccountVerification,
  },
  {
    path: "/productfilter",
    name: "ProductFilter",
    component: ProductFilter,
  },
  {
    path: "/reports",
    name: "Reports",
    component: Reports,
    meta: {
      breadCrumb: [
        {
          to: '/reports', 
          text: 'Report'
        },
      ]
    }
  },
  {
    path: "/accounts",
    name: "Account",
    component: Account,
    meta: {
      breadCrumb: [
        {
          to: '/accounts', 
          text: 'Account'
        },
      ]
    }
  },
  {
    path: "/viewreports",
    name: "ViewReports",
    component: ViewReports,
    meta: {
      breadCrumb: [
        {
          to: '/reports', 
          text: 'Report',
          color:'#3C5AAA'
        },
        {
          text: 'Add Report'
        },
      ]
    }
  },
  {
    path: "/addcompany",
    name: "AddCompany",
    component: AddCompany,
    meta: {
      breadCrumb: [
        {
          to: '/', 
          text: 'Home',
          color:'#3C5AAA'
        },
        {
          text: 'Add Company'
        },
      ]
    }
  },
  {
    path: "/activity",
    name: "Activity",
    component: Activity,
  },
  {
    path: "/product",
    name: "Product",
    component: ProductList,
    meta: {
      breadCrumb: [
        {
          to: '/product',
          text: 'Product'
        },
      ]
    }
  },
  {
    path: '/addproduct',
    name: 'AddProduct',
    component: AddProduct,
    meta: {
      breadCrumb: [
        {
          to: '/product',
          text: 'Product',
          color:'#3C5AAA'
        },
        {
          text: 'Add Product'
        },
      ]
    }
  },
  {
    path: '/addProductSku',
    name: 'AddproductSku',
    component: AddproductSku,
    meta: {
      breadCrumb: (route) => ([
        {
          to: '/product',
          text: 'Product',
          color:'#3C5AAA'
        },
        {
          to: {
            name: 'ProductHomePage',
            params: { id: route.query.styleId }
          },
          text: 'Product View',
          color:'#3C5AAA'
        },
        {
          text: 'Add ProductSku'
        },
      ])
    }
  },
  {
    path: "/productview/:id?",
    name: "ProductHomePage",
    component: ProductHomePage,
    meta: {
      breadCrumb: [
        {
          to: '/product',
          text: 'Product',
          color:'#3C5AAA'
        },
        {
          to: '/productview',
          text: 'Product View',
        },
      ]
    }
  },
  {
    path: "/createreport",
    name: "CreateReport",
    component: CreateReport,
  },
  {
    path: "/editreport",
    name: "EditReport",
    component: EditReport,
  },
  {
    path: "/workflow",
    name: "workflow",
    component: WorkFlowGrid,
  },
  {
    path: "/addWorkflow",
    name: "addWorkflow",
    component: WorkFlowAdd,
  }
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const publicPages = [
  "/login",
  "/logout",
  "/autoLogin",
  "/slider",
  "/verification",
  "/"
];

router.beforeEach((to, from, next) => {
  const $token = localStorage.getItem("XTE_TOKEN");
  if (publicPages.includes(to.path)) {
    return next();
  }
  if ($token) {
    let $account = localStorage.getItem("_ACCOUNT_ID_");
    if (!$account && to.path != "/accounts" && to.path != "/notFound") {
      // alert('routing'+to.path)
      return next("/accounts");
    }
    return next();
  } else {
    return next("/login");
  }
});

export default router;
