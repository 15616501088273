<template>
    <PrimeTable
      :tableName="'List Models'"
      activity="ListModel"
      :showTableHeader="true"
      :headers="column"
      :tableData="data"
      @rowAction="rowAction"
      @addDialog="addDialog"
      :modelName="'list_models'"
      @filterData="applyFilter($event)"
    ></PrimeTable>
  <v-dialog v-model="confirmation" width="400px" persistent>
    <ConfirmationDialog
      @cancelled="cancelled()"
      @deleted="deleted"
      :content="confirmationContent"
    />
  </v-dialog>
  <v-dialog v-model="dialog" :width="600">
    <AddModel @close="close" />
  </v-dialog>
</template>

<script>
import ListModelService from "@/services/admin/ListModelService";
import ConfirmationDialog from "@/components/DialogConfirmation.vue";
import AddModel from "./AddModel.vue";
export default {
  components: {
    ConfirmationDialog,
    AddModel
  },
  data() {
    return {
      data: [],
      confirmationContent: null,
      editDataId: null,
      confirmation: false,
      dialog:false,
      column: [
        // { title: "Name", align: "start", key: "name" },
        { title: "Table Name", align: "start", key: "model" },
        { title: "Data Base", align: "start", key: "database_name" },
        { title: "Modified By", align: "start", key: "users" },
        { title: "Modified On", align: "start", key: "modified" },
        { title: "Status", key: "active", sortable: false },
        { title: "Action", key: "actions", sortable: false },
      ],
      active: [
        { id: 1, name: "Active" },
        { id: 0, name: "Inactive" },
      ],
    };
  },
  created() {
    let $value = {};
    this.getModels($value);
  },
  methods: {
    addDialog() {
      this.dialog=true;
      // this.$router.push(`/channel/listmodel/add`);
    },
    close(){
      this.dialog=false;
      this.getModels()
    },
    applyFilter($value){
      this.getModels($value)
    },
    async getModels($value) {
      let $data = await ListModelService.findAll($value);
      this.data = $data && $data.length > 0 ? $data : [];
      this.data.map((obj) => {
            return (obj.active = this.active.find(
              (type) => type.id == obj.active
            ).name);
          });
    },
    async deleted() {
      this.confirmation = false;
      await ListModelService.delete({ id: this.editDataId });
      this.getModels();
    },
    cancelled() {
      this.editDataId = null;
      this.confirmation = false;
    },
    rowAction(e) {
      this.dialog = false;
      if (e.type == "edit") {
        this.type = e.type;
        this.editDataId = e.value.id;
        this.$router.push(
          `/channel/listmodel/add?id=${this.editDataId}&type=edit`
        );
      }
      if (e.type == "view") {
        this.type = e.type;
        this.editDataId = e.value.id;
        this.$router.push(
          `/channel/listmodel/add?id=${this.editDataId}&type=view`
        );
      }
      if (e.type == "delete") {
        this.confirmation = true;
        this.confirmationContent = `Do you want to delete the ListModel ${e.value.model} ?`;
        this.editDataId = e.value.id;
      }
      if (e.type == "select") {
        this.type = e.type;
        this.editDataId = e.value.id;
        this.$router.push(
          `/channel/listmodel/add?id=${this.editDataId}&type=view`
        );
      }
    },
  },
};
</script>

<style>
</style>