<template>
  <div class="pl-4">
    <PrimeTable
      :tableName="'Scheduler'"
      :showTableHeader="true"
      activity="Scheduler"
      :headers="column"
      :tableData="data"
      @rowAction="rowAction"
      @addDialog="addDialog"
      :playButton="playButton"
      :showloader="showloader"
      :showstop="showstop"
      :modelName="'jobs'"
      @filterData="applyFilter($event)"
    ></PrimeTable>
    <v-dialog v-model="dialog" width="700px" max-height="600" persistent>
      <AddScheduler @closeDialog="closeDialog" :type="type" :id="editDataId" @reload="getJobs()" />
    </v-dialog>
    <v-dialog v-model="dialog1" width="400px" persistent>
      <PullRefundOrders @closeDialog="closeDialog" :message="message" :type="type" />
    </v-dialog>
    <v-dialog v-model="confirmation" width="300px" persistent>
    <ConfirmationDialog @cancelled="cancelled()" @deleted="deleted" :content="confirmationContent" />
  </v-dialog>
  </div>
</template>
      
      <script>
import JobModel from "../scheduler/Service/jobService";
import AddScheduler from "../scheduler/AddScheduler.vue";
import PullRefundOrders from "./PullRefundOrders.vue";
import ConfirmationDialog from "@/components/DialogConfirmation.vue";
export default {
  components: {
    AddScheduler,
    PullRefundOrders,
    ConfirmationDialog,
  },
  data() {
    return {
      showloader: false,
      showstop: false,
      confirmation: false,
      playButton: true,
      dialog: false,
      dialog1: false,
      data: [],
      column: [
        { title: "ID", align: "start", key: "id" },
        { title: "End Point", align: "start", key: "end_point" },
        { title: "Market Place", align: "start", key: "market_place" },
        { title: "Next Date", align: "start", key: "next_date"},
        { title: "Interval", align: "start", key:"intrvalname" },
        { title: "Processing", align: "start", key: "processing" },
        { title: "Action", key: "actions", sortable: false },
      ],
      processing:[
        {id:1, name:'Yes'},
        {id:0, name:'No'},
      ]
    };
  },
  created() {
    let $value = {};
    // this.getAttribute();
    this.getJobs($value);
  },
  methods: {
    applyFilter($value){
      this.getJobs($value);
    },
    async getJobs($value) {
      const response = await JobModel.findAll($value);
       response.forEach((obj) => {
        obj.showloader = false;
        obj.market_place = obj.mws_headers.name;
        obj.intevalData = JSON.parse(obj ? obj.interval_data:null);
        obj.intrvalname = obj && obj.intevalData.interval && obj.intevalData.interval_type ?
          `${obj.intevalData.interval}  ${obj.intevalData.interval_type}` :
          obj && obj.intevalData.interval ?
          obj.intevalData.interval :null;
        return obj.processing = this.processing.find(type=>type.id==obj.processing).name
      });
      this.data = response;
    },
    rowAction(e) {
      console.log(e, "evalue");
      if (e.type == "edit") {
        this.dialog = true;
        this.editDataId = e.value.id;
        this.type = e.type;
      }
      if (e.type == "view") {
        this.dialog = true;
        this.editDataId = e.value.id;
        this.type = e.type;
      }
      if (e.type == "delete") {
        this.dialog= false;
        this.confirmation = true;
        this.confirmationContent = `Do you want to delete the Scheduler ?`
        this.editDataId = e.value.id;
        console.log(this.editDataId,'ttt');
      }
      if (e.type == "select") {
        this.dialog = true;
        this.editDataId = e.value.id;
        this.type = e.type;
      }
      if (e.type == "run") {
        this.message=e.value
        var response = JobModel.runService({ id: e.value.id });
        console.log(response, "working444444");
        this.dialog1 = true;
        this.showstop = true;
        this.showloader = true;
      }
      if (e.type == "stop") {
        this.playButton = true;
        this.showstop = false;
        this.showloader = false;
      }
    },

    addDialog() {
      this.dialog = true;
      this.type = "add";
      this.editDataId = null
    },
    async deleted(){
      this.confirmation = false
      await JobModel.delete({id:this.editDataId});
      this.getJobs();
    },
    cancelled(){
      this.editDataId = null;
      this.confirmation = false;
    },
    closeDialog() {
      this.dialog = false;
      this.dialog1 = false;
      this.showstop = false;
      this.showloader = false;
      this.playButton = true;
    },
  },
};
</script>