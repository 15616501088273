<template>
    <PrimeTable :tableName="'UOM'" :showTableHeader="true" :headers="column" :tableData="data" @addDialog="addDialog"></PrimeTable>
    
    <v-dialog v-model="dialog" transition="dialog-bottom-transition" width="600px" persistent>
        <AddUOM @closeDialog="closeDialog()" :type="type"></AddUOM>
    </v-dialog>
  </template>
  
  <script>
  import UOMModel from "@/models/UomService";
  import AddUOM from "../AddAdmin/AddUOM.vue";
  export default {
   components:{
    AddUOM,
   },
   data(){
     return{
       dialog: false,
       data:[],
       column: [
         { title: 'Uom', align: 'start', key: 'uom' },
         { title: 'Factor', align: 'start', key: 'factor' },
         { title: 'Status', align: 'start', key: 'active' },
         { title: "Status", key: "active", sortable: false },
         { title: 'Action', key: 'actions', sortable: false },
       ],
       active: [
          { id: 1, name: "Active" },
          { id: 0, name: "Inactive" },
        ],
     }
   },
   created(){
       this.getUOM()
    },
    methods:{
      rowAction(e) {
      if (e.type == "edit") {
        this.dialog = true;
        this.type = e.type
      }
      if (e.type == "view") {
        this.dialog = true;
        this.type = e.type
      }
      if (e.type == "delete") {
        this.dialog = true;
      }
      if (e.type == "select") {
        this.dialog = true;
        this.type = e.type
      }
    },
    addDialog(){
        this.dialog = true;
        this.type = "add"
    },
    closeDialog(){
        this.dialog = false;
    },
      async getUOM(){
          await UOMModel.findAll()
          .then(response => { 
            console.log(response,"working1111")
            this.data = response.data
            this.data.map(obj=>{ 
              return obj.active = this.active.find(type=>type.id==obj.active).name
            })
          })
          .catch(error => { 
            console.log(error);
          });
      }
    }
  }
  </script>