<template>
    <v-row>
        <v-col cols="6" class="pr-1" sm="4" xs="2" md="6" lg="6" xl="6" xxl="12">
            <v-card elevation="0" class="border pb-10">
                <v-card-title class="dialog-Title">
                    <v-row>
                        <v-col class="pa-0 ma-0" cols="1" style="height: 57px;">
                            <v-checkbox v-model="selectAll" color="primary" @change="selectAllCheckboxes" :disabled="showReadOnly"/>
                        </v-col>
                        <v-col cols="11" align="left" style="height: 57px;" class="pl-2">
                            <span>COLUMNS</span>
                            <v-btn density="comfortable" @click="openDialog()" elevation="0" icon="mdi-plus"
                                style="float: right" v-if="!showReadOnly"></v-btn>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pt-0 scroll">
                    <v-container class="mb-12 ml-0 mr-0 pr-0 pl-0 pt-2">
                        <v-row v-for="item in formData" :key="item.index">
                            <v-row>
                                <v-col style="height: 60px;">
                                    <v-checkbox :label="item.label" color="primary" class="pa-0" @input="addchip(item)"
                                        v-model="item.layout_required" :disabled="showReadOnly"></v-checkbox>
                                </v-col>
                                <span align="end" class="pt-7 pr-5">
                                    <v-icon @click="editDialog(item.id)">mdi-pencil</v-icon>
                                    <!-- <span class="pl-2" v-if="showFilter">
                                        <i class="pi pi-filter" @click="addFilter(item)" :class="{filterColor:item.filter_required}"
                                            style="cursor: pointer;"></i></span> -->
                                            <span class="pl-2" v-if="showFilter">
                                        <i :class="item.filter_required ? 'pi pi-filter-fill' : 'pi pi-filter'" @click="addFilter(item)"
                                            style="cursor: pointer;color: #3f51b5;" :disabled="showReadOnly"></i></span>
                                </span>
                                <v-divider></v-divider>
                            </v-row>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="6" v-if="!showColumn" class="pl-1" sm="4" xs="2" md="6" lg="6" xl="6" xxl="12">
            <v-card elevation="0" class="border">
                <v-card-title class="dialog-Title pa-2">
                    LAYOUT & SORT
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="scroll ma-1">
                    <v-row>
                        <v-chip class="ma-1" color="#3f51b5" v-for="(item, index) in layout" :key="index" closable
                            variant="outlined" @click:close="removeItem(item)" :disabled="showReadOnly">{{ item.label }}</v-chip>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="6" v-if="showColumn">
            <AddColumn :type="this.viewtype" class="border" @closeDialog="closeDialog" style="overflow: auto;"></AddColumn>
        </v-col>
    </v-row>
</template>
<script>
import AddColumn from "@/modules/setting/Reports/AddColumn.vue";
import ListModelDetailService from "@/services/admin/ListModelDetailService";
import ListModelLayoutService from "@/services/admin/ListModelLayoutService";

export default {
    props: ['coulmnList', 'layoutData', 'showAddColumn',],
    components: {
        AddColumn,
        // VirtualScroller,
    },
    data() {
        return {
            filterActive: false,
            selectAll: false,
            sample: false,
            showColumn: false,
            type: null,
            showFilter: false,
            layOutView: true,
            // columnData : [],
            selectedItems: [],
            filterAddData: {},
            id: null,
            formData:[{
                layout_required: false
            }],
            layout:[],
            showReadOnly: false,
            viewtype: null
        }
    },
    created() {
        this.showFilterIcon();
        if (this.$route.query.type === 'view'){
            this.showReadOnly = true
            this.viewtype = 'view'
        }
        if (this.coulmnList){
            this.formData = this.coulmnList.map((obj) => {
        return {
            ...obj,
            layout_required: obj.layout_required === 0 ? false : true
             };
               });
        }
        else if (this.layoutData){
          this.assignLayoutData();
        }
    },
    watch:{
        coulmnList(){
            this.id = this.coulmnList[0].model_id;
            this.getColumnList();
            this.getLayoutData();
        },
        layoutData(){
            // this.assignLayoutData();
            this.getLayoutData();
        },

},
    methods: {
        assignLayoutData(){
            this.formData = this.layoutData.map((obj) => {
        return {
            ...obj,
            layout_required: obj.layout_required === 0 ? false : true
        };
    });
        this.layout = this.formData
        },

        async getColumnList(){
            let data = await ListModelDetailService.findAll({model_id: this.id})
            this.formData = data.map((obj) => {
        return {
            ...obj,
            layout_required: obj.layout_required === 0 ? false : true
        };
    });
        },

        async getLayoutData(){
            let data =  await ListModelDetailService.findAll({model_id: this.id})
            let limit = data.length
            this.layout = [];
            for(let i=0;i<limit;i++){
             if(data[i].layout_required == true){
                this.layout.push(data[i])
             }
            }
       },
        selectAllCheckboxes() {
            this.coulmnList.forEach(item => {
                item.active = this.selectAll;
                this.addchip(item)
            });
        },
        async addchip(item) {
            if (item.layout_required) {
                const existingItem = await this.selectedItems.find((selectedItem) => selectedItem.id === item.id);
                if (!existingItem) {
                    // this.layout =[]
                    this.selectedItems.push(item.label);
                    this.updateLayout(item);
                }
            } else {
                await ListModelDetailService.update({id: item.id, layout_required: 0})
                this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
                let removed_layout = item.label   
                this.removeItem(removed_layout)
                this.getLayoutData();
            }
        },
        async updateLayout(item){
            let res = await ListModelLayoutService.findAll({model_id: item.model_id})
            if (res.length > 0) {
                       await ListModelDetailService.update({id: item.id, layout_required: 1})
                    //    let avoidDuplicate = res[0].layout.indexOf(`"${item.label}"`)
                    //     if (avoidDuplicate == 1){
                    //         console.log("Already Exist")
                    //     }else{
                        await ListModelLayoutService.update({ model_id: item.model_id, layout: JSON.stringify(this.selectedItems) })
                        this.$emit("showListModel", item.model_id);
                        this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
                        this.getLayoutData();
                        // }
                    } else {
                        await ListModelLayoutService.create({ name: item.label , model_id: item.model_id, layout: JSON.stringify(this.selectedItems) })
                        this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
                        this.$emit("showListModel", item.model_id);
                    }
        },
        async removeItem(item) {
            await ListModelDetailService.update({id: item.id,layout_required: 0})
            this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
            await this.getColumnList();
            await this.getLayoutData();
        },
        openDialog() {
            if (this.$route.name == "EditReport") {
                this.layOutView = false;
                this.$emit("openDialog");
            }
            if (this.$route.name == "ViewReports") {
                this.showColumn = true;
                this.type = 'add'
            }
        },
        showFilterIcon() {
            if (this.$route.name == "EditReport") {
                this.showFilter = true;
            }
            if (this.$route.name == "ViewReports") {
                this.showFilter = false;
            }
        },
        editDialog(id) {
            // if (!this.showReadOnly){
                this.$emit("openDialog", id);
            // }
        },
        closeDialog() {
            this.layOutView = true
            this.showColumn = false
        },
        async addFilter($filter) {
            if(!this.showReadOnly){
            ListModelDetailService.update({id: $filter.id, filter_required: 1});
            this.$emit("filter",$filter)
            this.getColumnList();
            }
        }
    }
}
</script>
<style scoped>
.border {
    border-style: solid;
    border-width: 3px;
    margin: 4px;
    border-radius: 6px;
    border-color: #0000001f;
    height: 500px;
}

.scroll {
    overflow: auto;
    height: 500px;
    overflow-x: hidden;
}

.filterColor{
    color: blue;
    background-color: "blue";
}
</style>