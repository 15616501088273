<template>
    <v-card>
      <v-form @submit.prevent="save" ref="form">
        <v-card-title class="dialog-Title"
          >{{
            type == "edit"
              ? "Edit"
              : type == "view"
              ? "View"
              : type == "add"
              ? "Add"
              : type == "select"
              ? "View"
              : ""
          }}
          Color
          <v-btn
            density="comfortable"
            @click="closeDialog"
            elevation="0"
            icon="mdi-close"
            style="float: right"
          >
          </v-btn>  
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
             <v-col cols="12" class="pa-1">
                <InputBox :label="'Code'" v-model="formData.code" :rules="codeRule" />
              </v-col>
              <v-col cols="12" class="pa-1">
                <InputBox
                  :label="'Name'"
                  v-model="formData.name"
                  :rules="nameRule"
                />
              </v-col>
              <v-col cols="12" class="pa-1">
                <InputBox :label="'NRF'" v-model="formData.nrf" :rules="nrfRule" />
              </v-col>
              <v-col cols="12">
                <v-btn @click="showcolor = !showcolor" class="button" :style="`background-color:${formData.background_color};color:${formData.color} `">{{ formData.background_color }} </v-btn>
                 <v-color-picker v-if="showcolor" v-model="formData.background_color"></v-color-picker>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="dialogfooter">
          <v-spacer></v-spacer>
          <v-btn class="mr-1 cancel" @click="closeDialog">Cancel</v-btn>
          <v-btn class="save" type="submit">Save</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </template>
  
  <script>
  export default {
    props: ["type"],
    data() {
      return {
        showcolor:false,
        shipperData: [],
        nameRule: [(value) => !!value || "Name is Required"],
        codeRule: [(value) => !!value || "Code is Required"],
        formData: {
          name: null,
          code: null,
          nrf: null,
          color: null,
        },
      };
    },
    watch:{
       'formData.background_color': function(){
          this.changeColor()
       }
    },
       
  
    created() {
    },
  
    methods: {
      closeDialog() {
        this.$emit("closeDialog");
      },
      async save() {
        let validation = await this.$refs.form.validate();
        console.log(validation);
      },

      changeColor(){
          if(this.formData.background_color == '#FFFFFF'){
            this.formData.color = '#000000'
          }
          else if(this.formData.background_color == '#000000'){
            this.formData.color = '#FFFFFF'
          }
        },
    },
  }
  </script>
  
  <style>
  </style>