<template>
    <div style="padding:5px" v-if="accessPrivilege.indexOf('list') < 0 && !loading" class="noaccesscard">
     <span style="" class="text-h5 noaccesscontent">{{ this.checkingPermission }}</span>
  </div>
  <div class="pa-4 total" v-if="accessPrivilege.indexOf('list') >= 0">
    <v-row>
      <v-col cols="12" xs="2" sm="9" md="10" lg="10" class="text-h6 font-weight-bold">Products</v-col>
      <v-col v-if="accessPrivilege.indexOf('create') >= 0" cols="12" xs="2" sm="3" md="2" lg="2" @click="addProduct()" class="d-flex justify-end mb-3"><v-btn
          color="primary">New Product</v-btn></v-col>
    </v-row>
    <v-toolbar border density="comfortable" class="rounded-lg">
      <v-toolbar-title>
        <v-btn class="productListbtn" variant="outlined" @click="active" :class="{ productListButton: actives }">Active</v-btn>
        <v-btn class="ml-2 productListbtn" variant="outlined" @click="draft" :class="{ productListButton: drafts }">Draft</v-btn>
        <v-btn class="ml-2 productListbtn" variant="outlined" @click="archive" :class="{ productListButton: archives }">Archive</v-btn>
      </v-toolbar-title>
      <!-- <InputBox
        class="pr-3 mt-6"
        @keyup="searchData(search)"
        v-model="search"
        :placeholder="'Search'"
        :icon="'mdi-magnify'"
        :singleLine="true"
      ></InputBox> -->

      <!-- <v-menu>
        <template v-slot:activator="{ props }">
          <v-btn v-bind="props" variant="outlined" prepend-icon="mdi-filter-outline" append-icon="mdi-chevron-down">
            Filter By
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="(item, index) in items" :key="index" :value="index">
            <v-list-item-title>{{ item.title }}
              <template v-slot:append>
                <v-btn color="grey-lighten-1" icon="mdi-tune" variant="text"></v-btn>
              </template>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu> -->
      <v-chip v-if="showFilter" style="color:white;background-color: #3C5AAA;" closable
        @click:close="clearFilter()">Remove Filter</v-chip>
      <SearchBox @primeSearch="searchData" class="mr-3 mt-1" placeholder="Search" />
      <v-menu>
        <template v-slot:activator="{ props }">
          <v-btn class="mr-2" v-bind="props" variant="outlined" append-icon="mdi-chevron-down">
            Saved Filters
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="(item, index) in oldFilters" :key="index" :value="index">
            <v-list-item-title @click="setOldFilters(item)">{{ item.name }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <GlobalFilter @prependClicked="setFilter()" :model="'ds_products'" @appendClicked="setDefaultFilter"
        @oldFilterDetails="setFilterDetails"></GlobalFilter>

      <v-menu>
        <template v-slot:activator="{ props }">
          <v-btn class="ml-2" v-bind="props" variant="outlined" prepend-icon="mdi-sort" append-icon="mdi-chevron-down">
            Sort By
          </v-btn>
        </template>
        <v-list density="compact">
          <draggable class="dragArea list-group w-full" :list="sort">
            <v-list-item v-for="(item, i) in sort" :key="i" :value="item" color="primary">
              <template v-slot:append>
                <i @click="sortData(item)" :class="item.count == 0
                  ? 'pi pi-sort-alt'
                  : item.count == 1
                    ? 'pi pi-sort-alpha-down'
                    : 'pi pi-sort-alpha-up-alt'
                  "></i>
              </template>
              <v-list-item-title @click="sortData(item)" v-text="item.title"></v-list-item-title>
            </v-list-item>
          </draggable>
        </v-list>
      </v-menu>

      <v-menu>
        <template v-slot:activator="{ props }">
          <v-btn class="ml-2" v-bind="props" variant="outlined" prepend-icon="mdi-arrow-collapse-down"
            append-icon="mdi-chevron-down">
            Export
          </v-btn>
        </template>
        <v-list class="pt-0">
          <v-list-item v-for="(item, index) in exportData" :key="index" :value="index">
            <template v-slot:prepend>
              <v-icon :icon="item.icon"></v-icon>
            </template>
            <v-list-item-title @click="download(item.value)">{{ item.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-dialog v-model="openDrawer" width="800px" persistent>
      <Filter :model="'ds_products'" :filterId="filterId" @filterClosed="applyFilter" @closeFilter="closeFilter"
        :oldFilterName="'Product Filters'" />
    </v-dialog>

    <v-table class="mt-3 rounded-lg">
      <thead>
        <tr>
          <th class="text-left leftBoarderHeader"></th>
          <th class="text-center font-weight-bold" style="padding-right:132px;">
            Description
          </th>
          <th class="text-left font-weight-bold">SKU</th>
          <th class="text-left font-weight-bold">Variants</th>
          <th class="text-left font-weight-bold">Stores</th>
          <th class="text-left font-weight-bold">
            Last Sold Date
          </th>
          <th class="text-left font-weight-bold">Stage</th>
          <th class="text-left font-weight-bold ">Modified</th>
          <th class="text-left font-weight-bold rightBoarderHeader">
            Action
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in productData" :key="item.name">
          <td width="4%" class="leftBoarder">
            <v-checkbox color="#3C5AAA" class="rounded productLisetCheckBox"> </v-checkbox>
          </td>
          <td width="28%" @click="view(item)">
            <v-row no-gutters dense>
              <v-col cols="3">
                <v-img v-if="item.img" :src="item.img" width="50" height="50"/>
                <ImageCard :imgUpload="true" v-if="!item.img && item.img_id" :documentId="item.img_id" width="50"
                  height="50" />
              </v-col>
              <v-col cols="9" align-self="center">
                <span v-if="item.description" v-html="item.description"></span>
              </v-col>
            </v-row>
          </td>
          <td @click="view(item)">
            {{ item.sku }}
          </td>
          <td @click="view(item)">
            <v-chip label size="small" variant="outlined" class="Variants rounded" v-if="item.variants">{{
              item.variants }} Variants</v-chip>
          </td>
          <td @click="view(item)">
            <v-chip label size="small" variant="outlined" class="Store rounded" v-if="item.stores">{{ item.stores }}
              Store(s)</v-chip>
          </td>
          <td @click="view(item)">
            <div style="color: #f3a54a" class="font-weight-bold">
              {{
                item.last_sold_date
                ? moment(item.last_sold_date).format("MM/DD/YYYY")
                : ""
              }}
            </div>
            <div style="font-size: 12px; padding-left: 8px" class="font-weight-medium">
              {{
                item.last_sold_date ? moment(item.last_sold_date).fromNow() : ""
              }}
            </div>
          </td>
          <td @click="view(item)">
            <v-chip label size="small" variant="outlined" class="Stage rounded" v-if="item.stage">{{ item.stage ?
              titleCaseText(item.stage) : "" }}</v-chip>
          </td>
          <td @click="view(item)">
            {{
              item.modified ? moment(item.modified).format("MM/DD/YYYY") : ""
            }}
          </td>
          <td class="rightBoarder">
            <a class="ex2" v-if="accessPrivilege.indexOf('view') >= 0"><v-icon @click="view(item)" icon="mdi-eye"></v-icon>
            </a>
            <a class="ex2" v-if="accessPrivilege.indexOf('update') >= 0"><v-icon @click.stop="edit(item)" icon="mdi-pencil" class="pl-4"></v-icon></a>
          </td>
        </tr>
      </tbody>
    </v-table>
  </div>
</template>

<script>
// import InputBox from "../../components/InputBox.vue";
import exportFromJSON from "export-from-json";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import * as FileSaver from "file-saver";
import * as xlsx from "xlsx";
import DsProductService from "@/services/catalogs/DsProductService";
import { VueDraggableNext } from "vue-draggable-next";
import lodash from "lodash";
import moment from "moment";
import ImageCard from "@/components/ImageCard.vue";
import GlobalFilter from "../../components/GlobalFilter.vue";
import RolePermissionService from '@/services/admin/RolePermissionService'
import Filter from "@/components/FilterNew.vue";
import ListModelFilterDetailService from "@/services/admin/ListModelFilterDetailService";
export default {
  components: {
    // InputBox,
    draggable: VueDraggableNext,
    GlobalFilter,
    ImageCard,
    Filter,
  },
  data() {
    return {
      productData: [],
      localProductData: [],
      accessPrivilege: [],
      oldFilters: [],
      defaultFilters: [
        {
          column_name: "sku",
        },
        {
          column_name: "stores",
        },
        {
          column_name: "variants",
        },
        {
          column_name: "active",
        },
      ],
      actives: true,
      drafts: false,
      showFilter: false,
      fileName: "Products",
      archives: false,
      filterId: null,
      filterName: null,
      openDrawer: false,
      search: "",
      checkingPermission: null,
      layoutColumns: [
        {
          field: "description",
          header: "Description",
        },
        {
          field: "sku",
          header: "Sku",
        },
        {
          field: "variants",
          header: "Variants",
        },
        {
          field: "stores",
          header: "Stores",
        },
        {
          field: "last_sold_date",
          header: "Last Sold Date",
          type: "date",
        },
        {
          field: "stage",
          header: "Stage",
        },
        {
          field: "modified",
          header: "Modified",
          type: "date",
        },
      ],
      exportData: [
        { title: "Excel", value: "excel", icon: "mdi-file-excel" },
        { title: "Json", value: "json", icon: "mdi-file" },
        { title: "Pdf", value: "pdf", icon: "mdi-file-pdf" },
      ],
      sort: [
        { title: "Sort Options", icon: "mdi-tune-variant", count: 0 },
        {
          title: "Created",
          icon: "mdi-tune-variant",
          count: 0,
          value: "created",
        },
        {
          title: "Modified",
          icon: "mdi-tune-variant",
          count: 0,
          value: "modified",
        },
        { title: "SKU", icon: "mdi-tune-variant", count: 0, value: "sku" },
        {
          title: "Style",
          icon: "mdi-tune-variant",
          count: 0,
          value: "description",
        },
      ],
    };
  },
  async created() {
    this.accessPrivilege = await RolePermissionService.isAllowed('Product')
    let text = 'You Don\'t Have Permission to View This Page'
    this.checkingPermission = this.accessPrivilege.indexOf('list') < 0 ? text: '';
    this.getProducts();
  },
  methods: {
    moment,
    async setOldFilters($item) {
      let $dtl = await ListModelFilterDetailService.findAll({
        model_filter_id: $item.id,
      });
      let $obj = {};
      $dtl.forEach((ele) => {
        if (ele && ele.defaults && ele.column_name) {
          if (ele.expression == "exclude") {
            ele.defaults = ele.defaults ? JSON.parse(ele.defaults) : [];
          }
          $obj[ele.column_name] = ele.defaults;
          // return $obj;
        }
      });
      console.log($obj, "filtersssssssssssss");
      this.showFilter = true
      this.getProducts($obj)
    },
    clearFilter() {
      this.showFilter = false
      this.getProducts();
    },
    setFilterDetails($data) {
      this.oldFilters = $data;
    },
    async getProducts($obj) {
      let cond = {};
      if ($obj) {
        cond = $obj
      }
      let res = await DsProductService.findAll(cond);
      res = lodash.orderBy(res, [`modified`], [`desc`]);
      this.productData = res;
      this.localProductData = JSON.parse(JSON.stringify(res));
      console.log(res);
      //
    },
    edit(item) {
      this.$router.push("/productview/" + item.id);
    },
    titleCaseText(str) {
      return str
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    },
    // searchData(value) {
    //   this.productData = this.localProductData.filter(
    //     (f) =>
    //       (f.description &&
    //         f.description.toLowerCase().includes(value.toLowerCase())) ||
    //       (f.sku && f.sku.toLowerCase().includes(value.toLowerCase())) ||
    //       (f.varaints &&
    //         f.varaints
    //           .toString()
    //           .toLowerCase()
    //           .includes(value.toLowerCase())) ||
    //       (f.stores &&
    //         f.stores.toString().toLowerCase().includes(value.toLowerCase())) ||
    //       (f.last_sold_date &&
    //         f.last_sold_date.toLowerCase().includes(value.toLowerCase())) ||
    //       (f.stage && f.stage.toLowerCase().includes(value.toLowerCase()))
    //   );
    // },
    addProduct() {
      this.$router.push("/addproduct");
    },
    closeFilter() {
      this.openDrawer = false;
    },
    async getFilterProducts(data) {
      let res = await DsProductService.getFilter(data);
      res = lodash.orderBy(res, [`modified`], [`desc`]);
      this.productData = res;
      this.localProductData = JSON.parse(JSON.stringify(res));
      console.log(res);
      //
    },
    view(item) {
      this.$router.push("/productview/" + item.id);
    },
    sortData(item) {
      if (item.title != "Sort Options") {
        if (item.count < 2) {
          item.count = item.count + 1;
        } else {
          item.count = 0;
        }
      }
      // console.log(item, 'counttttt')
      let data = this.sort.filter((f) => f.count != 0);
      let values = data.map((v) => v.value);
      let sorting = data.map((v) => (v.count == 1 ? "asc" : "desc"));
      // console.log(sorting,'ascdesccccccc')
      values = values.join(",");
      sorting = sorting.join(",");
      //console.log(sorting,values,'valuessssssssss',lodash.orderBy(this.productData, [`${values}`], [`${sorting}`]))
      if (data.length > 0) {
        this.productData = lodash.orderBy(
          this.productData,
          [`${values}`],
          [`${sorting}`]
        );
      } else {
        this.productData = lodash.orderBy(
          this.productData,
          [`modified`],
          [`desc`]
        );
      }
    },
    active() {
      this.archives = false;
      (this.drafts = false), (this.actives = true);
      let data = {};
      this.getFilterProducts(data);
    },
    draft() {
      this.archives = false;
      (this.drafts = true), (this.actives = false);
      let data = {
        draftStage: "draft",
      };
      this.getFilterProducts(data);
    },
    archive() {
      this.archives = true;
      (this.drafts = false), (this.actives = false);
      let data = {
        archiveStage: "archive",
      };
      this.getFilterProducts(data);
    },
    setPDFHeader(data) {
      return [
        Object.keys(data[data.length - 1]).map(
          (item) =>
            `${item.charAt(0).toUpperCase()}${item.substr(1, item.length)}`
        ),
      ];
    },
    calculateFontSize(count) {
      return count ? 50 / count : count;
    },
    setPDFBody(data) {
      return data.map((item) => {
        const keys = Object.keys(item);
        const values = [];
        keys.forEach((key) => {
          if (typeof item[key] === "object" && item[key] !== null) {
            values.push(
              item[key].name
                ? item[key].name
                : item[key].code
                  ? item[key].code
                  : item[key].name
            );
          } else {
            values.push(item[key]);
          }
        });
        return values;
      });
    },
    exportAsPDF(data, fileName) {
      const headers = this.setPDFHeader(data);
      const fontSize = this.calculateFontSize(headers[0].length);
      const body = this.setPDFBody(data);
      const doc = new jsPDF();
      autoTable(doc, { html: "#dt2" });
      doc["autoTable"]({
        styles: {
          cellPadding: 0.5,
          fontSize: fontSize,
        },
        headStyles: {
          fillColor: "#3f51b5",
          textColor: "#fff",
          halign: "center",
        },
        bodyStyles: {
          halign: "center",
        },
        margin: {
          left: 5,
          right: 5,
        },
        tableLineWidth: 1,
        head: headers,
        body: body,
      });
      doc.save(fileName);
    },
    async download($event) {
      let fileName = this.fileName ? this.fileName : `${this.title}`;
      let data = await this.formattedData();
      if ($event.toLowerCase() == "excel") {
        this.exportExcel(fileName);
        // const exportType = exportFromJSON.types.csv;

        // if (data) exportFromJSON({ data, fileName, exportType });
      } else if ($event.toLowerCase() == "json") {
        const exportType = exportFromJSON.types.json;
        if (data) {
          exportFromJSON({ data, fileName, exportType });
        }
      } else {
        this.exportAsPDF(data, fileName);
      }
    },
    formattedData() {
      let selectedColumns = this.layoutColumns;
      let data = JSON.parse(JSON.stringify(this.productData));
      let formateData = [];
      for (let i = 0; i < data.length; i++) {
        let $obj = {};
        for (let j = 0; j < selectedColumns.length; j++) {
          if (selectedColumns[j]["header"].length > 0) {
            console.log(selectedColumns[j]["header"]);
            if (
              selectedColumns[j]["type"] == "date" ||
              selectedColumns[j]["type"] == "datetime" ||
              selectedColumns[j].field == "created" ||
              selectedColumns[j].field == "modified"
            ) {
              $obj[selectedColumns[j]["header"]] = this.formatDate(
                lodash.get(data[i], selectedColumns[j].field),
                selectedColumns[j].field
              );
            } else if (
              selectedColumns[j].field == "active" ||
              selectedColumns[j].header.toLowerCase() == "status"
            ) {
              $obj[selectedColumns[j]["header"]] =
                data[i].active == 1 ? true : false;
            } else if (selectedColumns[j]["header"].toLowerCase() != "action") {
              $obj[selectedColumns[j]["header"]] = lodash.get(
                data[i],
                selectedColumns[j].field
              );
            }
          }
        }
        formateData.push($obj);
      }
      return formateData;
    },
    formatDate(dateString, type) {
      if (!dateString) {
        return "";
      } else if (type == "date") {
        const date = new Date(dateString);
        return date.toLocaleDateString(); // moment(date).utcOffset('+05:30')
      } else {
        const date = new Date(dateString);
        //return moment(date).toLocal()
        return date.toLocaleString();
      }
    },
    exportExcel(name) {
      let data = this.formattedData();
      const worksheet = xlsx.utils.json_to_sheet(data);
      // const max_width = this.dataSource.reduce((w, r) => Math.max(w, r.name.length), 10);
      if (data && data.length > 0) {
        worksheet["!cols"] = [];
        Object.keys(data[0]).forEach((data) => {
          let width = { width: 20 };
          console.log(data, "called");
          worksheet["!cols"].push(width);
        });
      }
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      this.saveAsExcelFile(excelBuffer, name);
    },

    saveAsExcelFile(buffer, fileName) {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      FileSaver.saveAs(
        data,
        fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
      );
    },
    searchData($event) {
      this.productData = this.localProductData.filter(
        (f) =>
          (f.description &&
            f.description.toLowerCase().includes($event.toLowerCase())) ||
          (f.sku && f.sku.toLowerCase().includes($event.toLowerCase())) ||
          (f.stage && f.stage.toLowerCase().includes($event.toLowerCase()))
      );
    },
    setFilter() {
      this.openDrawer = true;
    },
    setDefaultFilter($item) {
      this.filterId = $item.id;
      this.filterName = $item.name;
      this.openDrawer = true;
    },
    applyFilter($obj) {
      console.log($obj, "filtersssss");
      this.showFilter = true
      this.openDrawer = false;
      this.getProducts($obj)
    },
  },
};
</script>

<style lang="scss">
.total {
  .v-table__wrapper {
    border-radius: inherit;
    overflow: unset !important;
}
  .v-table--density-default>.v-table__wrapper>table>tbody>tr>th,
  .v-table--density-default>.v-table__wrapper>table>thead>tr>th,
  .v-table--density-default>.v-table__wrapper>table>tfoot>tr>th {
    height: calc(var(--v-table-header-height) + -6px);
    border-top: 1px solid;
    border-color: #a1e5ff;
  }

  .rightBoarderHeader{
    border-right: 1px solid;
    border-radius: 0px 5px 5px 0px;
    border-color: #a1e5ff !important;
 }

 .leftBoarderHeader {
    border-left: 1px solid;
    border-radius: 5px 0px 0px 5px;
    border-color: #a1e5ff !important;
  }

  .Variants {
    font-size: 13px;
    font-weight: 400;
    color: #636363;
    background-color: #F6E0FE !important;
    border-color: #E9ABFF; 
  }

  .Store {
    font-size: 13px;
    font-weight: 400;
    color: #636363;
    background-color: #ebe1ff !important;
    border-color: #cfb7ff;
  }

  .Stage {
    font-size: 13px;
    font-weight: 400;
    color: #636363;
    background-color: #deffd9 !important;
    border-color: #76ff60;
  }

  th {
    background: #ddf1f9 !important;
  }
  tr{
    background: #ffffff;
  }
  tr:hover {
  background-color:#ddf1f9 !important ;
}


  .productListButton {
    color: #ffffff;
    background-color: #3c5aaa !important;
    border-color: #224095;
  }

  .v-table>.v-table__wrapper>table {
    width: 100%;
    border-spacing: 0px 10px;
  }

  .v-table {
    background: unset !important;
    color: rgba(var(--v-theme-on-surface), var(--v-high-emphasis-opacity));
  }

  a.ex2:hover,
  a.ex2:active {
    font-size: 120%;
    color: #224095;
  }

  .v-table--density-default>.v-table__wrapper>table>tbody>tr>td,
  .v-table--density-default>.v-table__wrapper>table>thead>tr>td,
  .v-table--density-default>.v-table__wrapper>table>tfoot>tr>td {
    height: calc(var(--v-table-row-height, 52px) + 0px);
    border-top: 1px solid !important;
    border-bottom: 1px solid !important;
    border-color: #cecece !important;
    background-color: #fafafa;
  }

  .rightBoarder {
    border-right: 1px solid;
    border-radius: 0px 3px 3px 0px;
    border-color: #cecece;
  }

  .leftBoarder {
    border-left: 1px solid;
    border-radius: 3px 0px 0px 3px;
    border-color: #cecece;
  }



  td {
    font-size: 13px;
    background: none !important;
  }
  .productListbtn{
    width: 100px;
  }
}
.productLisetCheckBox{
  height: 56px;
  }
</style>
