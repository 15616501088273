<template>
  <div class="pa-6">
    <v-card>
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="8"
            ><InputBox
              :placeholder="'Search'"
              :icon="'mdi-magnify'"
              :singleLine="true"
          /></v-col>
          <v-col cols="2" class="dialogfooter pl-6 pt-1"
            ><v-btn
              variant="outlined"
              class="mr-1 cancel"
              @click="showLayout = false"
              >Cancel</v-btn
            >
            <v-btn variant="outlined" class="save ml-4" type="submit"
              >Save</v-btn
            >
          </v-col>
          <v-col cols="2" class="pl-4 pt-1">
            <v-menu>
              <template v-slot:activator="{ props }">
                <v-btn
                  v-bind="props"
                  variant="outlined"
                  prepend-icon="mdi-arrow-collapse-down"
                  append-icon="mdi-chevron-down"
                >
                  Export
                </v-btn>
              </template>

              <v-card width="256">
                <v-card-text>
                  <v-row no-gutters class="pt-4">
                    <v-col cols="12"
                      ><AutoComplete
                        :label="'Stores'"
                        class="pa-2"
                        :items="producStores"
                        chips
                        :text="'name'"
                        :value="'id'"
                        :data="productPreferences.Stores"
                        @textData="($event) => {productPreferences.Stores = $event;}"
                        :disabled="showReadOnly"
                        :multiple="true"
                    /></v-col>
                    <v-col cols="12"
                      ><AutoComplete :label="'Fulfillments'" class="pa-2"
                      :items="productFulfillments"
                      chips
                      :text="'name'"
                      :value="'id'"
                      :data="productPreferences.Fulfillmentes"
                      @textData="($event) => {productPreferences.Fulfillmentes = $event;}"
                      :disabled="showReadOnly"
                      :multiple="true"
                    /></v-col>
                    <v-col cols="5"
                      ><InputBox class="pa-2" :label="'Inventory'"
                    /></v-col>
                    <v-col cols="2" class="pa-2 pt-4 font-weight-medium"
                      >To</v-col
                    >
                    <v-col cols="5"><InputBox class="pa-2" /></v-col>
                    <v-col cols="12"
                      ><InputBox :label="'SKU'" class="pa-2"
                    /></v-col>
                    <v-col cols="12" class="pa-2"
                      ><DatePicker
                        placeholder="Created"
                        class="pa-2"
                        append-icon="mdi-calendar-blank"
                        >Created</DatePicker
                      ></v-col
                    >
                    <v-col cols="12"
                      ><InputBox :label="'SBU'" class="pa-2"
                    /></v-col>
                    <v-col cols="12"
                      ><InputBox :label="'Category'" class="pa-2"
                    /></v-col>
                    <v-col cols="12" class="text-center"
                      ><v-btn
                        variant="outlined"
                        class="mr-1 cancel"
                        @click="showLayout = false"
                        >Cancel</v-btn
                      >
                      <v-btn
                        variant="outlined"
                        class="save ml-4"
                        prepend-icon="mdi-plus-circle"
                        type="submit"
                        >Save</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-menu></v-col
          >

          <v-col cols="2"><AutoComplete :placeholder="'Stores'" /></v-col>
          <v-col cols="2" class="pl-4"
            ><AutoComplete :placeholder="'Fulfillments'"
          /></v-col>
          <v-col cols="3" class="pl-4 pb-5"
            ><v-checkbox
              label="Include product with Zero Availability"
            ></v-checkbox>
          </v-col>
          <v-col cols="2" class="pl-4 pt-1"
            ><v-btn
              variant="outlined"
              append-icon="mdi-chevron-down"
              color="#757575"
            >
              Advanced</v-btn
            ></v-col
          >
          <v-col cols="1" class="pt-1"
            ><v-btn variant="outlined" color="#757575" append-icon="mdi-sort"
              >Sort By</v-btn
            ></v-col
          >
          <v-col cols="2" class="pl-4 pt-1"
            ><v-btn
              variant="outlined"
              color="#757575"
              append-icon="mdi-chevron-down"
              >Choose Columns
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="400"
    >
      <template v-slot:activator="{ props }">
        <v-btn
          color="primary"
          v-bind="props"
        >
          Open Dialog
        </v-btn>
      </template>

      <v-card style="background-color:#AA00FF" class="pa-3 rounded-lg">
      <v-card-title>
        <v-btn
             class="pl-2"
            density="comfortable"
            @click="closeDialog"
            elevation="0"
            icon="mdi-close"
            style="float: right ; background-color:#AA00FF"
        
          ></v-btn>
        </v-card-title>


        <v-card-title class="text-h6 text-md-h5 text-lg-h4 text-center" style="color:#FFFFFF">Pull Refund Orders</v-card-title>
        <v-col class="text-center" style="color:#FFFFFF" >JOB #</v-col>
        <v-card width="" height="" class=" rounded-lg">
          <v-col align="center" >
            <v-img  src="../../assets/cupOfCoffee.svg" width="150" height=""/>
          </v-col>
          <v-card-text class=" font-weight-medium text-center" style="font-size:20px"> Sit Back and Relax while we do the job for you </v-card-text>
          <v-col align="center"> 
    <v-progress-circular
      indeterminate
      color="purple"
    ></v-progress-circular></v-col>
    <v-col align="center"> 
      <span class="font-weight-bold"  style="color:rgb(223, 175, 31)">  16 Secs</span> Since started
        </v-col>
        </v-card>
      
      </v-card>
    </v-dialog> 
  </div>
  </div>


</template>

<script>
import InputBox from "@/components/InputBox.vue";
import AutoComplete from "@/components/AutoComplete.vue";
import DatePicker from "@/components/DatePicker.vue";
export default {
  components: {
    InputBox,
    AutoComplete,
    DatePicker,
  },
  data() {
    return {
      dialog: false,
        productPreferences:{
            Stores:null,
            Fulfillmentes:null
        },
        producStores:[
        { id: "price", name: "Price" },
        { id: "sku", name: "SKU" },
        { id: "upc", name: "UPC" },
        { id: "dimension", name: "Dimensions and tag" },
        { id: "price_per_region", name: "Price per Region" },
        { id: "images", name: "Image" },
      ],

      productFulfillments:[
        { id: "price", name: "Price" },
        { id: "sku", name: "SKU" },
        { id: " upc", name: "UPC" },
      ]

        }
    }
  } 

</script>

<style>
</style>