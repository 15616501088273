<template>
  <v-form @submit.prevent="save" ref="form">
    <v-card>
      <v-card-title class="dialog-Title"
        >{{
          type == "edit"
            ? "Edit"
            : type == "view"
            ? "View"
            : type == "add"
            ? "Add"
            : type == "select"
            ? "View"
            : ""
        }}
        Role
        <v-btn
          density="comfortable"
          @click="closeDialog"
          elevation="0"
          icon="mdi-close"
          style="float: right"
        ></v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-col cols="12" class="pa-0 ">
          <InputBox
            :data="formData.code"
            @textData="checkCode($event)"
            :label="'Code'"
            :disabled="showReadOnly"
            :rules="codeRules"
            @keydowninput="prevent"
          />
          <span v-if="codeError" class="error-field">Already Exists!</span>
        </v-col>
        <v-col cols="12" class="pa-0 ">
          <InputBox
            :data="formData.name"
            @textData="
              ($event) => {
                formData.name = $event;
              }
            "
            :label="'Name'"
            :disabled="showReadOnly"
            :rules="nameRules"
          />
        </v-col>
        <v-col cols="12" class="pa-0">
          <EnumDropdown
            :data="formData.role_type"
            @textData="
              ($event) => {
                formData.role_type = $event;
              }
            "
            :disabled="showReadOnly"
            :label="'Role Type'"
            :domainName="'usertype'"
            :rules="typeRule"
          />
        </v-col>
        <v-card-actions class="dialogfooter" v-if="!showReadOnly">
          <v-spacer></v-spacer>
          <v-btn class="mr-1 cancel" @click="closeDialog">Cancel</v-btn>
          <v-btn class="save" type="submit">Save</v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-form>
</template>
<script>
import RoleService from "@/services/admin/RoleService";
import EnumDropdown from "@/components/EnumDropdown.vue";
export default {
  props: ["type", "id"],
  components: {
    EnumDropdown,
  },
  data() {
    return {
      codeError: false,
      codeExistFlag: false,
      codeRules: [(value) => !!value || "Code is Required"],
      nameRules: [(value) => !!value || "Name is Required"],
      typeRule: [(value) => !!value || "Role Type is Required"],
      formData: {},
      showReadOnly: false,
    };
  },
  created() {
    if (this.id && this.type != "add") {
      this.getData();
    }
    if (this.type == "view" || this.type == "select") {
      this.showReadOnly = true;
    }
  },
  methods: {
    async getData() {
      let res = await RoleService.findOne({ id: this.id });
      this.formData = res[0];
    },
    prevent(e) {
      if (!/^[a-zA-Z0-9-_ \t]+$/.test(e.key) && e.key !== "Backspace") {
        e.preventDefault();
      }
    },
    async checkCode($event) {
      this.formData.code = $event;
      if (this.formData.code && this.formData.code.length > 0) {
        this.formData.code = this.formData.code.toUpperCase();
        await RoleService.findAll({ code: this.formData.code }).then((res) => {
          let $exist = res.filter(
            (key) => key.code.toUpperCase() == this.formData.code
          );
          this.codeExistFlag =
            $exist.length > 0
              ? this.formData.id && $exist[0].id == this.formData.id
                ? false
                : true
              : false;

          if (this.codeExistFlag) {
            this.codeError = true;
          } else {
            this.codeError = false;
          }
        });
      }
    },
    async save() {
      let validation = await this.$refs.form.validate();
      if (!validation.valid || this.codeError) {
        console.log("Not valid");
      } else {
        if (this.formData.id) {
          delete this.formData.users;
          await RoleService.update(this.formData);
          this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
          this.$emit("closeDialog", true);
        } else {
          try {
            await RoleService.create(this.formData);
            this.$store.dispatch("setSnackbar", { text: "Saved Successfully" });
            this.formData = {};
            this.$emit("closeDialog", true);
          } catch (e) {
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "Something Went Wrong",
            });
          }
        }
      }
    },
    closeDialog() {
      this.$emit("closeDialog", true);
    },
  },
};
</script>