<template>
  <PrimeTable
    :tableName="'SBUs '"
    activity="SBU"
    :showTableHeader="true"
    :headers="column"
    :tableData="data"
    @rowAction="rowAction"
    @addDialog="addDialog"
    :modelName="'sbus'"
    @filterData="applyFilter($event)"
  ></PrimeTable>
  <v-dialog v-model="dialog" width="600px" persistent>
    <AddSbu @closeDialog="closeDialog" :type="type" :id="editDataId" />
  </v-dialog>
  <v-dialog v-model="confirmation" width="300px" persistent>
    <ConfirmationDialog
      @cancelled="cancelled()"
      @deleted="deleted"
      :content="confirmationContent"
    />
  </v-dialog>
</template>

<script>
import SbuService from "@/services/admin/SbuService.js";
import ConfirmationDialog from "@/components/DialogConfirmation.vue";
import AddSbu from "../AddAdmin/AddSbu.vue";
export default {
  components: {
    AddSbu,
    ConfirmationDialog,
  },
  data() {
    return {
      dialog: false,
      data: [],
      confirmationContent: null,
      confirmation: false,
      column: [
        { title: "Name", align: "start", key: "name" },
        { title: "Code", align: "start", key: "code" },
        { title: "Erp Key", align: "start", key: "erp_key" },
        { title: "Modified by", align: "start", key: "users" },
        { title: "Modified on", align: "start", key: "modified" },
        { title: "Status", key: "active", sortable: false },
        { title: "Action", key: "actions", sortable: false },
      ],
      active: [
        { id: 1, name: "Active" },
        { id: 0, name: "Inactive" },
      ],
    };
  },
  created() {
    let $value = {};
    this.getSbu($value);
  },
  methods: {
    applyFilter($value){
      this.getSbu($value)
    },
    async getSbu($value) {
      await SbuService.findAll($value)
        .then((response) => {
          this.data = response;
          this.data.map((obj) => {
            return (obj.active = this.active.find((type) => type.id == obj.active).name);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    rowAction(e) {
      if (e.type == "edit") {
        this.dialog = true;
        this.editDataId = e.value.id;
        this.type = e.type;
      }
      if (e.type == "view") {
        this.dialog = true;
        this.editDataId = e.value.id;
        this.type = e.type;
      }
      if (e.type == "delete") {
        this.dialog = false;
        this.confirmation = true;
        this.confirmationContent = `Do you want to delete the SBU ${e.value.name} ?`;
        this.editDataId = e.value.id;
      }
      if (e.type == "select") {
        this.dialog = true;
        this.editDataId = e.value.id;
        this.type = e.type;
      }
    },
    addDialog() {
      this.editDataId = null;
      this.dialog = true;
      this.type = "add";
    },
    closeDialog() {
      this.dialog = false;
      this.getSbu();
    },
    async deleted() {
      this.confirmation = false;
      await SbuService.delete({ id: this.editDataId });
      this.getSbu();
    },
    cancelled() {
      this.editDataId = null;
      this.confirmation = false;
    },
  },
};
</script>
