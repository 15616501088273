import ApiService from '@/api/service/ApiService';
import apiConfig from '@/api/config/config';
var $options = {};
var SeasonService = {
    findAll: function() {
        return new Promise(function(resolve, reject) {
            $options.path = "season"
            $options.baseUrl=apiConfig.baseUrl
            $options.method = "get"
            ApiService.request($options)
                .then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error);
                })
        });
    }
};
export default SeasonService;