<template>
  <v-card elevation="0" class="ma-6">
    <v-form @Submit.prevent="save()" ref="form">
    <v-card-title class="dialog-Title">
      <v-icon class="mr-2" size="30px" color="primary" @click="$router.go(-1)">mdi-arrow-left</v-icon>
      <span class="mt-5">{{ this.$route.query.id ? 'EDIT REPORT' : 'CREATE NEW REPORT' }}</span>
      <v-btn class="button" type="submit" style="display: flex; float: right;">Save</v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-row class="pa-0 pt-2">
        <v-col cols="3" v-if="!this.reportId" class="pa-0 pl-4 pr-0">
          <!-- <b style="font-size: 12px" class="font-weight-bold pl-1">Select Your Reports</b> -->
          <span>
            <!-- <i class="pi pi-plus" @click="adddialog()" style="font-size: 14px; cursor: pointer;color: blue;float: right;padding-right: 15px;"></i> -->
            <v-icon color="primary" @click="adddialog()" style="float: right;" v-if="!showReadOnly">mdi-plus-circle</v-icon>
          </span>
          <EnumDropdown :data="data" @textData="getListModelData($event)" :disabled="showReadOnly" :rules="databaseRules"
            :label="'Select Your Report'" :domainName="'reports'" :reload="!this.dataSetDialog"/>

        </v-col>
        <v-col cols="8" class="pa-0 pl-3">
          <InputBox :label="'Name of the Report'" :data="reportData.name"
            @textData="($event) => { reportData.name = $event; }" :disabled="showReadOnly"></InputBox>
        </v-col>
        <v-col class="pt-6" cols="1" v-if="reportData.your_report">
          <v-btn class="button" type="submit">Advanced</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card elevation="0" class="content-menu-cardright">
            <v-row>
              <v-col cols="6" class="ma-0 pr-0">
                <Layout :coulmnList="coulmnList" :layoutData="layoutData" @showListModel="showListModel($event)"
                  @openDialog="openDialog($event)" :showAddColumn="showAddColumn" @filter="filters($event)"></Layout>
              </v-col>
              <v-col v-if="showAddColumn" class="mr-0" style="width: 700px;">
                <AddColumn :columnData="columnData" :type="this.viewtype" class="border"
                  @closeDialog="closeAddcolumn($event)">
                </AddColumn>
              </v-col>
              <v-col cols="2" class="ma-0 pr-0" v-if="!showAddColumn">
                <EditFilter :filterData="this.applyFilter" :filterId="this.filterId" class="border" :modelId="this.model_id" @reloadColumnList="getListModelDetailsData(this.model_id)"></EditFilter>
              </v-col>
              <v-col cols="4" class="ma-0" v-if="!showAddColumn">
                <v-col cols="12" class="pa-0">
                  <Sorting :sortingData="this.coulmnList" class="border" style="height: 248px; overflow: auto; overflow-x: hidden;"></Sorting>
                </v-col>
                <v-col cols="12" class="pa-0">
                  <GroupBy class="border" style="height: 248px; overflow: auto; overflow-x: hidden;"></GroupBy>
                </v-col>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <b style="font-size: 12px" class="font-weight-bold pl-1">After Render</b>
          <v-radio-group v-model="reportData.after_render" color="primary">
            <v-radio label="Javascript" value="javascript"></v-radio>
            <v-radio label="SQL" value="sql"></v-radio>
          </v-radio-group>
          <QuilEditor :data="editor" :editorStyle="'height:150px;overflow: auto'"
            @textData="($event) => { editor = $event; }"></QuilEditor>
        </v-col>
        <v-col>
          <b style="font-size: 12px" class="font-weight-bold pl-1">Before Render</b>
          <v-radio-group v-model="reportData.before_render" color="primary">
            <v-radio label="Javascript" value="javascript"></v-radio>
            <v-radio label="SQL" value="sql"></v-radio>
          </v-radio-group>
          <QuilEditor :data="editor" :editorStyle="'height:150px;overflow: auto'"
            @textData="($event) => { editor = $event; }"></QuilEditor>
        </v-col>
      </v-row>
    </v-card-text>
  </v-form>
  </v-card>
  <v-dialog v-model="dataSetDialog" persistent width="650px">
    <DataSet @closeDialog="closeDialog()"></DataSet>
  </v-dialog>
</template>
<script>
import Layout from "@/modules/setting/Reports/Layout.vue";
import Sorting from "@/modules/setting/Reports/Sorting.vue";
import EditFilter from "@/modules/setting/Reports/EditFilter.vue";
import GroupBy from "@/modules/setting/Reports/GroupBy.vue";
import ListModelDetailService from "@/services/admin/ListModelDetailService";
import ListModelService from "@/services/admin/ListModelService";
import ListModelLayoutService from "@/services/admin/ListModelLayoutService";
import ListModelFilterService from "@/services/admin/ListModelFilterService";
import ListModelFilterDetailService from "@/services/admin/ListModelFilterDetailService";
import DataSet from "@/modules/setting/Reports/DataSet.vue";
import QuilEditor from "@/components/QuilEditor.vue";
import AddColumn from "@/modules/setting/Reports/AddColumn.vue"
import EnumService from "@/services/admin/EnumService";
import ReportService from "@/services/admin/ReportService";
import InputBox from "@/components/InputBox.vue";
export default {
  components: {
    Layout,
    EditFilter,
    Sorting,
    GroupBy,
    DataSet,
    QuilEditor,
    AddColumn,
    InputBox
  },
  data() {
    return {
      data: [],
      dataSetDialog: false,
      coulmnList: [],
      layoutData: [],
      applyFilter: {},
      filterData: [],
      columnData: [],
      reportId: null,
      reportData: {},
      showAddColumn: false,
      filterId: null,
      model_id: null,
      showReadOnly: false,
      viewtype: null
    }
  },  
  async created() {
    if (this.$route.query.id) {
      this.reportData.id = this.$route.query.id
      let res1 = await ReportService.findAll({id: this.$route.query.id})
      this.reportData.name = res1[0].name;

      let res = await EnumService.findAll({id: res1[0].data_set_id})
      let $model = res[0].data_value
      this.data = $model
      this.getListModelData($model)
    }
    if (this.$route.query.type === 'view'){
      this.showReadOnly = true
      this.viewtype = 'view'
    }
  },
  watch:{
  },
  methods: {
    filters($event){
      this.applyFilter = $event
    },
    async getListModelData($model) {

      let res2 = await EnumService.findAll({data_value:$model});
      this.reportData.data_set_id = res2[0].id;
      try {
        var res = await ListModelService.findAll({ model: $model });
        this.model_id = res[0].id;
        await this.getListModelDetailsData(res[0].id)
        await this.getListModelLayout(res[0])
        await this.getListModelFiter(res[0])

      } catch (err) {
        console.log(err.message)
        throw (err)
      }

    },
    async getListModelDetailsData($model_id, $id) {
      try {
        var res;
        if ($model_id) {
          res = await ListModelDetailService.findAll({ model_id: $model_id });
          this.coulmnList = res;
        }
        else {
          var res2 = await ListModelDetailService.findAll({ id: $id });
          this.columnData = res2[0]
        }
      } catch (err) {
        console.log(err.message)
        throw (err)
      }
    },
    async updateListModelDetailsData($id) {
      try {
        await ListModelDetailService.update({ id: $id });
      } catch (err) {
        console.log(err.message)
        throw (err)
      }
    },
    async getListModelLayout($res) {
      try {
        var res = await ListModelLayoutService.findAll({ model_id: $res.id })
        if (res.length > 0) {
          this.layoutData = JSON.parse(res[0].layout)
        }
        else{
          await ListModelLayoutService.create({model_id: $res.id, name: $res.name})
        }

      } catch (err) {
        console.log(err.message)
        throw (err)
      }

    },
    async getListModelFiter($res) {
      try {
        var res = await ListModelFilterService.findAll({ model_id: $res.id })
        if (res.length > 0) {
          this.filterId = res[0].id
          this.getListModelFiterDetails(res[0].id)
        }else{
          await ListModelFilterService.create({model_id: $res.id, name: $res.name})
        }
      } catch (err) {
        console.log(err.message)
        throw (err)
      }

    },
    async getListModelFiterDetails($fiter_id) {
      try {
        var res = await ListModelFilterDetailService.findAll({ model_filter_id: $fiter_id })
        if (res.length > 0) {
          this.filterData = res
        }
      } catch (err) {
        console.log(err.message)
        throw (err)
      }
    },
    async save(){
      if (this.reportData.id){
        await ReportService.update(this.reportData)
        this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
      }else{
        await ReportService.create(this.reportData)
        this.$store.dispatch("setSnackbar", { text: "Saved Successfully" });
      }
    },
    showListModel($model_id) {
      this.getListModelLayout($model_id)
    },
    adddialog() {
      this.dataSetDialog = true;
    },
    closeDialog() {
      this.dataSetDialog = false;
    },
    async openDialog($id) {
      if ($id) {
        await this.getListModelDetailsData(null, $id)
      }
      this.type = 'add';
      this.showAddColumn = true;

    },
    async closeAddcolumn($data) {
      await this.updateListModelDetailsData($data);
      this.showAddColumn = false;

    }
  }
}
</script>
<style scoped>
.border {
  border-style: solid;
  border-width: 1px;
  margin: 4px;
  border-radius: 6px;
  border-color: #0000001f;
  height: 500px;
}
.mdi-plus-circle::before {
    content: "\F0417";
    padding-right: 66px;
}
/* .scroll{
    overflow: auto;
    height: 500px;
    overflow-x: hidden;
} */
</style>
