<template>
  <div>
    <Topmenu />
    <Login />
    <Overview />
    <v-divider />
    <Solution />
    <Solution1 />
    <Features />
    <v-divider />
     <Clients /> 
     <v-divider />
    <Map />
    <v-divider />
    <About />
  </div>
</template>
<script>
import Topmenu from "./templates/topmenu.vue";
import Login from "./templates/login.vue";
import Overview from "./templates/overview.vue";
import Solution from "./templates/solutions.vue";
import Solution1 from "./templates/solutions1.vue";
import Features from "./templates/features.vue";
import Clients from "./templates/clients.vue";
import Map from "./templates/map.vue";
import About from "./templates/about.vue";
export default {
  components: {
    Login,
    Overview,
    Solution,
    Solution1,
    Features,
    Clients,
    Map,
    About,
    Topmenu
  },
};
</script>