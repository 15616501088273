<template>
  <v-container>
    <v-sheet class="pa-3 rounded-lg banner" style="height: 75px;">
      <div class=" pt-2">
        <ImageCard :width="'110px'" :avatar="true" :keyId="userData.id" :keyType="'UP'" :height="'110px'"
          v-on:imageChanged="updateImage($event)" :documentId="userData.image_profile_id" :initials="getUserName()" />
        <!-- <v-avatar color="teal" size="110"><span class="text-h3">{{ this.userData && this.userData.first_name ? (this.userData.first_name.slice(0,1)).toUpperCase() : '' }}{{ this.userData && this.userData.last_name ? (this.userData.last_name.slice(0,1)).toUpperCase() : '' }}</span></v-avatar> -->
        <span class="name text-h6 title">{{ userData && userData.first_name ? userData.first_name : '' }} {{ this.userData
          && this.userData.last_name ? (this.userData.last_name) : '' }}</span>
        <span>
          <v-btn density="comfortable" elevation="0" icon="mdi-close" style="float: right;background: none;color: white;"
            @click="this.$router.go(-1)"></v-btn>
        </span>
      </div>
    </v-sheet>

    <v-row>
      <v-col cols="1" style="width: 10%;">

      </v-col>
      <v-col class="card-align">
        <v-card class="mt-2 pa-5" style="width:90%;" elevation="0">
          <v-col class=" pa-2" cols="8">
            <InputBox :label="'User Name'" :data="userData.name" @textData="($event) => { userData.name = $event; }" />
          </v-col>
          <v-col class=" pa-2" cols="8">
            <InputBox :label="'Email'" :data="userData.email" @textData="($event) => { userData.email = $event; }" />
          </v-col>
          <v-col class=" pa-2" cols="8">
            <InputBox :label="'Mobile'" :data="userData.mobile" @textData="($event) => { userData.mobile = $event; }" />
          </v-col>
          <v-col class=" pa-2" cols="8">
            <InputBox :label="'First Name'" :data="userData.first_name"
              @textData="($event) => { userData.first_name = $event; }" />
          </v-col>
          <v-col class=" pa-2" cols="8">
            <InputBox :label="'Last Name'" :data="userData.last_name"
              @textData="($event) => { userData.last_name = $event; }" />
          </v-col>
          <v-card-actions class="dialogfooter mt-3">
            <v-spacer></v-spacer>
            <v-btn class="mr-1 cancel" @click="this.$router.go(-1)">Cancel</v-btn>
            <v-btn class="save" type="submit" @click="save()">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import UserService from '@/services/admin/UserService';
import ImageCard from './ImageCard.vue';
export default {
  components: {
    ImageCard
  },
  data() {
    return {
      userId: null,
      userData: {
        first_name: '',
        last_name: ''
      },
    }
  },
  created() {
    this.userId = JSON.parse(localStorage.getItem('userDetails')).id
    this.getUserData();
  },
  methods: {
    async updateImage($data) {
      this.userData.image_profile_id = $data.id
      let data = {
        id: this.userData.id,
        image_profile_id: $data.id
      }
      await UserService.update(data)
    },
    getUserName() {
      if (this.userData && this.userData.first_name)
        return this.userData && this.userData.last_name ? this.userData.first_name.substr(0, 1).toUpperCase() + this.userData.last_name.substr(0, 1).toUpperCase() : this.userData.first_name.substr(0, 1).toUpperCase()
    },
    async getUserData() {
      let res = await UserService.findAll({ id: this.userId })
      this.userData = res[0]
      console.log(this.userData, 'this.userdata')
      this.userName = this.userData && this.userData.first_name ? (this.userData.first_name.slice(0, 1)).toUpperCase() : ''
    },
    async save() {
      await UserService.update(this.userData)
    }
  }
}
</script>

<style scoped>
.name {
  position: absolute;
  margin-top: 6px;
  padding-left: 10px;
}

.title {
  color: #0D0D67;
}

.banner {
  background-image: linear-gradient(to right,
      rgba(18, 88, 171, 0.1),
      rgb(18, 88, 171));
}

.card-align {
  display: flex;
  justify-content: flex-end;
}
</style>

