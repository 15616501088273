<template>
     <!-- <v-icon size="large" class="pl-8 pt-1" @click="$router.push('/chooseMarketPlace')">mdi-arrow-left-circle-outline</v-icon>
    <v-col class="pt-2 pb-0 pl-5 settingHeader"><v-icon>mdi-storefront-outline</v-icon> Stores </v-col> -->
    <v-row class="pa-5 contentsubmenu">
            <v-card min-height="60vh" elevation="0" class="content-menu-cardleft rounded-lg">
                <!-- <AutoComplete class="mt-1"  :items="data" :singleLine="true" label="Marketplace"></AutoComplete> -->
                <v-list  class="content-menu-list">
                    <v-list-item   :to="item.router" class="pa-4 contentsidemenu" v-for="(item, index) in items" :key="index" :value="index"
                        active-class="selecteditem">
                        <v-list-item-title >{{ item.title }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-card>

            <v-card  elevation="0" class="content-menu-cardright rounded-lg"> 
                <RouterView></RouterView>              
            </v-card>
    </v-row>
</template>
<script>

export default {
    data() {
        return {
            data:['SPO','Macy','Channel Advisor'],
            items: [
                {
                    title: "Profile",
                    value: 1,
                    router: "/marketPlace/profile"
                },
                {
                    title: "Integration",
                    value: 2,
                    router: "/marketPlace/integration"
                },
                {
                    title: "Products",
                    value: 3,
                    router: "/marketPlace/products"
                },
                {
                    title: "Orders",
                    value: 4,
                    router: "/marketPlace/orders"
                },
                {
                    title: "Inventory",
                    value: 5,
                    router: "/marketPlace/inventory"
                },
                {
                    title: "Locations ",
                    value: 6,
                    router: "/marketPlace/location"
                },
                {
                    title: "Shipment Rule",
                    value: 7,
                    router: "/marketPlace/shipmentRule"
                },
                {
                    title: "Pricing Rule ",
                    value: 8,
                    router: "/marketPlace/pricingRule"
                },
                {
                    title: "Discount Code ",
                    value: 9,
                    router: "/marketPlace/discountCode"
                },
                {
                    title: "Sku Mapping ",
                    value: 10,
                    router: "/marketPlace/skuMapping"
                },
                {
                    title: "Inventory Mapping",
                    value: 11,
                    router: "/marketPlace/inventoryMapping"
                },
                {
                    title: "Product in Store",
                    value: 12,
                    router: "/marketPlace/productInstore"
                },
                {
                    title: "Product Inventory",
                    value: 13,
                    router: "/marketPlace/productInventoy"
                },
            ]
        };
    },
    created(){
        this.scrollup();
    },
    methods: {
         scrollup(){
            window.scrollTo({
            top: 0,
            behavior: 'instant',
      });
         }
    //     selectItem(title, index) {
    //         this.selectedItem = title;
    //         window.scrollTo(0, 0);
    //         if (index <= 4) {
    //             this.showHeader = true;
    //         }
    //         else {
    //             this.showHeader = false;
    //         }
    //     },
    },
}
</script>
<style scoped>
/* >>> .v-toolbar__content, .v-toolbar__extension {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    position: relative;
    transition: inherit;
    width: 100%;
    /* background-color: #3C5aaa; */
/* } */ 
/* >>> .v-toolbar-title__placeholder {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    /* color: white; */
/* }  */
</style>