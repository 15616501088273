<template>
    <v-card elevation="0">
        <v-card-title class="text-body-2">
            Ship To Address
        </v-card-title>
        <v-card-text class="pl-1">
   <!-- Address Column -->
   <v-col cols="12" class="">
                <b><span v-if="mwsOrderdata && mwsOrderdata.ship_to_salutation">{{ mwsOrderdata && mwsOrderdata.ship_to_salutation ? mwsOrderdata.ship_to_salutation : ''}}.</span> <span v-if="mwsOrderdata && mwsOrderdata.ship_to_name">{{ mwsOrderdata && mwsOrderdata.ship_to_name ? mwsOrderdata.ship_to_name : ''}}</span></b>
                <p class="pt-5" v-if="mwsOrderdata && mwsOrderdata.ship_to_address1">{{ mwsOrderdata && mwsOrderdata.ship_to_address1.length>2 ? mwsOrderdata.ship_to_address1 : 'No Shipping Address'}}</p>
                <p ><span v-if="mwsOrderdata && mwsOrderdata.ship_to_address2">{{ mwsOrderdata && mwsOrderdata.ship_to_address2.length>2 ? mwsOrderdata.ship_to_address2 : ''}}</span> <span v-if="mwsOrderdata && mwsOrderdata.ship_to_address3">{{ mwsOrderdata && mwsOrderdata.ship_to_address3 ? mwsOrderdata.ship_to_address3 : ''}}</span><span v-if="mwsOrderdata && mwsOrderdata.ship_to_address4"> {{ mwsOrderdata && mwsOrderdata.ship_to_address4 ? mwsOrderdata.ship_to_address4 : ''}},</span></p>
                <p ><span v-if="mwsOrderdata && mwsOrderdata.ship_to_state">{{ mwsOrderdata && mwsOrderdata.ship_to_state ? mwsOrderdata.ship_to_state : ''}},</span> <span v-if="mwsOrderdata && mwsOrderdata.ship_to_city">{{ mwsOrderdata && mwsOrderdata.ship_to_city ? mwsOrderdata.ship_to_city : ''}}</span> <span v-if="mwsOrderdata && mwsOrderdata.ship_to_country">{{ mwsOrderdata && mwsOrderdata.ship_to_country ? mwsOrderdata.ship_to_country : ''}},</span> <span v-if="mwsOrderdata && mwsOrderdata.ship_to_zip_code">{{ mwsOrderdata && mwsOrderdata.ship_to_zip_code ? mwsOrderdata.ship_to_zip_code : ''}}</span></p>
                
                <p class="pt-4" v-if="mwsOrderdata && mwsOrderdata.ship_to_mobile"><span class="p-title">Ph:</span>&nbsp;&nbsp;{{mwsOrderdata && mwsOrderdata.ship_to_mobile ? mwsOrderdata.ship_to_mobile : ''}}</p>
                <p v-if="mwsOrderdata && mwsOrderdata.ship_to_email"><span class="p-title">Email:</span>&nbsp;&nbsp;{{mwsOrderdata && mwsOrderdata.ship_to_email ? mwsOrderdata.ship_to_email : ''}}</p>
              </v-col>
            <!-- <v-col>
                <v-row>
                <v-col cols="6" class="mr-0 pr-0">
                 <p v-if="mwsOrderdata && mwsOrderdata.ship_to_mobile">Ph: {{ mwsOrderdata && mwsOrderdata.ship_to_mobile ? mwsOrderdata.ship_to_mobile : ''}}</p>
                </v-col>
                <v-col cols="6" align="end" class="ml-0 pl-0">
                 <p v-if="mwsOrderdata && mwsOrderdata.ship_to_email">{{ mwsOrderdata && mwsOrderdata.ship_to_email ? mwsOrderdata.ship_to_email : ''}}</p>
                </v-col>
            </v-row>
            </v-col> -->
        </v-card-text>
    </v-card>
</template>
<script>
export default{
    props:['data'],
  data(){
    return{
      mwsOrderdata:{}, 
    }
  },
  watch: {
        data() {
            this.mwsOrderdata=this.data
        },
    },
  created(){
    this.mwsOrderdata=this.data
  },
  methods:{

  }
}
</script>
<style>
.p-title{
  color: #636363;
}
</style>