<template>
    <v-row justify="center">
        <v-col cols="3" class="pa-4" v-for="(item, index) in items" :key="index">
            <v-card @click="select(index)" :class="{ onbordActive: item.isSelect }" elevation="5" height="170"
                class="rounded-lg onboardCard">
                <v-card-text class="text-center mt-4">
                    <div class="text-center mt-2">{{ item.title }}</div>
                    <v-icon class="tick-icon" color="green" size="80">mdi-check-circle-outline</v-icon>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>

export default {
    props: ['erp', 'user', 'reload'],
    data() {
        return {
            items: [
                {
                    title: 'On Premises ERP',
                    isSelect: false,
                    value: 'onpremises'
                },
                {
                    title: 'ERP on a Cloud',
                    isSelect: false,
                    value: 'cloud'
                },
                {
                    title: 'Access to the database connection',
                    isSelect: false,
                    value: 'database'
                },
            ]
        }
    },
    watch: {
        reload() {
            this.items.forEach(f => {
                f.isSelect = false
            })
        }
    },
    methods: {
        async select(index) {
            this.items.forEach(element => {
                element.isSelect = false
            });
            this.items[index].isSelect = true
            let config = {
                type: this.items[index].value
            }
            let data = {
                erp: this.erp,
                configuration: JSON.stringify(config),
                active: 1
            }
            localStorage.setItem('ERPData', JSON.stringify(data))
            // await ErpProfileService.create(data)
            this.$emit('erptype')
        }
    }
}
</script>

