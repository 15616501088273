<template>
  <v-card>
    <v-form @submit.prevent="save" ref="form">
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" class="pa-0 pl-2">
              <InputBox label="Email" :disabled="showUserDetails" :rules="EmailRule" :data="users.email"
                @textData="($event) => { users.email = $event; }" @keyChange="anychange()" />
              <span style="color: #b00020" v-if="existsMessage">Email already exists</span>
            </v-col>
            <v-col cols="6" v-if="showUserDetails" class="pa-0 pl-2">
              <InputBox :data="users.first_name" @textData="($event) => { users.first_name = $event; }"
                :disabled="showReadOnly" :label="'First Name'" :rules="FirstNameRule" />
            </v-col>
            <v-col cols="6" v-if="showUserDetails" class="pa-0 pl-2">
              <InputBox :data="users.last_name" @textData="($event) => { users.last_name = $event; }"
                :disabled="showReadOnly" :label="'Last Name'" />
            </v-col>
            <v-col cols="6" v-if="showUserDetails" class="pa-0 pl-2">
              <InputBox :data="users.name" @textData="($event) => { users.name = $event; }" :disabled="showReadOnly"
                :label="'User Name'" :rules="UserNameRule" />
            </v-col>
            <v-col cols="6" v-if="showUserDetails" class="pa-0 pl-2">
              <InputBox :data="users.mobile" @textData="($event) => { users.mobile = $event; }" :disabled="showReadOnly"
                @keypress="allowNumbers($event)" :label="'Mobile'" :rules="MobileRule" />
            </v-col>
            <!-- <v-col cols="6" v-if="showUserDetails" class="pa-0 pl-2">
              <EnumDropdown :data="users.user_type" @textData="($event) => { users.user_type = $event; }"
                :disabled="showReadOnly" :label="'User Type'" :domainName="'usertype'" :rules="typeRule" />
            </v-col> -->
            <v-col cols="6" v-if="showUserDetails && (users && users.id)" class="pa-0 pl-2">
              <AutoComplete :data="users.active" @textData="($event) => { users.active = $event; }"
                :disabled="showReadOnly" :items="userStatus" :text="'name'" :value="'id'" :label="'User Status'" />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="dialogfooter" v-if="!showReadOnly">
        <v-spacer></v-spacer>
        <v-btn class="mr-1 cancel" @click="closeDialog(false)">Cancel</v-btn>
        <v-btn class="save" @click="save" :loading="loading">{{
          !showUserDetails ? "Verify" : "Save"
        }}</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import AutoComplete from "@/components/AutoComplete.vue";
import InputBox from "@/components/InputBox.vue";
import UserService from "@/services/admin/UserService";
import CompanyXrefService from "@/services/admin/CompanyXrefService";
export default {
  props: ["type", "id", "user_data"],
  components: {
    InputBox,
    AutoComplete,
  },
  data() {
    return {
      typeRule: [(value) => (!!value || "User Type is Required")],
      userStatus: [{ id: 0, name: 'In-Active' },
      { id: 1, name: 'Active' }],
      EmailRule: [
        (value) => !!value || "E-mail is Required",
        (value) => {
          if (/.+@.+\..+/.test(value)) {
            this.isValidEmail = true;
            return true;
          }
          this.isValidEmail = false;
          return "E-mail must be valid.";
        },
      ],
      FirstNameRule: [(value) => !!value || "First Name is Required"],
      // LastNameRule: [(value) => !!value || "Last Name is Required"],
      UserNameRule: [(value) => !!value || "User Name is Required"],
      MobileRule: [(value) => !!value || "Mobile is Required"],
      showReadOnly: false,
      isValidEmail: false,
      showUserDetails: false,
      loading: false,
      existsMessage: false,
      message: null,
      users: {
        email: null,
        first_name: null,
        last_name: null,
        name: null,
        mobile: null,
        user_type: null,
      },
      userData: [],
      userTypes: [],
    };
  },
  watch: {
    id() {
      if (this.id) {
        this.users = {};
        this.getById(this.id);
      }
    },
  },
  created() {
    if (this.id) {
      this.getById(this.id);
    }
    if (this.type) {
      this.showReadOnly = this.type == "view" ? true : false;
    }
  },

  methods: {
    async getById($id) {
      this.showUserDetails = true;
      let res = await UserService.findOne({ id: $id });
      this.users = res[0];
      console.log(this.users, "userData");
    },
    closeDialog(value) {
      this.$emit("closeDialog", value);
    },
    allowNumbers(evt) {
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();
      if (!/^[+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    anychange() {
      this.existsMessage = false;
    },
    async save() {
      let validation = await this.$refs.form.validate();
      if (!this.isValidEmail) {
        return;
      }
      if (this.users.email && this.users.email) {
        if (!this.showUserDetails) {
          this.loading = true;
          await UserService.checkUser({ email: this.users.email }).then((res) => {
            this.loading = false;
            if (res.message == "Email already exists") {
              this.existsMessage = true;
            } else {
              this.showUserDetails = true;
              this.loading = false;
              this.message = res.message;
              if (res && res.data && res.data.id) {
                this.users = res.data;
              }
              console.log("1111111", res);
              console.log("2222222", res.message)
              if (res.password) {
                this.showPassword = true;
              } else {
                this.showPassword = false;
              }
            }
          });
        } else if (validation.valid) {
          this.loading = true;
          let userCompany = {};
          userCompany.user_id = this.users.id
          // userCompany.email = this.users.email
          let accountId = [JSON.parse(localStorage.getItem('_ACCOUNT_ID_'))]
          userCompany.account_id = JSON.stringify(accountId);
          userCompany.company_id = JSON.parse(localStorage.getItem('companyDetails')).id
          if (this.users && this.users.id) {
            if (this.message == 'Create CompanyXref') {
              await UserService.update(this.users)
              await CompanyXrefService.createNewCompanyXref(userCompany)
                .then(
                  (res) => {
                    console.log(res);
                    this.loading = false;
                    this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
                  },
                  (error) => {
                    console.log(error)
                  }
                )

            }
            else {
              await UserService.update(this.users).then(
                (res) => {
                  console.log(res);
                  this.loading = false;
                  this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
                },
                (error) => {
                  console.log(error);
                }
              );
            }


          } else {
            this.users.accountId = JSON.stringify(accountId)
            await UserService.addUser(this.users).then(
              (res) => {
                console.log(res);
                this.loading = false;
                this.$store.dispatch("setSnackbar", { text: "Saved Successfully" });
              },
              (error) => {
                console.log(error);
              }
            );
          }
          this.closeDialog(true);
        }
      }
    },
  },
};
</script>

<style></style>
