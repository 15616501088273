import ApiService from '../../api/service/ApiService.js';
const querystring = require('querystring');
import apiConfig from '@/api/config/config.js';
var currentlySelectedTree=null;
var flowChange = 1;
var newId=null;
import { authStore } from "@/store";
var WorkFlowService = {
    
    findAll: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "workflows"
            $options.method = "get"
            $options.baseUrl = apiConfig.adminUrl
            if ($data) {
                $options.url = "workflows?" + querystring.stringify($data)
                console.log($options.url)
            }
            ApiService.request($options)
            .then(response => {
                console.log('test');
                resolve(response.data.data)
            }).catch(error => {
                reject(error);
            })
        });

    },
  
    findOne: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.url = "workflows/" + $data.id
            console.log($options.url)
            $options.method = "get"
            $options.baseUrl = apiConfig.adminUrl
            if (!$data) {
                $options.data = $data
            }
            ApiService.request($options)
                .then(response => {
                    // console.log('Gpt the data');
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });

    },
    update: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.data = $data
            $options.url = "workflows/" + $data.id;
            $options.method = "post"
            $options.baseUrl = apiConfig.adminUrl
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
        })
    },
    save: function($data) {
        return new Promise(function(resolve, reject) {
            var $options = {};
            $options.data = $data
            $options.url = "workflows" + $data.id;
            $options.method = "post"
            $options.baseUrl = apiConfig.adminUrl
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
        });
    },

    create: function($data) {
        return new Promise(function(resolve, reject) {
            var $options = {};
            $options.data = $data
            $options.url = "workflows";
            $options.method = "post"
            $options.baseUrl = apiConfig.adminUrl
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
        });
    },
    delete: function($data) {
        return new Promise(function(resolve, reject) {
            let $options = {};
            $options.data = $data
            $options.url = "workflows/" + $data.id;
            $options.method = "delete"
            $options.baseUrl = apiConfig.adminUrl
            console.log($options)
            ApiService.request($options)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                });
        })
    },
    addChild(id, name) {
        const childArray = this.getChildrenOfID(currentlySelectedTree[0], id);
        childArray.push({
          'name': name,
          'id': newId,
          'description': 'this is a sheep description',
          'datecreated': new Date(),
          'showchildren': '1',
          'taskTypeData': {},
          'task': flowChange,
          'nextActionId':newId + 1,
          'children': [],
          'active': true
        });
        newId = newId + 1;
        authStore.commit("setChildArray", childArray);
      },
    
      getChildrenOfID(tree, id) {
        const stack = [tree];
        while (stack.length) {
          const current = stack.pop();
          if (current.id === id) {
            return current.children;
          }
          stack.push(...current.children);
        }
      }
};
export default WorkFlowService;