import Channel from '@/modules/Channel/Channel.vue'
import ListModel from '@/modules/Channel/ListModel.vue'
import AddListModel from '@/modules/Channel/AddListModel.vue'
import Enum from '@/modules/Channel/Enum.vue'
import CommonChannel from '@/modules/Channel/CommonChannel.vue'
import AddCommonChannel from '@/modules/Channel/AddCommonChannel.vue'
export default [
  {
    path: '',
    component: Channel,
    children: [
      {
        path: 'listmodel',
        name: 'ListModel',
        children: [
          {
            path: '',
            name: 'ListModel',
            component: ListModel,
            meta: {
              breadCrumb: [
                {
                  to: '/channel/listmodel',
                  text: 'Settings',
                  color: '#3C5AAA'
                },
                {
                  to: '/channel/listmodel',
                  text: 'Channel',
                  color: '#3C5AAA'
                },
                {
                  to: '/channel/listmodel',
                  text: 'ListModel'
                },
              ]
            }
          },
          {
            path: 'add',
            name: 'addListModel',
            component: AddListModel,
            meta: {
              breadCrumb: [
                {
                  to: '/channel/listmodel',
                  text: 'Settings',
                  color: '#3C5AAA'
                },
                {
                  to: '/channel/listmodel',
                  text: 'Channel',
                  color: '#3C5AAA'
                },
                {
                  to: '/channel/listmodel',
                  text: 'ListModel',
                  color: '#3C5AAA'
                },
                {
                  to: '/channel/listmodel/add',
                  text: 'Add ListModel'
                },
              ]
            }
          },
        ]
      },
      {
        path: 'enum',
        name: 'Enum',
        component: Enum,
        meta: {
          breadCrumb: [
            {
              to: '/channel/enum',
              text: 'Settings',
              color: '#3C5AAA'
            },
            {
              to: '/channel/enum',
              text: 'Channel',
              color: '#3C5AAA'
            },
            {
              to: '/channel/enum',
              text: 'Enum'
            },
          ]
        }
      },
      {
        path: 'email',
        name: 'Email',
        children: [
          {
            path: '',
            name: 'Email',
            component: CommonChannel,
            meta: {
              breadCrumb: [
                {
                  to: '/channel/email',
                  text: 'Settings',
                  color: '#3C5AAA'
                },
                {
                  to: '/channel/email',
                  text: 'Channel',
                  color: '#3C5AAA'
                },
                {
                  to: '/channel/email',
                  text: 'Email'
                },
              ]
            }
          },
          {
            path: 'addEmail',
            name: 'email',
            component: AddCommonChannel,
            meta: {
              breadCrumb: [
                {
                  to: '/channel/email',
                  text: 'Settings',
                  color: '#3C5AAA'
                },
                {
                  to: '/channel/email',
                  text: 'Channel',
                  color: '#3C5AAA'
                },
                {
                  to: '/channel/email',
                  text: 'Email',
                  color: '#3C5AAA'
                },
                {
                  to: '/channel/email/addEmail',
                  text: 'Add Email'
                }
              ]
            }
          },
        ]
      },
      {
        path: 'sms',
        name: 'SMS',
        children: [
          {
            path: '',
            name: 'SMS',
            component: CommonChannel,
            meta: {
              breadCrumb: [
                {
                  to: '/channel/sms',
                  text: 'Settings',
                  color: '#3C5AAA'
                },
                {
                  to: '/channel/sms',
                  text: 'Channel',
                  color: '#3C5AAA'
                },
                {
                  to: '/channel/sms',
                  text: 'SMS'
                },
              ]
            }
          },
          {
            path: 'addSMS',
            name: 'sms',
            component: AddCommonChannel,
            meta: {
              breadCrumb: [
                {
                  to: '/channel/sms',
                  text: 'Settings',
                  color: '#3C5AAA'
                },
                {
                  to: '/channel/sms',
                  text: 'Channel',
                  color: '#3C5AAA'
                },
                {
                  to: '/channel/sms',
                  text: 'SMS',
                  color: '#3C5AAA'
                },
                {
                  to: '/channel/sms/addSMS',
                  text: 'Add SMS'
                }
              ]
            }
          },
        ]
      },
      {
        path: 'whatsapp',
        name: 'Whatsapp',
        children: [
          {
            path: '',
            name: 'Whatsapp',
            component: CommonChannel,
            meta: {
              breadCrumb: [
                {
                  to: '/channel/whatsapp',
                  text: 'Settings',
                  color: '#3C5AAA'
                },
                {
                  to: '/channel/whatsapp',
                  text: 'Channel',
                  color: '#3C5AAA'
                },
                {
                  to: '/channel/whatsapp',
                  text: 'Whatsapp'
                },
              ]
            }
          },
          {
            path: 'addWhatsapp',
            name: 'whatsapp',
            component: AddCommonChannel,
            meta: {
              breadCrumb: [
                {
                  to: '/channel/whatsapp',
                  text: 'Settings',
                  color: '#3C5AAA'
                },
                {
                  to: '/channel/whatsapp',
                  text: 'Channel',
                  color: '#3C5AAA'
                },
                {
                  to: '/channel/whatsapp',
                  text: 'Whatsapp',
                  color: '#3C5AAA'
                },
                {
                  to: '/channel/whatsapp/addWhatsapp',
                  text: 'Add Whatsapp'
                }
              ]
            }
          },
        ]
      },
      {
        path: 'notification',
        name: 'Notification',
        children: [
          {
            path: '',
            name: 'Notification',
            component: CommonChannel,
            meta: {
              breadCrumb: [
                {
                  to: '/channel/notification',
                  text: 'Settings',
                  color: '#3C5AAA'
                },
                {
                  to: '/channel/notification',
                  text: 'Channel',
                  color: '#3C5AAA'
                },
                {
                  to: '/channel/notification',
                  text: 'Notification'
                },
              ]
            }
          },
          {
            path: 'addNotification',
            name: 'notification',
            component: AddCommonChannel,
            meta: {
              breadCrumb: [
                {
                  to: '/channel/notification',
                  text: 'Settings',
                  color: '#3C5AAA'
                },
                {
                  to: '/channel/companyprofile',
                  text: 'Channel',
                  color: '#3C5AAA'
                },
                {
                  to: '/channel/notification',
                  text: 'Notification',
                  color: '#3C5AAA'
                },
                {
                  to: '/channel/notification/addNotification',
                  text: 'Add Notification'
                }
              ]
            }
          },
        ]
      },
      {
        path: 'api',
        name: 'Api',
        children: [
          {
            path: '',
            name: 'Api',
            component: CommonChannel,
            meta: {
              breadCrumb: [
                {
                  to: '/channel/api',
                  text: 'Settings',
                  color: '#3C5AAA'
                },
                {
                  to: '/channel/api',
                  text: 'Channel',
                  color: '#3C5AAA'
                },
                {
                  to: '/channel/api',
                  text: 'Api'
                },
              ]
            }
          },
          {
            path: 'addApi',
            name: 'api',
            component: AddCommonChannel,
            meta: {
              breadCrumb: [
                {
                  to: '/channel/api',
                  text: 'Settings',
                  color: '#3C5AAA'
                },
                {
                  to: '/channel/api',
                  text: 'Channel',
                  color: '#3C5AAA'
                },
                {
                  to: '/channel/api',
                  text: 'Api',
                  color: '#3C5AAA'
                },
                {
                  to: '/channel/api/addApi',
                  text: 'Add Api'
                }
              ]
            }
          },
        ]
      },
      {
        path: 'uploadDocument',
        name: 'Upload Document',
        children: [
          {
            path: '',
            name: 'Upload Document',
            component: CommonChannel,
            meta: {
              breadCrumb: [
                {
                  to: '/channel/uploadDocument',
                  text: 'Settings',
                  color: '#3C5AAA'
                },
                {
                  to: '/channel/uploadDocument',
                  text: 'Channel',
                  color: '#3C5AAA'
                },
                {
                  to: '/channel/uploadDocument',
                  text: 'Upload Document'
                },
              ]
            }
          },
          {
            path: 'addUploadDocument',
            name: 'uploadDocument',
            component: AddCommonChannel,
            meta: {
              breadCrumb: [
                {
                  to: '/channel/uploadDocument',
                  text: 'Settings',
                  color: '#3C5AAA'
                },
                {
                  to: '/channel/uploadDocument',
                  text: 'Channel',
                  color: '#3C5AAA'
                },
                {
                  to: '/channel/uploadDocument',
                  text: 'Upload Document',
                  color: '#3C5AAA'
                },
                {
                  to: '/channel/uploadDocument/addUploadDocument',
                  text: 'Add Upload Document'
                }
              ]
            }
          },
        ]
      },
      {
        path: 'glIntegration',
        name: 'GL Integration',
        children: [
          {
            path: '',
            name: 'GL Integration',
            component: CommonChannel,
            meta: {
              breadCrumb: [
                {
                  to: '/channel/glIntegration',
                  text: 'Settings',
                  color: '#3C5AAA'
                },
                {
                  to: '/channel/glIntegration',
                  text: 'Channel',
                  color: '#3C5AAA'
                },
                {
                  to: '/channel/glIntegration',
                  text: 'GL Integration'
                },
              ]
            }
          },
          {
            path: 'addGlIntegration',
            name: 'glIntegration',
            component: AddCommonChannel,
            meta: {
              breadCrumb: [
                {
                  to: '/channel/glIntegration',
                  text: 'Settings',
                  color: '#3C5AAA'
                },
                {
                  to: '/channel/glIntegration',
                  text: 'Channel',
                  color: '#3C5AAA'
                },
                {
                  to: '/channel/glIntegration',
                  text: 'GL Integration',
                  color: '#3C5AAA'
                },
                {
                  to: '/channel/glIntegration/addGlIntegration',
                  text: 'Add GL Integration'
                }
              ]
            }
          },
        ]
      },
      {
        path: 'formBuilder',
        name: 'Form Builder',
        children: [
          {
            path: '',
            name: 'Form Builder',
            component: CommonChannel,
            meta: {
              breadCrumb: [
                {
                  to: '/channel/formBuilder',
                  text: 'Settings',
                  color: '#3C5AAA'
                },
                {
                  to: '/channel/formBuilder',
                  text: 'Channel',
                  color: '#3C5AAA'
                },
                {
                  to: '/channel/formBuilder',
                  text: 'Form Builder'
                },
              ]
            }
          },
          {
            path: 'addFormBuilder',
            name: 'formBuilder',
            component: AddCommonChannel,
            meta: {
              breadCrumb: [
                {
                  to: '/channel/formBuilder',
                  text: 'Settings',
                  color: '#3C5AAA'
                },
                {
                  to: '/channel/formBuilder',
                  text: 'Channel',
                  color: '#3C5AAA'
                },
                {
                  to: '/channel/formBuilder',
                  text: 'Form Builder',
                  color: '#3C5AAA'
                },
                {
                  to: '/channel/formBuilder/addFormBuilder',
                  text: 'Add Form Builder'
                }
              ]
            }
          },
        ]
      },
      {
        path: 'nextfloInternalApi',
        name: 'Nextflo Internal Api',
        children: [
          {
            path: '',
            name: 'Nextflo Internal Api',
            component: CommonChannel,
            meta: {
              breadCrumb: [
                {
                  to: '/channel/nextfloInternalApi',
                  text: 'Settings',
                  color: '#3C5AAA'
                },
                {
                  to: '/channel/nextfloInternalApi',
                  text: 'Channel',
                  color: '#3C5AAA'
                },
                {
                  to: '/channel/nextfloInternalApi',
                  text: 'Nextflo Internal Api'
                },
              ]
            }
          },
          {
            path: 'addNextfloInternalApi',
            name: 'nextfloInternalApi',
            component: AddCommonChannel,
            meta: {
              breadCrumb: [
                {
                  to: '/channel/nextfloInternalApi',
                  text: 'Settings',
                  color: '#3C5AAA'
                },
                {
                  to: '/channel/nextfloInternalApi',
                  text: 'Channel',
                  color: '#3C5AAA'
                },
                {
                  to: '/channel/nextfloInternalApi',
                  text: 'Nextflo Internal Api',
                  color: '#3C5AAA'
                },
                {
                  to: '/channel/nextfloInternalApi/addNextfloInternalApi',
                  text: 'Add Nextflo Internal Api'
                }
              ]
            }
          },
        ]
      },
    ],
  }
]