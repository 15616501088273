<template>
  <div>
    <v-toolbar color="#fff" flat>
      <v-toolbar-title class="settingHeader">Inventory</v-toolbar-title>
      <v-btn
        class="button"
        v-if="!showReadOnly"
        style="float: right"
        @click="createUpdateMws"
        >Save</v-btn
      >
      <v-btn
        variant="outlined"
        v-if="showReadOnly"
        @click="edit"
        class="usersent-btn"
        prepend-icon="mdi-pencil-outline"
        style="float: right"
        >Edit</v-btn
      >
      <v-btn
        variant="text"
        density="comfortable"
        @click="close"
        v-if="!showReadOnly"
        elevation="0"
        icon="mdi-close"
        style="float: right"
      ></v-btn>
    </v-toolbar>
    <v-divider class="mb-2"></v-divider>
    <v-container class="pa-7 pl-8">
      <v-row>
        <v-col cols="6" class="pa-0">
          <AutoComplete
            :data="inventoryPreferences.syncProducts"
            @textData="($event) => {inventoryPreferences.syncProducts = $event;}"
            :items="syncOptions"
            :text="'name'"
            :value="'code'"
            :disabled="showReadOnly"
            :label="'Sync Products based on SKU/UPC'"
          ></AutoComplete>
        </v-col>
        <v-col cols="6" class="pa-0 pl-2">
          <AutoComplete
            :data="inventoryPreferences.fulfillmentSelected"
            @textData="($event) => {inventoryPreferences.fulfillmentSelected = $event;}"
            :disabled="showReadOnly"
            :multiple="true"
            :items="fulfillments"
            :text="'name'"
            :value="'id'"
            chips
            :closeChip="true"
            :label="'Fulfillment in Use to sync the marketplace Inventory'"
          ></AutoComplete>
        </v-col>
        <v-col cols="12" class="pt-0 pb-2 pr-2 sub-title"
          >Discrepancy Report Settings :-</v-col
        >
        <v-col cols="6" class="pa-0">
          <AutoComplete
            :data="inventoryPreferences.include"
            @textData="($event) => {inventoryPreferences.include = $event;}"
            :items="includes"
            :text="'name'"
            :value="'id'"
            :disabled="showReadOnly"
            :label="'Include'"
          ></AutoComplete>
        </v-col>
        <v-col cols="12" class="pl-2 pt-0 sub-title">Notifications :-</v-col>
        <v-col cols="4" class="pb-0 pt-0 pl-4">
          <v-switch
            v-model="inventoryPreferences.inventoryAlerts"
            :disabled="showReadOnly"
            color="#3C5AAA"
            :label="'Inventory Alerts'"
          >
          </v-switch>
        </v-col>
        <!-- <v-col cols="12">
          <TextArea
            v-model="inventoryPreferences.distribution_list"
            :label="'Distribution List for Inventory Snapshot'"
            :disabled="showReadOnly"
          />
        </v-col> -->
        <v-col cols="12" class="pa-0 pl-2">
            <QuilEditor
            :data="inventoryPreferences.distribution_list"
            :editorStyle="'height:200px;overflow: auto'"
            :showReadOnly="showReadOnly"
            @textData="
              ($event) => {
                inventoryPreferences.distribution_list = $event;
              }
            "
          ></QuilEditor>
        </v-col>
      </v-row>
    </v-container>
    <v-toolbar height="54px" class="table-header-bg pa-1" flat >
      <v-toolbar-title class="settingHeader">Thresholds</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip location="top" text="Add">
        <template v-slot:activator="{ props }">
          <v-sheet
            v-bind="props"
            border
            rounded
            disabled="true"
            id="threshold-activator"
            :height="45"
            :width="45"
            class="tableicon"
          >
            <v-icon>mdi-plus</v-icon>
          </v-sheet>
        </template>
      </v-tooltip>
    </v-toolbar>
    <v-table class="elevation-0 dataTable pa-2">
      <thead>
        <tr>
          <th v-for="header in headers" :key="header" class="text-left">
            {{ header }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(inventory, index) in inventorySyncRules" :key="index" @click="editThreshold(index, inventory,'select')">
          <td>{{ getFulFilmentMany(inventory.fulfillment_id) }}</td>
          <td>{{ getThresholdCategory(inventory.category_id) }}</td>
          <td>{{ getThresholdProduct(inventory.product_id) }}</td>
          <td>{{ inventory.min_value }} -- {{ inventory.max_value }}</td>
          <td>{{ inventory.formula }}</td>
          <td>
            <v-icon v-if="inventory.min_value != 0" class="me-2" @click="editThreshold(index, inventory,'view')">
              mdi-eye
            </v-icon>
            <v-icon v-if="inventory.min_value != 0" class="me-2" @click.stop="editThreshold(index, inventory,'edit')">
              mdi-pencil
            </v-icon>
            <v-icon v-if="inventory.min_value != 0"  @click.stop="deleterow(inventory,index)"> mdi-delete </v-icon>
          </td>
        </tr>
      </tbody>
    </v-table>
    <v-menu activator="#threshold-activator">
      <v-list>
        <v-list-item
          v-for="(item1, index1) in items"
          :key="index1"
          :value="index1"
          @click="openDialog(item1)"
        >
          <v-list-item-title> {{ item1.title }} </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-dialog v-model="entryModal" width="700px" persistent>
      <AddThreshold
        :editData="editData"
        @saveDialog="saveDialog"
        @closeDialog="closeDialog"
        :products="products"
        :showReadOnly="showReadOnly"
        :type="type"
      ></AddThreshold>
    </v-dialog>
  </div>
  <v-dialog v-model="confirmation" width="400px" persistent>
    <DialogConfirmation
      @cancelled="cancelled()"
      @deleted="deleted"
      :content="confirmationContent"
    />
  </v-dialog>
</template>
<script>
import MwsPreferenceService from "@/services/mws/MwsPreferenceService";
import FulfillmentService from "@/services/catalogs/FulfillmentService";
import moment from "moment";
import AddThreshold from "./AddThreshold.vue";
import CategoryService from "@/services/catalogs/CategoryService";
import QuilEditor from "@/components/QuilEditor.vue";
import ProductService from "@/services/catalogs/ProductService";
import lodash from "lodash";
import DialogConfirmation from "@/components/DialogConfirmation.vue";
export default {
  components: {
    AddThreshold,
    DialogConfirmation,
    QuilEditor,
  },
  data() {
    return {
      showReadOnly: true,
      categories: [],
      products: [],
      fulfillments: [],
      inventoryData: {},
      deleteIndex:null,
      entryModal: false,
      confirmation: false,
      addBefore: false,
      addAfter: false,
      index: 0,
      data: [],
      items: [
        {
          title: "Add Before",
        },
        {
          title: "Add After",
          },
      ],
      headers: [
        "Fulfillment",
        "Category",
        "Product",
        "Min Value --  Max Value",
        "Formula",
        "Action ",
      ],
      syncOptions: [
        { code: "sku", name: "SKU" },
        { code: "upc", name: "UPC" },
      ],
      mwsId: localStorage.getItem("mwsHeaderId"),
      includes: [
        { id: "Y", name: "Match/Mismatch" },
        { id: "N", name: "Only Mismatch" },
      ],
      column: [
        { title: "Fulfilment", align: "start", key: "name" },
        { title: "Category", align: "start", key: "email" },
        { title: "Product", align: "start", key: "mobile" },
        { title: "Min value    ", align: "start", key: "role" },
        { title: "Max value", key: "active", sortable: false },
        { title: "Formula", key: "actions", sortable: false },
      ],
      active: [
        { id: 1, name: "Active" },
        { id: 0, name: "Inactive" },
      ],
      inventoryPreferences: {
        erpInventoryLocations: null,
        syncProducts: null,
        fulfillmentSelected: [],
        inventoryAlerts: false,
        editIndex: 0,
        distribution_list: "",
        include: null,
        syncRules: null,
      },
      mwsHeader: {
        id: null,
        rule_type: "inventory_preferences",
        mws_header_id: localStorage.getItem("mwsHeaderId"),
      },
      inventorySyncRules: [
        {
          fulfillment_id: "",
          category_id: "",
          product_id: "",
          min_value: 0,
          max_value: 9999999,
          formula: "{{available}}",
          products: [],
        },
      ],
    };
  },
  created() {
    this.getFulFillments();
    this.getCategories();
    this.getProducts();
    this.getMws();
  },
  methods: {
    moment,
    getFulFilmentMany($ids) {
      if ($ids.length > 0) {
        let $fullfilmentMany = [];
        for (let $i = 0; $i < $ids.length; $i++) {
          var $fulfillment = lodash.filter(this.fulfillments, { id: $ids[$i] });
          if ($fulfillment && $fulfillment.length > 0) {
            $fullfilmentMany.push($fulfillment[0].name);
          }
        }
        return $fullfilmentMany.join();
      } else {
        return "Any";
      }
    },
    close() {
      this.showReadOnly = true;
      this.getMws();
    },
    deleterow(e,$index) {
      console.log(e,'evalue')
      this.dialog = false;
      this.confirmation = true;
      let categoryName = this.categories.filter(k => k.id == e.category_id)[0]
      this.confirmationContent = `Do you want to delete the Threshold ${categoryName ? categoryName.name:''} ?`;
      this.deleteIndex = $index;
    },
    async deleted() {
      this.confirmation = false;
      this.inventorySyncRules.splice(this.deleteIndex, 1)
      this.inventoryPreferences.syncRules = this.inventorySyncRules;
      let $obj = Object.assign({}, this.inventoryPreferences);
      this.mwsHeader.preferences = JSON.stringify($obj);
      if (this.mwsHeader && this.mwsHeader.id) {
        await MwsPreferenceService.update(this.mwsHeader);
      }
    },
    cancelled() {
      this.editDataId = null;
      this.confirmation = false;
    },
    getThresholdCategory($id) {
      if ($id) {
        let $category = lodash.filter(this.categories, { id: $id });
        return $category && $category.length > 0 ? $category[0].name : "Any";
      } else {
        return "Any";
      }
    },
    getThresholdProduct($id) {
      if ($id) {
        //alert($products && $products.length)
        let $product = lodash.filter(this.products, { id: $id });
        return $product && $product.length > 0 ? $product[0].sku : "Any";
      } else {
        return "Any";
      }
    },
    saveDialog($event) {
      this.entryModal = false;
      if (this.addAfter) {
        this.inventorySyncRules.splice(
          this.index + 1,
          0,
          Object.assign({}, $event)
        );
      } else if (this.addBefore) {
        this.inventorySyncRules.splice(
          this.index,
          0,
          Object.assign({}, $event)
        );
      } else {
        this.inventorySyncRules[this.index] = Object.assign({}, $event);
      }
      this.inventorySyncRules = [...this.inventorySyncRules];
      console.log($event);
    },
    closeDialog() {
      this.entryModal = false;
    },
    editThreshold(index, $value,type) {
      if(type == 'view'){
      this.entryModal = true;
      this.addBefore = false;
      this.addAfter = false;
      this.index = index;
      this.editData = $value;
      this.type = "view";
      this.showReadOnly = true
      }if(type == 'edit'){
        this.entryModal = true;
      this.addBefore = false;
      this.addAfter = false;
      this.index = index;
      this.editData = $value;
      this.type = "edit";
      this.showReadOnly = false
      }if(type == 'select' && $value.min_value != 0){
        this.entryModal = true;
      this.addBefore = false;
      this.addAfter = false;
      this.index = index;
      this.editData = $value;
      this.type = "select";
      this.showReadOnly = true
      }
    },
    async getFulFillments() {
      let $res = await FulfillmentService.findAll({});
      if ($res && $res.length > 0) {
        $res.unshift({ id: "*", code: "All", name: "Any" });
        this.fulfillments = $res;
      }
      else{
      this.fulFillments=[];
    } 
    },
    async getCategories() {
      let $res = await CategoryService.findAll({});
      if ($res && $res.length > 0) {
        $res.unshift({ id: "*", code: "All", name: "Any" });
        this.categories = $res;
      }
      else{
      this.categories=[];
    }
    },
    async getProducts() {
      let $res = await ProductService.findAll({});
      if ($res && $res.length > 0) {
        $res.unshift({ id: "*", code: "All", name: "Any" });
        this.products = $res; 
      }
      else{
      this.products=[];
    }
    },
    async createUpdateMws() {
      this.inventoryPreferences.syncRules = this.inventorySyncRules;
      let $obj = Object.assign({}, this.inventoryPreferences);
      this.mwsHeader.preferences = JSON.stringify($obj);
      if (this.mwsHeader && this.mwsHeader.id) {
        await MwsPreferenceService.update(this.mwsHeader);
        this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
        this.showReadOnly = true;
      } else {
        try {
          await MwsPreferenceService.create(this.mwsHeader);
          this.$store.dispatch("setSnackbar", { text: "Saved Successfully" });
          this.showReadOnly = true;
        } catch (e) {
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: "Something Went Wrong",
          });
        }
      }
    },
    async getMws() {
      let $result = await MwsPreferenceService.findAll({
        mws_header_id: this.mwsId,
        rule_type: "inventory_preferences",
      });
      if ($result && $result.length > 0) {
        $result = $result[0];
        let $obj = {};
        $obj.id = $result.id;
        $obj.preferences =
          $result.preferences && typeof $result.preferences == "string"
            ? JSON.parse($result.preferences)
            : {};
        $obj.rule_type = "inventory_preferences";
        this.mwsHeader = $obj;
        this.inventoryPreferences = this.mwsHeader.preferences;
        this.inventorySyncRules = this.mwsHeader.preferences
          ? this.mwsHeader.preferences.syncRules
          : this.inventorySyncRules;
      }
    },
    edit() {
      this.showReadOnly = false;
    },
    openDialog($value){
        if($value.title == 'Add Before'){
            this.entryModal = true;
            this.addBefore = true;
            this.index = $value;
            this.addAfter = false;
            this.type = 'add'
            this.showReadOnly = false
        }else{
            this.entryModal = true;
            this.addAfter = true;
            this.addBefore = false;
            this.addBefore = false;
            this.index = $value;
            this.type = 'add'
            this.showReadOnly = false
        }
    }
  },
};
</script>