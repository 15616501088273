<template>
    <span class="text-xs-center">
        <v-btn :icon="DoNotShowText" flat small @click="uploadDocument" style="zoom:90%" outlined class="blue--text">
            <v-icon :class="color ? color : 'blue--text'" :small="size == 'small'" :large="size == 'large'"
                :medium="size == 'medium'">
                {{ icon ? icon : 'fas fa-plus-circle' }}
            </v-icon> &nbsp; &nbsp;
            <span v-if="!DoNotShowText">&nbsp;Add Files</span>
        </v-btn>
        <input v-show="false" ref="inputUpload" type="file" @input="fileUpload">
    </span>
</template>
<script>
import DocumentModel from '@/services/admin/DocumentService';
export default {
    props: [
        'dataType',
        'data',
        'type',
        'key_id',
        "DoNotShowText",
        "icon",
        "size",
        "color"
    ],
    data() {
        return {
            file: {
                "imageName": "",
                "imageUrl": "",
                "imageFile": ""
            },
            keyId: null,
            moduleType: this.dataType
        }
    },
    watch: {
        dataType() {
            this.moduleType = this.dataType
        },
        key_id() {
            this.keyId = this.key_id
        }
    },
    mounted: function () {
        this.moduleType = this.dataType
    },
    methods: {
        uploadDocument() {
            this.$emit('uploadStarted')
            this.$refs.inputUpload.click()
        },
        setData($data) {
            this.$emit('uploadCompleted', $data)
            this.$refs.inputUpload.value = null
            //this.data = $data 
        },
        fileUpload($event) {
            const files = $event.target.files
            this.$emit('uploadStart')
            if (files[0] !== undefined) {
                this.file.imageName = files[0].name
                if (this.file.imageName.lastIndexOf('.') <= 0) {
                    return
                }
                const fr = new FileReader()
                var $dataType = this.dataType
                var $file = this.file
                var $type = this.type
                var $keyId = this.key_id
                if (!$keyId) { $keyId = 100 }
                if (this.type != 'UP') {
                    $type = $dataType
                } else {
                    $type = 'UP' + $dataType
                }
                fr.readAsDataURL(files[0])
                fr.addEventListener('load', () => {
                    $file.imageUrl = fr.result
                    $file.imageFile = files[0] // this is an image file that can be sent to server...
                    var $json = {
                        "key_type": $type,
                        "key_id": $keyId,
                        "doc_type_id": 1,
                        "name": $file.imageName,
                        "doc_name": $file.imageName.length > 200 ? $file.imageName.subStr(0, 200) : $file.imageName,
                        "file_name": $file.imageName,
                        "file_type": $file.imageFile.type,
                        "active": 1,
                        "data": $file.imageUrl
                        //"company_id":JSON.parse(localStorage.getItem('tiburaXtend')).data.User.company_id
                    }
                    if ($dataType === '##') {
                        this.setData($file.imageUrl)

                    } else {
                        DocumentModel.upload($json)
                            .then(response => {
                                this.setData(response.data.data)
                            }).catch(error => {
                                console.log(error)
                                //reject(error)
                            });
                    }
                })
            } else {
                this.file.imageName = ''
                this.file.imageFile = ''
                this.file.imageUrl = ''
            }
        }
    }
}
</script>