<template>
  <v-card>
    <v-form @Submit.prevent="save" ref="form">
      <v-card-title class="dialog-Title"
        >{{
          type == "edit"
            ? "Edit"
            : type == "view"
            ? "View"
            : type == "add"
            ? "Add"
            : type == "select"
            ? "View"
            : ""
        }}
        User Role 
        <v-btn
          density="comfortable"
          @click="closeDialog"
          elevation="0"
          icon="mdi-close"
          style="float: right"
        ></v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" class="pa-2">
              <AutoComplete
                :data="formData.role_id"
                @textData="checkValue($event)"
                :label="'Role'"
                :disabled="showReadOnly"
                :items="roleData"
                :value="'id'"
                :text="'name'"
                :rules="roleRule"
              />
            </v-col>
            <span class="error" v-if="existsMessage">Role already exists</span>
            <!-- <v-divider></v-divider> -->
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="dialogfooter" v-if="!showReadOnly">
        <v-spacer></v-spacer>
        <v-btn class="mr-1 cancel" @click="closeDialog">Cancel</v-btn>
        <v-btn class="save" type="submit">Save</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
import RoleService from "@/services/admin/RoleService";
import UserRoleSecurityService from "@/services/admin/UserRoleSecurityService.js";
export default {
  props: ["type", "id","userId"],
  components: {},
  data() {
    return {
      existsMessage: false,
      roleRule:[(value) => !!value || "Role is Required"],
      formData: {
        role_id: null,
        user_id: null,
      },
      showReadOnly: false,
      roleData: [],
      showRow: false,
      rows: [
        {
          fieldName: null,
          operand: null,
          value: null,
        },
      ],
    };
  },
  created() {
    if (this.userId){
      this.formData.user_id = this.userId;
    }
    if (this.id) {
      this.getEditData();
    }
    if (this.type == "view" || this.type == "select") {
      this.showReadOnly = true;
    }
    this.getRole();
  },
  methods: {
    async checkValue(e){
      let res = await UserRoleSecurityService.findAll({role_id : e,user_id : this.userId})
      console.log("1111111",res)
      if (res.length > 0){
        this.existsMessage = true
      }else{
        this.existsMessage = false
        this.formData.role_id = e;
      }
    },
    async getEditData() {
      let $res = await UserRoleSecurityService.findAll({ id: this.id });
      this.formData = $res[0];
      console.log(this.formData, "formData");
    },
    async getRole() {
      let $res = await RoleService.findAll();
      this.roleData = $res&&$res.length>0 ? $res : [];
      console.log(this.formData, "formData");
    },

    closeDialog() {
      this.$emit("closeDialog");
    },
    async save() {
      delete this.formData.roles
      delete this.formData.users
      console.log(this.formData, "data");
      let validation = await this.$refs.form.validate();
      if (!validation.valid || this.existsMessage) {
        console.log(validation);
      } else {
        if (this.formData && this.formData.id) {
          let output = await UserRoleSecurityService.update(this.formData);
          this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
          console.log(output);
          this.closeDialog();
        } else {
          let output = await UserRoleSecurityService.create(this.formData);
          this.$store.dispatch("setSnackbar", { text: "Saved Successfully" });
          console.log(output, "output", this.formData);
          this.closeDialog();
        }
      }
    },
  },
};
</script>
<style>
.error{
  color: #b00020;
  padding-left: 10px
}
</style>
