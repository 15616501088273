<template>
    <v-card elevation="0">
        <v-card-title class="dialog-Title">
            GROUP BY
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
                <v-col cols="12">
                    <AutoComplete label="Columns" :items="columnData" :value="'title'" :data="selectedItem" @textData="addswitches($event)" :disabled="showReadOnly"></AutoComplete>
                </v-col> 
            <v-row v-for="(row, index) in rows" :key="index" class="row">
                <v-col cols="6" class="pt-6">
                  <span ><i class="pi pi-times pr-3 pl-2" style="font-size: 10px;cursor: pointer;" @click="removeItem(index)"></i></span><span>{{ row.title }}</span>
                    
                </v-col>
                <v-col cols="6" align="end" class="">
                    <v-switch label="Asc" v-model="row.switchValue" color="primary" :disabled="showReadOnly"></v-switch>
                </v-col>
            </v-row> 
        </v-card-text>
    </v-card>
</template>
<script>
export default {
  data() {
    return {
      switchValue: false,
      columnData: [
        { title: 'Style', id: 1 },
        { title: 'Name', id: 2 },
        { title: 'Description', id: 3 },
        { title: 'Image', id: 4 },
        { title: 'Item Num', id: 5 },
        { title: 'UPC', id: 6 },
      ],
      rows: [],
      selectedItem: [],
      showReadOnly: false, 
    };
  },
  created(){
    if (this.$route.query.type === 'view'){
      this.showReadOnly = true
    }
  },
  methods: {
    addswitches($event) {
      this.selectedItem = $event
      this.rows.push({title:this.selectedItem,switchValue:true})
      console.log("Selected Item:",this.rows);
    },
    removeItem(index) {
       if (!this.showReadOnly){
        this.rows.splice(index, 1);
        this.selectedItem = []
       }
    },
  },
};
</script>
<style scoped>
.row{
  padding-left: 16px;
  margin-left: 5px;
  height: 55px;
}
</style>

