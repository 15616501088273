<template>
  <PrimeTable class='pa-2'    
        :tableName="'Product In Store'"
        :showTableHeader="true"
        :headers="column"
        :tableData="productInStore"
        @addDialog="addDialog"
      ></PrimeTable>


  <!-- <div class="pa-4 total">
    <v-row>
      <v-col cols="10" class="text-h6 font-weight-bold"
        >Products In Store</v-col
      >
      <v-col cols="2" @click="addProduct()" class="d-flex justify-end"
        ><v-btn color="primary">New Product</v-btn></v-col
      >
    </v-row>
    <v-toolbar border density="comfortable" class="rounded-lg mt-4">
      <v-toolbar-title>
        <v-btn
          variant="outlined"
          @click="active"
          :class="{ productListButton: actives }"
          >Active</v-btn
        >
        <v-btn
          class="ml-2"
          variant="outlined"
          @click="draft"
          :class="{ productListButton: drafts }"
          >Draft</v-btn
        >
        <v-btn
          class="ml-2"
          variant="outlined"
          @click="archive"
          :class="{ productListButton: archives }"
          >Archive</v-btn
        >
        <v-menu>
          <template v-slot:activator="{ props }">
            <v-btn
              class="ml-2"
              v-bind="props"
              variant="outlined"
              append-icon="mdi-chevron-down"
            >
              Saved View
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in items"
              :key="index"
              :value="index"
            >
              <v-list-item-title
                >{{ item.title }}
                <template v-slot:append>
                  <v-btn
                    color="grey-lighten-1"
                    icon="mdi-tune"
                    variant="text"
                  ></v-btn>
                </template>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-title>

      <v-menu>
        <template v-slot:activator="{ props }">
          <v-btn
            v-bind="props"
            variant="outlined"
            prepend-icon="mdi-text-box-search-outline"
            append-icon="mdi-chevron-down"
          >
            Search & Filter
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in items"
            :key="index"
            :value="index"
          >
            <v-list-item-title
              >{{ item.title }}
              <template v-slot:append>
                <v-btn
                  color="grey-lighten-1"
                  icon="mdi-tune"
                  variant="text"
                ></v-btn>
              </template>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu>
        <template v-slot:activator="{ props }">
          <v-btn
            class="ml-2"
            v-bind="props"
            variant="outlined"
            prepend-icon="mdi-sort"
            append-icon="mdi-chevron-down"
          >
            Sort By
          </v-btn>
        </template>
        <v-list variant="outlined" density="compact">
          <v-list-item
            draggable="true"
            v-for="(item, i) in sort"
            :key="i"
            :value="item"
            color="primary"
          >
            <template v-slot:append>
              <v-icon :icon="item.icon"></v-icon>
            </template>

            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu>
        <template v-slot:activator="{ props }">
          <v-btn
            class="ml-2"
            v-bind="props"
            variant="outlined"
            prepend-icon="mdi-arrow-collapse-down"
            append-icon="mdi-chevron-down"
          >
            Export
          </v-btn>
        </template>
        <v-list class="pt-0">
          <v-list-item
            v-for="(item, index) in exportData"
            :key="index"
            :value="index"
          >
            <template v-slot:prepend>
              <v-icon :icon="item.icon"></v-icon>
            </template>
            <v-list-item-title @click="download(item.value)"
              >{{ item.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>

    <v-table class="mt-3 rounded-lg">
      <thead>
        <tr class="">
          <th class="text-left leftBoarder"></th>
          <th class="text-center font-weight-bold text-subtitle-2">
            Description
          </th>
          <th class="text-left font-weight-bold text-subtitle-2">
            Identifiers
          </th>
          <th class="text-left font-weight-bold text-subtitle-2">Stores</th>
          <th class="text-left font-weight-bold text-subtitle-2">Inventory</th>
          <th class="text-left font-weight-bold text-subtitle-2">
            Last Sold Date
          </th>
          <th class="text-left font-weight-bold text-subtitle-2">Stage</th>
          <th class="text-left font-weight-bold text-subtitle-2 rightBoarder">
            Modified
          </th> -->
          <!------------------- <th class="text-left font-weight-bold text-subtitle-2 rightBoarder">
            Action
          </th> -------------------->
        <!-- </tr>
      </thead>
      <tbody>
        <tr v-for="item in productInStore" :key="item.name" class="borders">
          <td width="4%" class="leftBoarder">
            <v-checkbox color="#3C5AAA" class="mt-4 rounded-xl"></v-checkbox>
          </td>
          <td style="font-weight: 500" width="28%" @click="view(item)">
            <v-row no-gutters dense>
              <v-col cols="3" class="mt-3">
                <v-img :src="item.style_images" width="50" height="50" />
              </v-col>
              <v-col cols="9">
                <p v-html="item.style_description"></p>
              </v-col>
            </v-row>
          </td>

          <td width="10%">
            {{ item.mws_variant_id }}
          </td>
          <td width="10%">
            <v-chip
              label
              size="small"
              variant="outlined"
              class="Store rounded-lg"
              v-if="item.store"
              >{{ item.store }}</v-chip
            >
          </td>
          <td style="font-weight: 400" width="10%">{{ item.inventory_id }}</td>
          <td width="10%">
            <div style="color: #f3a54a" class="font-weight-bold">
              {{
                item.last_sold_date
                  ? moment(item.last_sold_date).format("MM/DD/YYYY")
                  : ""
              }}
            </div>
            <div
              style="font-size: 12px; padding-left: 8px"
              class="font-weight-medium"
            >
              {{
                item.last_sold_date ? moment(item.last_sold_date).fromNow() : ""
              }}
            </div>
          </td>
          <td width="10%">
            <v-chip
              label
              size="small"
              variant="outlined"
              class="Stage rounded-lg"
              v-if="item.stage"
              >{{ item.stage }}</v-chip
            >
          </td>
          <td width="10%" class="font-weight-medium rightBoarder">
            {{
              item.mws_modified
                ? moment(item.mws_modified).format("MM/DD/YYYY")
                : ""
            }}
          </td> -->
          <!--------<td width="8%" class="rightBoarder">
            <a class="ex2"
              ><v-icon @click="view(item)" icon="mdi-eye-outline"></v-icon>
            </a>
            <a class="ex2"
              ><v-icon
                @click.stop="edit(item)"
                icon="mdi-pencil-outline"
                class="pl-4"
              ></v-icon
            ></a>
          </td> -------->
        <!-- </tr>
      </tbody>
    </v-table>
  </div> -->
</template>
  
  <script>
import DsProductService from "@/services/catalogs/DsProductService";
import moment from "moment";
import PrimeTable from '../../components/PrimeTable.vue';
export default {
  components: {
    PrimeTable
  },
  data() {
    return {
      productInStore: [],
      localproductInStore: [],
      actives: true,
      drafts: false,
      archives: false,
      search: "",
      column: [
        { title: "", align: "start", key: "style_images" },
        { title: "Description", align: "start", key: "style_description" },
        { title: "Identifiers", align: "start", key: "mws_variant_id" },
        { title: "Stores", align: "start", key: "store" },
        { title: "Inventory", align: "start", key: "inventory_id" },
        { title: "Last Sold Date ", align: "start", key: "last_sold_date" },
        { title: "Stage", align: "start", key: "stage" },
        { title: "Modified ", align: "start", key: "mws_modified" },
       
      ],
      // sort: [
      //   { title: "Sort Options", icon: "mdi-tune-variant" },
      //   { title: "Created", icon: "mdi-tune-variant" },
      //   { title: "Modified", icon: "mdi-tune-variant" },
      //   { title: "SKU", icon: "mdi-tune-variant" },
      //   { title: "Style", icon: "mdi-tune-variant" },
      // ],
    };
  },
  created() {
    this.getProductInStore();
  },
  methods: {
    moment,
    async getProductInStore() {
      let res = await DsProductService.getByStore();
      this.productInStore = res;
      console.log(this.productInStore, "vill");
      this.localproductInStore = JSON.parse(JSON.stringify(res));
    },
    edit(item) {
      console.log(item);
    },
    // titleCaseText(str) {
    //   return str
    //     .toLowerCase()
    //     .split(" ")
    //     .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    //     .join(" ");
    // },
    // searchData(value) {
    //   this.productData = this.localProductData.filter(
    //     (f) =>
    //       (f.description &&
    //         f.description.toLowerCase().includes(value.toLowerCase())) ||
    //       (f.sku && f.sku.toLowerCase().includes(value.toLowerCase())) ||
    //       (f.varaints &&
    //         f.varaints
    //           .toString()
    //           .toLowerCase()
    //           .includes(value.toLowerCase())) ||
    //       (f.stores &&
    //         f.stores.toString().toLowerCase().includes(value.toLowerCase())) ||
    //       (f.last_sold_date &&
    //         f.last_sold_date.toLowerCase().includes(value.toLowerCase())) ||
    //       (f.stage && f.stage.toLowerCase().includes(value.toLowerCase()))
    //   );
    // },
    async getFilterProducts(data) {
      let res = await DsProductService.getFilter(data);
      this.productInStore = res;
      this.localproductInStore = JSON.parse(JSON.stringify(res));
      console.log(res);
      //
    },
    // view(item) {
    //   this.$router.push("/productview?id=" + item.id);
    // },
    active() {
      this.archives = false;
      (this.drafts = false), (this.actives = true);
      let data = {};
      this.getFilterProducts(data);
    },
    draft() {
      this.archives = false;
      (this.drafts = true), (this.actives = false);
      let data = {
        draftStage: "draft",
      };
      this.getFilterProducts(data);
    },
    archive() {
      this.archives = true;
      (this.drafts = false), (this.actives = false);
      let data = {
        archiveStage: "archive",
      };
      this.getFilterProducts(data);
    },
  },
};
</script>
  
