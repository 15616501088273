<template>
    <v-card elevation="0">
        <v-card-title class="dialog-Title">
            FILTERS
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-6">
          <v-row v-for="item in data" :key="item.id">
                <v-col cols="11" v-if="item.type == 'select'" class="pa-0 pl-2">
                  <AutoComplete :label="item.column_name" :disabled="showReadOnly"
                  :text="'value'" :value="'value'" :items="dropDownData" @textData="saveAutoInput(item.column_name,$event)" :data="item.default">
                </AutoComplete>              
                </v-col> 
                <v-col cols="11" v-if="item.type == 'text'" class="pa-0 pl-2">
                  <InputBox :label="item.column_name" @textData="saveInput(item.column_name,$event)" :data="item.default" :disabled="showReadOnly"></InputBox>
                </v-col>
          </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
import AutoComplete from '@/components/AutoComplete.vue';
import InputBox from '@/components/InputBox.vue';
import ReportSubscriptionService from './services/ReportSubscriptionService';
export default {
  props:['filterData'],
  components:{
    AutoComplete,
    InputBox,
  },
  data() {
    return {
      data: [],
      dropdownItems: [],
      selectedItems: [],
      dropDownData: [{id:1,value:"Default Value"}],
      showReadOnly: false
    };
  },
  created(){
    if (this.$route.query.type == 'view'){
          this.showReadOnly =true
       }
    if(this.$route.query.id){
        this.getbyId();
      }else{
        this.filterData.map((obj)=>{
        this.data.push({column_name: obj.column_name, default: null,type: obj.type})
      })
      }
  },
  watch:{
     filterData(){
      if(this.$route.query.id){
        this.getbyId();
      }else{
        this.data = [];
        this.filterData.map((obj)=>{
        return this.data.push({column_name: obj.column_name, default: null,type: obj.type})
      })
      }
     }
  },
  methods: {
    async getbyId(){
      console.log("columnsDatataa",this.filterData)
      let res = await ReportSubscriptionService.findAll({id: this.$route.query.id})
      this.data = JSON.parse(res[0].columns)
    },
    saveInput(name,$event){
     this.data.find(v => v.column_name === name).default = $event;
     this.$emit("filterValues",this.data)
    },
    saveAutoInput(name,$event){
     this.data.find(v => v.column_name === name).default = $event;
     this.$emit("filterValues",this.data)
    },
    removeItem(index) {
      this.selectedItems.splice(index, 1);
    },
  },
};
</script>