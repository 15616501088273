<template>
    <v-card flat min-width="600px" class="ma-2">
        <v-card-item>
            <v-card-title>
                Activities
            </v-card-title>
        </v-card-item>
        <v-card-text>
            <v-row  dense>
                <v-col cols="4">
                    <v-row dense>
                        <v-col cols="12">
                            <v-label>Module</v-label>
                        </v-col>
                      
                        <v-col cols="10">
                            <AutoComplete :items="modules" style="width:100%"/>
                        </v-col>
                        <v-col cols="2" class="text-center pa-3" >
                            <Button :size="'25'" :variant="'outlined'" :boxIcon="'mdi-plus'"  :color="'primary'" />
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="4">
                    <v-row dense>
                        <v-col cols="12">
                            <v-label>Group</v-label>
                        </v-col>
                      
                        <v-col cols="10">
                            <AutoComplete :items="groups" style="width:100%"/>
                        </v-col>
                        <v-col cols="2" class="text-center pa-3" >
                            <Button :size="'25'" :variant="'outlined'" :boxIcon="'mdi-plus'"  :color="'primary'" />
                        </v-col>
                    </v-row>
                </v-col>
               
            </v-row>
            <v-row dense>
                <v-col cols="6">
                    <MenuComponent :items="activities" />
                </v-col>
                <v-col cols="6">

                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
// import { AutoComplete } from 'frontendcomponent'
// import {Button } from 'frontendcomponent'
// import { MenuComponent } from 'frontendcomponent'
import ModuleService from '@/services/admin/ModuleService.js'
import ActivityGroupService from '@/services/admin/ActivityGroupService.js'
import ActivityService from '@/services/admin/ActivityService.js'
export default {
    props:[],
    components:{
        // AutoComplete,
        // MenuComponent,
        // Button
    },
    data() {
        return {
            activity:{

            },
            modules:[],
            activityGroups:[],
            activities:[]
        }
    },
    created() {
        this.getModules()
    },
    mounted() {
        
    },
    updated() {

    },
    computed:{

    },
    watch:{

    },
    methods:{
        async getActivityGroups() {
            this.groups = await ActivityGroupService.findAll({"moduleId":this.activity.moduleId})
        },
        async getModules() {
            this.modules = await ModuleService.findAll()
        },
        async getActivity() {
            this.activities = await ActivityService.findAll({"parentId":this.activity.parentId})
        }
    }
}
</script>
<style scoped>

</style>