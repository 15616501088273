<template>
  <v-label class="commonLabel ml-1" v-if="label">{{ label }}
    <span style="color: rgb(207, 7, 7);padding-left: 2px;font-weight: 700;" v-if="rules">*</span>
  </v-label>
  <!-- {{ placeholder }} -->
  <v-autocomplete class="text-Field" density="compact" v-model="model" @update:model-value="changeText()" :rules="rules"
    :disabled="disabled" :items="items" :placeholder="model ? '':label"
 variant="outlined" :item-title="text" @focus="FocusEvent()"
    :multiple="multiple" :chips="chips" :closable-chips="closeChip" :item-value="value">
  </v-autocomplete>
</template>

<script>
export default {
  props: ["items", "rules", "label", "text", "data", "value", "disabled", 'placeholder', 'multiple', 'chips', 'closeChip'],
  data() {
    return {
      model: null,
      labelHide: false,
      labelShow: null,
    };
  },
  created() {
    this.labelHide = false;
    this.model = this.data;
    this.labelShow = this.placeholder ? this.placeholder : this.label;
  },
  watch: {
    data() {
      this.model = this.data;
      if(this.model == ''){
        this.labelHide = false;        
      }else{
        this.labelHide = true
      }

    }
  },
  methods: {
    changeText() {
      this.labelHide = true
      this.$emit('textData', this.model)
    },
    FocusEvent(){
      this.$emit('focus')
    }
  }
};
</script>

