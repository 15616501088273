<template>
  <v-card flat class="rounded-lg" style="border: 2px solid; border-color: #cfcdcd" height="342px">
    <v-card-title> Details </v-card-title>
    <v-card-text>
      <v-form ref="form">
        <v-row class="pt-4">
          <v-col cols="6" class="pa-0 pl-2">
            <InputBox :label="'Style'"
                :data="formData.code"
                @textData="checkCode($event)"
                :rules="styleRule" />
          </v-col>
          <v-col cols="6" class="pa-0 px-2">
            <InputBox :label="'Name'" 
                :data="formData.name"
                @textData="checkName($event)"
              :rules="nameRule" />
          </v-col>
          <v-col class="pa-0 px-2">  
             <QuilEditor
            :data="formData.description"
            :showReadOnly="showReadOnly"
            :editorStyle="'height:130px;overflow: auto'"
            @textData="productDetails($event)" 
          ></QuilEditor></v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import InputBox from "@/components/InputBox.vue";
import QuilEditor from "@/components/QuilEditor.vue";
export default {
  props: ['validation','productData'],
  components: {
    InputBox,
    QuilEditor
  },
  data() {
    return {
      nameRule: [(value) => !!value || "Name is Required"],
      styleRule: [(value) => !!value || "Style is Required"],
      descriptionRule: [(value) => !!value || "Description is Required"],
      formData: {
        code: null,
        name: null,
        description: null,
      },
    };
  },
  watch: {
    validation() {
      this.save();
    },
    productData() {
      this.formData = this.productData
    }
  },
  created() {
    if (this.productData) {
      this.formData = this.productData
    }
  },
  methods: {
    async save() {
      let validation = await this.$refs.form.validate();
      if (!validation.valid) {
        console.log(validation);
      }
      else {
        var isvalid = true
        this.$emit("validCheck", isvalid)
      }
    },
    productDetails(value) {
      this.formData.description = value
      this.$emit("productDetails", this.formData)
    },
    checkCode(value){
      this.formData.code = value
      this.$emit("productDetails", this.formData)
    },
    checkName(value){
      this.formData.name = value
      this.$emit("productDetails", this.formData)
    }
  },
};
</script>

<style></style>