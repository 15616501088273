<template>
  <v-col class="font-weight-black mb-2 pl-2 pb-0"
    >Products in Watch
    <v-icon class="pb-1" color="primary" @click="openDialog()"
      >mdi-plus-circle</v-icon
    ></v-col>
    <v-card flat v-if="ProductDashBoardData.length == 0" height="204">
      <v-img src="https://xtendv3-snaps.s3.ap-south-1.amazonaws.com/public/icons/NotFound.svg"></v-img>
    </v-card>
  <v-row v-if="ProductDashBoardData">
    <v-slide-group class="w-100" show-arrows>
      <v-slide-group-item>
        <v-col
          cols="12"
          md="3" sm="5" lg="2" xl="2" xs="12"
          align="center"
          v-for="item in ProductDashBoardData"
          :key="item"
        >
          <v-hover v-slot="{ isHovering, props }" open-delay="100">
            <v-card
              elevation="0"
              class="Card rounded-lg"
              :color="isHovering ? 'hoverbg' : undefined"
              :class="{ 'on-hover': isHovering }"
              v-bind="props"
            >
            <div class="img-show-content">
              <v-card-item align="end" class="product-close-icon pa-0"
                ><img
                  @click="productDelete(item)"  
                  style="cursor: pointer;"                     
                  class="pt-1 pr-1"
                  src="https://s3.ap-south-1.amazonaws.com/xtend3.0/icons/xtend/off_outline_close.svg"
                  alt="close"
                  width="22"
              /></v-card-item>
              <v-img :width="110" height="93" :src="item.images"></v-img>
            </div>
              <v-card-title class="subTitle">{{ item.sku }}</v-card-title>
              <v-card-text class="subTitle pa-1"
                >{{ item.sale_price }} $</v-card-text
              >
              <v-chip class="mb-2" color="primary">
                {{ item.stores }} In Stock
              </v-chip>
            </v-card>
          </v-hover>
        </v-col>
      </v-slide-group-item>
    </v-slide-group>
  </v-row>
  <v-dialog v-model="dialog" width="500px" persistent>
    <AddProduct @closeDialog="closeDialog" :type="type" @close="close" />
  </v-dialog>
  <v-dialog v-model="confirmation" width="400px" persistent>
    <DialogConfirmation
      @cancelled="cancelled()"
      @deleted="deleted"
      :content="confirmationContent"
    />
  </v-dialog>
</template>

<script>
import AddProduct from "../components/AddProduct.vue";
import DialogConfirmation from "@/components/DialogConfirmation.vue";
import ProductsWatchService from "@/services/catalogs/ProductsWatchService";
import DsProductsDashboardService from "@/services/catalogs/DsProductsDashboardService";
export default {
  components: {
    AddProduct,
    DialogConfirmation,
  },
  data() {
    return {
      ProductDashBoardData: [],
      productData: [],
      dialog: false,
      confirmation: false,
      deleteId:null,
    };
  },
  methods: {
    async productDelete($value) {
     this.confirmation = true
     this.confirmationContent = `Do you want to delete the Product ${$value.sku}`
     this.deleteId = $value.id
    },
    async getDsProductDashboard() {
      let res = await DsProductsDashboardService.findAll({ active: 1 });
      this.ProductDashBoardData = res;
    },

    getImg($data) {
      let $imges = $data.images ? JSON.parse($data.images) : [];
      if ($imges.length > 0) {
        return $imges[0].src;
      }
    },
    async deleted() {
      this.confirmation = false;
      await ProductsWatchService.update({ id: this.deleteId, active: 0 });
      this.getDsProductDashboard();
    },
    cancelled() {
      this.editDataId = null;
      this.confirmation = false;
    },
    openDialog() {
      this.type = "add";
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.getDsProductDashboard();
    },
  },
  created: function () {
    this.getDsProductDashboard();
  },
};
</script>
<style lang="scss">
.Card{
.img-show-content{
  background: #f4f5f9;
  margin: 8px;
  border-radius: 5px 35px 5px 5px;
  padding: 16px;
.product-close-icon{
    position: absolute;
    right: -2px;
    top: -2px;
}
}
}
</style>