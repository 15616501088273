<template>
  <v-card>
    <v-form @submit.prevent="save" ref="form">
      <v-card-title class="dialog-Title"
        >{{
          type == "edit"
            ? "Edit"
            : type == "view"
            ? "View"
            : type == "add"
            ? "Add"
            : type == "select"
            ? "View"
            : ""
        }}
        Group By
        <v-btn
          density="comfortable"
          @click="closeDialog"
          elevation="0"
          icon="mdi-close"
          style="float: right"
        ></v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-container>
          <!-- <template v-for="layout in draggableData" :key="layout">
            <v-col
              :draggable="true"
              @drop="onDrop($event, layout)"
              @dragenter.prevent
              @dragover.prevent
            >
            {{ layout.type=='column' ? 'Columns' : layout.type=='total' ? 'Total' : ''  }}
            <v-card class="layoutCard">
                <span
                v-for="item in layout.columns"
                :key="item"
                :draggable="true"
                @dragstart="dragStart($event, item,layout.type)"
                :cols="12"
              >
              
                <v-chip
                color="primary"
                :key="item"
                label
                class="ma-2"
                >
                {{ item.column_name }}
                </v-chip>
              </span>
            </v-card>
             
            </v-col>
          </template> -->
          <v-row class="pa-3">
            <v-col cols="4">
              <v-row>
                <v-col class="table-title" :cols="4"> Field </v-col>
                <v-col class="table-title" :cols="4"> Column </v-col>
                <v-col class="table-title" :cols="4"> Total </v-col>
              </v-row>
              <v-row no-gutters class="ma-0 pa-0" dense v-for="item in displayColumns" :key="item">
                  <v-col cols="4" class="pt-4">
                    {{ item.column_name }}
                  </v-col>
                  <v-col cols="4">
                    <v-checkbox class="groupbycheckbox" color="primary" @change="setColumns(item)" v-model="item.isColumn"></v-checkbox>
                  </v-col>
                  <v-col cols="4" v-if="item.isShowTotal">
                    <v-checkbox class="groupbycheckbox" color="primary"  @change="setTotals(item)" v-model="item.isTotal"></v-checkbox>
                  </v-col>
                  <!-- <v-divider /> -->
                <!-- {{ item.column_name }} <v-checkbox v-model="item.isColumn"></v-checkbox>
                <v-checkbox v-model="item.isTotal"></v-checkbox> -->
              </v-row>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="4">
              <div class="sub-title">Column</div>
                <v-chip
                  color="primary"
                  v-for="layout in groupBy.columns"
                  :key="layout"
                  label
                  class="ma-2"
                >
                  {{ layout.column_name }}
                </v-chip>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="4"> 
              <div class="sub-title">Total</div>
                <v-chip
                  color="primary"
                  v-for="layout in groupBy.total"
                  :key="layout"
                  label
                  class="ma-2"
                >
                  {{ layout.column_name }}
                </v-chip> 
                 </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="dialogfooter">
        <v-spacer></v-spacer>
        <v-btn class="mr-1 cancel" @click="closeDialog">Cancel</v-btn>
        <v-btn class="save" type="submit">Save</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
// import draggable from "vuedraggable";
export default {
  props: ["editData", "type", "columns"],
  //   components: {
  //     draggable,
  //   },
  data() {
    return {
      drag: false,
      groupBy: {
        columns: [],
        total: [],
      },
      displayColumns: [],
      draggedElement: null,
      draggableData: [
        { columns: [], type: "base" },
        { columns: [], type: "column" },
        { columns: [], type: "total" },
      ],
      fromType: null,
    };
  },
  created() {
    if (this.editData) {
      this.groupBy = this.editData;
    }
    this.displayColumns = this.columns;
    this.displayColumns.forEach((e) => {
        let $index = this.groupBy.columns.findIndex(k=>k.id==e.id);
        let $index1 = this.groupBy.total.findIndex(k=>k.id==e.id);
      e.isColumn = e.isColumn ? e.isColumn : $index>-1 ? true:false;
      e.isTotal = e.isTotal ? e.isTotal : $index1>-1 ? true:false;
      e.isShowTotal = e.data_type=='float';
    });
  },
  methods: {
    closeDialog() {
      this.$emit("closed");
    },
    save() {
      console.log(this.groupBy, "groupByss");
      this.$emit("saved", this.groupBy);
    },
    setColumns($item){
        
        let $index = this.groupBy.columns.findIndex(
        (k) => k.id == $item.id
      );
        if($item.isColumn){
            this.groupBy.columns.push($item);
        }else{
            this.groupBy.columns.splice($index,1);
        }
    },
    setTotals($item){
        let $index = this.groupBy.total.findIndex(
        (k) => k.id == $item.id
      );
        if($item.isTotal){
            this.groupBy.total.push($item);
        }else{
            this.groupBy.total.splice($index,1);
        }
    }
  },
};
</script>
<style lang="scss">
.groupbycheckbox{
  height: 35px;
}
</style>