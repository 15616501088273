<template>
  <div v-if="accessPrivilege.indexOf('list') < 0 && !loading" class="noaccesscard">
    <span style="" class="text-h5 noaccesscontent">{{ this.checkingPermission }}</span>
  </div>
  <div style="padding:5px" v-if="accessPrivilege.indexOf('list') >= 0">
  <v-data-table :items="displayData" v-model:page="page" :search="search" :items-per-page="this.itemsPerPage"
    :headers="headers" show-select @click:row="onRowClick" class="elevation-0 dataTable">
    <!-- <template v-slot:no-data>
          <p>The Table is Empty. Please insert data with the above Button.</p>
      </template> -->

    <template #[`item.stage`]="{ item }" v-if="tableName == 'Orders' || 'ProductInStore' || 'InventoryInStore'">
      <v-chip color="#636363" variant="outlined" text-color="white" size="small"
        style="border-radius: 4px; background-color:#DEFFD9; border-color: #76FF60;" v-if="item.columns.stage">{{
          item.columns.stage }}</v-chip>
    </template>

    <template #[`item.store`]="{ item }">
      <v-chip color="#636363" text-color="white" variant="outlined" size="small"
        style="border-radius: 4px; background-color: #ebe1ff ;  border-color: #cfb7ff;" v-if="item.columns.store">{{
          item.columns.store }}</v-chip>
    </template>

    <template v-slot:top v-if="showTableHeader">
      <v-toolbar class="table-header-bg" flat>
        <v-toolbar-title class="settingHeader">{{ tableName }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-chip v-if="showFilter" style="color:white;background-color: #3C5AAA;" closable
          @click:close="clearFilter()">Remove Filter</v-chip>
        <span class="mr-4">
          <v-btn class="save" @click="save" style="background-color: #3C5AAA; color: white;" v-if="PushButton">Push To
            MarketPlace</v-btn>
        </span>

        <SearchBox @primeSearch="searchData" class="mr-3 mt-1" placeholder="Search" />
        <v-tooltip location="top" text="Sync" v-if="showSync">
          <template v-slot:activator="{ props }">
            <v-sheet v-bind="props" @click="syncClicked" border rounded :height="45" :width="45" class="tableicon">
              <IconSync></IconSync>
            </v-sheet>
          </template>
        </v-tooltip>
        <v-menu v-bind="props">
          <template v-slot:activator="{ props }">
            <!-- <v-btn class="ml-2" v-bind="props" variant="outlined" append-icon="mdi-chevron-down">
            Old Filters
          </v-btn> -->
            <v-sheet v-bind="props" border rounded :height="45" :width="45" class="tableicon">
              <v-tooltip location="top" text="Saved Filters">
                <template v-slot:activator="{ props }">
                  <v-img v-bind="props" style="max-width: 25px" src="@/assets/icon/oldfilter.svg"></v-img>
                </template>
              </v-tooltip>
            </v-sheet>
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in oldFilters" :key="index" :value="index">
              <v-list-item-title @click="setOldFilters(item)">{{ item.name }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <GlobalFilter class="ml-2" @prependClicked="setFilter()" :showIcon="true" :model="modelName"
          @appendClicked="setDefaultFilter" @oldFilterDetails="setFilterDetails"></GlobalFilter>
        <!-- <v-tooltip location="top" text="Filter">
          <template v-slot:activator="{ props }">
            <v-sheet v-bind="props" @click="$emit('filterClicked')" border rounded :height="45" :width="45"
              class="tableicon">
              <v-icon>mdi-filter-outline</v-icon>
            </v-sheet>
          </template>
        </v-tooltip> -->
        <v-menu v-if="accessPrivilege.indexOf('download') >= 0">
          <template v-slot:activator="{ props }">
            <spna v-bind="props">
              <v-tooltip text="Download" location="top">
                <template v-slot:activator="{ props }">
                  <v-sheet v-bind="props" border rounded :height="45" :width="45" class="tableicon">
                    <v-icon v-bind="props">mdi-download-outline</v-icon>
                  </v-sheet>
                </template>
              </v-tooltip>
            </spna>
          </template>
          <v-list>
            <v-list-item @click="download(item)" v-for="(item, index) in downlodData" :key="index" :value="index">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-tooltip location="top" text="Create Report" v-if="settingIcon">
          <template v-slot:activator="{ props }">
            <v-sheet v-bind="props" border rounded :height="45" :width="45" class="tableicon" @click="createReport"
              v-if="showAddButton">
              <i class="pi pi-cog"></i>
            </v-sheet>
          </template>
        </v-tooltip>

        <v-tooltip location="top" text="Add" v-if="accessPrivilege.indexOf('create') >= 0">
          <template v-slot:activator="{ props }">
            <v-sheet v-bind="props" border rounded :height="45" :width="45" class="tableicon" @click="addDialog"
              v-if="showAddButton">
              <v-icon>mdi-plus</v-icon>
            </v-sheet>
          </template>
        </v-tooltip>
        <!-- <MenuComponent :activator="'#downlod-actvator'" @menuClicked="download" :items="downlodData" /> -->
      </v-toolbar>
    </template>
    <template v-slot:bottom>
      <v-row class="table-bottom">
        <v-col class="d-flex pb-0">
          <div class="mt-4 ml-4 font-weight-light text-body-2">Showing {{ startCount ? startCount : 1 }} to {{ endCount ?
            endCount : this.itemsPerPage }} of {{
    displayData ? displayData.length : 0 }} entries</div>
          <div class="mt-2 ml-2">
            <v-select density="compact" v-model="itemsPerPage" :items="['10', '25', '50', '75', '100',]"
              variant="outlined"></v-select>
          </div>
        </v-col>
        <v-col class="pb-0">
          <v-pagination v-model="page" optionValue="code" :length="pageCount" rounded="circle"></v-pagination>
        </v-col>
      </v-row>
    </template>
    <template #[`item.style_images`]="{ item }">
      <img :src="item.columns.style_images" style="width: 30px;" />
    </template>
    <template #[`item.users`]="{ item }">
      <span>{{ item.columns && item.columns.users ? item.columns.users.name : ' ' }}</span>
    </template>
    <template #[`item.roles`]="{ item }">
      <span>{{ item.columns && item.columns.roles ? item.columns.roles.name : ' ' }}</span>
    </template>
    <template #[`item.product_types`]="{ item }">
      <span>{{ item.columns && item.columns.product_types ? item.columns.product_types.name : ' ' }}</span>
    </template>
    <template #[`item.description`]="{ item }">
      <span v-html="item.columns.description"></span>
    </template>
    <template #[`item.categories`]="{ item }">
      <span>{{ item.columns && item.columns.categories ? item.columns.categories.name : ' ' }}</span>
    </template>
    <template #[`item.last_sold_date`]="{ item }">
      <span>{{ item.columns && item.columns.last_sold_date ? moment(item.columns.last_sold_date).format("MMMM DD, YYYY") :
        ' ' }}</span>
    </template>
    <template #[`item.mws_modified`]="{ item }">
      <span>{{ item.columns && item.columns.mws_modified ? moment(item.columns.mws_modified).format("MMMM DD, YYYY") : ' '
      }}</span>
    </template>
    <template #[`item.created`]="{ item }">
      <span>{{ item.columns && item.columns.created ? moment(item.columns.created).format("MMMM DD, YYYY") : ' ' }}</span>
    </template>
    <template #[`item.modified`]="{ item }">
      <span>{{ item.columns && item.columns.modified ? moment(item.columns.modified).format("MMMM DD, YYYY") : ''
      }}</span>
    </template>
    <template #[`item.start_date`]="{ item }">
      <span>{{ item.columns && item.columns.start_date ? moment(item.columns.start_date).format("MMMM DD, YYYY HH:mm") : ''
      }}</span>
    </template>
    <template #[`item.next_date`]="{ item }">
      <span>{{ item.columns && item.columns.next_date ? moment(item.columns.next_date).format("MMMM DD, YYYY HH:mm") : ''
      }}</span>
    </template>
    <template #[`item.end_date`]="{ item }">
      <span>{{ item.columns && item.columns.end_date ? moment(item.columns.end_date).format("MMMM DD, YYYY HH:mm") : ''
      }}</span>
    </template>
    <template #[`item.trans_date`]="{ item }">
      <span>{{ item.columns && item.columns.trans_date ? moment(item.columns.trans_date).format("MMMM DD, YYYY") : ''
      }}</span>
    </template>

    <template #[`item.active`]="{ item }">
      <v-icon class="me-2" style="zoom: 70%;" :class="{
        'text-green': item.columns.active === 'Active',
        'text-red': item.columns.active === 'Inactive',
      }">
        mdi-square
      </v-icon>
      <span>{{ item.columns.active }}</span>
    </template>
    <template #[`item.actions`]="{ item }">
      <span v-if="showView && accessPrivilege.indexOf('view') >= 0">
        <v-icon class="me-2" @click.stop="rowAction(item.raw, 'view')"> mdi-eye </v-icon>
        <v-tooltip activator="parent" location="bottom">View</v-tooltip>
      </span>
      <span>
        <v-icon v-if="!item.raw.showloader && playButton" class="me-2" @click.stop="rowAction(item.raw, 'run')">
          mdi-play-circle
        </v-icon>
        <v-tooltip activator="parent" location="bottom" v-if="tooltip">Run</v-tooltip>
      </span>
      <span v-if="item.raw.showloader" @click.stop="rowAction(item.raw, 'stop')"> <v-progress-circular size="20" width="3"
          indeterminate color="#78909C">
          <!-- <i class="pi pi-stop-fill" style="font-size: 5px; color: black;"></i> -->
          <img :src="require('@/assets/stop.svg')" style="width: 5px;height: 10px;" alt="bvjgbvjhbv">
        </v-progress-circular><v-tooltip activator="parent" location="bottom">Stop</v-tooltip></span>
      <span v-if="showEdit && accessPrivilege.indexOf('update') >= 0">
        <v-icon class="me-2" @click.stop="rowAction(item.raw, 'edit')"> mdi-pencil </v-icon>
        <v-tooltip activator="parent" location="bottom">Edit</v-tooltip>
      </span>
      <span v-if="showDelete && accessPrivilege.indexOf('delete') >= 0">
        <v-icon @click.stop="rowAction(item.raw, 'delete')"> mdi-delete </v-icon>
        <v-tooltip activator="parent" location="bottom">Delete</v-tooltip>
      </span>
    </template>
  </v-data-table>
  <v-dialog v-model="openDrawer" width="900px" persistent>
    <Filter :model="modelName" :filterId="filterId" @filterClosed="applyFilter" @closeFilter="closeFilter"
      :oldFilterName="`${tableName} Filters`" />
  </v-dialog>
</div>
</template>
<script>
import SearchBox from "./SearchBox.vue";
// import MenuComponent from "./MenuComponent.vue";
import moment from 'moment'
import exportFromJSON from "export-from-json";
import jsPDF from "jspdf";
import GlobalFilter from "../components/GlobalFilter.vue";
import Filter from "@/components/FilterNew.vue";
import autoTable from "jspdf-autotable";
import lodash from 'lodash';
import IconSync from "./IconSync.vue";
import RolePermissionService from '@/services/admin/RolePermissionService'
import ListModelFilterDetailService from "@/services/admin/ListModelFilterDetailService";
export default {
  props: ["view", "edit", "tableName", "modelName", "delete", "showTableHeader", "tableData",
    "displayColumns", "headers", "isShowAdd", "playButton", "showloader", "showstop", "showSync",
    "settingIcon", "PushButton","activity"],
  components: {
    SearchBox,
    // MenuComponent,
    IconSync,
    GlobalFilter,
    Filter
  },
  data() {
    return {
      tooltip: true,
      showEdit: true,
      showView: true,
      filterId: null,
      search: '',
      searchString: null,
      openDrawer: false,
      oldFilters: [],
      accessPrivilege: [],
      showDelete: true,
      showFilter: false,
      showAddButton: true,
      page: 1,
      itemsPerPage: 10,
      displayData: [],
      displayColumnData: [],
      downlodData: [
        { title: 'PDF' },
        { title: 'Excel' },
        { title: 'Json' },
      ],
      startCount: null,
      endCount: null,
      checkingPermission: null,
    };
  },
  watch: {
    itemsPerPage() {
      this.pageCount = this.displayData.length / this.itemsPerPage
      this.showPagesCount();
    },
    page() {
      this.showPagesCount();
    },
    tableData() {
      this.displayData = this.tableData;
      this.setDefault()
    },
    displayData() {
      this.endCount = this.displayData.length < 10 ? this.displayData.length : null;
    }
  },
  computed: {
    pageCount() {
      return Math.ceil(this.displayData.length / this.itemsPerPage);
    },
  },
  async created() {
    if (this.activity) {
        this.accessPrivilege = await RolePermissionService.isAllowed(this.activity)
        let text = 'You Don\'t Have Permission to View This Page'
        this.checkingPermission = this.accessPrivilege.indexOf('list') < 0 ? text: '';
    }
    if (this.edit) {
      this.showEdit = false;
    }
    if (this.view) {
      this.showView = false;
    }
    if (this.delete) {
      this.showDelete = false
    }
    if (this.isShowAdd) {
      this.showAddButton = false;
    }
    this.displayData = this.tableData;
    this.setDefault();
  },
  methods: {
    moment,
    showPagesCount() {
      this.endCount = this.itemsPerPage * this.page
      this.startCount = (this.itemsPerPage * this.page) - this.itemsPerPage
      if (this.endCount > this.displayData.length) {
        this.endCount = this.displayData.length
      }
    },
    rowAction(item, value) {
      let $index = this.displayData.findIndex(k => k.id == item.id)
      if (value == 'run') {
        this.tooltip = false;
        this.displayData[$index].showloader = true;
      }
      if (value == 'stop') {
        this.displayData[$index].showloader = false;
      }
      let data = {
        value: this.displayData[$index],
        type: value,
      };
      this.$emit("rowAction", data);
    },
    closeFilter() {
      this.openDrawer = false;
    },
    setFilter() {
      this.openDrawer = true;
    },
    setDefaultFilter($item) {
      this.filterId = $item.id;
      this.filterName = $item.name;
      this.openDrawer = true;
    },
    applyFilter($obj) {
      console.log($obj, "filtersssss");
      this.showFilter = true
      this.openDrawer = false;
      this.$emit("filterData", $obj)
      this.getModel();
    },
    async getModel() {
      let $model = await ListModelFilterDetailService.getDetails({
        model: this.modelName,
      });
      if ($model && $model.length > 0) {
        this.oldFilters = $model;
      }
    },
    setFilterDetails($data) {
      this.oldFilters = $data;
    },
    async setOldFilters($item) {
      let $dtl = await ListModelFilterDetailService.findAll({
        model_filter_id: $item.id,
      });
      let $obj = {};
      $dtl.forEach((ele) => {
        if (ele && ele.defaults && ele.column_name) {
          if (ele.expression == "exclude" || ele.expression == "include") {
            ele.defaults = ele.defaults ? JSON.parse(ele.defaults) : [];
          }
          $obj[ele.column_name] = ele.defaults;
          // return $obj;
        }
      });
      console.log($obj, "filtersssssssssssss");
      this.showFilter = true
      this.$emit("filterData", $obj)
    },
    clearFilter() {
      this.showFilter = false
      let $obj = {}
      this.$emit("filterData", $obj)
    },
    addDialog() {
      this.$emit("addDialog");
    },
    createReport() {
      this.$emit("createReport");
    },
    syncClicked() {
      this.$emit("syncClicked")
    },
    searchData($event) {
      if ($event && $event.length > 0) {
        let $data = this.globalFilter(this.displayColumnData, $event)
        this.displayData = [...$data]
      } else {
        this.displayData = [...this.tableData]
      }
    },

    globalFilter(arr, searchKey) {
      return arr.filter(function (obj) {
        return Object.keys(obj).some(function (key) {
          let keyValue = obj[key] && obj[key] != null ? typeof obj[key] == 'number' ? obj[key].toString() : obj[key] : null;
          return keyValue && ((typeof keyValue != 'object' && keyValue.toLowerCase().includes(searchKey.toLowerCase())) || (typeof keyValue == 'object' && keyValue.name.toLowerCase().includes(searchKey.toLowerCase())));
        })
      });
    },
    setDefault() {
      let $result = [];
      if (this.tableData && this.tableData.length > 0) {
        for (let i = 0; i < this.tableData.length; i++) {
          let $nObj = {};
          for (let j = 0; j < this.headers.length; j++) {
            if (Object.prototype.hasOwnProperty.call(this.tableData[i], this.headers[j]['key'])) {
              $nObj[this.headers[j]['key']] = this.tableData[i][this.headers[j]['key']]
            }
          }
          if (Object.prototype.hasOwnProperty.call(this.tableData[i], "showloader")) {
            $nObj.showloader = this.tableData[i].showloader
          }
          $nObj.id = this.tableData[i].id;
          $result.push($nObj)
        }
      }
      this.displayColumnData = [...$result]
      this.displayData = $result;
    },
    onRowClick(item, row) {
      if(this.accessPrivilege.indexOf('view') >= 0){
        let data = row.item.raw
        let $index = this.tableData.findIndex(k => k.id == item.id)
        let dataList = {
        value: data,
        type: 'select',
        itemdata: this.tableData[$index]
      }
      this.$emit("rowAction", dataList, 'view');
      }else{
        console.log("Give View Access in Role & Permission")
      }
    },
    async download($event) {
      let fileName = this.tableName ? this.tableName : `${this.title}`;
      let data = await this.formattedData()
      if ($event.title.toLowerCase() == 'excel') {
        const exportType = exportFromJSON.types.csv;

        if (data) exportFromJSON({ data, fileName, exportType });
      }
      else if ($event.title.toLowerCase() == 'json') {
        const exportType = exportFromJSON.types.json;
        if (data) exportFromJSON({ data, fileName, exportType });

      }
      else {
        this.exportAsPDF(data, fileName)
      }
    },
    formatDate(dateString, type) {

      if (!dateString) {
        return ''
      }
      else if (type == 'date') {
        const date = new Date(dateString);
        return date.toLocaleDateString()// moment(date).utcOffset('+05:30')
      }
      else {
        const date = new Date(dateString);
        //return moment(date).toLocal()
        return date.toLocaleString();
      }
    },
    async formattedData() {
      let selectedColumns = this.headers
      let data = JSON.parse(JSON.stringify(this.displayData))
      let formateData = []
      for (let i = 0; i < data.length; i++) {
        let $obj = {}
        for (let j = 0; j < selectedColumns.length; j++) {
          if (selectedColumns[j]["title"].length > 0) {
            if ((selectedColumns[j]['type'] == 'date' || selectedColumns[j]['type'] == 'datetime' || selectedColumns[j].key == 'created' || selectedColumns[j].key == 'modified' || selectedColumns[j].key == 'start_date')) {
              $obj[selectedColumns[j]['title']] = this.formatDate(lodash.get(data[i], selectedColumns[j].key), selectedColumns[j].key)
            }
            else if (selectedColumns[j].key == 'active' || selectedColumns[j].title.toLowerCase() == 'status') {
              $obj[selectedColumns[j]['title']] = data[i].active == 1 ? true : false
            }
            else if (selectedColumns[j]['title'].toLowerCase() != 'action') {
              $obj[selectedColumns[j]['title']] = lodash.get(data[i], selectedColumns[j].key)
            }
          }

        }
        formateData.push($obj)
      }
      return await formateData
    },
    exportAsPDF(data, fileName) {
      const headers = this.setPDFHeader(data);
      const fontSize = this.calculateFontSize(headers[0].length);
      const body = this.setPDFBody(data);
      const doc = new jsPDF();
      autoTable(doc, { html: "#dt2" });
      doc["autoTable"]({
        styles: {
          cellPadding: 0.5,
          fontSize: fontSize,
        },
        headStyles: {
          fillColor: "#3f51b5",
          textColor: "#fff",
          halign: "center",
        },
        bodyStyles: {
          halign: "center",
        },
        margin: {
          left: 5,
          right: 5,
        },
        tableLineWidth: 1,
        head: headers,
        body: body,
        // didDrawPage: function (data) {

        //   // Header
        //   doc.setFontSize(20);
        //   doc.setTextColor(40);
        //   doc.text("Report", data.settings.margin.left, 22);

        //   // Footer
        //   var str = "Page " + doc.internal.pages;

        //   doc.setFontSize(10);

        //   // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        //   var pageSize = doc.internal.pageSize;
        //   var pageHeight = pageSize.height
        //     ? pageSize.height
        //     : pageSize.getHeight();
        //   doc.text(str, data.settings.margin.left, pageHeight - 10);
        // }
      });
      doc.save(fileName);
    },
    setPDFHeader(data) {
      return [
        Object.keys(data[data.length - 1]).map(
          (item) =>
            `${item.charAt(0).toUpperCase()}${item.substr(1, item.length)}`
        ),
      ];
    },
    calculateFontSize(count) {
      return count ? 50 / count : count;
    },
    setPDFBody(data) {
      return data.map((item) => {
        const keys = Object.keys(item);
        const values = [];
        keys.forEach((key) => {
          if (typeof item[key] === "object" && item[key] !== null) {
            values.push(
              item[key].name
                ? item[key].name
                : item[key].code
                  ? item[key].code
                  : item[key].name
            );
          } else {
            values.push(item[key]);
          }
        });
        return values;
      });
    },
    downloadPDF() {
      const doc = new jsPDF();
      doc.autoPrint({
        head: [['Name', 'Age']],
        body: this.data.map(({ name, code }) => [name, code])
      });
      doc.save('data.pdf');
    },
    getTableHeader() {
      return Object.keys(this.displayData[0])

    },
  },
};
</script>
<style>
.noaccesscard{
  padding-top: 25px; 
  position: relative; 
  display: flex;
  align-items: center;
  height: 100%;
}
.noaccesscontent{
  color: #3C5AAA;  
  position: relative; 
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>