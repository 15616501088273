<template>
    <v-col cols="6">
        <div class="ma-2" style="font-size: 16px;font-weight: bolder;">MarketPlaces</div>
        <InputBox :placeholder="'Search for more marketplaces'" :data="search" @textData="searchData($event)" />
    </v-col>
    <v-row justify="center">
        <v-col cols="2" class="pa-4" v-for="(item, index) in items" :key="index">
            <v-card @click="select(index)" :class="{ onbordActive: item.isSelect }" elevation="5" height="150"
                class="rounded-lg onboardCard">
                <v-card-text class="text-center mt-4">
                    <div class="text-center mt-2">{{ item.display_value }}</div>
                    <v-icon class="tick-icon" color="green" size="80">mdi-check-circle-outline</v-icon>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col style="text-align: end;" v-if="showNext" cols="12">
            <v-btn class="save" @click="save()" type="submit">Next</v-btn>
        </v-col>
    </v-row>
</template>

<script>
import EnumService from '../services/admin/EnumService'
export default {
    props: ['reload'],
    data() {
        return {
            items: [],
            subItems: [],
            showNext: false,
            search: ''
        }
    },
    watch: {
        reload() {
            this.getEnums()
        }
    },
    async mounted() {
        await this.getEnums()
    },
    methods: {
        async getEnums() {
            await EnumService.findAll({ domain: 'marketplaces', company_id: 0 }).then(res => {
                res.forEach(f => {
                    f.isSelect = false
                })
                this.items = res
                this.items = this.items.slice(0, 5);
                this.subItems = JSON.parse(JSON.stringify(res))
            }, error => {
                console.log(error)
            })
        },
        searchData($value) {
            this.search=$value
            this.items = this.subItems.filter(f => f.display_value.toLowerCase().includes($value.toLowerCase()) || f.data_value.toLowerCase().includes($value.toLowerCase()));
            this.items = this.items.slice(0, 5);
        },
        select(index) {
            this.items[index].isSelect = !this.items[index].isSelect
            let i = this.subItems.findIndex(i => i.data_value == this.items[index].data_value)
            this.subItems[i].isSelect = !this.subItems[i].isSelect
            this.showNext = false
            this.subItems.forEach(async f => {
                if (f.isSelect) {
                    this.showNext = true
                }
            })
        },
        save() {
            let $data = []
            this.subItems.forEach(async (f, index) => {
                if (f.isSelect) {
                    let data = {
                        code: f.data_value,
                        name: f.display_value,
                        market_place: f.display_value
                    }
                    $data.push(data)
                    //await MwsHeaderService.create(data)
                }
                if (index == this.subItems.length - 1) {
                    localStorage.setItem('MarketPlaces', JSON.stringify($data))
                }
            })
            this.$emit('checkMarketplace')
        }
    }
}
</script>

<style scoped>
.save {
    background-color: #3C5AAA;
    color: #fff;
    width: 90px;
}
</style>