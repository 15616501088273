<template>
    <span>
        <v-avatar v-if="avatar" @click="showImageDialog(userImage)" :class="borderRadius ? 'BorderRadius' : ' '"
            :size="height ? height : '18px'" :color="backgroundColor"
            :style="{ 'font-size': fontSize ? fontSize : 'inherit', 'height': height || '20px', 'width': width || '20px' }">
            <strong v-if="!documentId && initials" class="white--text pa-1">{{ initials }}</strong>
            <v-img aspect-ratio="1/5" v-if="!documentId && !initials" style="cursor:pointer" contain :src="userImage"
                :height="height || '24px'" :width="width || '24px'" />
            <v-img aspect-ratio="1/5" v-if="documentId" style="cursor:pointer" contain :src="userImage"
                :height="height || '24px'" :width="width || '24px'" />
        </v-avatar>
        <div v-if="!avatar" @click="showImageDialog(userImage)" :class="borderRadius ? 'BorderRadius' : ' '"
            :size="height ? height : '18px'" :color="backgroundColor"
            :style="{ 'font-size': fontSize ? fontSize : 'inherit' }">
            <strong v-if="!documentId && initials" class="white--text pa-1">{{ initials }}</strong>
            <v-img aspect-ratio="1/5" v-if="!documentId && !initials" style="cursor:pointer" contain :src="userImage"
                :height="height ? height : '24px'" :width="width ? width : '24px'" />
            <v-img aspect-ratio="1/5" v-if="documentId" style="cursor:pointer" contain :src="userImage"
                :height="height ? height : '24px'" :width="width ? width : '24px'" />
        </div>
        <input v-show="false" ref="inputUpload" type="file" @change="fileUpload($event)" @click="fileUpload($event)"
            v-if="!readOnly" multiple>
        <v-dialog v-if="imageDialog" width="350px" v-model="imageDialog">
            <v-card>
                <v-card-title>
                    <v-spacer />
                    <v-icon @click="closeImageDialog" medium>fas fa-times</v-icon>
                </v-card-title>
                <v-container fluid grid-list-md>
                    <v-img :src="userImage" />
                    <CameraView v-if="cameraMode" :keyType="keyType ? keyType : 'PP'" :keyId="keyId"
                        v-on:closeCamera="closeCamera" />
                </v-container>
                <v-card-actions class="text-xs-center" v-if="!readOnly">
                    <v-container justify-center class="pa-0 ma-0">
                        <v-chip small @click="showCamera" class="indigo white--text">
                            <v-icon class="amber--text camera-margin" small>fas fa-camera</v-icon> &nbsp;
                            <span>Take Picture</span>
                        </v-chip>
                        <FileConverterButton :key_id="keyId" :dataType="keyType ? keyType : 'PP'"
                            v-on:uploadCompleted="uploaded" />
                    </v-container>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </span>
</template>
<script>
import DocumentModel from '@/services/admin/DocumentService';
import CameraView from './CameraView.vue'
import FileConverterButton from './FileConverterButton'
import randomColor from 'randomcolor';

export default {
    props: ["documentId", "keyId", "avatar", "imgUpload", "keyType", "readOnly", "height", "width", "size", "initials", "color", "fontSize", "borderRadius",],
    components: {
        FileConverterButton, CameraView
    },
    data() {
        return {
            file: {
                "imageName": "",
                "imageUrl": "",
                "imageFile": ""
            },
            uploadImage: true,
            cameraMode: false,
            imageDialog: false,
            userImage: require('@/assets/no-image-icon-1.jpg')
        }
    },
    created: function () {
        if (this.imgUpload) {
            this.uploadImage = false
        }
        else {
            this.uploadImage = true
        }
        this.getDocuments()
    },
    mounted: function () {

    },
    computed: {
        backgroundColor() {
            if (this.initials) {
                return this.color ? this.color : randomColor.randomColor({
                    luminosity: 'dark', seed: this.initials
                })

            } else {
                return 'transparent';
            }
        }
    },
    watch: {
        documentId() {
            this.getDocuments()
        },
        imgUpload() {
            if (this.imgUpload) {
                this.uploadImage = false
            }
            else {
                this.uploadImage = true
            }
        }
    },
    methods: {
        getDocuments() {
            if (this.documentId) {
                DocumentModel.getImage({ "id": this.documentId })
                    .then(response => {
                        this.userImage = response
                    }).catch(error => {
                        console.log(error)
                    })
            } else {
                this.userImage = require('@/components/svgIcons/No-Image.svg')
            }
        },
        showCamera() {
            this.cameraMode = true
        },
        closeCamera($image) {
            this.cameraMode = false
            this.$emit('imageChanged', $image)
        },
        showImageDialog($image) {
            console.log($image)
            if (this.uploadImage) {
                this.$refs.inputUpload.click()
            }
        },
        getStyle() {
            return 'color:' + (this.color ? this.color : '##27b5f2')
        },
        closeImageDialog() {
            this.imageDialog = false
        },
        uploaded($image) {
            this.$emit('imageChanged', $image)
        },
        setData($data) {
            console.log($data, 'imagechangedddddddddddd')
            this.$emit('imageChanged', $data)
            this.$refs.inputUpload.value = null
            //this.data = $data 
        },
        fileUpload($event) {
            const files = $event.target.files
            this.$emit('uploadStart')
            if (files[0] !== undefined) {
                this.file.imageName = files[0].name
                if (this.file.imageName.lastIndexOf('.') <= 0) {
                    return
                }
                const fr = new FileReader()
                let $keyType = this.keyType
                if ($keyType == '' || $keyType == null) {
                    $keyType = 'UP'
                }
                var $dataType = $keyType
                var $file = this.file
                var $type = this.type
                var $keyId = this.key_id
                if (!$keyId) { $keyId = 100 }
                if (this.type != 'UP') {
                    $type = $dataType
                } else {
                    $type = 'UP' + $dataType
                }
                fr.readAsDataURL(files[0])
                fr.addEventListener('load', () => {
                    $file.imageUrl = fr.result
                    $file.imageFile = files[0] // this is an image file that can be sent to server...
                    var $json = {
                        "key_type": $type,
                        "key_id": $keyId,
                        "doc_type_id": 1,
                        "doc_name": $file.imageName,
                        "file_name": $file.imageName,
                        "content_type": $file.imageFile.type,
                        "active": 1,
                        "data": $file.imageUrl
                        //"company_id":JSON.parse(localStorage.getItem('tiburaUser')).data.User.company_id
                    }
                    if ($dataType === '##') {
                        this.setData($file.imageUrl)

                    } else {
                        DocumentModel.upload($json)
                            .then(response => {
                                this.setData(response.data.data)
                                //resolve("File Uploaded")
                            }).catch(error => {
                                console.log(error)
                                //reject(error)
                            });
                    }
                })
            } else {
                this.file.imageName = ''
                this.file.imageFile = ''
                this.file.imageUrl = ''
            }
        }
    }
}
</script>
<style>
.img-circle {
    border-radius: 50%;

}

.my-icon {
    vertical-align: top;
}

.camera-margin {
    margin-right: 3px;
}

.BorderRadius {
    border-radius: 0%;
}
</style>