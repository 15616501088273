<template>
    <v-row>
        <!-- <v-col :cols="12" class="chosemarketplace">
          <ColorMarketPlace @applyHeaderId="filterValues"></ColorMarketPlace>
          <v-chip 
            v-model="showMarketPlace"
            label 
            close 
            color="primary" 
            variant="outlined"
            closable
            class="black--text font-weight-bold ml-2 selectchip"
            @click:close="clearFilter">
            Marketplace Applied
            </v-chip>
        </v-col> -->
         <v-col :cols="12">
             <PrimeTable :isShowAdd="true" :activity="'Product In Store'" :tableName="'Product In Store '" :showTableHeader="true" :headers="column" :tableData="data" @rowAction="rowAction" 
             :modelName="'mws_product_xref'" @filterData="applyFilter($event)"></PrimeTable>
         </v-col>
     </v-row>
 </template>
 <script>
 import MwsProductXrefService from '@/services/mws/MwsProductXrefService';
 import lodash from 'lodash'
 export default{
  components:{
   
  },
   data(){
     return {
       data: [],
       showMarketPlace:false,
       mwsId:localStorage.getItem("mwsHeaderId"),
             column: [
             { title: 'Store', align: 'start', key: 'store_name' },
                 { title: 'Sku', align: 'start', key: 'mws_sku' },
                 { title: 'Merchant Sku', align: 'start', key: 'merchant_sku' },
                 { title: 'UPC', align: 'start', key: 'mws_upc' },
                 { title: 'Mws Product Id', align: 'start', key: 'mws_product_id' },
                 { title: 'Variant Id', align: 'start', key: 'mws_variant_id' },
                 { title: 'Inventory Id', align: 'start', key: 'inventory_id' },
                 { title: 'Sales Price', align: 'start', key: 'sale_price' },
                 { title: 'Status    ', key: 'active',sortable: false, },
 
             ],
             active: [
                 { id: 1, name: "Active" },
                 { id: 0, name: "Inactive" },
             ],
     }
   },
   created(){
      let $value = {};
      this.getInventories($value);
   },
   methods:{
    applyFilter($value){
        this.getInventories($value)
    },
    clearFilter(){
        this.getInventories();
    },
    filterValues($id){
        if($id){
           this.ShowFilter=lodash.filter(this.styles,{"mws_header_id":$id});
            this.showMarketPlace = true;
        }
    },
    async getInventories($value){
      let $res = await MwsProductXrefService.findAll({mws_header_id:this.mwsId, ...$value});
      if($res && $res.length>0){
        $res.forEach((element) => {
          element.store_name=element.mws_headers ? element.mws_headers.name : null;
        })
        this.data = $res;
        this.data.map((obj) => {
            return (obj.active = this.active.find(
              (type) => type.id == obj.active
            ).name);
          });
      }
    }
   }
 }
 </script>
 <style lang="scss">
.chosemarketplace{
     position: absolute;
    top: 17px;
    left: 156px;
    z-index: 100;
  }
  .selectchip{
    position: relative;
    left: 257px;
    bottom: 18px;
  }
 </style>