<template>
  <PrimeTable
    :tableName="'Accounts'"
    activity="Accounts"
    :showTableHeader="true"
    :headers="column"
    :tableData="data"
    :delete="true"
    @rowAction="rowAction"
    @addDialog="addDialog"
    :modelName="'accounts'"
    @filterData="applyFilter($event)"
  ></PrimeTable>
  <v-dialog v-model="dialog" width="600px" persistent>
    <AddAccount @closeDialog="closeDialog" :type="type" :id="editDataId" />
  </v-dialog>
  <v-dialog v-model="confirmation" width="300px" persistent>
    <ConfirmationDialog
      @cancelled="cancelled()"
      @deleted="deleted"
      :content="confirmationContent"
    />
  </v-dialog>
</template>
  
  <script>
import AccountService from "@/services/admin/AccountService";
import ConfirmationDialog from "@/components/DialogConfirmation.vue";
import AddAccount from "../AddAdmin/AddAccounts.vue";
export default {
  components: {
    AddAccount,
    ConfirmationDialog,
  },
  data() {
    return {
      dialog: false,
      DivisionId: "",
      data: [],
      confirmationContent: null,
      confirmation: false,
      column: [
        { title: "Name", align: "start", key: "name" },
        { title: "Code", align: "start", key: "code" },
        // { title: "Marketplace", align: "start", key: "mws_header_id" },
        { title: "Modified by", align: "start", key: "users" },
        { title: "Modified on", align: "start", key: "modified" },
        { title: "Status", align: "start", key: "active" },
        { title: "Action", key: "actions", sortable: false },
      ],
      active: [
        { id: 1, name: "Active" },
        { id: 0, name: "Inactive" },
      ],
    };
  },
  created() {
    let $value = {};
    this.getAccount($value);
  },
  methods: {
    applyFilter($value){
      this.getAccount($value);
    },
    async getAccount($value) {
      await AccountService.findAll($value)
        .then((response) => {
          this.data = response;
          this.data.map((obj) => {
            return (obj.active = this.active.find(
              (type) => type.id == obj.active
            ).name);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    rowAction(e) {
      if (e.type == "edit") {
        this.dialog = true;
        this.type = e.type;
        this.editDataId = e.value.id;
      }
      if (e.type == "view") {
        this.dialog = true;
        this.type = e.type;
        this.editDataId = e.value.id;
      }
      if (e.type == "delete") {
        this.dialog = false;
        this.confirmation = true;
        this.confirmationContent = `Do you want to delete the Account ${e.value.name} ?`;
        this.editDataId = e.value.id;
      }
      if (e.type == "select") {
        this.dialog = true;
        this.type = e.type;
        this.editDataId = e.value.id;
      }
    },

    addDialog() {
      this.dialog = true;
      this.type = "add";
      this.editDataId = null;
    },
    closeDialog() {
      this.dialog = false;
      this.getAccount();
    },
    async deleted() {
      this.confirmation = false;
      await AccountService.delete({ id: this.editDataId });
      this.getAccount();
    },
    cancelled() {
      this.editDataId = null;
      this.confirmation = false;
    },
  },
};
</script>
  