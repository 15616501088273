<template>
  <div class="hv-item">
    <div class="hv-item-parent">
      <div
        @click="nodeClicked(node, node.task)"
        :class="{
          parentCircle: node.task === 1 || node.task === 4,
          dia: node.task === 2 || node.task === 6,
          rectangle: node.task === 3,
          hidechildrenafter: node?.task === 5,
          active: node.isActive,
        }"
      >
        <div
          :class="{ diamondtext: node.task == 2 || node.task == 6 }"
          v-if="node?.task !== 5"
        >
          {{
            node.task === 1
              ? "Start"
              : node.task === 4
              ? "End"
              : node.task === 2
              ? "Condition"
              : node.task === 6
              ? "Task Approval"
              : node.name
          }}
        </div>
      </div>
      <div fxLayout="row" class="conditiontext" v-if="node?.task === 2">
        <div class="left">on success</div>
        <div class="right">on failure</div>
      </div>
      <div fxLayout="row" class="conditiontext" v-if="node?.task === 6">
        <div class="left">on Approval</div>
        <div class="right">on Rejected</div>
      </div>
      <button
        id="addbutton"
        :class="{ topPosition: node?.task === 5 }"
        class="add-button"
        v-if="node?.children.length === 0"
        @click="addChild(node, 0)"
      >
        <span class="material-icons"> <v-icon>mdi-plus</v-icon> </span>
      </button>
    </div>
    <div class="hv-item-children" v-if="node?.children.length > 0">
      <div
        class="hv-item-child onlyfirst"
        v-for="child of node.children"
        :key="child.task"
      >
        <TreeView v-if="child.children.length > 0" :node="child"></TreeView>
        <div
          class="hv-item"
          :class="{
            'hv-item-parent': child?.children.length == 0 && child.task !== 5,
            hideafterline: child.task === 4,
          }"
          v-if="child.children.length == 0"
        >
          <div
            @click="nodeClicked(child, child.task)"
            :class="{
              parentCircle: child.task === 1 || child.task === 4,
              dia: child.task === 2,
              rectangle: child.task === 6,
              rectangle: child.task === 3,
              hidechildrenafter: child.task === 5,
              active: child.isActive,
            }"
          >
            <div v-if="child?.task !== 5">
              {{
                child.task === 1
                  ? "Start"
                  : child.task === 4
                  ? "End"
                  : child.task === 2
                  ? "Condition"
                  : child.task === 6
                  ? "Task Approval"
                  : child.name
              }}
              <div>
                {{
                  child.taskTypeData && child.taskTypeData.taskType
                    ? child.taskTypeData.taskType
                    : ""
                }}
              </div>
            </div>
          </div>
          <div fxLayout="row" class="conditiontext" v-if="child?.task === 2">
            <div class="left">on success</div>
            <div class="right">on failure</div>
          </div>
          <div fxLayout="row" class="conditiontext" v-if="child?.task === 6">
            <div class="left">on Approval</div>
            <div class="right">on Rejected</div>
          </div>
          <button
            id="addbutton{{index}}"
            v-if="child.task !== 4"
            class="add-button"
            :class="{ topPosition: child?.task === 5 }"
            @click="addChild(child, 2)"
          >
            <span class="material-icons"> add </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TreeView from "./TreeView.vue";
import { authStore } from '@/store/index'
export default {
  props: ["node"],
  components: {
    TreeView,
  },
  data() {
    return {
      operator: 0,
      newId: 0,
      active: true,
    };
  },
  methods: {
    nodeClicked(selectedNode, nodeId) {
      this.$emit("nodeClicked", { data: selectedNode, value: nodeId });
      selectedNode.active = true;
      authStore.commit("setSelectedNode", { data: selectedNode, value: nodeId });

    },

    addChild(item, event) {
      this.$emit("addChild", { data: item, value: event });
      authStore.commit("setChildArrayNew", { data: item, value: event });
    },
  },
};
</script>
<style>
.hv-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
}
.hv-item .hv-item-parent {
  margin-bottom: 50px;
  position: relative;
  display: flex;
  justify-content: center;
}
.hv-item .hv-item-parent.hideArrow:after {
  display: block !important;
}
.hv-item .hv-item-parent:after {
  position: absolute;
  content: "";
  width: 2px;
  height: 50px;
  bottom: 0;
  left: calc(50% - 0px);
  background-color: #cdcdcd;
  transform: translateY(100%);
}
.hv-item .hv-item-children {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.hv-item .hv-item-children .hv-item-child {
  position: relative;
}
.hv-item .hv-item-children .hv-item-child:only-child:after {
  content: none;
}
.hv-item .hv-item-children .hv-item-child:before,
.hv-item .hv-item-children .hv-item-child:after {
  content: "";
  position: absolute;
  background-color: #cdcdcd;
  left: 0;
}
.hv-item .hv-item-children .hv-item-child:before {
  left: calc(50% + 0px);
  top: 0;
  transform: translateY(-100%);
  width: 2px;
  height: 50px;
}
.hv-item .hv-item-children .hv-item-child:after {
  top: -50px;
  transform: translateY(-100%);
  height: 2px;
  width: calc(100%);
}
.hv-item .hv-item-children .hv-item-child:first-child:after {
  left: 50%;
  width: 50%;
}
.hv-item .hv-item-children .hv-item-child:last-child:after {
  width: calc(50% + 2px);
}
.add-button {
  width: 20px;
  height: 20px;
  background: #fff;
  color: grey !important;
  position: absolute;
  right: 19px;
  bottom: -59px;
  border: 0.5px solid grey;
  left: calc(50% - 8px);
  z-index: 9999 !important;
  border-radius: 10px;
  opacity: 1;
}
.add-button .material-icons {
  position: relative;
  left: -5px;
  font-size: 17px !important;
  top: 0px;
  cursor: pointer;
}
.parentCircle {
  height: 50px;
  width: 50px;
  border-radius: 50% !important;
  padding: 0px !important;
  min-width: 50px !important;
  font-size: 13px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none !important;
  border: 1.5px solid #cdcdcd !important;
  color: grey !important;
  margin-bottom: 0px !important;
  background: #fff !important;
  font-weight: bold;
}
.dia {
  width: 90px;
  height: 90px;
  background-color: #fff f;
  transform: rotate(45deg);
  margin: 0px 0px 14px;
  text-align: center;
  border: 1px solid;
  margin-left: 2px;
  margin-top: 15px !important;
}
.dia .diamondtext {
  position: relative;
  transform: rotate(317deg);
  margin: 20px 0px 0px 0px;
  left: 2px;
  color: rgba(0, 0, 0, 0.87);
  text-align: center;
  font-weight: bold;
}
.diamond {
  width: 0;
  height: 0;
  border: 50px solid transparent;
  border-bottom-color: #fff;
  position: relative;
  top: -50px;
  left: 1px;
  z-index: 99;
}
.diamond .diamondtext {
  position: relative;
  top: 36px;
  z-index: 9999;
  left: -22px;
  color: #fff;
  text-align: center;
}
.diamond:after {
  content: "";
  position: absolute;
  left: -50px;
  top: 50px;
  width: 0;
  height: 0;
  border: 50px solid transparent;
  border-top-color: #fff;
}
.diamondActive {
  border-bottom-color: #1d7880 !important;
}
.diamondActive:after {
  border-top-color: #1d7880;
}
.rectangle {
  box-shadow: none !important;
  min-width: 183px !important;
  border-radius: 0px;
  border-width: 1.5px;
  font-size: 13px !important;
  height: 60px;
  border: 1.5px solid #bbb5b5;
  margin: 10px;
  margin-top: -2px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0px;
  background: #fff !important;
  font-weight: bold;
}
.hidechildrenafter {
  box-shadow: none !important;
  min-width: 183px !important;
  font-size: 13px !important;
  margin: 10px;
  margin-top: -10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.topPosition {
  top: 0px !important;
}
.hideafterline:after {
  display: none;
}
.active {
  background: #1d7880 !important;
  color: #fff !important;
}
.conditiontext {
  position: relative;
}
.conditiontext .left,
.conditiontext .right {
  position: absolute;
  top: 120px;
  width: 100px;
  font-size: 14px;
  font-weight: bold;
  text-transform: capitalize;
}
.conditiontext .left {
  left: -130px;
}
.conditiontext .right {
  left: -17px;
}
.dia.active .diamondtext {
  color: #fff !important;
}
</style>