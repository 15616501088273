<template>
    <v-card width="100%" style="border-radius: 12px; overflow: hidden">
        <v-form @submit.prevent="save" ref="form">
            <v-card-title class="dialog-Title">{{ viewType == "edit" ? "Edit" : viewType == "view" ? "View" : "Add" }} Action
                <v-btn @click="close()" elevation="0" density="comfortable" icon="mdi-close" style="float: right;"></v-btn>
            </v-card-title>
              <v-divider></v-divider>
            <v-card-text>
                <v-row no-gutters class="pa-2">
                    <v-col cols="12">
                        <v-radio-group :disabled="isRadioDisabled" v-model="formData.active" inline>
                            <v-radio color="primary" label="Active" :value="1"></v-radio>
                            <v-radio color="error" label="Inactive" :value="0"></v-radio>
                        </v-radio-group>
                    </v-col>
                    <v-col cols="4">
                        <!-- <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Code
                            <span v-if="!showReadOnly"
                                style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label> -->
                        <InputBox density="compact" :label="'Code'" placeholder="Code" :rules="codeRule"
                            :data="formData.code" :disabled="showReadOnly" @textData="checkCode($event)"
                            @keydowninput="prevent($event)" />
                        <v-error v-if="codeError">Already Exists!</v-error>
                    </v-col>

                    <v-col cols="12" class="">
                        <!-- <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Name
                            <span v-if="!showReadOnly"
                                style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label> -->
                        <InputBox density="compact" placeholder="Name" :label="'Name'" :rules="nameRule"
                            :data="formData.name" :disabled="showReadOnly"
                            @textData="($event) => { formData.name = $event; }" />
                    </v-col>
                    <v-col cols="12">
                        <!-- <v-label class="pb-1" style="font-size: 14px; color: #272727; font-weight: 600">Event
                            <span v-if="!showReadOnly"
                                style="color: #cf0707; padding-left: 2px; font-weight: 700">*</span></v-label> -->
                        <InputBox density="compact" placeholder="Event" :label="'Event`'" :data="formData.event"
                            :disabled="showReadOnly" @textData="($event) => { formData.event = $event; }" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="dialogfooter" v-if="!showReadonly">
                <v-spacer></v-spacer>
                <v-btn class="mr-1 cancel" @click="dialog = close()">Cancel</v-btn>
                <v-btn class="save" type="submit">Save</v-btn>
            </v-card-actions>
        </v-form>
    </v-card>
</template>
<script>
import InputBox from "@/components/InputBox.vue";

export default {
    components: {
        InputBox,
    },

    data() {
        return {
            showReadOnly: false,
            dialog: false,
            type: null,
            codeRule: [(value) => !!value || "Code is Required."],
            nameRule: [(value) => !!value || "Name is Required."],
            codeError: false,
            formData: {
                code: null,
                name: null,
                event: null,
                active: 1
            },
        };
    },

    watch: {
        id() {
            if (this.id) {
                this.formData = {};
                this.getById(this.id);
            }
        },
    },
    created() {
        if (this.id) {
            this.getById(this.id);
        }
        if (this.viewType) {
            this.showReadOnly = this.viewType == "edit" ? false : true;
        }
    },
    methods: {
        checkCode($value) {
        this.formData.code = $value
        this.formData.code = this.formData && this.formData.code ?this.formData.code.toUpperCase() :'';
        },
        prevent(e) {
            if (e !== null) {
                if (!/^[a-zA-Z0-9-_ \t]+$/.test(e.key) && e.key !== "Backspace") {
                    e.preventDefault();
                }
            }
        },

        close() {
            this.$emit("closeDialog");
        },

        async getById(id) {
            console.log(id)
        },

        async save() {
            let validation = await this.$refs.form.validate()
            if (!validation.valid || this.codeError) {
                console.log("Not valid")
            }
            else {
                //this.formData.active = this.formData && this.formData.active == 1 ? true : false
                this.$emit('apply-changes', this.formData)

            }
        },
    },
};

</script>

<style>
.apply {
    background-color: #3C5AAA;
    color: #fff;
    width: 120px;
}
</style>
    