<template>
  <v-card>
    <v-form @submit.prevent="save" ref="form">
      <v-card-title class="dialog-Title"
        >{{
          type == "edit"
            ? "Edit"
            : type == "view"
            ? "View"
            : type == "add"
            ? "Add"
            : type == "select"
            ? "View"
            : ""
        }}
        Freight Template
        <v-btn
          density="comfortable"
          @click="closeDialog"
          elevation="0"
          icon="mdi-close"
          style="float: right"
        >
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" class="pa-2">
              <InputBox
                :label="'Code'"
                :data="formData.code"
                @textData="($event) => {formData.code = $event;}"

                :rules="codeRule"
              />
            </v-col>

            <v-col cols="12" class="pa-2">
              <InputBox
                :label="'Name'"
                :data="formData.name"
                @textData="($event) => {formData.name = $event;}"
                :rules="nameRule"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="dialogfooter">
        <v-spacer></v-spacer>
        <v-btn class="mr-1 cancel" @click="closeDialog">Cancel</v-btn>
        <v-btn class="save" type="submit">Save</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
      
      <script>
export default {
  props: ["type"],
  data() {
    return {
      shipperData: [],
      nameRule: [(value) => !!value || "Name is Required"],
      codeRule: [(value) => !!value || "Code is Required"],
      formData: {
        name: null,
        code: null,
      },
    };
  },

  created() {},

  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
    async save() {
      let validation = await this.$refs.form.validate();
      console.log(validation);
    },
  },
};
</script>
      
      <style>
</style>