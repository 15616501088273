<template>
  <PrimeTable
    :tableName="'Material'"
    :showTableHeader="true"
    :headers="column"
    :tableData="data"
    @rowAction="rowAction"
    @addDialog="addDialog"
  ></PrimeTable>

  <v-dialog v-model="dialog" width="600px" persistent>
    <AddMateiral @closeDialog="closeDialog" :type="type" />
  </v-dialog>
</template>
  
  <script>
import MaterialModel from "@/models/MaterialService";
import AddMateiral from "../AddAdmin/AddMateiral.vue";
export default {
  components: {
    AddMateiral,
  },
  data() {
    return {
      dialog:false,
      data: [],
      column: [
        { title: "Name", align: "start", key: "name" },
        { title: "Code", align: "start", key: "code" },
        { title: "Status", key: "active", sortable: false },
        { title: "Action", key: "actions", sortable: false },
      ],
      active: [
        { id: 1, name: "Active" },
        { id: 0, name: "Inactive" },
      ],
    };
  },
  created() {
    this.getMaterial();
  },
  methods: {
    async getMaterial() {
      await MaterialModel.findAll()
        .then((response) => {
          console.log(response, "working1111");
          this.data = response.data;
          this.data.map((obj) => {
            return (obj.active = this.active.find(
              (type) => type.id == obj.active
            ).name);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addDialog() {
      this.dialog = true;
      this.type = "add";
    },
    closeDialog() {
      this.dialog = false;
    },
  },  
};
</script>