<template>
    <v-row>
        <v-col class="pa-0" v-for="item in items" :key="item.title" cols="12">
          <v-row>
            <v-col cols="10" class="pl-3 pt-7 pb-7"><b>Fulfilled ()</b>
                <span class="pl-3">
                    <Tag class="chip1 pa-2 pt-1 pb-1 text-caption" >Ship Station</Tag>&nbsp;
                    <Tag class="chip2 pa-2 pt-1 pb-1 text-caption" v-if="item.tracking_num">{{ item.tracking_num }}<span class="pl-1"><v-icon size="15px" @click="copyText(item.chip)"><v-img src="https://s3.ap-south-1.amazonaws.com/xtend3.0/icons/edit/copy.svg" width="10px"></v-img></v-icon></span></Tag>&nbsp;
                    <Tag class="chip3 pa-2 pt-1 pb-1 text-caption" v-if="item.fulfillments.code">{{ item.fulfillments.code }}</Tag>&nbsp;
                    <Tag class="chip4 pa-2 pt-1 pb-1 text-caption" v-if="item.mws_orders.shipment_method">{{ item.mws_orders.shipment_method }}</Tag>&nbsp;
                    <Tag class="chip1 pa-2 pt-1 pb-1 text-caption" v-if="item.modified">{{ moment(item.modified).format("DD-MM-YYYY") }}</Tag>
                    </span> 
            </v-col>
              <v-col cols="2" align="end" class="pt-6">
                   <v-btn variant="tonal" size="small" class="ma-2" elevation="0"> UNDO </v-btn>
              </v-col>
            </v-row>
                <v-card elevation="0">
                  <v-card-text class="pb-0">
                    <v-row class="pa-1">
                        <v-col cols="10" >
                            <v-row >
                                <span><v-checkbox color="indigo"></v-checkbox></span>
                                   <span class="pl-5 pr-3"> <v-img :width="30" :height="60" cover :src="`${item.products.images}`"></v-img></span>
                                  <span class="pl-3 pt-2">
                                     <b class="text-subtitle-2">{{ item.description }}</b><span class="pl-2"><v-icon size="20" @click="openDialog()">mdi-pencil</v-icon></span>
                                     <div><span class="text-caption">{{ item.shop_sku }} | {{ item.merchant_sku_ordered }}</span> </div>
                                  </span>
                           </v-row>
                        </v-col>
                        <v-col cols="2" align="end">
                            <p class="text-body-2"><b>${{ item.sale_price }}</b></p>
                            <p class="text-caption">Qty : {{ item.ordered_qty }}</p>
                        </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>  
        </v-col>
    </v-row>
    <v-dialog v-model="dialog" width="500px" persistent>
         <AddItems @closeDialog="closeDialog()"/>
    </v-dialog>
</template>
<script>
import moment from 'moment'
import Tag from 'primevue/tag';
import AddItems from './AddItems.vue';
export default{
    props:['data'],
    components:{
        Tag,
        AddItems,
    },
    data(){
        return{
            items:[
        //   {count:"2",title:"Tour Manager FL 36\" Rolling Black Duffle Bag Default Title",chip:'Tracking #ABCS23400',b_color:'aqua',color:'',src:"https://cdn.shopify.com/s/files/1/0663/5854/2615/products/Screenshot_20221104_020028.png?v=1667550905"},
        //   {count:"1",title:"Tour Manager FL 36\" Rolling Black Duffle Bag Default Title",chip:'Tracking #ABCS23500',b_color:'blanchedalmond',src:"https://cdn.shopify.com/s/files/1/0663/5854/2615/products/Screenshot_20221104_014645.png?v=1667550082"},
        //   {count:"3",title:"Tour Manager FL 36\" Rolling Black Duffle Bag Default Title",chip:'Missing Product',b_color:'blanchedalmond'}
        ],
        dialog: false,
        }
    },
    watch:{
     data(){
        this.items = this.data
     }
    },
    methods:{
        moment,
        copyText(chip) {
           navigator.clipboard.writeText(chip);
        },
        closeDialog(){
            this.dialog = false
        },
        openDialog(){
        this.dialog = true
        },
    }
}
</script>

<style scoped>
>>> .chip4{
    background-color: #D1DBFF;
    color:#272727;
    font-weight: 400;
} 
>>> .chip3{
    background-color: #D1FCFF;
    color:#272727;
    font-weight: 400;
} 
.chip2{
    background-color: #D8FFD1;
    color:#272727;
    font-weight: 400;
} 
>>> .chip1{
    /* padding-right: 5px;
    padding-left: 5px;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-left: 8px; */
    background-color: #FFDFD1;
    color:#272727;
    font-weight: 400;
}  

</style>