<template>
  <PrimeTable
    :tableName="'Locations'"
    activity="Locations"
    :showTableHeader="true"
    :headers="column"
    :tableData="data"
    @rowAction="rowAction"
    @addDialog="addDialog($event)"
    :modelName="'locations'"
    @filterData="applyFilter($event)"
  ></PrimeTable>
  <v-dialog v-model="dialog" width="800px" persistent>
    <AddLocation @closeDialog="closeDialog()" :showReadOnly="showReadOnly" :type="type" :id="editDataId" />
  </v-dialog>
  <v-dialog v-model="confirmation" width="400px" persistent>
    <ConfirmationDialog @cancelled="cancelled()" @deleted="deleted" :content="confirmationContent" />
  </v-dialog>
</template>
<script>
import AddLocation from "./AddLocation.vue";
import ConfirmationDialog from "@/components/DialogConfirmation.vue";
import MwsFulfillmentService from "@/services/mws/MwsFulfillmentService";
export default {
  components: {
    AddLocation,
    ConfirmationDialog
  },
  data() {
    return {
      dialog: false,
      mwsId: localStorage.getItem("mwsHeaderId"),
      confirmationContent:null,
      confirmation:false,
      data: [],
      type:null,
      column: [
        // { title: "Name", align: "start", key: "name" },
        { title: "Fulfillment", align: "start", key: "fulfillment_name" },
        { title: "MWS", align: "start", key: "store_name" },
        { title: "State", align: "start", key: "state" },
        { title: "Country", align: "start", key: "country" },
        { title: "Status    ", key: "active", sortable: false },
        { title: "Action", key: "actions", sortable: false }
      ],
      active: [
        { id: 1, name: "Active" },
        { id: 0, name: "Inactive" },
      ],
      editDataId: null,
    };
  },
  created(){
    let $value = {};
    this.getLocations($value);
  },
  methods: {
    applyFilter($value){
      this.getLocations($value);
    },
    async getLocations($value){
      let $res = await MwsFulfillmentService.findAll({mws_header_id:this.mwsId, ...$value})
      console.log($res,'result')
      if($res && $res.length>0){
        $res.forEach((element) => {
          element.fulfillment_name=element.fulfillments ? element.fulfillments.name : null;
          element.store_name=element.mws_headers ? element.mws_headers.name : null;
        })
        this.data=$res;
        this.data.map((obj) => {
            return (obj.active = this.active.find((type) => type.id == obj.active).name);
          });
      }
    },
    rowAction(e) {
      this.dialog=false;
      if (e.type == "edit") {
        this.dialog = true;
        this.type = e.type;
        this.editDataId = e.value.id;
        this.showReadOnly = false
      }
      if (e.type == "view") {
        this.dialog = true;
        this.type = e.type;
        this.editDataId = e.value.id;
        this.showReadOnly = true
      }
      if (e.type == "delete") {
        this.dialog=false;
        this.confirmation = true;
        this.confirmationContent = `Do you want to delete the location ?`
        this.editDataId = e.value.id;
      }
      if (e.type == "select") {
        this.dialog = true;
        this.type = e.type;
        this.editDataId = e.value.id;
        this.showReadOnly = true
      }
    },
    addDialog() {
      this.dialog=true;
      this.type = 'add'
      this.showReadOnly = false
    },
    closeDialog(){
      this.dialog=false;
      this.getLocations()
    },
    async deleted(){
      this.confirmation = false;
       await MwsFulfillmentService.delete({id:this.editDataId});
      this.getLocations();
    },
    cancelled(){
      this.editDataId = null;
      this.confirmation = false;
    }
  },
};
</script>