<template>
    <PrimeTable
      :tableName="'Workflow'"
      :showTableHeader="true"
      :headers="column"
      :tableData="data"
      @rowAction="rowAction"
      @addDialog="addDialog"
    ></PrimeTable>
    <v-dialog v-model="confirmation" width="300px" persistent>
      <ConfirmationDialog
        @cancelled="cancelled()"
        @deleted="deleted"
        :content="confirmationContent"
      />
    </v-dialog>
  </template>
  
  <script>
  import WorkflowService from '@/services/admin/WorkFlowService.js'
  import ConfirmationDialog from "@/components/DialogConfirmation.vue";
  export default {
    components: {
      ConfirmationDialog,
    },
    data() {
      return {
        dialog: false,
        DivisionId: "",
        data: [],
        confirmationContent: null,
        confirmation: false,
        column: [
          { title: "Name", align: "start", key: "name" },
          { title: "Code", align: "start", key: "code" },
          { title: "Modified by", align: "start", key: "modified_by" },
          { title: "Modified on", align: "start", key: "modified" },
          { title: "Status", align: "start", key: "active" },
          { title: "Action", key: "actions", sortable: false },
        ],
        active: [
          { id: 1, name: "Active" },
          { id: 0, name: "Inactive" },
        ],
      };
    },
    created() {
      this.getWorkflows();
    },
    methods: {
      async getWorkflows() {
        await WorkflowService.findAll()
          .then((response) => {
            this.data = response;
            this.data.map((obj) => {
              return (obj.active = this.active.find((type) => type.id == obj.active).name);
            });
          })
          .catch((error) => {
            console.log(error);
          });
      },
      rowAction(e) {
        if (e.type == "edit") {
          this.dialog = true;
          this.type = e.type;
          this.editDataId = e.value.id;
        }
        if (e.type == "view") {
          this.dialog = true;
          this.type = e.type;
          this.editDataId = e.value.id;
        }
        if (e.type == "delete") {
          this.dialog = false;
          this.confirmation = true;
          this.confirmationContent = `Do you want to delete the Workflow ${e.value.name} ?`;
          this.editDataId = e.value.id;
        }
        if (e.type == "select") {
          this.dialog = true;
          this.type = e.type;
          this.editDataId = e.value.id;
        }
      },
  
      addDialog() {
        this.type = "add";
        this.editDataId = null;
        this.$router.push('/addWorkflow')
      },
      closeDialog() {
        this.dialog = false;
        this.getWorkflows();
      },
      async deleted() {
        this.confirmation = false;
        await WorkflowService.delete({ id: this.editDataId });
        this.getWorkflows();
      },
      cancelled() {
        this.editDataId = null;
        this.confirmation = false;
      },
    },
  };
  </script>
  