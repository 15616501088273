<template>
  <div>
    <div class="subTitle">Health</div>
    <v-card class="mt-4 pa-2 Card" elevation="0" min-height="106px">
      <v-card-title class="text-subtitle-2 mt-5"
        >Scheduler Status
        <v-icon style="float: right" class="pt-2" :color="HealthCardIcon ? 'red' : 'green'" 
          >mdi-check-bold</v-icon
        ></v-card-title
      >
    </v-card>
     <div class="subTitle mt-4">Do you know ?</div>
    <v-card flat class="Card rounded-lg mt-4">
    <!-- <v-card-title>Do you know ?</v-card-title> -->
<v-card-text>
    <v-row v-for="item in healthData" :key="item">
      <v-col cols="2" class="mt-0">
        <v-avatar color="#F8F8F8">
          <v-icon color="#3C5AAA">mdi-flag-outline</v-icon>
        </v-avatar>
      </v-col>
      <v-col cols="10">
        {{ item.textConetent }}
      </v-col>
      <v-divider color="#C5C5C5" class="border-opacity-15"></v-divider>
    </v-row>
  </v-card-text>
  </v-card>
    <!-- <v-divider color="#C5C5C5" class="border-opacity-75 mt-8"></v-divider> -->
    <!-- <v-col cols="12">
      <v-col class="backroundimg">
        <p class="mt-15 img-text">
          Send those <strong>45 Products</strong> that are not yet in
          Marketplaces
        </p>
      </v-col>
      <v-col class="backroundimg1 mt-3">
        <p class="ma-3">Your Selling Ratio</p>
        <p class="mt-15"><strong>33 %</strong></p>
      </v-col>
    </v-col> -->
    <!-- <v-col>
        <v-card>
        <div id="chart">
        <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
      </div>
    </v-card>
    </v-col> -->
  </div>
</template>

<script>
import DashBoardService from '@/services/admin/DashBoardService';
export default {
  props:["cardData"],
  data() {
    return {
      HealthCardIcon:false,
      healthData: [
        // {
        //   textConetent: "You have 43 people browsing your Shopify site",
        // },
        {
          textConetent: "No Orders from Store(s) since Last 20 Day(s)",
        },
        {
          textConetent: "43 Orders  are not yet closed",
        },
        {
          textConetent: "82 % of Your products have no orders since 20 Day(s)",
        },
      ],
      series: [
        {
          name: "Actual",
          data: [
            {
              x: "2011",
              y: 1292,
              goals: [
                {
                  name: "Expected",
                  value: 1400,
                  strokeHeight: 5,
                  strokeColor: "#775DD0",
                },
              ],
            },
            {
              x: "2012",
              y: 4432,
              goals: [
                {
                  name: "Expected",
                  value: 5400,
                  strokeHeight: 5,
                  strokeColor: "#775DD0",
                },
              ],
            },
            {
              x: "2013",
              y: 5423,
              goals: [
                {
                  name: "Expected",
                  value: 5200,
                  strokeHeight: 5,
                  strokeColor: "#775DD0",
                },
              ],
            },
            {
              x: "2014",
              y: 6653,
              goals: [
                {
                  name: "Expected",
                  value: 6500,
                  strokeHeight: 5,
                  strokeColor: "#775DD0",
                },
              ],
            },
            {
              x: "2015",
              y: 8133,
              goals: [
                {
                  name: "Expected",
                  value: 6600,
                  strokeHeight: 13,
                  strokeWidth: 0,
                  strokeLineCap: "round",
                  strokeColor: "#775DD0",
                },
              ],
            },
            {
              x: "2016",
              y: 7132,
              goals: [
                {
                  name: "Expected",
                  value: 7500,
                  strokeHeight: 5,
                  strokeColor: "#775DD0",
                },
              ],
            },
            {
              x: "2017",
              y: 7332,
              goals: [
                {
                  name: "Expected",
                  value: 8700,
                  strokeHeight: 5,
                  strokeColor: "#775DD0",
                },
              ],
            },
            {
              x: "2018",
              y: 6553,
              goals: [
                {
                  name: "Expected",
                  value: 7300,
                  strokeHeight: 2,
                  strokeDashArray: 2,
                  strokeColor: "#775DD0",
                },
              ],
            },
          ],
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            columnWidth: "60%",
          },
        },
        colors: ["#00E396"],
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: true,
          showForSingleSeries: true,
          customLegendItems: ["Actual", "Expected"],
          markers: {
            fillColors: ["#00E396", "#775DD0"],
          },
        },
      },
    };
  },
  watch:{
    cardData(){
      this.setContent()
    }
  },
  created(){
    this.getDashBoardCard()
    if(this.cardData){
      this.setContent()
    }
  },
  methods:{
    setContent(){
      let $lt = this.cardData.filter(k=>k.title=='last_20');
      let $nc = this.cardData.filter(k=>k.title=='not_closed');
      let $tp = this.cardData.filter(k=>k.title=='total_percentage');
      this.healthData[0].textConetent = $lt && $lt.length >0 ? $lt[0].total_count + " Orders from Store(s) since Last 20 Day(s)" : "No Orders from  Store(s) since Last 20 Day(s)";
      this.healthData[1].textConetent = $nc && $nc.length >0 ? $nc[0].total_count + " Orders are not yet closed in marketplaces" : "0 Orders are not yet closed"
      this.healthData[2].textConetent = $tp && $tp.length >0 ? Math.round($tp[0].total_count) + "% of Your products have no orders since 20 Day(s)" : "0 % of Your products have no orders since 20 Day(s)"
    },
    async getDashBoardCard(){
      let res = await DashBoardService.getCardData()
      console.log(res,'resulte')
    }
  }
};
</script>
<style lang="scss">
.backroundimg {
  background-image: url("../assets/image/backroundimg1.svg");
  height: 206px;
}

.backroundimg1 {
  background-image: url("../assets/image/backroundimg2.svg");
  height: 170px;
}

.img-text {
  max-width: 245px;
  font-size: 12px;
}
</style>