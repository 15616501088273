<template>
    <v-card>
        <v-col align="center" class="pb-0">
            <v-img src="https://s3.ap-south-1.amazonaws.com/xtend3.0/icons/xtend/logoutscreen.svg" width="65" />
        </v-col>
        <v-card-title class="text-center">
            Are you sure you want to logout ?</v-card-title>
        <v-card-action align="center" class="mb-5 mt-3">
            <v-btn @click="close">Cancel</v-btn>
            <v-btn class="ml-2" color="indigo" @click="LogOut()">Logout</v-btn>
        </v-card-action>
    </v-card>
</template>
<script>
// import { authStore } from "@/store";
export default {
    data() {
        return {
            userName: localStorage.getItem("_CUSER_"),
        }
    },
    methods: {
        LogOut() {
            localStorage.removeItem("XTE_TOKEN");
            localStorage.removeItem("_TPMIT_");
            localStorage.removeItem("_ACCOUNT_ID_")
            this.$store.commit("setSignIn", false);
            this.$router.push("/logout");
        },
        close() {
            this.$emit('close')
        }
    }
}
</script>