<template>
    <v-card style="border-radius: 8px">
  
      <v-card-title class="dialog-Title" >{{ viewType ? 'View' : condition ? 'Edit' : 'Add'}} Condition
              <v-btn
                density="comfortable"
                @click="setFalse"
                elevation="0"
                icon="mdi-close"
                style="float: right;"
              ></v-btn>
            </v-card-title>
            <v-divider></v-divider>
  
      <v-container class="mt-8 mb-8">
          <!-- Input Field -->
  
          <v-col>
            <AutoComplete
                :items="listModels"
                :placeholder="'List Model'"
                :disabled="showReadOnly"
                :text="'name'"
                :value="'id'"
                :data="condition.list_model_id"
                @textData="getModelDetails($event)"
              />
          </v-col>
  
          <!-- Input Field -->
          <v-col>
            <AutoComplete
                :items="columns"
                :placeholder="'Column Name'"
                :disabled="showReadOnly"
                :text="'column_name'"
                :value="'column_name'"
                :data="condition.column_name"
                @textData="($event) => {condition.column_name= $event;}"
              />
          </v-col>
          <v-col>
            <AutoComplete
                :items="operators"
                :placeholder="'Operators'"
                :disabled="showReadOnly"
                :text="'name'"
                :value="'id'"
                :data="condition.operator"
                @textData="($event) => {condition.operator = $event;}"
              />
          </v-col>

          <v-col>
          <TextFieldComponent
            density="compact"
            placeholder="Value"
            :data="condition.value"
            @textData="($event) => {condition.value = $event;}"
            :disabled="showReadOnly"
          />
        </v-col>
  
          <!-- Cancle And Save Button -->
      </v-container>
      <v-card-actions class="dialogfooter" v-if="!viewType">
              <v-spacer></v-spacer>
              <v-btn class="mr-1 cancel"  @click="setFalse">Cancel</v-btn>
              <v-btn  class="save" type="submit" @click="save">Save</v-btn>
            </v-card-actions>
    </v-card>
  </template>
  <script>
  import TextFieldComponent from "@/components/InputBox.vue";
  import AutoComplete from "@/components/AutoComplete.vue";
  import ListModelService from "@/services/admin/ListModelService";
  import ListModelDetailService from "@/services/admin/ListModelDetailService";
  export default {
    props: ["id","viewType","modelData"],
    components: {
      TextFieldComponent,
      AutoComplete
    },
  
    data() {
      return {
        showReadOnly: false,
        categoryData: [],
        listModels:[],
        columns:[],
        operators:[
            {
                id:'contains',
                name:'Contains'
            },
            {
                id:'equals',
                name:'Equals'
            },
            {
                id:'greaterthan',
                name:'Greater Than'
            },
            {
                id:'greaterthanorequal',
                name:'Greater Than On Equal'
            },
            {
                id:'in',
                name:'In'
            },
            {
                id:'lessthan',
                name:'Less Than'
            },
            {
                id:'lessthanorequal',
                name:'Less Than On Equal'
            },
            {
                id:'notin',
                name:'Not In'
            }
        ],
        codeRule: [(value) => !!value || "Code is Required"],
        nameRule: [(value) => !!value || "Name is Required"],
        dialog: false,
        type: null,
  
        condition: {},
      };
    },
    watch:{
      modelData(){
        this.condition = this.modelData;
      }
    },
    created() {
      this.getModels()
      if(this.modelData){
        this.condition = this.modelData;
        if(this.condition.list_model_id){
            this.getModelDetails(this.condition.list_model_id)
        }
      }
    },
    methods: {
      async getModels(){
        let $res = await ListModelService.findAll({});
        this.listModels = $res && $res.length > 0 ? $res : []; 
      },
      async getModelDetails($id){
        this.condition.list_model_id = $id
        let $res = await ListModelDetailService.findAll({model_id:$id});
        this.columns = $res && $res.length > 0 ? $res : []; 
      },
      setFalse() {
        this.$emit("closeDialog");
      },
      async save() {
        this.$emit("savedData",this.condition);
      },
    },
  };
  </script>
  