<template>
  <v-card>
    <v-form @Submit.prevent="save" ref="form">
      <v-card-title class="dialog-Title">
        {{
          this.type == "add"
            ? "Add"
            : this.type == "edit"
            ? "Edit"
            : this.type == "view"
            ? "View"
            : ""
        }}
        Template
        <v-btn
          density="comfortable"
          @click="$router.go(-1)"
          elevation="0"
          icon="mdi-close"
          style="float: right"
        >
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col :cols="9">
              <v-row>
                <v-col cols="6" class="pa-0">
                  <InputBox
                    :label="'Code'"
                    :data="templateData.code"
                    @textData="checkCode($event)"
                    :disabled="showReadOnly"
                    @keydowninput="prevent"
                  />
                  <span v-if="codeError" class="error-field"
                    >Already Exists!</span
                  >
                </v-col>
                <v-col cols="6" class="pa-0 pl-2 pr-2">
                  <InputBox
                    :label="'Name'"
                    :data="templateData.name"
                    @textData="
                      ($event) => {
                        templateData.name = $event;
                      }
                    "
                    :disabled="showReadOnly"
                  />
                </v-col>
                <v-col cols="6" class="pa-0">
                  <AutoComplete
                    :data="templateData.channel_id"
                    @textData="
                      ($event) => {
                        templateData.channel_id = $event;
                      }
                    "
                    :items="channels"
                    :text="'name'"
                    :value="'id'"
                    :disabled="showReadOnly"
                    label="From"
                  ></AutoComplete>
                </v-col>
                <v-col cols="6" class="pa-0 pl-2 pr-2">
                  <AutoComplete
                    :data="templateData.domain"
                    @textData="
                      ($event) => {
                        templateData.domain = $event;
                      }
                    "
                    :items="domains"
                    :text="'display_value'"
                    :value="'data_value'"
                    :disabled="showReadOnly"
                    label="Domain"
                  ></AutoComplete>
                </v-col>
                <v-col cols="12" class="pa-0 pr-2">
                  <QuilEditor
                    :showReadOnly="showReadOnly"
                    :data="templateData.to_address"
                    :lable="'To'"
                    :editorStyle="'height:200px;overflow: auto'"
                    @textData="
                      ($event) => {
                        templateData.to_address = $event;
                      }
                    "
                  ></QuilEditor>
                </v-col>
                <v-col cols="12" class="pa-0 pr-2" v-if="showSubject">
                  <QuilEditor
                    :showReadOnly="showReadOnly"
                    :data="templateData.message"
                    :lable="'Cc Mail Address'"
                    :editorStyle="'height:200px;overflow: auto'"
                    @textData="
                      ($event) => {
                        templateData.message = $event;
                      }
                    "
                  ></QuilEditor>
                </v-col>
                <v-col cols="12" class="pa-0 pr-2" v-if="showSubject">
                  <QuilEditor
                    :showReadOnly="showReadOnly"
                    :data="templateData.subject"
                    :lable="'Subject'"
                    :editorStyle="'height:200px;overflow: auto'"
                    @textData="
                      ($event) => {
                        templateData.subject = $event;
                      }
                    "
                  ></QuilEditor>
                </v-col>
                <!-- <v-col cols="12" class="pa-3">
                  <QuilEditor
                    :data="templateData.template"
                    :lable="'Template'"
                    :editorStyle="'height:200px;overflow: auto'"
                    @textData="
                      ($event) => {
                        templateData.template = $event;
                      }
                    "
                  ></QuilEditor>
                </v-col> -->
              </v-row>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col :cols="3">
              <v-row>
                <v-col class="pa-0 pl-2">
                  <AutoComplete
                    :data="templateData.model_id"
                    @textData="
                      ($event) => {
                        templateData.model_id = $event;
                      }
                    "
                    :items="models"
                    :text="'name'"
                    :value="'id'"
                    :disabled="showReadOnly"
                    label="Model"
                  ></AutoComplete>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="dialogfooter pa-5" v-if="!showReadOnly">
        <v-spacer> </v-spacer>
        <v-btn class="mr-1 cancel" @click="closeDialog">Cancel</v-btn>
        <v-btn class="save" type="submit">Save</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
import MessagingTemplateService from "@/services/admin/MessagingTemplateService.js";
import ListModelService from "@/services/admin/ListModelService.js";
import ChannelService from "@/services/admin/ChannelService.js";
import QuilEditor from "@/components/QuilEditor.vue";
import EnumService from "../../services/admin/EnumService";
export default {
  components: {
    QuilEditor,
  },
  data() {
    return {
      codeError: false,
      codeExistFlag: false,
      templateData: {
        id: null,
        channel_id: null,
        model_id: null,
        domain: null,
        type: null,
        to_address: null,
        template: null,
        code: null,
        name: null,
        message: null,
        subject: null,
      },
      models: [],
      channels: [],
      showSubject: false,
      showReadOnly: false,
      domains: [],
    };
  },
  created() {
    this.getModels();
    this.getChannels();
    this.getDomains();
    if (this.$route.path.indexOf("email") > -1) {
      this.showSubject = true;
    }
    this.showReadOnly =
      this.$route.query.type && this.$route.query.type == "view" ? true : false;
    this.type =
      this.$route.path.indexOf("notification") > -1
        ? "notification"
        : this.$route.path.indexOf("sms") > -1
        ? "sms"
        : this.$route.path.indexOf("whatsapp") > -1
        ? "whatsapp"
        : "email";
    if (this.$route.query.id) {
      this.getTemplate(this.$route.query.id);
    }
  },
  methods: {
    async getTemplate($id) {
      let $data = await MessagingTemplateService.findOne({ id: $id });
      this.templateData =
        $data && $data.length > 0 ? $data[0] : this.templateData;
    },
    async getModels() {
      let $data = await ListModelService.findAll({});
      this.models = $data ? $data : [];
    },
    async getDomains() {
      let $data = await EnumService.findAll({});
      this.domains = $data ? $data : [];
    },
    async getChannels() {
      let $data = await ChannelService.findAll({});
      this.channels = $data ? $data : [];
    },
    prevent(e) {
      if (!/^[a-zA-Z0-9-_ \t]+$/.test(e.key) && e.key !== "Backspace") {
        e.preventDefault();
      }
    },
    checkCode($event) {
      this.templateData.code = $event;
      if (this.templateData.code && this.templateData.code.length > 0) {
        this.templateData.code = this.templateData.code.toUpperCase();

        MessagingTemplateService.findAll({ code: this.templateData.code }).then(
          (res) => {
            let $exist = res.filter(
              (key) => key.code == this.templateData.code
            );

            this.codeExistFlag =
              $exist.length > 0
                ? this.templateData.id && $exist[0].id == this.templateData.id
                  ? false
                  : true
                : false;

            if (this.codeExistFlag) {
              this.codeError = true;
            } else {
              this.codeError = false;
            }
          }
        );
      }
    },
    async save() {
      let validation = await this.$refs.form.validate();
      if (!validation.valid || this.codeError) {
        console.log("Not valid");
      } else {
        let $obj = Object.assign({}, this.templateData);
        $obj.type = this.type;
        if ($obj && $obj.id) {
          await MessagingTemplateService.save($obj);
          this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
          this.$router.go(-1);
        } else {
          try {
            await MessagingTemplateService.create($obj);
            this.$store.dispatch("setSnackbar", { text: "Saved Successfully" });
            this.$router.go(-1);
          } catch (e) {
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "Something Went Wrong",
            });
          }
        }
      }
    },
  },
};
</script>