<template>
    <!-- <v-card-text class="fontstyle">
      <v-row no-gutters dense>
        <v-col lg="7" xl="7" md="7"  cols="12" class="splittxt" style="padding-left:30px;"> -->
          <v-row no-gutters dense>
            <v-col
              xl="12"
              lg="12"
              md="12"
              sm="12"
              cols="12"
              class="text-left"
              style="margin-top: 40px"
            >''
              <strong class="ml-5 txt" style="font-size: 45px; font-weight: 400"
                >About Us</strong
              ><br />
            <iframe class="screenwidth" style="margin-top: 2%;
    margin-left: 25%; width:50%" :height="$mq=='mobile' ? '': 480 " src="https://www.youtube.com/embed/gkQD5jI_mP4" 
            title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </v-col>
          </v-row>
</template>
           <!-- <v-col cols="1" class="dottxt" style="padding-top: 90px">
              <div>
                <span class="dot" onclick="currentSlide(1)"></span><br />
                <span class="dot " onclick="currentSlide(2)"></span><br />
                <span class="dot active" onclick="currentSlide(3)"></span> <br />
                  <span class="dot" onclick="currentSlide(4)"></span><br />
                <span class="dot" onclick="currentSlide(5)"></span>
            
              </div>
            </v-col>
            <v-col
              xl="11"
              lg="11"
              md="11"
              sm="11"
              cols="11"
              class="scrolltxt"
              style="padding-top: 80px; padding-left: 40px"
            >
               <strong class="ml-2" style="font-size: 30px;line-height:40px;font-weight: 400;">Lorem ipsum dolor sit amet consectetur <br/> adipisicing elit, sed do eiusmod tempor<br/>incididunt Ut labore et.</strong><br/> -->

              <!--<div class="slidingVertical">
                <span
                  >Lorem ipsum dolor sit amet consectetur <br />
                  adipisicing elit, sed do eiusmod tempor<br />incididunt Ut
                  labore et.</span
                >
                <span
                  >Lorem ipsum dolor sit amet consectetur <br />
                  adipisicing elit, sed do eiusmod tempor<br />incididunt Ut
                  labore et.</span
                >
                <span
                  >Lorem ipsum dolor sit amet consectetur <br />
                  adipisicing elit, sed do eiusmod tempor<br />incididunt Ut
                  labore et.</span
                >
                 <span
                  >Lorem ipsum dolor sit amet consectetur <br />
                  adipisicing elit, sed do eiusmod tempor<br />incididunt Ut
                  labore et.</span
                >
                <span
                  >Lorem ipsum dolor sit amet consectetur <br />
                  adipisicing elit, sed do eiusmod tempor<br />incididunt Ut
                  labore et.</span
                >
               
              </div>
            </v-col>
            <v-col
              xl="12"
              lg="12"
              md="12"
              sm="12"
              cols="12"
              class="endtxt"
              style="padding-top: 70px; padding-left: 40px;"
            >
              <span class="ml-2" style="font-size: 19px"
                >Straight Curly.
                <span style="font-size: 19px; color: blue"
                  >Subscribe electric</span
                ></span
              ><br />
            </v-col>
          </v-row>
        </v-col>
        <v-col lg="5" xl="5" md="5"  cols="12" class="mt-5 splitimg">
          <v-row no-gutters dense>
            <div>
              <v-img :src="require('@/assets/client.svg')" style="width:100% !important;" class="splitimg"/>
            </div>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
</template>-->
<script>
export default {
  data() {
    return {};
  },

  created: function () {},
  methods: {},
};
</script>

<style scoped>
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: rgb(112, 112, 161);
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active,
.dot:hover {
  background-color: rgb(14, 11, 211);
}
.fontstyle {
  font-family: 'Nunito' !important;
}

.slidingVertical {
  display: inline;
  text-indent: 8px;
  font-size: 30px;
  line-height: 40px;
  font-weight: 400;
}
.slidingVertical span {
  animation: topToBottom 12.5s linear infinite 0s;
  -ms-animation: topToBottom 12.5s linear infinite 0s;
  -webkit-animation: topToBottom 12.5s linear infinite 0s;
  opacity: 0;
  overflow: hidden;
  position: absolute;
}
.slidingVertical span:nth-child(2) {
  animation-delay: 2.5s;
  -ms-animation-delay: 2.5s;
  -webkit-animation-delay: 2.5s;
}
.slidingVertical span:nth-child(3) {
  animation-delay: 5s;
  -ms-animation-delay: 5s;
  -webkit-animation-delay: 5s;
}
.slidingVertical span:nth-child(4) {
  animation-delay: 7.5s;
  -ms-animation-delay: 7.5s;
  -webkit-animation-delay: 7.5s;
}
.slidingVertical span:nth-child(5) {
  animation-delay: 10s;
  -ms-animation-delay: 10s;
  -webkit-animation-delay: 10s;
}

/*topToBottom Animation*/
@-moz-keyframes topToBottom {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 0;
    -moz-transform: translateY(-50px);
  }
  10% {
    opacity: 1;
    -moz-transform: translateY(0px);
  }
  25% {
    opacity: 1;
    -moz-transform: translateY(0px);
  }
  30% {
    opacity: 0;
    -moz-transform: translateY(50px);
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes topToBottom {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 0;
    -webkit-transform: translateY(-50px);
  }
  10% {
    opacity: 1;
    -webkit-transform: translateY(0px);
  }
  25% {
    opacity: 1;
    -webkit-transform: translateY(0px);
  }
  30% {
    opacity: 0;
    -webkit-transform: translateY(50px);
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-ms-keyframes topToBottom {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 0;
    -ms-transform: translateY(-50px);
  }
  10% {
    opacity: 1;
    -ms-transform: translateY(0px);
  }
  25% {
    opacity: 1;
    -ms-transform: translateY(0px);
  }
  30% {
    opacity: 0;
    -ms-transform: translateY(50px);
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@media (max-width:480px) {
    .slidingVertical {
  font-size: 20px !important;
  line-height: 30px !important;
}
.txt{
    font-size: 33px !important;
}
.scrolltxt{
    padding-top:40px !important
}
.dottxt{
    padding-top:60px !important;
}
.endtxt{
padding-left: 0px !important
}
.v-responsive__content{
    width:100vw !important;
}
.screenwidth{
  width:80% !important;
  margin-left:30px !important;
}
}
@media only screen and (min-width:480px) and (max-width: 600px)  {
 .slidingVertical {
  font-size: 20px !important;
  line-height: 30px !important;
}
.txt{
    font-size: 33px !important;
}
.scrolltxt{
    padding-top:65px !important;
    padding-left:0px !important;
}
.dottxt{
    padding-top:60px !important;
}
.endtxt{
  padding-top:55px !important;
padding-left: 0px !important;
}
.v-responsive__content{
    width:100vw !important;
}
}
@media only screen and (min-width:600px) and (max-width: 850px){
 .slidingVertical {
  font-size: 20px !important;
  line-height: 30px !important;
}
.txt{
    font-size: 33px !important;
}
.scrolltxt{
    padding-top:65px !important;
padding-left: 20px !important;
}
.dottxt{
    padding-top:60px !important;
}
.endtxt{
  padding-top:55px !important;
}
.v-responsive__content{
    width:100vw !important;
}
.screenwidth{
  height:250px !important;
  width:60% !important;
  margin-left:20% !important;
}
}
@media only screen and (min-width:850px) and (max-width: 970px){
  .slidingVertical {
  font-size: 20px !important;
  line-height: 30px !important;
}
.txt{
    font-size: 33px !important;
}
.scrolltxt{
    padding-top:65px !important;
padding-left: 20px !important;
}

.dottxt{
    padding-top:60px !important;
}
.splitimg img{
  margin-left: 30px;
} 
.endtxt{
  padding-top:55px !important;
}
 .splittxt{
 flex:0 0 43%;float: left;
  }.splitimg{flex:0 0 40%;float: left;}
  
.v-responsive__content{
    width:100vw !important;
}
}
@media only screen and (min-width:970px) and (max-width: 1200px){
 .slidingVertical {
  font-size: 20px !important;
  line-height: 30px !important;
}
.txt{
    font-size: 33px !important;
}
.scrolltxt{
    padding-top:70px !important
}
.dottxt{
    padding-top:60px !important;
}
.endtxt{
padding-left: 0px !important;
}
.v-responsive__content{
    width:100vw !important;
} 
}
</style>