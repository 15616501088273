<template>
    <v-container>
     <v-form @submit.prevent="save" ref="form">
        <v-card elevation="0">
            <v-card-title  class="banner">
                <div class="mt-3 title">ADD NEW COMPANY
                <v-btn class="btn" density="comfortable" @click="this.$router.go(-1)" elevation="0" icon="mdi-close" style="float: right"></v-btn>
                </div>           
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
            <v-row>
             <v-col cols="4" class="image">
                <v-img  size="100px" cover  src="https://s3.ap-south-1.amazonaws.com/xtend3.0/icons/xtend/Xtend1.svg"></v-img>
                <!-- <div class=""> <v-file-input multiple label="File input" hide-input><v-btn density="compact" icon="mdi-plus" elevation="0"> </v-btn></v-file-input></div> -->
            </v-col>
            <v-col cols="8">
                <v-row class="pt-6">
                <v-col cols="6">
                    <InputBox :label="'Company Name'"  :data="formData.name" @textData="($event) => {formData.name = $event;}" :disabled="showReadonly" :rules="nameRules"/>
                </v-col>
                <v-col cols="6">
                    <InputBox :label="'Site Name'" :data="formData.portal_id" @textData="($event) => {formData.portal_id = $event;}" :disabled="showReadonly" :rules="siteRules"/>
                </v-col>
                <v-col cols="12">
                    <InputBox :label="'First Line of Address'" :data="formData.address1" @textData="($event) => {formData.address1 = $event;}" :disabled="showReadonly" :rules="addressRules"/>
                </v-col>
                <v-col cols="12">
                    <InputBox :label="'Second Line of Address'" :data="formData.address2" @textData="($event) => {formData.address2 = $event;}" :disabled="showReadonly"/>
                </v-col>
                <v-col cols="6">
                    <InputBox :label="'City'" :data="formData.city" @textData="($event) => {formData.city = $event;}" :disabled="showReadonly" :rules="cityRules"/>
                </v-col>
                <v-col cols="6">
                    <InputBox :label="'Zip'" :data="formData.zip_code" @textData="($event) => {formData.zip_code = $event;}" :disabled="showReadonly" :rules="zipRules"/>
                </v-col>
                <v-col cols="6">
                    <AutoComplete :data="formData.country" @textData="countrySelect($event)" :items="contries" :text="'name'" :value="'id'" :disabled="showReadOnly"
                       label="Country" :rules="countryRules"/>
                </v-col>
                <v-col cols="6">
                   <AutoComplete :data="formData.state" @textData="($event) => {formData.state = $event;}" :items="displacyStates" :text="'name'" :value="'id'"
                       :disabled="showReadOnly" label="State" @click="selectState" :rules="stateRules"></AutoComplete>
                </v-col>
              </v-row>
            </v-col>
            </v-row>
            </v-card-text>
            <v-card-actions class="dialogfooter" v-if="!showReadOnly">
               <v-spacer></v-spacer>
               <v-btn class="mr-1 cancel" @click="closeDialog">Cancel</v-btn>
               <v-btn class="save" type="submit">Save</v-btn>
            </v-card-actions>
        </v-card>
     </v-form>
    </v-container>
</template>
<script>
import Country from "@/api/service/Countries.json";
import States from "@/api/service/States.json";
import CompanyService from '../../../services/admin/CompanyService';
import CompanyXrefService from "@/services/admin/CompanyXrefService";

export default{
    components:{

    },
    data(){
        return{
            nameRules: [(value) => !!value || "Company Name Is Required"],
            siteRules: [(value) => !!value || "Site Name Is Required"],
            cityRules: [(value) => !!value || "City Is Required"],
            stateRules: [(value) => !!value || "State Is Required"],
            countryRules: [(value) => !!value || "Country Is Required"],
            zipRules: [(value) => !!value || "Zip Is Required"],
            addressRules: [(value) => !!value || "First Line of Address Is Required"],
            formData:{},
            stateData: States,
            displacyStates: [],
            contries: Country,
        }
    },
    methods:{
    async save(){
        let validation = await this.$refs.form.validate()
      if (!validation.valid || this.codeError) {
        console.log("Not valid")
      }
      else{
       this.formData.code = this.formData.name
       let res = await CompanyService.create(this.formData)
       if(res.data.data.id){
         let userData = {
            company_id : res.data.data.id,
            account_id : JSON.stringify([localStorage.getItem('_ACCOUNT_ID_')]),
            user_id : JSON.parse(localStorage.getItem('userDetails')).id,
         }
        await CompanyXrefService.create(userData).then(
            this.formData = {}
        )
       }
      }
    },
    countrySelect($event){
      this.formData.country = $event;
      this.formData.state = null;
      this.displacyStates = this.stateData[this.formData.country]
    },
    selectState(){
      this.displacyStates = this.stateData[this.formData.country]
    },
    }
}

</script>
<style scoped>
.image{
    justify-content: center;
    align-content: center;
    align-items: center;
    display: flex;
}
.banner{
  background-image: linear-gradient(
    to right,
    rgba(18, 88, 171, 0.1),
    rgb(18, 88, 171)
  );
  height: 80px;
}
.title{
    color: #0D0D67;
}
.btn{
    /* background-color: rgb(18, 88, 171); */
    background: none;
    color: white;
}

</style>

