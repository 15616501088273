<template>
  <v-card>
    <v-form @Submit.prevent="save" ref="form">
      <v-card-title class="dialog-Title">
        {{
          type == "edit"
            ? "Edit"
            : type == "view"
            ? "View"
            : type == "add"
            ? "Add"
            : type == "select"
            ? "View"
            : ""
        }}
        Threshold
        <v-btn
          density="comfortable"
          @click="closeDialog"
          elevation="0"
          icon="mdi-close"
          style="float: right"
        >
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="6" class="pa-0">
              <AutoComplete
                :data="threshold.category_id"
                @textData="categorySelect($event)"
                :items="categories"
                :text="'name'"
                :value="'id'"
                :disabled="showReadOnly"
                :rules="categoryRule"
                :label="'Category'"
              ></AutoComplete>
            </v-col>
            <v-col cols="6" class="pa-0 pl-2">
              <AutoComplete
                :data="threshold.product_id"
                @textData="($event) => {threshold.product_id = $event;}"
                :items="products"
                :text="'name'"
                :rules="productRule"
                :value="'id'"
                :disabled="showReadOnly"
                :label="'Product'"
              ></AutoComplete>
            </v-col>
            <v-col cols="6" class="pa-0">
              <InputBox
                :data="threshold.min_value"
                @textData="checkMinmum($event)"
                @keypress="onlyNumberMinmum($event)"
                :label="'Min Qty'"
                :rules="minRule"
                :type="'text'"
                :disabled="showReadOnly"
              ></InputBox>
              <span class="maxmunNmunbeError" v-if="showError">Min Qty must be less than the Max Qty</span>
            </v-col>
            <v-col cols="6" class="pa-0 pl-2">
              <InputBox
                :data="threshold.max_value"
                @textData="checkMaxmum($event)"
                @keypress="onlyNumberMaxmum($event)"
                :label="'Max Qty'"
                :disabled="showReadOnly"
                :rules="maxRule"
                :type="'text'"
              ></InputBox>
            </v-col>
            <v-col cols="6" class="pa-0">
              <AutoComplete
               :data="threshold.fulfillment_id"
                @textData="($event) => {threshold.fulfillment_id = $event;}"
                :items="fulfillments"
                :multiple="true"
                :rules="fulfillmentRule"
                :text="'name'"
                :value="'id'"
                :disabled="showReadOnly"
                :label="'FulFillment'"
              ></AutoComplete>
            </v-col>
            <v-col cols="6" class="pa-0 pl-2">
              <InputBox
                :data="threshold.formula"
                @textData="($event) => {threshold.formula = $event;}"
                :label="'Formula'"
                :disabled="showReadOnly"
                :icon="'mdi-calculator-variant-outline'"
                @clickAppend="expressionModal = true"
              ></InputBox>
            </v-col>
            <!-- <v-col cols="2" >
                <v-icon class="me-2" style="margin-top: 10px;margin-left: 20px;" @click="expressionModal=true">
                    mdi-pencil
                </v-icon>
            </v-col> -->
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="dialogfooter pa-5" v-if="!showReadOnly">
        <v-spacer> </v-spacer>
        <v-btn class="mr-1 cancel" @click="closeDialog">Cancel</v-btn>
        <v-btn class="save" type="submit">Save</v-btn>
      </v-card-actions>
    </v-form>
    <v-dialog v-model="expressionModal" width="600px" persistent transition="dialog-bottom-transition"
            scrollable>
      <ExpressionBuilder @applyExpression="assignExpression" @closeDialog="expressionModal=false" :formula="threshold.formula" :columns="columns" />
    </v-dialog>
  </v-card>
</template>
<script>
import ExpressionBuilder from "@/components/ExpressionBuilder.vue";
import ProductService from "@/services/catalogs/ProductService";
import CategoryService from "@/services/catalogs/CategoryService";
import FulfillmentService from "@/services/catalogs/FulfillmentService";
export default {
  components: {
    ExpressionBuilder,
  },
  props: ["editData",'type'],
  data() {
    return {
      expressionModal:false,
      showError:false,
      categories :[],
      fulfillments:[],
      products: [],
      columns:[
        {key:"sku",label:"SKU"},
        {key:"style",label:"Style"},
        {key:"upc",label:"UPC"}],
      threshold: {
        category_id: null,
        product_id: null,
        fulfillment_id:null,
        min_value: null,
        max_value:null,
        formula: null,
      },
      categoryRule: [(value) => !!value || "Category is Required"],
      productRule: [(value) => !!value || "Product is Required"],
      minRule: [(value) => !!value || "Min Qty is Required"],
      maxRule: [(value) => !!value || "Max Qty is Required"],
      fulfillmentRule: [(value) => !!value || "Fulfillment is Required"],
    };
  },
  created() {
    this.getCategory()
    this.getFullfilment()
    if (this.editData && this.type != 'add') {
      this.threshold = this.editData;
      this.threshold.min_value = this.editData.min_value
        ? this.editData.min_value
        : null;
      this.threshold.max_value = this.editData.max_value
        ? this.editData.max_value
        : null;
    }
  },
  // mounted(){
  //   this.getCategory()
  //   this.getFullfilment()
  //   this.getProducts()
  // },
  methods: {
    onlyNumberMinmum(evt){
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();
      if (!/^[+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    onlyNumberMaxmum(evt){
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();
      if (!/^[+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    checkMinmum($value){
      this.threshold.min_value = $value
      if(parseInt(this.threshold.max_value) <= parseInt(this.threshold.min_value)){
      this.showError = true
     }else{
      this.showError = false
     }
    },
    checkMaxmum($value){
      this.threshold.max_value = $value
      if(parseInt(this.threshold.min_value) >= parseInt(this.threshold.max_value)){
      this.showError = true
     }else{
      this.showError = false
     }
    },
    async getCategory(){
       let $res = await CategoryService.findAll()
       this.categories = $res&&$res.length>0 ? $res : [];
    },
    async getProducts($event) {
      let $res = await ProductService.productstyle({category_id: $event})
        this.products = $res&&$res.length>0 ? $res : [];
        console.log("producccc",this.products)
    },
    async getFullfilment(){
       let $res = await FulfillmentService.findAll()
       this.fulfillments = $res&&$res.length>0 ? $res :[];
    },
    async save() {
      let validation = await this.$refs.form.validate();
      if (!validation.valid || this.showError) {
          console.log(validation)
      }else{
        this.$emit("saveDialog", this.threshold);
      }
    },
    categorySelect($event){
      this.threshold.category_id = $event;
      this.getProducts($event)
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
    assignExpression($data){
      this.threshold.formula = $data;
        this.expressionModal= false
    },
  },
};
</script>
<style>
.maxmunNmunbeError{
    color: #b00020;
    font-size: 12px;
    font-weight: 400;
    position: absolute;
    transform: translate(10px, -20px);
}
</style>