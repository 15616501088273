<template>
    <svg width="206" height="28" viewBox="0 0 206 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.51291 5.47177H16.4565V0H5.51291L0 5.47177V27.3589H5.51291V16.4153H13.7411V10.9435H5.51291V5.47177Z" fill="black"/>
<path d="M37.3973 0H31.9256L26.4538 5.47177V27.3589H31.9256V16.4153H37.3973V27.3589H42.8691V5.47177L37.3973 0ZM37.3973 10.9435H31.9256V5.47177H37.3973V10.9435V10.9435Z" fill="black"/>
<path d="M189.043 0V27.3589H194.556V16.4153H199.987V27.3589H205.5V0H199.987V10.9435H194.556V0H189.043Z" fill="black"/>
<path d="M60.3129 10.9435V5.47177H65.8258V10.9435H71.3387V5.47177L65.8258 0H54.8411V27.3589H60.3129V16.4153H65.8258V27.3589H71.3387V16.4153L65.8258 10.9435H60.3129Z" fill="black"/>
<path d="M88.7414 5.47177H99.726V0H88.7414L83.2696 5.47177V27.3589H88.7414V16.4153H96.9696V10.9435H88.7414V5.47177Z" fill="black"/>
<path d="M152.634 0H136.177V5.47177H141.649V27.3589H147.121V5.47177H152.634V0Z" fill="black"/>
<path d="M114.207 21.8871V16.4153H122.436V10.9435H114.207V5.47177H125.192V0H114.207L108.736 5.47177V21.8871L114.207 27.3589H125.192V21.8871H114.207Z" fill="black"/>
<path d="M168.103 21.8871V5.47177H179.046V0H168.103L162.59 5.47177V21.8871L168.103 27.3589H179.046V21.8871H168.103Z" fill="black"/>
</svg>

</template>