<template>
    <v-card>
        <v-form @submit.prevent="save" ref="form">
        <v-card-title class="dialog-Title" >{{ type=='edit' ? 'Edit': type=='view' ? 'View':type=='add' ? 'Add':type == 'select' ? 'View':''}} UOM
              <v-btn
                density="comfortable"
                @click="closeDialog"
                elevation="0"
                icon="mdi-close"
                style="float: right;"
              ></v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12"  class="pa-2">
                            <InputBox :label="'UOM'" :data="formData.uom" @textData="($event) => {formData.uom = $event;}" :rules="uomRules"/>
                        </v-col>
                        <v-col cols="12"  class="pa-2">
                            <InputBox :label="'Factor'" :data="formData.factor" @textData="($event) => {formData.factor = $event;}" :rules="factorRules"/>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions class="dialogfooter">
                <v-spacer></v-spacer>
              <v-btn class="mr-1 cancel"  @click="closeDialog()">Cancel</v-btn>
              <v-btn  class="save" type="submit">Save</v-btn>
            </v-card-actions>
        </v-form>
    </v-card>
</template>

<script>

export default{
    props:['type'],
     data(){
       return{
        uomRules:[(value) => (!!value) || 'UOM Is Required'],
        factorRules:[(value) => (!!value) || 'Factor Is Required'],
        formData:{}
       } 
     },
     methods:{
        closeDialog(){
      this.$emit("closeDialog")
    },

     }
}
</script>