<template>
    <div>
        <Public v-if="publicUser" />
        <Home v-if="!publicUser" />
    </div>
</template>
<script>
import Public from '@/components/public/main.vue'
import Home from '@/DashBoard/DashBoardContent.vue';
export default {
    components: {
        Public, Home
    },
    data() {
        return {
            publicUser: localStorage.getItem("XTE_TOKEN") ? false : true
        }
    },
    mounted: function () {

    }
}
</script>