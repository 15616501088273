<template>
  <div class="loadingio-spinner-wedges-may9e1hj04">
    <div class="ldio-grxallb8lv">
      <div>
        <div><div></div></div>
        <div><div></div></div>
        <div><div></div></div>
        <div><div></div></div>
      </div>
    </div>
  </div>
</template>
    <style type="text/css">
@keyframes ldio-grxallb8lv {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ldio-grxallb8lv > div > div {
  transform-origin: 70px 70px;
  animation: ldio-grxallb8lv 3.0303030303030303s linear infinite;
  opacity: 0.8;
}
.ldio-grxallb8lv > div > div > div {
  position: absolute;
  left: 30px;
  top: 30px;
  width: 40px;
  height: 40px;
  border-radius: 70px 0 0 0;
  transform-origin: 100px 100px;
}
.ldio-grxallb8lv > div div:nth-child(1) {
  animation-duration: 0.7575757575757576s;
}
.ldio-grxallb8lv > div div:nth-child(1) > div {
  background: #3c5aaa;
  transform: rotate(0deg);
}
.ldio-grxallb8lv > div div:nth-child(2) {
  animation-duration: 1.0101010101010102s;
}
.ldio-grxallb8lv > div div:nth-child(2) > div {
  background: #3c5aaa;
  transform: rotate(0deg);
}
.ldio-grxallb8lv > div div:nth-child(3) {
  animation-duration: 1.5151515151515151s;
}
.ldio-grxallb8lv > div div:nth-child(3) > div {
  background: #fff;
  transform: rotate(0deg);
}
.ldio-grxallb8lv > div div:nth-child(4) {
  animation-duration: 3.0303030303030303s;
}
.ldio-grxallb8lv > div div:nth-child(4) > div {
  background: #fff;
  transform: rotate(0deg);
}
/* .loadingio-spinner-wedges-may9e1hj04 {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: #ffffff;
} */
.ldio-grxallb8lv {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-grxallb8lv div {
  box-sizing: content-box;
}
/* generated by https://loading.io/ */
</style>