<template>
  <v-card elevation="0">
    <PrimeTable :tableName="'Reports Subscription'" activity="Report" :showTableHeader="true" :headers="column" :tableData="Reportlist" @addDialog="addDialog"
      @rowAction="rowAction($event)" :playButton="'playButton'" :showloader="showloader" :showstop="showstop" :hideView="hideView" :settingIcon="settingIcon" @createReport="createReport" :view="true"     
      :modelName="'reports'" @filterData="applyFilter($event)"></PrimeTable>
  </v-card>
  <v-dialog v-model="confirmation" width="300px" persistent>
    <ConfirmationDialog @cancelled="cancelled()" @deleted="deleted()" :content="confirmationContent"/>
  </v-dialog>
</template>

<script>
import ReportSubscriptionService from './services/ReportSubscriptionService';
import ConfirmationDialog from "@/components/DialogConfirmation.vue";
export default {
  components:{
    ConfirmationDialog,
  },
  data() {
    return {
      dataId: null,
      Reportlist:[],
      hideView: true,
      showloader: false,
      showstop: false,
      column: [
        { title: 'id', align: 'start', sortable: false, key: 'report_id' },
        { title: 'Name', align: 'start', key: 'name' },
        { title: 'Next Run', align: 'start', key: 'source' },
        { title: 'Owner', align: 'start', key: 'Owner' },
        { title: 'Email To', align: 'start', key: 'email_to' },
        { title: 'Modified', key: 'modified', align: 'start' },
        { title: 'Action', key: 'actions', sortable: false,align: 'center' },
      ],
      confirmation: false,
      delete_id: null,
    }
  },
  created(){
   let $value = {};
   this.getReportSubscriptionData($value);
   this.hideView = false;
   this.settingIcon = true;
  },
  methods: {
    applyFilter($value){
      this.getReportSubscriptionData($value)
    },
    async getReportSubscriptionData($value){
      this.Reportlist = await ReportSubscriptionService.findAll($value);
    },
    async processReport (id){
      let res = await ReportSubscriptionService.getReport({id:id})
      if (res[0].processing == 0){
        this.showloader = false;
        this.playButton = true;
      }
      else if (res[0].processing == 1){
        this.showloader = true;
        this.playButton = false;
      }
    },

    rowAction($event) {
      if ($event.type == "edit") {
        this.dataId = $event.value.id;
        this.$router.push({ path: '/viewreports', query: { id: this.dataId } })
      }
      if ($event.type == "select") {
        this.dataId = $event.value.id;
        this.$router.push({ path: '/viewreports', query: { id: this.dataId ,type: 'view'} })
      }
      else if($event.type == "run"){
        if(this.showloader == false){
        this.dataId = $event.value.id;
        this.showloader = true;
        this.playButton = false;
        this.processReport(this.dataId)
      }
      }
      if ($event.type == "delete") {
        this.dialog=false;
        this.confirmation = true;
        this.confirmationContent = `Do you want to delete the Report Subscription ${$event.value.name}`
        this.delete_id = $event.value.id;
      }
    },
    addDialog(){
      this.$router.push({ path: '/viewreports'})
    },
    createReport(){
      this.$router.push({ path: '/createreport'})
    },
    cancelled() {
      this.confirmation = false;
    },
    async deleted(){
      await ReportSubscriptionService.delete({id: this.delete_id})
      this.confirmation = false;
      this.getReportSubscriptionData()
    }
  }
}
</script>