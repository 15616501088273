<template>
  <v-row class="pl-6">
    <v-col cols="12">
      <Title @Arrowkey="handleArrowKey($event)" :data="orderData"></Title>
      <v-row>
        <v-col cols="9" class="pl-2 pt-6">
          <TitleCard :data="orderData"></TitleCard>
          <v-col class="pt-5" v-if="openOrder">
            <OpenItems :data="orderData"></OpenItems>
          </v-col>
          <v-col class="pt-5" v-else-if="!openOrder">
            <Fulfilled :data="fulfillData"></Fulfilled>
          </v-col>
          <v-col class="pt-5" v-else-if="refundOrder">
            <Refunded :data="refundData"></Refunded>
          </v-col>
        </v-col>

        <v-col cols="3" class="">
          <v-col>
            <BillToAddress :data="orderData.mws_orders"></BillToAddress>
          </v-col>

          <v-col>
            <ShipToAddress :data="orderData.mws_orders"></ShipToAddress>
          </v-col>
          <v-col>
            <PricingDetails :datas="orderData"></PricingDetails>
          </v-col>
        </v-col>
      </v-row>
    </v-col>

  </v-row>
</template>
<script>
import BillToAddress from './BillToAddress.vue';
import ShipToAddress from './ShipToAddress.vue';
import TitleCard from "./TitleCard.vue"
import Title from './Title.vue';
import OpenItems from './OpenItems.vue';
import Fulfilled from './Fulfilled.vue';
import PricingDetails from './PricingDetails.vue';
import Refunded from './Refunded.vue';
import MwsOrderItemService from '@/services/orders/MwsOrderItemService';
import MwsOrderService from '@/services/orders/MwsOrderService';
export default {
  components: {
    BillToAddress,
    ShipToAddress,
    Title,
    TitleCard,
    OpenItems,
    Fulfilled,
    PricingDetails,
    Refunded
  },
  data() {
    return {
      orderData: {
        mws_orders: {}
      },
      fulfillData: {},
      marketPlace: {},
      refundData: {},
      openOrder: true,
      refundOrder:false
    }
  },


  created() {
    document.addEventListener('keydown', this.handleArrowKey);
    this.getOrderItem();
  },
  updated() {
    this.getOrderItem();
  },
  beforeUnmount() {
    this.destroyed()
  },
  methods: {
    async getOrderItem() {
      try {
        this.order_id = this.$route.query.id
        if (this.order_id) {
          let res = await MwsOrderItemService.findAll({ order_id: this.order_id })
          this.orderData = res[0];
          if (this.orderData.stage == 'C') {
            this.openOrder = false
            this.refundOrder = false
          }else if(this.orderData.stage == 'R'){
            this.refundOrder =true
          }
          this.fulfillData = res.filter(data => data.tracking_num )
          let $market = await MwsOrderService.findAll({ id: this.order_id })
          this.marketPlace = $market[0].mws_headers.name
        }
      } catch (err) {
        console.log(err)
      }
    },

    async handleArrowKey(event) {
      if (event.key === 'ArrowRight') {
        let res = await MwsOrderService.getNext({ order_id: this.order_id })
        if (res[0].next_order != null) {
          this.$router.replace({ path: '/ordersView', query: { id: res[0].next_order, store: this.marketPlace } })
        }
      }
      if (event.key === 'ArrowLeft') {
        let res = await MwsOrderService.getPrevious({ order_id: this.order_id })
        if (res[0].previous_order != null) {
          this.$router.replace({ path: '/ordersView', query: { id: res[0].previous_order, store: this.marketPlace } })
        }
      }
    },
    destroyed() {
      document.removeEventListener('keydown', this.handleArrowKey);
    },
  }
}
</script>
<style></style>