<template>
    <!-- <v-breadcrumbs :items="breadCrumbs" class="pb-0" v-if="breadCrumbs">
        <template v-slot:title="{ item }">
            <v-breadcrumbs-item :to="item.to" :disabled="item.disabled" exact
                :style="item.bold ? 'font-weight:800' : 'font-weight:400'">
                <span :style="`color: ${item.color}!important`"> {{ item.text }}</span>
            </v-breadcrumbs-item>
        </template>
    </v-breadcrumbs> -->
    <v-row class="mt-0 ml-0 bredcraubheader">
        <span v-for="(item,index) in breadCrumbs" :key="item">
        <div style="cursor: pointer;margin-left: 10px;font-size:13px" :style="`color: ${item.color}!important`" @click="goTo(item)">{{ item.text }}
        <span v-if="index!=breadCrumbs.length-1" style="color: black !important;font-size:13px">/</span></div>
    </span>
    </v-row>
</template>

<script>

export default {
    data() {
        return {

        }
    },
    computed: {
        breadCrumbs() {
            if (typeof this.$route.meta.breadCrumb === "function") {
                return this.$route.meta.breadCrumb.call(this, this.$route);
            }
            return this.$route.meta.breadCrumb;
        },
    },
    watch: {

    },

    created() {

        console.log(this.breadCrumbs, 'djghhjg')
    },
    methods: {
        async refreshSection() {

        },
        goTo($obj){
            this.$router.push($obj.to)
        }
    }
}
</script>