<template>
  <v-card flat class="rounded-lg" :class="{ dimensionCard: activeOn }">
    <v-form @Submit.prevent="save" ref="form">
      <v-card-title>Dimensions & Pack</v-card-title>
      <v-card-text>
        <v-row class="pt-6">
          <v-col cols="12" class="pa-0 px-2"
            ><InputBox
              :data="formData.length"
              @textData="productDimension($event)"
              @keydowninput="prevent"
              :label="'Length'"
          /></v-col>
          <v-col cols="12" class="pa-0 px-2"
            ><InputBox
            :data="formData.width"
              @textData="productDimension1($event)"
              @keydowninput="prevent"
              :label="'Width'"
          /></v-col>
          <v-col cols="12" class="pa-0 px-2"
            ><InputBox
            :data="formData.height"
              @textData="productDimension2($event)"
              @keydowninput="prevent"
              :label="'Height'"
          /></v-col>
          <v-col cols="12" class="pa-0 px-2"
            ><InputBox
            :data="formData.volume"
              @textData="productDimension3($event)"
              @keydowninput="prevent"
              :label="'Volume'"
          /></v-col>
          <v-col cols="12" class="pa-0 px-2"
            ><InputBox
            :data="formData.pack"
              @textData="productDimension4($event)"
              @keydowninput="prevent"
              :label="'Pack'"
          /></v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="dialogfooter" v-if="!showReadOnly && styleIdData">
        <v-spacer></v-spacer>
        <v-btn class="mr-1 cancel" @click="closeDialog">Cancel</v-btn>
        <v-btn class="save" type="submit">Save</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
  
  <script>
import InputBox from "@/components/InputBox.vue";
import StyleService from "@/services/catalogs/StyleService";
export default {
  props: ["productData", "styleIdData"],
  components: {
    InputBox,
  },
  data() {
    return {
      activeOn: true,
      formData: {
        length: null,
        width: null,
        height: null,
        volume: null,
        pack: null,
      },
    };
  },
  watch: {
    productData() {
      this.formData = this.productData;
    },
  },
  created() {
    if (this.styleIdData) {
      this.activeOn = false;
      this.getStyleData(this.styleIdData);
    }
    if (this.productData) {
      this.formData = this.productData;
    }
  },
  methods: {
    prevent(e) {
        if (!/^[0-9-_ \t]+$/.test(e.key) && e.key !== "Backspace") {
          e.preventDefault();
        }
},
    async getStyleData(id) {
      let res = await StyleService.findOne({ id: id });
      this.formData = res[0];
      console.log(this.formData,'this.formData')
    },
    productDimension(value) {
      this.formData.length = value
      this.$emit("productDimension", this.formData);
    },
    productDimension1(value) {
      this.formData.width = value
      this.$emit("productDimension", this.formData);
    },
    productDimension2(value) {
      this.formData.height = value
      this.$emit("productDimension", this.formData);
    },
    productDimension3(value) {
      this.formData.volume = value
      this.$emit("productDimension", this.formData);
    },
    productDimension4(value) {
      this.formData.pack = value
      this.$emit("productDimension", this.formData);
    },
    async save() {
      let validation = await this.$refs.form.validate();
      if (!validation.valid || this.codeError) {
        console.log(validation);
      } else {
        let output = await StyleService.update(this.formData);
        console.log(output);
        this.closeDialog();
      }
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
  },
};
</script>
  
  <style>
.dimensionCard {
  border: 2px solid;
  border-color: #cfcdcd;
}
</style>
 

