<template>
  <v-card flat class="rounded-lg" style="border: 2px solid; border-color: #cfcdcd">
    <v-card-title>Price Per Region</v-card-title>
    <v-card-text class="pt-4">
      <v-form ref="form">
        <v-row class="pl-2">
          <v-col cols="4" class="bgColor font-weight-bold pl-4" style="border-radius: 10px 0px 0px 10px">Region</v-col>
          <v-col cols="2" class="bgColor font-weight-bold pl-4">Price</v-col>
          <v-col cols="2" class="bgColor font-weight-bold pl-4">Compare at Price</v-col>
          <v-col cols="2" class="bgColor font-weight-bold pl-4" style="border-radius: 0px 10px 10px 0px">Currency</v-col>
        </v-row>
        <v-row v-for="(qualify, index) in qualifiers" :key="index" class="mt-6">
          <v-col cols="4" class="pa-0 pl-2">
            <AutoComplete :items="countryData" :text="'name'" :value="'id'" :data="qualify.region_id"
              :placeholder="'Region'" @textData="pricingRegionData($event)" :disabled="showReadOnly"
              :rules="countryRule" />
          </v-col>
          <v-col cols="2" class="pa-0 pl-2">
            <InputBox :data="qualify.price" @textData="pricingRegionData1($event)" :placeholder="'Price'"
              @keydowninput="prevent" :disabled="showReadOnly" :rules="priceRule" />
          </v-col>
          <v-col cols="2" class="pa-0 pl-2">
            <InputBox :data="qualify.comparate_price" @textData="pricingRegionData2($event)" :disabled="showReadOnly"
              :placeholder="'Compare at Price'" :rules="comPriceRule" />
          </v-col>
          <v-col cols="2" class="pa-0 pl-2">
            <EnumDropdown :data="qualify.currency" @textData="pricingRegionData3($event)" :domainName="'currencies'"
              :disabled="showReadOnly" :placeholder="'Currency'" />
            <!-- <InputBox
            :data="qualify.currency"
              @textData="pricingRegionData($event)"
              :placeholder="'Currency'"
             :disabled="showReadOnly"
              :rules="currencyRule" /> -->
          </v-col>
          <v-col :cols="2" class="pa-0 pl-2 pt-2" v-if="!showReadOnly">
            <v-btn variant="outlined" color="primary" @click="addRule()" density="compact" icon="mdi-plus"></v-btn>
            <v-btn variant="outlined" color="primary" class="ml-2" @click="deleteRule(index)" v-if="index > 0"
              density="compact" icon="mdi-delete"></v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>


<script>
import InputBox from "@/components/InputBox.vue";
import AutoComplete from "@/components/AutoComplete.vue";
import Country from "@/api/service/Countries.json";

export default {
  props: ["showReadOnly", 'productData', 'pricePerRegionData'],
  components: {
    InputBox,
    AutoComplete,
  },
  data() {
    return {
      countryRule: [(value) => (!!value) || 'Region Is Required'],
      priceRule: [(value) => (!!value) || 'Price Is Required'],
      comPriceRule: [(value) => (!!value) || 'Compare at Price Is Required'],
      currencyRule: [(value) => (!!value) || 'Currency Is Required'],
      countryData: Country,
      qualifiers: [
        {
          id: null,
          product_id: null,
          region_id: null,
          price: null,
          comparate_price: null,
          currency: null,
        },
      ],
      data: [
        { id: 'color', name: 'Color' },
        { id: 'size', name: 'Size' },
        { id: 'material', name: 'Material' },
      ]
    };

  },
  watch: {
    validation() {
      this.save();
    },
    pricePerRegionData() {
      console.log(this.pricePerRegionData, 'pricePerRegionData')
      if (this.pricePerRegionData && this.pricePerRegionData.length > 0) {
        this.qualifiers = this.pricePerRegionData
      }
    },
    productData() {
      this.qualifiers = this.productData && this.productData.price_per_region ? JSON.parse(this.productData.price_per_region) : [
        {
          id: null,
          product_id: null,
          region_id: null,
          price: null,
          comparate_price: null,
          currency: null,
        },
      ]
    }
  },
  created() {
    if (this.productData) {
      this.qualifiers = this.productData && this.productData.price_per_region ? JSON.parse(this.productData.price_per_region) : [
        {
          id: null,
          product_id: null,
          region_id: null,
          price: null,
          comparate_price: null,
          currency: null,
        },
      ]
    }
  },
  methods: {
    prevent(e) {
      if (!/^[0-9-_ \t]+$/.test(e.key) && e.key !== "Backspace") {
        e.preventDefault();
      }
    },
    async addRule() {
      let validation = await this.$refs.form.validate()
      if (!validation.valid || this.codeError) {
        console.log("Not valid")
      }
      else {
        this.qualifiers.push({
          id: null,
          product_id: null,
          region_id: null,
          price: null,
          comparate_price: null,
          currency: null,
        });
      }
    },
    deleteRule($index) {
      this.qualifiers.splice($index, 1);
    },
    pricingRegionData(value) {
      this.qualifiers[0].region_id = value
      this.$emit("pricingRegionData", this.qualifiers);
    },
    pricingRegionData1(value) {
      this.qualifiers[0].price = value
      this.$emit("pricingRegionData", this.qualifiers);
    },
    pricingRegionData2(value) {
      this.qualifiers[0].comparate_price = value
      this.$emit("pricingRegionData", this.qualifiers);
    },
    pricingRegionData3(value) {
      this.qualifiers[0].currency = value
      this.$emit("pricingRegionData", this.qualifiers);
    },
  },
};
</script>

<style>
.bgColor {
  background-color: #d7dcf6;
}
</style>