<template>
  <v-form @submit.prevent="save" ref="form">
    <v-toolbar color="#fff" flat>
      <v-toolbar-title class="settingHeader">Integration</v-toolbar-title>
      <v-btn class="button" v-if="!showReadOnly" type="submit">Save</v-btn>
      <v-btn
        variant="outlined"
        @click="edit"
        v-if="showReadOnly"
        class="usersent-btn"
        prepend-icon="mdi-pencil-outline"
        style="float: right"
        >Edit</v-btn
      >
      <v-btn
        variant="text"
        density="comfortable"
        @click="close"
        elevation="0"
        v-if="!showReadOnly"
        icon="mdi-close"
        style="float: right"
      ></v-btn>
    </v-toolbar>
    <v-divider class="mb-2"></v-divider>

    <v-row class="pa-10">
      <v-col cols="4" class=" pa-0">
        <InputBox
        :data="credentials.api_key"
          @textData="($event) => {credentials.api_key = $event;}"
          :rules="apikeyRule"
          :disabled="showReadOnly"
          :label="'Api Key'"
        ></InputBox>
      </v-col>
      <v-col cols="4"  class="pl-2 pa-0">
        <InputBox
        :data="credentials.shared_secret"
          @textData="($event) => {credentials.shared_secret = $event;}"
          :rules="sharedRule"
          :disabled="showReadOnly"
          :label="'Shared'"
        ></InputBox>
      </v-col>
      <v-col cols="4" class="pl-2 pa-0">
        <InputBox
        :data="credentials.password"
          @textData="($event) => {credentials.password = $event;}"
          :disabled="showReadOnly"
          :label="'Password'"
        ></InputBox>
      </v-col>
      <v-col cols="4" class="pa-0">
        <InputBox
        :data="credentials.portal_owner_id"
          @textData="($event) => {credentials.portal_owner_id = $event;}"
          :disabled="showReadOnly"
          :label="'Portal Login Id'"
        ></InputBox>
      </v-col>
      <v-col cols="4" class="pl-2 pa-0">
        <InputBox
          :data="credentials.web_password"
          @textData="($event) => {credentials.web_password = $event;}"
          v-model="credentials.web_password"
          :disabled="showReadOnly"
          :label="'Portal Password'"
        ></InputBox>
      </v-col>
      <v-col cols="4" class="pl-2 pa-0">
        <InputBox
        :data="credentials.license_key"
          @textData="($event) => {credentials.license_key = $event;}"
          :disabled="showReadOnly"
          :label="'Subscription key'"
        ></InputBox>
      </v-col>
      <v-col cols="12" class="pa-0">
        <InputBox
        :data="credentials.base_url"
          @textData="($event) => {credentials.base_url = $event;}"
          type="url"
          :disabled="showReadOnly"
          :rules="baseurl"
          :label="'Base Url'"
        ></InputBox>
      </v-col>
      <v-col cols="12" class=" pa-0">
        <InputBox
        :data="credentials.host_name"
          @textData="($event) => {credentials.host_name = $event;}"
          :disabled="showReadOnly"
          :rules="host"
          :label="'Host Name'"
        ></InputBox>
      </v-col>

      <v-col cols="4" class="pa-0">
        <EnumDropdown :data="credentials.currency" @textData="($event)=>{ credentials.currency = ($event) }" :domainName="'currencies'"
              :disabled="showReadOnly" :label="'Currency'" />
      </v-col>
      <!-- <v-col cols="12">
        <v-switch
          v-model="showCustomerField"
          color="#3C5AAA"
          label="Default Customer based on the following rule"
        >
        </v-switch>
      </v-col>
      <v-row v-if="showCustomerField">
        <v-col cols="3">
          <InputBox :label="'Customer #'"></InputBox>
        </v-col>
        <v-col cols="3">
          <InputBox :label="'Field'"></InputBox>
        </v-col>
        <v-col cols="3">
          <InputBox :label="'Operand'"></InputBox>
        </v-col>
        <v-col cols="3">
          <InputBox :label="'Value'"></InputBox>
        </v-col>
      </v-row> -->
    </v-row>
  </v-form>
</template>
<script>
import MwsHeaderService from "@/services/mws/MwsHeaderService";
export default {
  components: {},
  data() {
    return {
      showReadOnly: true,
      showCustomerField: false,
      mwsId: localStorage.getItem("mwsHeaderId"),
      mwsHeader: {
        id: null,
      },
      currencies: [],
      credentials: {
        api_key: null,
        shared_secret: null,
        password: null,
        portal_owner_id: null,
        web_password: null,
        license_key: null,
        base_url: null,
        host_name: null,
        currency: null,
      },
      apikeyRule: [(value) => !!value || "Apikey is Required"],
      sharedRule: [(value) => !!value || "Shared is Required"],
      baseurl: [
        (value) => {
          if (value) return true;

          return "URL is requred.";
        },
        (value) => {
          if (/^(https?|ftp):/.test(value)) return true;

          return "URL must be valid.";
        },
      ],
      host: [(value) => !!value || "Host Name is Required"],
    };
  },
  created() {
    this.getMws();
  },
  methods: {
    async save() {
      let validation = await this.$refs.form.validate();
      if (!validation.valid) {
        console.log(validation);
      } else {
        if (this.mwsHeader && this.mwsHeader.id) {
          let $obj = Object.assign({}, this.credentials);
          this.mwsHeader.credentials = JSON.stringify($obj);
          await MwsHeaderService.update(this.mwsHeader);
          this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
          this.showReadOnly = true;
        } else {
          try {
            await MwsHeaderService.create(this.mwsHeader);
            this.$store.dispatch("setSnackbar", { text: "Saved Successfully" });
            this.showReadOnly = true;
          } catch (e) {
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "Something Went Wrong",
            });
          }
        }
      }
    },
    async getMws() {
      let $result = await MwsHeaderService.findAll({ id: this.mwsId });
      $result = $result[0];
      if ($result) {
        let $obj = {};
        $obj.id = $result.id;
        $obj.credentials =
          $result.credentials && typeof $result.credentials == "string"
            ? JSON.parse($result.credentials)
            : {};
        this.mwsHeader = $obj;
        this.credentials = this.mwsHeader.credentials;
      }
    },
    close() {
      this.showReadOnly = true;
    },
    edit() {
      this.showReadOnly = false;
    },
  },
};
</script>
<style scoped></style>