<template>
    <v-card>
        <v-form @submit.prevent="save" ref="form">
            <v-card-title class="dialog-Title">
                Data Set
                <v-btn density="comfortable" @click="closeDialog" elevation="0" icon="mdi-close"
                    style="float: right"></v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-title>
                <v-row class="">
                    <v-col cols="12" class="pa-0 pl-2 pr-2">
                        <InputBox :label="'Name'" :rules="name" :data="modelData.name"
                            @textData="($event) => { modelData.name = $event; }">
                        </InputBox>
                    </v-col>
                </v-row>
                <v-row>

                    <v-col cols="6" class="pa-0 pl-2">
                        <EnumDropdown :data="modelData.database_name" @textData="checkDatabase($event)"
                            :disabled="showReadOnly" :rules="databaseRules" :label="'DataBase'" :domainName="'databases'" />

                    </v-col>
                    <v-col cols="6" class="pa-0 pl-2 pr-2">
                        <AutoComplete :data="modelData.model" @textData="($event) => { modelData.model = $event; }"
                            :rules="source" :text="'table_name'" :disabled="showReadOnly" :label="'Source'"
                            :items="allTables" :value="'table_name'" />
                    </v-col>
                </v-row>
                <v-row v-if="typeData == 'Report'">

                    <span v-if="showInvalid" style="color: #b00020;margin-left: 15px;">Already Exist</span>
                </v-row>

            </v-card-title>

            <v-card-actions align="end" class="dialogfooter">
                <v-spacer></v-spacer>
                <v-btn class="mr-2 cancel" @click="clear()">Clear</v-btn>
                <v-btn class="save" type="submit">Save</v-btn>
                <!-- <v-btn class="save" type="submit" :disabled="showonly">Save</v-btn> -->
            </v-card-actions>
        </v-form>
    </v-card>
</template>
<script>
import ListModelService from "@/services/admin/ListModelService";
import EnumService from '@/services/admin/EnumService'

export default {
    data() {
        return {
            modelData: {
                model: null,
                database_name: null,
            },
            showInvalid: false,
            allTables: [],
            name: [(value) => !!value || "Name Is Required"],
            type: [(value) => !!value || "Type Is Required"],
            source: [(value) => !!value || "source Is Required"],
            databaseRules: [(value) => !!value || "DataBase Is Required"],
        }
    },
    methods: {
        closeDialog() {
            this.$emit("closeDialog")
        },
        checkDatabase($value) {
            this.modelData.model = null
            this.modelData.database_name = $value
            this.getTables($value)
        },
        async getTables($value) {
            let $data = await ListModelService.getTables({ database: $value });
            let $rptData = []
            $data.filter(e => {
                if (e.table_name.startsWith("mws")) {
                    $rptData.push(e)
                }
            })
            this.allTables = $rptData;
        },
        async save() {
            this.showInvalid = false;
            let validation = await this.$refs.form.validate();
            if (validation.valid) {
                let data = await ListModelService.findAll({ database_name: this.modelData.database_name, model: this.modelData.model })
                if (data && data.length > 0) {
                    this.showInvalid = true
                    this.$store.dispatch("setSnackbar", { text: "Already Exist" });
                }
                else {
                    if (this.modelData && this.modelData.id) {
                        await ListModelService.update(this.modelData);
                        this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
                        this.$emit("closeDialog")
                    } else {
                        await ListModelService.createListModels(this.modelData);
                        var EnumData = { domain: 'reports',data_value: this.modelData.model, display_value: this.modelData.name, }
                        await EnumService.create(EnumData)
                        this.$store.dispatch("setSnackbar", { text: "Saved Successfully" });
                        this.$emit("closeDialog")
                    }
                }
            }
        },
        clear() {
            this.modelData = {};
        }
    }
}
</script>