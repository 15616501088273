<template>
  <v-card width="100%" style="border-radius: 12px; overflow: hidden">
    <v-form @submit.prevent="save" ref="form">
        <v-card-title class="dialog-Title">{{ viewType == "edit" ? "Edit" : viewType == "view" ? "View" : "Add" }}
          Activity Group
          <v-btn @click="close()" elevation="0" density="comfortable" icon="mdi-close" style="float: right;"></v-btn>
        </v-card-title>
        <v-divider></v-divider>
      <v-card-text>
        <v-row no-gutters class="pa-2">
          <v-col cols="4">
            <InputBox density="compact" label="Code" placeholder="Code" :rules="codeRule" :data="formData.code"
              :disabled="showReadOnly" @textData="checkCode($event)" @keydowninput="prevent($event)" />
            <v-error v-if="codeError" class="error-field">Already Exists!</v-error>
          </v-col>

          <!-- Input Field -->

          <v-col cols="12">
            <InputBox density="compact" placeholder="Name" label="Name" :rules="nameRule" :data="formData.name"
              :disabled="showReadOnly" @textData="($event) => { formData.name = $event; }" />
          </v-col>
          <v-col cols="12">
            <AutoComplete :items="modules" label="Module" :data="formData.module_id" placeholder="Select Module" 
              density="compact" variant="outlined" text="name" value="id" :rules="moduleRule" :disabled="true"
              @textData="moduleChanged($event)" />
          </v-col>

        </v-row>
      </v-card-text>
      <v-card-actions class="dialogfooter" v-if="!showReadonly">
        <v-spacer></v-spacer>
        <v-btn class="mr-1 cancel" @click="dialog = close()">Cancel</v-btn>
        <v-btn class="save" type="submit">Save</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
import AutoComplete from "@/components/AutoComplete.vue";
import InputBox from "@/components/InputBox.vue";
import ActivityGroupService from '../../services/admin/ActivityGroupService';
// import EventService from "../../services/admin/EventService";
import ModuleService from '../../services/admin/ModuleService';
export default {
  props: ["editID", "viewType", "moduleId"],
  components: {
    InputBox,
    AutoComplete
  },
  // watch: {
  //   editID(){
  //     alert('hi')
  //     if(this.editID) {
  //       console.log(this.editID,'rubrsh')
  //     }
  //   },
  // },

  data() {
    return {
      codeError: null,
      showReadOnly: false,
      dialog: false,
      type: null,
      modules: [],
      codeRule: [(value) => !!value || "Code is Required."],
      nameRule: [(value) => !!value || "Name is Required."],
      activityRule: [(value) => !!value || "Activity is Required."],
      moduleRule: [(value) => !!value || "Module is Required."],
      selectActivityId: [],
      backupActivity: [],
      ActivityDetails: [],
      selectActivity: [],
      formData: {
        id: null,
        code: null,
        name: null,
        module_id: this.moduleId
      },
      chips: [],
    };
  },
  created() {

    this.getModule();
    this.formData.module_id = this.moduleId
    if (this.id) {
      this.getById(this.id);
    }
    if (this.viewType) {
      this.showReadOnly = this.viewType == "edit" ? false : true;
    }
    this.editiD();

  },
  methods: {
    async getModule() {
      this.modules = await ModuleService.findAll({ active: 1, "id": this.moduleId });
    },
    prevent(e) {
      if (e !== null) {
        if (!/^[a-zA-Z0-9-_ \t]+$/.test(e.key) && e.key !== "Backspace") {
          e.preventDefault();
        }
      }
    },
    checkCode($value) {
      this.formData.code = $value
      this.formData.code = this.formData && this.formData.code ? this.formData.code.toUpperCase() : '';
      ActivityGroupService.findAll({ code: this.formData.code,module_id:this.moduleId }).then((res) => {
        if (res.length > 0) {
          this.codeError = true;
        } else {
          this.codeError = false;
        }
      });
    },

    editiD() {
      if (this.editID) {
        this.getById(this.id);
      }
    },
    moduleChanged($value){
      this.formData.module_id  = $value
    },
    close() {
      this.$emit("closeDialog");
    },

    async save() {
      if (this.formData.name && this.formData.code && !this.codeError) {
        if (this.formData.id) {
          await ActivityGroupService.update(this.formData);
          this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
          this.dialog = false;
          this.close();
          this.$emit("reload", true);
          this.formData = {};
        } else {
          try {
            let $data = await ActivityGroupService.create(this.formData);
            this.$store.dispatch("setSnackbar", { text: "Saved Successfully" });
            this.dialog = false;
            this.close();
            this.$emit("reload", $data.data.data);
            this.formData = {};
          } catch (e) {
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "Something Went Wrong",
            });
          }
        }
      }
    },
  },
};
</script>
<style>
.selectedChip {
  background-color: #fae2ee;
  color: #ffffff;
  margin-right: 5px;
  margin-top: 5px;
  color: #333333;
}
</style>