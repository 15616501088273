<template>
  <v-card>
    <v-form @Submit.prevent="save" ref="form">
      <v-card-title class="dialog-Title">
        {{
          type == "edit"
            ? "Edit"
            : type == "view"
            ? "View"
            : type == "add"
            ? "Add"
            : type == "select"
            ? "View"
            : ""
        }}
        Attribute
        <v-btn
          density="comfortable"
          @click="closeDialog"
          elevation="0"
          icon="mdi-close"
          style="float: right"
        >
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="6" class="pa-0">
              <InputBox
              :data="formData.code"
              @textData="checkCode($event)"
                :label="'Code'"
                :rules="codeRule"
                :disabled="showReadOnly"
                @keydowninput="prevent"
              />
              <span v-if="codeError" class="error-field">Already Exists!</span>
            </v-col>
            <v-col cols="6" class="pa-0 pl-2">
              <InputBox
              :data="formData.name"
              @textData="($event) => {formData.name = $event;}"
                :label="'Name'"
                :rules="nameRule"
                :disabled="showReadOnly"
              />
            </v-col>
            <v-col cols="6" class="pa-0">
              <AutoComplete :label="'Data Type'" :items="data_types" :text="'name'" :value="'code'" :disabled="showReadOnly" 
              :data="formData.data_type"
              @textData="($event) => {formData.data_type = $event;}" :rules="dataType"/>
            </v-col>

            <v-col cols="6" class="pa-0 pl-2">
             <EnumDropdown :data="formData.field_type" @textData="($event)=>{ formData.field_type = ($event) }" :domainName="'fieldtypes'"
              :disabled="showReadOnly" :label="'Field Type'" :rules="fieldType" />
            </v-col>
            <v-col cols="3" class="pa-0" v-if="formData.field_type=='select' || formData.field_type=='autocomplete'">
              <EnumDropdown :data="formData.database_name" @textData="checkDatabase($event)" :domainName="'databases'"
              :disabled="showReadOnly" :label="'Data Base'" :rules="dataBase" />
            </v-col>
            <v-col cols="3" class="pa-0 pl-2" v-if="formData.field_type=='select' || formData.field_type=='autocomplete' || formData.field_type=='enum'">
              <AutoComplete 
              :data="formData.source"
              @textData="($event) => {formData.source = $event;}"
              :rules="source"
              :disabled="showReadOnly" :label="'Source'" :items="formData.field_type=='select' || formData.field_type=='autocomplete' ? allTables:allEnums" :text="formData.field_type=='select' || formData.field_type=='autocomplete' ? 'table_name' : 'display_value'" :value="formData.field_type=='select' || formData.field_type=='autocomplete' ? 'table_name' : 'data_value'" />
            </v-col>
            <v-col cols="3" class="pa-0 pl-2" v-if="formData.field_type=='select' || formData.field_type=='autocomplete' || formData.field_type=='enum'">
              <InputBox
              :data="formData.data_value"
              @textData="($event) => {formData.data_value = $event;}"
              :disabled="showReadOnly" :label="'Value'" :rules="value" />
            </v-col>
            <v-col cols="3" class="pa-0 pl-2" v-if="formData.field_type=='select' || formData.field_type=='autocomplete' || formData.field_type=='enum'">
              <InputBox
              :data="formData.display_value"
              @textData="($event) => {formData.display_value = $event;}"
              :disabled="showReadOnly" :label="'Text'" :rules="text" />
            </v-col>
            <v-col cols="3" class="pa-0 pl-2" v-if="formData.field_type=='switch' || formData.field_type=='checkbox'">
              <InputBox 
              :data="formData.data_value"
              @textData="($event) => {formData.data_value = $event;}"
              :disabled="showReadOnly" :label="'On Value'" :rules="onValue" />
            </v-col>
            <v-col cols="3" class="pa-0 pl-2" v-if="formData.field_type=='switch' || formData.field_type=='checkbox'">
              <InputBox 
              :data="formData.display_value"
              @textData="($event) => {formData.display_value = $event;}"
              :disabled="showReadOnly" :label="'Off Value'" :rules="offValue" />
            </v-col>
            <v-col cols="6" class="pa-0 pl-2" v-if="formData.field_type=='radio'">
              <InputBox 
              :data="formData.options"
              @textData="($event) => {formData.options = $event;}"
              :disabled="showReadOnly" :label="'Options'" :rules="options"/>
            </v-col>
            <v-col cols="3" class="pa-0">
              <InputBox 
              :data="formData.sequence"
              @textData="($event) => {formData.sequence = $event;}"
              :disabled="showReadOnly" :label="'Sequence'" :rules="sequence" />
            </v-col>
            <v-col cols="12" class="pa-2">
              <!-- <TextArea :label="'Description'" v-model="formData.description" :disabled="showReadOnly"/> -->
              <QuilEditor
                :data="formData.description"
                :editorStyle="'height:100px;overflow: auto'"
                :lable="'Description'"
                :showReadOnly="showReadOnly"
                @textData="
                  ($event) => {
                    formData.description = $event;
                  }
                "
                :rules="description"
              ></QuilEditor>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="dialogfooter" v-if="!showReadOnly">
        <v-spacer> </v-spacer>
        <v-btn class="mr-1 cancel" @click="closeDialog">Cancel</v-btn>
        <v-btn class="save" type="submit">Save</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import AttributeService from "@/services/catalogs/AttributeService";
import QuilEditor from "@/components/QuilEditor.vue";
import EnumService from '@/services/admin/EnumService';
import ListModelService from '@/services/admin/ListModelService';
export default {
  props: ["type", "id"],
  components: {
    QuilEditor,
  },
  data() {
    return {
      types: [
        { id: "varchar", name: "VARCHAR" },
        { id: "boolean", name: "BOOLEAN" },
        { id: "timestamp", name: "TIMESTAMP" },
        { id: "bigint", name: "BIGINT" },
      ],
      codeError:false,
      codeExistFlag:false,
      codeRule: [(value)=> !! value ||"Code is Required"],
      nameRule: [(value) => !!value || "Name is Required"],
      dataType: [(value) => !!value || "DataType is Required"],
      fieldType: [(value) => !!value || "FieldType is Required"],
      source: [(value) => !!value || "Source is Required"],
      dataBase: [(value) => !!value || "DataBase is Required"],
      text: [(value) => !!value || "Text is Required"],
      value: [(value) => !!value || "Value is Required"],
      options: [(value) => !!value || "Options is Required"],
      sequence: [(value) => !!value || "Sequence is Required"],
      formData: {
        name: null,
        type: null,
        data_value: null,
        description: null,
        code:null,
        source_type:null,
        source:null,
        display_value:null,
        sequence:null,
        field_type:null,
        database_name:null,
        data_type:null,
        options:null
      },
      data_types:[
        {code:"integer",name:"Integer"},
        {code:"boolean",name:"Boolean"},
        {code:"string",name:"String"},
        {code:'varchar',name:'Varchar'},
        {code:"float",name:"Float"},
        {code:"date",name:"Date"},
      ],
      field_types:[],
      allEnums:[],
      allTables:[],
      dataBases:[]
    };
  },
  async created() {
    if (this.type == "view" || this.type == "select") {
      this.showReadOnly = true;
    }
    if (this.id) {
      let res = await AttributeService.findOne({ id: this.id });
      this.formData = res[0];
    }
    // this.getFileds();
    this.getEnums();
    // this.getTables()
    // this.getDbs()
  },
  methods: {
    async getEnums(){
     let $data =  await EnumService.findAll({});
     this.allEnums = $data && $data.length >0 ? $data : []
    },
    checkDatabase($value){
      this.formData.source = null
      this.formData.database_name = $value
      this.getTables($value)
    },
    async getTables($value) {
      let $data = await ListModelService.getTables({database:$value});
      this.allTables = $data && $data.length > 0 ? $data : [];
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
    prevent(e) {
  if (!/^[a-zA-Z0-9-_ \t]+$/.test(e.key) && e.key !== "Backspace") { 
    e.preventDefault();
  }
},
checkCode($event) {
  this.formData.code =$event 

      if (this.formData.code && this.formData.code.length > 0) {
        this.formData.code = this.formData.code.toUpperCase();

        AttributeService.findAll({ code: this.formData.code }).then((res) => {
          let $exist = res.filter((key) => key.code == this.formData.code);

          this.codeExistFlag =
            $exist.length > 0
              ? this.formData.id && $exist[0].id == this.formData.id
                ? false
                : true
              : false;

          if (this.codeExistFlag) {
            this.codeError = true;
          } else {
            this.codeError = false;
          }
        });
      }
    },

    async save() {
      let validation = await this.$refs.form.validate();
      if (!validation.valid || this.codeError) {
        console.log("Not valid");
      } else {
        if (this.id) {
          delete this.formData.users
          await AttributeService.update(this.formData);
          this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
          this.$emit("closeDialog", true);
          this.$emit("reload", true);
        } else {
          try{
          await AttributeService.create(this.formData);
          this.$store.dispatch('setSnackbar', { text: "Saved Successfully" });
          this.$emit("closeDialog", true);
          this.$emit("reload", true);
          }
          catch (e) {
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "Something Went Wrong",
            });
          }  
        }
      }
    },
  },
};
</script>

<style>
</style>