<template>
  <v-container>
    <div>
      <v-row>
        <v-col cols="8">
          <div fxFlex class="workflowcontainer">
            <div v-for="tree of data" :key="tree.id">
              <div class="hv-container">
                <TreeView
                  ref="childComponent"
                  :node="tree"
                  @nodeClicked="selectNode"
                  @addChild="childAdd"
                ></TreeView>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="4">

          <v-row v-if="showDelete">
            <v-btn color="indigo" @click="deleteNode"> Delete </v-btn>
          </v-row>
          <v-row>
            <v-btn color="indigo" @click="updateDetails"> Update Details </v-btn>
          </v-row>
          <div v-if="(showStart && !flowType) || (showStart && flowType=='start')">
            <v-card>
              <v-form @submit.prevent="submitStart" ref="form">
                <v-col style="border-bottom: 1px solid #e5e5e5">Start</v-col>
                <v-container>
                  <v-card-text class="pb-0 pt-0 pl-0">Status</v-card-text>
                  <v-radio-group v-model="workflow.active" inline>
                    <v-radio label="Active" value="1"></v-radio>
                    <v-radio label="Inactive" value="0"></v-radio>
                  </v-radio-group>
                  <AutoComplete
                    :data="workflow.worklfow_type"
                    @textData="($event) => {workflow.worklfow_type= $event;}"
                    :items="worklfowTypes"
                    :text="'name'"
                    :value="'id'"
                    :disabled="showReadOnly"
                    :rules="workFlowTypeRule"
                    label="Workflow Type"
                  ></AutoComplete>

                  <InputBox
                    :label="'Code'"
                    :data="workflow.code"
                    @textData="($event) => {workflow.code = $event;}"
                    :disabled="showReadOnly"
                    :rules="codeRule"
                  />

                  <InputBox
                    :label="'Name'"
                    :data="workflow.name"
                    @textData="($event) => {workflow.name= $event;}"
                    :disabled="showReadOnly"
                    :rules="nameRule"
                  />
                  <AutoComplete
                    :data="workflow.company_id"
                    @textData="($event) => {workflow.company_id= $event;}"
                    :items="companies"
                    multiple
                    :text="'name'"
                    :value="'id'"
                    :disabled="showReadOnly"
                    :rules="companyRule"
                    label="Company"
                  ></AutoComplete>

                  <AutoComplete
                    :data="workflow.activity_id"
                    @textData="getEvents($event)"
                    :items="activities"
                    :text="'name'"
                    :value="'id'"
                    :disabled="showReadOnly"
                    :rules="activityRule"
                    label="Activity"
                  ></AutoComplete>

                  <AutoComplete
                    :data="workflow.event_id"
                    @textData="($event) => {workflow.event_id= $event;}"
                    :items="events"
                    :text="'name'"
                    :value="'id'"
                    :disabled="showReadOnly"
                    :rules="eventRule"
                    label="Event"
                  ></AutoComplete>
                  <!-- <v-card-text class="pb-0 pt-0 pl-0"
                  >Participant Type</v-card-text
                >
                <v-radio-group >
                  <v-radio label="Role" value="radio-1"></v-radio>
                  <v-radio label="Employee" value="radio-2"></v-radio>
                </v-radio-group> -->
                  <AutoComplete
                    :data="workflow.participants"
                    @textData="($event) => {workflow.participants= $event;}"
                    :items="roles"
                    :text="'name'"
                    :value="'id'"
                    :disabled="showReadOnly"
                    :rules="roleRule"
                    label="Role"
                  ></AutoComplete>
                  <AutoComplete
                    :data="workflow.model_id"
                    @textData="($event) => {workflow.model_id= $event;}"
                    :items="listModels"
                    :text="'name'"
                    :value="'id'"
                    :disabled="showReadOnly"
                    :rules="modelRule"
                    label="Model"
                  ></AutoComplete>
                  <!-- <v-card-text class="pt-0 pl-0">Qualifier</v-card-text>
                <v-text-field
                  placeholder="Rules"
                  density="compact"
                  variant="outlined"
                ></v-text-field> -->
                  <QuilEditor
                    :data="workflow.description"
                    :editorStyle="'height:100px;overflow: auto'"
                    :lable="'Description'"
                    @textData="
                      ($event) => {
                        workflow.description = $event;
                      }
                    "
                  ></QuilEditor>
                </v-container>
                <v-col align="end">
                  <v-btn color="indigo" type="submit"> Save </v-btn>
                </v-col>
              </v-form>
            </v-card>
          </div>
          <!-- <v-row> -->
          <div v-if="!showStart ">
            <AddFlow v-if="!flowType" @selection="setFlow" />
            <v-row v-if="flowType == 'task'">
              <AutoComplete
                :items="taskTypes"
                :placeholder="'Task Types'"
                :disabled="showReadOnly"
                :text="'name'"
                :value="'id'"
                :data="flowData.task_type"
                @textData="($event) => {workflow.model_id= $event;}"
              />
            </v-row>
            <v-row v-if="flowType == 'condition'" style="margin-top:30px">
              <v-tooltip location="top" text="Add">
                <template v-slot:activator="{ props }">
                  <v-sheet v-bind="props" border rounded :height="45" :width="45" class="tableicon" @click="addCondition">
                    <v-icon>mdi-plus</v-icon>
                  </v-sheet>
                </template>
              </v-tooltip>

              <v-row style="margin-top:45px">
                <span v-for="(condition,index) in flowData.entry_rules" :key="condition" style="margin: 10px;">
                  <v-chip @click="editConditionData(condition,index)">
                    {{condition.column_name}} {{condition.operator}} {{condition.value}}
                  </v-chip>
                </span>
              </v-row>

            </v-row>
            <v-col v-if="flowType == 'taskapproval'">
              <!-- <v-row> -->
              <v-row>
                <AutoComplete
                  :items="approverTypes"
                  :placeholder="'Approver Type'"
                  :disabled="showReadOnly"
                  :text="'name'"
                  :value="'id'"
                  :data="flowData.approval_type"
                  @textData="($event) => {flowData.approval_type= $event;}"
                  
                />
              </v-row>
              <v-row v-if="flowData.approval_type">
                <AutoComplete
                  :items="flowData.approval_type == 'role' ? roles:users"
                  :placeholder="
                    flowData.approval_type == 'role'
                      ? 'Role'
                      : flowData.approval_type == 'user'
                      ? 'User'
                      : 'Role'
                  "
                  item-title="name"
                  :disabled="showReadOnly"
                  item-value="id"
                  :data="flowData.task_type"
                  @textData="($event) => {flowData.task_type= $event;}"
                />
              </v-row>
              <v-row>
                <AutoComplete
                  :items="approvalTypes"
                  :placeholder="'Approval Type'"
                  :disabled="showReadOnly"
                  :text="'name'"
                  :value="'id'"
                  :data="flowData.approver_type"
                  @textData="($event) => {flowData.approver_type= $event;}"
                />
              </v-row>
              <v-row>
                <v-checkbox
                  label="Multi Approval Required"
                  @change="setMultiApprovals()"
                  v-model="flowData.multi_approval_required"
                ></v-checkbox>
              </v-row>
              <v-row v-if="flowData.multi_approval_required">
                <AutoComplete
                  :items="multiApprovalPercentage"
                  :placeholder="'Multi Approval Percentage'"
                  :disabled="showReadOnly"
                  :text="'name'"
                  :value="'id'"
                  :data="flowData.multi_approval_percentage"
                  @textData="($event) => {flowData.multi_approval_percentage= $event;}"
                />
              </v-row>
              <v-row>
                <v-checkbox
                  label="Notify To User"
                  v-model="flowData.notify"
                ></v-checkbox>
              </v-row>
              <v-row v-if="flowData.notify">
                <AutoComplete
                  :items="communicationTypes"
                  :placeholder="'Communication By'"
                  :disabled="showReadOnly"
                  :text="'name'"
                  :value="'id'"
                  :data="flowData.type"
                  @textData="getTemplates($event)"
                />
              </v-row>
              <v-row v-if="flowData.notify">
                <AutoComplete
                  :items="templates"
                  :placeholder="'Template'"
                  :disabled="showReadOnly"
                  :text="'name'"
                  :value="'id'"
                  :data="flowData.approver_id"
                  @textData="($event) => {flowData.approver_id= $event;}"
                />
              </v-row>
              <!-- </v-row> -->
            </v-col>
            <v-row v-if="flowData.taskType == 'thirdpartyapi'">
              <AutoComplete
                :items="thirdPartyApiData"
                :placeholder="'Third Party API'"
                :disabled="showReadOnly"
                :text="'name'"
                :value="'id'"
                :data="flowData.approver_id"
                @textData="($event) => {flowData.approver_id= $event;}"
              />
            </v-row>
            <v-row v-if="flowData.task_type == 'communication'">
              <AutoComplete
                :items="communicationTypes"
                :placeholder="'Communication By'"
                :disabled="showReadOnly"
                :text="'name'"
                :value="'id'"
                :data="flowData.approval_type"
                @textData="($event) => {flowData.approval_type= $event;}"
              />
            </v-row>
            <v-row v-if="flowData.task_type == 'communication'">
              <AutoComplete
                :items="templates"
                :placeholder="'Template'"
                :text="'name'"
                :disabled="showReadOnly"
                :value="'id'"
                :data="flowData.approver_id"
                @textData="($event) => {flowData.approver_id= $event;}"
              />
            </v-row>
            <v-row v-if="flowType!='completion' && flowType!='start' && flowType!=null" style="float:right;margin-top:30px">
              <v-btn color="indigo" @click="saveCurrentNode"> Save </v-btn>
            </v-row>
          </div>
          <!-- </v-row> -->
        </v-col>
      </v-row>
    </div>
    <v-dialog persistent v-model="dialog" width="700px">
      <AddCondition :modelData="modelData" @closeDialog="dialog=false" :viewType="showReadOnly" @savedData="setConditionData" />
    </v-dialog>
  </v-container>
</template>
<script>
import TreeView from "./TreeView.vue";
import AddFlow from "./AddFlow.vue";
import AutoComplete from "@/components/AutoComplete.vue";
import AddCondition from "./AddCondition.vue";
import WorkFlowService from "@/services/admin/WorkFlowService";
import QuilEditor from "@/components/QuilEditor.vue";
import CompanyService from "@/services/admin/CompanyService";
import ListModelService from "@/services/admin/ListModelService";
import EventService from "@/services/admin/EventService";
import ActivityService from "@/services/admin/ActivityService";
import RoleService from "@/services/admin/RoleService";
import WfTaskService from "@/services/admin/WfTaskService";
import lodash from "lodash";
import UserService from "@/services/admin/UserService";
import MessagingTemplateService from "@/services/admin/MessagingTemplateService";
export default {
  components: {
    AddFlow,
    TreeView,
    AutoComplete,
    AddCondition,
    QuilEditor,
  },
  data() {
    return {
      data:[],
      modelData:{},
      taskNodes: [],
      workFlowId: null,
      nextProcess: false,
      flowType: null,
      dialog: false,
      showStart: true,
      flowData: {
        multi_approval_required: false,
      },
      showReadOnly: false,
      typeOfApproval: null,
      multiApprovalPercentage: [
        { name: "10%", id: 10 },
        { name: "20%", id: 20 },
        { name: "30%", id: 30 },
        { name: "40%", id: 40 },
        { name: "50%", id: 50 },
        { name: "60%", id: 60 },
        { name: "70%", id: 70 },
        { name: "80%", id: 80 },
        { name: "90%", id: 90 },
        { name: "100%", id: 100 },
      ],
      approvalTypes: [
        {
          id: "consent",
          name: "Consent",
        },
        {
          id: "approval",
          name: "Approval",
        }
      ],
      taskTypes: [
        {
          id: "communication",
          name: "Communication",
        },
        {
          id: "thirdpartyapi",
          name: "Third Party Api",
        }
        // {
        //   id: "customform",
        //   name: "Custom Form",
        // },
        // {
        //   id: "dataupdate",
        //   name: "Data Update",
        // },
      ],
      approverTypes: [
        {
          id: "role",
          name: "Role",
        },
        {
          id: "user",
          name: "User",
        }
      ],
      thirdPartyApiData: [
        {
          id: "first",
          name: "First",
        },
        {
          id: "second",
          name: "Second",
        },
      ],
      communicationTypes: [
        {
          id: "sms",
          name: "SMS",
        },
        {
          id: "email",
          name: "EMAIL",
        },
        {
          id: "whatsapp",
          name: "Whatsapp",
        },
      ],
      templates: [
      ],
      worklfowTypes: [
        {
          id: "approval",
          name: "Apporval",
        },
        {
          id: "api",
          name: "API",
        },
      ],
      workFlowTypeRule: [(value) => !!value || "Workflow Type is Required"],
      codeRule: [(value) => !!value || "Code is Required"],
      nameRule: [(value) => !!value || "Name is Required"],
      activityRule: [(value) => !!value || "Activity is Required"],
      companyRule: [(value) => !!value || "Company is Required"],
      eventRule: [(value) => !!value || "Event is Required"],
      roleRule: [(value) => !!value || "Role is Required"],
      modelRule: [(value) => !!value || "Model is Required"],
      listModels: [],
      companies: [],
      events: [],
      roles: [],
      activities: [],
      workflow: {
        active: "1",
        worklfow_type: "approval",
        code: null,
        name: null,
        company_id: [],
        activity_id: null,
        event_id: null,
        participants: [],
        model_id: null,
        description: null,
      },
      lastChild:{},
      selectedNodeFlow:{},
      showDelete:false,
      showNext:false,
      overallUpdateData:[],
      oldTaskData:[],
      lastSequenceId:0,
      lastObjectOfArray:{},
      selectedConditionIndex:-1,
      users:[]
    };
  },
  computed: {
    nodeSelection() {
      return this.$store.getters.getChildArrayNew;
    },
    selectedNode() {
      return this.$store.getters.getSelectedNode;
    },
  },
  watch:{
    nodeSelection(){
      this.flowType = null;
      this.showDelete = false;
      this.flowData = {};
      this.lastChild = this.nodeSelection.data;
      this.lastSequenceId = this.lastChild.sequence_id;
      // this.saveNext(this.data)
      this.validateBeforeAdd();
    },
    selectedNode(){
      this.showDelete = false;
      this.selectedNodeFlow = this.selectedNode;
      this.setActive(this.data)
      this.validateBeforeDelete(this.selectedNodeFlow.data)
      this.flowData = this.selectedNodeFlow.data.taskTypeData ? this.selectedNodeFlow.data.taskTypeData : {};
      this.flowType = this.selectedNodeFlow.data.name.toLowerCase();
      if(this.flowType=='condition'){
        this.flowData.entry_rules = this.flowData.entry_rules ? JSON.parse(this.flowData.entry_rules) : []
      }
      if((this.flowType=='taskapproval') && this.flowData.type){
        this.getTemplates(this.flowData.type)
      }
      if((this.flowType=='task') && this.flowData.approval_type){
        this.getTemplates(this.flowData.approval_type)
      }
      this.showStart = this.flowType=='start';
    }
  },
  created() {
    this.getRoles();
    this.getCompanies();
    this.getActivities();
    this.getListModels();
    this.getUsers();
    if (this.$route.query.id) {
      this.nextProcess = true;
      this.getById(this.$route.query.id);
      this.getByWorkflowId(this.$route.query.id);
    } else {
      this.loadData();
    }
  },
  methods: {
    addCondition(){
      this.modelData = {};
      this.dialog = true;
    },
    editConditionData($val,$index){
      this.selectedConditionIndex=$index;
      this.modelData = $val;
      this.dialog = true;
    },
    setConditionData($value){
      this.dialog = false;
      if(this.selectedConditionIndex>-1){
        this.flowData.entry_rules[this.selectedConditionIndex]=$value;
      }else{
        this.flowData.entry_rules.push($value);
      }
    },
    async getById($id) {
      let $data = await WorkFlowService.findOne({id:$id});
      if ($data && $data.length>0 ) {
        $data = $data[0]
        this.getEvents($data.activity_id);
        $data.active = $data.active.toString()
      }
      this.workFlowId = $data.id;

      this.data = $data
        ? $data.exit_rules
          ? JSON.parse($data.exit_rules)
          : []
        : [];
      if (this.data.length == 0) {
        this.loadData();
      } else {
        WorkFlowService.currentlySelectedTree = this.data;
      }
      this.workflow = JSON.parse(JSON.stringify($data));
      this.workflow.participants = $data.participants
        ? JSON.parse($data.participants)
        : [];
      this.workflow.company_id = $data.company_id
        ? JSON.parse($data.company_id)
        : [];
      if(this.data.length>0){
        this.data[0].isActive=true;
      }  
    },
    setOldData(){
      this.setUpData(this.data);
    },
    setUpData($data){
      for(let i=0;i<$data.length;i++){
        let $index = this.oldTaskData.findIndex(l=>l.sequence_id==$data[i].sequence_id)
        if($index>-1){
          $data[i].wf_task_id = this.oldTaskData[$index].id;
          $data[i].isValid = $data[i].taskTypeData ? true: $data[i].isValid;
        }else{
          this.setUpData($data[i].children);
        }
      }
    },
    async getByWorkflowId($id) {
      let $data = await WfTaskService.findAll({ work_flow_id: $id, active: 1 });
      if ($data && $data.length > 0) {
        $data = lodash.orderBy($data, ["sequence_id"], ["asc"]);
        this.oldTaskData = $data;
        // WorkFlowService.newId = $data[$data.length - 1].sequence_id + 1;
      }else{
        this.workflow.sequence_id=1;
        this.data.push(this.workflow)
      }
    },
    async getUsers(){
      let $data = await UserService.findAll({});
      this.users = $data&&$data.length>0 ? $data : [];
    },
    async getRoles() {
      let $data = await RoleService.findAll({});
      this.roles = $data&&$data.length>0 ? $data : [];
    },
    async getCompanies() {
      let $data = await CompanyService.findAll({});
      this.companies = $data&&$data.length>0 ? $data : [];
    },
    async getActivities() {
      let $data = await ActivityService.findAll({});
      this.activities = $data&&$data.length>0 ? $data : [];
    },
    async getListModels() {
      let $data = await ListModelService.findAll({});
      this.listModels = $data&&$data.length>0 ? $data : [];
    },
    async getTemplates($type) {
      this.flowData.type = $type
      let $data = await MessagingTemplateService.findAll({type:$type});
      this.templates = $data&&$data.length>0 ? $data : [];
    },
    async getEvents($id) {
      this.workflow.activity_id= $id
      let $data = await EventService.findAll({ activity_id: $id });
      this.events = $data&&$data.length>0 ? $data : [];
    },
    selectNode($val) {
      console.log($val,'selectedNode');
    },
    saveCurrentNode(){
      this.saveNode(this.data);
    },
    saveNode($data){
      for(let i=0;i<$data.length;i++){
        if($data[i].sequence_id==this.selectedNodeFlow.data.sequence_id){
          let $obj = Object.assign({},this.flowData);
          $obj.entry_rules = $obj.entry_rules ? JSON.stringify($obj.entry_rules) : JSON.stringify([])
          $data[i].taskTypeData = $obj;
          $data[i].isValid = true;
        }else{
          this.saveNode($data[i].children);
        }
      }
    },
    setActive($data){
      for(let i=0;i<$data.length;i++){
        if($data[i].sequence_id==this.selectedNodeFlow.data.sequence_id){
          $data[i].isActive = true;
          this.setActive($data[i].children);
        }else{
          $data[i].isActive = false;
          this.setActive($data[i].children);
        }
      }
    },
    saveNext($data){
      for(let i=0;i<$data.length;i++){
        if($data[i].sequence_id==this.lastChild.sequence_id){
          $data[i].next_process = (this.lastChild.name.toLowerCase()=='conditionfailure' || this.lastChild.name.toLowerCase()=='conditionsuccess' || this.lastChild.name.toLowerCase()=='onapproval' || this.lastChild.name.toLowerCase()=='onrejection' ) ? this.lastSequenceId+1: this.lastSequenceId+1;
        }else{
          this.saveNext($data[i].children);
        }
      }
    },
    validateBeforeDelete($data){
      this.showDelete=false;
      if($data.name.toLowerCase()=='condition' || $data.name.toLowerCase()=='taskapproval'){
        this.showDelete = $data.children.every(e=>e.children.length==0);
      }else if($data.name.toLowerCase()=='task'){
        this.showDelete = $data.children.length==0;
      }else if($data.name.toLowerCase()=='start'){
        this.showDelete = $data.children.length==0;
      }else{
        this.showDelete = true;
      }
    },
    validateBeforeAdd(){
      this.delNode(this.data);
      if(!this.showNext && this.lastChild.name.toLowerCase()=='start'){
        this.showNext = true;
      }
      if(this.showNext){
        this.flowType = null;
        this.showDelete = false;
      }else{
        this.flowType = this.lastChild.name.toLowerCase();
      }
    },
    updateDetails(){
      this.overallUpdateData=[];
      this.setUpdateData(this.data);
      this.submitStart();
    },
    setUpdateData($data){
      for(let i=0;i<$data.length;i++){
          if($data[i].children && $data[i].children.length>0){
            this.overallUpdateData.push($data[i])
            this.setUpdateData($data[i].children);
          }else{
            this.overallUpdateData.push($data[i])
          }
      }
    },
    deleteNode(){
      const filterItems = (items, fn) => items.reduce((acc, item) => {
        if(item.name==this.selectedNode.data.name && item.sequence_id==this.selectedNode.data.sequence_id){
          return [...acc];
        }
        else if(fn(item)){
          return [...acc, {...item, children: filterItems(item.children, fn)}];
        }   
        return acc;
      }, []);
    const filtered = filterItems(this.data, item => item.id !== this.selectedNode.data.sequence_id);
    this.data = filtered;
    },
    delNode($data){
      for(let i=0;i<$data.length;i++){
        if($data[i].sequence_id==this.lastChild.parentId){
          console.log($data[i],'$data[i]$data[i]')
          this.showNext = $data[i].taskTypeData && Object.keys($data[i].taskTypeData).length>0 ? true: $data[i].isValid;
          return $data[i].isValid;
        }else{
          this.delNode($data[i].children);
        }
      }
    },
    async addLastChild($data){
      let $values = 1;
      if(this.flowType=='condition' || this.flowType=='taskapproval'){
        $values=2;
      }
      for(let i=0;i<$data.length;i++){
          if($data[i].sequence_id==this.lastChild.sequence_id && $data[i].name==this.lastChild.name){
            if($values==2){
              if(this.flowType=='condition'){
                $data[i].children.push(
                  {
                    sequence_id: this.lastObjectOfArray.sequence_id+2,
                    installid: "ConditionSuccess",
                    name: "ConditionSuccess",
                    taskFlow:'ConditionSuccess',
                    taskTypeData: {},
                    parentId:$data[i].sequence_id,
                    isValid:true,
                    task: 5,
                    children: [],
                    active: true,
                    isActive:false
                  },
                  {
                    sequence_id: this.lastObjectOfArray.sequence_id+3,
                    installid: "ConditionFailure",
                    name: "ConditionFailure",
                    taskFlow:'ConditionFailure',
                    taskTypeData: {},
                    parentId:$data[i].sequence_id,
                    isValid:true,
                    task: 5,
                    children: [],
                    active: true,
                    isActive:false
                }) 
              // this.lastSequenceId  = $data[i].sequence_id+2;
              }else{
                $data[i].children.push({
                  sequence_id: this.lastObjectOfArray.sequence_id+2,
                  installid: "OnApproval",
                  name: "OnApproval",
                  taskFlow:'OnApproval',
                  taskTypeData: {},
                  parentId:$data[i].sequence_id,
                  isValid:true,
                  task: 5,
                  children: [],
                  active: true,
                  isActive:false
                },
                {
                  sequence_id: this.lastObjectOfArray.sequence_id+3,
                  installid: "OnRejection",
                  name: "OnRejection",
                  taskFlow:'OnRejection',
                  taskTypeData: {},
                  parentId:$data[i].sequence_id,
                  isValid:true,
                  task:5,
                  children: [],
                  active: true,
                  isActive:false
                })
                // this.lastSequenceId  = $data[i].sequence_id+2;
              }
              
            }else{
              // if(this.flowType=='end'){
              //   this.lastSequenceId = this.lastSequenceId+4;
              // }
              
              let $obj1 = {
                sequence_id: this.lastObjectOfArray.sequence_id+1,
                installid:this.flowType=='end' ? 'Completion' : 'Task',
                name: this.flowType=='end' ? 'Completion' : 'Task',
                taskFlow:this.flowType=='end' ? 'Completion' : 'Task',
                taskTypeData: {},
                parentId:$data[i].sequence_id,
                isValid:this.flowType=='end' ? true : false,
                task: this.flowType=='end' ? 4 : 3,
                // next_process:this.flowType=='end' ? 0  : $data[i].sequence_id+3,
                children: [],
                active: true,
                isActive:false
              };
              this.selectedNodeFlow.data  = $obj1;
              $data[i].children.push($obj1)
            }
            
          }else{
            this.addLastChild($data[i].children)
          }
      }
    },
    async  addBeforeObject($data){
      for(let i=0;i<$data.length;i++){
          if($data[i].sequence_id==this.lastChild.sequence_id && $data[i].name==this.lastChild.name){
            this.lastSequenceId = this.lastSequenceId+1;
            let $obj = {
              sequence_id: this.lastObjectOfArray.sequence_id+1,
              next_process:this.lastObjectOfArray.sequence_id+2,
              failure_process:this.lastObjectOfArray.sequence_id+3,
              installid: this.flowType == 'taskapproval' ? 'TaskApproval' : "Condition",
              name: this.flowType == 'taskapproval' ? 'TaskApproval' : "Condition",
              taskFlow:this.flowType == 'taskapproval' ? 'TaskApproval' : "Condition",
              taskTypeData: {},
              task: this.flowType == 'taskapproval' ? 6 : 2,
              parentId:$data[i].sequence_id,
              isValid:false,
              children: [],
              active: true,
              isActive:true
            };
              this.lastChild = $obj;
              // this.selectedNodeFlow = $obj;
              this.selectedNodeFlow.data = $obj;
              $data[i].children.push($obj)
          }else{
           this.addBeforeObject($data[i].children)
          }
      }
      
    },
    async childAdd($val) {
      if($val.value==0){
        let validation = true//await this.$refs.form.validate();
        if(validation){
          this.showStart = false;
        }else{
          this.showStart=true;
        }
      }else{
        this.showStart = false;
      }
    },
    setCorrectNext($data,$sequence){
      for(let i=0;i<$data.length;i++){
        if($data[i].sequence_id==$sequence){
          $data[i].next_process = this.lastSequenceId+1;
          return $data[i].isValid;
        }else{
          this.setCorrectNext($data[i].children);
        }
      }
    },
    setNextProcess($data){
      for(let i=0;i<$data.length;i++){
        if($data[i].sequence_id==this.lastChild.sequence_id){
          $data[i].next_process = this.lastObjectOfArray.sequence_id+1;
        }else{
          this.setNextProcess($data[i].children);
        }
      }
    },
   async checkLastObject($data){
      for(let i=0;i<$data.length;i++){
          if($data[i].children && $data[i].children.length>0){
            this.overallUpdateData.push($data[i])
           await this.checkLastObject($data[i].children);
          }else{
            this.overallUpdateData.push($data[i])
          }
      }
    },
    async setFlow($val) {
      this.flowType = $val;
      this.overallUpdateData = [];
      await this.checkLastObject(this.data)
      this.overallUpdateData = lodash.orderBy(this.overallUpdateData, ["sequence_id"], ["asc"]);
      this.lastObjectOfArray = this.overallUpdateData.length>0 ? this.overallUpdateData[this.overallUpdateData.length-1] : this.data[0];
      this.setNextProcess(this.data);
      if($val=='condition' || $val=='taskapproval'){
        this.addBeforeObject(this.data);
      }
      this.addLastChild(this.data)
      this.data[0].isActive=false;
    },
    setMultiApprovals() {
      if (!this.flowData.multi_approval_required) {
        this.flowData.multi_approval_percentage = null;
      }
    },
    async submitStart() {
      // let validation = await this.$refs.form.validate();
      // if (!validation.valid) {
      //   console.log(validation);
      // } else {
        let $obj = JSON.parse(JSON.stringify(this.workflow));
        // this.data = [...this.overallUpdateData];
        if ($obj && $obj.id) {
          $obj.exit_rules = this.data ? JSON.stringify(this.data): JSON.stringify([]);
          $obj.participants = this.workflow.participants
            ? JSON.stringify(this.workflow.participants)
            : JSON.stringify([]);
          $obj.company_id = this.workflow.company_id
            ? JSON.stringify(this.workflow.company_id)
            : JSON.stringify([]);
            let $res =  await WorkFlowService.update($obj);
         if($res){
            this.workFlowId = $res.data.data.id;
            this.nextProcess = true;
         }
        } else {
          $obj.exit_rules = this.data ? JSON.stringify(this.data): JSON.stringify([]);
          $obj.participants = this.workflow.participants
            ? JSON.stringify(this.workflow.participants)
            : JSON.stringify([]);
          $obj.company_id = this.workflow.company_id
            ? JSON.stringify(this.workflow.company_id)
            : JSON.stringify([]);
         let $res =  await WorkFlowService.create($obj);
         if($res){
            this.workFlowId = $res.data.data.id;
            this.nextProcess = true;
         }
        }
      // }
    },
    setSelected($value) {
      console.log($value, "valuesssssssss");
    },
    loadData() {
      this.data = [
        {
          datecreated: "2002-03-13",
          description: "Start",
          sequence_id: 1,
          next_process:2,
          installid: "Start",
          name: "Start",
          taskFlow:'start',
          taskTypeData: this.taskData,
          task: 1,
          children: [],
          active: true,
          isActive:true
        },
      ];
      WorkFlowService.currentlySelectedTree = this.data;
      this.lastSequenceId = 1;
    }
  },
};
</script>
<style>
.workflowcontainer {
  display: flex !important;
  height: 100vh;
  overflow: auto;
  padding: 10px;
}
.workflowright {
  background: #fff;
  border-left: 5px solid #e7e7e7;
}
.workflowmain {
  margin-top: -8px !important;
}
.fieldHeader {
  margin-left: -10px;
  padding: 15px 15px;
  border-bottom: 1px solid #e2e2e2;
  margin-bottom: 10px;
  margin-top: -5px;
  font-size: 15px;
  font-weight: bold;
}
.fieldHeader1 {
  margin-left: -10px;
  padding: 15px 15px;
  margin-bottom: 15px;
  margin-top: -5px;
  font-size: 15px;
  font-weight: bold;
}
.chip {
  color: white;
  background-color: teal;
}
.chip .mat-icon {
  color: #fff;
}
.chip .example-radio-group {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  align-items: flex-start;
}
</style>