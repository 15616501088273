<template>
  <v-card>
    <v-form @Submit.prevent="save" ref="form">
      <v-card-title class="dialog-Title"
        >{{
          type == "edit"
            ? "Edit"
            : type == "view"
            ? "View"
            : type == "add"
            ? "Add"
            : type == "select"
            ? "View"
            : ""
        }}
        Account
        <v-btn
          density="comfortable"
          @click="closeDialog"
          elevation="0"
          icon="mdi-close"
          style="float: right"
        ></v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-container>
          <!-- <v-form @submit.prevent="save" ref="form"> -->
            <v-row>
              <v-col cols="6" class="pa-0">
                <InputBox
                  :label="'Code'"
                  :disabled="showReadOnly"
                  :data="formData.code"
                  @textData="checkCode($event)"
                  :rules="codeRules"
                  @keydowninput="prevent"
                />
                <span v-if="codeError" class="error-field"
                  >Already Exists!</span
                >
              </v-col>
              <v-col cols="6" class="pl-2 pa-0">
                <InputBox
                  :label="'Name'"
                  :disabled="showReadOnly"
                  :data="formData.name"
                  @textData="
                    ($event) => {
                      formData.name = $event;
                    }
                  "
                  :rules="NameRules"
                />
              </v-col>
              <v-col cols="12" class="pa-0">
                <AutoComplete
                  :closeChip="true"
                  :chips="true"
                  :label="'Marketplace'"
                  :disabled="showReadOnly"
                  multiple
                  :items="mwsHeaderData"
                  :text="'name'"
                  :value="'id'"
                  :rules="marketplaceRules"
                  :data="formData.mws_header_id"
                  @textData="
                    ($event) => {
                      formData.mws_header_id = $event;
                    }
                  "
                >
                </AutoComplete>
              </v-col>
            </v-row>
          <!-- </v-form> -->
        </v-container>
      </v-card-text>
      <v-card-actions class="dialogfooter" v-if="!showReadOnly">
        <v-spacer></v-spacer>
        <v-btn class="mr-1 cancel" @click="closeDialog">Cancel</v-btn>
        <v-btn class="save" type="submit">Save</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
  
<script>
import InputBox from "@/components/InputBox.vue";
import AccountService from "@/services/admin/AccountService";
import AutoComplete from "@/components/AutoComplete.vue";
// import EnumService from "@/services/admin/EnumService";
import MwsHeaderService from "@/services/mws/MwsHeaderService";
export default {
  props: ["type", "id"],
  components: {
    InputBox,
    AutoComplete,
  },
  data() {
    return {
      codeError: false,
      codeExistFlag: false,
      picked: new Date(),
      codeRules: [(value) => !!value || "Code is Required"],
      marketplaceRules: [(value) => !!value || "Marketplace is Required"],
      NameRules: [(value) => !!value || " Name is Required"],
      formData: {
        code: null,
        name: null,
        mws_header_id: null,
      },
      showReadOnly: false,
      mwsHeaderData: [],
    };
  },
  created() {
    this.getMarketplace();
    if (this.id) {
      this.getEditData();
    }
    if (this.type == "view" || this.type == "select") {
      this.showReadOnly = true;
    }
  },
  methods: {
    async getMarketplace() {
      let res = await MwsHeaderService.findAll();
      this.mwsHeaderData = res&&res.length>0 ? res : [];
      console.log(this.mwsHeaderData, "marketplaces");
    },
    async getEditData() {
      let $res = await AccountService.findAll({ id: this.id });
      if ($res && $res.length > 0) {
        $res[0].mws_header_id = JSON.parse($res[0].mws_header_id);
      }
      this.formData = $res[0];
      console.log(this.formData, "formData");
    },

    closeDialog() {
      this.$emit("closeDialog");
    },
    prevent(e) {
      if (!/^[a-zA-Z0-9-_ \t]+$/.test(e.key) && e.key !== "Backspace") {
        e.preventDefault();
      }
    },
    checkCode($event) {
      this.formData.code = $event;
      if (this.formData.code && this.formData.code.length > 0) {
        this.formData.code = this.formData.code.toUpperCase();
        AccountService.findAll({ code: this.formData.code }).then((res) => {
          let $exist = res.filter(
            (key) => key.code.toUpperCase() == this.formData.code
          );
          this.codeExistFlag =
            $exist.length > 0
              ? this.formData.id && $exist[0].id == this.formData.id
                ? false
                : true
              : false;

          if (this.codeExistFlag) {
            this.codeError = true;
          } else {
            this.codeError = false;
          }
        });
      }
    },
    async save() {
      let validation = await this.$refs.form.validate();
      if (!validation.valid || this.codeError) {
        console.log(validation);
      }
     else{
      delete this.formData.users;
      let $accountData = JSON.parse(JSON.stringify(this.formData));
      if ($accountData && $accountData.id) {
        let output = await AccountService.update($accountData);
        this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });

        console.log(output);
        this.closeDialog();
      } else {
        try {
          let output = await AccountService.create($accountData);
          this.$store.dispatch("setSnackbar", { text: "Saved Successfully" });

          console.log(output, "output", this.formData);
          this.closeDialog();
        } catch (e) {
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: "Something Went Wrong",
          });
        }
      }
     }
    },
  },
};
</script>
  