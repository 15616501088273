<template>
  <v-card>
    <v-form @Submit.prevent="createOrUpdate" ref="form">
      <v-card-title class="dialog-Title">
        {{
          type == "edit"
          ? "Edit"
          : type == "view"
            ? "View"
            : type == "add"
              ? "Add"
              : type == "select"
                ? "View"
                : ""
        }}
        Sku Mapping
        <v-btn density="comfortable" @click="closeDialog" elevation="0" icon="mdi-close" style="float: right">
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" class="pa-0">
              <InputBox :data="formData.shop_sku" @textData="($event) => { formData.shop_sku = $event; }"
                :disabled="showReadOnly" :label="'Shop Sku'" @keyChange="checkSpecialCg(formData.shop_sku)"
                :rules="ShopSku" />
              <!-- <p v-if="showSkuError" style="color:red;position: relative;top: -16px;left:3px;font-size: small;">Shop Sku
                not found</p> -->
            </v-col>
            <v-col cols="12" class="pa-0">
              <InputBox :data="formData.merchant_sku" @textData="($event) => { formData.merchant_sku = $event; }"
                :disabled="showReadOnly" @keyChange="checkSpecialmerchant(formData.merchant_sku)" :label="'Merchant Sku'"
                :rules="MerchantSku" />
              <!-- <p v-if="showMerchantError" style="color:red;position: relative;top: -16px;left:3px;font-size: small;">
                Merchant Sku not found</p> -->
            </v-col>
            <v-col cols="12" class="pa-0">
              <AutoComplete :data="formData.substitute_only"
                @textData="($event) => { formData.substitute_only = $event; }" :disabled="showReadOnly"
                :label="'Substitute Only'" :items="skuMappingUpdate" :value="'id'" :text="'name'"
                :rules="SubstituteOnly" />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="dialogfooter pa-5" v-if="!showReadOnly">
        <v-spacer> </v-spacer>
        <v-btn class="mr-1 cancel" @click="closeDialog">Cancel</v-btn>
        <v-btn class="save" type="submit">Save</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
    
<script>
import MwsPreferenceService from "@/services/mws/MwsPreferenceService";
import MwsProductXrefService from '@/services/mws/MwsProductXrefService';
import lodash from 'lodash';
export default {
  props: ["type", "id"],
  data() {
    return {
      showReadOnly: false,
      ShopSku: [(value) => !!value || "Shop Sku is Required"],
      MerchantSku: [(value) => !!value || "Merchant Sku is Required"],
      SubstituteOnly: [(value) => !!value || "Substitute Only is Required"],
      showSkuError: false,
      showMerchantError: false,
      formData: {
        shop_sku: null,
        merchant_sku: null,
        substitute_only: null,
      },
      mwsId: localStorage.getItem("mwsHeaderId"),
      rule_type: "sku_mapping",
      skuMappingUpdate: [
        { id: "all", name: "All the Time" },
        { id: "stock", name: "When not in stock" },
      ],
    };
  },
  created() {
    if (this.id) {
      this.getEditData();
    }
    if (this.type == "view") {
      this.showReadOnly = true
    }
  },
  methods: {
    async getEditData() {
      let $res = await MwsPreferenceService.findAll({ id: this.id });
      if ($res && $res.length > 0) {
        this.formData = $res ? JSON.parse($res[0].preferences) : {};
        console.log(this.formData, 'formData')
      }
    },
    checkSpecialCg(value) {
      this.showSkuError = false
      if (value) {
        this.formData.shop_sku = value.replace(/[^a-zA-Z0-9@ .]+/, '');
      }
    },
    checkSpecialmerchant(value) {
      this.showMerchantError = false
      if (value) {
        this.formData.merchant_sku = value.replace(/[^a-zA-Z0-9@ .]+/, '');
      }
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
    // async save() {
    //   let validation = await this.$refs.form.validate();
    //   console.log(validation);
    // },
    async createOrUpdate() {
      const sku = []
      sku.push(this.formData.shop_sku)
      sku.push(this.formData.merchant_sku)
      let $data = await MwsProductXrefService.findAll({ mws_sku: sku, mws_header_id: localStorage.getItem("mwsHeaderId") })
      let shopSku = lodash.findIndex($data, { mws_sku: this.formData.shop_sku })
      let merchantSku = lodash.findIndex($data, { mws_sku: this.formData.merchant_sku })
      this.showSkuError = shopSku === -1;
      this.showMerchantError = merchantSku === -1;
      if (!this.showSkuError && !this.showMerchantError) {
        await this.save()
      }
    },
    async save() {
      console.log(this.formData);
      let validation = await this.$refs.form.validate();
      if (validation.valid) {
        let $obj = Object.assign({}, this.formData);
        let $val = {};
        $val.mws_header_id = this.mwsId;
        $val.rule_type = this.rule_type;
        if (this.id) {
          $val.id = this.id;
          $val.preferences = JSON.stringify($obj);
          await MwsPreferenceService.update($val);
          this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
          this.closeDialog();
        } else {
          try {
            $val.preferences = JSON.stringify($obj);
            let $res = await MwsPreferenceService.create($val);
            console.log($res, "responsessssssssss");
            this.$store.dispatch("setSnackbar", { text: "Saved Successfully" });
            this.closeDialog();
          } catch (e) {
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "Something Went Wrong",
            });
          }
        }
      }
    },
  },
};
</script>
        
    
<style></style>