<template>
  <PrimeTable
        :tableName="'Sub Category '"
        activity="Sub Category"
        :showTableHeader="true"
        :headers="column"
        :tableData="data"
        @rowAction="rowAction($event)"
        @addDialog="addDialog"
        @filterData="applyFilter($event)"
        :modelName="'sub_categories'"
      ></PrimeTable>
<v-dialog v-model="dialog" width="600px" persistent>
        <AddSubCategory @closeDialog="closeDialog" :type="type" :id="editDataId" @reload="getSubCategory()"/>
      </v-dialog>
      <v-dialog v-model="confirmation" width="300px" persistent>
    <ConfirmationDialog @cancelled="cancelled()" @deleted="deleted" :content="confirmationContent" />
  </v-dialog>
</template>
  
  <script>
import SubCategoryService from "@/services/catalogs/SubCategoryService.js";
import AddSubCategory from "../AddAdmin/AddSubCategory.vue";
import ConfirmationDialog from "@/components/DialogConfirmation.vue";
export default {
  components: {
    AddSubCategory,
    ConfirmationDialog
  },
  data() {
    return {
      dialog: false,
      data: [],
      confirmationContent:null,
      confirmation:false,
      column: [
        { title: "Name", align: "start", key: "name" },
        { title: "Code", align: "start", key: "code" },
        { title: "Category", align: "start", key: "categories" },
        { title: "Modified by", align: "start", key: "users" },
        { title: "Modified on", align: "start", key: "modified" },
        { title: "Status", align: "start", key: "active" },
        { title: "Action", key: "actions", sortable: false },
      ],
      active: [
        { id: 1, name: "Active" },
        { id: 0, name: "Inactive" },
      ],
    };
  },
  created() {
    let $value = {}
    this.getSubCategory($value);
  },
  methods: {
    applyFilter($value) {
      console.log($value, 'valueeeeeeee')
      this.getSubCategory($value)
    },
     async getSubCategory($value){
     await SubCategoryService.findAll($value)
      .then(response => { 
            console.log(response,"working11111")
            this.data = response
            this.data.map(obj=>{ 
              return obj.active = this.active.find(type=>type.id==obj.active).name
            })
          })
          .catch(error => { 
            console.log(error);
          });
    },
    rowAction(e) {
      if (e.type == "edit") {
        this.dialog = true;
        this.editDataId = e.value.id;
        this.type = e.type;
      }
      if (e.type == "view") {
        this.dialog = true;
        this.editDataId = e.value.id;
        this.type = e.type;
      }
      if (e.type == "delete") {
        this.dialog = false;
        this.confirmation = true;
        this.confirmationContent = `Do you want to delete the Sub Category ${e.value.name} ?`;
        this.editDataId = e.value.id;
      }
      if (e.type == "select") {
        this.dialog = true;
        this.editDataId = e.value.id;
        this.type = e.type;
      }
    },
    addDialog() {
      this.dialog = true;
      this.type = "add";
      this.editDataId = null;
    },
    closeDialog() {
      this.dialog = false;
      this.getSubCategory();
    },
    async deleted(){
      this.confirmation = false;
       await SubCategoryService.delete({id:this.editDataId});
      this.getSubCategory();
    },
    cancelled(){
      this.editDataId = null;
      this.confirmation = false;
    }
  },
};
</script>