<template>
    <v-row justify="center">
        <v-col cols="3" class="pa-7 ma-4" v-for="(item, index) in items" :key="index">
            <v-card elevation="5" @click="select(index)" :class="{ onbordActive: item.isSelect }" height="185"
                class="rounded-lg onboardCard">
                <v-card-text class="text-center mt-4">
                    <div class="text-center mt-2">{{ item.title }}</div>
                    <v-icon class="tick-icon" color="green" size="80">mdi-check-circle-outline</v-icon>
                </v-card-text>
            </v-card>

        </v-col>
    </v-row>
</template>

<script>
export default {
    props: ['reload'],
    data() {
        return {
            items: [
                {
                    title: 'I am using an ERP. Need to connect to multiple ecommerce channels',
                    isSelect: false,
                    value: 'erp'
                },
                {
                    title: 'I  don’t have ERP but have a ecommerce channel',
                    isSelect: false,
                    value: 'marketplace'
                },
                {
                    title: 'Exploring the options of ecommerce',
                    isSelect: false,
                    value: 'none'
                },
            ]
        }
    },
    watch: {
        reload() {
            this.items.forEach(f => {
                f.isSelect = false
            })
        }
    },
    methods: {
        select(index) {
            this.items.forEach(element => {
                element.isSelect = false
            });
            this.items[index].isSelect = true
            this.$emit('changeSlide', this.items[index])
        }
    }
}
</script>
