<template>
    <v-form>
   <v-card>
    <v-card-title class="dialog-Title">
        Get Order
        <v-btn density="comfortable" @click="closeDialog" elevation="0" icon="mdi-close" style="float: right"></v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
         <v-col class="pa-0 ">
            <InputBox :label="'Order'" :data="formData.order" @textData="($event) => {formData.order = $event;}"></InputBox>
         </v-col>
         <v-col class="pa-0">
            <InputBox :label="'Mws Name'" :data="formData.mws_name" @textData="($event) => {formData.mws_name = $event;}"></InputBox>
         </v-col>
    </v-card-text>
    <v-card-actions class="dialogfooter" v-if="!showReadOnly">
        <v-spacer> </v-spacer>
        <v-btn class="save" @click="pullOrder()">Pull Order</v-btn>
      </v-card-actions>
   </v-card>
   </v-form>
</template>
<script>
import InputBox from '@/components/InputBox.vue';
export default{
    components:{
        InputBox,
    },
    data(){
        return{
        formData:{},
        }
    },
    methods:{
        closeDialog(){
            this.$emit("closeDialog")
        },
        pullOrder(){

        }
    }
}

</script>