<template>
  <v-card>
    <v-form @submit.prevent="save()" ref="form">
      <v-card-title class="dialog-Title">{{
        type == "edit"
        ? "Edit"
        : type == "view"
          ? "View"
          : type == "add"
            ? "Add"
            : type == "select"
              ? "View"
              : ""
      }}
        Scheduler
        <v-btn density="comfortable" @click="closeDialog" elevation="0" icon="mdi-close" style="float: right"></v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row class="pt-3">
          <v-col cols="6" class="pa-0 pl-2 ">
            <EnumDropdown :data="schedulerData.end_point" @textData="($event) => { schedulerData.end_point = $event; }"
              :domainName="'endpoint'" :disabled="showReadOnly" :label="'End Point '" :rules="endpointRules" />
          </v-col>
          <v-col cols="6" class="pa-0 px-2">
            <AutoComplete :label="'Market Place'" :items="marketplaceData" :text="'name'" :value="'id'"
              :data="schedulerData.key_id" @textData="($event) => {
                  schedulerData.key_id = $event;
                }
                " :rules="marketPlace" :disabled="showReadOnly" />
          </v-col>
          <v-col cols="6" class="pa-0 pl-2">
            <DatePicker :data="schedule_date" @textData="selectScheduleDate($event)" :rules="scheduleDateRules"
              :disabled="showReadOnly" :label="'Schedule Date'" :errorColor="errorMsg" />
              <span v-if="errorMsg" class="error-field-datepicker">Schedule Date is Required</span> 
          </v-col>
          <v-col cols="6" class="pa-0 px-2">
            <InputBox :data="schedule_time" @selectChange="selectScheduleTime($event)" :disabled="showReadOnly"
              :label="'Schedule Time'" :type="'time'" :rules="scheduleTimeRule" />
            <!-- <DateTimePicker :placeholder="'Start time'" v-model="selectedTime" :value="'name'"></DateTimePicker> -->
          </v-col>
          <v-col cols="6" class="pa-0 pl-2">
            <InputBox :data="setData.interval" @textData="($event) => {
                setData.interval = $event;
              }
              " :label="'Interval'" :rules="intervalRules" :disabled="showReadOnly" />
          </v-col>
          <v-col cols="6" class="pa-0 px-2">
            <AutoComplete :data="setData.interval_type" @textData="($event) => {
                setData.interval_type = $event;
              }
              " :label="'Interval Type'" :text="'name'" :value="'id'" :items="intervalType" :disabled="showReadOnly"
              :rules="intervalTypeRules" />
          </v-col>
          <v-col cols="12" class="ma-0 pa-0 pl-3"><v-label class="commonLabel">Select Day</v-label></v-col>
          <v-row class="pa-5">
            <v-checkbox label="Sun" color="primary" :disabled="showReadOnly" v-model="setData.days" value="sunday"></v-checkbox>
            <v-checkbox label="Mon" color="primary" :disabled="showReadOnly" v-model="setData.days" value="monday"></v-checkbox>
            <v-checkbox label="Tue" color="primary" :disabled="showReadOnly" v-model="setData.days" value="tuesday"></v-checkbox>
            <v-checkbox label="Wed" color="primary" :disabled="showReadOnly" v-model="setData.days" value="wednesday"></v-checkbox>
            <v-checkbox label="Thu" color="primary" :disabled="showReadOnly" v-model="setData.days" value="thursday"></v-checkbox>
            <v-checkbox label="Fri" color="primary" :disabled="showReadOnly" v-model="setData.days" value="friday"></v-checkbox>
            <v-checkbox label="Sat" color="primary" :disabled="showReadOnly" v-model="setData.days" value="saturday"></v-checkbox>
          </v-row>
        </v-row>
      </v-card-text>
      <v-card-actions class="dialogfooter" v-if="!showReadOnly">
        <v-spacer></v-spacer>
        <v-btn class="mr-1 cancel" @click="closeDialog()">Cancel</v-btn>
        <v-btn class="save" type="submit">Save</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
import DatePicker from "@/components/DatePicker.vue";
import JobService from "./Service/jobService.js";
import moment from "moment";
import InputBox from "@/components/InputBox.vue";
import MwsHeaderService from "@/services/mws/MwsHeaderService";
export default {
  props: ["type", "id"],
  components: {
    DatePicker,
    InputBox,
  },
  data() {
    return {
      showReadOnly: false,
      intervalType: [
        { id: "hours", name: "Hours" },
        { id: "days", name: "Days" },
        { id: "month", name: "Month" },
        { id: "week", name: "Week" },
        { id: "minutes", name: "Minutes" },
      ],
      intervalRules: [(value) => !!value || "Interval is Required"],
      intervalTypeRules: [(value) => !!value || "Interval Type is Required"],
      marketPlace: [(value) => !!value || "Market Place is Required"],
      scheduleDateRules: [(value) => !!value || "Schedule Date is Required"],
      scheduleTimeRule: [(value) => !!value || "Schedule Time is Required"],
      endpointRules: [(value) => !!value || "End Point is Required"],
      schedulerData: {
        end_point: null,
        body: null,
      },
      scheduler: null,
      showRow: false,
      setData: {
        interval: null,
        days: [],
        interval_type: null,
      },
      schedule_date: null,
      schedule_time: null,
      marketplaceData: [],
      errorMsg: false,

    };
  },
  watch: {},
  created() {
    if (this.type == "view" || this.type == "select") {
      this.showReadOnly = true;
    }
    if (this.id && this.type != "add") {
      this.getScheduler(this.id);
    }
    this.getMarketPlace();
  },
  methods: {
    selectScheduleDate($event){
      this.schedule_date = $event
      if(this.schedule_date){
        this.errorMsg = false
      }
      //  this.discountCodeData.start_date = moment($event).format("YYYY-MM-DD")
    },

    selectScheduleTime($event) {
      this.schedule_time = $event;
    },
    
    async getScheduler() {
      let res = await JobService.findOne({ id: this.id });
      this.schedulerData = res[0];
      let interval_data = JSON.parse(res[0].interval_data)
      this.schedule_date = res[0].next_date
      // console.log(moment(res[0].next_date).format("HH:mm"),'(moment(res[0].next_date, "HH:mm")')
      //this.schedule_date =  moment(res[0].next_date, "YYYY-MM-DD").format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (Z)");
      this.schedule_time = moment(res[0].next_date).format("HH:mm")
      // console.log(this.scheduleTime,' moment(res[0].next_date).format("HH:mm")')
      // alert(this.schedule_date)

      console.log("ressssss", interval_data)
      this.setData.interval = interval_data.interval
      this.setData.days = interval_data.days
      this.setData.interval_type = interval_data.interval_type
      console.log(this.schedulerData, "vall");
      console.log(this);
    },
    async getMarketPlace() {
      await MwsHeaderService.findAll()
        .then((response) => {
          this.marketplaceData = response;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async save() {
      let date = moment(this.schedule_date).format("YYYY-MM-DD");
      let alteredDate = date.concat(" ", this.schedule_time, ":00");
      this.schedulerData.next_date = moment(alteredDate)
      console.log(alteredDate, 'moment(alteredDate)', moment(alteredDate))
      this.schedulerData.interval_data = JSON.stringify(this.setData);
      console.log(this.schedulerData, 'this.schedulerData.next_date')
      let validation = await this.$refs.form.validate();
      if (!validation.valid ||!this.schedule_date ) {
        console.log("Not valid");
        if(!this.schedule_date){
          this.errorMsg = true;
        }
      } else {
        if (this.id) {
          delete this.schedulerData.mws_headers
          console.log(this.schedulerData, "ttt");
          await JobService.update(this.schedulerData);
          console.log(this.schedulerData, "hih");
          this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
          this.$emit("closeDialog", true);
          this.$emit("reload", true);
        } else {
          try {
            this.schedulerData.key_type = "MwsHeader";
            console.log("111111", this.schedulerData);
            await JobService.create(this.schedulerData);
            this.$store.dispatch("setSnackbar", { text: "Saved Successfully" });
            this.$emit("closeDialog", true);
            this.$emit("reload", true);
          } catch (e) {
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "Something Went Wrong",
            });
          }
        }
      }
    },

    //   if (this.scheduler.end_point) {
    //     await JobService.create(this.scheduler);
    //     this.$emit("closeDialog", true);
    //     this.$emit("reload", true);
    //   }
    // },
    // async save() {
    //   let validation = await this.$refs.form.validate();
    //   if (!validation.valid) {
    //     console.log("Not valid");
    //   } else {
    //     if (this.id) {
    //       await JobService.update(this.formData);
    //       this.$store.dispatch("setSnackbar", { text: "Updated Successfully" });
    //       this.$emit("closeDialog", true);
    //       this.$emit("reload", true);
    //     } else {
    //       try {
    //         await JobService.create(this.formData);
    //         this.$store.dispatch("setSnackbar", { text: "Saved Successfully" });
    //         this.$emit("closeDialog", true);
    //         this.$emit("reload", true);
    //       } catch (e) {
    //         this.$store.dispatch("setSnackbar", {
    //           color: "error",
    //           text: "Something Went Wrong",
    //         });
    //       }
    //     }
    //   }
    // },

    deleteRows(index) {
      this.rows.splice(index, 1);
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
  },
};
</script>

<style scoped>
::-webkit-scrollbar{
  height: 0px;
  width: 0px;
}
</style>