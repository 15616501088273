<template>
  <div>
    <div class="Product-header pl-1">Media</div>
    <v-row class="mt-2">
      <template v-for="(image, imgIdx) in imageLayout" :key="imgIdx">
        <v-col :cols="image.cols">
          <v-img style="border: 2px solid; border-color: greem" :src="image.img" 
          height="100%"></v-img>
        </v-col>

        <v-col v-if="image.children" cols="6" class="d-flex flex-column">
          <v-row>
            <v-col v-for="(children, childIdx) in image.children" :key="childIdx" :cols="children.cols">
              <v-img style="border: 2px solid; border-color: greem" :src="image.img"> </v-img>
            </v-col>
          </v-row>
        </v-col>
      </template>         
      <v-col cols="12" class="backroundimg2" xs="1" sm="2" md="4" lg="4" xl="4">
        <p class="font-weight-black text-center">
          Sold {{
           products && products.products_data && products.products_data.length > 0 &&
            products.products_data[0].product_sold ?
            products.products_data[0].product_sold : 0 }} Units in the Last 90 Days
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DocumentModel from '@/services/admin/DocumentService';
export default {
  props: ['productsData'],
  data() {
    return {
      products: {},
      imageLayout: [
        {
          cols: 2,
          img: '',
          children: [
            { cols: 3 },
            { cols: 3 },
            { cols: 3 },
            { cols: 3 },
            { cols:6  },
            { cols:6  },
          ],
        },
      ],
    };
  },
  watch: {
    async productsData() {
      //console.log(this.productsData,'&&products.products_data')
      this.products = this.productsData
      if (this.products && this.products.style_images) {
        this.imageLayout[0].img = this.products.style_images ? this.products.style_images : ''
      }
      else {
        if (this.products && this.products.img_id) {
          let res = await DocumentModel.getImage({ "id": this.products.img_id })
          this.imageLayout[0].img = res
        }
      }
      //console.log('this.products',this.products,this.imageLayout,'imgggggggggggg')
    }
  },
  async created() {
    this.products = this.productsData
    if (this.products && this.products.style_images) {
      this.imageLayout[0].img = this.products.style_images ? this.products.style_images : ''
    }
    else {
      if (this.products && this.products.img_id) {
        let res = await DocumentModel.getImage({ "id": this.products.img_id })
        this.imageLayout[0].img = res
      }
    }
  }
};
</script>

<style>
.backroundimg2 {
  background-image: url("../../assets/backgroundImage.svg");
  /* padding-bottom: 2%;
  padding-left: 2%; */
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: end;
  /* padding-bottom:9px; */
  position: relative; 
  bottom: 11px;
  right: 5px;

}
</style>