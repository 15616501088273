<template>
  <PrimeTable
    :tableName="'Exchange Rates'"
    activity="Exchange Rates"
    :showTableHeader="true"
    :headers="column"
    :tableData="data"
    :isShowAdd="true"
    @rowAction="rowAction"
    @addDialog="addDialog"
    @syncClicked="addDialog"
    :showSync="true"
    :modelName="'exchange_rates'"
    @filterData="applyFilter($event)"
  ></PrimeTable>
  <v-dialog v-model="dialog" width="1000px" persistent>
    <AddExchangeRates @closeDialog="closeDialog" :type="type" :totalArray="data" />
  </v-dialog>
</template>

<script>
import AddExchangeRates from "../AddAdmin/AddExchangeRates.vue";
// import UserModel from "@/models/UserService";
import ExchangeRateService from "@/services/catalogs/ExchangeRateService.js";

export default {
  components: {
    AddExchangeRates,
  },
  data() {
    return {
      dialog: false,
      data: [],
      column: [
        { title: "TransDate", align: "start", key: "trans_date" },
        { title: "FromCurrency", align: "start", key: "from_currency_id" },
        { title: "ToCurrency", align: "start", key: "to_currency_id" },
        { title: "Rate", align: "start", key: "rate" },
        { title: "Status", key: "active", sortable: false },
        // { title: "Action", key: "actions", sortable: false },
      ],
      active: [
        { id: 1, name: "Active" },
        { id: 0, name: "Inactive" },
      ],
    };
  },
  created() {
    let $value = {};
    this.getExchangeRates($value);
  },
  methods: {
    applyFilter($value){
       this.getExchangeRates($value)
    },
    async getExchangeRates($value) {
      await ExchangeRateService.getData($value)
        .then((response) => {
          this.data = response;
          this.data.map((obj) => {
            return (obj.active = this.active.find((type) => type.id == obj.active).name);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    rowAction(e) {
      if (e.type == "edit") {
        this.dialog = true;
        this.type = e.type;
      }
      if (e.type == "view") {
        this.dialog = true;
        this.type = e.type;
      }
      if (e.type == "delete") {
        this.dialog = true;
      }
      if (e.type == "select") {
        this.dialog = true;
        this.type = e.type;
      }
    },
    addDialog() {
      this.dialog = true;
      this.type = "add";
    },
    closeDialog() {
      this.dialog = false;
      this.getExchangeRates()
    },
  },
};
</script>
