<template>
    <v-card elevation="0">
        <v-card-title class="text-body-2">
            Bill To Address
        </v-card-title>
        <v-card-text class="pl-1">
            <!-- Address Column -->
            <v-col cols="12">
                <b><span v-if="mwsOrderdata && mwsOrderdata.buyer_salutation">{{ mwsOrderdata && mwsOrderdata.buyer_salutation ? mwsOrderdata.buyer_salutation : ''}}.</span> {{ mwsOrderdata && mwsOrderdata.buyer_name ? mwsOrderdata.buyer_name : ''}}</b>
                <p class="pt-5"><span v-if="mwsOrderdata && mwsOrderdata.buyer_address1">{{ mwsOrderdata && mwsOrderdata.buyer_address1.length>2 ? mwsOrderdata.buyer_address1 : 'No Billing Address'}}</span></p>
                <p ><span v-if="mwsOrderdata && mwsOrderdata.buyer_address2">{{ mwsOrderdata && mwsOrderdata.buyer_address2.length>2 ? mwsOrderdata.buyer_address2 : ''}}</span> <span v-if="mwsOrderdata && mwsOrderdata.buyer_address3">{{ mwsOrderdata && mwsOrderdata.buyer_address3 ? mwsOrderdata.buyer_address3 : ''}}</span> <span v-if="mwsOrderdata && mwsOrderdata.buyer_address4">{{ mwsOrderdata && mwsOrderdata.buyer_address4 ? mwsOrderdata.buyer_address4 : ''}}</span></p>
                <p ><span v-if="mwsOrderdata && mwsOrderdata.buyer_address4">{{ mwsOrderdata && mwsOrderdata.buyer_address4.length>2 ? mwsOrderdata.buyer_address4 : ''}}</span></p>
                <p ><span v-if="mwsOrderdata && mwsOrderdata.buyer_state">{{ mwsOrderdata && mwsOrderdata.buyer_state ? mwsOrderdata.buyer_state : ''}}</span> <span v-if="mwsOrderdata && mwsOrderdata.buyer_city">{{ mwsOrderdata && mwsOrderdata.buyer_city ? mwsOrderdata.buyer_city : ''}},</span> <span v-if="mwsOrderdata && mwsOrderdata.buyer_country">{{ mwsOrderdata && mwsOrderdata.buyer_country ? mwsOrderdata.buyer_country : ''}},</span> <span v-if="mwsOrderdata && mwsOrderdata.buyer_zip_code">{{ mwsOrderdata && mwsOrderdata.buyer_zip_code ? mwsOrderdata.buyer_zip_code : ''}}</span></p>
                <p class="pt-4" v-if="mwsOrderdata && mwsOrderdata.buyer_mobile"><span class="p-title">Ph:</span>&nbsp;&nbsp; {{ mwsOrderdata && mwsOrderdata.buyer_mobile ? mwsOrderdata.buyer_mobile : ''}}</p>
                <p v-if="mwsOrderdata && mwsOrderdata.buyer_email"><span class="p-title">Email:</span>&nbsp;&nbsp; {{ mwsOrderdata && mwsOrderdata.buyer_email ? mwsOrderdata.buyer_email : ''}}</p>
                <!-- <p>Ph: {{ mwsOrderdata && mwsOrderdata.buyer_mobile ? mwsOrderdata.buyer_mobile : ''}}</p>
                <p>{{ mwsOrderdata && mwsOrderdata.buyer_email ? mwsOrderdata.buyer_email : ''}}</p> -->
              </v-col>
            <!-- <v-col>
                <v-row>
                <v-col cols="6" class="mr-0 pr-0" v-if="mwsOrderdata && mwsOrderdata.buyer_mobile">
                 <p>Ph: {{ mwsOrderdata && mwsOrderdata.buyer_mobile ? mwsOrderdata.buyer_mobile : ''}}</p>
                </v-col>
                <v-col cols="6" align="end" class="ml-0 pl-0" v-if="mwsOrderdata && mwsOrderdata.buyer_email">
                 <p>{{ mwsOrderdata && mwsOrderdata.buyer_email ? mwsOrderdata.buyer_email : ''}}</p>
                </v-col>
            </v-row>
            </v-col> -->
        </v-card-text>
    </v-card>
</template>
<script>
export default{
    props:['data'],
  data(){
    return{
      mwsOrderdata:{}, 
    }
  },
  watch: {
        data() {
            this.mwsOrderdata=this.data
        },
    },
  created(){
    this.mwsOrderdata=this.data
  },
  methods:{

  }
}
</script>
<style>
.p-title{
  color: #636363;
}
</style>